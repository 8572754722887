import mixpanel from "mixpanel-browser";
import { ChannelType } from "../models/channel";

const MIXPANEL_TOKEN=process.env.REACT_APP_MIXPANEL_TOKEN_DEV;
const parse = () => {
  const obj = JSON.parse(localStorage.getItem("userInfo") ?? {}) ?? {};
  return obj;
};
export const EVENTS = {
  sign_up: "sign_up",
  sign_in: "sign_in",
  connect_source: "connect_source",
  remove_source: "remove_source",
  generate_report: "generate_report",
  pagination_gads_account: "pagination_gads_account",
  add_new_chart: "add_new_chart",
  edit_chart: "edit_chart",
  delete_chart: "delete_chart",
  view_reports: "view_reports",
  refresh_chart: "refresh_chart",
  connect_sources_chart: "connect_sources_chart",
  data_blends_view: "data_blends_view",
  create_new_source: "create_new_source",
  create_custom_metric: "create_custom_metric",
  click_extractor: "click_extractor",
  create_trigger: "create_trigger",
  edit_triggers_success: "edit_triggers_success",
  trigger_success: "trigger_success",
  trigger_failure: "trigger_failure",
  migrate_reports_success: "migrate_reports_success",
  invite_user_success: "invite_user_success",
  view_chart_settings:"view_chart_settings",
  add_new_header:"add_new_header",
  default_report_set:"default_report_set",
  date_range_reports:"date_range_reports",
  edit_blend_success:"edit_blend_success",
  click_my_profile:"click_my_profile",
  change_name:"change_name",
  change_password:"change_password",
  remove_user:"remove_user",
  change_user_role:"change_user_role",
  click_manage_teams:"click_manage_teams",
  click_invite_users:"click_invite_users",
  click_logs:"click_logs",
  click_migrate_reports:"click_migrate_reports",
  report_type:"report_type",
  click_new_source:"click_new_source",
  view_preview:"view_preview",
  click_existing_blend:"click_existing_blend",
  delete_custom_blend:"delete_custom_blend",
  click_customised_metric:"click_customised_metric",
  click_customised_dimension:"click_customised_dimension",
  create_customised_dimension:"create_customised_dimension",
  download_trigger:"download_trigger",
  link_trigger:"link_trigger",
  delete_trigger:"delete_trigger",
  view_log_trigger:"view_log_trigger",
  view_preview_extractor:"view_preview_extractor",
  view_all_triggers:"view_all_triggers",
  click_edit_triggers:"click_edit_triggers",
  view_extractor: "view_extractor",
  success_create_blends: "success_create_blends",
  update_blends: "update_blends"
};
class Mixpanel {
  instance = null;
  constructor() {
    if (!this.instance) {
      this.instance = mixpanel;
      mixpanel.init(MIXPANEL_TOKEN, { debug: true });
    }
  }
  track(event, data, extra = false) {
    const extraObj = extra
      ? { email_id: parse().email, organisation_name: parse().company_name }
      : {};
    mixpanel.track(event, { ...data, ...extraObj });
  }
  login_success(email_id) {
    mixpanel.identify(email_id);
    mixpanel.people.set({
      "$email": email_id,
      "$name": email_id
    });
  }
}

const mixpanelObj = new Mixpanel();
Object.freeze(mixpanelObj);
export default mixpanelObj;

const selectdAccountMapping = () => {
  const parsedData = JSON.parse(
    localStorage.getItem("selected_accounts") ?? "{}"
  );
  const account_mapping = {};
  Object.entries(parsedData).forEach(([key, value]) => {
    if (key === "shopify-ads") {
      account_mapping[key] = value.storeName;
    } else if (key === "google-ads") {
      account_mapping[key] = value.customerId;
    } else {
      account_mapping[key] = value.accountId;
    }
  });
  return account_mapping;
};

const transform_create_new_source = (data = {}) => {
  const configs = [...data.dsConfigs];
  const joins = [...data.joins];
  const dataSources = [];
  const dimensions = [];
  const metrics = [];
  const accounts = [];
  const reportTypes = [];
  const joinTypes = [];
  const filters = [];
  const joiningKeys = [];
  const mappedAccounts = selectdAccountMapping();
  configs.forEach((config) => {
    dataSources.push(config.dataSource);
    const tempDimensions = [];
    const tempMetrics = [];
    config.dimensions.forEach((dimension) => {
      tempDimensions.push(dimension.name);
    });
    config.metrics.forEach((metric) => {
      tempMetrics.push(metric.name);
    });
    dimensions.push(tempDimensions);
    metrics.push(tempMetrics);
    reportTypes.push(config?.dsOptions?.tableUiName ?? "none");
    filters.push(config?.dsOptions);
    accounts.push(mappedAccounts[config.dataSource]);
  });
  joins.forEach((join) => {
    joinTypes.push(join?.joinType ?? "");
    joiningKeys.push(join.keys);
  });
  return {
    new_source_name: data.name,
    data_sources: dataSources,
    accounts: accounts,
    joining_keys: joiningKeys,
    metrics: metrics,
    dimensions: dimensions,
    filters: filters,
    report_types: reportTypes,
    join_types: joinTypes,
  };
};
const transform_add_new_chart_or_edit_widget = (data) => {
  let leftMetrics = [];
  let rightMetrics = [];
  let dimensions = [];
  let chartType = "";
  let accounts = {};
  let compareWith = "";
  let filter = {};
  let metrics = [];
  const platforms = [];
  const account_ids = [];
  const config = data.updates.length !== 0 ? data.updates[0] : null;
  const mappedAccounts = selectdAccountMapping();
  if (config && config?.params) {
    if (typeof config.params.channel === "string") {
      //normal chart
      if (Array.isArray(config.params.leftMetrics)) {
        leftMetrics = [...config.params.leftMetrics];
      }
      if (Array.isArray(config.params.rightMetrics)) {
        rightMetrics = [...config.params.rightMetrics];
      }
      if (Array.isArray(config.params.dimensions)) {
        dimensions = [...config.params.dimensions];
      }
      account_ids.push(
        mappedAccounts[config.params.channel] ?? "account_not_selected"
      );
      platforms.push(config.params.channel);
    } else if (typeof config.params.channel === "object") {
      //blend chart
      if (Array.isArray(config.params.leftMetrics)) {
        metrics = [...config.params.leftMetrics];
        leftMetrics = [...config.params.leftMetrics];
      }
      if (Array.isArray(config.params.rightMetrics)) {
        metrics = [...metrics, ...config.params.rightMetrics];
        rightMetrics = [...config.params.rightMetrics];
      }
      if (Array.isArray(config.params.dimensions)) {
        metrics = [...metrics, ...config.params.dimensions];
        dimensions = [...config.params.dimensions];
      }
      metrics.forEach((account) => {
        accounts[account.dataSource] =
          mappedAccounts[account.dataSource] ?? "account_not_selected";
      });
      Object.entries(accounts).forEach(([key, value], index) => {
        account_ids.push(value);
        platforms.push(value);
      });
    }
    filter = config.params.filter;
    compareWith = config.params.compareWith;
    chartType = config.params.type;
  }
  return {
    left_metrics: leftMetrics,
    right_metrics: rightMetrics,
    dimensions: dimensions,
    chart_type: chartType,
    compare_with: compareWith,
    filter: filter,
    account_ids: account_ids,
    platforms: platforms,
  };
};

const transform_generate_report = () => {
  const account_ids = [];
  const platforms = [];
  const mappedAccounts = selectdAccountMapping();
  Object.entries(mappedAccounts).forEach(([key, value]) => {
    account_ids.push(value);
    platforms.push(key);
  });
  return { account_ids, platforms };
};

const transform_refresh_chart = (data) => {
  let chart_title = data.title;
  let metrics = [];
  const accounts = {};
  const data_sources = [];
  if (typeof data.channelType === "string") {
    data_sources.push(data.channelType);
  } else if (typeof data.channelType === "object") {
    if (Array.isArray(data.leftMetrics)) {
      metrics = [...data.leftMetrics];
    }
    if (Array.isArray(data.rightMetrics)) {
      metrics = [...metrics, ...data.rightMetrics];
    }
    if (Array.isArray(data.dimensions)) {
      metrics = [...metrics, ...data.dimensions];
    }
    metrics.forEach((account) => {
      if (account?.metric) {
        if (!account?.metric?.dataSource) {
          data_sources.push(account?.metric?.dataSource);
          accounts[account?.metric?.dataSource] = account?.metric?.dataSource;
        }
      } else {
        if (!accounts[account.dataSource]) {
          data_sources.push(account.dataSource);
          accounts[account.dataSource] = account.dataSource;
        }
      }
    });
  }
  return { chart_title, data_sources };
};

const transform_connect_sources_chart = (data) => {
  const chart_title = data.title;
  const data_sources = [];
  if (typeof data.channelType === "string") {
    data_sources.push(data.channelType);
  }
  return { chart_title, data_sources };
};

const transform_migrate_reports_success = (data) => {
  const selected_tabs = [];
  const report_name = data.report.report_name;
  const choose_your_source = data.sourceOrg.name;
  const choose_your_destination = data.sourceOrg.name;
  data.report.tabs.forEach((tab) => {
    delete tab.grids;
    selected_tabs.push(tab);
  });
  return {
    selected_tabs,
    report_name,
    choose_your_source,
    choose_your_destination,
  };
};

const transform_edit_or_create_triggers_success = (data) => {
  const data_source = data.dataSource,tab_name = data.storage.tabName,speadsheet_name = data.storage.spreadsheetName;
  const number_of_rows = data.numberOfRows,filters = data.Filters,time_zone = data.triggerSchedule.timeZone;
  const action=data.triggerSchedule.action,starting_cell=data.storage.startingCell,ending_cell=data.storage.endingCell;
  const data_ingestion_destination = data.storage.dataDestination,write_mode = data.storage.writePreference;
  const make_header = data.storage.hasHeaders ? "yes" : "no";
  const date_range = data.storage.dateRange,dimensions = data.dimensions,sort_by_fields = data.orderBys;
  const create_new_empty_speadsheet = data.storage.spreadsheetCreate ? "yes" : "no";
  const aggregates = data.aggregates,segregate_rows=data.segregate ? "yes" : "no";
  const accounts = [],metrics = data.metrics;
  data.accounts.map(account => {
    if(data_source === "google-ads"){
      accounts.push(account.customerId);
    }else if(data_source === "shopify-ads"){
      accounts.push(account.storeName);
    }else{
      accounts.push(account.accountId);
    }
  });
  return {
          data_source,accounts,metrics,tab_name,speadsheet_name,number_of_rows,filters,
          time_zone,action,starting_cell,ending_cell,data_ingestion_destination,make_header,
          create_new_empty_speadsheet,write_mode,date_range,dimensions,sort_by_fields,
          aggregates,
          segregate_rows
        }
        
}
const transform_create_custom_metric = (data) => {
  const new_column_name = data.name,formula=data.other.nameFormula;
  const data_sources = [];
  if(typeof data.other.is_blended_datasource){
    const channels = Object.values(ChannelType);
    channels.forEach((value) => {
      if(data.formula.includes(value)){
        data_sources.push(value);
      }
    });
  }else{
    data_sources.push(data.source);
  }
  return {new_column_name,formula,data_sources};
}

const transform_view_reports = (data) => {
  const report_type = data.report_name;
  const tab_name = data.name;
  return {report_type,tab_name}
}

const transform_view_chart_settings = (data) => {
  const chart_type = data.type;
  const data_sources = transform_refresh_chart(data).data_sources;
  const mappedAccounts = selectdAccountMapping();
  const account_ids = [];
  data_sources.forEach(account => {
    account_ids.push(mappedAccounts[account]);
  });
  return {chart_type,data_sources,account_ids}
}

const transform_edit_blend_success = (data) => {
  const data_sources = [],accounts = [];
  const mappedAccounts = selectdAccountMapping();
  data.dsConfigs.forEach(source => {
    data_sources.push(source.dataSource);
    accounts.push(mappedAccounts[source.dataSource] ?? "account_not_selected");
  });
  return { data_sources,accounts };
}

const transform_click_existing_blend = (data) => {
  const mappedAccounts = selectdAccountMapping();
  const data_sources = [];
  const accounts = [];
  data.dsConfigs.forEach(data => {
    data_sources.push(data.dataSource);
    accounts.push(mappedAccounts[data.dataSource] ?? "account_not_selected");
  });
  return {data_sources,accounts};
}

const transform_delete_custom_blend = (data) => {
  const data_sources = [];
  const blend_name = data.name;
  data.dsConfigs.forEach(data => {
    data_sources.push(data.dataSource);
  });
  return {data_sources,blend_name};
}

const transform_create_customised_dimension = (data) => {
   const custom_dimension_name = data.customDimension.name;
   const choosen_source = data.customDimension.data_source;
   const choosen_base_column = data.customDimension.base_dimension.name;
   const choosen_reference_source = data.customDimension.mapped_ds;
   const report_type = data.customDimension.base_dimension.reportType?.name;
   const data_sources = [data.customDimension.data_source,data.customDimension.mapped_ds];
   const accounts = [data.baseAccountId,data.refAccountId];
   const selected_account = data.selectedAccount;
   const choosen_reference_column = data.customDimension.mapped_dimension.name;
   return {
    custom_dimension_name,choosen_source,choosen_base_column,choosen_reference_source,
    report_type,data_sources,accounts,selected_account,choosen_reference_column
   }
}

export const transformMixPanelData = (type, data) => {
  switch (type) {
    case EVENTS.create_new_source:
      mixpanelObj.track(type, transform_create_new_source(data), true);
      break;
    case EVENTS.add_new_chart:
      transform_add_new_chart_or_edit_widget(data).left_metrics?.length !== 0 && mixpanelObj.track(type,transform_add_new_chart_or_edit_widget(data),true);
      break;
    case EVENTS.edit_chart:
      transform_add_new_chart_or_edit_widget(data).left_metrics?.length !== 0 && mixpanelObj.track(
        type,
        transform_add_new_chart_or_edit_widget(data),
        true
      );
      break;
    case EVENTS.delete_chart:
      mixpanelObj.track(
        type,
        transform_add_new_chart_or_edit_widget(data),
        true
      );
      break;
    case EVENTS.generate_report:
      mixpanelObj.track(type, transform_generate_report(data), true);
      break;
    case EVENTS.pagination_gads_account:
      mixpanelObj.track(type, {}, true);
      break;
    case EVENTS.refresh_chart:
      mixpanelObj.track(type, transform_refresh_chart(data), true);
      break;
    case EVENTS.connect_sources_chart:
      mixpanelObj.track(type, transform_connect_sources_chart(data), true);
      break;
    case EVENTS.migrate_reports_success:
      mixpanelObj.track(type, transform_migrate_reports_success(data), true);
      break;
    case EVENTS.edit_triggers_success:
      mixpanelObj.track(type, transform_edit_or_create_triggers_success(data), true);
      break;
    case EVENTS.create_trigger:
      mixpanelObj.track(type, transform_edit_or_create_triggers_success(data), true);
      break;
    case EVENTS.create_custom_metric:
      mixpanelObj.track(type, transform_create_custom_metric(data), true);
      break;
    case EVENTS.view_reports:
      mixpanelObj.track(type, transform_view_reports(data), true);
      break;
    case EVENTS.view_chart_settings:
      mixpanelObj.track(type, transform_view_chart_settings(data), true);
      break;
    case EVENTS.add_new_header:
      mixpanelObj.track(type,data,true);
      break;
    case EVENTS.default_report_set:
      mixpanelObj.track(type,data,true);
      break;
    case EVENTS.date_range_reports:
      mixpanelObj.track(type,data,true);
      break;
    case EVENTS.edit_blend_success:
      mixpanelObj.track(type, transform_edit_blend_success(data), true);
      break;
    case EVENTS.click_my_profile:
      mixpanelObj.track(type,data,true);
      break;
    case EVENTS.change_name:
      mixpanelObj.track(type,data,true);
      break;
    case EVENTS.change_password:
      mixpanelObj.track(type,data,true);
      break;
    case EVENTS.remove_user:
      mixpanelObj.track(type,{removed_user_role:data.role,removed_user_email:data.email,role:parse().role},true);
      break;
    case EVENTS.change_user_role:
      mixpanelObj.track(type,{role:parse().role,changed_user_email:data.changed_user_email,existing_user_role:data.existing_user_role,new_user_role:data.new_user_role},true);
      break;
    case EVENTS.report_type:
      mixpanelObj.track(type,{report_name:data?.report_name ?? "new"},true);
      break;
    case EVENTS.view_preview:
      mixpanelObj.track(type,{data_sources:data},true);
      break;
    case EVENTS.click_existing_blend:
      mixpanelObj.track(type,transform_click_existing_blend(data),true);
      break;
    case EVENTS.delete_custom_blend:
      mixpanelObj.track(type,transform_delete_custom_blend(data),true);
      break;
    case EVENTS.click_customised_dimension:
    case EVENTS.click_customised_metric:
      mixpanelObj.track(type,data,true);
      break;
    case EVENTS.create_customised_dimension:
      mixpanelObj.track(type,transform_create_customised_dimension(data),true);
      break;
    case EVENTS.download_trigger:
    case EVENTS.link_trigger:
    case EVENTS.delete_trigger:
    case EVENTS.view_log_trigger:
      mixpanelObj.track(type,{trigger_name:data},true);
      break;
    case EVENTS.view_preview_extractor:
      mixpanelObj.track(type,{trigger_name:data},true);
      break;
    case EVENTS.click_edit_triggers:
      mixpanelObj.track(type,{trigger_name:data},true);
      break;
    case EVENTS.success_create_blends:
      handleSuccessCreateBlends(type, data);
      break;
    case EVENTS.update_blends:
      mixpanelObj.track(type,{},true);
      break;
  }
};

const handleSuccessCreateBlends = (type, data) => {
  let [platforms, ad_accounts] = [ [], [] ];
  data?.dsConfigs?.forEach((dsConfig) => {
    platforms.push(dsConfig.dataSource);
    ad_accounts.push(dsConfig?.audiences?.[0]?.id);
  })
  mixpanelObj.track(type, { platforms,ad_accounts }, true);
}