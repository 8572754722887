import { all, put, select, takeEvery, takeLatest, takeLeading } from "redux-saga/effects";
import { api } from "..";
import { CHECK_AUTH, SELECT_ACCOUNT } from "../channel/type";
import { channelType, getAccountId } from "./transformer";
import mixpanelObj from "../../utils/mixpanel";
import { convertAccountsFormat } from "../../models/channel";

function* getClientID() {
    try {
        const response = yield api.get("/v2/analytics/channel/google-ads/get-client-id");
        yield put({
            type: `${channelType}.GET_CLIENT_ID_SUCCESS`,
            payload: response.data.clientId
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_CLIENT_ID_FAILURE`,
            payload: error.response
        });
    }
}

function* connectAccount(args) {
    try {
        const clientId = yield select((state) => state[channelType].clientId);
        let token = localStorage.getItem("accessToken");
        let state = encodeURIComponent(JSON.stringify({
            access_token: token,
            success_redirect_url: args.payload.redirect_url + `?channel=${channelType}`,
            failure_redirect_url: args.payload.redirect_url + `?channel=${channelType}&failure`
        }));
        const scopes = [
            'https://www.googleapis.com/auth/adwords',
            'https://www.googleapis.com/auth/userinfo.email',
            'https://www.googleapis.com/auth/userinfo.profile'
        ];
        let authorizationUrl = `https://accounts.google.com/o/oauth2/v2/auth` +
            `?scope=${scopes.join(' ')}` +
            `&client_id=${clientId}&state=${state}` +
            `&redirect_uri=${process.env.REACT_APP_GOOGLE_ADS_REDIRECT_URI}` +
            `&access_type=offline&prompt=consent` +
            `&include_granted_scopes=true&response_type=code`;
        // `&client_id=980922366252-7huh0l4ukcd81ddsav4tcdg0erqkb76t.apps.googleusercontent.com`
        yield window.location.href = authorizationUrl;
    } catch (error) {
        yield put({
            type: `${channelType}.CONNECT_ACCOUNT_FAILURE`,
        });
    }
}

function* disconnectAccount() {
    try {
        yield api.post("/v2/analytics/google-ads/revoke");
        yield put({
            type: `${channelType}.DISCONNECT_ACCOUNT_SUCCESS`,
        });
        yield put({
            type: SELECT_ACCOUNT,
            payload: {
                channel: channelType,
            }
        });
        yield put({
            type: CHECK_AUTH,
        });
        mixpanelObj.track("remove_source", { data_source: channelType, channel_type: "ads" }, true);
    } catch (error) {
        yield put({
            type: `${channelType}.DISCONNECT_ACCOUNT_FAILURE`,
        });
    }
}

function* getAccountSummary(args) {
    try {
        yield put({ type: `${channelType}.START_LOADING` });
        const response = yield api.get(`/v2/analytics/google-ads/get-account-summary?refresh=${args.payload.refresh}`);
        const data = yield convertAccountsFormat(response.data.data)
        yield put({
            type: `${channelType}.GET_ACCOUNT_SUMMARY_SUCCESS`,
            payload: data
        });
        yield put({
            type: "UPDATE_ACCOUNTS_LENGTH",
            payload: { accLen: data.allAccounts.length, channelType }
        })
    } catch (error) {
        yield put({
            type: `${channelType}.GET_ACCOUNT_SUMMARY_FAILURE`,
            payload: error.response
        });
    }
}

function* getColumns(args) {
    try {
        const { id: tableName } = args.payload;
        const response = yield api.post("/v2/analytics/google-ads/get-fields", {
            table_name: tableName,
        });
        yield put({
            type: `${channelType}.GET_COLUMNS_SUCCESS`,
            payload: {
                tableName: tableName,
                data: response.data ?? [],
            },
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_COLUMNS_FAILURE`,
            payload: error.response
        });
    }
}

function* getFields() {
    try {
        const response = yield api.post("/v2/analytics/google-ads/get-fields");
        yield put({
            type: `${channelType}.GET_COLUMNS_SUCCESS`,
            payload: {
                data: response.data ?? [],
            },
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_COLUMNS_FAILURE`,
            payload: error.response
        });
    }
}

function* getTables() {
    try {
        const tablesResponse = yield api.post("/v2/analytics/google-ads/get-tables");
        const tables = tablesResponse.data.tables.map(table => ({
            id: table.name,
            name: table.uiName,
        }));
        // const fieldsResponse = yield api.post("/v2/analytics/google-ads/get-fields");
        // yield put({
        //     type: `${channelType}.GET_FIELDS_SUCCESS`,
        //     payload: fieldsResponse.data,
        // });
        yield put({
            type: `${channelType}.GET_TABLES_SUCCESS`,
            payload: tables,
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_FIELDS_FAILURE`,
            payload: error.response
        });
    }
}

function* getCurrency(args) {
    try {
        const response = yield api.post("/v2/analytics/google-ads/get-currency", getAccountId(args.payload));
        yield put({
            type: `${channelType}.GET_CURRENCY_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_CURRENCY_FAILURE`,
            payload: error.response
        });
    }
}

export default function* root() {
    yield all([
        takeLatest(`${channelType}.GET_CLIENT_ID`, getClientID),
        takeLatest(`${channelType}.CONNECT_ACCOUNT`, connectAccount),
        takeLatest(`${channelType}.DISCONNECT_ACCOUNT`, disconnectAccount),
        takeLeading(`${channelType}.GET_ACCOUNT_SUMMARY`, getAccountSummary),
        takeLeading(`${channelType}.GET_FIELDS`, getFields),
        takeEvery(`${channelType}.GET_COLUMNS`, getColumns),
        takeLatest(`${channelType}.GET_CURRENCY`, getCurrency),
    ])
}
