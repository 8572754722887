import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Box, Link } from "@mui/material";
import { useHistory } from "react-router-dom";
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';

import Pixis from "../../Assets/Images/logos_pixis.svg";
import AnchorLink from "react-anchor-link-smooth-scroll";

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, drawerWidth }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    }),
}));


function Header(props) {
    const history = useHistory();
    const pages = [
        { name: 'About Pixis', link: "https://pixis.ai/company/" },
        { name: 'Features', link: "" }
    ];

    return (
        <>
            <AppBar style={{ background: "#030B1C", padding: "10px 5vw", height: "10vh" }} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} drawerWidth="0" position="fixed"  >
                <Toolbar style={{ margin: 0, padding: 0, display: "flex" }}>
                    <div style={{ cursor: "pointer", display: 'flex', textAlign: "center" }}>
                        <img style={{ width: "40px" }} src={Pixis} alt="dots" />
                        <Typography style={{ color: "#0869FB", paddingLeft: "15px", fontWeight: '600', margin: 0, fontSize: '35px', fontFamily: 'Lato', paddingBotom: '3px' }} variant="h4" gutterBottom>
                            <span style={{ color: 'white' }}>Pixis</span>
                        </Typography>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                        <Box sx={{ flexGrow: 1, display: { md: 'flex' } }} style={{ display: "flex", justifyContent: "flex-end" }}>

                            <Link href="https://pixis.ai/company/" underline="none" color="inherit">
                                <Button
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                    style={{ margin: "0px 20px", textTransform: "none", fontFamily: "Lato", fontSize: "18px", fontWeight: "500" }}
                                >
                                    About Pixis
                                </Button>
                            </Link>
                            <AnchorLink href='#features'>
                                <Button
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                    style={{ margin: "0px 20px", textTransform: "none", fontFamily: "Lato", fontSize: "18px", fontWeight: "500" }}
                                >
                                    Features
                                </Button>
                            </AnchorLink>

                        </Box>
                        <Box>
                            <Button onClick={() => history.push("/login")}
                                style={{ marginRight: 8, textTransform: "none", margin: "0px 30px", fontFamily: "Lato", fontSize: "18px", fontWeight: "500" }}
                                variant="contained" color="primary" >
                                Sign In
                            </Button>
                        </Box>
                    </div>
                </Toolbar>
            </AppBar>
        </>
    );
}



export default Header;
