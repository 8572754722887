import { CREATE_FILTER_SUCCESS, DELETE_FILTER_SUCCESS, GET_ALL_FILTERS_SUCCESS, UPDATE_FILTER_SUCCESS } from "./type"

const INITIAL_STATE = {
    allFilters: []
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DELETE_FILTER_SUCCESS:
            return { ...state, allFilters: state.allFilters.filter(filter => filter.id !== action.payload.id) }
        case GET_ALL_FILTERS_SUCCESS:
            return { ...state, allFilters: action.payload };
        default:
            return state;
    }
};

export default reducer;

