import { useEffect } from "react";
import Grid from '@mui/material/Grid';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Divider from '@mui/material/Divider';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useState } from "react";
import UpdatePasswordModal from "../Modal/UpdatePasswordModal";
import { Button, TextField, Tooltip } from "@mui/material";
import { ChangePassword } from "../../api/user/saga";
import { getOrganizationList } from "../../api/super-admin";

const ManageProfile = ({ user, updateUser, isError, setError, message, setMessage }) => {
    const [userPassword, setUserPassword] = useState({ oldPswd: "", newPswd: "", againNewPswd: "" });
    const [changeNameValue, setChangeNameValue] = useState(user.name);
    const [updatePasswordModal, setUpdatePasswordModal] = useState(false);
    const [isUserWantToChangeName, setUserWantToChangeName] = useState(false);
    const [organizations, setOrganizations] = useState([]);

    const updateUserName = () => {
        updateUser({ name: changeNameValue });
        setUserWantToChangeName(false);
    }
    const validatePassword = (newPassword, reEnterNewPassword) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (newPassword.length === 0) {
            return { err: true, msg: "Password is required" };
        }
        if (!passwordRegex.test(newPassword)) {
            return { err: true, msg: "Invalid Password (minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character)" };
        }
        if (reEnterNewPassword.length === 0) {
            return { err: true, msg: "Confirm password is required" };
        }
        if (newPassword !== reEnterNewPassword) {
            return { err: true, msg: "Passwords do not match" };
        }
        return { err: false, msg: "" };
    }
    const updateUserPassword = async () => {
        const isPswdValid = validatePassword(userPassword.newPswd, userPassword.againNewPswd);
        if (isPswdValid.err) {
            setError(true);
            setMessage(isPswdValid.msg);
            return;
        }
        ChangePassword({ old_password: userPassword.oldPswd, new_password: userPassword.newPswd }).then((response) => {
            setError(false);
            setMessage("password updated successfully");
            setTimeout(() => {
                toggleUpdatePasswordModal();
            }, 2000);
        }).catch((err) => {
            setError(true);
            setMessage(err.data.message);
        })
    }
    const toggleUpdatePasswordModal = () => {
        setUpdatePasswordModal(!updatePasswordModal);
        setUserPassword({ oldPswd: "", newPswd: "", againNewPswd: "" });
        setMessage("");
        setError(false)
    }
    const getAllOrganizationsList = () => {
        if (user?.isExternalEditor === true) {
            getOrganizationList().then(_organizations => {
                setOrganizations(_organizations);
            });
        }
    }

    const mappedRole = new Map([["editor", { role: "Editor", des: "Accessible to modify the reports" }], ["superadmin", { role: "Super Admin", des: "Accessible to add and manage the data sources and organization members" }], ["admin", { role: "Admin", des: "Accessible to manage the data sources" }], ["viewer", { role: "Viewer", des: "Accessible to view the reports" }], ["externaleditor", { role: "External Editor", des: "Accessible to modify other org's reports as well" }]])

    useEffect(() => {
        getAllOrganizationsList();
    }, []);

    return (
        <div className="main_container" style={{ padding: "0px" }} >
            <Grid className="gridItem" container>
                {/*My profile*/}
                <Grid xs={12} style={{ display: "flex", alignItems: "center" }}>
                    <Grid item={2}>
                        <AccountCircleIcon style={{ color: "#6D8AFF", width: "30px", height: "30px" }} />
                    </Grid>
                    <Grid item={2} sx={{ pl: 1 }} xs={10}>
                        <h4><b>{user.name}</b></h4>
                        <p style={{ lineHeight: "0.5", color: "rgba(0, 0, 0, 0.5)" }}>{user.email}</p>
                    </Grid>
                </Grid>
                <Grid xs={12} sx={{ mt: 1.5 }}>
                    <Divider />
                </Grid>
                <Grid xs={12} sx={{ mt: 3 }}>
                    <Grid xs={12}>
                        <Grid container>
                            <Grid xs={4}>
                                <h4><b>Name</b></h4>
                            </Grid>
                            <Grid xs={4}>
                                <h4><b>Email</b></h4>
                            </Grid>
                            <Grid xs={4}>
                                <h4><b>Security</b></h4>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} sx={{ mt: 1 }}>
                    <Grid container>
                        <Grid xs={4}>
                            {
                                isUserWantToChangeName ? <TextField
                                    style={{ width: '80%' }}
                                    id="outlined-basic"
                                    value={changeNameValue}
                                    onChange={(e) => { setChangeNameValue(e.target.value) }}
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                    InputProps={{
                                        endAdornment: <CheckCircleIcon style={{ color: "#eee" }} />
                                    }}
                                    variant="outlined"
                                    size="small"
                                /> : <p style={{ color: "rgba(0, 0, 0, 0.5)" }}>{user.name}</p>
                            }

                        </Grid>
                        <Grid xs={4}>
                            <p style={{ color: "rgba(0, 0, 0, 0.5)" }}>{user.email}</p>
                        </Grid>
                        <Grid xs={4}>
                            <TextField
                                style={{ width: '80%' }}
                                id="outlined-basic"
                                size="small"
                                type="password"
                                value="raj.kiran@pixis.ai"
                                InputLabelProps={{ shrink: true, style: { fontWeight: "bolder" } }}
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                inputProps={{ style: { color: "rgba(0, 0, 0, 0.5)" } }}
                                variant='standard'
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} sx={{ mt: 0 }}>
                    <Grid container sx={{ justifyContent: 'space-between' }}>
                        <Grid xs={4}>
                            {isUserWantToChangeName === false && <p style={{
                                color: "#0869FB", cursor: "pointer"
                            }}
                                onClick={() => setUserWantToChangeName(!isUserWantToChangeName)}
                            >
                                <b>Change name</b>
                            </p>}
                            {isUserWantToChangeName === true && <Button style={{
                                marginTop: '10px', textTransform: 'none',
                                color: "white", cursor: "pointer", border: "1px solid #eee", backgroundColor: "#0869FB"
                            }}
                                onClick={() => updateUserName()}
                            >
                                <b>Save changes</b>
                            </Button>}
                        </Grid>
                        <Grid xs={4}>
                            <p style={{ color: "#0869FB", cursor: "pointer" }} onClick={() => setUpdatePasswordModal(!updatePasswordModal)}><b>Change password</b></p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} sx={{ mt: 2 }}>
                    <Grid xs={12}>
                        <Grid container>
                            <Grid xs={4}>
                                <h4><b>Domain</b></h4>
                            </Grid>
                            <Grid xs={4}>
                                <h4><b>Company Name</b></h4>
                            </Grid>
                            <Grid xs={4}>
                                <h4><b>Role</b></h4>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} sx={{ mt: 1 }}>
                    <Grid xs={12}>
                        <Grid container>
                            <Grid xs={4}>
                                <p style={{ color: "rgba(0, 0, 0, 0.5)", cursor: "pointer" }}>
                                    {user.company_domain}
                                </p>
                            </Grid>
                            <Grid xs={4}>
                                <p style={{ color: "rgba(0, 0, 0, 0.5)", cursor: "pointer" }}>
                                    {user.company_name}
                                </p>
                            </Grid>
                            <Grid xs={4}>

                                <p style={{ color: "rgba(0, 0, 0, 0.5)", cursor: "pointer" }}>
                                    {mappedRole.get(user.role).role}
                                    {(user.role !== "externaleditor" && user.isExternalEditor) &&
                                        <Tooltip title={organizations.map(org => org.name).filter(orgName => orgName !== user.company_name).join(", ")} placement="top">
                                            <span>{("/" + mappedRole.get("externaleditor").role)}</span>
                                        </Tooltip>
                                    }
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <UpdatePasswordModal
                modal={updatePasswordModal}
                setModal={setUpdatePasswordModal}
                updateUserPassword={updateUserPassword}
                userPassword={userPassword}
                setUserPassword={setUserPassword}
                isError={isError}
                message={message}
                toggleUpdatePasswordModal={toggleUpdatePasswordModal}
            />
        </div>
    )
}

export default ManageProfile;
