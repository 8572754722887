import React, { useState } from "react";
import { Close } from "@mui/icons-material";
import { Button, Grid, Modal, TextField } from "@mui/material";

const classes = {
  trigger: {
    border: "none",
    background: "transparent",
    padding: 0,
    margin: 0,
    width: "100%",
  },
  popover: {
    background: "#FFFFFF",
    borderRadius: "10px",
    margin: "10px 0",
    border: "none",
    width: "400px",
    position: "fixed",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
  },
  header: {
    background: "#FFFFFF",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
    borderRadius: "10px 10px 0px 0px",
    padding: "1rem",
    display: "flex",
    gap: "2rem",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "24px",
    color: "#1C1E21",
    margin: 0,
  },
  close: {
    background: "#F2F2F2",
    borderRadius: "4px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0.2rem",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
  },
  divider: {
    height: "0px",
    border: "1px solid #F2F2F2",
    width: "100%",
    marginBottom: "20px",
  },
  value: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#5F6771",
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "1rem",
    borderTop: "1px solid #F2F2F2",
    gap: "0.5rem",
  },
  button: {
    textTransform: "capitalize",
  },
  label: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "17px",
    color: "#5F6771",
  },
  field: {
    background: "#FFFFFF",
    border: "1px solid #EFEFEF",
    borderRadius: "8px",
    width: "100%",
    padding: "0.5rem 0.75rem",
    "& input": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "17px",
    },
    "& input::before, & input::after, & input:hover::before, &input:hover::after":
    {
      border: "none",
    },
  },
};

const colRegex = /^[a-zA-Z]+$/;

export default function StartDataPopover({
  heading = "Start data from",
  data = {},
  onSave,
  children,
}) {
  const [row, setRow] = useState(data.row);
  const [column, setColumn] = useState(data.column);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <button style={classes.trigger} type="button" onClick={handleOpen}>
        {children}
      </button>
      <Modal open={open} onCancel={handleClose}>
        <div style={classes.popover}>
          <header style={classes.header}>
            <h2 style={classes.title}>{heading}</h2>
            <button
              aria-label="close"
              style={classes.close}
              onClick={handleClose}
            >
              <Close />
            </button>
          </header>
          <section style={classes.content}>
            <h4 style={classes.value}>
              {column || "{Column}"}:{row || "{Row}"}
            </h4>
            <hr style={classes.divider} />
            <Grid container xs={12}>
              <Grid item xs={3} flex alignItems="center" mb="10px">
                <span style={classes.label}>Column No.</span>
              </Grid>
              <Grid item xs={9} mb="10px">
                <TextField
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  style={classes.field}
                  placeholder="{column}"
                  value={column}
                  onChange={(event) => {
                    let value = event.target.value;
                    if (!value || colRegex.test(value))
                      setColumn(value.toUpperCase());
                  }}
                />
              </Grid>
              <Grid item xs={3} flex alignItems="center" mb="10px">
                <span style={classes.label}>Row No.</span>
              </Grid>
              <Grid item xs={9} mb="10px">
                <TextField
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  style={classes.field}
                  placeholder="{row}"
                  value={row}
                  onChange={(event) => {
                    let value = event.target.value;
                    if (!value || Number(value)) setRow(value);
                  }}
                />
              </Grid>
            </Grid>
          </section>
          <footer style={classes.footer}>
            <Button style={classes.button} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              style={classes.button}
              variant="contained"
              onClick={() => {
                onSave({ row, column });
                handleClose();
              }}
            >
              Apply
            </Button>
          </footer>
        </div>
      </Modal>
    </>
  );
}
