import { ChannelType } from "../../models/channel";

export const channelType = ChannelType.ShopifyAds;

export function getAccountId(account) {
    return {
        storeName: account?.storeName,
    };
}

function getSubFilters(filterList) {
    let subFilters = [];
    var startIndex = 0;
    for (let i = 0; i < filterList.length; i++) {
        if (filterList[i].boolOperator === "AND") {
            subFilters.push(filterList.slice(startIndex, i + 1));
            startIndex = i + 1;
        }
    }
    if (startIndex < filterList.length) { subFilters.push(filterList.slice(startIndex)); }
    return subFilters;
}

export function getFilterObject(filter) {
    const filters = [...filter.dimensionFilters, ...filter.metricFilters];
    if (filters.length === 0) { return {}; }

    return filter.id ?
        {
            filterGrouping: {
                filterId: filter.id
            }
        } :
        {
            conditions: {
                operator: "AND",
                conditionGroups: getSubFilters(filters).map(subFilters => ({
                    operator: "OR",
                    conditions: subFilters.map(filter => ({
                        fieldName: filter.filter.id,
                        operator: filter.operator.type,
                        value: filter.value,
                    })),
                })),
            },
        }
}

export function getApiObject(metrics, dimensions, filter, table) {
    return {
        metrics: metrics,
        dimensions: dimensions,
        ...getFilterObject(filter),
    };
}

const negativeFields = ["returns", "discounts", "returned_item_quantity"];

export function transformData(data) {
    [...data.leftMetrics, ...(data.rightMetrics ?? [])].forEach(m => {
        if (negativeFields.find(f => f === m.id)) {
            data.data.forEach(d => d[m.name] = - d[m.name]);
        }
    });
}

export const properties = {
    dateDimension: { id: "day", name: "Day" },
    unitKey: "dataType",
    filterOperators: [
        { label: "Equal", type: "EQUALS", dataType: ["INTEGER", "STRING"] },
        { label: "Not Equal", type: "NOT_EQUALS", dataType: ["INTEGER", "STRING"] },
        { label: "Greater Than", type: "GREATER_THAN", dataType: ["INTEGER", "FLOAT"] },
        { label: "Greater Than or Equal", type: "GREATER_THAN_OR_EQUAL", dataType: ["INTEGER", "FLOAT"] },
        { label: "Less Than", type: "LESS_THAN", dataType: ["INTEGER", "FLOAT"] },
        { label: "Less Than or Equal", type: "LESS_THAN_OR_EQUAL", dataType: ["INTEGER", "FLOAT"] },
        { label: "Contains", type: "CONTAINS", dataType: "STRING" },
        { label: "Not Contains", type: "NOT_CONTAINS", dataType: "STRING" },
        { label: "In", type: "IN_LIST", dataType: "STRING" },
        { label: "Not In", type: "NOT_IN_LIST", dataType: "STRING" },
        { label: "Starts With", type: "STARTS_WITH", dataType: "STRING" },
        { label: "Ends With", type: "ENDS_WITH", dataType: "STRING" },
    ],
}
