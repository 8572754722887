import noaccess from "../../Assets/Images/noaccess.png";
import { Typography, Grid, Stack } from "@mui/material";
import CloseIcon from "../Common/CloseIcon";
import { logout } from "../../api/auth/action";
import { connect } from "react-redux";
import { useEffect } from "react";

function NoAccess({ logout }) {
    useEffect(() => {
        logout();
        if (window.location.href.search("blocked") < 0) {
            window.location.href = "/login";
        }
    }, []);

    return (
        <>
            <Grid container
                spacing={4}
                direction="column"
                alignItems="center"
                style={{ paddingTop: '75px' }}
            >
                <CloseIcon />
                <Grid item xs={12}>
                    <Stack spacing={0} alignItems="center">
                        <img src={noaccess} alt="Pixis icon" />
                        <Stack alignItems="center">
                        <Typography variant="h5">
                              "Doesn't have access for the Pixis Analytics platform. Please
                        </Typography>
                        <Typography variant="h5" style={{ fontWeight: 700 }}>
                              contact to your admin."
                        </Typography>
                    </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}

const mapDispatchToProps = dispatch => ({
	logout: () => dispatch(logout()),
})

export default connect(null, mapDispatchToProps)(NoAccess)
