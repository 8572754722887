import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { ReactComponent as Expand } from "../../Assets/Icons/Left.svg";
import { GetChannel } from "../../models/channel";
import { useSelector } from "react-redux";
import FilterAccod from "./FilterAccord";

const DataSourceAccord = ({ subGroup, chart, editFilter, applyFilter, deleteFilter }) => {

    const channel = GetChannel(subGroup[0][0]?.dataSource);
    const state = useSelector(state => channel.getState(state));

    return (
        <Accordion sx={{
            marginBottom: "24px", borderRadius: "16px !important", boxShadow: "none", background: "#F6F7F8",
            '&:before': { display: 'none', },
            "& .Mui-expanded": { height: "auto" }
        }}>
            <AccordionSummary
                sx={{ width: "100%", background: "#F6F7F8", borderRadius: "16px", display: "flex", alignItems: "center", }}
                expandIcon={<Expand />}
            >
                <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                    <div style={{ height: "40px", width: "40px", background: "#FFF", borderRadius: "10px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <img style={{ height: "24px", width: "24px" }} src={channel?.icon} alt={channel?.title} />
                    </div>
                    <Typography style={{ fontSize: "18px", fontWeight: "600" }}>
                        {state?.name ?? channel?.title}
                    </Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                {
                    subGroup.map(accountFilters => {
                        let account = accountFilters[0]?.audiences[0]?.metaData
                        return (
                            <>
                                <Typography style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "700", lineHeight: "24px", marginBottom: "16px" }}>
                                    Ad Account : {channel.isBlend ? state.audiences?.map((audience) => audience?.[0]?.metaData?.title).join(", ") : `${account?.title} (${account?.id})`}
                                </Typography>
                                {accountFilters.map((appliedFilter) => {
                                    return <FilterAccod chart={chart} appliedFilter={appliedFilter}
                                        editFilter={editFilter} applyFilter={applyFilter} deleteFilter={deleteFilter}
                                    />
                                })
                                }
                            </>
                        )
                    })
                }
            </AccordionDetails>
        </Accordion>
    )
}
export default DataSourceAccord;