import AppBar from "../Dashboard/AppBar";
import Grid from '@mui/material/Grid';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import LeftBar from "../LeftBar/LeftBar";

const TermsAndConditions = () => {
     const history = useHistory();
     return (
          <>
               {/* <AppBar/> */}
               <LeftBar />
               <div style={{ padding: "24px 24px 10px 124px", height: "100vh" }}>
                    <Box sx={{
                         background: "#FFF", borderRadius: "12px", width: "100%", padding: "24px", height: "calc( 100% - 15px )", overflow: "auto"
                    }}>
                         <Typography style={{ fontFamily: "Inter", fontWeight: "600", fontSize: "20px", lineHeight: "32px" }}>Terms & Conditions</Typography>
                         <p style={{ borderBottom: "1px solid #EAEAEC", width: "100%", marginTop: "24px", marginBottom: "24px" }}></p>

                         <p>
                              <b>1:Service Terms</b>
                              <br />
                              1.1 By accessing the Internet site located at www.Pixis.com/analytics (“Site”), you agree to the following Terms and Conditions (the “Terms and Conditions”). The Terms and Conditions, including any other agreement with us governing your use of the Services (the “Agreement”), set forth the legally binding terms and conditions which apply to your use of the services provided by Pixis and its affiliates (collectively “Pixis, Company, we, our, us”) through our web sites and applications (“Service (s)”).
                              <br />
                              1.2. The Company may amend the Terms and Conditions from time to time at its sole discretion. The Company will post a notice on the Site any time the Terms and Conditions have been changed or updated. You are responsible for reviewing the Terms and Conditions before using the Service and rereading them whenever you receive notice that the Terms and Conditions have been updated. If you find the Terms and Conditions unacceptable, you must immediately leave the Site and cease all Service and Site use.
                              <br />
                              1.3. The Site is not intended for children under 18. You must be 18 years old to use the Site and/or the Services. If you are under 18 years of age, do not use the Services and do not provide any personal information to us. By using the Service, you represent that you are at least 18 years old, legally able to use the Service, and will use the Service solely for your business purposes. If you are under 18 years old, you represent that you are at least 16 years old and that your parent or legal guardian consents to using the Service.
                              <br /><br />
                         </p>
                         <b>2. Services</b><br />
                         2.1. Pixis Analytics is a Business to Business (B2B) analytics reporting solution that helps marketing companies or departments collect their analytics data and create visual online reports (the “Reports”).
                         <br />
                         2.2. The Site and the Services are solely directed at the business customers (marketing companies, departments, or professionals). Nothing in these Terms and Conditions is or may be deemed an offer to or an agreement with a consumer.
                         <br /><br />
                         <p>
                              <b>3. Account Registration</b><br />
                              3.1 You must share access to platforms you’d like to see the Reports for. <br />
                              3.1.1. You must register to use features of the Services.<br />
                              3.2. If you represent a business entity (a marketing company, department, or other) when registering, you declare that you have the authority to bind such a business entity and that your acceptance of the Terms and Conditions will be treated as acceptance by that business entity.
                              <br />
                              3.3. When you register, you agree to (a) provide accurate, current, and complete information about you and a business entity you represent, as may be prompted by registration forms on the Service (the “Registration Data”); (b) maintain the security of any logins, passwords, or other credentials that you select or that are provided to you for the use of the Service; (c)
                              maintain and promptly update the Registration Data, and any other information you provide to us, and to keep all such information accurate, current, and complete; and (d) notify us immediately of any unauthorized use of your account or any other breach of security by emailing us at analytics@Pixis.com.
                              <br /><br />
                         </p>
                         <p>
                              4.1. Pixis assumes no liability whatsoever (a) if due to any reasons other than the fault of Pixis, the data or other content ( “Data Sources”) (or any of them) are not available; or (b) for the accuracy of any data received from the Data Sources.
                              <br />
                              4.2 You are solely responsible for ascertaining that you have the right to use the Data Source for gathering and processing any such data by using the Service. You must obtain any such consents and authorizations as may be needed from time to time in relation to such data or other content and their processing by using the Service.
                              <br /><br />
                         </p>
                         <p>
                              <b>5. Content</b><br />
                              5.1. Content<br />
                              5.1.1 You are legally responsible for all information, data, text, software, or other materials entered into the Reports, including the data received from the Data Sources and the data entered by you to the Reports directly, as well as data uploaded, posted, stored and/or shared online in connection with your use of the Reports or the Services (the “Content”). Pixis is not responsible for your Content; you are solely responsible for any Content that may be lost or unrecoverable through your use of the Services. You are encouraged to back up your Content regularly and frequently.
                              <br />
                              5.1.2 Pixis does not publish or otherwise use your Content, except to the extent required to provide you the Services. To provide you the Services, for legal purposes, Pixis has to get a limited right to your Content. Therefore you, at this moment, grant the Company, its affiliates, officers, directors, employees, agents, and representatives a worldwide, perpetual, royalty-free, non-exclusive, and limited license to use the Content solely to enable your use of the Service, and to enable the Service to provide you with the Reports, including without limitation the right to store, copy, transmit, display, reproduce, edit, and reformat your Content for the purposes defined above.
                              <br />
                              5.1.3 You represent and warrant that you own the rights to the Content or are otherwise authorized to enter, post, display, perform, transmit, or otherwise use the Content.
                              <br />
                              5.1.4 YouTube terms of services. By authorizing the Service to access your YouTube account, you agree to YouTube's terms of service.
                              <br />
                              5.2. Confidential Material<br />
                              5.2.1 You own your Content, and we treat it as confidential information. Pixis neither discloses nor communicates in any way to any third party any of your Content unless Pixis is obliged to disclose such information by law, of which then we will notify you in writing as soon as possible.
                              <br />
                              5.2.2 You are responsible for your use of the Services, for any Content, and for any liability deriving from using the Content that violates the confidentiality or privacy interests or makes unauthorized disclosure of proprietary business information or trade secrets of you, the business entity you represent, or any third party.
                              <br />
                              5.2.3 Pixis does not publish or otherwise use your Content, except to the extent required to provide you the Services. However, you are solely responsible for the confidentiality of the Content (if applicable), and Pixis bears no responsibility for maintaining the confidentiality of the Content, even in cases if the Content is marked as “private,” “unpublished,” “confidential,” or otherwise.
                              <br />
                              5.3. Compliance<br />
                              5.3.1 You represent and warrant that the Content does not violate or infringe any rights of any third party, including contractual rights, copyrights, trade secrets, proprietary business information concerning processes and systems, and rights of privacy. You acknowledge and agree that no other persons, parties, or entities have or have any right, title, or interest, including copyright, in or to the Content, or that in the event other persons do have a such right, title, or interest, that you are duly authorized to use their Content in connection with your use of the Service.
                              <br />
                              5.3.2 You are strictly and solely responsible for compliance with all laws, legal regulations, agreements, procedures, or other obligations related to the Content, including, but not limited to, those relevant trade secret, non-disclosure, proprietary business, personal data processing, including the international transfer of personal data, and protection of images.
                              <br />
                              5.4. Restricted Content
                              You shall not make the following types of Content available. You agree not to enter, upload, download, display, perform, transmit, or otherwise use about the Site, Services, or Reports any Content that (a) is libelous, defamatory, obscene, pornographic, abusive, or threatening; (b) advocates or encourages conduct that could constitute a criminal offense, give rise to civil liability, or otherwise violate any applicable local, state, national, or foreign law or regulation; or (c) discloses personal health information. Pixis reserves the right to terminate your receipt, transmission, or other distribution of any such material using the Service and, if applicable, to delete any such material from its servers. Pixis intends to cooperate fully with any law enforcement officials or agencies in the investigation of any violation of the Agreement or of any applicable laws.
                              <br />
                              5.5. Comments
                              Any questions, comments, suggestions, ideas, feedback, or other communication provided by you to Pixis (the Comments) will not be treated as confidential by Pixis, and you hereby give Pixis permission to reproduce, display, edit, publish or otherwise use such Comments as Pixis deems appropriate, for any and all commercial and/or non-commercial use, at Pixis’s sole discretion.
                         </p>
                         <p>
                              <b>6. Intellectual property</b><br />
                              6.1. Pixis’s intellectual property<br />
                              6.1.1. Pixis Analytics® is a registered trademark and is protected by laws. We do not authorize you to use “Pixis” or any similar or related marks for any use that is likely to cause confusion on the part of, to cause mistake by, or to deceive the public as to any affiliation, connection, association, origin, sponsorship, approval or endorsement by or with Pixis.
                              <br />
                              6.1.2. Any and all individual elements within the Site, the Service, and the Reports, including, without limitations, the trademarks, logos, names, texts, infographics, pictures, drawings, databases, and any other material included in the Reports by Pixis or contained on the Site, and the layout and design of the Reports and the Site are exclusively owned by Pixis.
                              <br />
                              6.1.3. Nothing contained on the Site, in the Terms and Conditions, in the Agreement, or otherwise should be understood as granting you a license to use any intellectual property owned by Pixis or by any third party. You agree not to copy, modify, display, mirror, frame, or otherwise use any of the above without a prior explicit written consent of Pixis, except for cases explicitly permitted by the Agreement.
                              <br />
                              6.1.4. Further, you now agree that any and all reports created by combining your Content with the Service will not be considered to be works of joint authorship or derivative works from your Content.
                              <br />
                              6.2. Marketing & Promotion<br />
                              You hereby grant to Pixis a perpetual, irrevocable, non-exclusive, royalty-free, worldwide right and license to use your name and logo or both (or the name and logo of the business entity you represent, as the case may be) in customer lists that may be published as part of Pixis’s marketing and promotional efforts.
                              6.3. Feedback<br />
                              Any materials, including but not limited to comments, suggestions, ideas, or other information, provided by you in the form of email or other submissions to us (the Feedback) are non-confidential and you hereby grant to our subsidiaries and us and affiliates a nonexclusive, royalty-free, perpetual, irrevocable, and fully sub-licensable right to use your Feedback for any purpose without compensation or attribution to you.
                              <br /><br />

                         </p>
                         <p>
                              <b>7. Use Restrictions</b><br />
                              7.1. Your right to use the Service is personal (i.e., granted to you or the business entity you represent), limited to your internal business purposes, non-transferable, non-exclusive, revocable, and subject to your compliance with the Terms and Conditions and the Agreement at all times, including your timely payment of all applicable fees for the Subscription Plan.
                              <br />
                              7.2. Without limiting the generality of the foregoing, you will not: (a) access content or data not intended for you, or log onto a server or account that you are not authorized to access; (b) attempt to probe, scan, or test the vulnerability of the Service, the Site, or any associated system or network, or to breach security or authentication measures without proper authorization; (c) interfere or attempt to interfere with service to any user, host, or network, including, without limitation, by means of submitting a virus to the Site or Service, overloading, “flooding,” “spamming,” “mail bombing,” or “crashing”; (d) use the Site or Service to upload, host or transmit the following items, including, without limitation,
                              unsolicited e-mail, SMS messages, worms, Trojan horses or code of a destructive nature, promotions or advertisements for products or services; (e) forge any TCP/IP packet header or any part of the header information in any e-mail or in any posting using the Service; or (f) attempt to modify, reverse-engineer, decompile, disassemble, or otherwise reduce or attempt to reduce to a human-perceivable form any of the source code used by the Company in providing the Site or Service. Any violation of system or network security may subject you to civil and/or criminal liability. Pixis may at any time suspend or terminate your access to the Service if we have reason to believe that you are not complying with the Terms and Conditions or the Agreement or you are otherwise abusing the Service.
                              <br /><br />
                         </p>
                         <p><b>8.Your responsibilities</b></p>
                         8.1. You will ensure the Registration Data provided to us is valid at all times, and you will keep your data accurate and up-to-date.<br />

                         8.2. You will not transfer your Pixis account to anyone without first getting express written permission from the Company.<br />

                         8.3. In addition to the prohibited uses outlined in the Terms and Conditions and Agreement, you will also not use the Site or Services for any unlawful purposes or to conduct any unlawful activity, including but not limited to fraud, embezzlement, money laundering, and any other activity prohibited by law or the Agreement.
                         <br />
                         8.4. You will not use the Site or Services if you are located in a country where such use is prohibited by the applicable law.
                         <br />
                         8.5. You will not use the Site or Services to impersonate another person.<br /><br />
                         <p>
                              <b>9. Alleged violations</b><br />
                              To ensure that the Company provides a high-quality experience for you and for other users of the Site and the Service, you agree that Company or its representatives may access your account and activity on a case-by-case basis to investigate complaints or allegations of abuse, infringement of third party rights, or other unauthorized uses of the Site or the Service. The Company does not intend to disclose the existence or occurrence of such an investigation unless required by law, but Company reserves the right to terminate your use of the Service and/or the Site, your account, or your access to the Site, terminate the Agreement and revoke all the licenses granted under the Agreement immediately, with or without notice to you, if the Company believes that you have violated any term of the Agreement, furnished the Company with false or misleading information, or interfered with the use of the Site or the Service by others.
                              <br /><br />
                         </p>
                         <p>
                              <b>10. No warranties</b><br />
                              The Company is making the Site and the Service available “as is” without warranty of any kind. You assume the risk of any and all damage or loss from use of, or inability to use, the Site or the Service. To the maximum extent permitted by law, the Company expressly disclaims any and all warranties, express or implied, regarding the Site and the Service, including, but not limited to, any implied warranties of merchantability, fitness for a particular purpose, or non-infringement. The Company does not warrant that the Site or the Service will meet your requirements or that the operation of the Site or the Service will be
                              uninterrupted, timely, virus or error-free, or that data displayed by the Service, including the Reports, will be accurate or reliable.
                              <br /><br />
                         </p>
                         <p>
                              <b>11. Limited liability</b><br />
                              The Company’s liability to you is limited. To the maximum extent permitted by law, in no event shall the Company, its affiliates, or suppliers be liable for damages of any kind (including, but not limited to, special, incidental, or consequential damages, lost or compromised data, lost profits or revenue due to Service interruption or computer failure, regardless of the foreseeability of those damages) arising out of or in connection with your use of the Site or Services. You, at this moment, agree that the Company’s liability to you will not exceed any fees paid by you to the Company for the Service. This limitation shall apply regardless of whether the damages arise out of breach of contract, tort, or any other legal theory or form of action.
                              <br /><br />
                         </p>
                         <p>
                              <b>12. Links and affiliated sites</b>
                              Pixis has no control over and liability for third-party websites or materials. Pixis works with several partners and affiliates whose Internet sites may be linked with or from the Site. Because neither Pixis nor the Site has control over the content and performance of these partner and affiliate sites, Pixis makes no guarantees about the accuracy, currency, content, or quality of the information provided by such sites. Pixis assumes no responsibility for unintended, objectionable, inaccurate, misleading, or unlawful content that may reside on those sites. Similarly, from time to time, in connection with your use of the Site or Service, you may have access to content items (including, but not limited to, websites) that third parties own. You acknowledge and agree that Pixis makes no guarantees about, and assumes no responsibility for, the accuracy, currency, content, or quality of this third party content, and that, unless expressly provided otherwise, the Terms and Conditions and the Agreement shall not govern your use of any and all third party content.
                              <br /><br />
                         </p>
                         <p>
                              <b>13. Indemnifications</b><br />
                              You hereby agree to indemnify and hold harmless the Company, its assignees, and/or successors in interest from and against any and all claims, damages, liabilities, costs, and expenses, including legal expenses and reasonable counsel fees, arising out of any breach or alleged breach of your warranty, representation or agreement, express or implied, made by you herein.
                              <br /><br />
                         </p>
                         <p>
                              <b>14. Cancellation and termination</b><br />
                              14.1. The Services and the Agreement may be terminated by Pixis, without cause, at any time.<br />

                              14.2. Pixis may terminate the Services and the Agreement at any time, without penalty, obligation to refund, and without notice, if you fail to comply with any of the terms of the Agreement.<br />
                              <br />
                              14.3. Notice of termination of the Services and the Agreement by Pixis may be sent to the contact email associated with your account. Upon termination, Pixis may delete all data, files, or other information that is stored in your account, and it’s your responsibility to retrieve and backup all account contents before termination.
                              <br />
                              14.4. The Services and the Agreement may be terminated by you, without cause, by giving Pixis fourteen (14) days' written notice in advance. The notice should be duly signed by you and sent to analytics@Pixis.com. Suppose you terminate the Agreement before the expiry of the relevant Subscription Plan. In that case, you shall pay the Company compensation in the amount equal to the discount granted to you, as indicated in the Agreement (the written subscription agreement). You now agree that we will bill the amount of such compensation to the credit card or PayPal account provided to us.
                              <br /><br />
                         </p>
                         <p>
                              <b>15. Severability; Waiver; Assignment</b>
                              15.1. If, for whatever reason, a court of competent jurisdiction finds any term or condition in these Terms and Conditions or the  Agreement to be unenforceable, all other terms and conditions will remain unaffected and in full force and effect. No breach of any provision of these Terms and Conditions or the Agreement shall constitute a waiver of any prior, concurrent, or subsequent breach of the same or any other provisions of these Terms and Conditions or the Agreement. No waiver shall be effective unless made in writing and signed by an authorized representative of the waiving party.
                              <br />
                              15.2. Pixis may assign or delegate some or all of its rights and obligations under these Terms and Conditions or the Agreement to a third party at its sole discretion.
                              <br />
                              15.3. You may not assign or delegate any of your rights and obligations under these Terms and Conditions or the Agreement without the written consent of Pixis.
                              <br /><br />
                         </p>
                         <p>
                              <b>16. Governing law and jurisdiction</b>
                              16.1. These Terms and Conditions shall be governed by and construed under California Law.
                              <br />
                              16.2. Any dispute, controversy, or claim arising out of or in connection with the Services, these Terms and Conditions, or the Agreement or any breach, termination, or validity of the Services or these Terms and Conditions or the Agreement shall be settled by our mutual negotiations. For any dispute with Pixis, you agree first to contact us at analytics@Pixis.com and attempt to resolve the dispute with us by mutual negotiations. If no agreement is reached within twenty (20) business days, the dispute, controversy, or claim shall be referred to the senior executives of both parties.
                              <br /><br />
                         </p>
                         <p>
                              <b>17. Modifications and amendments</b><br />
                              The Company may, in its sole discretion and without prior notice, (a) revise these Terms and Conditions; (b) modify the Site and/or the Service; and/or (c) discontinue the Site and/or Service at any time. The Company shall post any revision of these Terms and Conditions to the Site fourteen (14) days before revision shall be effective. You agree to review these Terms and Conditions and other online policies posted on the Site periodically to be aware of any revisions. You agree that your continued use of the Site and the Services after Pixis posts revised Terms and Conditions signifies your acceptance of the revised Terms and Conditions. We recommend reviewing these Terms and Conditions regularly to ensure you are updated as to any changes.
                              <br /><br />

                         </p>
                         <p>
                              <b>18. Privacy</b><br />
                              Pixis's current privacy statement is located at https://pixis.ai/privacy-policy/ (the “Privacy Policy”) and is incorporated into these Terms and Conditions. You can reach our DPO by email at dpo@pixis.ai to submit inquiries regarding personal information, privacy, and security.
                              Contact us If you have any questions about the Terms and Conditions, please contact us at <a htref="pixis-analytics-support@pixis.ai">pixis-analytics-support@pixis.ai </a>

                              <br />
                         </p>
                    </Box>
               </div>
          </>
     )
}
export default TermsAndConditions;