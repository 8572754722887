import { channelType } from "./transformer";

const INITIAL_STATE = {
    clientId: "",
    accountData: {},
    tables: null,
    dimensions: [],
    metrics: [],
    metricsUpdated: false,
    currency: null
};


const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case `${channelType}.GET_CLIENT_ID_SUCCESS`:
            return { ...state, clientId: action.payload.clientId };
        case `${channelType}.GET_CLIENT_ID_FAILURE`:
            return { ...state, clientId: "" };
        case `${channelType}.GET_ACCOUNT_SUMMARY_SUCCESS`:
            const username = action.payload.username;
            const email = action.payload.email;
            const accountList = action.payload.accountIds.map(data => ({
                title: data.advertiser_name,
                subtitle: data.advertiser_id,
                accountId: data.advertiser_id,
            }));
            return { ...state, accountData: { users: [{ username: username, email: email }], accountList: accountList } };
        case `${channelType}.START_LOADING`:
            return { ...state, accountData: { ...state.accountData, loading: true } };
        case `${channelType}.DISCONNECT_ACCOUNT_SUCCESS`:
        case `${channelType}.GET_ACCOUNT_SUMMARY_FAILURE`:
            return { ...INITIAL_STATE, clientId: state.clientId };
        case `${channelType}.GET_COLUMNS_SUCCESS`:
            const dimensions = action.payload.columns.filter(c => c.type === "DIMENSION");
            const metrics = action.payload.columns.filter(c => c.type === "METRIC");
            return { ...state, dimensions: dimensions, metrics: metrics, metricsUpdated: true };
        case `${channelType}.GET_CURRENCY_SUCCESS`:
            return { ...state, currency: action.payload.currency };
        default:
            return state;
    }
};

export default reducer;
