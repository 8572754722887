import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { List, Tabs, Tab, Link, TextField, Button, Box, Grid, Typography, InputAdornment, Avatar, Pagination, Tooltip } from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import "@fontsource/lato/300.css";
import "@fontsource/lato/400.css";
import "@fontsource/lato/700.css";
import TitleSubtitle from "./TitleSubtitle";
import { checkAuth } from "../../api/channel/action";
import { AdsList, AttributionList, ChannelType, GetChannel } from "../../models/channel";
import RemoveChannelAccountModal from "../Modal/RemoveChannelAccountModal"
import { getUser } from "../../api/user/action";
import { closeConnecting } from "../../api/shopify-ads/action";
import Loader from "../Common/Loader";
import { closeConnectingAppsflyer } from "../../api/appsflyer/action";
import { closeConnectingAdjust } from "../../api/adjust/action";
import Logout from "../../Assets/Icons/logout-icon.svg"
import Refresh from "../../Assets/Icons/refresh-icon.svg"
import Tag from "../../Assets/Images/tag.svg"
import { ReactComponent as NoReports } from "../../Assets/errorMsgImages/NoAuditData.svg";
import LogoutModal from "../Modal/LogoutModal";
import SelectingChannelErrorModal from "../Modal/SelectingChannelErrorModal";
import ShopifyConnectModal from "../Modal/ShopifyConnectModal";
import AppsflyerConnectModal from "../Modal/AppsflyerConnectModal";
import AdjustConnectModal from "../Modal/AdjustConnectModal";
import SearchBar from "../Common/SearchBar";
import { handleMixPanelData, EVENTS } from "../../utils/mixpanelV2";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function ManageDataSources(props) {
    const query = new Map(props.location.search.replace("?", "").split("&").map(q => q.split("=")));
    const history = useHistory();
    const { authenticatedAccounts, selectedAccounts, checkAuth, user, getUser, closeShopify, closeAppsflyer, closeAdjust } = props;
    const [filteredAccountList, setFilteredAccountList] = React.useState([]);
    const [modal, setModal] = React.useState(false);
    const [logoutModal, setLogOutModal] = React.useState(false);
    const [errorModal, setErrorModal] = React.useState(query.has("failure") || query.has("error"));
    const disconnectAccountPayload = React.useRef({});
    const selectedAccountTypes = Object.keys(selectedAccounts);

    const isChannelAuthenticated = channel => !!authenticatedAccounts.find(type => type === channel?.type);
    const isChannelSelected = channel => channel.type === channelType;

    const dispatch = useDispatch();
    const [channelType, setChannelType] = React.useState(
        query.get("channel") && GetChannel(query.get("channel")) ? query.get("channel")
            : query.get("state") && GetChannel(query.get("state")) ? query.get("state")
                : props.location?.state?.channelType && isChannelAuthenticated(GetChannel(props.location.state.channelType)) ? props.location.state.channelType
                    : selectedAccountTypes.length > 0 ? selectedAccountTypes[0]
                        : authenticatedAccounts.length > 0 ? authenticatedAccounts[0]
                            : AdsList[0].type ?? ChannelType.Default
    );
    const channel = GetChannel(channelType);
    const state = useSelector(state => channel.getState(state))
    const accountData = state.accountData;
    const selectedAccountData = selectedAccounts[channelType];
    const allChannelAccs = useSelector(state => state.channel.allChannelAccs);

    const [value, setValue] = React.useState(AttributionList.find(c => c.type === channelType) ? 1 : 0);
    const [accountTab, setAccountTab] = React.useState(0)

    const [page, setPage] = React.useState(0);
    const rowsPerPage = 20;

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    React.useEffect(() => {
        checkAuth();
        getUser();
        closeShopify();
        getCode();
        closeAppsflyer();
        closeAdjust();
    }, []);


    React.useEffect(() => {
        setFilteredAccountList(accountData.accountList ?? []);
    }, [accountData]);

    React.useEffect(() => {
        setPage(0);
    }, [filteredAccountList])

    const getCode = () => {
        const splitted = window.location.href.split("code=");
        if (splitted.length === 2 && splitted[1].includes(`&state=${channelType}`)) {
            const code = splitted[1].split(`&state=${channelType}`)[0];
            dispatch(channel.actions.sendCode({
                redirect_url: "managesources",
                code: code
            }));
        }
    }

    const getAccountId = item => Object.values(channel.transformer.getAccountId(item)).join("|");

    const totalAuthenticatedAds = AdsList.filter(channel => isChannelAuthenticated(channel)).length;
    const totalAuthenticatedAttributes = AttributionList.filter(channel => isChannelAuthenticated(channel)).length;

    const handleChange = (event, newValue) => {
        setChannelType(newValue ? AttributionList[0].type : AdsList[0].type)
        setValue(newValue);
    };

    const handleAccountTabChange = (event, newValue) => {
        setAccountTab(newValue)
    }

    const changeChannel = channel => {
        if (channel.isSupported) {
            setChannelType(channel.type);
            handleMixPanelData(EVENTS.choose_data_source, { data_source: channel.type });
        }
    }

    useEffect(() => {
        handleMixPanelData(EVENTS.view_data_sources);
    },[]);

    return (
        <div style={{ height: "100vh", background: "#F8F9FD", padding: "24px" }}>
            <Grid xs={12}>
                <div style={{ width: "100%", height: "64px", background: "#FFF", borderRadius: "12px", padding: "16px 16px 16px 24px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography style={{ fontFamily: "Inter", fontSize: "20px", fontWeight: "600", lineHeight: "32px", fontStyle: "normal" }}>Manage data sources</Typography>
                    <button style={{ background: "none", border: "none", cursor: "pointer" }} onClick={() => setLogOutModal(true)}><img src={Logout} alt="" /></button>
                </div>
            </Grid>

            <Grid container spacing={2} mt='0px' style={{ height: "calc(100% - 152px)" }}>
                <Grid item xs={6} md={3.4} style={{ height: "100%" }}>
                    <div style={{ width: "100%", background: "#FFF", borderRadius: "12px", padding: "16px", height: "100%" }}>
                        <Tabs
                            style={{ background: "#F6F7F8", borderRadius: "4px", padding: "8px 0px 8px 8px" }}
                            sx={{
                                "& .MuiTabs-indicator": { display: "none" },
                                "& .Mui-selected": {
                                    minHeight: "32px", height: "32px", background: "#0869FB",
                                    borderRadius: "6px", fontWeight: "400", color: "#FFFFFF !important",
                                },
                                "button": { minHeight: "32px !important", height: "32px" },
                                "& .MuiTabs-scroller": { height: "32px !important" }
                            }}
                            value={value} onChange={handleChange}  >
                            <Tab sx={{ textTransform: 'none', width: "50%" }} label={`Ads Channels (${totalAuthenticatedAds})`} />
                            <Tab sx={{ textTransform: 'none', width: "50%" }} label={`Attribution (${totalAuthenticatedAttributes})`} />
                        </Tabs>

                        {[AdsList, AttributionList].map((list, lsIx) =>
                            <TabPanel value={value} index={lsIx} style={{ overflowY: "auto", height: "calc(100% - 72px)", marginTop: "16px" }}>
                                <Stack spacing={2} style={{ width: "100%" }}>
                                    {list.map((channel, index) =>
                                        <Box key={index} style={{ padding: "16px" }} divider={false} disabled={!channel.isSupported}
                                            onClick={() => changeChannel(channel)}
                                            sx={{
                                                cursor: !channel.isSupported ? 'default' : 'pointer',
                                                border: isChannelSelected(channel) ? "1px solid #3C88FC" : "1px solid #ECEEF1",
                                                borderRadius: "8px", position: "relative"
                                            }}
                                        >
                                            {!channel.isSupported && <img src={Tag} style={{ position: "absolute", right: 0, top: 8 }} alt="" />}
                                            <div style={{ display: 'flex', width: '100%', opacity: !channel.isSupported ? 0.5 : 1, }}>
                                                <div style={{
                                                    display: "flex", alignItems: "center", justifyContent: "center",
                                                    background: "#F6F7F8", height: "48px", minWidth: "48px", borderRadius: "8px"
                                                }}>
                                                    <img style={{ height: 24, width: 24 }} src={channel.icon} alt={channel.title} />
                                                </div>

                                                <div style={{ display: "flex", flexDirection: "column", marginLeft: "12px", gap: "4px" }}>
                                                    <Typography style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "700", lineHeight: "20px" }}>
                                                        {channel.title}
                                                    </Typography>
                                                    <Typography style={{ fontFamily: "Inter", fontSize: "12px", color: "#5C6674" }}>
                                                        Connect and source data from {channel.title}
                                                    </Typography>
                                                    {(allChannelAccs[channel.type] || allChannelAccs[channel.type] === 0) &&
                                                        <div style={{ background: "#F6F7F8", borderRadius: "6px", padding: "2px 8px", width: "fit-content" }}>
                                                            <Typography style={{
                                                                fontFamily: "Inter", fontSize: "12px", fontWeight: "500",
                                                                lineHeight: "20px", color: "#5C6674"
                                                            }}>
                                                                Ad Accounts: {allChannelAccs[channel.type]}
                                                            </Typography>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </Box>
                                    )}
                                </Stack>
                            </TabPanel>
                        )}
                    </div>
                </Grid>

                <Grid item xs={6} md={8.6} style={{ height: "100%" }}>
                    <div style={{ borderRadius: "12px", background: "#FFF", width: "100%", height: "100%", padding: "16px" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "16px" }}>
                            <Typography style={{ fontFamily: "Inter", fontSize: "18px", fontWeight: "600", lineHeight: "24px" }}>Ad Accounts</Typography>
                            {(user.role === "admin" || user.role === "superadmin") &&
                                <button
                                    style={{
                                        fontFamily: "Inter", fontSize: "14px", fontWeight: "500",
                                        background: "none", color: !state.clientId ? "#808080" : "#0869FB", padding: "5px 15px",
                                        border: "none", borderRadius: "6px", cursor: "pointer"
                                    }}
                                    onClick={() => {
                                        dispatch({
                                            ...channel.actions.connectAccount,
                                            payload: { redirect_url: "managesources" }
                                        })
                                        handleMixPanelData(EVENTS.connect_ad_account, { "data_source": channel.type });
                                    }}
                                    disabled={!state.clientId}
                                >
                                    {isChannelAuthenticated(channel) ? "+ Connect More Accounts" : "+ Connect Your Account"}
                                </button>
                            }
                        </div>
                        {(isChannelAuthenticated(channel) && (accountData.accountList || accountData.loading)) ?
                            <>
                                <div style={{ display: "flex", gap: "16px" }}>
                                    <div style={{ width: "100%" }}>
                                        <SearchBar filteredData={accountData.accountList} setFilteredData={setFilteredAccountList} keys={["title", "subtitle", "id"]} />
                                    </div>
                                    <button style={{ background: "none", border: "none", cursor: "pointer", }}
                                        onClick={() => { dispatch(channel.actions.getAccountSummary({ refresh: true })) }}
                                    >
                                        <img src={Refresh} alt="" />
                                    </button>
                                </div>

                                <div style={{ width: "100%", overflowY: "auto", marginTop: "16px", height: "calc( 100% - 150px )" }}>
                                    <List
                                        sx={{ padding: "0px 0px", width: "100%", bgcolor: "background.paper", textAlign: "left", overflowY: "auto", display: "flex", flexWrap: "wrap" }}
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"
                                    >
                                        {(!accountData || !accountData.accountList || accountData.loading) ?
                                            <div style={{ width: "100%", height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <Loader />
                                            </div>
                                            : filteredAccountList.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                                                .filter(item => getAccountId(item) !== getAccountId(selectedAccountData))
                                                .map((item, index) =>
                                                    <Box key={index + getAccountId(item)}
                                                        style={{
                                                            width: "calc(25% - 12px)", maxWidth: "25%", minWidth: "150px",
                                                            border: "1px solid #f0f0f0", borderRadius: "12px", margin: "0 12px 12px 0",
                                                            display: "flex", flexDirection: "column", padding: "12px 12px 8px"
                                                        }}
                                                    >
                                                        <TitleSubtitle style={{ marginLeft: "15px" }} title={item.title} subtitle={item.subtitle} id={item.id} />
                                                        <p style={{ borderBottom: "1px solid #ECEEF1", width: "100%", margin: "0px", marginTop: "4px", marginBottom: "8px" }}></p>

                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                            <Typography style={{ fontFamily: "Inter", fontSize: "12px", color: "#AEB6C4" }}>
                                                                Connected Accounts
                                                            </Typography>
                                                            <div style={{ display: "flex", gap: "2px" }}>
                                                                {(item.users ?? accountData.users)?.map((accountUser, index) =>
                                                                    <Tooltip title={accountUser.email ?? accountUser.username} placement="top-start">
                                                                        <Avatar sx={{ height: "20px", width: "20px", backgroundColor: "#FCE5F2" }}>
                                                                            <Typography style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: "600", lineHeight: "18px", color: "#000000" }}>
                                                                                {accountUser.email?.[0]?.toUpperCase() ?? accountUser.username?.[0]}
                                                                            </Typography>
                                                                        </Avatar>
                                                                    </Tooltip>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Box>
                                                )
                                        }
                                    </List>
                                </div>

                                {(Math.ceil((filteredAccountList.length - 1) / rowsPerPage) > 1) &&
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                        <Pagination shape="rounded" color="primary" page={page + 1}
                                            count={Math.ceil((filteredAccountList.length - 1) / rowsPerPage)}
                                            onChange={(e, page) => handleChangePage(page - 1)}
                                            sx={{ justifyContent: 'center', display: 'flex', height: '60px' }}
                                        />
                                    </div>
                                }
                            </> :
                            <div className="displayColCenter" style={{ height: "calc( 100% - 70px )" }}>
                                <NoReports style={{ width: "50%", }} />
                                <Typography style={{ fontSize: "16px", fontWeight: 600 }}>No Ad account Available</Typography>
                                <Typography style={{ color: "#5C6674", marginTop: "8px" }}>There are no accounts. Please connect an account</Typography>
                            </div>
                        }
                    </div>
                </Grid>
            </Grid>

            <Grid xs={12} mt='16px'>
                <div style={{ width: "100%", height: "72px", background: "#FFF", borderRadius: "12px", padding: "20px 16px 20px 24px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: "400", lineHeight: "20px", fontStyle: "normal", color: "#5C6674" }}>Finding difficulty in connecting your data sources? <Link style={{ textDecoration: "underline" }} onClick={() => history.push("/personalized-demo")}>Get Help</Link></Typography>
                    <Button style={{ borderRadius: "8px", textTransform: "none" }} variant="contained" color="primary"
                        disabled={!authenticatedAccounts.length}
                        onClick={() => {
                            // transformMixPanelData(EVENTS.generate_report, {});
                            history.push("/report-management")
                        }}
                    >View Reports</Button>
                </div>
            </Grid>
            <RemoveChannelAccountModal
                modal={modal} setModal={setModal} channel={channel}
                onSubmit={() => dispatch({
                    ...channel.actions.disconnectAccount,
                    payload: disconnectAccountPayload.current
                })}
            />
            <LogoutModal modal={logoutModal} setModal={setLogOutModal} />
            <SelectingChannelErrorModal modal={errorModal} setModal={setErrorModal} />
            {/* <ShopifyConnectModal redirect_url="managesources" /> */}
            {/* <AppsflyerConnectModal redirect_url="managesources" /> */}
            {/* <AdjustConnectModal redirect_url="managesources" /> */}
        </div>
    );
}

const mapStateToProps = state => ({
    selectedAccounts: state.channel.selectedAccounts,
    authenticatedAccounts: state.channel.authenticatedAccounts,
    user: state.user.user,
});

const mapDispatchToProps = dispatch => ({
    checkAuth: () => dispatch(checkAuth()),
    getUser: () => dispatch(getUser()),
    closeShopify: () => dispatch(closeConnecting()),
    closeAppsflyer: (_) => dispatch(closeConnectingAppsflyer(_)),
    closeAdjust: (_) => dispatch(closeConnectingAdjust(_))
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageDataSources);
