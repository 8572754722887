import {
    Autocomplete,
    Box,
    Button,
    List,
    ListItem,
    ListSubheader,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Scrollbars from "react-custom-scrollbars";
import InfoIcon from "@mui/icons-material/Info";
import { createColumn, updateColumn } from "../../../api/channel/action";
import { useDispatch } from "react-redux";
import { filterAbsoluteMetrics } from "../Utils";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { updateLoader } from "../../../api/user/action";
import SearchBar from "../../Common/SearchBar";
import "./style.css"
import { handleMixPanelData, EVENTS } from "../../../utils/mixpanelV2";

/**
 * to get newMetricFormula for columnData from formulaString and metrics
 * @param {*} formulaString
 * @param {*} metrics
 */
const getNameFormulaWithFormulaString = (formulaString, metrics = []) => {
    let newMetricFormula = "";
    let counter = 0;
    let customMetricIds = [];
    let metricNotFound = false;
    while (counter < formulaString.length) {
        if (formulaString[counter] === "[") {
            let i = counter + 1;
            while (i < formulaString.length) {
                if (formulaString[i] === "]") {
                    const metricFound = metrics.find(
                        (metric) =>
                            metric.name === formulaString.substr(counter + 1, i - counter - 1)
                    );
                    if (metricFound) {
                        newMetricFormula +=
                            "[" +
                            metricFound.id +
                            (metricFound.dataSource ? "###" + metricFound.dataSource + (metricFound.audiences?.[0] ? "###" + metricFound.audiences[0].id : "") : "") +
                            "]";
                        if (metricFound.group === "Customized") {
                            customMetricIds.push(metricFound.id)
                        }
                        counter = i + 1;
                        break;
                    } else {
                        metricNotFound = true;
                        counter = i + 1;
                        break;
                    }
                } else {
                    i += 1;
                }
            }
        } else {
            newMetricFormula += formulaString[counter];
            counter += 1;
        }
    }
    return { newMetricFormula, customMetricIds, metricNotFound };
};

export default function CreateCustomCol({
    updateId,
    channel,
    channelCols,
    accountCols,
    columnData,
    setColumnData,
    resetData,
    setCompletion,
}) {
    const state = useSelector((state) => channel.getState(state));
    const user = useSelector((state) => state.user.user);
    const tableId = columnData?.table?.id || columnData?.other?.table?.id;
    const dataLevel = columnData?.dataLevel || columnData?.other?.dataLevel;
    const baseMetrics = Array.isArray(state.metrics)
        ? filterAbsoluteMetrics(
            channel.isBlend ? channelCols.metrics : state.metrics,
            channel.isBlend ? "default" : columnData.source
        )
        : filterAbsoluteMetrics(
            state.metrics[tableId + (dataLevel ? `:${dataLevel}` : "")],
            channel.isBlend ? "default" : columnData.source
        ) ?? [];
    const customMetrics = accountCols
        ? filterAbsoluteMetrics(accountCols.metrics)
        : Array.isArray(state.customMetrics)
            ? filterAbsoluteMetrics(
                state.customMetrics,
                channel.isBlend ? "default" : columnData.sourcee
            )
            : filterAbsoluteMetrics(
                state.customMetrics?.[tableId + (dataLevel ? `:${dataLevel}` : "")],
                channel.isBlend ? "default" : columnData.source
            ) ?? [];
    const metrics = baseMetrics.concat(customMetrics);

    const dispatch = useDispatch();
    const _updateColumn = (_) => dispatch(updateColumn(_));
    const _createColumn = (_) => dispatch(createColumn(_));
    const _updateLoader = (_) => dispatch(updateLoader(_));

    const [nameError, setNameError] = useState(false);
    const [formulaError, setFormulaError] = useState("");
    const [dataTypeError, setDataTypeError] = useState("");
    const [metricTypeError, setMetricTypeError] = useState("");
    const [initialUpdate, setInitialUpdate] = useState(true);
    const [stack, setStack] = useState([]);
    const [searchText, setSearchText] = useState("");
    const inputRef = useRef(null);

    const [formulaString, setFormulaString] = useState("=");
    // breakpoints are to navigate cursor to the breakpoint only
    const [breakPointsInFormula, setBreakPointsInFormula] = useState([0, 1]);

    // breakpoints at which '(' present
    const [smallOpenBracketIndexes, setSmallOpenBracketIndexes] = useState([]);
    // breakpoints at which ')' present
    const [smallCloseBracketIndexes, setSmallCloseBracketIndexes] = useState([]);

    const onFormulaChange = (item) => {
        if (item.charAt(item.length - 1) === "=") {
            item = item.substring(0, item.length - 1);
            item = "=" + item;
        }
        if (!item.includes("=")) {
            item = "=" + item;
        }
        setFormulaString(item);
    };
    // const onFormulaChange = (item) => {
    //     setFormulaString(item);
    // };
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.selectionStart = formulaString.length;
            inputRef.current.selectionEnd = formulaString.length;
        }
    }, [formulaString]);

    const DATATYPE_OPTIONS = [
        { id: "INTEGER", name: "Integer" },
        { id: "FLOAT", name: "Float" },
        { id: "PERCENT", name: "Percentage" },
        {
            id: "CURRENCY",
            name: `Price ${state?.currency ? "(" + state.currency + ")" : ""}`,
        },
    ];

    const METRIC_TYPE = [
        { id: "POSITIVE", name: "Positive" },
        { id: "NEGATIVE", name: "Negative" },
        { id: "NEUTRAL", name: "Neutral" },
    ];

    useEffect(() => {
        if (!initialUpdate) {
            setNameError(columnData.name.length === 0);
        } else {
            setInitialUpdate(false);
        }
    }, [columnData.name]);

    const validOperators = ["+", "-", "*", "/", "(", ")", "."];
    const validLastOperators = ["+", "-", "*", "/", "(", "=", "."];
    const buttons = [
        { val: '+', id: 0, type: 'operator' },
        { val: '-', id: 1, type: 'operator' },
        { val: '*', id: 2, type: 'operator' },
        { val: '/', id: 3, type: 'operator' },
        { val: '(', id: 4, type: 'bracketOpen' },
        { val: ')', id: 5, type: 'bracketClose' },
    ];

    const addMetric = (metric) => {
        addItemToFormula("[" + metric.name + "]");
    };

    const updateFormula = (event) => {
        setFormulaError("");
        setMetricError(false);
        if (validOperators.includes(event.key)) {
            addItemToFormula(event.key);
        } else if (/\d/.test(event.key)) {
            addItemToFormula(event.key);
        } else if (event.key === "Backspace") {
            if (inputRef.current.selectionStart === 1) {
                return;
                // setFormulaError("Syntax Error");
            }
            onBackspacePressHandler();
        } else {
            // setFormulaError("Invalid character!");
        }
    };

    const testValidExpression = (string) => {
        const formulaArray = formulaString.split(/[*+-/]/);

        // for digits and decimal just before metric or just after metric
        const containsDigitOrDecimalBeforeOpeningBracketRegex = /(\d|\.)+\[/;
        const containsDigitOrDecimalAfterClosingBracketRegex = /\](\d|\.)+/;

        if (
            formulaArray.includes("") ||
            formulaString.includes("][") ||
            formulaString.includes(")[") ||
            containsDigitOrDecimalAfterClosingBracketRegex.test(formulaString) ||
            containsDigitOrDecimalBeforeOpeningBracketRegex.test(formulaString) ||
            !formulaString.match(/[a-zA-Z]/)
        ) {
            return false;
        }
        return true;
    };

    const saveData = () => {
        if (metricError) return;
        // for open'(' and close ')' brackets validation
        let openCloseBrackets = true;
        if (smallCloseBracketIndexes.length !== smallOpenBracketIndexes.length) {
            openCloseBrackets = false;
        } else {
            let [smallOpenBracketCounter, smallCloseBracketCounter] = [0, 0];
            let bracketStack = [];
            while (
                smallCloseBracketCounter < smallCloseBracketIndexes.length ||
                smallOpenBracketCounter < smallOpenBracketIndexes.length
            ) {
                if (
                    smallOpenBracketIndexes[smallOpenBracketCounter] <
                    smallCloseBracketIndexes[smallCloseBracketCounter]
                ) {
                    bracketStack.push(smallOpenBracketIndexes[smallOpenBracketCounter]);
                    smallOpenBracketCounter += 1;
                } else if (!bracketStack.length) {
                    openCloseBrackets = false;
                    break;
                } else {
                    bracketStack.pop();
                    smallCloseBracketCounter += 1;
                }
            }
        }
        if (columnData.name.length === 0) {
            setNameError(true);
            return;
        } else if (!columnData.other?.dataType) {
            setDataTypeError("DataType is required!");
            return;
        } else if (!columnData.other?.metricType) {
            setMetricTypeError("Metric Type is required!");
            return;
        } else if (!openCloseBrackets) {
            setFormulaError(
                "Syntax Error! Each opening bracket must have a closing bracket as well"
            );
            return;
        } else if (!testValidExpression()) {
            setFormulaError("Invalid Expression!");
            return;
        }

        let { newMetricFormula, customMetricIds, metricNotFound } = getNameFormulaWithFormulaString(formulaString, metrics);
        if (metricNotFound) {
            setMetricError(true);
            return;
        }
        const tempNewColumnData = {
            ...columnData,
            formula: newMetricFormula,
            other: { ...columnData.other, customMetricIds, nameFormula: formulaString },
        };
        setColumnData(tempNewColumnData);
        _updateLoader(true);
        if (updateId) {
            _updateColumn({
                columnId: updateId,
                params: tempNewColumnData,
                loading: (msg, error) => {
                    _updateLoader(false);
                    setCompletion({ message: msg, error, operation: "Updation" });
                },
            });
            handleMixPanelData(EVENTS.update_custom_metric, tempNewColumnData);
        } else {
            _createColumn({
                columnData: tempNewColumnData,
                loading: (msg, error) => {
                    _updateLoader(false);
                    setCompletion({ message: msg, error, operation: "Creation" });
                },
            });
            handleMixPanelData(EVENTS.save_custom_metric, tempNewColumnData);
        }
    };

    const filterData = (value) => {
        return value.toLowerCase().includes(searchText.toLowerCase());
    };

    const [metricError, setMetricError] = useState(false);

    const checkMetricsAvailabe = () => {
        var metricsIds = metrics.map(
            (e) => e.id + (e.dataSource ? "###" + e.dataSource + (e.audiences?.[0] ? "###" + e.audiences[0].id : "") : "")
        );
        const pattern = /\[([^[\]]+)]/g;
        const matches = columnData.formula.match(pattern) ?? [];
        setMetricError(
            matches.some((metric) => {
                let metricId = metric.slice(1, -1);
                return !(metricsIds?.indexOf(metricId) > -1);
            })
        );
    };

    useEffect(() => {
        setMetricError(false);
        checkMetricsAvailabe();
    }, [accountCols]);

    useEffect(() => {
        if (metricError) {
            checkMetricsAvailabe();
        }
    }, [columnData.formula]);

    useEffect(() => {
        if (formulaString === "=" && columnData?.other?.nameFormula) {
            setFormulaString(columnData.other.nameFormula);
            modifiedFormulaStringfromIncomingFormula(columnData.other.nameFormula);
        }
    }, [columnData]);

    const modifiedFormulaStringfromIncomingFormula = (string) => {
        if (string) {
            let lengthCounter = 1; // for breakpoints
            let tempFormulaString = "=";
            let i = 1;
            const tempBreakPointsInFormula = [...breakPointsInFormula];
            const tempSmallOpenBracketBreakPointsInFormula = [];
            const tempSmallCloseBracketBreakPointsInFormula = [];
            while (i < string?.length) {
                const item = string?.charAt(i);
                if (/[0-9.+\-*/()]/.test(item)) {
                    tempFormulaString += item;
                    switch (item) {
                        case "(":
                            tempSmallOpenBracketBreakPointsInFormula.push(lengthCounter);
                            break;
                        case ")":
                            tempSmallCloseBracketBreakPointsInFormula.push(lengthCounter);
                            break;
                        default:
                            break;
                    }
                    lengthCounter += 1;
                    i += 1;
                } else {
                    let itemString = "";
                    let start = i;
                    while (start < string.length) {
                        if (["]"].includes(string[start])) {
                            itemString += string[start];
                            break;
                        }
                        itemString += string[start];
                        start += 1;
                    }
                    tempFormulaString += itemString;
                    lengthCounter += itemString.length;
                    i = start + 1;
                }
                tempBreakPointsInFormula.push(lengthCounter);
            }
            setBreakPointsInFormula(tempBreakPointsInFormula);
            setSmallOpenBracketIndexes(tempSmallOpenBracketBreakPointsInFormula);
            setSmallCloseBracketIndexes(tempSmallCloseBracketBreakPointsInFormula);
            return tempFormulaString;
        }
    };

    /**
     * adding item to formula at focussed position and modifying breakPointsInFormula
     * @param item that to be added in formulaString
     * @returns new formula string
     */
    const addItemToFormula = (item) => {
        const currentPosition = inputRef.current?.selectionStart;
        const newFormulaString = [
            formulaString.slice(0, currentPosition),
            item,
            formulaString.slice(currentPosition),
        ].join("");
        onFormulaChange(newFormulaString);
        switch (item) {
            case "(":
                setSmallOpenBracketIndexes((prev) =>
                    [...prev, currentPosition].sort((a, b) => a - b)
                );
                break;
            case ")":
                setSmallCloseBracketIndexes((prev) =>
                    [...prev, currentPosition].sort((a, b) => a - b)
                );
                break;
            default:
                break;
        }
        modifyBreakPointsOnAdd(currentPosition, item);
        setTimeout(() => {
            handleCursorPosition(currentPosition + item.length);
        }, 1);
        return newFormulaString;
    };

    /**
     * changes on pressing Backspace button
     */
    const onBackspacePressHandler = () => {
        const cursorPosition = inputRef.current?.selectionStart;

        if (!cursorPosition) return;

        const previousBreakPointsInFormula = breakPointsInFormula?.findLastIndex(
            (id) => id < cursorPosition
        );
        modifyBreakPointsOnDelete(cursorPosition);

        switch (formulaString[breakPointsInFormula[previousBreakPointsInFormula]]) {
            case "(":
                setSmallOpenBracketIndexes((prev) =>
                    prev.filter(
                        (a) => a !== breakPointsInFormula[previousBreakPointsInFormula]
                    )
                );
                break;
            case ")":
                setSmallCloseBracketIndexes((prev) =>
                    prev.filter(
                        (a) => a !== breakPointsInFormula[previousBreakPointsInFormula]
                    )
                );
                break;
            default:
                break;
        }
        onFormulaChange(
            formulaString.substr(
                0,
                breakPointsInFormula[previousBreakPointsInFormula]
            ) + formulaString.substr(cursorPosition)
        );
        setTimeout(() => {
            handleCursorPosition(breakPointsInFormula[previousBreakPointsInFormula]);
        }, 1);
    };

    /**
     *
     * @param positionIndex is index in input box where cursor need to be positioned
     * @param forClick on focus in textfield
     */
    const handleCursorPosition = (positionIndex, forClick = false) => {
        let finalIndex =
            positionIndex !== null ? positionIndex : formulaString.length;
        if (forClick) {
            if (
                !(
                    breakPointsInFormula.includes(finalIndex) ||
                    finalIndex === formulaString.length
                )
            ) {
                finalIndex =
                    breakPointsInFormula[
                    breakPointsInFormula?.findIndex((id) => id > finalIndex)
                    ];
            } else if (finalIndex === 0) {
                finalIndex = 1;
            }
        }
        if (inputRef.current) {
            inputRef.current.selectionStart = finalIndex;
            inputRef.current.selectionEnd = finalIndex;
            inputRef.current.focus();
        }
    };

    /**
     * modify break-points for formula string for cursor to move on adding metric or operator
     * @param index is index of last character of item after adding to formulaString
     * {item} is item that needs to be added.
     */
    const modifyBreakPointsOnAdd = (index, item) => {
        if (index > breakPointsInFormula[breakPointsInFormula.length - 1]) {
            setBreakPointsInFormula((prev) => [...prev, index].sort((a, b) => a - b));
        } else if (["*", "+", "-", "/", "(", ")"].includes(item)) {
            setBreakPointsInFormula((prev) => {
                return [...prev.map((id) => (id < index ? id : id + 1)), index].sort(
                    (a, b) => a - b
                );
            });
        } else {
            // for metric
            setBreakPointsInFormula((prev) => {
                return [
                    ...prev.map((id) => (id < index ? id : id + item.length)),
                    index,
                ].sort((a, b) => a - b);
            });
        }
    };

    /**
     * modify break-points for formula string for cursor to move on deleting metric or operator
     * @param index - index is position of last elemant of the item which is going to delete
     * @returns
     */
    const modifyBreakPointsOnDelete = (index) => {
        if (index === -1) return;

        let tempBreakPointsInFormula = [...breakPointsInFormula];
        const intialIndexToDelete = breakPointsInFormula?.findLastIndex(
            (id) => id < index
        );
        tempBreakPointsInFormula.splice(tempBreakPointsInFormula.indexOf(index), 1);
        setBreakPointsInFormula(
            tempBreakPointsInFormula
                .map((id) => {
                    return id < index
                        ? id
                        : id - (index - tempBreakPointsInFormula[intialIndexToDelete]);
                })
                .sort((a, b) => a - b)
        );
    };

    /**
     * on which position should cursor move on pressing left arrow key
     * @returns
     */
    const onLeftArrowPressHandler = () => {
        const cursorPosition = inputRef.current?.selectionStart;
        if (cursorPosition === 1) return;
        const previousBreakPointsInFormula = breakPointsInFormula?.findLastIndex(
            (id) => id < cursorPosition
        );
        handleCursorPosition(breakPointsInFormula[previousBreakPointsInFormula]);
    };

    /**
     * on which position should cursor move on pressing right arrow key
     * @returns
     */
    const onRightArrowPressHandler = () => {
        const cursorPosition = inputRef.current?.selectionStart;
        if (cursorPosition === formulaString?.length) return;
        const nextBreakPointsInFormula = breakPointsInFormula?.findIndex(
            (id) => id > cursorPosition
        );
        handleCursorPosition(breakPointsInFormula[nextBreakPointsInFormula]);
    };

    /**
     * get char value on pressing button on keyboard
     * @return permitted character or null
     */
    const getCharValueOnPressingButton = (event) => {
        if (!event) return null;
        let char;
        switch (event?.key) {
            case "*":
                char = "*";
                break;
            case "+":
                char = "+";
                break;
            case "-":
                char = "-";
                break;
            case "/":
                char = "/";
                break;
            case ".":
                char = ".";
                break;
            case "(":
                char = "(";
                break;
            case ")":
                char = ")";
                break;
            default:
                char = String.fromCharCode(event?.which);
        }
        return /[0-9.+\-*/()]/.test(char) ? char : null;
    };

    return (
        <Box>
            <Box>
                <h2 className="inter h18">Custom Metric</h2>
                <p style={{ color: "#00000099" }}>
                    {" "}
                    Add a column that is computed from the other columns
                </p>
            </Box>
            <hr style={{ border: "1px solid #EAEAEC", margin: "0px -20px" }} />

            <Box className="Top_header" >
                <div style={{ width: "100%" }}>
                    <Typography style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "600", lineHeight: "22px", marginBottom: "8px" }}>Name</Typography>
                    <TextField
                        required
                        placeholder="Add Column Name"
                        style={{ width: "100%" }}
                        inputProps={{
                            style: {
                                padding: "10px 16px",
                                fontFamily: "Inter",
                                fontSize: "12px",
                                fontWeight: "500"
                            }
                        }}
                        id="outlined-basic"
                        value={columnData.name}
                        error={nameError}
                        helperText={nameError ? "Name is required" : ""}
                        onChange={(e) =>
                            setColumnData({ ...columnData, name: e.target.value })
                        }
                        variant="outlined"
                        size="small"
                        sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0, borderRadius: "8px" },
                        }}
                    />
                </div>

                <div style={{ width: "100%" }}>
                    <Typography style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "600", lineHeight: "22px", marginBottom: "8px" }}>Format Data Type</Typography>
                    <Autocomplete
                        sx={{ width: "100%" }}
                        options={DATATYPE_OPTIONS}
                        getOptionLabel={(option) => option.name ?? option.title}
                        value={columnData.other?.dataType}
                        onChange={(event, newValue) => {
                            setColumnData({
                                ...columnData,
                                format: newValue?.id,
                                other: { ...columnData.other, dataType: newValue },
                            });
                            setDataTypeError("");
                        }}
                        disableClearable={true}
                        isOptionEqualToValue={(option, value) =>
                            option?.id === value?.id
                        }
                        renderInput={(params) => {
                            return (
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        {...params}
                                        size="small"
                                        error={dataTypeError}
                                        helperText={
                                            dataTypeError ? "Data Type is required" : ""
                                        }
                                        placeholder={"Add Source"}
                                        sx={{
                                            "& legend": { display: "none" },
                                            "& input": { fontSize: "14px" },
                                            "& fieldset": { top: 0, borderRadius: "8px" },
                                        }}
                                    />
                                </div>
                            );
                        }}
                    />

                </div>

                <div style={{ width: "100%" }}>
                    <Typography style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "600", lineHeight: "22px", marginBottom: "8px" }}>Type of Metric</Typography>
                    <Autocomplete
                        sx={{ width: "100%" }}
                        options={METRIC_TYPE}
                        getOptionLabel={(option) => option.name ?? option.title}
                        value={columnData.other?.metricType}
                        onChange={(event, newValue) => {
                            setColumnData({
                                ...columnData,
                                metric_type: newValue?.id,
                                other: { ...columnData.other, metricType: newValue },
                            });
                            setMetricTypeError("");
                        }}
                        disableClearable={true}
                        isOptionEqualToValue={(option, value) =>
                            option?.id === value?.id
                        }
                        renderInput={(params) => {
                            return (
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        {...params}
                                        size="small"
                                        error={metricTypeError}
                                        helperText={
                                            metricTypeError ? "Metric Type is required" : ""
                                        }
                                        placeholder={"Add Type of Metric"}
                                        sx={{
                                            "& legend": { display: "none" },
                                            "& input": { fontSize: "14px" },
                                            "& fieldset": { top: 0, borderRadius: "8px" },
                                        }}
                                    />
                                </div>
                            );
                        }}
                    />

                </div>
            </Box>

            <Box className="Formula_section" sx={{ marginTop: "16px" }}>
                <Typography style={{ fontFamily: "Inter", fontWeight: "600", fontSize: "14px", lineHeight: "22px", marginBottom: "8px" }}>Custom Formula</Typography>
                <Box className="formula_container">
                    <div style={{ width: "40%", borderRight: "1px solid #EAEAEC", padding: "12px", height: "100%" }}>
                        <Typography style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: "500", color: "#ABACB4", marginBottom: "12px" }}>Select Metric</Typography>
                        <SearchBar setFilterText={setSearchText} />

                        <List
                            style={{
                                // border: "1px solid #EAEAEC",
                                // borderRadius: "8px",
                                height: "calc(100% - 57px)",
                                // marginTop: "10px",
                                // background: "#fff",
                            }}
                        >
                            <Scrollbars
                                autoHide
                                style={{ height: "100%",marginTop: "20px" }}
                            // renderTrackVertical={({ style, ...props }) => (
                            //     <div {...props} style={{ ...style, zIndex: 20 }} />
                            // )}
                            >
                                {metrics
                                    .filter((metric) => filterData(metric.name))
                                    .map((metric, index, array) => (
                                        <>
                                            {!(
                                                index && array[index - 1].group === metric.group
                                            ) && (
                                                    <ListSubheader style={{ lineHeight: "inherit", padding: "0px", fontSize: "14px" }}>
                                                        {metric.group}
                                                    </ListSubheader>
                                                )}
                                            <ListItem
                                                // className="h14_dark pointer highlight1"
                                                style={{
                                                    // margin: "8px 15px",
                                                    // width: "calc(100% - 30px)",
                                                    padding: "6px 0px",
                                                    background: "none",
                                                    fontSize: "12px",
                                                    fontFamily: "Inter",
                                                    fontWeight: "500",
                                                    cursor: "pointer"
                                                }}
                                                key={index}
                                                onClick={() => {
                                                    addMetric(metric);
                                                }}
                                            >
                                                {metric.name}
                                            </ListItem>
                                        </>
                                    ))}
                            </Scrollbars>
                        </List>

                    </div>
                    <div style={{ width: "100%" }}>

                        <div style={{ display: "flex", width: "100%", height: "36px", background: "#F6F7F8" }}>

                            {buttons.map((item, index) => {
                                return (
                                    <button className="formula_buttons"
                                        key={index}
                                        onClick={(event) => {
                                            addItemToFormula(item.val)
                                        }}
                                    >{item.val}</button>
                                )

                            })
                            }

                        </div>
                        <TextField
                            multiline
                            defaultValue={formulaString}
                            value={formulaString}
                            //   inputRef={inputRef}
                            inputRef={inputRef}
                            style={{ width: "100%" }}
                            onKeyDown={(event) => {
                                if (event) {
                                    event.preventDefault();
                                    let char;
                                    switch (event.key) {
                                        case "ArrowLeft":
                                            onLeftArrowPressHandler();
                                            return;
                                        case "ArrowRight":
                                            onRightArrowPressHandler();
                                            return;
                                        default:
                                            updateFormula(event);
                                    }
                                    return;
                                }
                            }}
                            // onChange={handleCursorPosition}
                            onFocus={(event) => {
                                event.preventDefault();
                                // handleCursorPosition(inputRef.current.selectionStart);
                            }}
                            // onChange={() => handleCursorPosition(inputRef.current.selectionStart)}
                            onClick={(event) => {
                                event.preventDefault();
                                handleCursorPosition(inputRef.current.selectionStart, true);
                            }}
                            disableUnderline
                            sx={{
                                "& legend": { display: "none" },
                                "& fieldset": {
                                    top: 0,
                                    borderRadius: "8px",
                                    border: "none",
                                    minHeight: "100%",
                                },

                                minHeight: "100%",
                            }}
                        // minRows={3}
                        // maxRows={3}
                        />

                        {formulaError && (
                            <span
                                style={{
                                    color: "#d32f2f",
                                    fontSize: "12px",
                                    marginLeft: "15px",
                                }}
                            >
                                {formulaError}
                            </span>
                        )}
                    </div>
                </Box>
            </Box>



            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "15px",
                    position: "relative",
                }}
            >
                {metricError && (
                    <Tooltip
                        PopperProps={{
                            sx: {
                                "& .MuiTooltip-arrow": { color: "white" },
                                "& .MuiTooltip-tooltip": {
                                    backgroundColor: "white",
                                    color: "#333",
                                    borderRadius: "12px",
                                    filter: "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))",
                                    padding: "10px 25px",
                                },
                            },
                        }}
                        title={
                            "Some Metrics Used in the fomula are unavailable for selected account! To continue, remove the custom metric(s) from the formula or change the account ID."
                        }
                        arrow
                        placement="top"
                    >
                        <WarningAmberIcon
                            style={{
                                color: "#F3807D",
                                position: "absolute",
                                left: 5,
                                top: 5,
                            }}
                        />
                    </Tooltip>
                )}
                <Button
                    sx={{ marginRight: "10px", color: "#0869FB" }}
                    onClick={()=>resetData()}
                >
                    Cancel
                </Button>
                <Tooltip
                    title={
                        user.role === "viewer"
                            ? "Viewer does not have permission to save!"
                            : ""
                    }
                    placement="top"
                >
                    <div style={{}}>
                        <Button
                            disabled={
                                user.role === "viewer" ||
                                nameError ||
                                formulaError ||
                                metricError
                            }
                            sx={{ backgroundColor: "#0869FB" }}
                            onClick={() => {
                                saveData();
                            }}
                            variant="contained"
                        >
                            {updateId ? "Update" : "Save"}
                        </Button>
                    </div>
                </Tooltip>
            </Box>
        </Box>
    );
}
