import { Grid, Typography, FormControl, TextField, Chip, InputAdornment } from '@mui/material';
import "../styles/profile.css";

export default function ReRunDataList({ width = 6, title, label, list, displayAggregate, startAdornment, bottomSpace = "10px", stretch = "95%" }) {
    return (
        <Grid item xs={width} style={{ marginLeft: "0px" }}>
            <div style={{ margin: "0px 0px", display: "flex", width: "95%" }}>
                <Typography noWrap className="newText" >{title}</ Typography>
            </div>
            <FormControl
                sx={{ marginBottom: bottomSpace, display: "flex", flexDirection: "row", width: stretch, marginHorizontal: "auto" }}>
                <div className="metric-list-form" style={{ width: "100%" }}>
                    <TextField
                        hiddenLabel
                        id="Analytics"
                        variant="filled"
                        size="small"
                        className='trigger-table'
                        style={{ overflowX: "auto" }}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            readOnly: true,
                            style: { cursor: "default" },
                            startAdornment: (list ?
                                list.map((item, index) => (
                                    <Chip
                                        size="small"
                                        sx={{
                                            borderRadius: '6px',
                                            background: '#f7f7f7',
                                            border: '1px solid #EAEAEC',
                                            fontFamily: "Inter",
                                            fontSize: "10px",
                                            margin: "2px",
                                        }}
                                        key={index}
                                        tabIndex={index}
                                        label={(item.name ?? item.title) + (item.aggregate && displayAggregate ? (" (" + item.aggregate + ") ") : "")}
                                    />
                                )) : startAdornment ?
                                    <InputAdornment onClick={startAdornment.onClick} style={{ cursor: "pointer" }}>
                                        <img style={{ width: "18px", marginRight: "10px" }} src={startAdornment.src} alt="Calendar" />
                                    </InputAdornment> : <></>
                            )
                        }}
                        value={label}
                    />
                </div>
            </FormControl>
        </Grid>
    )
}