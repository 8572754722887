import Highcharts from 'highcharts';
import HighchartsTreemap from 'highcharts/modules/treemap';
import HighchartsDrilldown from 'highcharts/modules/drilldown';

HighchartsTreemap(Highcharts);
HighchartsDrilldown(Highcharts);

const TreemapChartWrapper = ({ children }) => {
    return children({ HighchartsInstance: Highcharts });
};

export default TreemapChartWrapper;
