import { LOGIN_SUCCESS, SIGNUP_SUCCESS, UPDATE_USER_ORG_SUCCESS } from "../auth/type";
import { UPDATE_LOADER, UPDATE_TOUR_FLAG_SUCCESS, UPDATE_TOUR_INFO, USER_DETAILS_SUCCESS } from "./type";

const INITIAL_STATE = {
    user: {},
    tutorial: {
        showTutorial: false,
        run: false,
        stepIndex: 0
    },
    loading: false,
    serviceDown: false
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SIGNUP_SUCCESS:
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: { ...state.user, role: action.payload.role ?? action.payload.user.role, isExternalEditor: action.payload.isExternalEditor ?? action.payload.user?.isExternalEditor },
                tutorial: { ...state.tutorial, showTutorial: action.payload.showTutorial }
            };
        case USER_DETAILS_SUCCESS:
            return { ...state, user: { ...state.user, ...action.payload.user } }
        case UPDATE_USER_ORG_SUCCESS:
            return { ...state, user: { ...state.user, org: action.payload } }
        case UPDATE_TOUR_INFO:
            return { ...state, tutorial: { ...state.tutorial, ...action.payload } }
        case UPDATE_TOUR_FLAG_SUCCESS:
            return { ...state, tutorial: INITIAL_STATE.tutorial }
        case UPDATE_LOADER:
            return { ...state, loading: action.payload }
        case "SERVICE_UP":
            return { ...state, serviceDown: false }
        case "SERVICE_DOWN":
            return { ...state, serviceDown: true }
        default:
            return state;
    }
}

export default reducer;
