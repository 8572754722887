import React, { useState } from "react";
import Modal from 'react-modal';
import { Button, Grid, TextField } from '@mui/material';
import "../styles/modal.css";
import { Report } from "../../models/report";
import { AllIcons } from "../../Assets/ChannelIcons/allIcons"

const EditNameModal = (props) => {
    const { oldName, oldIcon, modal, setModal, update, allNames } = props;
    const [name, setName] = useState(modal.update ? oldName : null);
    const [iconIndex, setIconIndex] = useState(AllIcons.findIndex(icon => icon === oldIcon));
    const [errMessage, setErrMessage] = useState("");
    const nameRegex = /^[a-zA-Z0-9- ()]+$/;

    const validation = () => {
        const checkName = name;
        if (allNames.includes(checkName)) {
            setErrMessage(`${modal.show} with this name is already created. Please use a new unique name!`)
            return false;
        }
        if(!checkName?.trim()){
            setErrMessage(`Name can't be empty`);
            return false;
        }
        return true;
    }

    const handleChange = (name) => {
        if (!nameRegex.test(name)) {
            setErrMessage("Invalid name ( Must have length greater than 1 and include upper/lowercase alphabets, numbers, spaces, ( ) or - only")
        } else {
            setErrMessage("")
        }
        setName(name);
    }

    const handleSubmit = () => {
        if (!errMessage && ((oldName && name === oldName) || validation())) {
            update(name, modal.show, AllIcons[iconIndex])
        }
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: "#ffff",
            width: modal.show === "Report" ? "510px" : "450px",
            minHeight: modal.show === "Report" ? "370px" : "250px",
            padding: "0",
            zIndex: 9000000000000,
            position: "absolute"
        },
    };

    return (
        <Modal isOpen={Boolean(modal.show)} style={customStyles}>
            <div style={{ width: "100%", height: "40px", borderBottom: "1px solid #eee", padding: "10px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div>
                    <b>{!modal.update ? `Create new ${modal.show}` : `Update ${modal.show}`}</b>
                </div>
                <div>
                    <Button onClick={() => setModal({ show: false, update: false })}>X</Button>
                </div>
            </div>
            <div style={{ width: "100%", position: "relative", zIndex: 90000000, backgroundColor: "#ffff", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
                    {modal.show === "Report" ?
                        <Grid item xs={12} style={{ width: "80%", margin: "0 auto", marginTop: "30px", marginBottom: "0px" }}>
                            <div><h4>Select Report Icon </h4></div>
                            {
                                AllIcons.map((icon, index) => {
                                    return (
                                        <span onClick={() => { setIconIndex(index) }} >
                                            <img key={index} style={{ height: "45px", width: "45px", padding: "5px", border: iconIndex === index ? "2px solid blue" : "0px", borderRadius: "30px" }} src={icon} alt={"abc"} />
                                        </span>
                                    )
                                })
                            }
                        </Grid> :
                        <Grid item xs={12} style={{ width: "80%", margin: "0 auto", marginTop: "20px", marginBottom: "0px" }}>
                        </Grid>
                    }
                    <Grid item xs={12} style={{ width: "80%", margin: "0 auto", marginTop: "30px", marginBottom: "10px" }}>
                        <TextField
                            label={`Enter ${modal.show} name `}
                            key={oldName}
                            style={{ width: '100%' }}
                            id="outlined-basic"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ style: { borderRadius: '6px' } }}
                            value={name}
                            defaultValue={oldName}
                            size="small"
                            onChange={(e) => handleChange(e.target.value)}
                            variant="outlined" />
                    </Grid>
                    {errMessage !== "" &&
                        <Grid xs={12} style={{ width: "80%", margin: "0 auto", display: "flex", justifyContent: "center", alignItems: "flex-start", flexDirection: "column" }}>
                            {
                                <h4 style={{
                                    color: "red",
                                    textAlign: "right",
                                    borderRadius: "10px",
                                    width: "100%",
                                    fontSize: "10px"
                                }}><b>{errMessage}</b>
                                </h4>

                            }
                        </Grid>
                    }
                    <Grid xs={12} style={{ width: "80%", margin: "0 auto", transform: "translateY(20px)", marginBottom: "20px" }}>
                        <Button style={{ backgroundColor: "#0869FB", color: "white", padding: "10px", textAlign: "right", float: "right", borderRadius: "8px", width: "100%" }}
                            onClick={() => { handleSubmit() }}
                        >{!modal.update ? "ADD" : "UPDATE"}</Button>
                    </Grid>
                </div>
            </div>
        </Modal>
    )
}
export default EditNameModal;
