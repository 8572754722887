import { InputAdornment, TextField } from "@mui/material";
import { ReactComponent as SearchMenu } from "../../Assets/Icons/search-icon.svg";
import { useEffect, useState } from "react";
import { handleMixPanelData, EVENTS } from "../../utils/mixpanelV2";

export default function SearchBar({
  setFilterText,
  filteredData,
  setFilteredData,
  keys = ["name", "report_name"],
  style,
}) {
  const [searchText, setSearchText] = useState("");

  const filterData = (value = "") => {
    return value.toLowerCase().includes(searchText.toLowerCase());
  };

  useEffect(() => {
    if (setFilteredData && filteredData) {
      setFilteredData(
        filteredData.filter((data) =>
          keys.some((key) => filterData(data?.[key] ?? ""))
        )
      );
    }
    if (keys.includes("report_name") && searchText.length === 1) {
      handleMixPanelData(EVENTS.click_search_reports_mg);
    }
  }, [searchText, filteredData]);

  return (
    <TextField
      placeholder="Search"
      id="outlined-basic"
      name="none"
      defaultValue={""}
      value={searchText}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchMenu style={{ marginRight: "10px" }} />
          </InputAdornment>
        ),
      }}
      type="search"
      variant="outlined"
      size="small"
      inputProps={{
        autocomplete: "new-password",
        form: {
          autocomplete: "off",
        },
      }}
      onChange={(event) => {
        event.preventDefault();
        setFilterText?.(event.target.value);
        setSearchText(event.target.value);
      }}
      sx={{
        ...style,
        height: "10%",
        width: "100%",
        "& legend": { display: "none" },
        "& fieldset": {
          top: 0,
          borderRadius: "6px",
          border: "1px solid #EAEAEC",
        },
      }}
    />
  );
}
