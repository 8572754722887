import { v4 as uuid } from 'uuid';
import { ChartFilterSupport } from '../components/Charts/ChartUtils';
import { ChannelType, GetChannel } from "./channel";

export class Filter {
  constructor(name = "", segment = null, dimensionFilters = [], metricFilters = [], filterId, account) {
    this.channelType = null;
    this.account = account ?? null;
    this.name = name;
    this.segment = segment;
    this.dimensionFilters = dimensionFilters;
    this.metricFilters = metricFilters;
    this.id = filterId;
  }

  static copy(filter) {
    if (!filter) { return null; }
    return Filter.new(
      filter,
      filter.name,
      filter.segment ? JSON.parse(JSON.stringify(filter.segment)) : null,
      filter.dimensionFilters.map(d => ({ ...d })),
      filter.metricFilters.map(m => ({ ...m })),
      filter.id
    );
  }

  isEqualAll(filter) {
    let segmentEquality = this.segment?.id === filter.segment?.id;
    if (Array.isArray(this.segment)) {
      let filterSeg = filter.segment?.flat() ?? [];
      let oldFilterSeg = this.segment?.flat() ?? [];
      segmentEquality = (filterSeg.length === oldFilterSeg.length) && oldFilterSeg.every((v, i) => v?.id === filterSeg[i]?.id);
    }
    return (
      this.id === filter.id && this.name === filter.name &&
      segmentEquality &&
      this.dimensionFilters.length === filter.dimensionFilters.length &&
      this.dimensionFilters.every((v, i) => {
        const dimFilter = filter.dimensionFilters[i]
        return v.boolOperator === dimFilter.boolOperator &&
          v.filter?.id === dimFilter.filter?.id &&
          v.operator?.type === dimFilter.operator?.type &&
          v.value.length === dimFilter.value.length &&
          v.value.every((arr, i) => arr === dimFilter.value[i])
      }) &&
      this.metricFilters.length === filter.metricFilters.length &&
      this.metricFilters.every((v, i) => v === filter.metricFilters[i])
    );
  }

  isEqual(filter) {
    return (
      this.id === filter.id
    );
  }

  isEmpty() {
    let segmentEmpty = !this.segment;
    if (Array.isArray(this.segment)) {
      segmentEmpty = this.segment.filter(attr => attr).length === 0;
    }
    return segmentEmpty && this.dimensionFilters.length === 0 && this.metricFilters.length === 0;
  }

  toString() {
    if (this.isEmpty()) { return null; }
    return `Filter: ${this.name}`;
  }

  validate() {
    GetChannel(this.channelType)?.transformer?.validateFilter?.(this);
  }

  toJSON() {
    if (this.isEmpty()) {
      return { id: this.id };
    }
    return {
      id: this.id,
      name: this.name,
      segment: this.segment,
      dimensionFilters: this.dimensionFilters.map(f => f),
      metricFilters: this.metricFilters.map(f => f),
      account: this.account,
    }
  }

  toJSONfiltered() {
    return {
      id: this.id
    }
  }

  static fromJSON(json) {
    if (!json) { return null; }
    return new Filter(
      json.name,
      json.segment,
      json.dimensionFilters,
      json.metricFilters,
      json.id,
      json.account
    );
  }

  static new(chart, ...params) {
    const filter = new Filter(...params);
    filter.channelType = chart.channelType;
    filter.account = filter.account ?? chart.account;
    filter.validate();
    return filter;
  }

}

export const NEW_FILTER = {
  boolOperator: null,
  filter: null,
  operator: null,
  value: [""],
};

export class Metric {
  constructor(metric, filter = new Filter()) {
    this.channelType = null;
    this.metric = metric;
    this.filter = filter;
  }

  static copy(metric) {
    const newMetric = new Metric({ ...metric.metric }, Filter.copy(metric.filter));
    newMetric.channelType = metric.channelType;
    return newMetric;
  }

  isEqual(metric) {
    return (
      this.metric?.id === metric.metric?.id &&
      this.filter.isEqual(metric.filter)
    );
  }

  getFilterString() {
    const filterString = this.filter.toString();
    if (filterString) {
      return `Metric: ${this.metric.name}, ${filterString}`
    }
    return null;
  }

  validate() {
    this.filter.channelType = this.channelType;
    this.filter.validate();
  }

  toJSON() {
    return {
      ...this.metric,
      id: this.metric?.id,
      dataSource: this.metric?.dataSource,
      filter: this.filter?.toJSON(),
    }
  }

  toJSONfiltered() {
    return {
      ...this.metric,
      id: this.metric?.id,
      dataSource: this.metric?.dataSource,
      filter: this.filter?.id ? this.filter?.toJSONfiltered() : this.filter?.toJSON(),
    }
  }

  static fromJSON(json) {
    const metric = new Metric(
      { ...json, id: json.id, dataSource: json.dataSource },
    );
    const filter = Filter.fromJSON(json.filter);
    if (filter) { metric.filter = filter; }
    return metric;
  }

  static new(chart, metric) {
    const newMetric = new Metric(metric);
    newMetric.channelType = chart.channelType;
    newMetric.validate();
    return newMetric;
  }

}

export const ABSOLUTE_METRICS = {
  "google-analytics": ["INTEGER", "CURRENCY", "FLOAT"],
  "shopify-ads": ["FLOAT", "INTEGER"],
  "google-ads": ["INTEGER", "FLOAT", "CURRENCY"],
  "facebook-ads": ["Price", "Number"],
  "linkedin-ads": ["CURRENCY", "INTEGER", "FLOAT"],
  "bing-ads": ["FLOAT", "INTEGER", "CURRENCY"],
  "tiktok-ads": ["FLOAT", "INTEGER", "CURRENCY"],
  "google-analytics-4": ["INTEGER", "CURRENCY", "FLOAT"],
  "appsflyer": ["INTEGER", "CURRENCY", "FLOAT"],
  "adjust": ["INTEGER", "CURRENCY", "FLOAT"],
  "default": ["INTEGER", "CURRENCY", "FLOAT", "Price", "Number"]
}

export const DATE_METRICS = ["Date", "Day", "Hour", "Week", "Month", "Quarter", "Year", "Hour of day", "Week of Year"]

export class Chart {
  constructor(
    channelType,
    title,
    type,
    table,
    gridPosition = {
      x: 0, y: 0, w: 0, h: 0, minW: ["ADD", "KPI", "TEXT"].includes(type) ? 3 : 6,
      minH: ["ADD", "KPI", "TEXT"].includes(type) ? 1 : 2, maxH: 4, static: false
    },
    leftMetrics = [],
    rightMetrics = ["LINE", "BAR", "BAR3D", "STACKBAR", "STACKBAR3D", "LINEBAR", "AREA"].includes(type) ? [] : null,
    dimensions = ["KPI", "PIE", "PIE3D", "DONUT", "DONUT3D", "FUNNEL"].includes(type) ? null
      : (ChartFilterSupport(type) || !channelType) ? [] : [GetChannel(channelType)?.properties.dateDimension],
    filter = ChartFilterSupport(type) ? new Filter() : null,
    accounts = null,
    chartStyle = {
      legendPosition: 'bottom',
      legendStyle: { fontStyle: 'Inter', fontSize: 16 }, palette: 0,
      titleStyle: {
        font: 'Inter', fontSize: type === "TEXT" ? '14' : '16', alignment: 'left',
        color: '#000000', fontFormat: type === "TEXT" ? [] : ['bold']
      },
      tableStyle: {
        tableHeader: { font: 'Inter', fontSize: 14 },
        tableContent: { font: 'Inter', fontSize: 14 }
      }
    },
    gridId = "",
    compareWith = "",
    dataLevel = "",
    excludeDeleted = true
  ) {
    this.channelType = channelType;
    this.title = title;
    this.type = type;
    this.table = table;
    this.gridPosition = gridPosition;
    this.leftMetrics = leftMetrics;
    this.rightMetrics = rightMetrics;
    this.dimensions = dimensions;
    this.filter = filter;
    this.accounts = Array.isArray(accounts) ? accounts : [accounts];
    this.chartStyle = chartStyle;
    this.id = uuid();
    this.gridId = gridId;
    this.compareWith = compareWith;
    this.dataLevel = dataLevel;
    this.excludeDeleted = excludeDeleted;
    this.validate();
  }

  copyValues(chart) {
    this.channelType = chart.channelType;
    this.title = chart.title;
    this.type = chart.type;
    this.table = chart.table;
    this.gridPosition = { ...chart.gridPosition };
    this.leftMetrics = chart.leftMetrics.map(m => Metric.copy(m));
    this.rightMetrics = chart.rightMetrics?.map(m => Metric.copy(m)) ?? null;
    this.dimensions = chart.dimensions?.map(d => ({ ...d })) ?? null;
    this.filter = Filter.copy(chart.filter);
    this.accounts = chart.accounts;
    this.chartStyle = chart.chartStyle;
    this.compareWith = chart.compareWith;
    this.dataLevel = chart.dataLevel;
    this.excludeDeleted = chart.excludeDeleted;
  }

  validate() {
    this.leftMetrics.forEach(metric => {
      metric.channelType = this.channelType;
      metric.validate();
    });
    this.rightMetrics?.forEach(metric => {
      metric.channelType = this.channelType;
      metric.validate();
    });
    if (this.filter) {
      this.filter.channelType = this.channelType;
      this.filter.validate();
    }
  }

  isEqual(chart) {
    if (!chart) { return false; }
    return (
      (this.channelType?.id ?? this.channelType) === (chart.channelType?.id ?? chart.channelType) &&
      this.title === chart.title &&
      this.type === chart.type &&
      this.table?.id === chart.table?.id &&
      this.leftMetrics.length === chart.leftMetrics.length && this.leftMetrics.every((metric, index) => metric.isEqual(chart.leftMetrics[index])) &&
      (this.rightMetrics ? this.rightMetrics.length === chart.rightMetrics.length && this.rightMetrics.every((metric, index) => metric.isEqual(chart.rightMetrics[index])) : chart.rightMetrics === null) &&
      (this.dimensions ? this.dimensions.length === chart.dimensions?.length && this.dimensions.every((dimension, index) => Chart.isDimensionEqual(dimension, chart.dimensions?.[index])) : chart.dimensions === null) &&
      (this.filter ? this.filter.isEqual(chart.filter) : chart.filter === null)
    );
  }

  toJSON() {
    return {
      id: this.id,
      chart_id: this.chartId,
      grid_id: this.gridId,
      source: this.channelType?.id ?? this.channelType,
      is_blends: this.channelType?.id ? true : false,
      account: this.account,
      accounts: this.account ? [this.account.id] : this.accounts,
      title: this.title,
      chart_type: this.type,
      table: this.table?.id,
      grid_position: {
        x: this.gridPosition.x, y: this.gridPosition.y,
        w: this.gridPosition.w, h: this.gridPosition.h,
        minW: ["ADD", "KPI", "TEXT"].includes(this.type) ? 3 : 6,
        minH: ["ADD", "KPI", "TEXT"].includes(this.type) ? 1 : 2, maxH: 4
      },
      left_metrics: this.leftMetrics.map(m => m.toJSON()),
      right_metrics: this.rightMetrics?.map(m => m.toJSON()),
      dimensions: this.dimensions?.filter(d => d),   //.map(d => ({ id: d.id, dataSource: d.dataSource })),
      filter: this.filter?.toJSON(),
      styling: this.chartStyle,
      compare_with: this.compareWith || "",
      dataLevel: this.dataLevel,
      excludeDeleted: this.excludeDeleted
    }
  }

  toJSONfiltered() {
    return {
      id: this.id,
      chart_id: this.chartId,
      grid_id: this.gridId,
      source: this.channelType?.id ?? this.channelType,
      is_blends: this.channelType?.id ? true : false,
      account: this.account,
      accounts: this.account ? [this.account.id] : this.accounts,
      title: this.title,
      chart_type: this.type,
      table: this.table?.id,
      grid_position: {
        x: this.gridPosition.x, y: this.gridPosition.y, w: this.gridPosition.w, h: this.gridPosition.h,
        minW: ["ADD", "KPI", "TEXT"].includes(this.type) ? 3 : 6,
        minH: ["ADD", "KPI", "TEXT"].includes(this.type) ? 1 : 2, maxH: 4
      },
      left_metrics: this.leftMetrics.map(m => m.toJSONfiltered()),
      right_metrics: this.rightMetrics?.map(m => m.toJSONfiltered()),
      dimensions: this.dimensions?.filter(d => d),  //.map(d => ({ id: d.id, dataSource: d.dataSource })),
      filter: this.filter?.toJSONfiltered(),
      styling: this.chartStyle,
      compare_with: this.compareWith || "",
      dataLevel: this.dataLevel,
      excludeDeleted: this.excludeDeleted
    }
  }

  static fromJSON(json) {
    let { x = 0, y = 0,  w = 3, h = 1 } = json.grid_position;
    const chart = new Chart(
      json.is_blends ? { id: json.source } : json.source,
      json.title,
      json.chart_type,
      { id: json.table },
      {
        x, y,
        h: ["ADD", "KPI", "TEXT"].includes(json.chart_type) || h >= 2 ? h : 2,
        w: ["ADD", "KPI", "TEXT"].includes(json.chart_type) || w >= 6 ? w : 6,
        minW: ["ADD", "KPI", "TEXT"].includes(json.chart_type) ? 3 : 6,
        minH: ["ADD", "KPI", "TEXT"].includes(json.chart_type) ? 1 : 2,
        maxH: 4,
        static: false,
      },
      json.left_metrics?.map((m) => Metric.fromJSON(m)),
      json.right_metrics?.map((m) => Metric.fromJSON(m)),
      !json.dimensions ? null : json.dimensions //typeof (json.dimensions[0]) === "object" ? json.dimensions : json.dimensions.map(d => ({ id: d })),
    );
    chart.gridId = json.grid_id;
    if (!chart.table.id) { chart.table = null; }
    const filter = Filter.fromJSON(json.filter);
    if (filter) { chart.filter = filter; }
    chart.id = json.id;
    chart.chartId = json.chart_id;
    chart.accounts = json.accounts;
    chart.account = json.account;
    chart.chartStyle = json.styling;
    chart.compareWith = json.compare_with;
    chart.dataLevel = json.dataLevel;
    chart.excludeDeleted = json.excludeDeleted;
    chart.isBlends = json.is_blends;
    chart.validate();
    return chart;
  }

  static new(...params) {
    if (params.length > 0) {
      return new Chart(...params);
    }
    return new Chart(
      ChannelType.Default,
      "",
      "ADD",
      null,
      { x: 0, y: 0, w: 6, h: 2, minH: 1, minW: 3, maxH: 4, static: false }
    );
  }

  static isDimensionEqual(baseDimension, changeDimension) {
    return baseDimension?.id === changeDimension?.id && baseDimension?.dataSource === changeDimension?.dataSource;
  }

}
