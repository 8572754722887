import { Box, Typography } from "@mui/material"
import LeftBar from "../../LeftBar/LeftBar"
import CustomizeColumns from "./CustomizeColumns"
import { handleMixPanelData, EVENTS } from "../../../utils/mixpanelV2";
import { useEffect } from "react";

const CustomColumn = () => {
    useEffect(() => {
       handleMixPanelData(EVENTS.view_custom_col);
    },[]);
    return (
        <>
            <LeftBar />
            <Box sx={{ width: "100%", padding: "24px 24px 24px 124px", height: "100vh", overflow: "hidden" }}>
                <CustomizeColumns />
            </Box>
        </>
    )
}

export default CustomColumn