import Modal from 'react-modal';
import { Button, Grid, IconButton, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "#ffff",
        width: "500px",
        //   height:"400px",
        padding: "0",
        zIndex: 9000000000000,
        position: "absolute"
    },
};

const UpdatePasswordModal = (props) => {
    const { modal, updateUserPassword, userPassword, setUserPassword, isError, message, toggleUpdatePasswordModal } = props;

    return (
        <Modal isOpen={modal} style={customStyles}>
            <div style={{ width: "100%", height: "40px", borderBottom: "1px solid #eee", padding: "10px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div>
                    <b>Update your password</b>
                </div>
                <div>
                    <IconButton
                        onClick={() => toggleUpdatePasswordModal()}
                        aria-label="save"
                    >
                        <Close sx={{
                            borderRadius: '4px',
                            padding: '4px',
                            backgroundColor: '#F2F2F2'
                        }}
                        />
                    </IconButton>
                </div>
            </div>
            <div style={{ width: "100%", height: "calc(100% - 40px)", position: "relative", zIndex: 90000000, backgroundColor: "#ffff", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column", marginTop: '40px' }}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "70%" }}>
                    <Grid item xs={12} style={{ padding: '4px 0 4px 0', marginBottom: "15px" }}>
                        <TextField
                            label="Enter your previous password"
                            style={{ width: '100%' }}
                            id="outlined-basic"
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            type="password"
                            onChange={(e) => setUserPassword({ ...userPassword, oldPswd: e.target.value })}
                            variant="outlined" />
                    </Grid>
                    <Grid item xs={12} style={{ padding: '4px 0 4px 0', marginBottom: "15px" }}>
                        <TextField
                            label="Enter new password"
                            onChange={(e) => setUserPassword({ ...userPassword, newPswd: e.target.value })}
                            style={{ width: '100%' }}
                            id="outlined-basic"
                            type="password"
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            variant="outlined" />
                    </Grid>
                    <Grid item xs={12} style={{ padding: '4px 0 4px 0', marginBottom: "15px" }}>
                        <TextField
                            label="Re-enter new password"
                            style={{ width: '100%' }}
                            id="outlined-basic"
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            type="password"
                            onChange={(e) => setUserPassword({ ...userPassword, againNewPswd: e.target.value })}
                            variant="outlined" />
                    </Grid>
                    {
                        message !== null && <Grid item xs={12} style={{ padding: '4px 0 4px 0', marginBottom: "15px" }}>
                            {
                                isError ? <p style={{ color: "red", lineHeight: 1, fontSize: "10px" }}>{message}</p> : <p style={{ color: "green" }}>{message}</p>
                            }
                        </Grid>
                    }
                </div>
                <div style={{ width: "100%", borderTop: "1px solid #eee", padding: "10px", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>

                    <div>
                        <Button sx={{ padding: "5px 20px", textAlign: "right", float: "right", borderRadius: "10px", textTransform: "none" }} variant='contained' onClick={() => updateUserPassword()}>Update password</Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default UpdatePasswordModal;
