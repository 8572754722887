import { Box, Button, Grid, Typography } from "@mui/material";
import "../../../styles/filterpopup.scss";
import { useSelector } from "react-redux";
import Scrollbars from "react-custom-scrollbars";
import DataList from "../../CustomizingSources/BlendDataSource/DataList";
import { ReactComponent as AddIcon } from "../../../Assets/Icons/add_icon.svg";
import { ReactComponent as RemoveIcon } from "../../../Assets/Images/logos_remove_chart.svg";
import { ReactComponent as AndIcon } from "../../../Assets/Images/and.svg";
import "../../styles/profile.css";
import {
  REFRESH_ACTIONS,
  SCHEDULE_DAYS,
  TIMEZONES,
  SCHEDULE_DATES,
} from "../Utils";
import CustomTimePicker from "../../Common/TimePicker";
import CustomLoader from "../../Common/CustomLoader";

const defaultStartAt = {
  on: null,
  date: null,
  time: { hour: 10, min: 0 },
};

export default function TriggerSchedule({
  loading,
  infoComplete,
  scheduleData,
  setScheduleData,
  openIngestionBox,
  setOpenIngestionBox,
  isBulkEditing = false
}) {
  // infoComplete, scheduleData, setScheduleData, setOpenIngestionBox
  // updateId, channel, table, dataLevel, accounts, accountCols, extractData, resetData, setCompletion
  const user = useSelector((state) => state.user.user);
  const updateTime = (value, index) => {
    let { hour, min } = value;
    let newStartAts = Array.from(scheduleData.startAt);
    newStartAts[index] = {
      ...newStartAts[index],
      time: { hour: Number(hour), min: Number(min) },
    };
    setScheduleData({ ...scheduleData, startAt: [...newStartAts] });
  };

  return loading ? (
    <Box className="loader">
      <CustomLoader
        heading={"Waiting for account columns data"}
        subHeading=""
      />
    </Box>
  ) : (
    <Box className="trigger-form">
      <div
        className="Schedule_name"
        style={{
          borderBottom: "1px solid #eee",
          paddingBottom: "18px",
          paddingLeft: "16px",
          paddingTop: "18px",
        }}
      >
        <Typography className="extractorHeaders">
          Schedule Your Data Extraction
        </Typography>
      </div>

      <Scrollbars autoHide style={{ minHeight: "250px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "15px",
            height: "100%",
            padding: "10px 20px",
          }}
        >
          <Grid container xs={12} style={{ marginBottom: "10px" }}>
            <Grid item xs={5}>
              <div style={{ margin: "5px 0px", display: "flex" }}>
                <Typography className="newText">Action*</Typography>
              </div>
              <DataList
                metric={scheduleData.action}
                metrics={REFRESH_ACTIONS}
                isEditable={true}
                onClick={(action) =>
                  setScheduleData({
                    ...scheduleData,
                    action,
                    startAt: [{ ...defaultStartAt }],
                  })
                }
                defaultTitle="Chooose Action"
                disabled={isBulkEditing}
              />
            </Grid>
            <Grid item xs={5}>
              <div style={{ margin: "5px 0px", display: "flex" }}>
                <Typography className="newText">Timezone*</Typography>
              </div>
              <DataList
                metric={scheduleData.timeZone}
                metrics={TIMEZONES}
                isEditable={true}
                onClick={(timeZone) =>
                  setScheduleData({ ...scheduleData, timeZone })
                }
                defaultTitle="Chooose TimeZone"
                disabled={isBulkEditing}
              />
            </Grid>
            <Grid item key={scheduleData.action?.id} xs={12}>
              {scheduleData.startAt.map((startAt, index) => (
                <Grid container xs={12} style={{ marginBottom: "10px" }}>
                  {scheduleData.action?.id === "weekly" ? (
                    <Grid item xs={5} style={{ marginTop: "10px" }}>
                      <div style={{ margin: "5px 0px", display: "flex" }}>
                        <Typography className="newText">On*</Typography>
                      </div>
                      <DataList
                        metric={SCHEDULE_DAYS.find(
                          (day) => day.id === startAt?.on
                        )}
                        metrics={SCHEDULE_DAYS}
                        isEditable={true}
                        onClick={(on) => {
                          scheduleData.startAt[index].on = on.id;
                          setScheduleData({ ...scheduleData });
                        }}
                        defaultTitle="Select Schedule On"
                      />
                    </Grid>
                  ) : scheduleData.action?.id === "monthly" ? (
                    <Grid item xs={5} style={{ marginTop: "10px" }}>
                      <div style={{ margin: "5px 0px", display: "flex" }}>
                        <Typography className="newText">Date*</Typography>
                      </div>
                      <DataList
                        metric={SCHEDULE_DATES.find(
                          (date) => date.id === startAt?.date
                        )}
                        metrics={SCHEDULE_DATES}
                        isEditable={true}
                        onClick={(date) => {
                          scheduleData.startAt[index].date = date.id;
                          setScheduleData({ ...scheduleData });
                        }}
                        defaultTitle="Select Date"
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={5} style={{ marginTop: "10px" }}>
                    <div style={{ margin: "5px 0px", display: "flex" }}>
                      <Typography className="newText">Start at*</Typography>
                    </div>
                    <CustomTimePicker
                      time={startAt.time}
                      onChange={(value) => updateTime(value, index)}
                      disabled={isBulkEditing}
                    />
                  </Grid>
                  {scheduleData.action &&
                    scheduleData.action.id !== "hourly" && (
                      <Grid
                        item
                        xs={2}
                        style={{
                          display: "flex",
                          alignItems: "flex-end",
                          padding: "5px",
                        }}
                      >
                        {index === scheduleData.startAt.length - 1 ? (
                          <>
                            <AddIcon
                              style={{ height: "28px", width: "28px" }}
                              onClick={() => {
                                scheduleData.startAt.push({
                                  ...defaultStartAt,
                                });
                                setScheduleData({ ...scheduleData });
                              }}
                            />
                            {index !== 0 && (
                              <RemoveIcon
                                style={{
                                  height: "28px",
                                  width: "28px",
                                  marginLeft: "10px",
                                }}
                                onClick={() => {
                                  setScheduleData({
                                    ...scheduleData,
                                    startAt: scheduleData.startAt.slice(
                                      0,
                                      scheduleData.startAt.length - 1
                                    ),
                                  });
                                }}
                              />
                            )}
                          </>
                        ) : (
                          <AndIcon style={{ height: "28px", width: "28px" }} />
                        )}
                      </Grid>
                    )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Scrollbars>
      {!openIngestionBox && (
        <div
          className="footer_para"
          style={{
            width: "100%",
            height: "72px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "0px 16px",
          }}
        >
          <Button
            onClick={() => {
              setOpenIngestionBox(true);
            }}
            disabled={
              user.role === "viewer" ||
              !infoComplete.triggerComplete ||
              !infoComplete.scheduleComplete
            }
            variant="contained"
            sx={{
              backgroundColor: "#0869FB",
              variant: "contained",
              "&:hover": {
                backgroundColor: "#0869FB",
                borderColor: "#0062cc",
                boxShadow: "none",
              },
              color: "#ffffff",
              ":disabled": { color: "#0869FB88", backgroundColor: "#EBF3FF" },
            }}
          >
            Next
          </Button>
        </div>
      )}
    </Box>
  );
}
