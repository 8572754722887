import { Box, Button, IconButton, Modal, TextField, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useState } from 'react';
import { connect } from 'react-redux';
import { channelType } from '../../api/appsflyer/transformer';
import { connectAccountApi, closeConnectingAppsflyer } from '../../api/appsflyer/action';
import { ReactComponent as UserIcon } from '../../Assets/Icons/User.svg';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "25%",
    minWidth: "384px",
    bgcolor: "#FFF",
    borderRadius: "12px",
    boxShadow: 24,
    padding: "24px"
};

const AppsflyerConnectModal = ({ isConnecting, connectAccountApi, close, redirect_url = "selectchannels" }) => {
    const [state, setState] = useState({
        appsflyer_access_token: "",
        token_name: ""
    });

    const handleChange = (property, value) => {
        setState({ ...state, [property]: value });
    }

    return (
        <Modal open={isConnecting} onClose={close} aria-labelledby="Connect AppsFlyer" >
            <Box sx={style}>
                <div className='displayRowSpaced'>
                    <div className='connect-account-logo'>
                        <UserIcon />
                    </div>
                    <IconButton aria-label="save" style={{ backgroundColor: '#F6F7F8', borderRadius: "8px" }} onClick={close}>
                        <Close />
                    </IconButton>
                </div>

                <Typography style={{ fontSize: "20px", fontWeight: "600", margin: "16px 0px 8px" }}>
                    New Appsflyer Account
                </Typography>
                <div style={{ color: "#5C6674" }}>
                    <p style={{ marginBottom: "10px" }}>
                        Follow these <a href="https://support.appsflyer.com/hc/en-us/articles/360004562377-Managing-API-tokens" target="_blank">instructions</a> to connect your appsflyer account.
                    </p>
                    <ul style={{ textAlign: "left", padding: "0px 0px 0px 25px" }}>
                        <li>
                            From the menu bar, access the user menu (email address drop-down
                            at the top right corner).
                        </li>
                        <li>Select Security center.</li>
                        <li>
                            In the AppsFlyer API tokens section, click Manage your AppsFlyer
                            API tokens.
                        </li>
                    </ul>
                </div>

                <div style={{ margin: "32px 0px" }}>
                    <Typography style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "400", lineHeight: "22px", marginBottom: "8px" }}>
                        Access Token Name
                    </Typography>
                    <TextField
                        hiddenLabel
                        style={{ width: '100%' }}
                        placeholder='Enter access token name'
                        size="small"
                        id="access_token_name"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        onChange={(e) => handleChange("token_name", e.target.value)}
                    />

                    <Typography style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "400", lineHeight: "22px", margin: "20px 0px 8px" }}>
                        Access Token
                    </Typography>
                    <TextField
                        style={{ width: '100%' }}
                        placeholder='*******************************'
                        size="small"
                        id="access_token"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        onChange={(e) => handleChange("appsflyer_access_token", e.target.value)}
                    />
                </div>

                <Button
                    sx={{ padding: "14px 24px", borderRadius: "8px", textTransform: "none", width: '100%', height: '40px', fontSize: '14px' }}
                    variant="contained"
                    onClick={() => connectAccountApi({ redirect_url, ...state })}
                >
                    Connect An Account
                </Button>
            </Box>
        </Modal>
    )
}

const mapStateToProps = state => ({
    isConnecting: state[channelType].isConnecting,
})

const mapDispatchToProps = dispatch => ({
    connectAccountApi: (_) => dispatch(connectAccountApi(_)),
    close: () => dispatch(closeConnectingAppsflyer()),
})

export default (connect(mapStateToProps, mapDispatchToProps)(AppsflyerConnectModal));

