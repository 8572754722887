import React, { useEffect, useState } from "react";
import Modal from 'react-modal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Select, { components } from "react-select";
import StarIcon from '@mui/icons-material/Star';
import { Button, Grid, IconButton, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import { changeUserRole } from "../../api/user/saga";

const Option = (props) => {
	return (
		<components.Option {...props}>
			<div className="left">
				<strong className="title">{props.data.label}</strong>
				<div style={{ fontSize: "10px" }}>{props.data.description}</div>
			</div>
		</components.Option>
	);
};

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: "#ffff",
		width: "400px",
		minHeight: "450px",
		padding: "0",
		zIndex: 9999999999,
		position: "absolute",
		overflow: 'hidden'
	},
};

const ChangeInvitedTeamModal = (props) => {
	const { modal, setModal, changeUserRoleData, setChangeUserRoleData, getOrganizationMember } = props;
	const [isEmailValid, setEmailValid] = useState(false);
	const [isError, setError] = useState(false);
	const [errorMessageOrSuccess, setErrorMessageOrSuccess] = useState("");
	const [dropDownValues, setDropDownValues] = useState([
		// {
		// 	value: "superadmin",
		// 	label: "Super Admin",
		// 	description:"Accessible to add and manage the data sources and organization members"
		// },
		{
			value: "admin",
			label: "Admin",
			description: "Accessible to manage the data sources"
		},
		{
			value: "editor",
			label: "Editor",
			description: "Accessible to modify the reports"
		},
		{
			value: "viewer",
			label: "Viewer",
			description: "Accessible to view the reports"
		}
	])

	const getDropdownValue = (data) => {
		const { value } = data;
		setChangeUserRoleData({ ...changeUserRoleData, newRole: value });
	};
	const getEmail = (email) => {
		setChangeUserRoleData({ ...changeUserRoleData, email: email });
		if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
			setEmailValid(false);
			return;
		}
		setEmailValid(true);
	}

	useEffect(() => {
		setEmailValid(false);
		setError(false);
		setErrorMessageOrSuccess("");
	}, [modal]);

	const isValidation = (userData) => {
		let error = { errorMessage: "", isError: false, isEmailValid: true };
		if (userData.email === "") {
			error.errorMessage = "email can't be empty";
			error.isError = true;
			return;
		}
		if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(userData.email)) {
			error.errorMessage = "enter valid email";
			error.isEmailValid = false
			error.isError = true;
		}
		return error;
	}


	const changeInvitedUserRole = async () => {
		const isAnyError = isValidation(changeUserRoleData);
		if (isAnyError.isError) {
			setError(true);
			setErrorMessageOrSuccess(isAnyError.errorMessage);
			return;
		}
		changeUserRole({
			user_uuid: changeUserRoleData.uuid,
			updated_role: changeUserRoleData.newRole,
			changed_user_email: changeUserRoleData.email,
			existing_user_role: changeUserRoleData.existingRole,
			new_user_role: changeUserRoleData.newRole
		}).then((data) => {
			setErrorMessageOrSuccess(data.message);
			setError(false);
			getOrganizationMember();
			setTimeout(() => {
				setModal(!modal);
			}, 2000);
		}).catch((err) => {
			setErrorMessageOrSuccess(err.data.message);
			setError(true);
		})
	}
	return (
		<>
			<Modal isOpen={modal} style={customStyles}>
				<div style={{ width: "100%", height: "40px", borderBottom: "1px solid #eee", padding: "10px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
					<div>
						<b>Change the user role</b>
					</div>
					<IconButton
						onClick={() => setModal(!modal)}
						aria-label="save"
						sx={{
							borderRadius: '4px',
							padding: '2px',
							backgroundColor: '#F2F2F2',
							fontSize: '18px',

						}}
						size="small"
					>
						<Close />
					</IconButton>
				</div>
				<div style={{ width: "100%", height: "calc(100% - 40px)", position: "relative", zIndex: 90000000, backgroundColor: "#ffff", justifyContent: "center", alignItems: "center", display: "flex" }}>
					<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "80%" }}>
						<Grid item xs={12} style={{ padding: '4px 0 4px 0', marginBottom: "15px", marginTop: "5px" }}>
							{errorMessageOrSuccess !== "" && <p style={{ color: isError ? "red" : "green" }}>{errorMessageOrSuccess}</p>}
							<p><b>E-mail <sup style={{ color: "red" }}><StarIcon style={{ fontSize: "9px" }} /></sup></b></p>
							<TextField
								placeholder="Invite someone..."
								sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
								style={{ width: '100%' }}
								id="outlined-basic"
								InputLabelProps={{ shrink: true }}
								value={changeUserRoleData.email}
								inputProps={{ readOnly: true }}
								InputProps={{
									endAdornment: <CheckCircleIcon style={{ color: "#1CC9AA" }} />
								}}
								size="small"
								variant="outlined" />
						</Grid>
						<Grid item xs={12} style={{ padding: '4px 0 4px 0', marginBottom: "15px" }}>
							<p><b>Existing Role</b></p>
							<TextField
								sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
								placeholder="Enter your name"
								style={{ width: '100%' }}
								size="small"
								id="outlined-basic"
								inputProps={{ readOnly: true }}
								value={changeUserRoleData.existingRole}
								InputLabelProps={{ shrink: true, style: { color: "black", fontWeight: "bolder" } }}
								variant="outlined" />
						</Grid>
						<Grid item xs={12} style={{ padding: '4px 0 4px 0', marginBottom: "15px" }}>
							<p><b>New Role</b></p>
							<Select
								label="Single select"
								placeholder="Select Role"
								options={dropDownValues.filter(role => role.value !== changeUserRoleData.existingRole)}
								components={{
									Option
								}}
								styles={{
									menuPortal: provided => ({ ...provided, zIndex: 9999 }),
									menu: provided => ({ ...provided, zIndex: 9999 })
								}}
								menuPlacement="bottom"
								menuPortalTarget={document.body}
								menuPosition={'fixed'}
								onChange={getDropdownValue}
							/>
						</Grid>
						<Grid item xs={12} style={{ padding: '4px 0 4px 0', marginBottom: "15px" }}>
							<p><b>State Your Reason</b></p>
							<TextField
								sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
								placeholder="Optional"
								style={{ width: '100%' }}
								multiline={true}
								rows={3}
								size="small"
								id="outlined-basic"
								onChange={(e) => setChangeUserRoleData({ ...changeUserRoleData, reason: e.target.value })}
								InputLabelProps={{ shrink: true, style: { color: "black", fontWeight: "bolder" } }}
								variant="outlined" />
						</Grid>
						<Grid style={{ padding: '4px 0 4px 0' }} item xs={12} alignItems="flex-end">
							<Button style={{ backgroundColor: "#0869FB", color: "white", padding: "8px 15px", textAlign: "right", float: "right", borderRadius: "10px", marginTop: "0px", textTransform: "none", transform: "translateY(-10px)" }} onClick={() => changeInvitedUserRole()}>Save Changes</Button>
						</Grid>
					</div>
				</div>
			</Modal>
		</>
	)
}
export default ChangeInvitedTeamModal;