export const AUTHORIZE_EMAIL = "AUTHORIZE_EMAIL";
export const CHECK_EMAIL = "CHECK_EMAIL";
export const DISCONNECT_EMAIL = "DISCONNECT_EMAIL"
export const EMAIL_SUCCESS = "EMAIL_SUCCESS";
export const EMAIL_FAILURE = "EMAIL_FAILURE";
export const GET_SHEET_URLS = "GET_SHEET_URLS";
export const SHEETS_SUCCESS = "SHEETS_SUCCESS";
export const SHEETS_FAILURE = "SHEETS_FAILURE";

export const CREATE_TRIGGER = "CREATE_TRIGGER";
export const UPDATE_TRIGGER = "UPDATE_TRIGGER";
export const DELETE_TRIGGER = "DELETE_TRIGGER";
export const CREATE_TRIGGER_SUCCESS = "CREATE_TRIGGER_SUCCESS";
export const CREATE_TRIGGER_FAILURE = "CREATE_TRIGGER_FAILURE";
export const UPDATE_TRIGGER_SUCCESS = "UPDATE_TRIGGER_SUCCESS";
export const UPDATE_TRIGGER_FAILURE = "UPDATE_TRIGGER_FAILURE";
export const DELETE_TRIGGER_SUCCESS = "DELETE_TRIGGER_SUCCESS";
export const DELETE_TRIGGER_FAILURE = "DELETE_TRIGGER_FAILURE";

export const GET_ALL_TRIGGERS = "GET_ALL_TRIGGERS";
export const GET_ALL_TRIGGERS_SUCCESS = "GET_ALL_TRIGGERS_SUCCESS";
export const GET_ALL_TRIGGERS_FAILURE = "GET_ALL_TRIGGERS_FAILURE";
export const GET_ALL_LOGS = "GET_ALL_LOGS";
export const GET_ALL_LOGS_SUCCESS = "GET_ALL_LOGS_SUCCESS";
export const GET_ALL_LOGS_FAILURE = "GET_ALL_LOGS_FAILURE";

export const GET_TRIGGER = "GET_TRIGGER";
export const GET_TRIGGER_SUCCESS = "GET_TRIGGER_SUCCESS";
export const GET_TRIGGER_FAILURE = "GET_TRIGGER_FAILURE";

export const RUN_TRIGGER = "RUN_TRIGGER"
export const RUN_TRIGGER_SUCCESS = "RUN_TRIGGER_SUCCESS"
export const RUN_TRIGGER_FAILURE = "RUN_TRIGGER_FAILURE"

export const SELECT_BULK_EDITING = "SELECT_BULK_EDITING"
export const UPDATE_BULK_TRIGGER = "UPDATE_BULK_TRIGGER"