export const CREATE_FILTER = "CREATE_FILTER";
export const UPDATE_FILTER = "UPDATE_FILTER";
export const DELETE_FILTER = "DELETE_FILTER";
export const CREATE_FILTER_SUCCESS = "CREATE_FILTER_SUCCESS";
export const CREATE_FILTER_FAILURE = "CREATE_FILTER_FAILURE";
export const UPDATE_FILTER_SUCCESS = "UPDATE_FILTER_SUCCESS";
export const UPDATE_FILTER_FAILURE = "UPDATE_FILTER_FAILURE";
export const DELETE_FILTER_SUCCESS = "DELETE_FILTER_SUCCESS";
export const DELETE_FILTER_FAILURE = "DELETE_FILTER_FAILURE";

export const GET_ALL_FILTERS = "GET_ALL_FILTERS";
export const GET_ALL_FILTERS_SUCCESS = "GET_ALL_FILTERS_SUCCESS";
export const GET_ALL_FILTERS_FAILURE = "GET_ALL_FILTERS_FAILURE";

export const GET_FILTER = "GET_FILTER";
export const GET_FILTER_SUCCESS = "GET_FILTER_SUCCESS";
export const GET_FILTER_FAILURE = "GET_FILTER_FAILURE";

