import { Grid, Tab } from "../../models/report";
import { Chart, Metric, Filter } from "../../models/chart";
import { channelType } from "./transformer";

// tables
const CUSTOMER = { id: "customer", name: "Customer" };
const AD_GROUP = { id: "ad_group", name: "Ad Group" };

// metrics
const CONVERSIONS = { id: "metrics.conversions", name: "Conversions" };
const CLICKS = { id: "metrics.clicks", name: "Clicks" };
const IMPRESSIONS = { id: "metrics.impressions", name: "Impressions" };
const COST = { id: "metrics.cost_micros", name: "Cost Micros" };
const COST_PER_ALL_CONVERSIONS = { id: "metrics.cost_per_all_conversions", name: "Cost Per All Conversions" };
// { id: "metrics.average_cost", name: "Average Cost" }
const CTR = { id: "metrics.ctr", name: "Ctr" };
const COST_PER_CONVERSION = { id: "metrics.cost_per_conversion", name: "Cost Per Conversion" };
const CONVERSIONS_VALUE = { id: "metrics.conversions_value", name: "Conversions Value" };
const AVERAGE_CPC = { id: "metrics.average_cpc", name: "Average Cpc" };

// dimensions
const CAMPAIGN_NAME = { id: "campaign.name", name: "Name" };
const ADGROUP_NAME = { id: "ad_group.name", name: "Name" };
const DEVICE = { id: "segments.device", name: "Device" }

const overallCharts = [
  new Chart(channelType, "Conversion Over time", "LINE", CUSTOMER,
    { x: 0, y: 0, w: 6, h: 2, static: false },
    [new Metric(CONVERSIONS)],
  ),
  new Chart(channelType, "Funnel activity chart", "FUNNEL", CUSTOMER,
    { x: 6, y: 0, w: 6, h: 2, static: false },
    [
      new Metric(IMPRESSIONS),
      new Metric(CLICKS),
      new Metric(CONVERSIONS),
    ],
  ),
  new Chart(channelType, "Cost Per conversion", "KPI", CUSTOMER,
    { x: 0, y: 2, w: 3, h: 1, static: false },
    [new Metric(COST_PER_CONVERSION)],
  ),
  new Chart(channelType, "Conversions", "KPI", CUSTOMER,
    { x: 3, y: 2, w: 3, h: 1, static: false },
    [new Metric(CONVERSIONS)],
  ),
  new Chart(channelType, "Clicks", "KPI", CUSTOMER,
    { x: 6, y: 2, w: 3, h: 1, static: false },
    [new Metric(CLICKS)],
  ),
  new Chart(channelType, "Conversion Rate", "KPI", CUSTOMER,
    { x: 9, y: 2, w: 3, h: 1, static: false },
    [new Metric({ id: "metrics.conversions_from_interactions_rate", name: "Conversions From Interactions Rate",  })],
  ),
  new Chart(channelType, "Total Cost", "KPI", CUSTOMER,
    { x: 0, y: 3, w: 3, h: 1, static: false },
    [new Metric(COST)],
  ),
  new Chart(channelType, "Cost per click", "KPI", CUSTOMER,
    { x: 3, y: 3, w: 3, h: 1, static: false },
    [new Metric(AVERAGE_CPC)],
  ),
  new Chart(channelType, "Click Through Rate", "KPI", CUSTOMER,
    { x: 6, y: 3, w: 3, h: 1, static: false },
    [new Metric(CTR)],
  ),
  new Chart(channelType, "Impressions", "KPI", CUSTOMER,
    { x: 9, y: 3, w: 3, h: 1, static: false },
    [new Metric(IMPRESSIONS)],
  ),
  new Chart(channelType, "Performing Campaigns", "TABLE", AD_GROUP,
    { x: 0, y: 4, w: 12, h: 2, static: false },
    [
      new Metric(CLICKS),
      new Metric(IMPRESSIONS),
      new Metric(CTR),
      new Metric(COST),
      new Metric(AVERAGE_CPC),
      new Metric(CONVERSIONS),
      new Metric(COST_PER_ALL_CONVERSIONS),
    ],
    null,
    [CAMPAIGN_NAME],
  ),
  new Chart(channelType, "Top Ad Group", "TABLE", AD_GROUP,
    { x: 0, y: 6, w: 12, h: 2, static: false },
    [
      new Metric(CLICKS),
      new Metric(IMPRESSIONS),
      new Metric(CTR),
      new Metric(COST),
      new Metric(AVERAGE_CPC),
      new Metric(CONVERSIONS),
      new Metric(COST_PER_ALL_CONVERSIONS),
    ],
    null,
    [ADGROUP_NAME],
  ),
  new Chart(channelType, "Conversions by device", "TABLE", CUSTOMER,
    { x: 0, y: 6, w: 12, h: 2, static: false },
    [
      new Metric(CLICKS),
      new Metric(IMPRESSIONS),
      new Metric(CTR),
      new Metric(COST),
      new Metric(AVERAGE_CPC),
      new Metric(CONVERSIONS),
      new Metric(COST_PER_ALL_CONVERSIONS),
    ],
    null,
    [DEVICE],
  ),
  new Chart(channelType, "Conversions by gender", "TABLE", { id: "gender_view", name: "Gender View" },// check
    { x: 0, y: 6, w: 12, h: 2, static: false },
    [
      new Metric(CLICKS),
      new Metric(IMPRESSIONS),
      new Metric(CTR),
      new Metric(COST),
      new Metric(AVERAGE_CPC),
      new Metric(CONVERSIONS),
      new Metric(COST_PER_ALL_CONVERSIONS),
    ],
    null,
    [{ id: "ad_group_criterion.gender.type", name: "Type" }, CAMPAIGN_NAME, ADGROUP_NAME],
  ),
  new Chart(channelType, "Keywords", "TABLE", { id: "search_term_view", name: "Search Term View" },
    { x: 0, y: 8, w: 12, h: 2, static: false },
    [
      new Metric(CLICKS),
      new Metric(IMPRESSIONS),
      new Metric(CTR),
      new Metric(COST),
      new Metric(AVERAGE_CPC),
      new Metric(CONVERSIONS),
      new Metric(COST_PER_ALL_CONVERSIONS),
    ],
    null,
    [
      { id: "search_term_view.search_term", name: "Search Term" },
      { id: "segments.search_term_match_type", name: "Search Term Match Type" },
      CAMPAIGN_NAME,
      ADGROUP_NAME,
    ],
  ),
];

const overallGrid = [
  new Grid(null, null, null, overallCharts),
];


const onsiteConversionsCharts = [
  new Chart(channelType, "Conversion Funnel", "FUNNEL", CUSTOMER,
    { x: 0, y: 0, w: 6, h: 2, static: false },
    [
      new Metric(IMPRESSIONS),
      new Metric(CLICKS),
      new Metric(CONVERSIONS),
    ],
  ),
  new Chart(channelType, "Conversions", "KPI", CUSTOMER,
    { x: 6, y: 0, w: 3, h: 1, static: false },
    [new Metric(CONVERSIONS)],
  ),
  new Chart(channelType, "Cost Per conversion", "KPI", CUSTOMER,
    { x: 9, y: 0, w: 3, h: 1, static: false },
    [new Metric(COST_PER_CONVERSION)],
  ),
  new Chart(channelType, "Cost per all conversions", "KPI", CUSTOMER,
    { x: 6, y: 1, w: 6, h: 1, static: false },
    [new Metric(COST_PER_ALL_CONVERSIONS)],
  ),
  new Chart(channelType, "View Through Conversions", "KPI", CUSTOMER,
    { x: 9, y: 2, w: 3, h: 1, static: false },
    [new Metric({ id: "metrics.view_through_conversions", name: "View Through Conversions" })],
  ),
  new Chart(channelType, "All Conversions Value", "KPI", CUSTOMER,
    { x: 0, y: 3, w: 3, h: 1, static: false },
    [new Metric({ id: "metrics.all_conversions_value", name: "All Conversions Value" })],
  ),
  new Chart(channelType, "Conversion List", "TABLE", CUSTOMER,
    { x: 3, y: 3, w: 6, h: 2, static: false },
    [
      new Metric(CONVERSIONS),
      new Metric({ id: "metrics.view_through_conversions", name: "View Through Conversions" }),
    ],
    null,
    [{ id: "segments.conversion_action", name: "Conversion Action" }],
  ),
  new Chart(channelType, "Campaign performance", "TABLE", { id: "campaign", name: "Campaign" },
    { x: 0, y: 5, w: 6, h: 2, static: false },
    [
      new Metric(IMPRESSIONS),
      new Metric(CLICKS),
      new Metric(CTR),
      // new Metric(COST),
      new Metric(CONVERSIONS),
      new Metric(COST_PER_CONVERSION),
      new Metric(CONVERSIONS_VALUE),
    ],
    null,
    [{ id: "campaign.base_campaign", name: "Base Campaign" }],
  ),
  new Chart(channelType, "Ad Groups", "TABLE", AD_GROUP,
    { x: 6, y: 5, w: 6, h: 2, static: false },
    [
      new Metric(CLICKS),
      new Metric(CONVERSIONS),
      new Metric(COST_PER_CONVERSION),
      new Metric(CONVERSIONS_VALUE),
    ],
    null,
    [ADGROUP_NAME],
  ),
  new Chart(channelType, "Ad Group Ad", "TABLE", AD_GROUP,
    { x: 0, y: 7, w: 6, h: 2, static: false },
    [
      new Metric(CLICKS),
      new Metric(CONVERSIONS),
      new Metric(COST_PER_CONVERSION),
      new Metric(CONVERSIONS_VALUE),
    ],
    null,
    [ADGROUP_NAME],
  ),
];

const onsiteConversionsGrid = [
  new Grid(null, null, null, onsiteConversionsCharts),
];


const trafficCharts = [
  new Chart(channelType, "Click Funnel", "FUNNEL", CUSTOMER,
    { x: 0, y: 0, w: 6, h: 2, static: false },
    [
      new Metric(IMPRESSIONS),
      new Metric({ id: "metrics.active_view_impressions", name: "Active View Impressions" }),
      new Metric(CLICKS),
    ],
  ),
  new Chart(channelType, "Impressions/Clicks", "BAR", CUSTOMER,
    { x: 6, y: 0, w: 6, h: 2, static: false },
    [new Metric(IMPRESSIONS)],
    [new Metric(CLICKS)],
  ),
  new Chart(channelType, "Clicks", "KPI", CUSTOMER,
    { x: 6, y: 0, w: 3, h: 1, static: false },
    [new Metric(CLICKS)],
  ),
  new Chart(channelType, "Impressions", "KPI", CUSTOMER,
    { x: 9, y: 0, w: 3, h: 1, static: false },
    [new Metric(IMPRESSIONS)],
  ),
  new Chart(channelType, "Click Through Rate", "KPI", CUSTOMER,
    { x: 6, y: 2, w: 3, h: 1, static: false },
    [new Metric(CTR)],
  ),
  new Chart(channelType, "Average CPC", "KPI", CUSTOMER,
    { x: 9, y: 2, w: 3, h: 1, static: false },
    [new Metric(AVERAGE_CPC)],
  ),
  new Chart(channelType, "Cost", "KPI", CUSTOMER,
    { x: 0, y: 3, w: 3, h: 1, static: false },
    [new Metric(COST)],
  ),
  new Chart(channelType, "Network Type", "TABLE", AD_GROUP,
    { x: 3, y: 3, w: 6, h: 2, static: false },
    [
      new Metric(IMPRESSIONS),
      new Metric(CLICKS),
      new Metric(COST),
    ],
    null,
    [{ id: "segments.ad_network_type", name: "Ad Network Type" }],
  ),
  new Chart(channelType, "Country/City", "TABLE", { id: "geographic_view", name: "Geographic View" },
    { x: 0, y: 5, w: 6, h: 2, static: false },
    [
      new Metric(IMPRESSIONS),
      new Metric(CLICKS),
      new Metric(CTR),
      // new Metric(COST),
    ],
    null,
    // [{ id: "Country", name: "Country" }, { id: "City", name: "City" }],
  ),
  new Chart(channelType, "Keywords", "TABLE", { id: "keyword_view", name: "Keyword View" },
    { x: 6, y: 5, w: 6, h: 2, static: false },
    [
      new Metric(IMPRESSIONS),
      new Metric(CLICKS),
      new Metric(CTR),
    ],
    null,
    // [{ id: "Keywords", name: "Keywords" }],
  ),
  new Chart(channelType, "Landing Pages", "TABLE", { id: "landing_page_view", name: "Landing Page View" },
    { x: 0, y: 7, w: 6, h: 2, static: false },
    [
      new Metric(IMPRESSIONS),
      new Metric(CLICKS),
      new Metric(CTR),
    ],
    null,
    // [{ id: "Expanded Final URL", name: "Expanded Final URL" }],
  ),
];

const trafficGrid = [
  new Grid(null, null, null, trafficCharts),
];

export default [
  new Tab("Overall", 0, overallGrid),
  // new Tab("On Site Conversions", 1, onsiteConversionsGrid),
  // new Tab("Traffic", 2, trafficGrid),
];
