import React from "react";
import { Box, Button, Modal, Typography } from '@mui/material';
import "../styles/modal.css";
import CancelIcon from '@mui/icons-material/Cancel';

const FailureModal = (props) => {
    const { modal, setModal, message, error } = props;

    const customStyles = {
        top: '30%',
        left: '35%',
        right: 'auto',
        bottom: 'auto',
        backgroundColor: "#ffff",
        borderRadius: "8px",
        width: "30%",
        minHeight: "auto",
        padding: "30px",
        zIndex: 9000000000000,
        position: "absolute",
        fontFamily: "Inter",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center"
    };

    return (
        <div>
            <Modal
                open={modal}
                onClose={() => setModal("none")}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={customStyles}>
                    <CancelIcon style={{ color: "#FF7A7D", width: "120px", height: "120px", padding: "10px" }} />
                    <Typography variant="body1" style={{ fontSize: "28px", fontWeight: 600, marginBottom: "10px" }}>
                        Failure
                    </Typography>
                    <Typography variant="body1" style={{ fontSize: "16px", fontWeight: 400, marginBottom: "10px", color: "#5A5A5A" }}>
                        {message}
                    </Typography>
                    <Typography variant="body1" style={{ fontSize: "16px", fontWeight: 400, marginBottom: "10px", color: "#5A5A5A" }}>
                        {error}
                    </Typography>
                    <Button variant="contained" style={{ borderRadius: "6px", marginTop: "10px" }}
                        onClick={() => { setModal("none"); }}
                    >
                        Close
                    </Button>
                </Box>

            </Modal >
        </div>
    )
}
export default FailureModal;
