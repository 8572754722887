import { all, put, select, takeLatest, takeLeading } from "redux-saga/effects";
import { api } from "..";
import { channelType } from "./transformer";
import { CHECK_AUTH, SELECT_ACCOUNT } from "../channel/type";
import mixpanelObj, { EVENTS } from "../../utils/mixpanel";

function* getClientID() {
    try {
        const response = yield api.get("/v2/analytics/channel/tiktok-ads/get-client-id");
        yield put({
            type: `${channelType}.GET_CLIENT_ID_SUCCESS`,
            payload: response.data.clientId
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_CLIENT_ID_FAILURE`,
            payload: error.response
        });
    }
}

function* connectAccount(args) {
    yield window.location.href = `https://ads.tiktok.com/marketing_api/auth?app_id=${process.env.REACT_APP_TIKTOK_APP_ID}&state=${channelType}&redirect_uri=${process.env.REACT_APP_BASE_URL + "/managesources/"}&rid=8rt4vyu37o4`;
}

function* sendCode(args) {
    try {
        yield api.post("/v2/analytics/tiktok-ads/auth", {
            code: args.payload.code,
            redirect_url: args.payload.redirect_url,
        });
        mixpanelObj.track(EVENTS.connect_source, {
            data_source: channelType,
            channel_type: "ads",
            connected: "Yes"
        }, true);
        window.location.href = "/managesources";
    } catch (error) {
        yield put({
            type: `${channelType}.CONNECT_ACCOUNT_FAILURE`,
        });
        mixpanelObj.track(EVENTS.connect_source, {
            data_source: channelType,
            channel_type: "ads",
            connected: "No"
        }, true);
        window.location.href = "/managesources" + `?channel=${channelType}&failure`;
    }
}

function* getAccountSummary(args) {
    try {
        yield put({ type: `${channelType}.START_LOADING` });
        const response = yield api.post(`/v2/analytics/tiktok-ads/get-account-summary?refresh=${args.payload.refresh}`);
        yield put({
            type: `${channelType}.GET_ACCOUNT_SUMMARY_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_ACCOUNT_SUMMARY_FAILURE`,
            payload: error.response
        });
    }
}

function* getFields() {
    try {
        const response = yield api.post("/v2/analytics/tiktok-ads/get-columns", {
            table_name: "Basic",
        });
        yield put({
            type: `${channelType}.GET_COLUMNS_SUCCESS`,
            payload: response.data,
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_COLUMNS_FAILURE`,
            payload: error.response
        });
    }
}

function* disconnectAccount() {
    try {
        yield api.post("/v2/analytics/tiktok-ads/revoke");
        yield put({
            type: `${channelType}.DISCONNECT_ACCOUNT_SUCCESS`,
        });
        yield put({
            type: SELECT_ACCOUNT,
            payload: {
                channel: channelType,
            }
        });
        yield put({
            type: CHECK_AUTH,
        });
        mixpanelObj.track(EVENTS.remove_source, { data_source: channelType, channel_type: "ads" }, true);
    } catch (error) {
        yield put({
            type: `${channelType}.DISCONNECT_ACCOUNT_FAILURE`,
        });
    }
}

function* getCurrency(args) {
    try {
        const response = yield api.post("/v2/analytics/tiktok-ads/get-currency", { advertiserId: args.payload.accountId });
        yield put({
            type: `${channelType}.GET_CURRENCY_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_CURRENCY_FAILURE`,
            payload: error.response
        });
    }
}

export default function* root() {
    yield all([
        // takeLatest(`${channelType}.GET_CLIENT_ID`, getClientID),
        takeLatest(`${channelType}.CONNECT_ACCOUNT`, connectAccount),
        takeLatest(`${channelType}.SEND_CODE`, sendCode),
        takeLeading(`${channelType}.GET_ACCOUNT_SUMMARY`, getAccountSummary),
        takeLatest(`${channelType}.GET_FIELDS`, getFields),
        takeLatest(`${channelType}.DISCONNECT_ACCOUNT`, disconnectAccount),
        takeLatest(`${channelType}.GET_CURRENCY`, getCurrency)
    ])
}

