import { defaultOrderBy } from "./Extractor/Extractor";
import moment from "moment";

export const transformToSuperMetricDataObject = (trigger) => {
  const { triggerData, scheduleData, storageData } = trigger;

  const triggerObj = {
    name: trigger.name,
    dataSource: trigger.channel.type,
    accounts: trigger.accounts,
    table: trigger.channel.type === "tiktok-ads" ? "BASIC" : trigger.table,
    dataLevel: trigger.dataLevel,
    dateRange: trigger.dateRange,
    compareDateRange: trigger.compareDateRange && { ...trigger.compareDateRange, alignDates: true },
    sendOverEmail: trigger.sendOverEmail,
    ...triggerData,
    metrics: triggerData.metrics.map((metric, index) => ({
      ...metric,
      aggregate: triggerData.aggregates[index] ?? "Sum",
    })),
    filters: Object.values(triggerData.filters),
    orderBys: triggerData.orderBys.filter((order) => order.fieldName),
    status: trigger.status,
    triggerSchedule: {
      ...scheduleData,
      refreshFrequency: scheduleData.action?.id,
      timeZone: scheduleData.timeZone?.id,
    },
    storage: {
      dataDestination: storageData.storage?.id,
      writePreference: storageData.writeMode?.id,
      hasHeaders: storageData.headerLine,
      startingCell: storageData.startCell,
      endingCell: storageData.endCell,
      spreadsheetCreate: storageData.newEmptySheet?.id === "yes",
      spreadsheetId: storageData.existingSheet
        ? ((storageData.newEmptySheet?.id === "no")
          ? storageData.existingSheet?.id
          : (storageData.sheetNomenclature == storageData.existingSheet?.name
            ? storageData.existingSheet?.id
            : ""
          )
        )
        : "",
      folder: storageData.existingFolder,
      spreadsheetName: storageData.existingSheet
        ? ((storageData.newEmptySheet?.id === "no")
          ? storageData.existingSheet?.name
          : (storageData.sheetNomenclature == storageData.existingSheet?.name
            ? storageData.existingSheet?.name
            : storageData.sheetNomenclature
          )
        )
        : storageData.sheetNomenclature,
      tabName: storageData.tabNomenclature,
      sheetNameFormat: storageData.sheetFormat.map(
        (format) => format?.id ?? format?.value ?? ""
      ),
      tabNameFormat: storageData.tabFormat.map(
        (format) => format?.id ?? format?.value ?? ""
      ),
    },
  };

  return triggerObj;
};

export const transformFromSuperMetricDataObject = (trigger, isDuplicate) => {
  const { triggerSchedule, storage } = trigger;

  let triggerData = {
    metrics: trigger.metrics,
    aggregates: trigger.metrics.map((metric) => metric.aggregate ?? "Sum"),
    segregate: trigger.segregate,
    dimensions: trigger.dimensions,
    numberOfRows: trigger.numberOfRows,
    orderBys: trigger.orderBys.length
      ? trigger.orderBys
      : [{ ...defaultOrderBy }],
    filters:
      trigger.filters?.reduce(
        (filterObj, filter) => ({
          ...filterObj,
          [filter.account?.title]: filter,
        }),
        {}
      ) ?? {},
  };
  let scheduleData = {
    action: REFRESH_ACTIONS.find(
      (action) => action.id === triggerSchedule.refreshFrequency
    ),
    timeZone: TIMEZONES.find((zone) => zone.id === triggerSchedule.timeZone),
    startAt: [...triggerSchedule.startAt],
  };
  let storageData = {
    storage: STORAGE_OPTIONS.find(
      (store) => store.id === storage.dataDestination
    ),
    writeMode: WRITE_MODE_OPTIONS.find(
      (mode) => mode.id === storage.writePreference
    ),
    headerLine: storage.hasHeaders,
    startCell: storage.startingCell,
    endCell: storage.endingCell,
    newEmptySheet: storage.spreadsheetCreate
      ? BOOLEAN_OPTIONS[0]
      : BOOLEAN_OPTIONS[1],
    existingSheet: storage.spreadsheetId
      ? { id: storage.spreadsheetId, name: storage.spreadsheetName }
      : null,
    existingFolder: storage.folder
      ? { id: storage.folder.id, name: storage.folder.name, type: "folder" }
      : { id: "root", name: "Root Folder", type: "folder" },
    sheetNomenclature: storage.spreadsheetName,
    tabNomenclature: storage.tabName,
    sheetFormat: storage.sheetNameFormat.map((format) =>
      format.startsWith("{")
        ? DYNAMIC_PARA_OPTIONS.find((para) => para.id === format)
        : { custom: true, value: format }
    ),
    tabFormat: storage.tabNameFormat.map((format) =>
      format.startsWith("{")
        ? DYNAMIC_PARA_OPTIONS.find((para) => para.id === format)
        : { custom: true, value: format }
    ),
  };
  let mapTrigger = {
    name: isDuplicate ? "Duplicate of " + trigger.name : trigger.name,
    channelType: trigger.dataSource,
    accounts: trigger.accounts,
    table: trigger.table,
    dataLevel: trigger.dataLevel,
    dateRange: trigger.dateRange,
    compareDateRange: trigger.compareDateRange && { ...trigger.compareDateRange, alignDates: true },
    sendOverEmail: trigger.sendOverEmail,
    triggerData,
    scheduleData,
    storageData,
    isDuplicate: isDuplicate,
    status: trigger.status
  };
  return mapTrigger;
};

export const REFRESH_ACTIONS = [
  { id: "hourly", name: "Refresh Hourly" },
  { id: "daily", name: "Refresh Daily" },
  { id: "weekly", name: "Refresh Weekly" },
  { id: "monthly", name: "Refresh Monthly" },
];
export const SCHEDULE_DAYS = [
  { id: 0, name: "Sundays" },
  { id: 1, name: "Mondays" },
  { id: 2, name: "Tuesdays" },
  { id: 3, name: "Wednesdays" },
  { id: 4, name: "Thursdays" },
  { id: 5, name: "Fridays" },
  { id: 6, name: "Saturdays" },
];
export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];
export const SCHEDULE_DATES = [
  { id: 1, name: "1st" },
  { id: 2, name: "2nd" },
  { id: 3, name: "3rd" },
  { id: 4, name: "4th" },
  { id: 5, name: "5th" },
  { id: 6, name: "6th" },
  { id: 7, name: "7th" },
  { id: 8, name: "8th" },
  { id: 9, name: "9th" },
  { id: 10, name: "10th" },
  { id: 11, name: "11th" },
  { id: 12, name: "12th" },
  { id: 13, name: "13th" },
  { id: 14, name: "14th" },
  { id: 15, name: "15th" },
  { id: 16, name: "16th" },
  { id: 17, name: "17th" },
  { id: 18, name: "18th" },
  { id: 19, name: "19th" },
  { id: 20, name: "20th" },
  { id: 21, name: "21st" },
  { id: 22, name: "22nd" },
  { id: 23, name: "23rd" },
  { id: 24, name: "24th" },
  { id: 25, name: "25th" },
  { id: 26, name: "26th" },
  { id: 27, name: "27th" },
  { id: 28, name: "28th" },
  { id: 29, name: "29th" },
  { id: 30, name: "30th" },
  { id: 31, name: "31st" },
];
export const TIMEZONES = [
  { id: "GMT", name: "GMT", desciption: "Greenwich Mean Time", offset: "GMT" },
  {
    id: "UTC",
    name: "UTC",
    desciption: "Universal Coordinated Time",
    offset: "GMT",
  },
  {
    id: "ECT",
    name: "ECT",
    desciption: "European Central Time",
    offset: "GMT+1:00",
  },
  {
    id: "EET",
    name: "EET",
    desciption: "Eastern European Time",
    offset: "GMT+2:00",
  },
  {
    id: "ART",
    name: "ART",
    desciption: "(Arabic) Egypt  Standard Time",
    offset: "GMT + 2: 00",
  },
  {
    id: "EAT",
    name: "EAT",
    desciption: "Eastern African Time",
    offset: "GMT+3:00",
  },
  {
    id: "MET",
    name: "MET",
    desciption: "Middle East Time",
    offset: "GMT+3:30",
  },
  { id: "NET", name: "NET", desciption: "Near East Time", offset: "GMT+4:00" },
  {
    id: "PLT",
    name: "PLT",
    desciption: "Pakistan Lahore Time",
    offset: "GMT+5:00",
  },
  {
    id: "IST",
    name: "IST",
    desciption: "India Standard Time",
    offset: "GMT+5:30",
  },
  {
    id: "BST",
    name: "BST",
    desciption: "Bangladesh Standard Time",
    offset: "GMT+6:00",
  },
  {
    id: "VST",
    name: "VST",
    desciption: "Vietnam Standard Time",
    offset: "GMT+7:00",
  },
  {
    id: "CTT",
    name: "CTT",
    desciption: "China Taiwan Time",
    offset: "GMT+8:00",
  },
  {
    id: "JST",
    name: "JST",
    desciption: "Japan Standard Time",
    offset: "GMT+9:00",
  },
  {
    id: "ACT",
    name: "ACT",
    desciption: "Australia Central Time",
    offset: "GMT+9:30",
  },
  {
    id: "AET",
    name: "AET",
    desciption: "Australia Eastern Time",
    offset: "GMT+10:00",
  },
  {
    id: "SST",
    name: "SST",
    desciption: "Solomon Standard Time",
    offset: "GMT+11:00",
  },
  {
    id: "NST",
    name: "NST",
    desciption: "New Zealand Standard",
    offset: "Time	GMT+12:00",
  },
  {
    id: "MIT",
    name: "MIT",
    desciption: "Midway Islands Time",
    offset: "GMT-11:00",
  },
  {
    id: "HST",
    name: "HST",
    desciption: "Hawaii Standard Time",
    offset: "GMT-10:00",
  },
  {
    id: "AST",
    name: "AST",
    desciption: "Alaska Standard Time",
    offset: "GMT-9:00",
  },
  {
    id: "PST",
    name: "PST",
    desciption: "Pacific Standard Time",
    offset: "GMT-8:00",
  },
  {
    id: "PNT",
    name: "PNT",
    desciption: "Phoenix Standard Time",
    offset: "GMT-7:00",
  },
  {
    id: "MST",
    name: "MST",
    desciption: "Mountain Standard Time",
    offset: "GMT-7:00",
  },
  {
    id: "CST",
    name: "CST",
    desciption: "Central Standard Time",
    offset: "GMT-6:00",
  },
  {
    id: "EST",
    name: "EST",
    desciption: "Eastern Standard Time",
    offset: "GMT-5:00",
  },
  {
    id: "IET",
    name: "IET",
    desciption: "Indiana Eastern Standard",
    offset: "Time	GMT-5:00",
  },
  {
    id: "PRT",
    name: "PRT",
    desciption: "Puerto Rico and",
    offset: "US Virgin Islands Time	GMT-4:00",
  },
  {
    id: "CNT",
    name: "CNT",
    desciption: "Canada Newfoundland Time",
    offset: "GMT-3:30",
  },
  {
    id: "AGT",
    name: "AGT",
    desciption: "Argentina Standard Time",
    offset: "GMT-3:00",
  },
  {
    id: "BET",
    name: "BET",
    desciption: "Brazil Eastern Time",
    offset: "GMT-3:00",
  },
  {
    id: "CAT",
    name: "CAT",
    desciption: "Central African Time",
    offset: "Gid-1:00",
  },
];
export const STORAGE_OPTIONS = [{ id: "sheets", name: "Google Sheet" }];
export const WRITE_MODE_OPTIONS = [
  { id: "append", name: "Append to the same sheet" },
  { id: "replace", name: "Replace data in the same sheet " },
  { id: "new-sheet", name: "New Spreadsheet " },
  { id: "new-tab", name: "New tab in a Spreadsheet" },
];
export const BOOLEAN_OPTIONS = [
  { id: "yes", name: "Yes" },
  { id: "no", name: "No" },
];
export const ORDER_OPTIONS = [
  { id: "ASC", name: "Ascending" },
  { id: "DESC", name: "Descending" },
];
export const DYNAMIC_PARA_OPTIONS = [
  { id: "{brand_name}", name: "Brand name" },
  { id: "{data_source}", name: "Data Source" },
  { id: "{start_date}", name: "Start Date" },
  { id: "{end_date}", name: "End Date" },
  { id: "{time}", name: "Time" },
];

export const StatusMapping = {
  FAILED: "Failed",
  DONE: "Successfull",
  QUEUED: "Running",
};

export const formattedDate = (date) => {
  if (!date) return "";
  let newDate = new Date(date).toString();
  const day = newDate.slice(4, 10);
  const year = newDate.slice(11, 15);
  const time = newDate.slice(16, 21);
  return day + ", " + year + " " + time;
};

export const formattedDateString = (dateString) => {
  if (!dateString) return "";
  let dateTime = dateString.split(",")
  let date = dateTime[0].split("/")
  let time = dateTime[1].split(":")
  let hrs = Number(time[0].slice(1))
  let mnt = time[1]
  let frt = time[2].slice(3)
  if (frt == "pm" && hrs < 12) hrs = hrs + 12;
  else if (frt == "am" && hrs == 12) hrs = hrs - 12;
  return (MONTHS[Number(date[1]) - 1] + " " + date[0] + ", " + date[2] + "  " + hrs.toString() + ":" + mnt);
}

export const viewDate = (date) => moment(date,"YYYY-MM-DD").format("MMM DD, YYYY");

export const allUniqueDates = (triggers) => {
  const obj = {};
  triggers?.forEach((trigger) => {
    const dateType = trigger.dateRange.dateType?.trim()?.toLowerCase();
    const { fromDate,toDate } = trigger.dateRange;
    if(dateType?.includes("custom")){
      obj[fromDate+"###"+toDate] = {id:fromDate+"###"+toDate,name:viewDate(fromDate)+" - "+viewDate(toDate),dateType: trigger.dateRange.dateType};
    }else{
      obj[dateType] = {id:fromDate+"###"+toDate,name:viewDate(fromDate)+" - "+viewDate(toDate),dateType: trigger.dateRange.dateType};
    }
  });
  return obj;
}

export const allUniqueSheets = (triggers) => {
  const obj = {};
  triggers?.forEach((trigger) => {
    if(trigger?.storageData?.existingSheet && (!["new-sheet"].includes(trigger?.storageData?.writeMode?.id))){
      const { name,id } = trigger.storageData.existingSheet;
      obj[id] = { name,id };
    }
  });
  return obj;
}

export const mappedColor = {
  "DRAFT":"#C8CED7",
  "PAUSED":"#F99F35",
  "ACTIVE":"#0869FB"
}