import { SupportedChannels } from "../../models/channel";

export default function update(state) {
    SupportedChannels.forEach(channel => {
        const channelState = state[channel.type];
        if (!("metricsUpdated" in channelState)){
            const numMetrics = 
                Array.isArray(channelState.metrics) ? channelState.metrics.length
                : Object.keys(channelState.metrics).length;
            state[channel.type].metricsUpdated = numMetrics > 0;
        }
    });
    return state;
}
