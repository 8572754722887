import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    TextField,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Scrollbars from "react-custom-scrollbars";
import { saveDimension } from "../../../api/channel/action";
import { useDispatch } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { ReactComponent as EditIcon } from "../../../Assets/Icons/edit.svg";
import { ReactComponent as MatchPassIcon } from "../../../Assets/Icons/check-circle.svg";
import { ReactComponent as MatchFailIcon } from "../../../Assets/Icons/cross-circle.svg";
import CircularProgressWithLabel from "../../Common/CircularProgress";
import RefreshIcon from '@mui/icons-material/Refresh';
import { updateLoader } from "../../../api/user/action";
import SearchBar from "../../Common/SearchBar";

export default function CreateCustomDim({ updateId, channel, refChannel, columnData, mappingData, resetData, refresh, setCompletion, refAccount }) {

    const user = useSelector(state => state.user.user)
    const dispatch = useDispatch();
    const _saveDimension = (_) => dispatch(saveDimension(_))
    const _updateLoader = (_) => dispatch(updateLoader(_))


    const [error, setError] = useState("");
    const [edit, setEdit] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [columnMapping, setColumnMapping] = useState(mappingData.mapping ?? []);
    const availableRefCols = mappingData.available_vals ?? [];
    const [matchScore, setMatchScore] = useState(mappingData.matches ?? 0);

    const updateDimension = () => {
        _updateLoader(true);
        mappingData.metadata.customDimension.name = columnData.name;
        _saveDimension({
            mappingObj: {
                ...mappingData.metadata, mappingVals: columnMapping, selectedAccount: refAccount?.title
            },
            loading: (msg) => {
                _updateLoader(false);
                setCompletion({ message: msg, operation: !updateId ? "Creation" : "Updation" });
            }
        })
    }

    const filterData = (value) => {
        return value?.toString()?.toLowerCase().includes(searchText?.toLowerCase())
        // && !(columnMapping.map(col => col.mapped_val).includes(value))
    }

    const allUnique = arr => arr.length === new Set(arr).size;

    useEffect(() => {
        if (allUnique(columnMapping.map(col => col.mapped_val).filter(col => col))) {
            setError("");
        }
    }, [columnMapping])

    return (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", minWidth: "100%" }}>
            <Box sx={{ display: "flex", flexDirection: "row", height: "90%" }}>
                <Box sx={{ minWidth: "55%" }}>
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', height: "50px", marginBottom: "10px" }}>
                        <img style={{ marginRight: 16, height: 20, width: 20 }} src={channel.icon} alt={channel.title} />
                        <div className="inter h18">{channel.title}</div>
                    </div>
                    <hr style={{ border: "1px solid #EAEAEC", margin: "0px -20px" }} />
                    <div style={{ padding: "20px", width: "100%", height: "94%", borderRight: "1px solid #EAEAEC" }}>
                        <div style={{ display: "flex" }}>
                            <p className="h14_light" style={{ width: "45%" }}>
                                {columnData.base_dimension.name}
                            </p>
                            <p className="h14_light" style={{ marginLeft: "40px", width: "55%" }}>
                                {columnData.name}
                            </p>
                        </div>
                        <Scrollbars autoHide style={{ height: "calc(100% - 20px)" }}>
                            <div style={{ display: "flex", height: "100%" }}>
                                <div style={{ width: "45%", background: "#FFFFFF", borderRadius: "6px", minHeight: "100%", height: "fit-content" }}>
                                    {
                                        columnMapping.map(column => {
                                            return (
                                                <TextField
                                                    required
                                                    className="highlight1"
                                                    style={{ margin: "8px 0px", padding: "10px 20px" }}
                                                    id="outlined-basic"
                                                    value={column.base_val}
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0, border: "0px !important" },
                                                        '& input': { fontSize: "14px", padding: "4px" },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: "rgba(0, 0, 0, 0.23)",
                                                        }
                                                    }}
                                                    InputProps={{ readOnly: true }}
                                                />
                                            )
                                        })
                                    }
                                </div>
                                <div style={{ marginLeft: "40px", width: "55%", minHeight: "100%", height: "fit-content" }}>
                                    {
                                        columnMapping.map((column, colIndex) => {
                                            let ref;
                                            return (
                                                <div className="displayRowSpaced">
                                                    <TextField
                                                        required
                                                        className="highlight1"
                                                        style={{ margin: "8px 0px", padding: "10px 20px" }}
                                                        id="outlined-basic"
                                                        value={column.mapped_val ?? "-"}
                                                        error={column.error}
                                                        variant="outlined"
                                                        size="small"
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0, border: "0px !important" },
                                                            '& input': { fontSize: "14px", padding: "4px" },
                                                        }}
                                                        onChange={e => {
                                                            if (columnMapping.find(col => col.mapped_val === e.target.value)) {
                                                                column.error = true;
                                                                setError("Mapped Columns must be unique")
                                                            } else {
                                                                column.error = false;
                                                            }
                                                            column.mapped_val = e.target.value
                                                            columnMapping[colIndex] = column;
                                                            setColumnMapping([...columnMapping])
                                                        }}
                                                        onKeyDown={(event) => {
                                                            if (event.key === "Enter") { setEdit(0); }
                                                        }}
                                                        InputProps={{ readOnly: (colIndex + 1) !== edit }}
                                                        inputRef={(el) => { ref = el; }}
                                                    />
                                                    <IconButton style={{ cursor: "default", background: "#FAFAFA", borderRadius: "6px", height: 30, width: 30 }} >
                                                        {column.mapped_val ?
                                                            <MatchPassIcon style={{ width: "20px", height: "20px" }} /> :
                                                            <MatchFailIcon style={{ width: "20px", height: "20px" }} />
                                                        }
                                                    </IconButton>
                                                    <IconButton style={{
                                                        background: "#FAFAFA", borderRadius: "6px", height: 30, width: 30,
                                                        marginRight: "2px", border: (colIndex + 1) === edit ? "1px solid #0869FB" : ""
                                                    }} >
                                                        <EditIcon stroke={"#0869FB"} style={{ width: "20px", height: "20px" }}
                                                            onClick={() => { setEdit(colIndex + 1); ref.focus(); }}
                                                        />
                                                    </IconButton>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </Scrollbars>
                    </div>
                </Box>

                <Box sx={{ marginLeft: "20px", minWidth: "42%" }}>
                    <div className="displayRowSpaced" style={{ height: "50px", marginBottom: "10px" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img style={{ marginRight: 16, height: 20, width: 20 }} src={refChannel.icon} alt={refChannel.title} />
                            <div className="inter h18">{refChannel.title}</div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", padding: "10px", background: "#FFFFFF", boxShadow: "0px 5.45406px 14.6286px rgba(23, 15, 73, 0.07)", borderRadius: "8px" }}>
                            <CircularProgressWithLabel value={matchScore ? (matchScore / (mappingData.totalBaseColumns ?? 0)) * 100 : 0} />
                            <div style={{ textAlign: "center", marginLeft: "20px" }}>
                                <Typography variant="body1" style={{ fontSize: "14px", fontWeight: 600, color: "#0869FB" }}>
                                    {matchScore + "/" + (mappingData.totalBaseColumns ?? 0)}
                                </Typography>
                                <Typography variant="body1" style={{ fontSize: "8px", fontWeight: 400, color: "#0869FB" }}>
                                    Keywords Selected
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <hr style={{ border: "1px solid #EAEAEC", margin: "0px -20px 20px" }} />
                    <div style={{ height: "94%" }}>
                        <p className="h14_light" >
                            Available Columns ({columnData.mapped_dimension.name})
                        </p>
                        <SearchBar setFilterText={setSearchText} />
                        <List style={{ height: "calc(100% - 100px)" }}>
                            <Scrollbars autoHide style={{ height: "100%" }}>
                                {availableRefCols.filter(column => filterData(column)).map((column, index) =>
                                    <>
                                        <ListItem className="h14_dark highlight1"
                                            style={{ wordBreak: "break-all" }}
                                            key={index} sx={{ paddingLeft: "30px" }} disabled={columnMapping.map(col => col.mapped_val).includes(column)}
                                        >
                                            {column}
                                        </ListItem>
                                    </>
                                )}
                            </Scrollbars>
                        </List>
                    </div>
                </Box>
            </Box >

            <Box>
                {
                    error && <h4 style={{
                        color: "red",
                        textAlign: "right",
                        borderRadius: "10px",
                        width: "100%",
                        fontSize: "12px",
                        marginTop: "20px"
                    }}><b>{error}</b>
                    </h4>
                }
            </Box>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: error ? "5px" : "30px",
                }}>

                {(updateId && !mappingData.refresh) ?
                    <Button
                        sx={{ backgroundColor: "#0869FB" }}
                        style={{ height: "40px", borderRadius: '8px', }}
                        onClick={() => refresh({ refresh: true })}
                        variant="contained"
                        startIcon={<RefreshIcon />}
                    >
                        Refresh
                    </Button> :
                    <div></div>
                }
                <div>
                    <Button
                        sx={{ marginRight: "10px", color: "#0869FB" }}
                        onClick={resetData}>
                        Cancel
                    </Button>
                    <Button
                        sx={{ backgroundColor: "#0869FB" }}
                        style={{ height: "40px", borderRadius: '8px', }}
                        disabled={user.role === "viewer" || error}
                        onClick={() => { updateDimension() }}
                        variant="contained"
                    >
                        {updateId ? "Update Dimension" : "Create Dimension"}
                    </Button>
                </div>
            </Box>
        </Box >
    );
};
