import { channelType } from "./transformer";

const INITIAL_STATE = {
    clientId: "",
    isConnecting: false,
    accountData: {},
    dimensions: [],
    metrics: [],
    currency: null,
    metricsUpdated: false,
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case `${channelType}.GET_CLIENT_ID_SUCCESS`:
            return { ...state, clientId: action.payload.clientId };
        case `${channelType}.GET_CLIENT_ID_FAILURE`:
            return { ...state, clientId: "" };
        case `${channelType}.SHOW_CONNECT_ACCOUNT`:
            return { ...state, isConnecting: true };
        case `${channelType}.HIDE_CONNECT_ACCOUNT`:
            return { ...state, isConnecting: false };
        case `${channelType}.GET_ACCOUNT_SUMMARY_SUCCESS`:
            const accountList = Object.values(action.payload.response.map(storeName => {
                const names = storeName.split("-");
                const name = names.map(name => name.charAt(0).toUpperCase() + name.slice(1)).join(" ");
                return {
                    user: { username: name, email: name, storeName: storeName },
                    account: {
                        title: name,
                        subtitle: name,
                        storeName: storeName,
                    },
                };
            }));
            return { ...state, accountData: { users: accountList.map(a => a.user), accountList: accountList.map(a => a.account) } };
        case `${channelType}.START_LOADING`:
            return { ...state, accountData: { ...state.accountData, loading: true } };
        case `${channelType}.DISCONNECT_ACCOUNT_SUCCESS`:
            if (state.accountData.accountList?.length > 0) { return state; }
        case `${channelType}.GET_ACCOUNT_SUMMARY_FAILURE`:
            return { ...INITIAL_STATE, clientId: state.clientId };
        case `${channelType}.GET_COLUMNS_SUCCESS`:
            const dimensions = action.payload.columns.filter(c => c.type === "DIMENSION");
            const metrics = action.payload.columns.filter(c => c.type === "METRIC");
            return { ...state, dimensions: dimensions, metrics: metrics, metricsUpdated: true };
        case `${channelType}.GET_CURRENCY_SUCCESS`:
            return { ...state, currency: action.payload.currency }
        default:
            return state;
    }
};

export default reducer;
