import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ChevronRightRounded,
  KeyboardDoubleArrowRightRounded,
} from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  Modal,
  Checkbox
} from "@mui/material";
import { Close } from '@mui/icons-material';
import { getAllTriggers } from "../../../api/extractor/action";
import { ReactComponent as EditIcon } from "../../../Assets/Icons/edit.svg";
import { ReactComponent as ExportIcon } from "../../../Assets/Icons/export.svg";
import { ReactComponent as LinkIcon } from "../../../Assets/Icons/link-icon.svg";
import { ReactComponent as MenuIcon } from "../../../Assets/Icons/menu-icon.svg";
import { ReactComponent as RefreshIcon } from "../../../Assets/Icons/refresh.svg";
import RemoveModal from "../../Modal/RemoveModal";
import "../../styles/profile.css";
import { updateLoader } from "../../../api/user/action";
// import StatusDrawer from "./StatusDrawer";
import {
  formattedDate,
  formattedDateString,
  SCHEDULE_DATES,
  SCHEDULE_DAYS,
  StatusMapping,
  transformFromSuperMetricDataObject,
  transformToSuperMetricDataObject
} from "../Utils";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SearchBar from "../../Common/SearchBarBE";
import { GetChannel } from "../../../models/channel";
import { EVENTS, transformMixPanelData } from "../../../utils/mixpanel.js";
import { updateTrigger } from "../../../api/extractor/action";
import { selectBulkEditing } from "../../../api/extractor/action";
import { useHistory } from "react-router";
import BulkEnable from "../../../Assets/Icons/Bulk duplication icon.svg";
import BulkDisable from "../../../Assets/Icons/bulk_icon.svg";
import "../../styles/extractor.css"

const triggerStatus = ["ALL", "ACTIVE", "PAUSED", "DRAFT"];
const Triggers = ({ redirect, setCompletion }) => {
  const [removeTriggerModal, setRemoveTriggerModal] = useState({
    open: false,
    removeId: "",
  });
  const [pauseModal, setPauseModal] = useState({ open: false, pauseTrigger: {} })
  const listBulkEditing = useSelector((state) => state.extractor.listBulkEditing);
  const isAllBulkChecked = useSelector((state) => state.extractor.allCheckBoxEnable);

  const pauseTrigger = () => {
    setPauseModal({ open: false, pauseTrigger: {} })

    var pauseData = transformFromSuperMetricDataObject(pauseModal.pauseTrigger, false)
    const channel = pauseData.channelType;
    const accounts = pauseData.accounts ?? [];
    const table = pauseData.table ?? [];
    const dataLevel = pauseData.dataLevel ?? null;
    const dateRange = pauseData.dateRange;
    const sendOverEmail = pauseData.sendOverEmail;
    const triggerData = pauseData.triggerData;
    const scheduleData = pauseData.scheduleData;
    const storageData = pauseData.storageData;

    var toPause = transformToSuperMetricDataObject({
      name: pauseData.name,
      channel,
      table,
      dataLevel,
      accounts,
      dateRange,
      sendOverEmail,
      status: "PAUSED",
      triggerData,
      scheduleData,
      storageData,
    });
    dispatch(updateLoader(true));

    dispatch(
      updateTrigger({
        triggerId: pauseModal.pauseTrigger._id,
        triggerObj: toPause,
        loading: (msg) => {
          dispatch(updateLoader(false));
          setCompletion({ message: msg, operation: "Pausing" });
        },
      })
    );
  }
  // const [isStatusDrawerOpen, setIsStatusDrawerOpen] = useState(false);
  const [containerEl, setContainerEl] = useState(null);

  const user = useSelector(state => state.user.user);
  const allTriggers = useSelector((state) => state.extractor.allTriggers);
  const total_triggers = useSelector((state) => state.extractor.total_triggers);
  const dispatch = useDispatch();
  useEffect(() => {
    refreshPage()
  }, []);

  const refreshPage = (current_page = 0, limit_per_page = 10, search_text = "", status = triggerStatus[0]) => {
    isAllBulkChecked ? selectAllBulkEditing(true) : dispatch(getAllTriggers({ loading: true, current_page, limit_per_page, search_text, status }));
  }
  const history = useHistory();

  const [openMenus, setOpenMenus] = useState([]);
  const handleClickMenu = (index, event) => {
    let menus = [...openMenus];
    menus[index] = event.currentTarget;
    setOpenMenus(menus);
  };
  const handleCloseMenu = (index) => {
    let menus = [...openMenus];
    menus[index] = null;
    setOpenMenus(menus);
  };

  const [currentView, setCurrentView] = React.useState(0);

  useEffect(() => {
    refreshPage(0, rowsPerPage, searchText, triggerStatus[currentView]);
    setPage(0);
  }, [currentView]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    refreshPage(newPage, rowsPerPage, searchText, triggerStatus[currentView]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    refreshPage(0, parseInt(event.target.value, 10), searchText, triggerStatus[currentView]);
  };
  async function downloadURI(uri, name) {
    if (uri.includes("http://")) {
      uri = uri.replace("http://", "https://");
    }
    window.open(uri);
  }
  const selectAllBulkEditing = (checked) => {
    if(checked){
      dispatch(getAllTriggers({ 
                                loading: true, current_page: 0,
                                limit_per_page: -1, 
                                search_text:searchText, 
                                status:"ACTIVE",
                                isBulkUpdate: true,
                                callBack: (new_triggers) => dispatch(selectBulkEditing({ allCheckBoxEnable: checked,triggers: [...new_triggers] }))
                              }));
    }else {
      dispatch(selectBulkEditing({allCheckBoxEnable: checked,triggers: []}));
    }
  }
  return (
    <div
      className="grid-item"
    style={{ width: "100%", position: "relative", marginTop: "20px", backgroundColor: "#FFF" }}
      ref={setContainerEl}
    >
      {/* <StatusDrawer
        container={containerEl}
        open={isStatusDrawerOpen}
        onClose={() => setIsStatusDrawerOpen(false)}
      /> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#FFF",
          marginBottom: '17px'
        }}
      >
        <div
          style={{
            backgroundColor: "#EFF5FF80",
            display: "flex",
            alignItems: "center",
            padding: "0px 8px",
            borderRadius: "4px",
          }}
        >
          <Button
            onClick={() => {
              setCurrentView(0);
            }}
            variant="contained"
            disabled={currentView === 0}
            sx={{
              backgroundColor: "transparent",
              boxShadow: "none",
              variant: "contained",
              "&:hover": {
                backgroundColor: "transparent",
                borderColor: "#0062cc",
                boxShadow: "none",
              },
              color: "black",
              ":disabled": {
                backgroundColor: "#0869FB",
                borderColor: "#0062cc",
                color: "#FFF",
                boxShadow: "none",
              },
            }}
          >
            <Typography
              style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: 400 }}
            >
              {" "}
              All Triggers{" "}
            </Typography>
          </Button>

          <Button
            onClick={() => {
              setCurrentView(1);
            }}
            variant="contained"
            disabled={currentView === 1}
            sx={{
              backgroundColor: "transparent",
              boxShadow: "none",
              variant: "contained",
              "&:hover": {
                backgroundColor: "transparent",
                borderColor: "#0062cc",
                boxShadow: "none",
              },
              color: "black",
              ":disabled": {
                backgroundColor: "#0869FB",
                borderColor: "#0062cc",
                color: "#FFF",
                boxShadow: "none",
              },
            }}
          >
            <Typography
              style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: 400 }}
            >
              {" "}
              Active{" "}
            </Typography>
          </Button>

          <Button
            onClick={() => {
              setCurrentView(2);
            }}
            disabled={currentView === 2 || isAllBulkChecked}
            variant="contained"
            sx={{
              backgroundColor: "transparent",
              boxShadow: "none",
              variant: "contained",
              "&:hover": {
                backgroundColor: "transparent",
                borderColor: "#0062cc",
                boxShadow: "none",
              },
              color: "black",
              ":disabled": {
                backgroundColor: isAllBulkChecked ? "rgb(235,235,228)" : "#0869FB",
                borderColor: "#0062cc",
                color: "#FFF",
                boxShadow: "none",
                marginLeft: isAllBulkChecked ? "3px" : "0px"
              },
            }}
          >
            <Typography
              style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: 400 }}
            >
              {" "}
              Paused{" "}
            </Typography>
          </Button>

          <Button
            onClick={() => {
              setCurrentView(3);
            }}
            disabled={currentView === 3 || isAllBulkChecked}
            variant="contained"
            sx={{
              boxShadow: "none",
              backgroundColor: "transparent",
              variant: "contained",
              "&:hover": {
                backgroundColor: "transparent",
                borderColor: "#0062cc",
                boxShadow: "none",
              },
              color: "black",
              ":disabled": {
                backgroundColor: isAllBulkChecked ? "rgb(235,235,228)" : "#0869FB",
                borderColor: "#0062cc",
                color: "#FFFFFF",
                boxShadow: "none",
                marginLeft: isAllBulkChecked ? "3px" : "0px"
              },
            }}
          >
            <Typography
              style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: 400 }}
            >
              {" "}
              Drafts{" "}
            </Typography>
          </Button>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ width: "80%", display: "flex" }}>
            {[{ name: "Active", id: "#0869FB" }, { name: "Paused", id: "#F99F35" }, { name: "Drafts", id: "#C8CED7" }].map((e) => {
              return (
                <>
                  <div
                    style={{
                      width: "6px",
                      height: "19px",
                      background: e.id,
                      borderRadius: "100px",
                      marginRight: "8px",
                      marginLeft: "8px"
                    }}
                  ></div>
                  <Typography className="newText">{e.name}</Typography>
                </>)
            })}
          </div>
          <div style={{ width: "80%" }}>
            <SearchBar
              getData={(text) => {
                refreshPage(0, 10, text, triggerStatus[currentView]);
                setPage(0);
              }}
              setSearchText={setSearchText} 
              searchText={searchText}
            />
          </div>
          <button 
            style={{
                    border: "1px solid #eee", 
                    borderRadius: "8px", background: "none", 
                    cursor: listBulkEditing.length < 2 ? "auto" : "pointer", 
                    marginLeft: "16px", height: "40px", width: "12.84%" 
                  }}
            onClick={() => history.push("/extractor/bulk-editing?=" + listBulkEditing[0]._id)}
            disabled={listBulkEditing.length < 2}
            className={listBulkEditing.length > 1 && "bulkEditBtn"}
          >
            <img src={listBulkEditing.length < 2 ? BulkDisable : BulkEnable} alt="" />
          </button>

          <IconButton
            disableRipple
            onClick={() => refreshPage()}
            style={{
              marginLeft: "18px",
              height: "40px",
              width: "40px",
              padding: "0px",
            }}
          >
            <RefreshIcon />
          </IconButton>
        </div>
      </div>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          boxShadow: "0",
          height: "100%",
          padding: '0px 16px',
          background: '#F8F9FD',
          borderRadius: '12px'
        }}
      >
        <TableContainer className="trigger-table" sx={{ maxHeight: "70vh" }}>
          <Table
            stickyHeader
            sx={{ minWidth: 650, width: "3000px", tableLayout: "fixed" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {/* <TableCell> <Checkbox /> </TableCell> */}
                <TableCell
                  width="max-content"
                  sx={{
                    position: "sticky",
                    left: "0px",
                    background: "#FFF",
                    zIndex: "500",
                  }}
                >
                  <Checkbox 
                      disableRipple style={{ height: "18px", width: "18px", marginRight: "32px" }} 
                      checked={isAllBulkChecked}
                      onClick={(e) => selectAllBulkEditing(e.target.checked)} /> TRIGGER NAME
                </TableCell>
                <TableCell>SHEET NAME/TAB NAME</TableCell>
                <TableCell align="left">DATA SOURCE/ACCOUNT NAME</TableCell>
                <TableCell>CREATED BY</TableCell>
                <TableCell>LAST EDITED</TableCell>
                <TableCell align="left">SCHEDULE FREQUENCY</TableCell>
                <TableCell align="left">LAST RUN TIME</TableCell>
                <TableCell align="left">NEXT RUN</TableCell>
                <TableCell align="left">STATUS</TableCell>
                <TableCell align="left">TIMEZONE</TableCell>
                <TableCell align="left">DATA DESTINATION</TableCell>
                {/* <TableCell align="left">VIEW PREVIEW</TableCell> */}
                <TableCell sx={{ width: "14rem", textAlign: "left" }}>
                  ACTION
                </TableCell>
                <TableCell
                  sx={{
                    width: "5rem",
                    backgroundColor: "#fff",
                    position: "sticky",
                    right: "0px",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allTriggers.map((row, index) => {
                const refreshFrequency = row.triggerSchedule.refreshFrequency;
                const status = StatusMapping[row.lastRunStatus] ?? "";
                const channel = GetChannel(row.dataSource);
                const actions = (row.status === "PAUSED" || row.status === "DRAFT")
                return (
                  <TableRow
                    key={row._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      scope="row"
                      style={{
                        position: "sticky",
                        left: "0px",
                        background: "#FFF",
                        zIndex: "100",
                        borderRight: "2px solid #ECEEF1"
                      }}
                    >
                      <TableRow>
                        <TableCell
                          sx={{ borderBottom: "none", padding: "0px", display: "flex" }}
                        >
                          <Checkbox
                            disableRipple style={{ height: "18px", width: "18px", marginRight: "32px" }}
                            onClick={() => dispatch(selectBulkEditing({ allCheckBoxEnable:"INDIVIDUAL_SELECTION", triggers: row }))}
                            disabled={row.status !== "ACTIVE"}
                            checked={listBulkEditing.find(trigger => trigger._id === row._id) ? true : false}

                          />
                          <div
                            style={{
                              width: "6px",
                              height: "19px",
                              background:
                                row.status === "DRAFT"
                                  ? "#C8CED7"
                                  : row.status === "PAUSED"
                                    ? "#F99F35"
                                    : "#0869FB",
                              borderRadius: "100px",
                              marginRight: "8px",
                            }}
                          ></div>
                        </TableCell>
                        <TableCell
                          sx={{ borderBottom: "none", padding: "0px", overflowWrap: "anywhere" }}
                        >
                          {row.name}
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell scope="row">
                      <div className="displayCol">
                        <span>
                          {row.lastSheetName ??
                            (row.storage?.spreadsheetName ||
                              row.storage?.sheetNameFormat?.join("_"))}
                        </span>
                        <span className="greyText">
                          {row.lastTabName ??
                            (row.storage?.tabName ||
                              row.storage?.tabNameFormat?.join("_"))}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div className="displayCol">
                        <span>
                          <img
                            style={{
                              margin: "6px 14px 6px 0px",
                              height: 16,
                              width: 16,
                            }}
                            src={channel.icon}
                            alt={channel.title}
                          />
                          {row.dataSource}
                        </span>
                        <span
                          style={{ marginLeft: "30px" }}
                          className="greyText"
                        >
                          {row.accounts.map((acc) => acc.title).join(", ")}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="displayCol">
                        <span>{row.createdBy ?? "--"}</span>
                        <span className="greyText">{formattedDateString(row.createdAt)}</span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="displayCol">
                        <span>{row.lastEditedBy ?? "--"}</span>
                        <span className="greyText">{formattedDateString(row.lastEditedAt)}</span>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div className="displayCol">
                        <span>Refresh {refreshFrequency}</span>
                        <span className="greyText">
                          {row.triggerSchedule.startAt
                            .map((startAt) => {
                              let str = "";
                              switch (refreshFrequency) {
                                case "weekly":
                                  str +=
                                    "On " +
                                    SCHEDULE_DAYS.find(
                                      (day) => day.id === startAt.on
                                    )?.name +
                                    " ";
                                  break;
                                case "monthly":
                                  str += startAt.date
                                    ? SCHEDULE_DATES.find(
                                      (date) => date.id === startAt.date
                                    )?.name + " of every month "
                                    : "";
                                  break;
                                default:
                                  str +=
                                    "At " +
                                    startAt.time.hour +
                                    ":" +
                                    startAt.time.min;
                                  break;
                              }
                              return str;
                            })
                            .join(" and ")}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell>
                      {formattedDate(row.lastRunTime) || "-"}
                    </TableCell>
                    <TableCell>{formattedDate(row.nextRunTime)}</TableCell>
                    <TableCell>
                      <div className={status}>
                        <span className="statusText">{status}</span>
                        {status && (
                          <Tooltip
                            PopperProps={{
                              sx: {
                                "& .MuiTooltip-tooltip": {
                                  backgroundColor: "transparent",
                                  //   filter:
                                  //     "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))",
                                },
                              },
                            }}
                            title={
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ width: "14px", height: "14px", borderRadius: "1px", background: "white", borderLeft: "2px solid #ECEEF1", borderBottom: "2px solid #ECEEF1", transform: `rotate(45deg)` }}></div>
                                <div style={{ width: "200px", borderRadius: "8px", background: "white", border: "2px solid #ECEEF1", marginLeft: "-7px", padding: "10px" }}>
                                  <div style={{ display: "flex", alignItems: "center" }}>
                                    <Typography style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: 700, marginRight: "4px", color: (status === "Successfull") ? "#5ABCA3" : (status === "Failed") ? "#D7584E" : "black" }}>{status}</Typography>
                                    {status === "Successfull" ? (
                                      <CheckCircleIcon
                                        style={{ color: "#1ABFA1", fontSize: "16px" }}
                                      />
                                    ) : status === "Failed" ? (
                                      <ErrorIcon style={{ color: "#E84B47", fontSize: "16px" }} />
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  <div className="trigger-table" style={{ width: "100%", maxHeight: "300px", overflowY: "auto", margin: (row?.lastRunAdditionalInfo?.failureReason) ? "6px 0px" : null }}>
                                    <Typography className="newText">{row?.lastRunAdditionalInfo?.failureReason}</Typography>
                                  </div>
                                  <Typography style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: 600, color: "#585969" }}>{row?.lastRunAdditionalInfo?.message}</Typography>
                                </div>
                              </div>
                            }
                            placement="right"
                          >
                            <IconButton
                              style={{
                                background: "transparent",
                                padding: 0,
                                fontSize: "1em",
                                color: "inherit",
                              }}
                            // onClick={() => setIsStatusDrawerOpen(true)}
                            >
                              <KeyboardDoubleArrowRightRounded
                                sx={{ fontSize: "1.5em", color: "inherit" }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    </TableCell>
                    <TableCell>{row.triggerSchedule.timeZone}</TableCell>
                    <TableCell>
                      {"{" +
                        (row.storage.startingCell?.column || "A") +
                        (row.storage.startingCell?.row || "1") +
                        "}-{" +
                        row.storage.endingCell?.column +
                        row.storage.endingCell?.row +
                        "}"}
                    </TableCell>

                    {/* <TableCell style={{ color: "#0869FB" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <PreviewIcon style={{ marginRight: "10px" }} />
                            View Preview
                        </div>
                    </TableCell> */}

                    <TableCell sx={{ textAlign: "center" }}>
                      {/* <div style={{ display: "flex" }}>
                        <IconButton
                          disabled={!row.lastDataUrl}
                          onClick={() => {
                            row.lastDataUrl &&
                              downloadURI(row.lastDataUrl, "_");
                            transformMixPanelData(
                              EVENTS.download_trigger,
                              row.name
                            );
                          }}
                        >
                          <ExportIcon
                            stroke={!row.lastDataUrl ? "grey" : "#0869FB"}
                            style={{ height: "16px" }}
                          />
                        </IconButton>
                        <IconButton
                          disabled={!row.lastSheetId}
                          onClick={() => {
                            row.lastSheetId &&
                              window.open(
                                `https://docs.google.com/spreadsheets/d/${row.lastSheetId}`
                              );
                            transformMixPanelData(
                              EVENTS.link_trigger,
                              row.name
                            );
                          }}
                        >
                          <LinkIcon
                            stroke={!row.lastSheetId ? "grey" : "#0869FB"}
                            style={{ height: "16px" }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            redirect("", 0, row._id, row, false);
                            transformMixPanelData(
                              EVENTS.click_edit_triggers,
                              row.name
                            );
                          }}
                        >
                          <EditIcon
                            stroke={"#0869FB"}
                            style={{ height: "16px" }}
                          />
                        </IconButton>
                        <IconButton
                          disabled={user.role === "viewer"}
                          onClick={() => redirect("", 0, row._id, row, true)}
                        >
                          <DuplicateIcon
                            stroke={user.role === "viewer" ? "grey" : "#0869FB"}
                            style={{ height: "16px" }}
                          />
                        </IconButton>
                        <Tooltip
                          title={
                            user.role === "viewer"
                              ? "Viewer does not have permission to delete!"
                              : ""
                          }
                          placement="top"
                        >
                          <div>
                            <IconButton
                              disabled={user.role === "viewer"}
                              onClick={() =>
                                setRemoveTriggerModal({
                                  open: true,
                                  removeId: row._id,
                                  msg: "This saved trigger will be removed from Pixis analytics tool that you are in. This will affect 0 triggers under this custom super metrics and no data will be appeared in the dashboard.",
                                  trigger_name: row.name,
                                })
                              }
                            >
                              <DeleteIcon
                                fill={
                                  user.role === "viewer" ? "grey" : "#F34A3F"
                                }
                                style={{ height: "16px" }}
                              />
                            </IconButton>
                          </div>
                        </Tooltip>
                      </div> */}
                      <div style={{ display: "flex" }}>
                        {currentView < 2 &&
                          <IconButton
                            disabled={!row.lastDataUrl || row.status === "DRAFT" || row.status === "PAUSED"}
                            onClick={() => {
                              row.lastDataUrl &&
                                downloadURI(row.lastDataUrl, "_");
                              transformMixPanelData(
                                EVENTS.download_trigger,
                                row.name
                              );
                            }}
                            sx={{
                              height: "32px",
                              width: "32px",
                              background: "#FAFAFA",
                              ":disabled": { background: "#FAFAFA" },
                              ":hover": { background: "#EBF3FF", border: "1px solid #0869FB" },
                            }}
                            disableRipple
                          >
                            <ExportIcon
                              stroke={!row.lastDataUrl ? "#82b4fd" : "#0869FB"}
                              style={{ height: "16px" }}
                            />
                          </IconButton>
                        }

                        {currentView < 2 &&
                          <IconButton
                            disabled={!row.lastSheetId || row.status === "DRAFT" || row.status === "PAUSED"}
                            onClick={() => {
                              row.lastSheetId &&
                                window.open(
                                  `https://docs.google.com/spreadsheets/d/${row.lastSheetId}`
                                );
                              transformMixPanelData(
                                EVENTS.link_trigger,
                                row.name
                              );
                            }}
                            sx={{
                              height: "32px",
                              width: "32px",
                              background: "#FAFAFA",
                              ":disabled": { background: "#FAFAFA" },
                              ":hover": { background: "#EBF3FF", border: "1px solid #0869FB" },
                            }}
                            disableRipple
                          >
                            <LinkIcon
                              stroke={!row.lastSheetId ? "#82b4fd" : "#0869FB"}
                              style={{ height: "16px" }}
                            />
                          </IconButton>
                        }

                        <IconButton
                          onClick={() => {
                            redirect("", 0, row._id, row, false);
                            transformMixPanelData(
                              EVENTS.click_edit_triggers,
                              row.name
                            );
                          }}
                          sx={{
                            height: "32px",
                            width: "32px",
                            background: "#FAFAFA",
                            ":disabled": { background: "#FAFAFA" },
                            ":hover": { background: "#EBF3FF", border: "1px solid #0869FB" },
                          }}
                          disableRipple
                        >
                          <EditIcon style={{ height: "16px" }} />
                        </IconButton>

                        <div>
                          <IconButton
                            id="basic-button"
                            aria-controls={Boolean(openMenus[index]) ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={Boolean(openMenus[index]) ? "true" : undefined}
                            onClick={(event) => { handleClickMenu(index, event) }}
                            disableRipple
                            sx={{
                              height: "32px",
                              width: "32px",
                              padding: "0px",
                              ":hover": { background: "#EBF3FF", border: "1px solid #0869FB" },
                            }}
                          >
                            <MenuIcon />
                          </IconButton>
                          <Menu
                            id={`${index}-menu-bar`}
                            sx={{
                              "& .MuiMenu-paper": {
                                boxShadow: "none",
                                border: "1px solid #E5E5E5",
                                width: "179px",
                                height: "174px",
                                padding: "12px",
                              },
                              "	.Mui-selected": {
                                borderRadius: "12px",
                                background: "#EBF3FF",
                              },
                              " .Mui-disabled": {
                                color: "#2E3044"
                              }
                            }}
                            anchorEl={openMenus[index]}
                            open={Boolean(openMenus[index])}
                            keepMounted
                            onClose={(event) => handleCloseMenu(index)}
                            disableScrollLock={true}
                          // MenuListProps={{ sx: { marginBottom: "12px" } }}
                          >
                            <MenuItem
                              sx={{ marginBottom: "12px" }}
                              onClick={(event) => {
                                handleCloseMenu(index);
                                redirect("", 0, row._id, row, true)
                              }}
                              disabled={actions || user.role === "viewer"}
                            >
                              Duplicate
                            </MenuItem>
                            <MenuItem
                              sx={{ marginBottom: "12px" }}
                              onClick={(event) => {
                                handleCloseMenu(index);
                                setPauseModal({ open: true, pauseTrigger: row })
                              }}
                              disabled={actions || user.role === "viewer"}
                            >
                              Pause
                            </MenuItem>
                            <MenuItem
                              onClick={(event) => {
                                handleCloseMenu(index);
                                setRemoveTriggerModal({
                                  open: true,
                                  removeId: row._id,
                                  msg: "This saved trigger will be removed from Pixis analytics tool that you are in. This will affect 0 triggers under this custom super metrics and no data will be appeared in the dashboard.",
                                  trigger_name: row.name,
                                })
                              }}
                              disabled={user.role === "viewer"}
                            >
                              Delete
                            </MenuItem>
                          </Menu>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        background: "#FFF",
                        position: "sticky",
                        right: "0px",
                      }}
                    >
                      <IconButton
                        style={{ background: "transparent" }}
                        onClick={() => {
                          redirect("", 2, row._id);
                          transformMixPanelData(
                            EVENTS.view_log_trigger,
                            row.name
                          );
                        }}
                      >
                        <ChevronRightRounded sx={{ fontSize: "1.25rem" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className="pagination"
          rowsPerPageOptions={isAllBulkChecked ? [{ label: "All", value: -1 }] :  [10, 25, 50, { label: "All", value: -1 }]}
          component="div"
          count={total_triggers}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            MenuProps: {
              sx: {
                ".MuiTablePagination-menuItem": {
                  fontFamily: "Inter",
                  fontSize: "12px",
                  color: "#585969",
                  margin: "5px"
                }
              }
            }
          }}
        />
      </Paper>

      <RemoveModal
        modal={removeTriggerModal}
        onClose={() => setRemoveTriggerModal({ open: false, removeId: "" })}
        setCompletion={setCompletion}
      />

      <Modal
        open={pauseModal.open}
        onClose={() => setPauseModal({ open: false, pauseTigger: {} })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className="remove-trigger-modal">
          <div className="header">
            <div>
              <b>Pause Trigger</b>
            </div>
            <div>
              <IconButton
                onClick={() => setPauseModal({ open: false, pauseId: {} })}
                aria-label="save"
                sx={{
                  borderRadius: '4px',
                  padding: '2px',
                  backgroundColor: '#F2F2F2',
                  fontSize: '18px',

                }}
                size="small"
              >
                <Close />
              </IconButton>
            </div>
          </div>
          <div className="content">
            <p>
              Are you sure you want to Pause this trigger? You can later resume it by going to the edit page of this trigger.
            </p>
          </div>
          <div className="footer">
            <Button sx={{ marginRight: "10px" }} onClick={() => setPauseModal({ open: false, pauseId: {} })}>
              No
            </Button>
            <Button onClick={() => pauseTrigger()} variant="contained">
              Yes
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Triggers;
