import React, { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Modal,
  TextField,
} from "@mui/material";
import { DYNAMIC_PARA_OPTIONS } from "../Utils";

const classes={
  trigger: {
    border: "none",
    background: "transparent",
    padding: 0,
    margin: 0,
    width: "100%",
  },
  popover: {
    background: "#FFFFFF",
    borderRadius: "10px",
    margin: "10px 0",
    border: "none",
    width: "550px",
    position: "fixed",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
  },
  header: {
    background: "#FFFFFF",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
    borderRadius: "10px 10px 0px 0px",
    padding: "1rem",
    display: "flex",
    gap: "2rem",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "24px",
    color: "#1C1E21",
    margin: 0,
  },
  close: {
    background: "#F2F2F2",
    borderRadius: "4px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0.2rem",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
  },
  divider: {
    height: "0px",
    border: "1px solid #F2F2F2",
    width: "100%",
    marginBottom: "20px",
  },
  value: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#5F6771",
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "1rem",
    borderTop: "1px solid #F2F2F2",
    gap: "0.5rem",
  },
  button: {
    textTransform: "capitalize",
  },
  label: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "17px",
    color: "#5F6771",
  },
  field: {
    background: "#FFFFFF",
    border: "1px solid #EFEFEF",
    borderRadius: "8px",
    width: "100%",
    padding: "0.5rem 0.75rem",
    "& input": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "17px",
    },
    "& input::before, & input::after, & input:hover::before, &input:hover::after":
      {
        border: "none",
      },
  },
};

const labels = ["First", "Second", "Third", "Fourth"];
const placeholders = ["{brand_name}", "{date}", "{data_source}", "{time}"];
const defaultParaState = [
  DYNAMIC_PARA_OPTIONS[0],
  DYNAMIC_PARA_OPTIONS[1],
  DYNAMIC_PARA_OPTIONS[2],
];

export default function DynamicParametersPopover({
  nomenclature,
  heading = "Start data from",
  scheduleFrequency = "",
  onSave,
  children,
  disabled = false
}) {

  const [parameters, setParameters] = useState(defaultParaState);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!nomenclature.length) {
      if (scheduleFrequency === "hourly" && parameters.length === 3) {
        setParameters(defaultParaState.concat(DYNAMIC_PARA_OPTIONS[4]));
      } else if (scheduleFrequency !== "hourly" && parameters.length === 4) {
        setParameters(defaultParaState);
      }
    }
  }, [scheduleFrequency]);

  useEffect(() => {
    if (nomenclature.length) {
      setParameters(nomenclature);
    }
  }, [nomenclature]);

  return (
    <>
      <button style={classes.trigger} type="button" onClick={handleOpen} disabled={disabled}>
        {children}
      </button>
      <Modal open={open} onCancel={handleClose}>
        <div style={classes.popover}>
          <header style={classes.header}>
            <h2 style={classes.title}>{heading}</h2>
            <button
              aria-label="close"
              style={classes.close}
              onClick={handleClose}
            >
              <Close />
            </button>
          </header>
          <section style={classes.content}>
            <h4 style={classes.value}>
              {parameters
                .map((para, index) =>
                  para
                    ? para.custom
                      ? para.value
                      : para.id
                    : placeholders[index]
                )
                .join("_")}
            </h4>
            <hr style={classes.divider} />
            {parameters.map((parameter, index) => (
              <Grid
                container
                xs={12}
                style={{ marginBottom: index < 2 ? "0px" : "10px" }}
              >
                <Grid
                  item
                  xs={3}
                  flex
                  alignItems="center"
                  mb="10px"
                  style={{ marginTop: "8px" }}
                >
                  <span style={classes.label}>
                    {labels[index]} Parameter
                  </span>
                </Grid>
                <Grid item xs={9} mb="10px">
                  {!parameter?.custom ? (
                    <Autocomplete
                      id="grouped-dimensions"
                      value={parameter}
                      options={
                        index < 2
                          ? DYNAMIC_PARA_OPTIONS.slice(0, 2)
                          : index == 2
                          ? DYNAMIC_PARA_OPTIONS.slice(2, 4)
                          : [DYNAMIC_PARA_OPTIONS[4]]
                      }
                      isOptionEqualToValue={(option, value) =>
                        option?.id === value?.id
                      }
                      getOptionLabel={(option) => option.name}
                      disableClearable={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={placeholders[index]}
                          size="small"
                          value={parameter?.name}
                        />
                      )}
                      onChange={(event, newValue) => {
                        if (
                          index < 2 &&
                          parameters[Number(!index)].id === newValue.id
                        ) {
                          parameters[Number(!index)] = parameters[index];
                        }
                        parameters[index] = newValue;
                        setParameters([...parameters]);
                      }}
                    />
                  ) : (
                    <TextField
                      variant="outlined"
                      size="small"
                      sx={{ width: "100%" }}
                      InputProps={{ disableUnderline: true }}
                      value={parameter.value}
                      onChange={(event) => {
                        parameters[index].value = event.target.value;
                        setParameters([...parameters]);
                      }}
                    />
                  )}
                  {index < 2 && (
                    <FormControl
                      sx={{ width: "100%", justifyContent: "center" }}
                      size="small"
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={parameter?.custom}
                            onChange={(e) => {
                              if (e.target.checked) {
                                parameters[index] = {
                                  custom: e.target.checked,
                                  value: "",
                                };
                              } else {
                                parameters[index] =
                                  parameters[Number(!index)].id !==
                                  DYNAMIC_PARA_OPTIONS[index].id
                                    ? DYNAMIC_PARA_OPTIONS[index]
                                    : DYNAMIC_PARA_OPTIONS[Number(!index)];
                              }
                              setParameters([...parameters]);
                            }}
                          />
                        }
                        label={
                          <h4 style={{ margin: "auto", color: "#5F6771" }}>
                            Add custom field
                          </h4>
                        }
                      />
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            ))}
          </section>
          <footer style={classes.footer}>
            <Button style={classes.button} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              style={classes.button}
              variant="contained"
              onClick={() => {
                onSave(parameters);
                handleClose();
              }}
            >
              Apply
            </Button>
          </footer>
        </div>
      </Modal>
    </>
  );
}
