import { channelType } from "./transformer";

export const connectAccountApi = (payload) => {
    return {
        type: `${channelType}.CONNECT_ACCOUNT_API`,
        payload: payload,
    }
};

export const closeConnecting = () => {
    return {
        type: `${channelType}.HIDE_CONNECT_ACCOUNT`,
    }
};
