import {
  Box,
  FormControl,
  ListSubheader,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React from "react";
import { ChannelType } from "../../../models/channel";
import { useSelector } from "react-redux";
import AddLogo from "../../../Assets/Images/logos_add_chart.svg";
import RemoveLogo from "../../../Assets/Images/logos_remove_chart.svg";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FilterOperators } from "../Utils";
import InputSelect from "../../Common/InputSelect";

const REMOVE_BOOL_OPERATOR = "-";
const boolOperators = [REMOVE_BOOL_OPERATOR, "OR", "AND"];
const Effective_Status = [
  { id: "ACTIVE", name: "ACTIVE" },
  { id: "PAUSED", name: "PAUSED" },
  { id: "ARCHIVED", name: "ARCHIVED" },
  { id: "DELETED", name: "DELETED" },
  { id: "WITH_ISSUES", name: "WITH_ISSUES" },
  { id: "IN_PROCESS", name: "IN_PROCESS" },
  { id: "CAMPAIGN_PAUSED", name: "CAMPAIGN_PAUSED" },
  { id: "ADSET_PAUSED", name: "ADSET_PAUSED" },
  { id: "DISAPPROVED", name: "DISAPPROVED" }
]

const Objective_Enums = [
  { id: "OUTCOME_APP_PROMOTION", name: "OUTCOME_APP_PROMOTION" },
  { id: "OUTCOME_AWARENESS", name: "OUTCOME_AWARENESS" },
  { id: "OUTCOME_ENGAGEMENT", name: "OUTCOME_ENGAGEMENT" },
  { id: "OUTCOME_LEADS", name: "OUTCOME_LEADS" },
  { id: "OUTCOME_SALES", name: "OUTCOME_SALES" },
  { id: "OUTCOME_TRAFFIC", name: "OUTCOME_TRAFFIC" },
  { id: "APP_INSTALLS", name: "APP_INSTALLS" },
  { id: "BRAND_AWARENESS", name: "BRAND_AWARENESS" },
  { id: "CONVERSIONS", name: "CONVERSIONS" },
  { id: "EVENT_RESPONSES", name: "EVENT_RESPONSES" },
  { id: "LEAD_GENERATION", name: "LEAD_GENERATION" },
  { id: "LINK_CLICKS", name: "LINK_CLICKS" },
  { id: "LOCAL_AWARENESS", name: "LOCAL_AWARENESS" },
  { id: "MESSAGES", name: "MESSAGES" },
  { id: "OFFER_CLAIMS", name: "OFFER_CLAIMS" },
  { id: "PAGE_LIKES", name: "PAGE_LIKES" },
  { id: "POST_ENGAGEMENT", name: "POST_ENGAGEMENT" },
  { id: "PRODUCT_CATALOG_SALES", name: "PRODUCT_CATALOG_SALES" },
  { id: "REACH", name: "REACH" },
  { id: "STORE_VISITS", name: "STORE_VISITS" },
  { id: "VIDEO_VIEWS", name: "VIDEO_VIEWS" },
]

const ENUMS_DISPLAY = {
  "objective": Objective_Enums,
  "ad_effective_status": Effective_Status,
  "adset_effective_status": Effective_Status,
  "campaign_effective_status": Effective_Status,
}

export default function FilterList({ channel, filters, currentFilter, onChange, onDelete }) {
  const [boolOperator, setBoolOperator] = React.useState(currentFilter.boolOperator);
  const [filter, setFilter] = React.useState(filters.find(f => f.id === currentFilter.filter?.id
    && (f?.audiences?.[0]?.id ?? f?.account?.id) === (currentFilter.filter?.account?.id ?? currentFilter.filter?.audiences?.[0]?.id)
  ));
  const [operator, setOperator] = React.useState(currentFilter.operator);
  const [value, setValue] = React.useState(currentFilter.value ?? [""]);
  const [errors, setErrors] = React.useState({
    filter: false,
    operator: false,
    value: false
  });
  const [displayCol, setDisplayCol] = React.useState({
    METRIC: true,
    DIMENSION: true
  })

  const state = useSelector(state => channel.getState(state));
  React.useEffect(() => {
    const newErrors = { ...errors };
    if (filter) { newErrors.filter = false; }
    if (operator) { newErrors.operator = false; }
    if (value && (!Array.isArray(value) || (value.length && value[0]))) { newErrors.value = false; }
    setErrors(newErrors);
    onChange({
      boolOperator: boolOperator,
      filter: filter,
      operator: operator,
      value: value,
      urns: " "
    });
  }, [boolOperator, filter, operator, value])

  const supportedOperators = FilterOperators.filter(operator => {
    if (!filter) { return false; }
    const type = filter.type ?? "METRIC";
    return operator.dataType === type || operator.dataType.includes(type);
  })

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ width: "75%" }}>
        <div style={{ display: "flex" }}>
          <Box sx={{ padding: "5px 10px 10px 0px", width: "40%" }}>
            <InputSelect
              required={true}
              value={filter} options={filters}
              title={filter?.audiences?.[0]?.id ? `${filter?.audiences?.[0]?.metaData?.title} (${filter?.audiences?.[0]?.id})` : ""}
              placeholder="Select Filter*"
              onChange={(event, newValue) => { setFilter(newValue); setValue([""]); }}
              error={errors.filter}
              isOptionEqualToValue={(option, value) => option?.id === value?.id && option?.audiences?.[0]?.id === value?.audiences?.[0]?.id}
              renderGroup={(props) => {
                const groupHeaders = [];
                if (displayCol[filters[props.key].type]) {
                  groupHeaders.push(
                    <ListSubheader key={props.key} component="div" style={{ lineHeight: "inherit", top: "20px", paddingTop: "10px" }}>
                      <div style={{ paddingLeft: "10px" }}>{props.group}</div>
                    </ListSubheader>
                  )
                  groupHeaders.push(<ul style={{ paddingLeft: "10px" }}> {props.children} </ul>);
                }
                return (<>
                  {(props.key === 0 || filters[props.key].type !== filters[props.key - 1].type) ?
                    <ListSubheader key={props.key} component="div" style={{ lineHeight: "inherit", top: "-8px", paddingTop: "10px", zIndex: 5, height: "30px", color: "#1976d2CC" }}>
                      <div style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}>
                        {filters[props.key].type ?? "CUSTOM"}
                        <ArrowDropDownIcon onClick={() => { setDisplayCol({ ...displayCol, [filters[props.key].type]: !displayCol[filters[props.key].type] }) }} />
                      </div>
                    </ListSubheader> : null
                  }
                  <li>{groupHeaders}</li>
                </>)
              }}
            />
          </Box>
          <Box sx={{ padding: "5px 10px 10px 0px", width: "33%" }}>
            <InputSelect
              required
              value={supportedOperators.find(op => op.type === operator?.type)}
              options={supportedOperators}
              isOptionEqualToValue={(option, value) => option?.type === value?.type}
              onChange={(event, newValue) => {
                setOperator(newValue)
                if (Array.isArray(value)) {
                  setValue([value[0]])
                }
              }}
              title=""
              placeholder="Select Operator*"
              error={errors.operator}
            />
          </Box>
          {Array.isArray(value) ?
            <div style={{ display: "flex", flexDirection: "column", width: "33%" }}>
              {value.map((val, index) => <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "5px 10px 10px 0px", }}>
                {ENUMS_DISPLAY[filter?.id]
                  ? <InputSelect
                    required={true}
                    options={ENUMS_DISPLAY[filter?.id].filter(status => !value.includes(status.id))}
                    value={ENUMS_DISPLAY[filter?.id].find(status => status.id === val)}
                    onChange={(event, newValue) => {
                      let allVal = [...value];
                      allVal[index] = newValue.id
                      setValue(allVal);
                    }}
                    title=""
                    placeholder="Select Value*"
                    error={errors.value}
                  />
                  : <TextField
                    placeholder={channel.type === ChannelType.Bing ? "ex:value1,value2" : "Enter Value*"}
                    id="outlined-basic"
                    style={{ background: "white" }}
                    sx={{
                      '& input': { fontSize: "14px" },
                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #dddddd!important",
                        borderRadius: "6px !important"
                      },
                    }}
                    value={val}
                    variant="outlined"
                    size="small"
                    error={errors.value}
                    required
                    onChange={event => {
                      let allVal = [...value];
                      allVal[index] = event.target.value
                      setValue(allVal);
                    }}
                  />
                }
                {(operator?.type === "IN_LIST" || operator?.type === "NOT_IN_LIST") &&
                  <>
                    {
                      value.length > 1 &&
                      < img style={{ marginLeft: "8px", height: "22px" }} onClick={() => setValue(value.filter((v, i) => i !== index))} src={RemoveLogo} alt="Remove Value" />
                    }
                    {index === (value.length - 1) &&
                      < img style={{ marginLeft: "8px", height: "22px" }} onClick={() => setValue([...value, ""])} src={AddLogo} alt="Add Value" />
                    }
                  </>
                }
              </Box>)}
            </div>
            : <FormControl sx={{ m: 1, width: "33%" }} size="small">
              <TextField
                label="Enter Value"
                placeholder={channel.type === ChannelType.Bing ? "ex:value1,value2" : "Enter Value"}
                id="outlined-basic"
                style={{ background: "white" }}
                value={value}
                variant="outlined"
                size="small"
                error={errors.value}
                required
                onChange={event => setValue(event.target.value)}
              />
            </FormControl>
          }
        </div>
        {boolOperator && <div style={{ padding: "10px", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center" }}>
          <span>{boolOperator}</span>
          {
            (!channel.properties.validFilterBoolOperators || channel.properties.validFilterBoolOperators.length !== 1) &&
            boolOperator === "AND" &&
            <div style={{ flex: 1, height: "1px", marginLeft: "10px", backgroundColor: "grey" }} />
          }
        </div>}
      </div>
      <div>
        <FormControl sx={{ m: 1 }} size="small">
          <ToggleButtonGroup
            color="primary"
            exclusive
            size="small"
            value={boolOperator}
            onChange={(event, newValue) => {
              if (!newValue) { return; }

              if (newValue === REMOVE_BOOL_OPERATOR) { return onDelete(); }

              const newErrors = { ...errors };
              if (!filter) { newErrors.filter = true; }
              if (!operator) { newErrors.operator = true; }
              if (!value || (Array.isArray(value) && value.length && !value[0])) { newErrors.value = true; }
              setErrors(newErrors);
              if (newErrors.filter || newErrors.operator || newErrors.value) { return; }

              setBoolOperator(newValue);
            }}
          >
            {boolOperators.filter(bo => (bo === REMOVE_BOOL_OPERATOR ? onDelete : (!channel.properties.validFilterBoolOperators || channel.properties.validFilterBoolOperators.find(b => b === bo)))).map((bo, index) =>
              <ToggleButton
                key={index}
                style={{ width: "48px", height: "40px", background: "white", border: "1px solid #ECEEF1", borderRadius: "8px", marginLeft: "10px" }}
                value={bo}
                disabled={bo !== REMOVE_BOOL_OPERATOR && channel.properties.validFilterBoolOperators && !channel.properties.validFilterBoolOperators.find(b => b === bo)}
              >
                {bo}
              </ToggleButton>
            )}
          </ToggleButtonGroup>
        </FormControl>
      </div>
    </div>
  );
}
