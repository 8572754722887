import { api } from "..";
import { USER_DETAILS, USER_DETAILS_SUCCESS, USER_DETAILS_FAILURE, UPDATE_USER, UPDATE_USER_ORG, UPDATE_USER_ORG_SUCCESS, UPDATE_TOUR_FLAG, UPDATE_TOUR_FLAG_SUCCESS } from "./type";
import { all, put, takeLatest } from "redux-saga/effects";
import mixpanelObj,{EVENTS,transformMixPanelData} from "../../utils/mixpanel";

const setUserInfoIntoLocalstorage = (obj) => {
    localStorage.setItem("userInfo",JSON.stringify(obj));
}

const MAPPED_APIS_REMOVE = {
    "Organization Members": "/v2/analytics/role-management/remove-user",
    "Pending Invitations": "/v2/analytics/role-management/remove/pending-invitation",
    "Inactive Users": "/v2/analytics/role-management/remove/inactive-user"
}
function* updateUser(args) {
    try {
        yield api.post("/v2/analytics/users/update-user/", args.payload);
        yield put({
            type: USER_DETAILS,
        })
        transformMixPanelData(EVENTS.change_name,{});
    } catch (error) {
    }
}

function* updateUserOrg(args) {
    try {
        yield put({
            type: UPDATE_USER_ORG_SUCCESS,
            payload: args.payload
        });
    } catch (error) {
    }
}

function* endTour() {
    try {
        yield api.post("/v2/analytics/users/set-tutorial-flag");
        yield put({
            type: UPDATE_TOUR_FLAG_SUCCESS,
        })
        window.location.href = "/dashboard";
    } catch (error) {
    }
}

export const ChangePassword = payload => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post("/v2/analytics/users/change-password/", payload);
        transformMixPanelData(EVENTS.change_password,{});
        resolve(response.data);
    } catch (error) {
        reject(error.response);
    }
});

export const ForgetPassword = payload => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post("/v2/analytics/users/forgot-password/", payload, false);
        resolve(response.data);
    } catch (error) {
        reject(error.response);
    }
});

export const ResetPassword = payload => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post("/v2/analytics/users/reset-password/", payload, false);
        resolve(response.data);
    } catch (error) {
        reject(error.response);
    }
});

function* GetProfileDetails() {
    try {
        const response = yield api.post("/v2/analytics/users/userDetail/");
        yield put({
            type: USER_DETAILS_SUCCESS,
            payload: response.data
        });
        setUserInfoIntoLocalstorage(response.data.user);
    } catch (error) {
        yield put({
            type: USER_DETAILS_FAILURE,
            payload: error.response
        });
    }
}

export const getOrgainzationMembers = payload => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post("/v2/analytics/role-management/get-organization-members", payload, true);
        resolve(response.data);
    } catch (error) {
        reject(error.response);
    }
});
export const getInvitedOrgainzationMembers = payload => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post("/v2/analytics/role-management/get-invited-organization-members", payload, true);
        resolve(response.data);
    } catch (error) {
        reject(error.response);
    }
});

export const inviteUsers = payload => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post("/v2/analytics/role-management/invite-user", payload, true);
        resolve(response.data);
    } catch (error) {
        reject(error.response);
    }
});

export const changeUserRole = payload => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post("/v2/analytics/role-management/change-user-role", payload, true);
        transformMixPanelData(EVENTS.change_user_role,payload);
        resolve(response.data);
    } catch (error) {
        reject(error.response);
    }
});

export const removeInvitedUserRoleApi = payload => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post(MAPPED_APIS_REMOVE[payload.API_TYPE], payload, true);
        transformMixPanelData(EVENTS.remove_user,payload);
        resolve(response.data);
    } catch (error) {
        reject(error.response);
    }
});

export const reInviteBlockedUserApi = payload => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post("/v2/analytics/role-management/unblock-user", payload, true);
        resolve(response.data);
    } catch (error) {
        reject(error.response);
    }
});


export const subscribeToNewsletter = payload => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post("/v2/analytics/users/subscribe", payload, false);
        resolve(response.data);
    } catch (error) {
        reject(error.response);
    }
});

export default function* root() {
    yield all([
        takeLatest(USER_DETAILS, GetProfileDetails),
        takeLatest(UPDATE_USER, updateUser),
        takeLatest(UPDATE_USER_ORG, updateUserOrg),
        takeLatest(UPDATE_TOUR_FLAG, endTour),
    ])
}
