import { Grid, Tab } from "../../models/report";
import { Chart, Metric, Filter } from "../../models/chart";
import { channelType } from "./transformer";


//tables
const AccountPerformance = { id: "Basic", name: "Basic" };


//metrics
const Impressions={id:"impressions",name:"Impressions"}
const Conversions={id:"conversion",name:"Conversions"}
const Clicks = { id: "clicks", name: "Clicks" };
const Ctr= {id:"ctr",name:"Ctr"}
const CampaignName={id:"campaign_name",name:"Campaign name"}

//dimensions
const DATE = { id: "Date", name: "Date" };

const overallCharts = [
  new Chart(channelType, "Impressions vs Clicks", "LINE",AccountPerformance,
      { x: 0, y: 0, w: 6, h: 2, static: false },
      [new Metric(Impressions)],[new Metric(Ctr)]
  ),
  new Chart(channelType, "Conversions", "BAR",AccountPerformance,
      { x: 6, y: 0, w: 6, h: 2, static: false },
      [new Metric(Conversions)],[]
      [DATE]
  ),
  new Chart(channelType, "Click Through Rate", "KPI",AccountPerformance,
      { x: 3, y: 0, w: 3, h: 1, static: false },
      [new Metric(Ctr)]
      
  ),
  new Chart(channelType, "Campaign Performance", "TABLE",AccountPerformance,
      { x: 0, y: 0, w: 6, h: 2, static: false },
      [new Metric(Impressions),new Metric(Clicks),new Metric(Ctr)],[],
      [CampaignName]
  ),
  new Chart(channelType, "Account Funnel", "FUNNEL",AccountPerformance,
      { x: 6, y: 0, w: 6, h: 2, static: false },
      [new Metric(Impressions),new Metric(Clicks),new Metric(Ctr)]
  ),
  new Chart(channelType, "Ad Group Performance", "TABLE",AccountPerformance,
      { x: 6, y: 0, w: 6, h: 2, static: false },
      [new Metric(Impressions),new Metric(Clicks),new Metric(Conversions)],[new Metric(Ctr)]
  ),
]; 

const overallGrid = [
  new Grid(null, null, null, overallCharts),
];

export default [
  new Tab("Overall", 0, overallGrid),
];
