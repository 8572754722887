import {
  Box,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import CustomLoader from "../../Common/CustomLoader";
import { Close } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 800,
  maxWidth: 1200,
  maxHeight: "82vh",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  p: 4,
  fontSize: "16px",
};

export default function PreviewTable({ heading, preview, isLoading, onClose }) {
  const { open, data } = preview;
  return (
    <div>
      <Modal
        open={true}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="grid-item" sx={style}>
          {isLoading ? (
            <div style={{ padding: "50px" }}>
              <CustomLoader />
            </div>
          ) : (
            <>
              <div className="displayRow">
                <h1>{heading || "Sheet Name"}</h1>
                <button
                  style={{ border: "none", display: "flex", padding: "0.2rem" }}
                  aria-label="close"
                  onClick={onClose}
                >
                  <Close />
                </button>
              </div>
              <TableContainer className="trigger-table">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {data.headers.map((head) => (
                        <TableCell>{head.name}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.rows.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {row.map((value) => (
                          <TableCell scope="row">{value}</TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}
