import { Box, Button, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Manage from './Assets/manage_icon.svg'
import Google from './Assets/analytics_icon.svg'
import './leftbar.scss'
import { useHistory, useParams } from "react-router";
import { GetChannel } from "../../models/channel";
import RightArrow from './Assets/rightarrow.svg'
import { connect } from "react-redux";
import { handleMixPanelData, EVENTS } from "../../utils/mixpanelV2";

const ManageSources = ({ selectedAccounts, authenticatedAccounts, navigationCallback }) => {

  const { channel: channelString } = useParams();
  const history = useHistory();
  const [hideOptions] = useState(false)
  const [isPanelExpanded, setIsPanelExpanded] = useState(false);
  const useStyles = {
    customTextField: {
      color: 'white',
      '&:before': {
        borderBottomColor: 'red', // replace with desired color
        borderBottomSize: '0px !important',
        display: 'none',
      },
      '&:hover:before': {
        borderBottomColor: 'red',
        borderBottomSize: '0px !important',
        display: 'none',
      },
      '&:after': {
        border: 'none',
      },
    },

    scrollContainer: {
      '& ::-webkit-scrollbar': {
        // width: 2,
        // height: 23,
        display: "none"
      },
      '& ::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        borderRadius: 20,
      },
    },

    selectOption: {
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.03)',
      },
    },
  };
  // const classes = useStyles();
  // const [open, setOpen] = useState(false);

  //   useEffect(() => {
  //     if (!hideOptions) {
  //       setOpen(false)
  //     }
  //   }, [hideOptions])
  return (
    <div style={{ width: '100%', position: 'relative', textAlign: 'left', userSelect: 'none', cursor: "pointer" }} onClick={() => {
      navigationCallback ? navigationCallback(() => history.push("/managesources", { prevPath: history.location.pathname, channelType: channelString })) :
    history.push("/managesources", { prevPath: history.location.pathname, channelType: channelString })
    }
    }>
      <div
        style={{
          border: '0.273px solid #0C5F76',
          height: '55px',
          margin: '0px 14px',
          background: '#153643',
          width: isPanelExpanded ? '52px' : 'auto',
          transition: 'height 1.5s ease, margin 1.5s ease',
          borderRadius: '12px',
          color: 'white',
          padding: '0 10px',
          justifyContent: isPanelExpanded ? 'center' : 'flex-start',
          alignItems: 'center',
          display: 'flex',
        }}>
        <div style={{
          display: 'flex',
          background: 'rgba(0, 0, 0, 0.18)',
          padding: '7px',
          width: '32px',
          minWidth: '32px',
          height: '32px',
          borderRadius: '100%',
          fontSize: '14px',
          float: 'left',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          marginRight: isPanelExpanded ? '0px' : '10px',
          transition: 'height 2s ease, width 1.5s ease, font-size 2s ease',
        }}>
          <img src={Manage} alt="" />

        </div>
        {!isPanelExpanded && (

          <div style={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}>
            <div style={{ display: "flex", flexDirection: 'row', alignItems: "center", marginLeft: '13px', gap: '4px' }} onClick={() => { handleMixPanelData(EVENTS.click_data_sources);
                navigationCallback ? navigationCallback(() => history.push("/managesources", { prevPath: history.location.pathname, channelType: channelString })) :
                history.push("/managesources", { prevPath: history.location.pathname, channelType: channelString })}}>
              <div>
                <div className="text-ellipsis">
                  <Typography style={{ fontFamily: 'Inter', fontWeight: '500', fontSize: '12px', lineHeight: '20px' }}>Connected Accounts  </Typography>
                </div>
                <div className="connected_accounts" style={{ display: 'flex', flexDirection: "row", width: '110px', overflowY: 'scroll', gap: '5px' }}>

                  {authenticatedAccounts.map((channelType, index) => {
                    const channel = GetChannel(channelType);
                    return (
                      <img
                        src={channel?.icon}
                        alt={channel?.title}
                        height={16}
                        width={16}
                      />
                    )
                  })}
                </div>
              </div>
              <img src={RightArrow} style={{ height: "24px", width: "24px" }} alt="" />
            </div>
          </div>
        )}
      </div>

    </div>

  )
}
const mapStateToProps = (state) => ({
  selectedAccounts: state.channel.selectedAccounts,
  authenticatedAccounts: state.channel.authenticatedAccounts,
  user: state.user.user,
});

export default connect(mapStateToProps)(ManageSources)