export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAILURE = "USER_DETAILS_FAILURE";
export const USER_DETAILS = "USER_DETAILS";

export const UPDATE_USER = "UPDATE_USER";

export const UPDATE_USER_ORG = "UPDATE_USER_ORG"
export const UPDATE_USER_ORG_SUCCESS = "UPDATE_USER_ORG_SUCCESS"

export const UPDATE_TOUR_FLAG = "UPDATE_TOUR_FLAG"
export const UPDATE_TOUR_FLAG_SUCCESS = "UPDATE_TOUR_FLAG_SUCCESS"
export const UPDATE_TOUR_INFO = "UPDATE_TOUR_INFO"
export const UPDATE_LOADER = "UPDATE_LOADER"
