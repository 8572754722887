import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import "../../../styles/filterpopup.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Scrollbars from "react-custom-scrollbars";
import { useDispatch } from "react-redux";
import DataList from "../../CustomizingSources/BlendDataSource/DataList";
import LinkIcon from "../../../Assets/Icons/link.svg";
import FolderIcon from "../../../Assets/Icons/folder-icon.svg";
import "../../styles/profile.css";
import { ReactComponent as IconInfo } from "../../../Assets/Icons/info-circle.svg";
import StartDataPopover from "./StartDataPopover";
import {
  BOOLEAN_OPTIONS,
  WRITE_MODE_OPTIONS,
  STORAGE_OPTIONS,
  transformToSuperMetricDataObject,
  DYNAMIC_PARA_OPTIONS,
} from "../Utils";
import DataInput from "../../Common/DataInput";
import DynamicParametersPopover from "./DynamicParametersPopover";
import CustomLoader from "../../Common/CustomLoader";
import { createTrigger, updateTrigger } from "../../../api/extractor/action";
import { updateLoader } from "../../../api/user/action";

const defaultStorage = {
  headerLine: false, //hasHeaders
  startCell: { column: "", row: "" }, //startingCell
  endCell: { column: "", row: "" }, //endingCell
  newEmptySheet: { id: "yes", name: "Yes" }, //spreadsheetCreate
  existingSheet: null, //spreadsheetId
  sheetNomenclature: "", //spreadsheetName
  tabNomenclature: "", //tabName
  sheetFormat: [],
  tabFormat: [],
  existingFolder: {
    id: "root",
    name: "Root Folder",
    type: "folder",
  },
};

export default function TriggerIngestion({
  nameRef,
  nameError,
  checkNameError,
  updateId,
  channel,
  table,
  dataLevel,
  accounts,
  extractData,
  loading,
  dateRange,
  compareDateRange,
  infoComplete,
  setInfoComplete,
  triggerData,
  setTriggerData,
  scheduleData,
  setScheduleData,
  storageData,
  setStorageData,
  setOpenScheduleBox,
  setOpenIngestionBox,
  setCompletion,
  extra,
  isBulkEditing = false
}) {
  // nameRef, checkNameError, dateRange, infoComplete, setInfoComplete, triggerData, setTriggerData,  scheduleData, setScheduleData, storageData, setStorageData, setOpenScheduleBox, setOpenIngestionBox
  // accountCols, resetData,
  const user = useSelector((state) => state.user.user);
  const email_connected = useSelector(
    (state) => state.extractor.email_connected
  );
  const availableSheets =
    useSelector((state) => state.extractor.availableSheets) ?? [];
  const dispatch = useDispatch();

  const [sendOverEmail, setSendOverEmail] = useState(
    extractData.sendOverEmail ?? false
  );

  useEffect(() => {
    let scheduleFrequency = scheduleData.action?.id;
    if (scheduleFrequency) {
      if (
        scheduleFrequency === "hourly" &&
        storageData.sheetFormat.length === 3
      ) {
        storageData.sheetFormat.push(DYNAMIC_PARA_OPTIONS[4]);
        setStorageData({ ...storageData });
      } else if (
        scheduleFrequency !== "hourly" &&
        storageData.sheetFormat.length === 4
      ) {
        setStorageData({
          ...storageData,
          sheetFormat: storageData.sheetFormat.slice(0, 3),
        });
      }

      if (
        scheduleFrequency === "hourly" &&
        storageData.tabFormat.length === 3
      ) {
        storageData.tabFormat.push(DYNAMIC_PARA_OPTIONS[4]);
        setStorageData({ ...storageData });
      } else if (
        scheduleFrequency !== "hourly" &&
        storageData.tabFormat.length === 4
      ) {
        setStorageData({
          ...storageData,
          tabFormat: storageData.tabFormat.slice(0, 3),
        });
      }
    }
  }, [scheduleData.action]);

  useEffect(() => {
    if (storageData.newEmptySheet.id === "yes") {
      setSendOverEmail(true);
    }
  }, [storageData.newEmptySheet]);

  const saveData = () => {
    const error = checkNameError();
    if (error) return;
    dispatch(updateLoader(true));
    const extractedData = transformToSuperMetricDataObject({
      name: nameRef.current,
      channel,
      table,
      dataLevel,
      accounts,
      dateRange,
      compareDateRange,
      sendOverEmail,
      status: "ACTIVE",
      triggerData,
      scheduleData,
      storageData,
    });
    if (updateId && !extractData?.isDuplicate) {
      dispatch(
        updateTrigger({
          triggerId: updateId,
          triggerObj: extractedData,
          loading: (msg) => {
            dispatch(updateLoader(false));
            setCompletion({ message: msg, operation: "Updation" });
          },
        })
      );
    } else {
      dispatch(
        createTrigger({
          trigger: extractedData,
          // setError: setOpenErrorMsg,
          loading: (msg) => {
            dispatch(updateLoader(false));
            setCompletion({
              message: msg,
              operation: extractData?.isDuplicate ? "Duplication" : "Creation",
            });
          },
        })
      );
    }
  };
  useEffect(()=>{
    extra?.type === "BULK_EDITING" && setSendOverEmail(extractData?.sendOverEmail ?? false);
  },[extractData]);

  return loading ? (
    <Box className="loader">
      <CustomLoader
        heading={"Waiting for account columns data"}
        subHeading=""
      />
    </Box>
  ) : (
    <Box className="trigger-form">
      <div
        className="Schedule_name"
        style={{
          borderBottom: "1px solid #eee",
          paddingBottom: "18px",
          paddingLeft: "16px",
          paddingTop: "18px",
        }}
      >
        <Typography className="extractorHeaders">Store Your Data*</Typography>
      </div>

      <Scrollbars autoHide style={{ minHeight: "388px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "15px",
            height: "100%",
            padding: "10px 20px",
          }}
        >
          <Grid container xs={12} style={{ marginBottom: "10px" }}>
            <Tooltip
              title={
                !email_connected
                  ? "Please connect an account before selecting a storage."
                  : ""
              }
            >
              <Grid item xs={6}>
                <div style={{ margin: "5px 0px", display: "flex" }}>
                  <Typography className="newText">Data Storage*</Typography>
                </div>
                <DataList
                  metric={storageData.storage}
                  metrics={STORAGE_OPTIONS}
                  isEditable={true}
                  onClick={(storage) =>
                    setStorageData({ ...storageData, storage })
                  }
                  defaultTitle="Select data storage"
                  disabled={!email_connected || isBulkEditing}
                />
              </Grid>
            </Tooltip>

            {/* Email Account Connection */}
            <Grid item xs={7}></Grid>

            <Grid item xs={6} style={{ marginTop: "10px" }}>
              <div style={{ margin: "5px 0px", display: "flex" }}>
                <Typography
                  className="newText"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Write Mode*
                  <Tooltip
                    title={"Choose how you want your data to be ingested to the sheet, every time the trigger runs. This will affect how you will see the old datasets ingested"}
                    placement="top">
                    <IconInfo style={{ marginLeft: "10px" }} />
                  </Tooltip>
                </Typography>
              </div>
              <DataList
                metric={storageData.writeMode}
                metrics={WRITE_MODE_OPTIONS}
                isEditable={true}
                onClick={(writeMode) => {
                  setStorageData({
                    ...storageData,
                    writeMode,
                    ...defaultStorage,
                    tabNomenclature: ["append", "replace"].includes(
                      writeMode?.id
                    )
                      ? "Sheet1"
                      : "",
                  });
                }}
                defaultTitle="Select Write mode"
                disabled={isBulkEditing}
              />
            </Grid>

            <Grid
              item
              xs={6}
              style={{
                marginTop: "10px",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <FormControl
                sx={{ width: "100%", justifyContent: "center" }}
                size="small"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      disableRipple
                      checked={storageData.headerLine}
                      sx={{
                        '&.Mui-checked': {
                          color: isBulkEditing ? "#eee" : "#0869FB",
                        },
                      }}
                      onChange={(e) => {
                        setStorageData({
                          ...storageData,
                          headerLine: e.target.checked,
                        });
                      }}
                      disabled={isBulkEditing}
                    />
                  }
                  label={
                    <Typography className="newText">
                      Make first row as header
                    </Typography>
                  }
                />
              </FormControl>
            </Grid>

            {["append", "replace"].includes(storageData.writeMode?.id) && (
              <Grid container xs={12}>
                <Grid item xs={6} style={{ marginTop: "10px" }}>
                  <div style={{ margin: "5px 0px", display: "flex" }}>
                    <Typography
                      className="newText"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Start Data From*
                      <Tooltip
                        title={"Mention the cell on the sheet you want to start the ingestion from."}
                        placement="top">
                        <IconInfo style={{ marginLeft: "10px" }} />
                      </Tooltip>
                    </Typography>
                  </div>
                  <StartDataPopover
                    heading="Start data from"
                    data={storageData.startCell}
                    onSave={(startCell) =>
                      setStorageData({ ...storageData, startCell })
                    }
                  >
                    <DataList
                      metric={{
                        name: `${storageData.startCell.column || "{Column}"}:${storageData.startCell.row || "{Row}"
                          }`,
                      }}
                      stretch={"100%"}
                      metrics={[]}
                      isEditable={false}
                      defaultTitle="Start data from"
                      disabled={isBulkEditing}
                    />
                  </StartDataPopover>
                </Grid>
                <Grid item xs={6} style={{ marginTop: "10px" }}>
                  <div style={{ margin: "5px 0px", display: "flex" }}>
                    <Typography
                      className="newText"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      End Data At
                      <Tooltip
                        title={"Mention the cell on the sheet you want to end the ingestion at."}
                        placement="top">
                        <IconInfo style={{ marginLeft: "10px" }} />
                      </Tooltip>
                    </Typography>
                  </div>
                  <StartDataPopover
                    heading="End data at"
                    data={storageData.endCell}
                    onSave={(endCell) =>
                      setStorageData({ ...storageData, endCell })
                    }
                  >
                    <DataList
                      metric={{
                        name: `${storageData.endCell.column || "{Column}"}:${storageData.endCell.row || "{Row}"
                          }`,
                      }}
                      stretch={"100%"}
                      metrics={[]}
                      isEditable={false}
                      defaultTitle="End data at"
                      disabled={isBulkEditing}
                    />
                  </StartDataPopover>
                </Grid>
              </Grid>
            )}

            <Grid
              container
              xs={12}
              style={{ display: "flex", alignItems: "flex-end" }}
            >
              <Grid item xs={6} style={{ marginTop: "10px" }}>
                <div style={{ margin: "5px 0px", display: "flex" }}>
                  <Typography
                    className="newText"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Create New Empty Sheet*
                    <Tooltip
                      title={"Choose if you want us to create a new spreadsheet in your drive to ingest the data when the trigger runs for the very first time."}
                      placement="top"
                    >
                      <IconInfo style={{ marginLeft: "10px" }} />
                    </Tooltip>
                  </Typography>
                </div>
                <DataList
                  metric={storageData.newEmptySheet}
                  metrics={BOOLEAN_OPTIONS}
                  isEditable={
                    storageData.writeMode?.id === "new-sheet" ? false : true
                  }
                  onClick={(newEmptySheet) => {
                    let tabNomenclature = storageData.tabNomenclature;
                    if (
                      ["append", "replace"].includes(storageData.writeMode?.id)
                    ) {
                      if (newEmptySheet.id === "yes")
                        tabNomenclature = "Sheet1";
                      else tabNomenclature = "";
                    }
                    setStorageData({
                      ...storageData,
                      newEmptySheet,
                      existingSheet: null,
                      sheetNomenclature: "",
                      tabNomenclature,
                    });
                  }}
                  defaultTitle="Select Option"
                  disabled={isBulkEditing}
                />
                {/* <FormControl>
                <RadioGroup row
                defaultValue="yes"
                onChange= {(e) => {
                  let tabNomenclature = storageData.tabNomenclature;
                  if (
                    ["append", "replace"].includes(storageData.writeMode?.id)
                  ) {
                    if (e.target.value === "yes") tabNomenclature = "Sheet1";
                    else tabNomenclature = "";
                  }
                  setStorageData({
                    ...storageData,  
                    newEmptySheet: {id: (e.target.value === 'yes' ? 'yes' : 'no'), name: (e.target.value === 'yes' ? 'Yes' : 'No')},
                     // newEmptySheet,
                    // : { id: newEmptySheet , name: newEmptySheet },
                    existingSheet: null,
                    sheetNomenclature: "",
                    tabNomenclature,
                  });
                }}               
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 17,
                    color: '#0968fb',
                  },                
                }}
                >
                  <FormControlLabel  value='yes' control={<Radio />} label={<Typography style={{fontFamily: 'Inter', fontSize: '12px', fontWeight: '400'}}>Yes</Typography>}/>
                  <FormControlLabel value='no' control={<Radio />} label={<Typography style={{fontFamily: 'Inter', fontSize: '12px', fontWeight: '400'}}>No</Typography>}/>
                </RadioGroup>
              </FormControl> */}
              </Grid>
              {storageData.newEmptySheet.id === "yes" && (
                <>
                  <Grid item xs={6} style={{ marginTop: "10px" }}>
                    <div style={{ margin: "5px 0px", display: "flex" }}>
                      <Typography className="newText">
                        Select Folder
                      </Typography>
                    </div>
                    <DataList
                      style={{ width: "100%" }}
                      metric={storageData.existingFolder}
                      metrics={[{
                        id: "root",
                        name: "Root Folder",
                        type: "folder",
                      }].concat(availableSheets.filter((sheet) => sheet.type === "folder"))}
                      isEditable={true}
                      onClick={(existingFolder) =>
                        setStorageData({ ...storageData, existingFolder })
                      }
                      defaultTitle="Root Folder"
                      startAdornment={{ src: FolderIcon }}
                      disabled={isBulkEditing}
                    // endAdornment={{ src: FolderIcon }}
                    // availableSheets
                    // .concat({
                    //   id: "root",
                    //   name: "Root Folder",
                    //   type: "folder",
                    // })
                    />
                  </Grid>
                </>
              )}
            </Grid>

            <Grid container xs={12}>
              {storageData.newEmptySheet.id === "yes" ? (
                <>
                  <Grid item xs={6} style={{ marginTop: "10px" }}>
                    <div style={{ margin: "5px 0px", display: "flex" }}>
                      <Typography className="newText">
                        Nomenclature of Sheet*
                      </Typography>
                    </div>
                    <DataInput
                      value={
                        storageData.sheetNomenclature ||
                        storageData.sheetFormat
                          .map((para) => (para.custom ? para.value : para.id))
                          .join("_")
                      }
                      stretch={"100%"}
                      isEditable={
                        storageData.writeMode?.id === "new-sheet" ? false : true
                      }
                      onChange={(sheetNomenclature) =>
                        setStorageData({ ...storageData, sheetNomenclature })
                      }
                      defaultTitle="|Spreadsheet"
                      disabled={isBulkEditing}
                    />
                  </Grid>
                  {storageData.writeMode?.id === "new-sheet" && (
                    <Grid
                      item
                      xs={6}
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        alignItems: "flex-end",
                      }}
                    >
                      <DynamicParametersPopover
                        heading="Sheet Label"
                        nomenclature={storageData.sheetFormat}
                        scheduleFrequency={scheduleData.action?.id}
                        onSave={(sheetFormat) =>
                          setStorageData({ ...storageData, sheetFormat })
                        }
                        disabled={isBulkEditing}
                      >
                        <Typography
                          style={{
                            color: "#0869FB",
                            padding: "10px 0px",
                            textAlign: "left",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: "500",
                            cursor: isBulkEditing ? "auto" : "pointer"
                          }}
                        >
                          Make Dynamic Parameters
                        </Typography>
                      </DynamicParametersPopover>
                    </Grid>
                  )}
                </>
              ) : (
                <Grid item xs={6} style={{ marginTop: "10px" }}>
                  <div style={{ margin: "5px 0px", display: "flex" }}>
                    <Typography className="newText">
                      Select Spreadsheet*
                    </Typography>
                  </div>
                  <DataList
                    metric={storageData.existingSheet}
                    metrics={availableSheets.filter(
                      (sheet) => sheet.type === "file"
                    )}
                    isEditable={true}
                    onClick={(existingSheet) =>
                      setStorageData({ ...storageData, existingSheet })
                    }
                    defaultTitle="Select spreadsheet"
                    endAdornment={{ src: LinkIcon }}
                    disabled={isBulkEditing}
                  />
                </Grid>
              )}
            </Grid>

            <Grid item xs={6} style={{ marginTop: "10px" }}>
              <div style={{ margin: "5px 0px", display: "flex" }}>
                <Typography className="newText">
                  Nomenclature Of Tab*
                </Typography>
              </div>
              <DataInput
                value={
                  storageData.tabNomenclature ||
                  storageData.tabFormat
                    .map((para) => (para.custom ? para.value : para.id))
                    .join("_")
                }
                stretch={"100%"}
                isEditable={
                  storageData.writeMode?.id === "new-tab" ? false : true
                }
                onChange={(tabNomenclature) =>
                  setStorageData({ ...storageData, tabNomenclature })
                }
                defaultTitle={"|Tab"}
                disabled={isBulkEditing}
              />
            </Grid>

            <Grid
              item
              xs={6}
              style={{
                marginTop: "10px",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              {storageData.writeMode?.id === "new-tab" ? (
                <DynamicParametersPopover
                  heading="Tab Label"
                  scheduleFrequency={scheduleData.action?.id}
                  nomenclature={storageData.tabFormat}
                  onSave={(tabFormat) =>
                    setStorageData({ ...storageData, tabFormat })
                  }
                  disabled={isBulkEditing}
                >
                  <Typography
                    style={{
                      color: "#0869FB",
                      padding: "10px 0px",
                      textAlign: "left",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "500",
                      cursor: isBulkEditing ? "auto" : "pointer",
                    }}
                  >
                    Make Dynamic Parameters
                  </Typography>
                </DynamicParametersPopover>
              ) : ["append", "replace"].includes(storageData.writeMode?.id) &&
                storageData.newEmptySheet.id === "no" ? (
                <div
                  style={{
                    borderRadius: "8px",
                    background: "#1877F211",
                    display: "flex",
                    alignItems: "center",
                    margin: "5px",
                    padding: "8px",
                  }}
                >
                  <IconInfo style={{ color: "#999999", margin: "0px 5px" }} />
                  <div
                    style={{
                      paddingLeft: "5px",
                      fontSize: "12px",
                      fontFamily: "Inter",
                      fontWeight: "500",
                      lineHeight: "20px",
                      color: "#585969",
                    }}
                  >
                    Please use existing tab name.
                  </div>
                </div>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Box>
      </Scrollbars>

      <div
        className="footer_para"
        style={{
          width: "100%",
          height: "72px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0px 16px",
        }}
      >
        <FormControl
          sx={{ width: "50%", justifyContent: "center" }}
          size="small"
        >
          <FormControlLabel
            sx={{
              ".MuiFormControlLabel-label": {
                color: (storageData.newEmptySheet.id === "yes") ? "#d9d9d9" : "#000000",
              },
              cursor: (storageData.newEmptySheet.id === "yes") ? "default" : "pointer",
            }}
            control={
              <Checkbox
                checked={sendOverEmail}
                disableRipple
                onChange={(e) => {
                  storageData.newEmptySheet.id !== "yes" &&
                    setSendOverEmail(e.target.checked);
                }}
                sx={{
                  '&.Mui-checked': {
                    color: isBulkEditing ? "#eee" : (storageData.newEmptySheet.id === "yes") ? "#EBF3FF" : "#0869FB", // "#0869FB88"
                    cursor: (storageData.newEmptySheet.id === "yes") ? "default" : "pointer"
                  },
                }}
                disabled={isBulkEditing}
              />
            }
            label="Send copy on Email"
          />
        </FormControl>
        {!isBulkEditing && <Tooltip
          title={
            user.role === "viewer"
              ? `Viewer does not have permission to ${updateId ? "Update" : "Create"
              }!`
              : ""
          }
          placement="top"
        >
          <div>
            <Button
              disabled={
                user.role === "viewer" ||
                !(
                  !nameError &&
                  infoComplete.triggerComplete &&
                  infoComplete.scheduleComplete &&
                  infoComplete.storageComplete
                )
              }
              onClick={() => {
                saveData();
              }}
              variant="contained"
              sx={{
                backgroundColor: "#0869FB",
                variant: "contained",
                "&:hover": {
                  backgroundColor: "#0869FB",
                  borderColor: "#0062cc",
                  boxShadow: "none",
                },
                color: "#ffffff",
                ":disabled": { color: "#0869FB88", backgroundColor: "#EBF3FF" },
              }}
            >
              {updateId
                ? extractData.isDuplicate
                  ? "Duplicate"
                  : extractData.status === "PAUSED"
                    ? "Resume"
                    : extractData.status === "DRAFT"
                      ? "Create"
                      : "Update"
                : "Create"}
            </Button>
          </div>
        </Tooltip>}
      </div>
    </Box>
  );
}
