import Modal from 'react-modal';
import { connect } from "react-redux";
import { logout } from "../../api/auth/action";
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import { Button, Grid } from '@mui/material';

const customStyles = {
	content: {
		overflow: 'hidden',
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: "#ffff",
		width: "400px",
		height: "220px",
		padding: "0",
		zIndex: 9000000000000,
		position: "absolute"
	},
};

const LogoutModal = (props) => {
	const { modal, setModal, logout } = props;
	return (
		<Modal isOpen={modal} style={customStyles} ariaHideApp={false}>
			<div style={{ width: "100%", height: "40px", borderBottom: "1px solid #eee", padding: "10px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				<div>
					<b>Logout</b>
				</div>
				<div>
					<Button onClick={() => setModal(!modal)}>X</Button>
				</div>
			</div>
			<div style={{ width: "100%", height: "calc(100% - 40px)", position: "relative", zIndex: 90000000, backgroundColor: "#ffff", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}>
				<div style={{ display: "flex", flexDirection: "column", width: "100%", paddingLeft: "15px", height: "80%", alignItems: "center", justifyContent: "center" }}>
					<Grid style={{ padding: '4px 0 4px 0' }} item xs={12}>
						<NotListedLocationIcon style={{ color: "#0869FB", width: "40px", height: "40px", transform: "translateY(15px)" }} />
					</Grid>
					<Grid item xs={12} style={{ padding: '4px 0 4px 0', marginBottom: "15px", transform: "translateY(8px)" }}>
						<p style={{ color: "#333333" }}><span><b>Are you sure you want to logout?</b></span></p>
					</Grid>
				</div>
				<div style={{ width: "100%", borderTop: "1px solid #eee", padding: "10px", display: "flex" }}>
					<div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "flex-end" }}>
						<div style={{ paddingRight: "10px", cursor: "pointer" }} onClick={() => setModal(!modal)}>
							Cancel
						</div>
						<div>
							<Button style={{ backgroundColor: "#0869FB", color: "white", padding: "5px 20px", textAlign: "right", float: "right", borderRadius: "8px" }} onClick={logout}>Logout</Button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}

const mapDispatchToProps = dispatch => ({
	logout: () => dispatch(logout()),
})

export default (connect(null, mapDispatchToProps)(LogoutModal));
