import { CHECK_AUTH, CHECK_AUTH_FAILURE, SELECT_ACCOUNT, GET_ALL_CUSTOM_COLUMNS, UPDATE_COLUMN, CREATE_COLUMN, DELETE_COLUMN, GET_ALL_CUSTOM_DIMENSIONS, SAVE_DIMENSION, DELETE_DIMENSION,IS_SAVE_AND_UPDATE_BUTTON_CLICK } from "./type";

export const checkAuth = () => {
    return {
        type: CHECK_AUTH
    };
};

export const removeAuth = () => {
    return {
        type: CHECK_AUTH_FAILURE
    };
};

export const selectAccount = payload => {
    return {
        type: SELECT_ACCOUNT,
        payload: payload
    };
};

export const getAllCustomDimensions = (payload) => {
    return {
        type: GET_ALL_CUSTOM_DIMENSIONS,
        payload: payload
    };
};

export const saveDimension = payload => {
    return {
        type: SAVE_DIMENSION,
        payload: payload
    };
};

export const deleteDimension = payload => {
    return {
        type: DELETE_DIMENSION,
        payload: payload
    };
};

export const getAllCustomColumns = (payload) => {
    return {
        type: GET_ALL_CUSTOM_COLUMNS,
        payload: payload
    };
};

export const createColumn = payload => {
    return {
        type: CREATE_COLUMN,
        payload: payload
    };
};

export const updateColumn = payload => {
    return {
        type: UPDATE_COLUMN,
        payload: payload
    };
};

export const deleteColumn = payload => {
    return {
        type: DELETE_COLUMN,
        payload: payload
    };
};

export const buttonClickSaveAndUpdate = payload => {
    return {
        type:IS_SAVE_AND_UPDATE_BUTTON_CLICK,
        payload:payload
    }
}
