import { TextField } from "@mui/material";
import { ReactComponent as ArrowUpIcon } from "../../Assets/Icons/arrowUp.svg"
import { ReactComponent as ArrowDownIcon } from "../../Assets/Icons/arrowDown.svg"

const ToggleList = ({ value, onUp, onDown, onChange, isEditable, disabled = false }) => {
    return (
        <div className="metric-list-form" style={{ width: "95%", height: "40px", marginTop: "10px" }}>
            <TextField
                hiddenLabel
                id="Analytics"
                variant="filled"
                size="small"
                style={{ width: "100%" }}
                sx={{ '& input': { paddingBottom: "6px", type: "time" } }}
                InputProps={{
                    disableUnderline: true,
                    readOnly: !isEditable,
                    endAdornment: <div style={{ cursor: "pointer", display: "flex", flexDirection: "column", justifyContent: "space-between", height: "15px",pointerEvents:disabled ? "none" : "all"}}>
                        <ArrowUpIcon onClick={onUp} fill={"#5F6771"} />
                        <ArrowDownIcon onClick={onDown} fill={"#5F6771"} />
                    </div>
                }}
                value={value}
                onChange={(event) => { onChange(event.target.value) }}
                disabled={disabled}
            />
        </div>
    )
}

export default ToggleList;