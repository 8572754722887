import { applyMiddleware, createStore, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer, { compressTransform } from "./rootReducer";
import rootSaga from "./rootSaga";
import createSagaMiddleware from "redux-saga";

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

/* istanbul ignore next */
if (process.env.NODE_ENV === "development") {
    const { createLogger } = require("redux-logger");
    const invariant = require("redux-immutable-state-invariant").default;

    middleware.push(invariant());
    middleware.push(createLogger({ collapsed: true }));
}

const reducer = persistReducer(
    {
        key: "root",
        storage,
        whitelist: ['auth', 'dashboard', 'user', 'channel'],
        blacklist: ['blends', 'filters', 'extractor'],
        transforms: [compressTransform]
    },
    rootReducer,
);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/* istanbul ignore next */
const configStore = (initialState = {}) => {
    const store = createStore(reducer, initialState, composeEnhancer(applyMiddleware(...middleware)));

    sagaMiddleware.run(rootSaga);

    return {
        persistor: persistStore(store),
        store,
    };
};

const { store, persistor } = configStore();

global.store = store;

export { store, persistor };
