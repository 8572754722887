import React from "react";
import { BrowserRouter as Router, Switch, Route, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import LoginPage from "./components/Auth/Login";
import SignupPage from "./components/Auth/Signup";
import NoAccess from "./components/Auth/NoAccess";
import DashBoardPage from "./components/Dashboard/Dashboard";
import OnboardingPage from "./components/Onboarding/Onboarding";
import ChannelsPage from "./components/Channels/Channels";
import PersonalizedDemo from "./components/Channels/PersonalizedDemo";
import ManageDataSources from "./components/ManageDataSources/ManageDataSources";
import Profile from "./components/Profile/Profile";
import BlendDataSource from "./components/CustomizingSources/BlendDataSource/BlendDataSource";
import CustomizingSources from "./components/CustomizingSources/Customizing";
import SuperMetrics from "./components/SuperMetrics/SuperMetrics";
import Faq from "./components/Profile/Faq";
import PrivacyPolicy from "./components/Profile/PrivacyPolicy";
import ResetPasswordModal from "./components/Modal/ResetPasswordModal";
import ShopifyConnectPage from "./components/Profile/ShopifyConnectPage";
import TermsAndConditions from "./components/Profile/TermsAndConditions";
import SelectOrganization from "./components/Auth/SelectOrganization";
import Landing from "./components/Landing/landing";
import PrivacyPolicy2 from "./components/Profile/PrivacyPolicy2";
import ForgotPasswordPage from "./components/Auth/ForgotPassword";
import GoogleSignIn from "./components/Auth/GoogleSignIn";
import ReportManagement from "./components/ReportManagement/ReportManagement";
import DataBlending from "./components/CustomizingSources/BlendDataSource/DataBlending";
import CustomColumn from "./components/CustomizingSources/CustomizeColumns/CustomColumn";
import BulkEditing from "./components/SuperMetrics/BulkEditing/BulkEditing";
import ShareReport from "./components/Dashboard/ShareReport";

function RouterContainer({ isAuthenticated, isOnboarded, isAccountConnected, renderSelectChannel, authenticatedAccounts, user }) {
    const commonRoutes = [
        // <Route path="/faq" component={Faq} />,
        <Route path="/report/:shareReportId" component={ShareReport} />,
        <Route path="/privacy-policy" component={PrivacyPolicy} />,
        <Route path="/terms-and-conditions" component={TermsAndConditions} />,
        <Route path="/connect-shopify-account" component={ShopifyConnectPage} />,
        <Route path="/logout" component={NoAccess} />,
    ];
    const routes = isAuthenticated ? (
        <Switch>
            <Route path="/dashboard/:channel" component={DashBoardPage} />
            <Route path="/dashboard" component={DashBoardPage} />
            <Route path="/report-management" component={ReportManagement} />
            {/* <Route path="/selectchannels" component={ChannelsPage} /> */}
            <Route path="/personalized-demo" component={PersonalizedDemo} />
            <Route path="/manageSources" component={ManageDataSources} />
            <Route path="/profile" component={Profile} />
            {/* <Route path="/blend-data-sources" component={BlendDataSource} /> */}
            {/* <Route path="/customizing-sources" component={CustomizingSources} /> */}
            <Route path="/data-blending" component={DataBlending} />
            <Route path="/custom-column" component={CustomColumn} />
            {/* <Route path="/extractor" component={SuperMetrics} exact /> */}
            {/* <Route path="/extractor/bulk-editing" component={BulkEditing} /> */}
            <Route path="/organizations" component={SelectOrganization} />
            {!isOnboarded && <Route path="/onboarding" component={OnboardingPage} />}
            {commonRoutes}
            <Redirect to={(isOnboarded || renderSelectChannel)
                ? (user?.isExternalEditor === true && user.role !== "viewer"
                    ? "/organizations"
                    : (isAccountConnected || authenticatedAccounts.length) ? "/report-management" : "/managesources")
                : "/onboarding"} />
        </Switch>
    ) : (
        <Switch>
            <Route path="/" exact component={Landing} />
            <Route path="/login" exact component={LoginPage} />
            <Route path="/createaccount/:invite_token?" exact component={SignupPage} />
            <Route path="/forgotpassword" exact component={ForgotPasswordPage} />
            <Route path="/signedin" exact component={GoogleSignIn} />
            <Route path="/reset-password/:reset_token" exact component={ResetPasswordModal} />
            {commonRoutes}
            <Route path="/privacy-policy2" exact component={PrivacyPolicy2} />
            <Redirect to="/" />
        </Switch>
    );
    return <Router>{routes}</Router>;
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    isOnboarded: state.auth.isOnboarded,
    isAccountConnected: state.auth.isAccountConnected,
    renderSelectChannel: state.auth.renderSelectChannel,
    authenticatedAccounts: state.channel.authenticatedAccounts,
    user: state.user.user
});

export default withRouter(connect(mapStateToProps)(RouterContainer))
