import { useEffect } from "react";
import Grid from '@mui/material/Grid';
import { useState } from "react";
import { Button, Checkbox, ListItem, Stack, TextField, Typography } from "@mui/material";
import { getOrganizationList } from "../../api/super-admin";
import DataList from "../CustomizingSources/BlendDataSource/DataList";
import Scrollbars from "react-custom-scrollbars";
import { AllIcons } from "../../Assets/ChannelIcons/allIcons";
import { ReactComponent as EditIcon } from "../../Assets/Icons/edit.svg";
import { getReportData, getReportsList } from "../../api/report/saga";
import { useSelector } from "react-redux";
import { createReport, duplicateReport, getReport } from "../../api/report/action";
import { useDispatch } from "react-redux";
import { Report } from "../../models/report";
import CustomReportIcon from "../../Assets/ChannelIcons/custom_report.svg";
import { updateLoader } from "../../api/user/action";
import SuccessModal from "../Modal/SuccessModal";
import FailureModal from "../Modal/FailureModal";
import { ReactComponent as PipeIcon } from "../../Assets/Images/pipe.svg";
import MultipleSelectCheckList from "../Common/MultipleSelectCheckList";

const MigrateReport = ({ user }) => {
    const [organizations, setOrganizations] = useState([]);
    const [sourceOrg, setSourceOrg] = useState(null);
    const [destOrg, setDestOrg] = useState(null);
    const [allReports, setAllReports] = useState([])
    const [sourceReport, setSourceReport] = useState(null)
    const [destReport, setDestReport] = useState(null);
    const [iconIndex, setIconIndex] = useState(0);
    const [selectedTabs, setSelectedTabs] = useState([])
    const [destTabs, setDestTabs] = useState([])
    const [error, setError] = useState(false);
    const [completion, setCompletion] = useState({ message: "none", operation: "none" })

    const currReports = useSelector(state => state.report.allReports);
    const currReportsData = useSelector(state => state.report.reports);

    const dispatch = useDispatch()
    const _createReport = (_) => dispatch(createReport(_))
    const _duplicateReport = (_) => dispatch(duplicateReport(_))

    useEffect(() => {
        if (destReport?.id) {
            if (currReportsData[destReport.id]) {
                setDestTabs(currReportsData[destReport.id].tabs)
            } else {
                dispatch(getReport({ id: destReport.id, update: (report) => { setDestTabs(report.tabs); } }))
            }
        }
        if (!destReport || !destReport.report_name) {
            setError("Report is required!")
        } else if (sourceReport?.tabs?.length === selectedTabs.length && currReports.find(report => report.report_name === destReport.report_name)) {
            setError("This Report name is already in use. Please change to proceed.")
        } else {
            setError("")
        }
    }, [destReport, selectedTabs])

    useEffect(() => {
        for (let tab of selectedTabs) {
            let tabName = (tab.newName || tab.name).toLowerCase().replaceAll(" ", "-");
            if (
                destTabs.map(tab => tab.path).includes(tabName) ||
                selectedTabs.filter(t => t.id !== tab.id).map(tab => (tab.path)).includes(tabName)
            ) {
                tab.error = true;
                setError("Tab name cannot be same, Please click on edit to change.")
            } else {
                tab.error = false;
            }
        }
    }, [destTabs, selectedTabs])

    const selectAllTabs = (event) => {
        setSelectedTabs(event.target?.checked ? [...sourceReport.tabs] : [])
    };

    const selectTab = (tab, event) => {
        if (event.target.checked) {
            setSelectedTabs([...selectedTabs, tab])
        } else {
            setSelectedTabs(selectedTabs.filter(t => t.name !== tab.name))
        }
    };

    const getSelectedReportData = (report) => {
        getReportData({ id: report?.id }).then(report => {
            setSourceReport({ ...report, name: report.report_name });
        }).catch(err => {
            console.log("🚀 ~ file: MigrateReport.js ~ line 66 ~ getReportData ~ err", err)
        });

    }
    const updateSourceReport = (report) => {
        setSourceReport(report)
        getSelectedReportData(report)
    }

    useEffect(() => {
        setSelectedTabs([])
    }, [sourceReport])

    const getAllReportsList = (org) => {
        getReportsList({ id: org.id }).then(reports => {
            setAllReports(reports);
        });
    }

    const updateSourceOrg = (org) => {
        setSourceOrg(org)
        getAllReportsList(org)
        setSourceReport(null)
    }

    const getAllOrganizationsList = () => {
        dispatch(updateLoader(true));
        getOrganizationList().then(_organizations => {
            setOrganizations(_organizations);
            // .filter(org => org.id !== user.org?.id)
            if (user?.isExternalEditor === true) {
                setDestOrg(_organizations.find(org => org.id === user.org?.id))
                if (user.role !== "externaleditor") {
                    updateSourceOrg(_organizations.find(org => org.id === user.org?.id))
                }
            } else {
                updateSourceOrg(_organizations?.[0])
                setDestOrg(_organizations?.[0])
            }
        }).finally(() => {
            dispatch(updateLoader(false));
        });
    }
    useEffect(() => {
        getAllOrganizationsList();
    }, []);

    const migrateReport = () => {
        dispatch(updateLoader(true));
        if (sourceReport?.tabs?.length === selectedTabs.length) {
            const newReport = sourceReport;
            newReport.report_name = destReport.report_name;
            newReport.report_icon = AllIcons[iconIndex]
            _createReport({
                report: Report.duplicate(newReport),
                loading: (msg) => {
                    dispatch(updateLoader(false));
                    setCompletion({ message: msg, operation: "Migration" });
                },
                sourceOrg: sourceOrg
            })
        } else {
            const newReport = Report.fromJSON(sourceReport);
            _duplicateReport({
                report: newReport, destReport, tabs: selectedTabs, destTabs,
                loading: (msg) => {
                    dispatch(updateLoader(false));
                    setCompletion({ message: msg, operation: "Migration" });
                },
                sourceOrg: sourceOrg
            })
        }

    }

    return (
        <div className="main_container">
            <Grid container xs={12} style={{ minHeight: "calc(100vh - 230px)" }} justifyContent="center" alignItems="center">
                {/*Manage Team*/}
                <Grid className="gridItem" container item flexDirection="column" xs={3.5} style={{ height: "100%" }}>
                    <h3 className="h16">Source</h3>
                    <hr style={{ border: "1px solid #EAEAEC", margin: "0px -20px 15px" }} />

                    {/* source type */}
                    <div style={{ marginBottom: "15px" }}>
                        <div style={{ margin: "5px 0px" }}>
                            <h4 className="inter h14" style={{ margin: "auto 0px" }}>
                                Choose your source
                            </h4>
                        </div>
                        <DataList
                            metric={sourceOrg}
                            metrics={organizations}
                            isEditable={user.role === "externaleditor"}
                            onClick={updateSourceOrg}
                            defaultTitle="Select Source"
                        />
                    </div>

                    {/* report type */}
                    <div style={{ marginBottom: "15px" }}>
                        <div style={{ margin: "5px 0px", }}>
                            <h4 className="inter h14" style={{ margin: "auto 0px" }}>
                                Report Type
                            </h4>
                        </div>
                        <DataList
                            metric={sourceReport}
                            metrics={allReports.map(report => ({ ...report, name: report.report_name, icon: report.report_icon ?? CustomReportIcon }))}
                            isEditable={true}
                            onClick={updateSourceReport}
                            defaultTitle="Select Report"
                        />
                    </div>

                    {/* tab list */}
                    {sourceReport?.tabs &&
                        <div style={{ marginBottom: "25px", height: "auto", width: "100%" }}>
                            <div style={{ margin: "5px 0px", }}>
                                <h4 className="inter h14" style={{ margin: "auto 0px" }}>
                                    List of Tabs
                                </h4>
                            </div>
                            <MultipleSelectCheckList
                                optionsList={sourceReport.tabs}
                                value={selectedTabs}
                                onChange={(tabsObj) =>
                                    setSelectedTabs(tabsObj)
                                }
                                defaultTitle="Choose Tabs"
                                onDelete={true}
                                enableSelectAll={true}
                            />
                            {/* <Scrollbars autoHide style={{ height: "80%", marginTop: "5px" }}>
                                <Stack spacing={0}>
                                    {sourceReport.tabs.map((tab, index) =>
                                        <ListItem key={index} className="h12" style={{ padding: 0 }}>
                                            <Checkbox size="small" checked={selectedTabs.findIndex(t => t.name === tab.name) !== -1} onChange={e => selectTab(tab, e)} />
                                            {tab.name}
                                        </ListItem>
                                    )}
                                </Stack>
                            </Scrollbars>
                            <hr />
                            <Typography className="h12">
                                <Checkbox size="small" onChange={selectAllTabs} checked={sourceReport.tabs?.length === selectedTabs.length} /> Select All tabs
                            </Typography> */}
                        </div>
                    }
                </Grid>

                {(sourceReport && selectedTabs.length) ? <>
                    <PipeIcon />
                    <Grid className="gridItem" container item xs={3.5} flexDirection={"column"} style={{ marginTop: "12px", }}>
                        <h3 className="h16">Destination</h3>
                        <hr style={{ border: "1px solid #EAEAEC", margin: "0px -20px 15px" }} />
                        {/* source type */}
                        <div style={{ marginBottom: "15px" }}>
                            <div style={{ margin: "5px 0px" }}>
                                <h4 className="inter h14" style={{ margin: "auto 0px" }}>
                                    Choose your destination
                                </h4>
                            </div>
                            <DataList
                                metric={destOrg}
                                metrics={organizations}
                                isEditable={false}
                                onClick={setDestOrg}
                                defaultTitle="Select Destination"
                            />
                        </div>

                        {sourceReport?.tabs?.length === selectedTabs.length ?
                            <>
                                {/* report icon */}
                                < div style={{ marginBottom: "15px" }}>
                                    <div style={{ margin: "5px 0px", }}>
                                        <h4 className="inter h14" style={{ margin: "auto 0px" }}>
                                            Choose Icon
                                        </h4>
                                    </div>
                                    {
                                        AllIcons.map((icon, index) => {
                                            return (
                                                <span onClick={() => { setIconIndex(index) }} >
                                                    <img key={index} style={{ height: "35px", width: "35px", padding: "5px", border: iconIndex === index ? "2px solid blue" : "0px", borderRadius: "30px" }} src={icon} alt={"abc"} />
                                                </span>
                                            )
                                        })
                                    }
                                </div>

                                {/* report name */}
                                < div style={{ marginBottom: "15px" }}>
                                    <div style={{ margin: "5px 0px", }}>
                                        <h4 className="inter h14" style={{ margin: "auto 0px" }}>
                                            Report Name
                                        </h4>
                                    </div>
                                    <TextField
                                        required
                                        placeholder="Type Report Name"
                                        style={{ width: "95%" }}
                                        id="outlined-basic"
                                        value={destReport?.report_name}
                                        error={error}
                                        onChange={e => setDestReport({ report_name: e.target.value })}
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0, borderRadius: "8px", border: "1px solid #DDDDDD" }
                                        }} />
                                </div>
                            </> :
                            <>
                                {/* report type */}
                                <div style={{ marginBottom: "15px" }}>
                                    <div style={{ margin: "5px 0px", }}>
                                        <h4 className="inter h14" style={{ margin: "auto 0px" }}>
                                            Report Type
                                        </h4>
                                    </div>
                                    <DataList
                                        metric={destReport}
                                        metrics={currReports.map(report => ({ ...report, name: report.report_name, icon: report.report_icon ?? CustomReportIcon }))}
                                        isEditable={true}
                                        onClick={setDestReport}
                                        defaultTitle="Select Destination"
                                    />
                                </div>
                                {/* Migrated tabs */}
                                <div style={{ marginBottom: "15px" }}>
                                    <div style={{ margin: "5px 0px 8px" }}>
                                        <h4 className="inter h14" style={{ margin: "auto 0px" }}>
                                            Migrated Tab
                                        </h4>
                                    </div>
                                    <div style={{ display: "flex", flexWrap: "wrap", padding: "6px", width: "95%", border: "1px solid #DDDDDD", borderRadius: "8px" }}>
                                        {
                                            selectedTabs.map((tab, tabIndex) => {
                                                return <div className="highlight3" style={{ width: "46%", height: "26px", margin: "5px 2%", padding: 4, display: "flex", border: tab.error ? "1px solid red" : "" }}>
                                                    {/* {tab.newName || tab.name} */}
                                                    <TextField
                                                        sx={{ marginLeft: "5px", width: "80%", '& input': { fontSize: "12px", padding: 0 } }}
                                                        variant="standard"
                                                        placeholder="Tab name"
                                                        defaultValue={tab.newName || tab.name}
                                                        error={tab.error}
                                                        required
                                                        onChange={e => {
                                                            tab.newName = e.target.value
                                                            tab.error = false;
                                                            selectedTabs[tabIndex] = tab;
                                                            setSelectedTabs([...selectedTabs])
                                                        }}
                                                        onKeyDown={(event) => {
                                                            if (event.key === "Enter") {
                                                                selectedTabs[tabIndex].edit = false;
                                                                setSelectedTabs([...selectedTabs])
                                                            }
                                                        }}
                                                        InputProps={{ disableUnderline: true, readOnly: !tab.edit }}
                                                        inputRef={(input) => {
                                                            if (input != null && tab.edit) {
                                                                input.focus();
                                                            }
                                                        }}
                                                    />
                                                    <EditIcon
                                                        style={{ cursor: "pointer", margin: "auto", width: "16px", height: "12px" }}
                                                        onClick={() => {
                                                            const tabs = selectedTabs.map(selectTab => ({ ...selectTab, edit: (selectTab.id === tab.id) ? true : false }))
                                                            setSelectedTabs([...tabs])
                                                        }}
                                                    />
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </>
                        }
                        {
                            error && <h4 style={{
                                color: "red",
                                textAlign: "right",
                                borderRadius: "10px",
                                width: "100%",
                                fontSize: "12px",
                                marginTop: "15px"
                            }}><b>{error}</b>
                            </h4>
                        }
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button disabled={error} onClick={() => { migrateReport() }} variant="contained"
                                style={{ padding: "8px 30px", borderRadius: "8px" }} sx={{ backgroundColor: "#0869FB", }}
                            >
                                Migrate
                            </Button>
                        </div>
                    </Grid >
                </> : null}

                <SuccessModal
                    modal={completion.message === "Success"}
                    setModal={() => setCompletion({ message: "none", operation: "none" })}
                    message={`${completion.operation} of ${sourceReport?.tabs?.length === selectedTabs.length ? "Reports" : "Tabs"} is Successful`}
                />

                <FailureModal
                    modal={completion.message === "Fail"}
                    setModal={() => setCompletion({ message: "none", operation: "none" })}
                    message={`${completion.operation} of ${sourceReport?.tabs?.length === selectedTabs.length ? "Reports" : "Tabs"} Failed`}
                />
            </Grid >
        </div >
    )
}

export default MigrateReport;
