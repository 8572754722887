import { Box, Button, IconButton, InputAdornment, MenuItem, Popover, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ReactComponent as Clear } from "../../Assets/Icons/close-no-bg.svg";
import { ReactComponent as SearchIcon } from "../../Assets/Icons/searchicon.svg";

export default function SearchToggle({ filterText, setFilterText, filterKey, setFilterKey, filteredData, setFilteredData, filterDataOptions, keys = ["name"], }) {

    const [searchText, setSearchText] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);

    const filterData = (value = "") => {
        return value.toLowerCase().includes(searchText.toLowerCase())
    }
    // const [searchActive, setSearchActive] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // const filterOptions = createFilterOptions({
    //     matchFrom: "any",
    //     stringify: (option) => (option.name ?? option.title) + option.subtitle,
    // });
    useEffect(() => {
        if (setFilteredData && filteredData) {
            setFilteredData(filteredData.filter(data => keys.some(key => filterData(data?.[key] ?? ""))))
        }
    }, [searchText, filteredData])
    // console.log("Blends",filteredData,filterData)


    const open = Boolean(anchorEl);

    return (
        <>
            {<Tooltip PopperProps={{
                sx: {
                    "& .MuiTooltip-arrow": { color: "white" },
                    "& .MuiTooltip-tooltip": {
                        backgroundColor: "#FFF",
                        color: "#",
                        borderRadius: "8px",
                        filter: "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))",
                        padding: "15px",
                    },
                },
            }} title={((filterText || searchText) && filterKey) ?

                <Box sx={{
                    background: "#FFF",
                    color: "#000000"
                }}>
                    <Typography style={{ fontFamily: "Inter !important", fontWeight: "700", fontSize: "14px", lineHeight: "20px" }}>Applied Filter</Typography>
                    <Typography noWrap style={{ fontFamily: "Inter !important", fontWeight: "500", fontSize: "12px", lineHeight: "20px" }}>{filterKey} <span style={{ color: "#5C6674" }}>contains</span> <span style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", width: "70px" }}>{filterText}</span></Typography>

                </Box> : ""
            } placement="bottom" arrow>

                <Button
                    variant="outlined"
                    size="small"
                    onClick={handleClick}
                    sx={{
                        padding: "0px", margin: "0px",
                        maxWidth: "32px", height: "32px", minWidth: "32px",
                        borderColor: "#ececee",
                        position: "relative"
                    }}
                >
                    <SearchIcon />
                    {((filterText || searchText) && filterKey) && <div style={{ background: "#0869FB", position: "absolute", borderRadius: "50%", height: "8px", width: "8px", top: "-3px", right: "-3px" }}></div>}
                </Button>
            </Tooltip>


            }

            <Popover
                sx={{
                    "& .MuiPopover-paper": {
                        maxWidth: "242px",
                        padding: "12px",
                        // left: "700px !important"
                    }
                }}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: 'right'
                }}

            >
                <TextField
                    type="search"
                    placeholder="Search"
                    value={filterText ?? searchText}
                    onChange={event => {
                        setFilterText ? setFilterText(event.target.value) : setSearchText(event.target.value); if (!event.target.value) {
                            setFilterKey("")
                        }
                    }}
                    inputProps={{ style: { height: "32px", padding: "0px 16px" } }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => {
                                    setFilterText ? setFilterText("") : setSearchText("");
                                    setFilterKey("")
                                }}>
                                    <Clear />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        width: "100%",
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0, borderRadius: "6px", border: "1px solid #EAEAEC", },
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #EAEAEC",
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                            border: "1px solid #EAEAEC",
                        },
                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                            border: "1px solid #EAEAEC",
                        },
                    }}
                />

                {filterDataOptions?.map((option) =>

                    <MenuItem onClick={() => { setFilterKey(option?.name+ (option?.audiences?.[0] ? ` (${option?.audiences?.[0]?.id})` : "")); handleClose() }} selected={filterKey === option?.name} sx={{ "& .Mui-selected": { background: "blue" } }}>
                        <Typography noWrap style={{ fontFamily: "Inter !important", fontWeight: "400", fontSize: "14px", lineHeight: "22px", }}>
                            {option?.name} <span style={{ color: "#AEB6C4" }}>contains</span> <span style={{ color: "#0869FB", }}>
                                {filterText ?? searchText}</span>
                        </Typography>
                    </MenuItem>
                )
                }



            </Popover>

        </>

    )
}