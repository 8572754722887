import { useState, useEffect } from "react";
import Modal from 'react-modal';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button, Grid, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { removeInvitedUserRoleApi } from "../../api/user/saga";

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: "#ffff",
		width: "400px",
		padding: "0",
		zIndex: 9000000000000,
		position: "absolute"
	},
};

const RemoveInvitedUserModal = (props) => {
	const { modal, setModal, userRemoveData, getOrganizationMember, getInvitedOrgainzationMember } = props;
	const [isError, setError] = useState(false);
	const [errorMessageOrSuccess, setErrorMessageOrSuccess] = useState("");
	const [isUserSelectedBox, setUserSelectedBox] = useState(false);

	useEffect(() => {
		setError(false);
		setErrorMessageOrSuccess("");
		setUserSelectedBox(false);
	}, [modal]);

	const removeInvitedUser = () => {
		if (isUserSelectedBox) {
			removeInvitedUserRoleApi(userRemoveData).then((data) => {
				setError(false);
				setErrorMessageOrSuccess(data.message);
				getOrganizationMember();
				getInvitedOrgainzationMember();
				setTimeout(() => {
					setModal(!modal);
				}, 2000);
			}).catch((err) => {
				setError(true);
				setErrorMessageOrSuccess(err.data.message);
			});
		}
	}

	return (
		<Modal isOpen={modal} style={customStyles}>
			<div style={{ width: "100%", height: "40px", borderBottom: "1px solid #eee", padding: "10px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				<div>
					<b>Remove member</b>
				</div>
				<div>
					<IconButton
						onClick={() => setModal(!modal)}
						aria-label="save"
						sx={{
							borderRadius: '4px',
							padding: '2px',
							backgroundColor: '#F2F2F2',
							fontSize: '18px',

						}}
						size="small"
					>
						<Close />
					</IconButton>
				</div>
			</div>
			<div style={{ width: "100%", height: "calc(100% - 40px)", position: "relative", zIndex: 90000000, backgroundColor: "#ffff", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}>
				<div style={{ display: "flex", flexDirection: "column", width: "100%", paddingLeft: "15px" }}>
					<Grid style={{ padding: '4px 0 4px 0' }} item xs={12}>
						<p>
							Are you sure you want to remove this person from the invited member list?
						</p>
						<p style={{ paddingLeft: "9px" }}>
							<FormControlLabel
								value="end"
								control={<Checkbox />}
								label="Check this box to confirm the member removal?"
								labelPlacement="end"
								sx={{ "& span:last-child": { fontSize: 15, } }}
								onClick={(e) => setUserSelectedBox(e.target.checked)}
							/>
						</p>
						<p style={{ color: isError ? "red" : "green" }}>{errorMessageOrSuccess}</p>
					</Grid>
				</div>
				<div style={{ width: "100%", borderTop: "1px solid #eee", padding: "10px", display: "flex" }}>
					<div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "flex-end", marginTop: "7px" }}>
						<div style={{ paddingRight: "10px", cursor: "pointer", marginRight: '10px' }} onClick={() => setModal(!modal)}>
							Cancel
						</div>
						<div>
							<Button style={{ backgroundColor: "#0869FB", color: "white", padding: "5px 15px", textAlign: "right", float: "right", borderRadius: "10px", textTransform: "none" }} onClick={() => removeInvitedUser()}>Remove account</Button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}
export default RemoveInvitedUserModal;