import { Typography } from '@mui/material';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';


export default function TitleSubtitle({ title, subtitle, id }) {
    return (
        <div style={{ width: '100%', textAlign: 'left' }}>
            <Tooltip title={title}>
                <Typography className="inter" variant='subtitle2' style={{ fontWeight: "600", textOverflow: "ellipsis", maxWidth: "95%", whiteSpace: "nowrap", overflow: "hidden" }}>
                    {/* {title.length > 26 ? title.substring(0, 26) + "..." : title} */}
                    {title}
                </Typography>
            </Tooltip>

            {subtitle && <Tooltip title={subtitle}>
                <Typography className="inter" style={{ fontSize: 11, color: "#5C6674", lineHeight: "20px" }}>
                    {subtitle.length > 38 ? subtitle.substring(0, 38) + "..." : subtitle}
                </Typography>
            </Tooltip>}

            {id && <Tooltip title={id}>
                <Typography className="inter" style={{ fontSize: 11, color: "#5C6674", lineHeight: "20px" }}>
                    {id.length > 38 ? id.substring(0, 38) + "..." : id}
                </Typography>
            </Tooltip>}
        </div>
    );
}
