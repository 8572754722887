import { ChannelType } from "../../models/channel";

export const channelType = ChannelType.GoogleAnalytics;

function getSubFilters(filterList) {
    let subFilters = [];
    var startIndex = 0;
    for (let i = 0; i < filterList.length; i++) {
        if (filterList[i].boolOperator === "AND") {
            subFilters.push(filterList.slice(startIndex, i + 1));
            startIndex = i + 1;
        }
    }
    if (startIndex < filterList.length) { subFilters.push(filterList.slice(startIndex)); }
    return subFilters;
}

export function getAccountId(account) {
    return { viewId: account?.viewId, accountId: account?.accountId };
}

export function getFilterObject(filter) {
    if (filter.id) {
        return {
            filterGrouping: {
                filterId: filter.id
            }
        }
    }
    return {
        segments: (filter.segment ? [filter.segment.id] : []).filter(val => val),
        dimensionFilterClauses: getSubFilters(filter.dimensionFilters).map(subFilters => ({
            operator: "OR",
            filters: subFilters.map(subFilter => ({
                dimensionName: subFilter.filter.id,
                not: false,
                operator: subFilter.operator.type,
                expressions: [subFilter.value],
                caseSensitive: false,
            })),
        })),
        metricFilterClauses: getSubFilters(filter.metricFilters).map(subFilters => ({
            operator: "OR",
            filters: subFilters.map(subFilter => ({
                metricName: subFilter.filter.id,
                not: false,
                operator: subFilter.operator.type,
                comparisonValue: subFilter.value,
            })),
        })),
    }
}

export function getApiObject(metrics, dimensions, filter, table) {
    return {
        metrics: metrics,
        dimensions: dimensions,
        ...getFilterObject(filter),
    };
}

export const properties = {
    dateDimension: { id: "ga:date", name: "Date" },
    filterOperators: [
        { label: "Equal", type: "EQUALS", dataType: ["CURRENCY", "INTEGER", "TIME"] },
        { label: "Not Equal", type: "NOT_EQUALS", dataType: ["CURRENCY", "INTEGER", "TIME"] },
        { label: "Greater Than", type: "GREATER_THAN", dataType: ["CURRENCY", "INTEGER", "TIME", "PERCENT", "FLOAT"] },
        { label: "Greater Than or Equal", type: "GREATER_THAN_OR_EQUAL", dataType: ["CURRENCY", "INTEGER", "TIME", "PERCENT", "FLOAT"] },
        { label: "Less Than", type: "LESS_THAN", dataType: ["CURRENCY", "INTEGER", "TIME", "PERCENT", "FLOAT"] },
        { label: "Less Than or Equal", type: "LESS_THAN_OR_EQUAL", dataType: ["CURRENCY", "INTEGER", "TIME", "PERCENT", "FLOAT"] },
        { label: "Contains", type: "CONTAINS", dataType: "STRING" },
        { label: "Not Contains", type: "NOT_CONTAINS", dataType: "STRING" },
        { label: "In", type: "IN_LIST", dataType: "STRING" },
        { label: "Not In", type: "NOT_IN_LIST", dataType: "STRING" },
        { label: "Starts With", type: "STARTS_WITH", dataType: "STRING" },
        { label: "Ends With", type: "ENDS_WITH", dataType: "STRING" },

        // { label: "Exactly matches", type: "EXACT", dataType: "STRING" },
        // { label: "Matches regex", type: "REGEXP", dataType: "STRING" },
    ],
}
