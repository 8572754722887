import Loader from "./Loader";

export default function CustomLoader({ heading = "Waiting for data", subHeading = "It can take few minutes to pull your data" }) {
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Loader height={200} width={200} />
            <h1 className="lato bold"> {heading} </h1>
            <h3 style={{ fontFamily: "Inter", color: "#6F767E" }}> {subHeading} </h3>
        </div>
    )

}