import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box, Button, List, Radio, Tab, Tabs, Typography } from "@mui/material";
import CloseIcon from "../../Assets/Icons/close-icon.svg";
import { AdsList, AttributionList, GetChannel } from "../../models/channel";
import { TabPanel } from "./AccountModal";
import { useSelector } from "react-redux";
import { getDefaultBlendsList } from "../../api/blends/saga";
import Loader from "../Common/Loader";
import TitleSubtitle from "../ManageDataSources/TitleSubtitle";
import { handleMixPanelData, EVENTS } from "../../utils/mixpanelV2";

const style = {
  position: 'absolute',
  top: '50%',
  left: "53%",
  transform: 'translate(-50%, -50%)',
  width: "80%",
  height: "calc(100% - 60px)",
  bgcolor: "#FFF",
  borderRadius: "12px",
  boxShadow: 24,
  padding: "24px 32px"
};

const BlendTemplateModal = ({ isOpen, isClose, selectTemplate }) => {
  const [value, setValue] = useState(0);
  const [selectChannels, setSelectChannels] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [allTemplates, setAllTemplates] = useState([]);
  const [loader, setLoader] = useState(true);

  const authenticatedAccounts = useSelector(state => state.channel.authenticatedAccounts);
  const isChannelAuthenticated = channel => !!authenticatedAccounts.find(type => type === channel?.type);

  const totalAuthenticatedAds = AdsList.filter(channel => isChannelAuthenticated(channel)).length;
  const totalAuthenticatedAttributes = AttributionList.filter(channel => isChannelAuthenticated(channel)).length;

  const selectChannel = channel => {
    if (selectChannels.includes(channel.type))
      setSelectChannels(selectChannels.filter(c => c !== channel.type))
    else
      setSelectChannels(selectChannels.concat(channel.type))
  }

  const handleRadioChange = (index) => {
    setSelectedTemplate(index);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const closeModal = () => {
    isClose(false);
  };

  useEffect(() => {
    setAllTemplates(!selectChannels.length
      ? allTemplates
      : allTemplates.filter(temp => temp.dsConfigs.some(ds => selectChannels.includes(ds.dataSource)))
    )
  }, [selectChannels])

  useEffect(() => {
    getDefaultBlendsList()
      .then((data) => { setAllTemplates(data) })
      .catch((err) => { setAllTemplates([]) })
      .finally(() => { setLoader(false) })
  }, [])

  return (
    <Modal open={isOpen} onClose={isClose}>
      <Box sx={style}>
        <div style={{ display: "flex", justifyContent: "space-between", }}>
          <Typography className="h24"> Select Blend Template </Typography>
          <img className="pointer" src={CloseIcon} onClick={closeModal} alt="close" />
        </div>
        <Typography style={{ fontSize: "14px", fontWeight: "700", marginTop: "24px", }}>
          Data Source
        </Typography>

        <Tabs value={value} onChange={handleChange} sx={{ borderBottom: "1px solid #ECEEF1", }}>
          <Tab sx={{ textTransform: "none" }} label={`Ads Channels (${totalAuthenticatedAds})`} />
          <Tab sx={{ textTransform: "none" }} label={`Attribution (${totalAuthenticatedAttributes})`} />
        </Tabs>
        <TabPanel value={value} index={value}>
          <div style={{ display: "flex", flexDirection: "row", gap: "16px", marginTop: "20px" }}>
            {(!value ? AdsList : AttributionList).map((channel, index) => {
              const authenticated = isChannelAuthenticated(channel);
              return <div
                style={{
                  display: "flex", alignItems: "center", justifyContent: "center",
                  background: "#F6F7F8", height: "48px", width: "48px", position: "relative",
                  borderRadius: "8px", border: selectChannels.includes(channel.type) ? "1px solid #0869FB" : "0px",
                  opacity: authenticated ? 1 : 0.5,
                  cursor: authenticated ? "pointer" : ""
                }}
                onClick={() => authenticated && selectChannel(channel)}
              >
                <img style={{ height: 24, width: 24 }} src={channel.icon} alt={channel.title} />
              </div>
            })}
          </div>
        </TabPanel>

        <div style={{ width: "100%", marginTop: "24px", height: "calc(100% - 280px)" }}>
          <Typography style={{ fontSize: "14px", fontWeight: "700", color: "#5C6674", marginBottom: "8px", }}>
            Choose Template
          </Typography>

          {loader ? <Loader /> :
            <List className="displayRowWrap"
              sx={{ height: "calc(100% - 28px)", width: "100%", gap: "12px", }}
              component="nav" aria-labelledby="nested-list-subheader"
            >
              {allTemplates.map((template, index) => (
                <div key={index}
                  style={{
                    width: "calc(25% - 9px)", border: "1px solid #ECEEF1", borderRadius: "8px",
                    display: "flex", justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      width: "calc(100% - 42px)", padding: "12px",
                      display: "flex", flexDirection: "column", gap: "8px",
                    }}
                  >
                    <TitleSubtitle style={{ marginLeft: "15px" }} title={template.name} />
                    <Typography style={{ fontSize: "12px", fontWeight: "400", color: "#5C6674", }} >
                      Connected Source
                    </Typography>
                    <div style={{ display: "flex", flexDirection: "row", gap: "4px", }} >
                      {template.dsConfigs.map((ds, index) => {
                        const channel = GetChannel(ds.dataSource);
                        return (
                          <div style={{ height: "24px", width: "24px", padding: "0 4px", backgroundColor: "#F6F7F8", borderRadius: "4px" }} >
                            <img style={{ height: 16, width: 16 }} src={channel.icon} alt={channel.title} />
                          </div>)
                      })}
                    </div>
                  </div>
                  <div>
                    <Radio
                      checked={selectedTemplate === index}
                      onChange={() => handleRadioChange(index)}
                      color="primary"
                    />
                  </div>
                </div>
              ))}
            </List>
          }
        </div>

        <div style={{ height: "50px", display: "flex", justifyContent: "flex-end", marginTop: "20px", }} >
          <Button
            variant="contained" sx={{ height: "40px" }}
            disabled={selectedTemplate === null}
            onClick={() => { handleMixPanelData(EVENTS.template_default_blend, allTemplates[selectedTemplate]); selectTemplate(allTemplates[selectedTemplate]) }}
          >
            Select & Continue
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default BlendTemplateModal;
