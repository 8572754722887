import { useEffect, useState } from "react";
import { Grid, Button, Tooltip, Box, Typography } from "@mui/material";
import { GetChannel, SupportedChannels } from "../../../models/channel";
import DataList from "../BlendDataSource/DataList";
import { useSelector } from "react-redux";
import CreateCustomCol from "./CreateCustomCol";
import { ReactComponent as PipeIcon } from "../../../Assets/Images/pipe.svg";
import InfoIcon from '@mui/icons-material/Info';
import { BlendsAllAccountsSelected } from "../../EditWidget/EditChart";
import { dimensionFunctions } from "../Utils";
import CreateCustomDim from "./CreateCustomDim";
import { capitalizeString } from "../../CustomizingSources/Utils";
import { getBlendColumns } from "../../../api/blends/saga";
import { useDispatch } from "react-redux";
import { getAccountBasedColumns } from "../../../api/channel/saga";
import CloseIcon from '@mui/icons-material/Close';
import CustomLoader from "../../Common/CustomLoader";
import InputSelect from "../../Common/InputSelect";

export default function SelectSource({ updateId, columnData, customType, setColumnData, resetData, setCompletion }) {
    const dispatch = useDispatch();
    const blends = useSelector(state => state.blends.blends)
    const selectedAccounts = useSelector(state => state.channel.selectedAccounts);
    const authenticatedAccounts = useSelector(state => state.channel.authenticatedAccounts);
    const isChannelAuthenticated = channel => !!authenticatedAccounts.find(type => type === channel?.type);
    const isChannelAccountSelected = channel => {
        if (channel.isBlend) {
            return BlendsAllAccountsSelected(channel, selectedAccounts)
        } else {
            return channel.type in selectedAccounts
        }
    };

    const [channelCols, setChannelCols] = useState({ metrics: [], dimensions: [] });
    const blendChannelData = columnData?.source && blends.find(blend => blend.id === columnData.source)
    const [channel, setChannel] = useState(GetChannel(blendChannelData ?? columnData?.source));
    const [table, setTable] = useState(columnData?.other?.table || columnData.table);
    const [formulaType, setFormulaType] = useState("")

    const blendsState = useSelector(state => state.blends);
    const state = useSelector(state => channel?.getState(state));
    const tables = state?.tables;
    const dataLevels = (state?.dataLevels?.[table?.id] ?? []).map(level => ({ id: level, name: level }));

    const accountList = (state?.accountData?.accountList ?? []).map((acc, index) => ({ id: index, name: acc.title, ...acc }));
    const [account, setAccount] = useState(columnData?.audiences?.[0]?.metaData);
    const [accountCols, setAccountCols] = useState(null);

    const [displayPopup, setDisplayPopup] = useState(updateId ? true : false);
    const [loading, setLoading] = useState(false);

    const [initialChannelUpdate, setInitialChannelUpdate] = useState(true);
    const [initialTableUpdate, setInitialTableUpdate] = useState(true);

    const [dataLevel, setDataLevel] = useState(columnData?.other?.dataLevel ? { id: columnData?.other?.dataLevel, name: capitalizeString(columnData?.other?.dataLevel.split("_").join(" ")) } : null);

    useEffect(() => {
        if (!channel?.isBlend && account) {
            setLoading(true);
            getAccountBasedColumns(account, channel).then(data => {
                let columns = data?.columns ?? [];
                setAccountCols({
                    metrics: columns.filter(c => c.type === "METRIC"),
                    dimensions: columns.filter(c => c.type === "DIMENSION")
                });
            }).catch(err => {
                console.log("🚀 ~ file: EditChart.js:67 ~ getAccountBasedColumns ~ err:", err)
                setAccountCols({ metrics: [], dimensions: [] })
            }).finally(() => {
                setLoading(false);
            });

        } else {
            setAccountCols(null);
        }
        setColumnData({
            ...columnData,
            audiences: account ? [{ type: "AD_ACCOUNT", id: account.id, dataSource: channel.type, metaData: account }] : undefined
        });
    }, [account]);

    const updateBlendCols = () => {
        if (channel && channel.isBlend) {
            setLoading(true);
            getBlendColumns(channel.type).then(data => {
                let columns = (data?.columns ?? []).map(column => {
                    let channel = GetChannel(column.dataSource)
                    return ({
                        ...column,
                        name: (column.uiName ?? column.name) + (channel ? ` (${channel.shortTitle}, ${column.audiences?.[0]?.id})` : ""),
                    })
                });

                setChannelCols({
                    dimensions: columns.filter(c => c.type === "DIMENSION"),
                    metrics: columns.filter(c => c.type === "METRIC").concat(blendsState.blendCustomCols[channel.type] ?? [])
                })
            }).catch(err => {
                setChannelCols({ metrics: [], dimensions: [] })
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    useEffect(() => {
        if (initialChannelUpdate) {
            setInitialChannelUpdate(false);
        } else {
            setAccount(null);
            setTable(null)
            setDisplayPopup(false)
            setColumnData({
                ...columnData, source: channel.type, name: "",
                formula: customType !== "Dimension" ? "=" : `=${formulaType.toUpperCase()}(`,
                other: {
                    is_blended_datasource: channel.isBlend, formula_parts: [],
                    blendAudiences: channel.isBlend
                        ? state.audiences?.map((audience) => ({ id: audience?.[0]?.id, name: audience?.[0]?.metaData?.title }))
                        : undefined
                }
            })
        }
        updateBlendCols();
    }, [channel])

    useEffect(() => {
        if (initialTableUpdate) {
            setInitialTableUpdate(false);
        } else {
            setDisplayPopup(false)
            setColumnData({
                ...columnData, table, other: { ...columnData.other, table, formula_parts: [] }, name: "",
                formula: customType !== "Dimension" ? "=" : `=${formulaType.toUpperCase()}(`
            })
        }
        // if (table && state?.tablesUpdated && !state?.tablesUpdated?.[table.id]) {
        //     dispatch(channel.actions?.getColumns(table));
        // }
    }, [table])

    useEffect(() => {
        if (customType === "Dimension" && formulaType) {
            setDisplayPopup(false)
            let formula = `=${formulaType.toUpperCase()}(`;
            setColumnData({ ...columnData, formula, other: { ...columnData.other, formula_parts: [] } });
        }
    }, [formulaType])

    useEffect(() => {
        if (initialChannelUpdate) {
            setInitialChannelUpdate(false);
        } else {
            setColumnData({
                ...columnData, other: { ...columnData.other, dataLevel: dataLevel?.id }
            });
        }
    }, [dataLevel]);

    return (
        <>
            <Grid container item xs={3} style={{ minHeight: "84%", width: "100%", alignItems: "center" }}>
                <div className="gridItem" style={{ height: "fit-content", width: "100%" }}>
                    <div className="displayRowSpaced" >
                        <Typography style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "600", lineHeight: "20px" }}> Select Data Source</Typography>
                        {/* <CloseIcon className="pointer" onClick={() => { resetData() }} /> */}
                    </div>
                    <hr style={{ border: "1px solid #EAEAEC", margin: "10px -20px" }} />
                    {/* source type */}
                    <div style={{ marginBottom: "24px" }}>
                        <div style={{ margin: "5px 0px", display: "flex" }}>
                            <h4 className="inter bold h14" style={{ margin: "auto 0px" }}>
                                Source
                            </h4>
                            {/* <Tooltip
                                title="You need to choose one Account ID for all connected data sources to create your own metrics. Please goto 'Manage Data Source' Page."
                                placement="right"
                            >
                                <InfoIcon style={{ color: "#999999", margin: "0px 5px", height: "20px", width: "20px" }} />
                            </Tooltip> */}
                        </div>
                        <DataList
                            channel={channel}
                            metric={channel && { id: channel?.type, name: channel?.title }}
                            metrics={SupportedChannels
                                .filter(channel => (isChannelAuthenticated(channel)))
                                .concat(blends.map(GetChannel))
                                .map(channel => ({
                                    id: channel.type, name: channel.title, icon: channel.icon, isBlend: channel.isBlend,
                                    // disabled: !isChannelAccountSelected(channel)
                                }))
                            }
                            isEditable={!updateId}
                            onClick={channelObject => setChannel(GetChannel(channelObject.isBlend ? channelObject : channelObject.id))}
                            defaultTitle="Add Source"
                        />
                    </div>
                    {/* report type */}
                    {tables && <div style={{ marginBottom: displayPopup ? "24px" : "0px" }}>
                        <div style={{ margin: "5px 0px", display: "flex" }}>
                            <h4 className="inter bold h14" style={{ margin: "auto 0px" }}>
                                Report type
                            </h4>
                        </div>
                        <DataList
                            channel={channel}
                            metric={table}
                            metrics={tables}
                            isEditable={!updateId}
                            width={200}
                            onClick={setTable}
                            defaultTitle="Add table name"
                        />
                    </div>}
                    {/* data level */}
                    {channel?.type === "tiktok-ads" && tables && <div style={{ marginBottom: "25px" }}>
                        <div style={{ margin: "5px 0px", display: "flex" }}>
                            <h4 className="inter bold h14" style={{ margin: "auto 0px" }}>
                                Data Level
                            </h4>
                        </div>
                        <DataList
                            channel={channel}
                            metric={dataLevel}
                            metrics={dataLevels}
                            isEditable={!updateId}
                            width={200}
                            onClick={setDataLevel}
                            defaultTitle="Add table name"
                        />
                    </div>}

                    {/* account type */}
                    <div style={{ marginBottom: !displayPopup ? "24px" : "0px" }}>
                        <div style={{ margin: "5px 0px", display: "flex" }}>
                            <h4 className="inter bold h14" style={{ margin: "auto 0px" }}>
                                Ad Account
                            </h4>
                        </div>
                        {channel?.isBlend ?
                            <InputSelect
                                value={state.audiences?.map((audience) => (audience?.[0]?.metaData))}
                                options={state.audiences?.map((audience) => {
                                    const channel = GetChannel(audience?.[0]?.dataSource);
                                    let metaData = audience?.[0]?.metaData ?? {};
                                    return ({
                                        id: metaData.id,
                                        name: metaData.title,
                                        subtitle: metaData.id + (metaData.subTitle ? ", " + metaData.subTitle : ""),
                                        icon: channel?.icon
                                    })
                                }) ?? []}
                                multiple={true}
                                readOnly={true}
                                title={""}
                                selectBg={"none"}
                            /> :
                            <DataList
                                channel={channel}
                                metric={account}
                                metrics={accountList}
                                isEditable={!updateId}
                                onClick={accObj => setAccount(accObj)}
                                defaultTitle="Add Ad Account"
                            />
                        }
                    </div>

                    {/* function type */}
                    {customType === "Dimension" && <div style={{ marginBottom: "25px" }}>
                        <div style={{ margin: "5px 0px", display: "flex" }}>
                            <h4 className="inter bold" style={{ margin: "auto 0px" }}>
                                Select Function
                            </h4>
                        </div>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {
                                Object.keys(dimensionFunctions).map(fn => {
                                    return <div onClick={() => dimensionFunctions[fn].active && setFormulaType(fn)}
                                        style={{
                                            margin: "5px 2%", padding: "4px 8px", borderRadius: "4px",
                                            opacity: dimensionFunctions[fn].active ? "1" : "0.5",
                                            background: fn === formulaType ? "#0869FB22" : "#F5F5F5",
                                            border: fn === formulaType ? "1px solid #0869FB88" : "1px solid #F0F0F0"
                                        }}>
                                        {fn}
                                    </div>
                                })
                            }
                        </div>
                    </div>}
                    {!displayPopup && (
                        <div
                            className="footer_para"
                            style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                // padding: "0px 16px",
                            }}
                        >
                            <Button
                                style={{ width: "100%", height: "40px", borderRadius: '8px', backgroundColor: "#0869FB", }}
                                variant="contained"
                                color="primary"
                                disabled={!(channel && (!tables || table) && (!dataLevels.length || dataLevel) && (account || channel.isBlend) && (customType !== "Dimension" || formulaType))}
                                onClick={() => { setDisplayPopup(true) }}
                                sx={{
                                    backgroundColor: "#0869FB",
                                    variant: "contained",
                                    "&:hover": {
                                        backgroundColor: "#0869FB",
                                        borderColor: "#0062cc",
                                        boxShadow: "none",
                                    },
                                    color: "#ffffff",
                                    ":disabled": { color: "#0869FB88", backgroundColor: "#EBF3FF !important" },
                                }}
                            >
                                Next
                            </Button>
                        </div>


                    )}
                </div>
            </Grid>
            {
                (displayPopup && channel && (!tables || table)) &&         //&& (account || channel.isBlend)
                <Grid container item xs={8} className="displayRowCenter" style={{ minHeight: "84%", width: "100%" }}>
                    <div className="displayRowCenter" style={{ width: "100%" }}>
                        <PipeIcon width="45px" height="25px" />
                        <div className="gridItem" style={{ width: "100%" }}>
                            {
                                loading ?
                                    <Box className="loader">
                                        <CustomLoader
                                            heading={"Waiting for account columns data"}
                                            subHeading=""
                                        />
                                    </Box> :
                                    customType === "Dimension" ?
                                        <CreateCustomDim key={formulaType} updateId={updateId} channel={channel} formulaType={formulaType} columnData={columnData} setColumnData={setColumnData} resetData={resetData} /> :
                                        <CreateCustomCol updateId={updateId} channel={channel} channelCols={channelCols} accountCols={accountCols} columnData={columnData} setColumnData={setColumnData} resetData={resetData} setCompletion={setCompletion} />
                            }
                        </div>
                    </div>
                </Grid>
            }
        </>
    )
}
