import React, { useEffect } from "react";
import ErrorIcon from "../../Assets/Images/error_icon.svg"
import LoginIcon from "../../Assets/Images/new-signup.svg";
import { connect } from "react-redux";
import { resetError, signup } from "../../api/auth/action";
import { Box, Button, Divider, Grid, InputAdornment, Link, TextField, Typography } from "@mui/material";
import MuiPhoneNumber from 'material-ui-phone-number';
import jwtDecode from "jwt-decode";
import { useParams } from "react-router";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from '@mui/material/IconButton';
import FacebookIcon from "../../Assets/Icons/facebook-icon.svg"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function SignupPage(props) {
    const { signup, resetError, signupFailureMessage } = props;
    const history = useHistory();

    let { invite_token } = useParams();
    const invitedUser = invite_token && jwtDecode(invite_token)

    const isInvited = !!invitedUser;

    const getFirstAndLastName = (name) => {
        let firstName = invitedUser.name.split(' ').slice(0, -1).join(' ');
        let lastName = invitedUser.name.split(' ').slice(-1).join(' ');
        if(firstName?.trim()?.length === 0) {
            firstName = lastName;
            lastName = "";
        }
        return { firstName, lastName }
    }

    const state = React.useRef({
        firstName: isInvited ? getFirstAndLastName(invitedUser.name).firstName : "",
        lastName: isInvited ? getFirstAndLastName(invitedUser.name).lastName : "",
        phone: "",
        company: isInvited ? invitedUser.organization_name : "",
        email: isInvited ? invitedUser.email : "",
        domain: isInvited ? invitedUser.organization_domain : "",
        password: "",
        confirmPassword: "",
    });

    const [errors, setErrors] = React.useState({
        firstName: null,
        lastName: null,
        phone: null,
        company: null,
        email: null,
        domain: null,
        password: null,
        confirmPassword: null,
        social: null
    });
    const [togglePassword1, setTogglePassword1] = React.useState(false);
    const [togglePassword2, setTogglePassword2] = React.useState(false);

    const validateData = (property, reqVal) => {
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const domainRegex = /^[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(.[a-zA-Z0-9-]+)*$/;
        const phoneRegex = /^\+[1-9]{1,3} [0-9-]{3,14}$/;
        const specialCharTest = /[+={}()|[\]:;"'<>,.?/~`]/;
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#_-]+).{8,}$/;

        const values = state.current;

        switch (property) {
            case "firstName":
                if (values.firstName.length === 0) {
                    return "First name is required";
                }
                break;
            case "lastName":
                if (values.lastName.length === 0) {
                    return "Last name is required";
                }
                break;
            case "phone":
                // if (values.phone.length === 0) {
                //     return "Phone is required";
                // }
                // else if (!phoneRegex.test(values.phone)) {
                //     return "Invalid phone";
                // }
                break;
            case "company":
                if (values.company.length === 0) {
                    return "Company is required";
                }
                break;
            case "email":
                if (values.email.length === 0) {
                    return reqVal ? "Email is required" : null;
                } else if (!emailRegex.test(values.email)) {
                    return "Invalid Email";
                } else if (!values.email.endsWith(values.domain.split(".").splice(-2).join("."))) {
                    return "Email Domain must match domain name"
                }
                break;
            case "domain":
                if (values.domain.length === 0) {
                    return reqVal ? "Domain is required" : null;
                } else if (!domainRegex.test(values.domain)) {
                    return "Invalid Domain Name";
                } else if (!values.email.endsWith(values.domain.split(".").splice(-2).join("."))) {
                    return "Domain Name must match email ID domain"
                }
                break;
            case "password":
                if (values.password.length === 0) {
                    return "Password is required";
                } else if (specialCharTest.test(values.password)) {
                    return "Entered character is not supported";
                } else if (!passwordRegex.test(values.password)) {
                    return "Invalid Password (minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character)";
                }
                break;
            case "confirmPassword":
                if (values.confirmPassword.length === 0) {
                    return "Confirm password is required";
                } else if (values.password !== values.confirmPassword) {
                    return "Passwords do not match";
                }
                break;
            default:
                return null;
        }
        return null;
    }

    const handleSignup = () => {
        const newErrors = { ...errors, social: null };
        Object.keys(state.current).forEach(key => newErrors[key] = validateData(key, true));
        setErrors(newErrors);

        const isValid = Object.values(newErrors).find(v => v !== null) === undefined;
        if (isValid) {
            signup(state.current);
        }
    }

    const handleChange = (property, value) => {
        state.current = { ...state.current, [property]: (property === "email" || property === "domain") ? value.toLowerCase().trim() : value };
        let coProperty = property === "email" ? "domain" : property === "domain" ? "email" : null;
        setErrors({ ...errors, [property]: validateData(property), [coProperty]: coProperty ? validateData(coProperty) : null, social: null });
    }

    const handleFBLogin = () => {
        window.fbLogin(function (response) {
            if (response.authResponse) {
                window.getEmail(function (user) {
                    if (user.email) {
                        history.push("/signedin", {
                            prevPath: history.location.pathname,
                            authResponse: { ...response.authResponse, email: user.email, type: "facebook" }
                        })
                    } else {
                        setErrors({ ...errors, "social": "Facebook Authentication failed." });
                    }
                })
            } else {
                setErrors({ ...errors, "social": "Facebook Login failed." });
            }
        })
    };


    const handleGoogleResponse = (response) => {
        if (response) {
            history.push("/signedin", {
                prevPath: history.location.pathname,
                authResponse: { ...response, type: "google" }
            })
        } else {
            setErrors({ ...errors, "social": "Google Login failed." });
        }
    }

    useEffect(() => {
        /* global google*/
        google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            callback: handleGoogleResponse
        });
        renderGoogleButton();
    }, [])
    const renderGoogleButton = () => {
        google.accounts.id.renderButton(
            document.getElementById("googleSignIn"),
            {
                theme: 'outline',
                text: 'signup_with',
                width: document.getElementById("parentElement")?.offsetWidth
            }
        );
    }
    useEffect(() => {
        window.addEventListener("resize", renderGoogleButton, false);
    }, [])

    return (
        <div style={{ display: "flex", flexDirection: "row", height: "100vh", width: "100%", alignItems:'center' }}>
            <div
                style={{ height: "100%", overflow: "hidden", width: "100%" }}>
                <img src={LoginIcon} style={{ height: "100%", objectFit: "cover" }} alt="" />
            </div>
            <div style={{ display: "flex",  justifyContent: "center", width: "100%", height:'90%' , overflow:'hidden'}}>
                <Box sx={{
                    padding: "32px",
                    border: "1px solid #ECEEF1",
                    borderRadius: "12px",
                    width: "464px",
                    overflowY:"auto"
                }}>
                    <div style={{ marginBottom: "16px" }}>
                        <Typography style={{ fontFamily: "Inter", fontSize: "20px", fontWeight: "600", fontStyle: "normal", lineHeight: "32px" }} >
                            Create Account
                        </Typography>
                        <Typography style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "400", fontStyle: "normal", lineHeight: "32px", color: "#5C6674" }} >
                            Set up your account to create an new organisation.
                        </Typography>
                    </div>

                        <div style={{ display: "flex", alignItems: "center", gap: "12px", marginBottom: "12px" }}>
                        <div style={{ width: "100%" }}>
                            <Typography style={{ fontFamily: "Inter", fontSize: "16px", fontWeight: "400", fontStyle: "normal", lineHeight: "24px", marginBottom: "8px" }}>
                                First Name <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <TextField
                                required
                                defaultValue={state.current.firstName}
                                error={errors.firstName !== null}
                                // helperText={errors.firstName}
                                placeholder="Enter First Name"
                                style={{ width: '100%' }}
                                onChange={_ => handleChange("firstName", _.target.value)}
                                onKeyDown={() => {
                                    if (props.signupFailure) { resetError(); }
                                }}
                                InputLabelProps={{ shrink: true, style: { color: 'black', fontFamily: 'Inter' } }}
                                InputProps={{ style: { borderRadius: '8px', height: '40px', width: "100%" } }}
                                inputProps={{ style: { padding: "16px" } }}
                                variant="outlined" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <Typography style={{ fontFamily: "Inter", fontSize: "16px", fontWeight: "400", fontStyle: "normal", lineHeight: "24px", marginBottom: "8px" }}>
                                Last Name <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <TextField
                                required
                                defaultValue={state.current.lastName}
                                error={errors.lastName !== null}
                                // helperText={errors.lastName}
                                placeholder="Enter Last Name"
                                style={{ width: '100%' }}
                                onChange={_ => handleChange("lastName", _.target.value)}
                                onKeyDown={() => {
                                    if (props.signupFailure) { resetError(); }
                                }} InputLabelProps={{ shrink: true, style: { color: 'black', fontFamily: 'Inter' } }}
                                InputProps={{ style: { borderRadius: '8px', height: "40px", width: "100%" } }}
                                inputProps={{ style: { padding: "16px" } }}
                                variant="outlined" />
                        </div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", gap: "12px", marginBottom: "12px" }}>
                        <div>
                            <Typography style={{ fontFamily: "Inter", fontSize: "16px", fontWeight: "400", fontStyle: "normal", lineHeight: "24px", marginBottom: "8px" }}>
                                Company Name <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <TextField
                                required
                                defaultValue={state.current.company}
                                error={errors.company !== null}
                                // helperText={errors.company}
                                placeholder="Enter Company Name"
                                style={{ width: '100%' }}
                                onChange={_ => handleChange("company", _.target.value)}
                                onKeyDown={() => {
                                    if (props.signupFailure) { resetError(); }
                                }} InputLabelProps={{ shrink: true, style: { color: 'black', fontFamily: 'Inter' } }}
                                InputProps={{ style: { borderRadius: '8px', height: "40px" }, readOnly: isInvited }}
                                inputProps={{ style: { padding: "12px 16px" } }}
                                variant="outlined" />
                        </div>

                        <div>
                            <Typography style={{ fontFamily: "Inter", fontSize: "16px", fontWeight: "400", fontStyle: "normal", lineHeight: "24px", marginBottom: "8px" }}>
                                Domain Name <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <TextField
                                required
                                defaultValue={state.current.domain}
                                error={errors.domain !== null}
                                // helperText={errors.domain}
                                placeholder="Enter Domain Name"
                                style={{ width: '100%' }}
                                onChange={_ => handleChange("domain", _.target.value)}
                                onKeyDown={() => {
                                    if (props.signupFailure) { resetError(); }
                                }} InputLabelProps={{ shrink: true, style: { color: 'black', fontFamily: 'Inter' } }}
                                InputProps={{ style: { borderRadius: '8px', height: "40px" }, readOnly: isInvited }}
                                inputProps={{ style: { padding: "12px 16px" } }}
                                variant="outlined" />
                        </div>
                    </div>
                    {/* <Grid style={{ padding: "4px" }} item xs={12}>
                        <MuiPhoneNumber
                            error={errors.phone !== null}
                            helperText={errors.phone}
                            variant="outlined"
                            label="Phone No."
                            placeholder="Enter Phone Number"
                            defaultCountry={'in'}
                            onChange={_ => handleChange("phone", _)}
                            onKeyDown={() => {
                                if (props.signupFailure) { resetError(); }
                            }} style={{ marginBottom: '10px', width: '100%' }}
                            InputLabelProps={{ shrink: true, style: { color: 'black', fontFamily: 'Inter' } }}
                            InputProps={{ style: { borderRadius: '8px' } }} />
                    </Grid> */}

                    <div style={{ marginBottom: "12px" }}>
                        <Typography style={{ fontFamily: "Inter", fontSize: "16px", fontWeight: "400", fontStyle: "normal", lineHeight: "24px", marginBottom: "8px" }}>
                            E-Mail ID <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextField
                            required
                            defaultValue={state.current.email}
                            error={errors.email !== null}
                            // helperText={errors.email}
                            placeholder="Enter Your E-Mail"
                            style={{ width: '100%' }}
                            onChange={_ => handleChange("email", _.target.value)}
                            onKeyDown={() => {
                                if (props.signupFailure) { resetError(); }
                            }} InputLabelProps={{ shrink: true, style: { color: 'black', fontFamily: 'Inter' } }}
                            InputProps={{ style: { borderRadius: '8px', height: "40px" }, readOnly: isInvited }}
                            inputProps={{ style: { padding: "12px 16px" } }}
                            variant="outlined" />
                    </div>

                    <div style={{ marginBottom: "12px" }}>
                        <Typography style={{ fontFamily: "Inter", fontSize: "16px", fontWeight: "400", fontStyle: "normal", lineHeight: "24px", marginBottom: "8px" }}>
                            Create Password <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextField
                            required
                            error={errors.password !== null}
                            // helperText={errors.password}
                            placeholder="Enter to Create Your Password"
                            style={{ width: '100%' }}
                            type={!togglePassword2 ? "password" : "text"}
                            onChange={_ => handleChange("password", _.target.value)}
                            onKeyDown={() => {
                                if (props.signupFailure) { resetError(); }
                            }} InputLabelProps={{ shrink: true, style: { color: 'black', fontFamily: 'Inter' } }}
                            InputProps={{
                                style: { borderRadius: '8px', height: "40px" }, endAdornment: (
                                    <InputAdornment position="end">
                                        {togglePassword2 ? <IconButton onClick={() => setTogglePassword2(!togglePassword2)}><Visibility style={{ color: "#707070" }} /></IconButton> :
                                            <IconButton onClick={() => setTogglePassword2(!togglePassword2)}><VisibilityOff style={{ color: "#707070" }} /></IconButton>}
                                    </InputAdornment>
                                )
                            }}
                            inputProps={{ style: { padding: "12px 16px" } }}
                            variant="outlined" />
                    </div>

                    <div style={{ marginBottom: "12px" }}>
                        <Typography style={{ fontFamily: "Inter", fontSize: "16px", fontWeight: "400", fontStyle: "normal", lineHeight: "24px", marginBottom: "8px" }}>
                            Confirm Password <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextField
                            required
                            error={errors.confirmPassword !== null}
                            // helperText={errors.confirmPassword}
                            placeholder="Enter to Confirm Your Password"
                            style={{ marginBottom: '10px', width: '100%' }}
                            type={!togglePassword1 ? "password" : "text"}
                            onChange={_ => handleChange("confirmPassword", _.target.value)}
                            onKeyDown={() => {
                                if (props.signupFailure) { resetError(); }
                            }}
                            InputLabelProps={{ shrink: true, style: { color: 'black', fontFamily: 'Inter' } }}
                            InputProps={{
                                style: { borderRadius: '8px', height: "40px" }, endAdornment: (
                                    <InputAdornment position="end">
                                        {togglePassword1 ? <IconButton onClick={() => setTogglePassword1(!togglePassword1)}><Visibility style={{ color: "#707070" }} /></IconButton> :
                                            <IconButton onClick={() => setTogglePassword1(!togglePassword1)}><VisibilityOff style={{ color: "#707070" }} /></IconButton>}
                                    </InputAdornment>
                                )
                            }}
                            inputProps={{ style: { padding: "12px 16px" } }}
                            variant="outlined" />
                    </div>

                    {errors &&
                        <Typography style={{
                            fontFamily: "Inter",
                            fontWeight: "400",
                            color: "#E91B16",
                            textAlign: "left",
                            borderRadius: "10px",
                            width: "100%",
                            fontSize: "14px",
                            // marginTop: "12px",
                            marginBottom: "12px"
                        }}>
                            {Object.values(errors).filter(error => error)[0]}
                        </Typography>
                    }
                    {props.signupFailure
                        ? <div style={{ height: "auto", backgroundColor: "#FF000033", borderRadius: "8px", display: "flex", alignItems: "center", padding: "8px 16px", margin: "10px 0px" }}>
                            <div style={{ display: "flex" }} >
                                <img src={ErrorIcon} alt="Error!" />
                            </div>
                            <div style={{ width: "100%", padding: "0px 10px" }} >
                                <b>Error!</b>
                                <br />{signupFailureMessage}
                            </div>
                        </div>
                        : <></>
                    }
                    <div style={{ marginTop: "8px" }}>
                        <Button onClick={() => handleSignup()} style={{ width: "100%", height: "50px", borderRadius: '8px' }} variant="contained" color="primary">
                            Sign Up
                        </Button>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "16px" }}>
                        <Typography >
                            <span style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "400", lineHeight: "24px" }}>Already have an account? </span>
                            <Link href="/login" >
                                Sign In
                            </Link>
                        </Typography>
                    </div>

                    <div style={{ marginTop: "16px", marginBottom: "16px", fontFamily: "Inter", fontSize: "14px", fontWeight: "400", color: "#5C6674" }}><Divider>or</Divider></div>

                    <div style={{ display: "flex", gap: "14px" }}>
                        <div id="parentElement" style={{ width: "50%" }} >
                            <div id="googleSignIn" style={{ width: "50%" }} />
                        </div>

                        <button onClick={() => handleFBLogin()} className="button0 button1"
                        >
                            <img src={FacebookIcon} style={{ marginRight: "8px", }} alt="" /> Sign up with Facebook
                        </button>

                    </div>
                </Box>
            </div>
        </div >
    );
}

const mapDispatchToProps = dispatch => ({
    signup: (_) => dispatch(signup(_)),
    resetError: () => dispatch(resetError()),
});

const mapStateToProps = state => ({
    signupFailure: state.auth.signupFailure,
    signupFailureMessage: state.auth.signupFailureMessage
});


export default (connect(mapStateToProps, mapDispatchToProps)(SignupPage))
