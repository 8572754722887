import React from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { connect } from "react-redux";
import { checkSocialLogin } from "../../api/auth/saga";
import { resetError, socialLogin } from "../../api/auth/action";
import jwt_decode from "jwt-decode";
import SignInIcon from "../../Assets/Images/NewLogin.svg";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import Back from "../../Assets/Icons/backicon.svg"
import { Link } from "react-router-dom";
import ErrorIcon from "../../Assets/Images/error_icon.svg"
import { useDispatch } from "react-redux";

const GoogleSignIn = (props) => {
    const { loginFailureMessage } = props;
    const authResponse = props.location.state.authResponse;
    const decoded = authResponse?.credential ? jwt_decode(authResponse.credential) : null;
    const dispatch = useDispatch();

    const [state, setState] = React.useState({
        email: decoded?.email ?? authResponse?.email,
        company: "",
        domain: ""
    });
    const [isChecking, setIsChecking] = React.useState(true);
    const [errors, setErrors] = React.useState({
        email: null,
        company: null,
        domain: null,
    });

    const validateData = (property, reqVal) => {
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const domainRegex = /^[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(.[a-zA-Z0-9-]+)*$/;

        switch (property) {
            case "company":
                if (state.company.length === 0) {
                    return "Company is required";
                }
                break;
            case "email":
                if (state.email.length === 0) {
                    return reqVal ? "Email is required" : null;
                } else if (!emailRegex.test(state.email)) {
                    return "Invalid Email";
                } else if (!state.email.endsWith(state.domain.split(".").splice(-2).join("."))) {
                    return "Email Domain must match domain name"
                }
                break;
            case "domain":
                if (state.domain.length === 0) {
                    return reqVal ? "Domain is required" : null;
                } else if (!domainRegex.test(state.domain)) {
                    return "Invalid Domain Name";
                } else if (!state.email.endsWith(state.domain.split(".").splice(-2).join("."))) {
                    return "Domain Name must match email ID domain"
                }
                break;
            default:
                return null;
        }
        return null;
    }

    const handleChange = (property, value) => {
        setState({ ...state, [property]: value });
        setErrors({ ...errors, [property]: validateData(property) });
    }

    const handleSocailLogin = (metadata = {}) => {
        const newErrors = { ...errors };
        Object.keys(metadata).forEach(key => newErrors[key] = validateData(key, true));
        setErrors(newErrors);

        const isValid = Object.values(newErrors).find(v => v !== null) === undefined;
        if (isValid && authResponse) {
            dispatch(socialLogin({
                email: decoded?.email ?? authResponse.email,
                credential: authResponse.credential ?? authResponse.accessToken,
                type: authResponse.type,
                ...metadata
            }))
        }
    }

    React.useEffect(() => {
        if (authResponse && isChecking) {
            checkSocialLogin(authResponse?.credential ?? authResponse?.accessToken, decoded?.email ?? authResponse.email, authResponse.type)
                .then((data) => {
                    if (data.user_exists) {
                        handleSocailLogin();
                    } else {
                        setIsChecking(false);
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: "LOGIN_FAILURE",
                        payload: error.response?.data?.message
                    })
                });
        }
    }, [authResponse]);

    React.useEffect(() => {
        if (props.loginFailure && isChecking) {
            window.location.href = "/login";
        }
    }, [props.loginFailure])

    return (
        <>
            {!isChecking &&

                < div style={{ display: "flex", flexDirection: "row", height: "100vh", width: "100%" }}>

                    <div style={{ height: "100%" }}>
                        <img src={SignInIcon} style={{ height: "100%" }} alt="" />
                    </div>

                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                        <Box sx={{
                            padding: "32px",
                            border: "1px solid #ECEEF1",
                            borderRadius: "12px",
                            width: "464px",
                            boxShadow: "0px 32px 48px -16px rgba(0, 0, 0, 0.10)"
                        }}>
                            <div style={{ marginBottom: "20px" }}>
                                <Link style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}
                                    to="/login"
                                >
                                    <img src={Back} alt="" /> <span style={{ fontFamily: "Inter", fontSize: "16px", fontWeight: "500", lineHeight: "20px", color: "#0869FB", marginLeft: "8px" }}>Go Back</span>
                                </Link>
                                <Typography style={{ fontFamily: "Inter", fontSize: "20px", fontWeight: "600", marginBottom: "6px" }}>Signed in with {authResponse.type}</Typography>
                                <span style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "400", lineHeight: "16px", marginBottom: "6px", color: "#5C6674" }}>Set up your account to create an new organisation.</span>
                            </div>

                            <div style={{ marginBottom: "16px" }}>
                                <Typography style={{ fontFamily: "Inter", fontSize: "16px", fontWeight: "400", fontStyle: "normal", lineHeight: "24px", marginBottom: "8px" }} >E-Mail ID <span style={{ color: "red" }}>*</span></Typography>
                                <TextField
                                    required
                                    value={decoded?.email ?? authResponse.email}
                                    style={{ width: '100%' }}
                                    id="outlined-basic"
                                    InputLabelProps={{ shrink: true, style: { fontWeight: "500", color: 'black' } }}
                                    InputProps={{
                                        style: { color: 'black' }
                                    }}
                                    size="small"
                                    variant="outlined" padding
                                    disabled
                                />
                            </div>

                            <div style={{ marginBottom: "16px" }}>
                                <Typography style={{ fontFamily: "Inter", fontSize: "16px", fontWeight: "400", fontStyle: "normal", lineHeight: "24px", marginBottom: "8px" }} >Company Name<span style={{ color: "red" }}>*</span></Typography>
                                <TextField
                                    required
                                    error={errors.company !== null}
                                    placeholder="Company Name"
                                    style={{ width: '100%' }}
                                    id="outlined-basic"
                                    size="small"
                                    inputProps={{
                                        style: { color: 'rgb(50, 50, 50)' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { color: "black", fontWeight: "500" } }}
                                    InputProps={{ style: { borderRadius: '6px' } }}
                                    variant="outlined"
                                    onChange={(e) => handleChange("company", e.target.value)}
                                    onKeyDown={(event) => {
                                        if (props.loginFailure) { dispatch(resetError()); }
                                        if (event.key === "Enter") { handleSocailLogin(state) }
                                    }}
                                />
                            </div>

                            <div style={{ marginBottom: "16px" }}>
                                <Typography style={{ fontFamily: "Inter", fontSize: "16px", fontWeight: "400", fontStyle: "normal", lineHeight: "24px", marginBottom: "8px" }} >Domain Name<span style={{ color: "red" }}>*</span></Typography>
                                <TextField
                                    required
                                    error={errors.domain !== null}
                                    placeholder="Domain Name"
                                    style={{ width: '100%' }}
                                    id="outlined-basic"
                                    size="small"
                                    InputLabelProps={{ shrink: true, style: { color: "black", fontWeight: "500" } }}
                                    InputProps={{
                                        endAdornment: <CheckCircleIcon style={{ color: "#eee" }} />, style: { borderRadius: '6px' }
                                    }}
                                    variant="outlined"
                                    onChange={(e) => handleChange("domain", e.target.value)}
                                    onKeyDown={(event) => {
                                        if (props.loginFailure) { dispatch(resetError()); }
                                        if (event.key === "Enter") { handleSocailLogin(state) }
                                    }}
                                />
                            </div>

                            {
                                errors && <Typography style={{
                                    fontFamily: "Inter",
                                    fontWeight: "400",
                                    color: "#E91B16",
                                    textAlign: "left",
                                    borderRadius: "10px",
                                    width: "100%",
                                    fontSize: "14px",
                                    // marginTop: "12px",
                                    marginBottom: "12px"
                                }}>{Object.values(errors).filter(error => error)[0]}
                                </Typography>
                            }
                            {props.loginFailure
                                ? <Grid item xs={12} style={{ backgroundColor: "#FF000033", borderRadius: "8px", display: "flex", alignItems: "center", padding: "5px 15px", margin: "10px 0px" }}>
                                    <div style={{ width: "20%", display: "flex" }} >
                                        <img src={ErrorIcon} alt="Error!" />
                                    </div>
                                    <div style={{ width: "60%" }} >
                                        <b>Error!</b>
                                        <br />{loginFailureMessage}
                                    </div>
                                </Grid>
                                : <></>
                            }
                            <div>
                                <Button
                                    style={{
                                        backgroundColor: "#0869FB",
                                        width: "100%",
                                        color: "white",
                                        padding: "10px",
                                        borderRadius: "8px"
                                    }}
                                    onClick={() => { handleSocailLogin(state) }}
                                >
                                    Get Started
                                </Button>
                            </div>
                        </Box>
                    </div>
                </div>
            }
        </>
    )
}

const mapStateToProps = state => ({
    loginFailure: state.auth.loginFailure,
    loginFailureMessage: state.auth.loginFailureMessage
});

export default (connect(mapStateToProps)(GoogleSignIn))