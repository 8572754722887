import GoogleAdsLogo from "../Assets/ChannelIcons/google_ads.svg"
import FacebookLogo from "../Assets/ChannelIcons/facebook.svg"
import LinkedInLogo from "../Assets/ChannelIcons/linkedin.svg"
import BingLogo from "../Assets/ChannelIcons/bing.svg"
import GoogleAnalyticsLogo from "../Assets/ChannelIcons/google_analytics.svg"
import LookerLogo from "../Assets/ChannelIcons/looker.png"
import SalesforceLogo from "../Assets/ChannelIcons/salesforce.png"
import ShopifyLogo from "../Assets/ChannelIcons/shopify.png"
import AdjustLogo from "../Assets/ChannelIcons/adjust.png"
import BranchLogo from "../Assets/ChannelIcons/branch.svg"
import MetadataLogo from "../Assets/ChannelIcons/metadata.svg"
import AppsflyerLogo from "../Assets/ChannelIcons/appsflyer.webp"
import DataBlendIcon from "../Assets/Icons/DataBlend.svg";
import TiktokLogo from "../Assets/ChannelIcons/tiktok.svg";
import Ga4Logo from "../Assets/ChannelIcons/google_analytics_4.svg";

class Channel {
    constructor(type, title, shortTitle, icon, subtext, isSupported = false, isBlend = false) {
        this.type = type;
        this.title = title;
        this.shortTitle = shortTitle;
        this.icon = icon;
        this.subtext = subtext;
        this.isSupported = isSupported;
        this.isBlend = isBlend;

        if (this.isSupported) {
            if (this.isBlend) {
                const transformerModule = require("../api/blends/transformer");
                this.properties = transformerModule.properties;
                this.transformer = Object.keys(transformerModule)
                    .filter(t => typeof transformerModule[t] === 'function')
                    .reduce((obj, t) => ({ ...obj, [t]: transformerModule[t] }), {});
            } else {
                const transformerModule = require(`../api/${this.type}/transformer`);
                this.properties = transformerModule.properties;
                this.transformer = Object.keys(transformerModule)
                    .filter(t => typeof transformerModule[t] === 'function')
                    .reduce((obj, t) => ({ ...obj, [t]: transformerModule[t] }), {});
                this.actions = {
                    getClientId: { type: `${this.type}.GET_CLIENT_ID` },
                    connectAccount: { type: `${this.type}.CONNECT_ACCOUNT` },
                    disconnectAccount: { type: `${this.type}.DISCONNECT_ACCOUNT` },
                    removeAccountData: { type: `${this.type}.DISCONNECT_ACCOUNT_SUCCESS` },
                    getAccountSummary: (params) => ({ type: `${this.type}.GET_ACCOUNT_SUMMARY`, payload: params }),
                    getFields: { type: `${this.type}.GET_FIELDS` },
                    getColumns: (params) => ({ type: `${this.type}.GET_COLUMNS`, payload: params }),
                    getCurrency: (account) => ({ type: `${this.type}.GET_CURRENCY`, payload: account }),
                    sendCode: (params) => ({ type: `${this.type}.SEND_CODE`, payload: params })
                };
            }
        }
    }

    loadGrid() {
        if (this.isSupported && !this.isBlend) {
            this.grid = require(`../api/${this.type}/grid`).default;
            this.grid.forEach(tab => tab.grids.forEach(grid => {
                grid.tabId = tab.id;
                grid.charts.forEach(chart => chart.gridId = grid.id);
            }));
        }
    }

    updateGridFields(chart, tables, metrics, dimensions) {
        if (!this.isSupported) { return; }

        function getId(column) {
            if (column.dataSource) {
                return `${column.id}/${column.dataSource}`
            } else {
                return column.id;
            }
        }

        const tableMap = new Map();
        tables?.forEach(table => tableMap.set(table.id, table));

        const metricMap = new Map();
        if (Array.isArray(metrics)) {
            metrics.forEach(metric => metricMap.set(getId(metric), metric));
        } else {
            Object.entries(metrics).forEach(e => {
                const innerMap = new Map();
                e[1].forEach(metric => innerMap.set(getId(metric), metric));
                metricMap.set(e[0], innerMap);
            });
        }

        const dimensionMap = new Map();
        if (Array.isArray(dimensions)) {
            dimensions.forEach(dimension => dimensionMap.set(getId(dimension), dimension));
        } else {
            Object.entries(dimensions).forEach(e => {
                const innerMap = new Map();
                e[1].forEach(dimension => innerMap.set(getId(dimension), dimension));
                dimensionMap.set(e[0], innerMap);
            });
        }

        chart.table = tableMap.get(chart.table?.id);

        [...chart.leftMetrics, ...(chart.rightMetrics ?? [])].forEach(metric => {
            if (metric.metric && Object.keys(metric.metric).length <= 2) {
                if (chart.table) {
                    if (chart.dataLevel) {
                        metric.metric = metricMap.get(chart.table.id + ":" + chart.dataLevel)?.get(getId(metric.metric));
                    } else {
                        metric.metric = metricMap.get(chart.table.id)?.get(getId(metric.metric));
                    }
                } else {
                    metric.metric = metricMap.get(getId(metric.metric));
                }
            }
        });
        chart.dimensions = chart.dimensions?.map(dimension => {
            if (dimension && Object.keys(dimension).length <= 2) {
                if (chart.table) {
                    if (chart.dataLevel) {
                        return dimensionMap.get(chart.table.id + ":" + chart.dataLevel)?.get(getId(dimension));
                    }
                    return dimensionMap.get(chart.table.id)?.get(getId(dimension));
                } else {
                    return dimensionMap.get(getId(dimension));
                }
            }
            return dimension;
        });
    }

    getState(state) {
        return this.isBlend ? state.blends[this.type] ?? {} : state[this.type];
    }
}

export const ChannelType = {
    Default: "default",
    GoogleAnalytics: "google-analytics",
    ShopifyAds: "shopify-ads",
    Adjust: "adjust",
    Branch: "branch",
    Looker: "looker",
    Appsflyer: "appsflyer",
    Salesforce: "salesforce",
    Metadata: "metadata",
    GoogleAds: "google-ads",
    FacebookAds: "facebook-ads",
    LinkedInAds: "linkedin-ads",
    Bing: "bing-ads",
    TiktokAds: "tiktok-ads",
    GoogleAnalytics4: "google-analytics-4"
};

export const AUDIENCE_TYPE = {
    AD_ACCOUNT: "AD_ACCOUNT"
}

export const AdsList = [
    new Channel(
        ChannelType.GoogleAds,
        "Google Ads", "GAds",
        GoogleAdsLogo,
        "Connect and source data from Google Ads",
        true,
    ),
    new Channel(
        ChannelType.FacebookAds,
        "Facebook Ads", "FB",
        FacebookLogo,
        "Connect and source data from Facebook Ads",
        true,
    ),
    new Channel(
        ChannelType.LinkedInAds,
        "LinkedIn Ads", "LI",
        LinkedInLogo,
        "Connect and source data from Linkedin Ads",
        true
    ),
    new Channel(
        ChannelType.Bing,
        "Bing Ads", "Bing",
        BingLogo,
        "Connect and source data from Bing",
        false
    ),
    new Channel(
        ChannelType.TiktokAds,
        "Tiktok Ads", "TA",
        TiktokLogo,
        "Connect and source data from Tiktok Ads",
        false
    )
];

export const AttributionList = [
    new Channel(
        ChannelType.GoogleAnalytics,
        "Google Analytics", "GA",
        GoogleAnalyticsLogo,
        "Connect and source data from Google Analytics",
        true,
    ),
    new Channel(
        ChannelType.GoogleAnalytics4,
        "Google Analytics 4", "GA4",
        Ga4Logo,
        "Connect and source data from Google Analytics",
        true,
    ),
    new Channel(
        ChannelType.ShopifyAds,
        "Shopify", "Shopify",
        ShopifyLogo,
        "Connect and source data from Shopify",
        false,
    ),
    new Channel(
        ChannelType.Appsflyer,
        "Appsflyer", "AF",
        AppsflyerLogo,
        "Connect and source data from Appsflyer",
        false
    ),
    new Channel(
        ChannelType.Adjust,
        "Adjust", "Adjust",
        AdjustLogo,
        "Connect and source data from Adjust",
        false
    ),
    new Channel(
        ChannelType.Branch,
        "Branch", "Branch",
        BranchLogo,
        "Connect and source data from Branch",
    ),
    new Channel(
        ChannelType.Looker,
        "Looker", "Looker",
        LookerLogo,
        "Connect and source data from Looker",
    ),
    new Channel(
        ChannelType.Salesforce,
        "Salesforce", "SF",
        SalesforceLogo,
        "Connect and source data from Salesforce",
    ),
    new Channel(
        ChannelType.Metadata,
        "Metadata", "MD",
        MetadataLogo,
        "Connect and source data from Metadata",
    ),
];

const allChannels = [...AdsList, ...AttributionList];


export const SupportedChannels = allChannels.filter(channel => channel.isSupported);

const channelMapping = new Map(allChannels.map(v => [v.type, v]));
export const GetChannel = (channelString) => {
    if (!channelString) {
        return null;
    } else if (channelString === ChannelType.Default) {
        return SupportedChannels[0];
    } else if (channelMapping.has(channelString)) {
        return channelMapping.get(channelString);
    } else if (typeof (channelString) === "object" && "id" in channelString) {
        return new Channel(channelString.id, channelString.name, "Blend", DataBlendIcon, "Template for customized blended data sources", true, true);
    } else {
        return null;
    }
};

export const GetChannelSortOrder = (channelType) => allChannels.findIndex(channel => channel.type === channelType);

SupportedChannels.forEach(channel => channel.loadGrid());

export const convertAccountsFormat = (inputData) => {
    const allUsers = []
    const allAccounts = inputData.reduce((allAccounts, item) => {
        allUsers.push({ id: item.platformUserId, email: item.platformUserEmail });
        (item.adAccounts ?? []).forEach(account => {
            const { adAccountId, adAccountName } = account;
            if (account.properties) {
                account.properties.forEach(property => {
                    const { propertyId, propertyName } = property;
                    if (property.views) {
                        property.views.forEach(view => {
                            let key = adAccountId + propertyId + view.viewId;
                            allAccounts[key] = allAccounts[key] || {
                                ...view, adAccountId, adAccountName, propertyId, propertyName, users: []
                            };
                            allAccounts[key].users.push({ id: item.platformUserId, email: item.platformUserEmail });
                        })
                    } else {
                        let key = adAccountId + propertyId;
                        allAccounts[key] = allAccounts[key] || { adAccountId, adAccountName, propertyId, propertyName, users: [] };
                        allAccounts[key].users.push({ id: item.platformUserId, email: item.platformUserEmail });
                    }
                })
            } else {
                let key = adAccountId + adAccountName;
                allAccounts[key] = allAccounts[key] || { ...account, users: [] };
                allAccounts[key].users.push({ id: item.platformUserId, email: item.platformUserEmail });
            }
        });
        return allAccounts;
    }, {})
    return ({ allAccounts: Object.values(allAccounts), allUsers })
}


/**
 * Get audiences from adAccounts
 * @param {string} audienceType
 * @param {Array<{accountId: string, channelType: string, id: string, title: string}>} adAccounts 
 * @returns {Array<{type: string, id: string, dataSource: string, metaData: object}>} audiences
 */
export const getAudienceFromAdAccountDetails = (audienceType, adAccounts = []) => {
    const audiences = [];
    adAccounts.forEach(adAccount => {
        let { users, ...metaData } = adAccount || {};
        audiences.push({
            type: audienceType,
            id: adAccount.id,
            dataSource: adAccount.channelType ?? adAccount.source,
            metaData
        })
    });
    return audiences;
}