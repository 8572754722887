import { useState, useEffect } from "react";
import { Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, Modal, Grid, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import "../styles/profile.css";
import { useDispatch, useSelector } from "react-redux";
import { runTrigger } from "../../api/extractor/action";
import { updateLoader } from "../../api/user/action";
import { transformFromSuperMetricDataObject } from "../SuperMetrics/Utils";
import { GetChannel } from "../../models/channel";
import { ORDER_OPTIONS } from "../SuperMetrics/Utils";
import ReRunDataList from "../Common/ReRunDataList";
import CalendarIcon from "../../Assets/Icons/calendar-icon.svg"
import moment from "moment";
import DateRangeModal from "./DateRangeModal";

const defaultOrderBy = {
    fieldName: null,
    sortOrder: ORDER_OPTIONS[0],
    fieldError: false,
};

const defaultStartAt = {
    on: null,
    date: null,
    time: { hour: 10, min: 0 },
}; // Schedule

const defaultStorage = {
    headerLine: false, //hasHeaders
    startCell: { column: "", row: "" }, //startingCell
    endCell: { column: "", row: "" }, //endingCell
    newEmptySheet: { id: "yes", name: "Yes" }, //spreadsheetCreate
    existingSheet: null, //spreadsheetId
    sheetNomenclature: "", //spreadsheetName
    tabNomenclature: "", //tabName
    sheetFormat: [],
    tabFormat: [],
    existingFolder: {
        id: "root",
        name: "Root Folder",
        type: "folder",
    },
};

const ReRunModal = (props) => {
    const { modal, onClose, setCompletion, reRunDateRange, updateReRunDateRange } = props;
    const dispatch = useDispatch();

    const allTriggers = useSelector((state) => state.extractor.allTriggers)
    const [reRunData, setreRunData] = useState({})

    const [channel, setChannel] = useState(null)
    const [table, setTable] = useState(null);
    const [dataLevel, setDataLevel] = useState(null);
    const [accounts, setAccounts] = useState([])

    const [triggerData, setTriggerData] = useState(
        reRunData.triggerData ?? {
            metrics: [],
            aggregates: [],
            segregate: false,
            dimensions: [],
            numberOfRows: 10000,
            orderBys: [{ ...defaultOrderBy }],
            filters: {},
        }
    );

    const [scheduleData, setScheduleData] = useState(
        reRunData.scheduleData ?? {
            action: null,
            timeZone: null,
            startAt: [{ ...defaultStartAt }], //
        }
    );

    const [storageData, setStorageData] = useState(
        reRunData.storageData ?? {
            storage: null, //dataDestination
            writeMode: null, //writePreference
            ...defaultStorage,
        }
    );

    const viewDate = (date) => moment(date).format("MMM DD, YYYY");
    const [isReRunDatePickerOpen, setIsReRunDatePickerOpen] = useState(false);

    useEffect(() => {
        let temp = allTriggers.filter((e) => e.name === modal.name && e._id === modal.id)
        if (temp.length > 0) {
            setreRunData(transformFromSuperMetricDataObject(temp[0], false));
            updateReRunDateRange({ label: "", startDate: modal.fromDate ?? null, endDate: modal.toDate ?? null })
        }
    }, [modal.id])

    useEffect(() => {
        setAccounts(reRunData.accounts ?? [])
        setChannel(GetChannel(reRunData.channelType))
        setTable(reRunData.table ?? null)
        setDataLevel(reRunData.dataLevel ?? null)
        setTriggerData(reRunData.triggerData ?? {
            metrics: [],
            aggregates: [],
            segregate: false,
            dimensions: [],
            numberOfRows: 10000,
            orderBys: [{ ...defaultOrderBy }],
            filters: {},
        })
        setScheduleData(reRunData.scheduleData ?? {
            action: null,
            timeZone: null,
            startAt: [{ ...defaultStartAt }],
        })
        setStorageData(reRunData.storageData ?? {
            storage: null, //dataDestination
            writeMode: null, //writePreference
            ...defaultStorage,
        })
    }, [reRunData])

    const reRun = (tid, lid) => {
        onClose();
        dispatch(updateLoader(true));
        dispatch(runTrigger({
            triggerId: tid,
            logId: lid,
            runFromDate: reRunDateRange?.fromDate,
            runToDate: reRunDateRange?.toDate,
            loading: (msg) => {
                dispatch(updateLoader(false));
                setCompletion({ message: msg, operation: "Re-Run" });
            },
        }));
    }

    const customStyles = {
        top: '3.5%',
        left: '20%',
        right: 'auto',
        bottom: 'auto',
        backgroundColor: "#ffff",
        borderRadius: "12px",
        width: "60%",
        minHeight: "93%",
        // padding: "10px",
        zIndex: 9000000000000,
        position: "absolute",
        fontFamily: "Inter",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center"
    };

    return (
        <div>
            <Modal
                open={modal.open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={customStyles}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap", height: "72px", width: "calc(100% - 64px)", margin: "5px 32px", top: 0, position: "absolute", }}>
                        <div style={{ textAlign: "start" }}>
                            <Typography style={{ fontFamily: "Inter", fontWeight: 700, fontSize: "14px", color: "#5C6674" }}>Trigger Name</Typography>
                            <Typography style={{ fontFamily: "Inter", fontWeight: 600, fontSize: "18px", color: "#000000" }}>{modal.name}</Typography>
                        </div>
                        <div style={{ width: "390px", display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap", }}>
                            <div style={{ margin: "0px 0px", display: "flex" }}>
                                <Typography className="newText">Select a Date Range</Typography>
                            </div>
                            <div style={{ width: "260px" }}>
                                <ReRunDataList
                                    width={12}
                                    bottomSpace={"0px"}
                                    stretch={"100%"}
                                    label={(reRunDateRange?.fromDate) ?
                                        (viewDate(reRunDateRange.fromDate) + " - " + viewDate(reRunDateRange.toDate))
                                        : "Select a Date Range"}
                                    startAdornment={{
                                        onClick: () => setIsReRunDatePickerOpen(true),
                                        src: CalendarIcon,
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="trigger-table" style={{ top: "72px", height: "calc(100% - 144px)", position: "absolute", width: "100%", overflowY: "auto" }}>
                        <div className="content" style={{ backgroundColor: "#F6F7F8", width: "calc(100% - 64px)", margin: "5px 32px", padding: "16px", borderRadius: "10px" }}>
                            {reRunData.name &&
                                <Grid container xs={12} style={{ overflowY: "auto" }}>
                                    <ReRunDataList
                                        width={3}
                                        title={"Data Source"}
                                        label={channel?.title}
                                    />
                                    {table &&
                                        <ReRunDataList
                                            width={3}
                                            title={"Report Type"}
                                            label={table?.name}
                                        />
                                    }
                                    {dataLevel &&
                                        <ReRunDataList
                                            width={3}
                                            title={"Data Level"}
                                            label={dataLevel}
                                        />
                                    }
                                    <ReRunDataList
                                        width={3}
                                        title={"Accounts"}
                                        label={""}
                                        list={accounts}
                                    />
                                </Grid>
                            }
                        </div>

                        <div style={{ width: "calc(100% - 64px)", display: "flex", alignItems: "flex-start", justifyContent: "flex-start", margin: "20px 32px 6px 32px" }}>
                            <Typography style={{ fontFamily: "Inter", fontWeight: 700, fontSize: "14px" }}>
                                Extractor
                            </Typography>
                        </div>
                        <div className="content" style={{ backgroundColor: "#F6F7F8", width: "calc(100% - 64px)", margin: "5px 32px", padding: "16px", borderRadius: "10px" }}>
                            {reRunData.name &&
                                <Grid container xs={12} style={{ overflowY: "auto" }}>
                                    <ReRunDataList
                                        title={"Metrics"}
                                        label={""}
                                        list={triggerData?.metrics}
                                        displayAggregates={!triggerData?.segregate && accounts?.length}
                                    />
                                    <ReRunDataList
                                        title={"Dimensions"}
                                        label={""}
                                        list={triggerData?.dimensions}
                                    />
                                    <ReRunDataList
                                        title={"Date range  (at the time of trigger creation)"}
                                        label={viewDate(reRunData?.dateRange?.fromDate) + " - " + viewDate(reRunData?.dateRange?.toDate)}
                                    />
                                    <ReRunDataList
                                        title={"#Of Rows To Fetch"}
                                        label={triggerData?.numberOfRows}
                                    />
                                    {triggerData?.orderBys?.length >= 0 &&
                                        triggerData?.orderBys?.map((ord) => {
                                            return (
                                                <>
                                                    <ReRunDataList
                                                        title={"Attribute"}
                                                        label={ord?.fieldName?.name}
                                                    />
                                                    <ReRunDataList
                                                        title={"Sort By"}
                                                        label={ord?.sortOrder?.name}
                                                    />
                                                </>
                                            )
                                        })
                                    }
                                    {Object.values(triggerData.filters).length ? (
                                        <Grid item xs={11.5}>
                                            <Typography className="newText" sx={{ textAlign: "left" }}>
                                                Filters
                                            </Typography>
                                            <Grid
                                                item
                                                xs={12}
                                                style={{
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    border: "1px solid #DDDDDD",
                                                    borderRadius: "4px",
                                                    padding: "10px",
                                                }}
                                            >
                                                {Object.values(triggerData.filters).map(
                                                    (appliedFilter, index, filters) => {
                                                        const name = appliedFilter.filterName;
                                                        const filterGroups =
                                                            appliedFilter.filterGrouping?.filterGroups ?? [];
                                                        const segments =
                                                            appliedFilter.additionalFilters[0]?.values?.filter(
                                                                (val) => val
                                                            );
                                                        const unify =
                                                            appliedFilter.additionalFilters[0]?.unify_data;
                                                        return (
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                style={{
                                                                    borderBottom:
                                                                        (filters.length % 2 === 0 &&
                                                                            filters.length < index + 3) ||
                                                                            (filters.length % 2 === 1 &&
                                                                                filters.length < index + 2)
                                                                            ? "0px"
                                                                            : "1px solid rgba(0, 0, 0, 0.1)",
                                                                }}
                                                            >
                                                                <div style={{ padding: "10px", display: "flex", flexDirection: "row" }}>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                            alignItems: "center",
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            variant="body1"
                                                                            style={{
                                                                                fontFamily: "Inter",
                                                                                fontSize: "14px",
                                                                                fontWeight: 600,
                                                                                marginBottom: "10px",
                                                                                marginRight: "15px",
                                                                            }}
                                                                        >
                                                                            Filter: {name} -
                                                                        </Typography>
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                            alignItems: "center",
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            variant="body1"
                                                                            style={{
                                                                                fontFamily: "Inter",
                                                                                fontSize: "14px",
                                                                                fontWeight: 500,
                                                                                color: "#787878",
                                                                                marginBottom: "10px",
                                                                                marginRight: "15px"
                                                                            }}
                                                                        >
                                                                            Account: {appliedFilter.account.title}, Report
                                                                            Type: {appliedFilter.reportType ?? "-"}, Filter:{" "}
                                                                            {filterGroups.reduce(
                                                                                (filterString, filterGroup, index) => {
                                                                                    filterString +=
                                                                                        " " +
                                                                                        filterGroup.filters.reduce(
                                                                                            (filterString, filter, index) => {
                                                                                                return (
                                                                                                    filterString +
                                                                                                    " " +
                                                                                                    filter.fieldName +
                                                                                                    " " +
                                                                                                    (filter.not ? "NOT " : "") +
                                                                                                    filter.operator +
                                                                                                    " " +
                                                                                                    filter.expressions +
                                                                                                    " " +
                                                                                                    (filterGroup.filters.length ===
                                                                                                        index + 1
                                                                                                        ? ""
                                                                                                        : filterGroup.operator)
                                                                                                );
                                                                                            },
                                                                                            ""
                                                                                        );
                                                                                    if (filterGroups.length !== index + 1) {
                                                                                        filterString +=
                                                                                            " " + appliedFilter.operator;
                                                                                    }
                                                                                    return filterString;
                                                                                },
                                                                                ""
                                                                            )}
                                                                            {segments && segments.length ? (
                                                                                <span>
                                                                                    <br />
                                                                                    Segments: {segments.join(", ")}
                                                                                    {unify && " Unify: " + unify.value}
                                                                                </span>
                                                                            ) : null}
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                        );
                                                    }
                                                )}
                                            </Grid>
                                        </Grid>
                                    ) : null}
                                </Grid>
                            }
                        </div>

                        <div style={{ width: "calc(100% - 64px)", display: "flex", alignItems: "flex-start", justifyContent: "flex-start", margin: "20px 32px 6px 32px" }}>
                            <Typography style={{ fontFamily: "Inter", fontWeight: 700, fontSize: "14px" }}>
                                Storage
                            </Typography>
                        </div>
                        <div className="content" style={{ backgroundColor: "#F6F7F8", width: "calc(100% - 64px)", margin: "5px 32px", padding: "16px", borderRadius: "10px" }}>
                            {reRunData.name &&
                                <Grid container xs={12} style={{ overflowY: "auto" }}>
                                    <ReRunDataList
                                        width={3}
                                        title={"Date Storage"}
                                        label={storageData?.storage?.name}
                                    />
                                    <ReRunDataList
                                        width={3}
                                        title={"Write Mode"}
                                        label={storageData?.writeMode?.name}
                                    />
                                    <ReRunDataList
                                        width={3}
                                        title={"Create New Empty Sheet"}
                                        label={storageData?.newEmptySheet?.name}
                                    />
                                    <ReRunDataList
                                        width={3}
                                        title={"Make first row as header"}
                                        label={storageData?.headerLine ? "Yes" : "No"}
                                    />
                                    {["append", "replace"].includes(storageData?.writeMode?.id) && (
                                        <>
                                            <ReRunDataList
                                                width={3}
                                                title={"Start Data From"}
                                                label={(storageData?.startCell?.column.length ? storageData?.startCell?.column : "{column}")
                                                    + " : " + (storageData?.startCell?.row.length ? storageData?.startCell?.row : "{row}")}
                                            />
                                            <ReRunDataList
                                                width={3}
                                                title={"End Data At"}
                                                label={(storageData?.endCell?.column.length ? storageData?.endCell?.column : "{column}")
                                                    + " : " + (storageData?.endCell?.row.length ? storageData?.endCell?.row : "{row}")}
                                            />
                                        </>
                                    )}
                                    <ReRunDataList
                                        width={3}
                                        title={"Folder Name"}
                                        label={storageData?.existingFolder?.name}
                                    />
                                    <ReRunDataList
                                        width={3}
                                        title={"SpreadSheet Name"}
                                        label={storageData?.sheetNomenclature ||
                                            storageData?.sheetFormat
                                                ?.map((para) => (para?.custom ? para?.value : para?.id))
                                                ?.join("_")
                                        }
                                    />
                                    <ReRunDataList
                                        width={3}
                                        title={"Tab Name"}
                                        label={storageData?.tabNomenclature ||
                                            storageData?.tabFormat
                                                ?.map((para) => (para?.custom ? para?.value : para?.id))
                                                ?.join("_")
                                        }
                                    />
                                </Grid>
                            }
                        </div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", height: "72px", bottom: 0, position: "absolute", padding: "15px 32px" }}>
                        <div>
                            <FormControl
                                sx={{ justifyContent: "flex-start" }}
                                size="small"
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={reRunData?.sendOverEmail}
                                            sx={{
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: 20,
                                                },
                                                '&.Mui-checked': {
                                                    color: "#0869FB",
                                                },
                                                cursor: "default"
                                            }}
                                            disableRipple
                                        />
                                    }
                                    label={<Typography
                                        style={{
                                            fontFamily: "Inter",
                                            fontSize: "14px",
                                            fontWeight: 400
                                        }}>Send copy on Email</Typography>}
                                />
                            </FormControl>
                        </div>
                        <div>
                            <Button sx={{
                                marginRight: "8px", 
                                fontFamily: "Inter", 
                                borderRadius: "8px", 
                                fontSize: "14px", 
                                fontWeight: 400, 
                                textTransform: "none" 
                            }} onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button sx={{ 
                                fontFamily: "Inter", 
                                borderRadius: "8px", 
                                fontSize: "14px", 
                                fontWeight: 400, 
                                textTransform: "none", 
                                ":disabled": { 
                                    color: "#0869FB88", 
                                    backgroundColor: "#EBF3FF" 
                                }, 
                                }} 
                                disabled={!reRunDateRange?.fromDate || !reRunDateRange?.toDate} 
                                onClick={() => reRun(modal.id, modal.logId)} 
                                variant="contained"
                            >
                                Re-run
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal >
            {isReRunDatePickerOpen && <DateRangeModal
                dateRange={reRunDateRange}
                setDateRange={updateReRunDateRange}
                isDatePickerOpen={isReRunDatePickerOpen}
                setIsDatePickerOpen={setIsReRunDatePickerOpen}
            />}
        </div>
    )
}

export default ReRunModal;

{/* <div className="header" style={{ height: "78px", top: 0, position: "absolute", alignItems: "center" }}>
    <div style={{ textAlign: "start" }}>
        <Typography className="newText">Trigger Name</Typography>
        <b>{modal.name}</b>
    </div>
    <div style={{ width: "270px", marginRight: "8px" }}>
        <ReRunDataList
            width={12}
            title={"Select a Date Range"}
            label={(reRunDateRange?.fromDate) ?
                (viewDate(reRunDateRange.fromDate) + " - " + viewDate(reRunDateRange.toDate))
                : "Select a Date Range"}
            startAdornment={{
                onClick: () => setIsReRunDatePickerOpen(true),
                src: CalendarIcon,
            }}
        />
    </div>
</div> */}

{/* <div className="content" style={{ top: "78px", height: "calc(100% - 150px)", width: "100%", padding: "16px 12px 16px 20px", position: "absolute", overflowY: "auto" }}>
    {reRunData.name &&
        <Grid container xs={12} style={{ overflowY: "auto" }}>
            <ReRunDataList
                title={"Data Source"}
                label={channel?.title}
            />
            {table &&
                <ReRunDataList
                    title={"Report Type"}
                    label={table?.name}
                />
            }
            {dataLevel &&
                <ReRunDataList
                    title={"Data Level"}
                    label={dataLevel}
                />
            }
            <ReRunDataList
                title={"Accounts"}
                label={""}
                list={accounts}
            />
            
            <div style={{ borderBottom: "1px solid #EAEAEC", width: "100%", marginTop: "16px", marginBottom: "16px" }} />
            <div style={{ width: "100%", display: "flex", alignItems: "flex-start", justifyContent: "flex-start", marginBottom: "16px" }}>
                <Typography style={{ fontFamily: "Inter", fontWeight: 600, fontSize: "14px" }}>
                    Extractor
                </Typography>
            </div>
            <ReRunDataList
                title={"Metrics"}
                label={""}
                list={triggerData?.metrics}
                displayAggregates={!triggerData?.segregate && accounts?.length}
            />
            <ReRunDataList
                title={"Dimensions"}
                label={""}
                list={triggerData?.dimensions}
            />
            <ReRunDataList
                title={"Date range  (at the time of trigger creation)"}
                label={viewDate(reRunData?.dateRange?.fromDate) + " - " + viewDate(reRunData?.dateRange?.toDate)}
            />
            <ReRunDataList
                title={"#Of Rows To Fetch"}
                label={triggerData?.numberOfRows}
            />
            {triggerData?.orderBys?.length >= 0 &&
                triggerData?.orderBys?.map((ord) => {
                    return (
                        <>
                            <ReRunDataList
                                title={"Attribute"}
                                label={ord?.fieldName?.name}
                            />
                            <ReRunDataList
                                title={"Sort By"}
                                label={ord?.sortOrder?.name}
                            />
                        </>
                    )
                })
            }
            {Object.values(triggerData.filters).length ? (
                <Grid item xs={11.5}>
                    <Typography className="newText" sx={{ textAlign: "left" }}>
                        Filters
                    </Typography>
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            border: "1px solid #DDDDDD",
                            borderRadius: "4px",
                            padding: "10px",
                        }}
                    >
                        {Object.values(triggerData.filters).map(
                            (appliedFilter, index, filters) => {
                                const name = appliedFilter.filterName;
                                const filterGroups =
                                    appliedFilter.filterGrouping?.filterGroups ?? [];
                                const segments =
                                    appliedFilter.additionalFilters[0]?.values?.filter(
                                        (val) => val
                                    );
                                const unify =
                                    appliedFilter.additionalFilters[0]?.unify_data;
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            borderBottom:
                                                (filters.length % 2 === 0 &&
                                                    filters.length < index + 3) ||
                                                    (filters.length % 2 === 1 &&
                                                        filters.length < index + 2)
                                                    ? "0px"
                                                    : "1px solid rgba(0, 0, 0, 0.1)",
                                        }}
                                    >
                                        <div style={{ padding: "10px", display: "flex", flexDirection: "row" }}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    style={{
                                                        fontFamily: "Inter",
                                                        fontSize: "14px",
                                                        fontWeight: 600,
                                                        marginBottom: "10px",
                                                        marginRight: "15px",
                                                    }}
                                                >
                                                    Filter: {name} -
                                                </Typography>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    style={{
                                                        fontFamily: "Inter",
                                                        fontSize: "14px",
                                                        fontWeight: 500,
                                                        color: "#787878",
                                                        marginBottom: "10px",
                                                        marginRight: "15px"
                                                    }}
                                                >
                                                    Account: {appliedFilter.account.title}, Report
                                                    Type: {appliedFilter.reportType ?? "-"}, Filter:{" "}
                                                    {filterGroups.reduce(
                                                        (filterString, filterGroup, index) => {
                                                            filterString +=
                                                                " " +
                                                                filterGroup.filters.reduce(
                                                                    (filterString, filter, index) => {
                                                                        return (
                                                                            filterString +
                                                                            " " +
                                                                            filter.fieldName +
                                                                            " " +
                                                                            (filter.not ? "NOT " : "") +
                                                                            filter.operator +
                                                                            " " +
                                                                            filter.expressions +
                                                                            " " +
                                                                            (filterGroup.filters.length ===
                                                                                index + 1
                                                                                ? ""
                                                                                : filterGroup.operator)
                                                                        );
                                                                    },
                                                                    ""
                                                                );
                                                            if (filterGroups.length !== index + 1) {
                                                                filterString +=
                                                                    " " + appliedFilter.operator;
                                                            }
                                                            return filterString;
                                                        },
                                                        ""
                                                    )}
                                                    {segments && segments.length ? (
                                                        <span>
                                                            <br />
                                                            Segments: {segments.join(", ")}
                                                            {unify && " Unify: " + unify.value}
                                                        </span>
                                                    ) : null}
                                                </Typography>
                                            </div>
                                        </div>
                                    </Grid>
                                );
                            }
                        )}
                    </Grid>
                </Grid>
            ) : null}

            <div style={{ borderBottom: "1px solid #EAEAEC", width: "100%", marginTop: "16px", marginBottom: "16px" }} />
            <div style={{ width: "100%", display: "flex", alignItems: "flex-start", justifyContent: "flex-start", marginBottom: "16px" }}>
                <Typography style={{ fontFamily: "Inter", fontWeight: 600, fontSize: "14px" }}>
                    Storage
                </Typography>
            </div>
            <ReRunDataList
                title={"Date Storage"}
                label={storageData?.storage?.name}
            />
            <ReRunDataList
                title={"Write Mode"}
                label={storageData?.writeMode?.name}
            />
            <ReRunDataList
                title={"Create New Empty Sheet"}
                label={storageData?.newEmptySheet?.name}
            />
            <ReRunDataList
                title={"Make first row as header"}
                label={storageData?.headerLine ? "Yes" : "No"}
            />
            {["append", "replace"].includes(storageData?.writeMode?.id) && (
                <>
                    <ReRunDataList
                        title={"Start Data From"}
                        label={(storageData?.startCell?.column.length ? storageData?.startCell?.column : "{column}")
                            + " : " + (storageData?.startCell?.row.length ? storageData?.startCell?.row : "{row}")}
                    />
                    <ReRunDataList
                        title={"End Data At"}
                        label={(storageData?.endCell?.column.length ? storageData?.endCell?.column : "{column}")
                            + " : " + (storageData?.endCell?.row.length ? storageData?.endCell?.row : "{row}")}
                    />
                </>
            )}
            <ReRunDataList
                title={"Folder Name"}
                label={storageData?.existingFolder?.name}
            />
            <ReRunDataList
                title={"SpreadSheet Name"}
                label={storageData?.sheetNomenclature ||
                    storageData?.sheetFormat
                        ?.map((para) => (para?.custom ? para?.value : para?.id))
                        ?.join("_")
                }
            />
            <ReRunDataList
                title={"Tab Name"}
                label={storageData?.tabNomenclature ||
                    storageData?.tabFormat
                        ?.map((para) => (para?.custom ? para?.value : para?.id))
                        ?.join("_")
                }
            />
        </Grid>
    }
</div> */}

{/* <div className="footer" style={{ height: "72px", bottom: 0, position: "absolute" }}>
    <div>
        <FormControl
            sx={{ justifyContent: "flex-start" }}
            size="small"
        >
            <FormControlLabel
                control={
                    <Checkbox
                        checked={reRunData?.sendOverEmail}
                        sx={{
                            '& .MuiSvgIcon-root': {
                                fontSize: 20,
                            },
                            '&.Mui-checked': {
                                color: "#0869FB",
                            },
                        }}
                        disableRipple
                    />
                }
                label={<Typography
                    style={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 400
                    }}>Send copy on Email</Typography>}
            />
        </FormControl>
    </div>
    <Typography
        component="div"
        sx={{ flexGrow: 1 }}
    ></Typography>
    <Button sx={{ marginRight: "10px" }} onClick={onClose}>
        Cancel
    </Button>
    <Button sx={{ marginRight: "12px" }} disabled={false} onClick={() => reRun(modal.id, modal.logId)} variant="contained">
        Re-run
    </Button>
</div> */}