import axios from "axios";
import axiosRetry from "axios-retry";
import { v4 as uuid } from 'uuid';

axiosRetry(axios, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: error => error.response.status === 429 || error.response.status === 502 || error.response.status === 504,
});

class API {
    get(url, useToken = true) {
        let header = {};
        if (useToken) {
            let token = localStorage.getItem("accessToken");
            if (!token) { throw "Token not Found!" }
            header = { authorization: token };
        }

        return axios.get(process.env.REACT_APP_BACKEND_BASE_URL + url, {
            headers: { "Content-Type": "application/json", ...header, "request_id": uuid() }
        }).catch(err => {
            if (err.response.status === 401) {
                if (err.response.data.message === "User has been blocked") {
                    window.location.href = "/logout?blocked";
                } else {
                    window.location.href = "/logout";
                }
            } else {
                throw err;
            }
        });
    }

    post(url, data, useToken = true) {
        let header = {};
        if (!data) { data = {}; }
        if (useToken) {
            let token = localStorage.getItem("accessToken");
            // data["access_token"] = token;
            header = { authorization: token };
        }

        return axios.post(process.env.REACT_APP_BACKEND_BASE_URL + url, data, {
            headers: { "Content-Type": "application/json", ...header, "request_id": uuid() }
        }).catch(err => {
            if (err.response.status === 401) {
                if (err.response.data.message === "User has been blocked") {
                    window.location.href = "/logout?blocked";
                } else {
                    window.location.href = "/logout";
                }
            } else {
                throw err;
            }
        });
    }

    put(url, data, useToken = true) {
        let header = {};
        if (!data) { data = {}; }
        if (useToken) {
            let token = localStorage.getItem("accessToken");
            // data["access_token"] = token;
            header = { authorization: token };
        }

        return axios.put(process.env.REACT_APP_BACKEND_BASE_URL + url, data, {
            headers: { "Content-Type": "application/json", ...header, "request_id": uuid() }
        }).catch(err => {
            if (err.response.status === 401) {
                if (err.response.data.message === "User has been blocked") {
                    window.location.href = "/logout?blocked";
                } else {
                    window.location.href = "/logout";
                }
            } else {
                throw err;
            }
        });
    }
    delete(url, data, useToken = true) {
        let headers = {};
        if (!data) { data = {}; }
        if (useToken) {
            let token = localStorage.getItem("accessToken");
            // data["access_token"] = token;
            headers = { authorization: token };
        }
        return axios
            .delete(process.env.REACT_APP_BACKEND_BASE_URL + url, {
                headers,
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    this.clearLocalStorage();
                    if (err.response.data.message === "User has been blocked") {
                        window.location.href = "/logout?blocked";
                    } else {
                        window.location.href = "/logout";
                    }
                } else {
                    throw err;
                }
            });
    }
}

export const api = new API();
