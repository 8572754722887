import React, { useEffect } from "react";
import { Box, Button, Typography, } from "@mui/material";
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateTourInfo } from "../../api/user/action";
import { endTour } from "../../api/user/action";

function IntroTour({ editDrawerState, setEditDrawerState, chart, source = "dashboard" }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const { role } = useSelector(state => state.user.user);
    const { showTutorial, run, stepIndex } = useSelector(state => state.user.tutorial) || {};

    const dashboardSteps = [
        {
            title: "Edit, Remove, and Download charts",
            content: 'With a click of a button, you can easily edit, remove, or download your charts.',
            disableBeacon: true,
            // hideFooter: true,
            // spotlightClicks: true,
            placement: 'bottom',
            target: '[data-tut="edit_chart"]',
        },
        {
            title: "Change Data Source type and Chart type",
            content: 'You have the flexibility to change the data source type and chart type to best fit your needs. Also define dimensions and metrics to customize your charts.',
            placement: 'left',
            target: '[data-tut="sidebar_widget"]'
        },
        {
            title: "Create Your Filters",
            content: `Our product allows you to create new filters on dimensions and metrics, as well as view a list of already created filters.`,
            target: '[data-tut="filter_box"]'
        },
        {
            title: "Add Multiple Report Types",
            content: `You can add multiple report types to your charts to meet your specific requirements.`,
            target: '[data-tut="reports_list"]'
        },
        {
            title: "Multiple Tabs",
            content: 'You can create multiple tabs within a single report to keep your information organised.',
            target: '[data-tut="add_tab"]'
        },
        {
            title: "Data Blending",
            content: 'Our product also allows you to merge data from different sources to visualise multiple metrics in one chart.',
            target: '[data-tut="cutomize_sources"]'
        }
    ]

    const customizeSourcesSteps = [
        {
            title: "Custom Dimensions & Metrics",
            content: 'You can create a new custom metric from existing metrics for even more customisation.',
            target: '[data-tut="custom_column"]'
        }
    ]

    const profileSteps = [
        (role === "admin" || role === "superadmin") ? {
            title: `Manage Team and Invite Team Members`,
            content: "Finally, you can manage your team and easily invite new members to collaborate with you.",
            target: '[data-tut="invite_users"]'
        } : {
            title: `Manage Team`,
            content: "Finally, you can manage your team easily.",
            target: '[data-tut="view_members"]',
        }
    ]

    const steps = [
        ...dashboardSteps,
        ...customizeSourcesSteps,
        ...profileSteps,
        {
            content: `We hope this tour has been helpful in introducing you to Pixis Analytics.`,
            placement: 'center',
            target: 'body'
        }
    ]

    useEffect(() => {
        if (showTutorial && run) {
            switch (stepIndex) {
                case dashboardSteps.length:
                    if (source !== "customizing-sources") {
                        history.push(`/customizing-sources`);
                    }
                    break;
                case dashboardSteps.length + customizeSourcesSteps.length:
                    if (source !== "profile") {
                        history.push(`/profile`);
                    }
                    break;
                default:
                    break;
            }
        }
    }, [stepIndex])

    useEffect(() => {
        if (run) {
            checkStepWiseExecution(stepIndex, true);
            dispatch(updateTourInfo({ run: false }))
            setTimeout(() => {
                dispatch(updateTourInfo({ run: true }))
            }, [400])
        }
    }, [])

    const Tooltip = (data) => {
        const { continuous, index, size, isLastStep, primaryProps, skipProps, step, tooltipProps } = data || {};
        return (
            < Box {...tooltipProps}
                sx={{
                    maxWidth: "420px", minWidth: "290px", background: "white",
                    padding: "25px", borderRadius: "8px", fontFamily: "Inter"
                }
                }
            >
                {step?.title && <Typography style={{ fontWeight: "bold", marginBottom: "10px", fontSize: "18px" }} >{step.title}</Typography>}
                <Box>{step?.content}</Box>
                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "15px" }}>
                    <Typography style={{ color: "#0869FB" }} >{!isLastStep ? `${index + 1} of ${size - 1}` : ""}</Typography>
                    <div style={{ display: "flex" }}>
                        {!isLastStep && (
                            <Button {...skipProps} size="sm" style={{ textTransform: "none" }}>
                                Skip
                            </Button>
                        )}
                        <Button {...primaryProps} size="sm" variant="contained" color="primary"
                            style={{ textTransform: "none", borderRadius: "8px" }}
                        >
                            {(continuous && !isLastStep) ? "Continue" : "Let's get started!"}
                        </Button>
                    </div>
                </Box>
            </Box >
        )
    };

    const checkStepWiseExecution = (step, initialLoad) => {
        switch (step) {
            case 1:
                setEditDrawerState({
                    ...editDrawerState,
                    isOpen: true,
                    chart: chart,
                    grid: null,
                    handleChartChange: () => { },
                })
                if (!initialLoad) {
                    dispatch(updateTourInfo({ run: false }))
                    setTimeout(() => {
                        dispatch(updateTourInfo({ run: true }))
                    }, [400])
                }
                break;
            case 2:
                setEditDrawerState({ ...editDrawerState, isOpen: false });
                break;
            default:
                break;
        }
    }

    const handleJoyrideCallback = (data) => {
        const { action, status, index, type } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            // dispatch(updateTourInfo({ run: false, stepIndex: 0 }));
            dispatch(endTour());
        } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND]).includes(type)) {
            const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
            checkStepWiseExecution(nextStepIndex);
            dispatch(updateTourInfo({ stepIndex: nextStepIndex }))
        }
    };

    return (
        <Joyride
            tooltipComponent={Tooltip}
            callback={handleJoyrideCallback}
            continuous
            hideCloseButton
            run={run}
            scrollOffset={100}
            showProgress
            showSkipButton
            stepIndex={stepIndex}
            steps={steps}
            styles={{
                options: {
                    zIndex: 2000000,
                    primaryColor: "#0869FB"
                }
            }}
        />
    );
}
export default IntroTour;
