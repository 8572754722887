import {
  Box,
  Button,
  Checkbox,
  IconButton,
  List,
  Modal,
  Pagination,
  Popover,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import CloseIcon from "../../Assets/Icons/close-icon.svg";
import {
  AUDIENCE_TYPE,
  AdsList,
  AttributionList,
  GetChannel,
  getAudienceFromAdAccountDetails,
} from "../../models/channel";
import { useSelector } from "react-redux";
import TitleSubtitle from "../ManageDataSources/TitleSubtitle";
import SearchBar from "../Common/SearchBar";
import { AllIcons } from "../../Assets/ChannelIcons/allIcons";
import { createReport, updateReport, } from "../../api/report/action";
import { Report } from "../../models/report";
import { ReactComponent as DownIcon } from "../../Assets/Icons/arrow-down.svg";
import { ReactComponent as UpIcon } from "../../Assets/Icons/arrow-up.svg";
import { ReactComponent as CheckedIcon } from "../../Assets/Icons/checked_icon.svg";
import { ReactComponent as UncheckedIcon } from "../../Assets/Icons/unchecked_icon.svg";
import { handleMixPanelData, EVENTS } from "../../utils/mixpanelV2";

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AccountModal = (props) => {
  const {
    isopen,
    setIsOpen,
    report,
    authenticatedAccounts,
    createReport,
    updateReport,
    reportCallback
  } = props;
  // const query = new Map(props.location.search.replace("?", "").split("&").map(q => q.split("=")));
  const [adAccounts, setAdAccounts] = useState(report?.adAccounts ?? report?.audiences ?? []);
  const [newAdAccounts, setNewAdAdAccounts] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState(
    adAccounts?.reduce((channels, account) => {
      channels[account.channelType ?? account.source] = (
        channels[account.channelType ?? account.source] ?? []
      ).concat(account);
      return channels;
    }, {}) ?? {}
  );
  const [showAll, setShowAll] = useState(false);
  const nameRef = useRef(report?.report_name ?? "");
  const [error, setError] = useState("");

  const style = {
    position: "absolute",
    top: "50%",
    left: "53%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "calc(100% - 60px)",
    bgcolor: "#FFF",
    borderRadius: "12px",
    boxShadow: 24,
    padding: "16px 32px",
  };

  const selectChannel = (channel) => {
    if (selectChannels.includes(channel.type))
      setSelectChannels(selectChannels.filter((c) => c !== channel.type));
    else setSelectChannels(selectChannels.concat(channel.type));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [selectChannels, setSelectChannels] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [iconIndex, setIconIndex] = useState(
    report ? AllIcons.findIndex((icon) => icon === report?.report_icon) : 0
  );
  const [value, setValue] = useState(0);
  const isChannelAuthenticated = (channel) =>
    !!authenticatedAccounts.find((type) => type === channel?.type);

  const [filteredAccountList, setFilteredAccountList] = useState([]);
  const state = useSelector((state) => state);

  const [accountData, setAccountData] = useState([]);

  useEffect(() => {
    setAccountData(selectChannels.length
      ? selectChannels.flatMap(channel => state[channel].accountData.accountList ?? [])
      : (AdsList.concat(AttributionList)).flatMap(channel => isChannelAuthenticated(channel)
        ? state[channel.type].accountData.accountList ?? [] : [])
    )
  }, [selectChannels])

  const totalAuthenticatedAds = AdsList.filter((channel) =>
    isChannelAuthenticated(channel)
  ).length;
  const totalAuthenticatedAttributes = AttributionList.filter((channel) =>
    isChannelAuthenticated(channel)
  ).length;

  const handleCheckboxSelection = (item, remove) => {
    if (remove) {
      setSelectedAccounts({
        ...selectedAccounts,
        [item.channelType]: (selectedAccounts[item.channelType] ?? []).filter(
          (acc) => acc.id !== item.id
        ),
      });
      setAdAccounts(adAccounts.filter((acc) => acc.id !== item.id));
      setNewAdAdAccounts(newAdAccounts.filter((acc) => acc.id !== item.id));
    } else {
      const newItem = { ...item };
      delete newItem.users;
      setSelectedAccounts({
        ...selectedAccounts,
        [newItem.channelType]: (
          selectedAccounts[newItem.channelType] ?? []
        ).concat(newItem),
      });
      setAdAccounts(adAccounts.concat(newItem));
      setNewAdAdAccounts(newAdAccounts.concat(newItem));
    }
  };

  const handleSubmit = () => {
    if (nameRef.current.length <= 0) {
      setError("Report Name is required!");
    } else if (iconIndex === -1) {
      setError("Report Icon is required!");
    } else if (!adAccounts.length) {
      setError("Atleast one Ad Account must be selected!");
    } else {
      if (!report) {
        let reportObj = Report.new(nameRef.current, AllIcons[iconIndex], getAudienceFromAdAccountDetails(AUDIENCE_TYPE.AD_ACCOUNT, adAccounts));
        createReport({
          report: reportObj,
          loading: (msg, error) => {
            if (msg === "Success") {
              handleMixPanelData(EVENTS.view_success_create_report, reportObj);
            }
            reportCallback({ message: msg, error, operation: "Creation" });
          },
        });
      } else {
        updateReport({
          id: report.id,
          reportId: report.report_id,
          params: {
            report_name: nameRef.current,
            report_icon: AllIcons[iconIndex],
            add_new_accounts: getAudienceFromAdAccountDetails(AUDIENCE_TYPE.AD_ACCOUNT, newAdAccounts),
            audiences: getAudienceFromAdAccountDetails(AUDIENCE_TYPE.AD_ACCOUNT, adAccounts),
          },
          loading: (msg, error) => {
            reportCallback({ message: msg, error, operation: "Updation" });
          },
          refetch: true,
        });
        handleMixPanelData(EVENTS.Update_add_sources, { report_name: nameRef.current, adAccounts: getAudienceFromAdAccountDetails(AUDIENCE_TYPE.AD_ACCOUNT, adAccounts) })
      }
      setIsOpen(false);
    }
  };

  const [page, setPage] = useState(0);
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setError("");
  }, [iconIndex, adAccounts]);

  useEffect(() => {
    setFilteredAccountList(accountData ?? []);
  }, [selectChannels]);

  useEffect(() => {
    setPage(0);
  }, [filteredAccountList]);

  useEffect(() => {
    report && handleMixPanelData(EVENTS.view_add_sources, { report_name: report?.report_name });
  }, []);

  const rowsPerPage = 20;
  return (
    <Modal open={isopen}>
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex" }}>
            <IconButton
              onClick={(e) => setAnchorEl(e.currentTarget)}
              style={{
                background: "#F6F7F8",
                marginRight: "12px",
                padding: "9px",
                height: "40px",
                borderRadius: "6px",
              }}
              sx={{
                "&:hover": { border: "1px solid #808080" },
                border: "1px solid white",
              }}
            >
              <img
                style={{ height: "22px", width: "22px" }}
                src={AllIcons[iconIndex]}
                alt={""}
              />
            </IconButton>
            <TextField
              variant="standard"
              placeholder="Report Name"
              defaultValue={nameRef.current}
              onChange={(e) => {
                nameRef.current = e.target.value;
                setError("");
              }}
              sx={{
                "& .MuiInput-input": {
                  fontFamily: "Inter",
                  fontWeight: "600",
                  fontSize: "24px",
                  lineHeight: "20px",
                },
                "& input": {
                  color: "black",
                },
              }}
              InputProps={{
                disableUnderline: true,
                style: { "&::placeholder": { color: "red" } },
              }}
            />
            <Popover
              id={Boolean(anchorEl) ? "icon-selector-popover" : undefined}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <div style={{ padding: "16px", width: "312px" }}>
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "700",
                    lineHeight: "20px",
                    color: "#5C6674",
                    margin: "4px",
                  }}
                >
                  Choose Icon
                </Typography>
                {AllIcons.map((icon, index) => {
                  return (
                    <span
                      onClick={() => {
                        setIconIndex(index);
                      }}
                      style={{
                        display: "inline-block",
                        background: "#F6F7F8",
                        margin: "4px",
                        padding: "6px",
                        borderRadius: "6px",
                        border:
                          iconIndex === index
                            ? "1px solid blue"
                            : "1px solid #F6F7F8 ",
                      }}
                    >
                      <img
                        key={index}
                        style={{ height: "18px", width: "18px" }}
                        src={icon}
                        alt={"abc"}
                      />
                    </span>
                  );
                })}
              </div>
            </Popover>
          </div>
          <img
            src={CloseIcon}
            style={{ height: "40px", width: "40px", cursor: "pointer" }}
            onClick={() => {
              setIsOpen(false);
            }}
            alt=""
          />
        </div>

        <div
          style={{
            height: error ? "calc(100% - 120px)" : "calc(100% - 95px)",
            overflow: "auto",
          }}
        >
          {adAccounts.length ? (
            <div>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "700",
                  lineHeight: "20px",
                  color: "#5C6674",
                  margin: "24px 0 16px",
                }}
              >
                Selected Ad Accounts
              </Typography>

              <div
                style={{
                  width: "100%",
                  background: "#F6F7F8",
                  borderRadius: "12px",
                  textAlign: "left",
                  overflowY: "auto",
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "8px",
                }}
              >
                {adAccounts
                  .slice(0, showAll ? undefined : 4)
                  .map((item, index) => {
                    const accCannel = GetChannel(item.source ?? item.channelType) ?? {};
                    const disabled =
                      (report?.audiences || report?.adAccounts) &&
                      !!(report.audiences ?? report.adAccounts).find((acc) => acc.id === item.id);
                    return (
                      <Tooltip
                        title={
                          disabled
                            ? "You can’t deselect any selected ad accounts."
                            : ""
                        }
                        PopperProps={{
                          sx: {
                            "& .MuiTooltip-tooltip": {
                              backgroundColor: "white",
                              color: "black",
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontWeight: 400,
                              filter:
                                "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))",
                              padding: "8px 12px",
                            },
                          },
                        }}
                        placement="top"
                      >
                        <Box
                          key={index + item.id}
                          style={{
                            width: "calc(25% - 16px)",
                            maxWidth: "25%",
                            border: "1px solid #f0f0f0",
                            borderRadius: "12px",
                            margin: "8px",
                            display: "flex",
                            flexDirection: "column",
                            padding: "12px",
                            background: "#FFF",
                            opacity: disabled ? 0.8 : 1,
                          }}
                        >
                          <div
                            style={{ display: "flex", width: "100%" }}
                            onClick={() => {
                              !disabled && handleCheckboxSelection(item, true);
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "20px",
                                width: "20px",
                                background: "#F6F7F8",
                                borderRadius: "3.333px",
                                marginRight: "8px",
                              }}
                            >
                              <img
                                style={{ height: 12, width: 12 }}
                                src={accCannel.icon}
                                alt={accCannel.title}
                              />
                            </div>
                            <div style={{ width: "calc(100% - 62px)" }}>
                              <TitleSubtitle
                                style={{ marginLeft: "15px" }}
                                title={item.title}
                                subtitle={item.subtitle}
                                id={item.id}
                              />
                            </div>
                            <Checkbox
                              disableRipple
                              checked
                              checkedIcon={<CheckedIcon />}
                              indeterminate={disabled}
                              indeterminateIcon={
                                <CheckedIcon fill="#EBF3FF" stroke="#B5D2FE" />
                              }
                            />
                          </div>
                        </Box>
                      </Tooltip>
                    );
                  })}
                {adAccounts.length > 4 && (
                  <Button
                    style={{ textTransform: "none", width: "100%" }}
                    onClick={() => {
                      setShowAll(!showAll);
                    }}
                  >
                    {showAll ? (
                      <>
                        {" "}
                        Show Less Accounts <UpIcon />{" "}
                      </>
                    ) : (
                      <>
                        {" "}
                        Show All Accounts <DownIcon />{" "}
                      </>
                    )}
                  </Button>
                )}
              </div>
            </div>
          ) : null}

          <Typography
            style={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "700",
              lineHeight: "20px",
              marginTop: "24px",
            }}
          >
            Data Source
          </Typography>
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              borderBottom: "1px solid #ECEEF1",
            }}
          >
            <Tab
              sx={{ textTransform: "none" }}
              label={`Ads Channels (${totalAuthenticatedAds})`}
            />
            <Tab
              sx={{ textTransform: "none" }}
              label={`Attribution (${totalAuthenticatedAttributes})`}
            />
          </Tabs>
          <TabPanel value={value} index={value}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                marginTop: "20px",
              }}
            >
              {(!value ? AdsList : AttributionList).map((channel, index) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#F6F7F8",
                    height: "48px",
                    width: "48px",
                    position: "relative",
                    borderRadius: "8px",
                    border: selectChannels.includes(channel.type)
                      ? "1px solid #0869FB"
                      : "0px",
                    opacity: isChannelAuthenticated(channel) ? 1 : 0.5,
                  }}
                  onClick={() => {
                    if (isChannelAuthenticated(channel)) {
                      selectChannel(channel);
                      handleMixPanelData(EVENTS.click_filterds_create_report, { platform: channel.type });
                    }
                  }}
                >
                  <img
                    style={{ height: 24, width: 24 }}
                    src={channel.icon}
                    alt={channel.title}
                  />
                  {selectedAccounts[channel.type]?.length ? (
                    <span className="countMarker">
                      {selectedAccounts[channel.type].length}
                    </span>
                  ) : null}
                </div>
              ))}
            </div>

            <div style={{ width: "100%", display: "flex", marginTop: "24px" }}>
              <SearchBar
                filteredData={accountData ?? []}
                setFilteredData={setFilteredAccountList}
                keys={["title", "subtitle", "id"]}
              />
            </div>

            <div style={{ width: "100%", marginTop: "24px" }}>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "700",
                  lineHeight: "20px",
                  color: "#5C6674",
                  marginBottom: "8px",
                }}
              >
                Choose Ad Account
              </Typography>
              <List
                sx={{
                  padding: "0px 0px",
                  width: "100%",
                  bgcolor: "background.paper",
                  textAlign: "left",
                  overflowY: "auto",
                  display: "flex",
                  flexWrap: "wrap",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                {filteredAccountList
                  .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .filter(
                    (item) => !adAccounts.find((acc) => acc.id === item.id)
                  )
                  .map((item, index) => {
                    const channel = GetChannel(item.source ?? item.channelType);
                    const disabled =
                      (selectedAccounts[item.source ?? item.channelType]?.length ?? 0) >= 5;
                    return (
                      <Tooltip
                        title={
                          disabled
                            ? "You can can only select five account IDs for a source. You can’t add more account IDs."
                            : ""
                        }
                        PopperProps={{
                          sx: {
                            "& .MuiTooltip-tooltip": {
                              backgroundColor: "white",
                              color: "black",
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontWeight: 400,
                              filter:
                                "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))",
                              padding: "8px 12px",
                            },
                          },
                        }}
                        placement="top"
                      >
                        <Box
                          key={index + item.id}
                          style={{
                            width: "calc(25% - 16px)",
                            maxWidth: "25%",
                            border: "1px solid #f0f0f0",
                            borderRadius: "12px",
                            margin: "8px",
                            display: "flex",
                            flexDirection: "column",
                            padding: "12px",
                            opacity: disabled ? 0.5 : 1,
                          }}
                        >
                          <div
                            style={{ display: "flex", width: "100%" }}
                            onClick={() => {
                              !disabled && handleCheckboxSelection(item);
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "20px",
                                width: "20px",
                                background: "#F6F7F8",
                                borderRadius: "3.333px",
                                marginRight: "8px",
                              }}
                            >
                              <img
                                style={{ height: 12, width: 12 }}
                                src={channel.icon}
                                alt={channel.title}
                              />
                            </div>
                            <div style={{ width: "calc(100% - 62px)" }}>
                              <TitleSubtitle
                                style={{ marginLeft: "15px" }}
                                title={item.title}
                                subtitle={item.subtitle}
                                id={item.id}
                              />
                            </div>
                            <Checkbox
                              disableRipple
                              icon={<UncheckedIcon />}
                              disabled={disabled}
                              size="small"
                            />
                          </div>
                        </Box>
                      </Tooltip>
                    );
                  })}
              </List>
            </div>
            {Math.ceil((filteredAccountList.length - 1) / rowsPerPage) > 1 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  shape="rounded"
                  color="primary"
                  page={page + 1}
                  count={Math.ceil(
                    (filteredAccountList.length - 1) / rowsPerPage
                  )}
                  onChange={(e, page) => handleChangePage(page - 1)}
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    height: "60px",
                  }}
                />
              </div>
            )}
          </TabPanel>
        </div>
        {error && (
          <h4
            style={{
              color: "red",
              textAlign: "right",
              borderRadius: "10px",
              width: "100%",
              fontSize: "12px",
              marginTop: "15px",
            }}
          >
            <b>{error}</b>
          </h4>
        )}
        <div
          style={{
            height: "50px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            sx={{ height: "40px" }}
            onClick={() => {
              handleSubmit();
              report === "Generate Report" && handleMixPanelData(EVENTS.click_create_report_rm);
            }}
          >
            {report ? "Save Changes" : "Create Report"}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
const mapStateToProps = (state) => ({
  authenticatedAccounts: state.channel.authenticatedAccounts,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  createReport: (_) => dispatch(createReport(_)),
  updateReport: (_) => dispatch(updateReport(_)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountModal);
