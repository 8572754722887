import { Grid, Button, Typography, Link } from "@mui/material";
import Header from "./header";
import LandingImage1 from "../../Assets/Images/landing1.png"
import LandingImage2 from "../../Assets/Images/landing2.png"
import LandingImage3 from "../../Assets/Images/landing3.png"
import LandingImage4 from "../../Assets/Images/landing4.png"
import LandingImage5 from "../../Assets/Images/landing5.png"
import LandingImage6 from "../../Assets/Images/landing6.png"
import LandingImage7 from "../../Assets/Images/landing7.png"
import LandingImage8 from "../../Assets/Images/landing8.png"
import Footer from "./footer";

const styling = {
    title: {
        color: "#fff",
        fontSize: "4vw",
        fontFamily: "Lato",
        fontWeight: 600,
        lineHeight: "normal"

    },
    subTitle: {
        color: "#C9DAFF",
        fontSize: "1.4vw",
        fontFamily: "Lato",
        marginTop: "revert"
    }
}

const Landing = (props) => {

    return (
        <>
            <div >
                <div style={{ height: "10vh" }}>
                    <Header />
                </div>
                <div style={{ background: "#030A17", width: "fit-content" }}>
                    <div style={{ width: "100%", height: "100vh", padding: "5vh 5vw", background: `url(${LandingImage1}) right center / contain no-repeat` }}>
                        <div style={{ width: "80%" }}>
                            <Typography style={styling.subTitle}>
                                PIXIS ANALYTICS
                            </Typography>
                            <Typography style={styling.title}>
                                Unlock True Incrementality
                                <br /> with a Unified Reporting
                                <br /> Platform
                            </Typography>
                            <Typography style={styling.subTitle}>
                                Pixis Analytics enables enterprises to monitor KPIs across channels and
                                <br /> unlock the true potential of their marketing data.
                            </Typography>
                            <Link href="https://pixis.ai/book-a-demo/" >
                                <Button style={{ textTransform: "none", padding: "10px 40px", borderRadius: "8px", marginTop: "20px" }} variant="contained" color="primary" >
                                    Book a Demo
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <div style={{ width: "100%", padding: "5vh 5vw" }}>
                        <Typography style={{ ...styling.title, textAlign: "center" }}>
                            Orchestrating Better Data-backed Decisions
                            <br /> Across Teams
                        </Typography>
                        <Typography style={{ ...styling.subTitle, textAlign: "center" }}>
                            With a unified dashboard, all growth-centric teams can collaborate and work towards the most profitable strategies.
                        </Typography>
                        <img src={LandingImage2} style={{ width: "100%", marginTop: "5%" }} alt="Pixis icon" />
                    </div>
                    <div id="features" style={{ paddingTop: "5vh" }}>
                        <Grid container style={{ width: "100%", padding: "5vh 5vw" }}>
                            <Grid item xs={6} md={6}>
                                <Typography style={styling.title}>
                                    Blend Data From
                                    <br /> All Your Integrations
                                </Typography>
                                <Typography style={styling.subTitle}>
                                    Integrate and blend all your data from multiple channels
                                    <br /> and view actionable insights in a unified
                                    <br /> reporting dashboard.
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <img src={LandingImage3} style={{ width: "100%" }} alt="Pixis icon" />
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container style={{ width: "100%", padding: "5vh 5vw" }}>
                        <Grid item xs={7} md={7}>
                            <img src={LandingImage4} style={{ width: "100%" }} alt="Pixis icon" />
                        </Grid>
                        <Grid item xs={5} md={5} style={{ paddingLeft: "2vw" }}>
                            <Typography style={styling.title}>
                                Customise All Your
                                <br /> Reports Down
                                <br /> to the T
                            </Typography>
                            <Typography style={styling.subTitle}>
                                Customize reports and apply advanced filters with a
                                <br /> simple and intuitive drag and drop interface.
                            </Typography>
                        </Grid>
                    </Grid>
                    <div style={{ width: "100%", padding: "5vh 5vw", margin: "5vh 0" }}>
                        <Typography style={{ ...styling.title, textAlign: "center" }}>
                            Seamlessly Connect Your Data Through All
                            <br /> Integrations
                        </Typography>
                        <img src={LandingImage5} style={{ width: "100%", marginTop: "5%" }} alt="Pixis icon" />
                    </div>
                    <div style={{ width: "100%", padding: "5vh 5vw" }}>
                        <Typography style={{ ...styling.title, textAlign: "center" }}>
                            Built for Scaling More Profitability
                        </Typography>
                        <img src={LandingImage6} style={{ width: "100%", marginTop: "5%" }} alt="Pixis icon" />
                    </div>
                    <div style={{ width: "100%", padding: "5vh 5vw" }}>
                        <div style={{
                            width: "100%", height: "60vh",
                            padding: "5vh 5vw",
                            borderRadius: "20px",
                            background: `url(${LandingImage7}) right center / contain no-repeat`,
                            display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"
                        }}>
                            <Typography style={{ ...styling.title, textAlign: "center" }}>
                                Getting Started is Easy
                            </Typography>
                            <Typography style={{ ...styling.subTitle, textAlign: "center" }}>
                                Learn more about all the Pixis Analytics potential
                                <br /> by booking a quick 20 minutes demo.
                            </Typography>
                            <Link href="https://pixis.ai/book-a-demo/">
                                <Button onClick={() => { }} style={{ textTransform: "none", padding: "10px 40px", borderRadius: "8px", marginTop: "10px" }} variant="contained" color="primary" >
                                    Book a Demo
                                </Button>
                            </Link>
                        </div>
                    </div>
                    {/* <div style={{ width: "100vw", padding: "5vh 5vw" }}>
                        <img src={LandingImage8} style={{ width: "100%" }} alt="Pixis icon" />
                    </div> */}
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default Landing;
