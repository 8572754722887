import { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

export default function EditHeader({ editDrawerState, handleClose }) {
  const { tab,grid, handleGridChange } = editDrawerState;
  const [title, setTitle] = useState(grid.title);
  const [subtitle, setSubTitle] = useState(grid.subtitle);

  return (
    <div>
      <Accordion
        defaultExpanded={true}
        sx={{
          boxShadow: "none",
          "& .Mui-expanded": { marginBottom: "0px" },
          width: "100%",
        }}
      >
        <AccordionSummary
          sx={{ maxHeight: "30px", width: "100%", padding: "0px" }}
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            style={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "500",
              color: "#000000",
            }}
          >
            Header Title
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            placeholder="Enter Title"
            style={{ width: "100%" }}
            id="outlined-basic"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            variant="outlined"
            size="small"
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        defaultExpanded={true}
        sx={{
          boxShadow: "none",
          "& .Mui-expanded": { marginBottom: "0px" },
          width: "100%",
        }}
      >
        <AccordionSummary
          sx={{ maxHeight: "30px", width: "100%", padding: "0px" }}
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            style={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "500",
              color: "#000000",
            }}
          >
            Sub-Header Title
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            placeholder="Enter Title"
            style={{ width: "100%" }}
            id="outlined-basic"
            value={subtitle}
            onChange={(e) => setSubTitle(e.target.value)}
            variant="outlined"
            size="small"
          />
        </AccordionDetails>
      </Accordion>
      <div style={{ width: "calc(100% - 32px)", marginTop: "20px", position: "absolute", bottom: "20px" }}>
        <Button
          style={{ width: "100%", height: "40px", borderRadius: "8px" }}
          variant="contained"
          sx={{
            background: "#0968fb",
          }}
          onClick={() => {
            handleGridChange("title", title, grid, tab);
            handleGridChange("sub_title", subtitle, grid, tab);
          }}
        >
          Save & Update
        </Button>
      </div>
    </div>
  );
}
