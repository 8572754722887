import { v4 as uuid } from 'uuid';
import { ChannelType } from "./channel";
import { Chart, Metric } from "./chart";
import CustomReportIcon from "../Assets/ChannelIcons/custom_report.svg";


export const REPORT_LEVEL = {
  REPORT: "REPORT",
  TAB: "TAB",
  GRID: "GRID",
  CHART: "CHART",
}

export const UPDATE_TEMPLATE_ID = 2;
export class Tab {
  constructor(name, position, grids) {
    this.name = name;
    this.position = position ?? 0;
    this.grids = grids;
    this.id = uuid();
  }

  toJSON() {
    return {
      id: this.id,
      title: this.name,
      position: this.position,
      tab_id: this.tabId,
      grids: this.grids.map(grid => grid.toJSON()),
    }
  }

  static fromJSON(json) {
    const tab = new Tab(json.title, json.position, []);
    tab.tabId = json.tab_id;
    tab.id = json.id;
    tab.grids = json.grids?.map(grid => Grid.fromJSON(grid));
    return tab;
  }

  static new(...params) {
    const tab = new Tab(...params);
    const grid = new Grid(tab.id);
    grid.charts = Array.from(Array(4)).map((_, index) => {
      const chart = Chart.new();
      chart.gridPosition = { ...chart.gridPosition, x: parseInt(index / 2) * 6, y: index % 2 * 2 };
      chart.gridId = grid.id;
      return chart;
    });
    tab.grids = [grid];
    return tab;
  }

  static updateCustomColumns(payload, accountCustomColumn) {
    return payload.map((grid) => {
      const chart = grid.charts.map((chart) => {
        if (chart.left_metrics.length) {
          chart.left_metrics = chart.left_metrics.map((leftMetric) => {
            if(leftMetric.group === "customized") {
              const {title, accountId} = leftMetric.account
              const customColumnMetadata = accountCustomColumn[title].metrics?.filter((metric) => {
                if(metric.account.accountId === accountId) {
                  return true
                }
              })
              return {
                ...leftMetric,
                left_metrics: customColumnMetadata[0]
              }
            } else {
              return leftMetric
            }
          })
        }
        return chart
      })
      return {
        ...grid,
        charts: chart
      }
    })
  }

}

export class Grid {
  constructor(tabId, title, subtitle, charts,
    gridStyle = { titleStyle: { font: 'Inter', fontSize: '16', color: '#000000', fontFormat: ['bold'] }, subTitleStyle: { font: 'Inter', fontSize: '16', color: '#00000088', fontFormat: ['bold'] } },
  ) {
    this.tabId = tabId;
    this.title = title || "Overall";
    this.subtitle = subtitle;
    this.charts = charts;
    this.gridStyle = gridStyle;
    this.id = uuid();
  }

  toJSON() {
    return {
      id: this.id,
      tab_id: this.tabId,
      title: this.title,
      sub_title: this.subtitle,
      styling: this.gridStyle,
      grid_id: this.gridId,
      charts: this.charts.map(chart => chart.toJSON()),
    }
  }

  static fromJSON(json) {
    const grid = new Grid(json.tabId, json.title, json.sub_title, [], { titleStyle: json.styling?.title_style, ...json.styling });
    grid.id = json.id;
    grid.gridId = json.grid_id;
    grid.charts = json.charts?.map(chart => Chart.fromJSON(chart));
    return grid;
  }

  static new(...params) {
    const grid = new Grid(...params);
    grid.charts = Array.from(Array(4)).map((_, index) => {
      const chart = Chart.new();
      chart.gridPosition = { ...chart.gridPosition, x: parseInt(index / 2) * 6, y: index % 2 * 2 };
      chart.gridId = grid.id;
      return chart;
    });
    return grid;
  }

}

export class Report {
  constructor(report_type, report_name, tabs, icon = CustomReportIcon, adAccounts = [],
    updatedAt = new Date(), createdAt = new Date()
  ) {
    this.report_type = report_type;
    this.report_name = report_name;
    this.tabs = tabs;
    this.report_icon = icon;
    this.adAccounts = adAccounts;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
  }

  // to create report
  toJSON() {
    const newTabs = {};
    for (let key in this.tabs) {
      newTabs[key] = this.tabs[key].toJSON();
    }
    return {
      ...this,
      report_name: this.report_name,
      report_icon: this.report_icon,
      audiences: this.adAccounts,
      tabs: newTabs || undefined,
    }
  }

  static duplicate(json) {
    if (!json) { return null; }
    const report = new Report(
      json.report_type,
      json.report_name,
      json.tabs.sort((prev, curr) => prev.position - curr.position).map(tabJSON => {
        const oldTabId = tabJSON.id;
        const tab = new Tab(tabJSON.name, tabJSON.position, tabJSON.grids);
        tab.grids = json.grids.filter(grid => grid.tabId === oldTabId).map(gridJSON => {
          const oldGridId = gridJSON.id;
          const grid = new Grid(tab.id, gridJSON.title, gridJSON.subtitle, gridJSON.charts, gridJSON.gridStyle);
          grid.charts = json.charts.filter(chart => chart.gridId === oldGridId).map(chart => Chart.fromJSON({ ...chart, gridId: grid.id, id: uuid() }))
          return grid;
        });
        return tab;
      }),
      json.report_icon,
      json.adAccounts,
      json.updated_at
    );
    report.id = json._id;
    return report;
  }

  static fromJSON(json) {
    if (!json) { return null; }
    const newTabs = {};
    for (let key in json.tabs) {
      newTabs[key] = Tab.fromJSON(json.tabs[key]);
    }
    const report = new Report(
      json.report_type,
      json.report_name,
      newTabs,
      json.report_icon,
      json.audiences,
      json.UpdatedAt,
      json.CreatedAt
    );
    report.id = json.id;
    report.report_id = json.report_id;
    report.date_frequency = json.date_frequency;
    report.last_updated_at = json.UpdatedAt;
    report.last_updated_by = json.last_updated_by;
    return report;
  }

  static getPUTBodyPayload({ level, type, data, reportId, _reportId, tabId, _tabId, gridId, _gridId }) {
    return {
      template_id: UPDATE_TEMPLATE_ID,
      level,
      type,
      report_id: reportId,
      tab_id: tabId,
      grid_id: gridId,
      _report_id: _reportId,
      _tab_id: _tabId,
      _grid_id: _gridId,
      data,
    }
  }

  static new(name, icon, adAccounts) {
    // const tab = new Tab(tabName ?? "Overall");
    // const grid = new Grid(tab.id, gridName);
    // grid.charts = Array.from(Array(4)).map((_, index) => {
    //   const chart = Chart.new();
    //   chart.gridPosition = { ...chart.gridPosition, x: parseInt(index / 2) * 6, y: index % 2 * 2 };
    //   chart.gridId = grid.id;
    //   return chart;
    // });
    // tab.grids = [grid];
    return new Report(
      null,
      name ?? "New",
      [],
      icon,
      adAccounts
    )
  }

  static getTabPathFromTitle(title) {
    return title.toLowerCase().replaceAll(" ", "-");
  }

  /**
   * 
   * @param {{reports: object, tabs: Array<object>}} report 
   * @returns {{tabId: object}} tabsObject 
   */
  static getTabDetailsObj(report) {
    const tabsObject = {};
    report?.tabs?.map((tab) => {
      tabsObject[tab.tab_id] = {
        ...tab, report_name: report.reports.report_name
      }
    });
    return tabsObject;
  }
}

const allCharts = [
  new Chart(ChannelType.GoogleAds, "Conversion Over time", "LINE", { id: "customer", name: "Customer" },
    { x: 0, y: 0, w: 6, h: 2, static: false },
    [new Metric({ id: "metrics.conversions", name: "Conversions" })],
  ),
  new Chart(ChannelType.FacebookAds, "Conversion Funnel", "FUNNEL", null,
    { x: 6, y: 0, w: 6, h: 2, static: false },
    [
      new Metric({ id: "impressions", name: "Impressions" }),
      new Metric({ id: "reach", name: "Reach" }),
      new Metric({ id: "clicks", name: "Clicks (all)" }),
    ],
  ),
  new Chart(ChannelType.GoogleAnalytics, "Conversions", "BAR", null,
    { x: 6, y: 0, w: 6, h: 2, static: false },
    [new Metric({ id: "ga:goalCompletionsAll", name: "Goal Completions" })],
  ),
  new Chart(ChannelType.ShopifyAds, "Products", "TABLE", { id: "sales", name: "Sales" },
    { x: 0, y: 6, w: 6, h: 2, static: false },
    [
      new Metric({ id: "orders", name: "Orders" }),
      new Metric({ id: "total_sales", name: "Total Sales" }),
      new Metric({ id: "gross_sales", name: "Gross Sales" }),
      new Metric({ id: "ordered_item_quantity", name: "Ordered Item Quantity" }),
      new Metric({ id: "returned_item_quantity", name: "Returned Item Quantity" }),
    ],
    null,
    [{ id: "product_title", name: "Product Title" }, { id: "variant_title", name: "Variant Title" }]
  ),
]

const allGrid = [
  new Grid(null, null, null, allCharts),
];

const tabs = [
  new Tab("Overall", 0, allGrid),
]

tabs.forEach(tab => tab.grids.forEach(grid => {
  grid.tabId = tab.id;
  grid.charts.forEach(chart => chart.gridId = grid.id);
}));

const allReport = new Report("template", "All Sources Report", tabs);
