import { Box, Button, Modal, Tooltip, Typography } from "@mui/material";
import ShareIcon from "../../Assets/Icons/share_icon.svg"
import InfoIcon from "../../Assets/Icons/info.svg"
import Info from "../../Assets/Icons/info_new.svg"
import CopyToClipboardButton from "../Common/Clicktocopy";
import ChipEmail from "../Common/CustomEmailInput";
import { handleMixPanelData, EVENTS } from "../../utils/mixpanelV2";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getShareId } from "../../api/report/saga";
import Loader from "../Common/Loader";
import { shareReport } from "../../api/report/action";

const ShareModal = (props) => {
    const { isopen, setIsOpen, report, reportCallback } = props;
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(true);
    const [shareData, setShareData] = useState({});
    const [emails, setEmails] = useState([]);

    const customStyles = {
        position: 'absolute',
        top: '50%',
        left: "53%",
        transform: 'translate(-50%, -50%)',
        width: "35.6%",
        // height: "calc(100% - 329px)",
        bgcolor: "#FFF",
        borderRadius: "12px",
        boxShadow: 24,
        padding: "24px"
    };
    const handleClose = () => setIsOpen(false);

    const shareReportOnEmails = () => {
        dispatch(shareReport({
            report, emails, sharedId: shareData.shared_id,
            loading: (msg, error) => {
                reportCallback({ message: msg, error, operation: "Sharing" });
            },
        }));
        handleMixPanelData(EVENTS.click_share_cta);
    }

    useEffect(() => {
        getShareId({ report_id: report.report_id }).then(data => {
            setShareData(data);
        }).catch(err => {
            console.log("~err ::", err)
        }).finally(() => {
            setLoader(false)
        })
    }, [])

    return (
        <div>
            <Modal
                open={isopen}
            >
                <Box sx={customStyles}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "start", marginBottom: "16px" }}>
                        <img src={ShareIcon} alt="" />
                        {/* <img src={Info} alt="" /> */}
                    </div>
                    <Tooltip title={report?.report_name} arrow>
                        <Typography style={{ fontFamily: "Inter", fontSize: "20px", fontWeight: "600", lineHeight: "32px", letterSpacing: "-0.3px", marginBottom: "24px" }}>Share {report?.report_name.length > 30 ? report?.report_name.substring(0, 30) + "..." : report?.report_name}</Typography>
                    </Tooltip>

                    {loader ? <Loader />
                        : <div>
                            <div style={{ marginBottom: "24px" }}>
                                <Typography style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "400", lineHeight: "22px", marginBottom: "" }}>Share Link
                                    <Tooltip title="This link is only valid for this month." arrow placement="right">
                                        <img src={InfoIcon} style={{ marginLeft: "6px" }} alt="" />
                                    </Tooltip>
                                </Typography>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ border: "1px solid #5C6674", borderRadius: "8px", padding: "9px 16px", width: "calc(100% - 56px)", height: "40px" }}>
                                        <Typography className="scrollBar0" style={{ fontSize: "14px", color: "#5C6674", whiteSpace: "nowrap" }}>
                                            {process.env.REACT_APP_BASE_URL + "/" + shareData.url}
                                        </Typography>

                                    </div>
                                    <CopyToClipboardButton shareUrl={process.env.REACT_APP_BASE_URL + "/" + shareData.url} />
                                </div>
                            </div>
                            <div>
                                <Typography style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "400", lineHeight: "22px", marginBottom: "" }}>Share Link via Email
                                    <Tooltip title="Once shared, the access from a user cannot be removed until the link expires." placement="right" arrow>
                                        <img src={InfoIcon} style={{ marginLeft: "6px" }} alt="" />

                                    </Tooltip>
                                </Typography>
                                <ChipEmail emails={emails} setEmails={setEmails} />
                            </div>

                            <div className="action_button">
                                <Button onClick={handleClose} disableRipple sx={{ width: "100%", height: "40px", background: "#FFF", color: "#394455", border: "1px solid #E0E3E5", borderRadius: "8px", fontFamily: "Inter", fontWeight: "600", fontSize: "14px" }}>Cancel</Button>
                                <Button disabled={emails.length === 0} disableRipple sx={{ width: "100%", height: "40px", background: emails.length === 0 ? "#eee" : "#0869FB", color: "#FFF", borderRadius: "8px", fontFamily: "Inter", fontWeight: "600", fontSize: "14px", "&:hover": { backgroundColor: emails.length === 0 ? "black" : "#1976d2" } }}
                                    onClick={() => {
                                        shareReportOnEmails();
                                    }}>Share</Button>

                            </div>
                        </div>
                    }
                </Box>
            </Modal>
        </div >
    )
}
export default ShareModal;