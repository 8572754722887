import { all, fork } from "redux-saga/effects";
import authSaga from "../api/auth/saga";
import channelSaga from "../api/channel/saga";
import dashboardSaga from "../api/dashboard/saga";
import userSaga from "../api/user/saga";
import reportSaga from "../api/report/saga";
import blendsSaga from '../api/blends/saga';
import filterSaga from '../api/filter/saga';
import extractorSaga from '../api/extractor/saga';
import { SupportedChannels } from "../models/channel";

export default function* root() {
	yield all([
		fork(authSaga),
		fork(channelSaga),
		fork(dashboardSaga),
		fork(userSaga),
		fork(reportSaga),
		fork(blendsSaga),
		fork(filterSaga),
		fork(extractorSaga)
	].concat(
		SupportedChannels.map(channel => fork(require(`../api/${channel.type}/saga`).default))
	));
}
