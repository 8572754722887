import { Grid, Tab } from "../../models/report";
import { Chart, Metric, Filter } from "../../models/chart";
import { channelType } from "./transformer";


//tables
const AccountPerformance = { id: "AccountPerformance", name: "AccountPerformance" };
const AdGroupPerformance={id:"AdGroupPerformance",name:"AdGroupPerformance"};
const AdPerformance={id:"AdPerformance",name:"AdPerformance"};
const CampaignPerformance={id:"CampaignPerformance",name:"CampaignPerformance"};

//metrics
const Impressions={id:"Impressions",name:"Absolute Top Impression Rate Percent"}
const Conversions={id:"Conversions",name:"Conversions"}
const Clicks = { id: "Clicks", name: "Clicks" };
const Ctr= {id:"Ctr",name:"Ctr"}
const AverageCpc={id:"AverageCpc",name:"Average CPC"}
const AllConversions={id:"AllConversions",name:"All Conversions"}
const CampaignName={id:"CampaignName",name:"Campaign name"}
const CostPerAssist={id:"CostPerAssist",name:"Cost Per Assist"}
const CostPerConversion={id:"CostPerConversion",name:"CostPerConversion"}
const ConversionRate={id:"ConversionRate",name:"ConversionRate"}
const AbsoluteTopImpressionRatePercent={id:"AbsoluteTopImpressionRatePercent",name:"Absolute Top Impression Rate Percent"}
const DeviceType={id:"DeviceType",name:"Device Type"}

//dimensions
const DATE = { id: "Date", name: "Date" };

const overallCharts = [
  new Chart(channelType, "Impressions vs Clicks", "LINE",AccountPerformance,
      { x: 0, y: 0, w: 6, h: 2, static: false },
      [new Metric(Impressions)],[new Metric(Ctr)]
  ),
  new Chart(channelType, "Conversions", "BAR",AccountPerformance,
      { x: 6, y: 0, w: 6, h: 2, static: false },
      [new Metric(Conversions)],[]
      [DATE]
  ),
  new Chart(channelType, "Average CPC", "KPI",AccountPerformance,
      { x: 0, y: 0, w: 3, h: 1, static: false },
      [new Metric(AverageCpc)]
  ),
  new Chart(channelType, "Click Through Rate", "KPI",AccountPerformance,
      { x: 3, y: 0, w: 3, h: 1, static: false },
      [new Metric(Ctr)]
      
  ),
  new Chart(channelType, "All Conversions", "KPI",AccountPerformance,
      { x: 6, y: 0, w: 3, h: 1, static: false },
      [new Metric(AllConversions)]
  ),
  new Chart(channelType, "Conversion Rate", "KPI",AccountPerformance,
      { x: 9, y: 0, w: 3, h: 1, static: false },
      [new Metric(ConversionRate)]
  ),
  new Chart(channelType, "Campaign Performance", "TABLE",AdPerformance,
      { x: 0, y: 0, w: 6, h: 2, static: false },
      [new Metric(Impressions),new Metric(Clicks),new Metric(AverageCpc),new Metric(Ctr)],[],
      [CampaignName]
  ),
  new Chart(channelType, "Account Funnel", "FUNNEL",AdPerformance,
      { x: 6, y: 0, w: 6, h: 2, static: false },
      [new Metric(Impressions),new Metric(Clicks),new Metric(Ctr)]
  ),
  new Chart(channelType, "Device Type", "COLUMN",AdGroupPerformance,
      { x: 0, y: 0, w: 6, h: 2, static: false },
      [new Metric(Impressions),new Metric(Clicks)],[],
      [{id:"DeviceType",name:"Device Type"}]
  ),
  new Chart(channelType, "Ad Group Performance", "TABLE",AdPerformance,
      { x: 6, y: 0, w: 6, h: 2, static: false },
      [new Metric(Impressions),new Metric(Clicks),new Metric(Conversions)],[new Metric(Ctr)],
      [{id:"AdGroupName",name:"AdGroupName"}]
  ),
]; 

const overallGrid = [
  new Grid(null, null, null, overallCharts),
];



export default [
  new Tab("Overall", 0, overallGrid),
];
