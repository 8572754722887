import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import LeftBar from "../../LeftBar/LeftBar";
import BlendDataSource from "./BlendDataSource";
import { handleMixPanelData, EVENTS } from "../../../utils/mixpanelV2";

const DataBlending = () => {
    useEffect(() => {
       handleMixPanelData(EVENTS.view_blends_page);
    },[]);
    return (
        <div>
            <LeftBar />
            <Box sx={{ width: "100%", padding: "24px 24px 24px 124px", height: "100vh", overflow: "hidden !important" }}>
                <BlendDataSource />
            </Box>
        </div>
    )
}

export default DataBlending;