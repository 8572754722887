export const SHARE_REPORT = "SHARE_REPORT";
export const SHARE_REPORT_SUCCESS = "SHARE_REPORT_SUCCESS";
export const SHARE_REPORT_FAILURE = "SHARE_REPORT_FAILURE";
export const GET_SHARED_REPORT = "GET_SHARED_REPORT";
export const GET_SHARED_REPORT_SUCCESS = "GET_SHARED_REPORT_SUCCESS";
export const GET_SHARED_REPORT_FAILURE = "GET_SHARED_REPORT_FAILURE";

export const CREATE_REPORT = "CREATE_REPORT";
export const UPDATE_REPORT = "UPDATE_REPORT";
export const UPDATE_REPORT_DATA = "UPDATE_REPORT_DATA";
export const ADD_REPORT_DATA = "ADD_REPORT_DATA";
export const DELETE_REPORT = "DELETE_REPORT";
export const CREATE_REPORT_SUCCESS = "CREATE_REPORT_SUCCESS";
export const CREATE_REPORT_FAILURE = "CREATE_REPORT_FAILURE";
export const UPDATE_REPORT_SUCCESS = "UPDATE_REPORT_SUCCESS";
export const UPDATE_REPORT_FAILURE = "UPDATE_REPORT_FAILURE";
export const DELETE_REPORT_SUCCESS = "DELETE_REPORT_SUCCESS";
export const DELETE_REPORT_FAILURE = "DELETE_REPORT_FAILURE";
export const UPDATE_REPORT_DATE_RANGE = "UPDATE_REPORT_DATE_RANGE";
export const UPDATE_REPORT_AD_ACC_COLS = "UPDATE_REPORT_AD_ACC_COLS";

export const GET_ALL_REPORTS = "GET_ALL_REPORTS";
export const GET_ALL_REPORTS_SUCCESS = "GET_ALL_REPORTS_SUCCESS";
export const GET_ALL_REPORTS_FAILURE = "GET_ALL_REPORTS_FAILURE";

export const GET_DEFAULT_REPORT = "GET_DEFAULT_REPORT"
export const GET_DEFAULT_REPORT_SUCCESS = "GET_DEFAULT_REPORT_SUCCESS"
export const GET_DEFAULT_REPORT_FAILURE = "GET_DEFAULT_REPORT_FAILURE"

export const SET_DEFAULT_REPORT = "SET_DEFAULT_REPORT"
export const SET_DEFAULT_REPORT_SUCCESS = "SET_DEFAULT_REPORT_SUCCESS"
export const SET_DEFAULT_REPORT_FAILURE = "SET_DEFAULT_REPORT_FAILURE"

export const GET_REPORT = "GET_REPORT";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_FAILURE = "GET_REPORT_FAILURE";
export const GET_REPORT_DOWNLOADED_SUCCESS = "GET_REPORT_DOWNLOADED_SUCCESS";

export const GET_TAB_DETAILS = "GET_TAB_DETAILS";
export const GET_TAB_SUCCESS = "GET_TAB_SUCCESS";
export const GET_TAB_FAILURE = "GET_TAB_FAILURE";

export const UPDATE_CHART = "UPDATE_CHART";
export const UPDATE_CHART_LOCAL = "UPDATE_CHART_LOCAL";
export const UPDATE_CHART_SUCCESS = "UPDATE_CHART_SUCCESS";
export const UPDATE_CHART_FAILURE = "UPDATE_CHART_FAILURE";

export const UPDATE_GRID = "UPDATE_GRID";
export const UPDATE_GRID_LOCAL = "UPDATE_GRID_LOCAL";
export const UPDATE_GRID_SUCCESS = "UPDATE_GRID_SUCCESS";

export const UPDATE_TAB = "UPDATE_TAB";
export const UPDATE_TAB_LOCAL = "UPDATE_TAB_LOCAL";
export const UPDATE_TAB_SUCCESS = "UPDATE_TAB_SUCCESS";

export const DUPLICATE_REPORT = "DUPLICATE_REPORT";
export const DUPLICATE_REPORT_SUCCESS = "DUPLICATE_REPORT_SUCCESS"
export const DUPLICATE_REPORT_FAILURE = "DUPLICATE_REPORT_FAILURE"
export const DUPLICATE_TAB_SUCCESS = "DUPLICATE_TAB_SUCCESS"
export const DUPLICATE_TAB_FAILURE = "DUPLICATE_TAB_FAILURE";

export const CLEAR_REPORT_UPDATES = "CLEAR_REPORT_UPDATES";
export const MAINTAIN_REPORT_UPDATES = "MAINTAIN_REPORT_UPDATES";
