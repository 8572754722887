export const CHECK_AUTH = "CHECK_AUTH";
export const CHECK_AUTH_SUCCESS = "CHECK_AUTH_SUCCESS";
export const CHECK_AUTH_FAILURE = "CHECK_AUTH_FAILURE";

export const SELECT_ACCOUNT = "SELECT_ACCOUNT";

export const GET_ALL_CUSTOM_COLUMNS = "GET_ALL_CUSTOM_COLUMNS"
export const GET_ALL_COLUMNS_SUCCESS = "GET_ALL_COLUMNS_SUCCESS"
export const GET_ALL_COLUMNS_FAILURE = "GET_ALL_COLUMNS_FAILURE"

export const CREATE_COLUMN = "CREATE_COLUMN"
export const CREATE_COLUMN_SUCCESS = "CREATE_COLUMN_SUCCESS"
export const CREATE_COLUMN_FAILURE = "CREATE_COLUMN_FAILURE"

export const UPDATE_COLUMN = "UPDATE_COLUMN"
export const UPDATE_COLUMN_SUCCESS = "UPDATE_COLUMN_SUCCESS"
export const UPDATE_COLUMN_FAILURE = "UPDATE_COLUMN_FAILURE"

export const DELETE_COLUMN = "DELETE_COLUMN"
export const DELETE_COLUMN_SUCCESS = "DELETE_COLUMN_SUCCESS"
export const DELETE_COLUMN_FAILURE = "DELETE_COLUMN_FAILURE"

export const GET_ALL_CUSTOM_DIMENSIONS = "GET_ALL_CUSTOM_DIMENSIONS"
export const GET_ALL_DIMENSIONS_SUCCESS = "GET_ALL_DIMENSIONS_SUCCESS"
export const GET_ALL_DIMENSIONS_FAILURE = "GET_ALL_DIMENSIONS_FAILURE"

export const SAVE_DIMENSION = "SAVE_DIMENSION"
export const SAVE_DIMENSION_SUCCESS = "SAVE_DIMENSION_SUCCESS"
export const SAVE_DIMENSION_FAILURE = "SAVE_DIMENSION_FAILURE"

export const DELETE_DIMENSION = "DELETE_DIMENSION"
export const DELETE_DIMENSION_SUCCESS = "DELETE_DIMENSION_SUCCESS"
export const DELETE_DIMENSION_FAILURE = "DELETE_DIMENSION_FAILURE"

export const IS_SAVE_AND_UPDATE_BUTTON_CLICK = "IS_SAVE_AND_UPDATE_BUTTON_CLICK"