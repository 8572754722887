import * as React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import Extractor from "./Extractor/Extractor";
import Triggers from "./Triggers/Triggers";
import Logs from "./Logs/Logs";
import { checkEmailConnected } from "../../api/extractor/action";
import { getUser, updateUser } from "../../api/user/action";
import { connect } from "react-redux";
import { transformFromSuperMetricDataObject } from "./Utils";
import SuccessModal from "../Modal/SuccessModal";
import FailureModal from "../Modal/FailureModal";
import mixpanelObj, { EVENTS } from "../../utils/mixpanel";
import { Tab, Typography, IconButton, Tooltip } from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RemoveModal from "../Modal/RemoveModal";
import ReRunModal from "../Modal/ReRunModal";
import { ReactComponent as IconCheck } from "../../Assets/Icons/check-circle.svg";
import { ReactComponent as CloseCircleIcon } from "../../Assets/Icons/close-circle-icon.svg";
import moment from "moment";
import { useHistory } from "react-router-dom";

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ height: "100%" }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const NavigationPane = ({
  user,
  getUser,
  updateUser,
  checkEmailConnected,
}) => {
  const [value, setValue] = React.useState(0);
  const [triggerId, setTriggerId] = useState("");
  const [extractData, setExtractData] = useState({});
  const [reRunModal, setReRunModal] = useState({ id: "", name: "", logId: "", open: false, fromDate: "", toDate: "" })
  const [completion, setCompletion] = useState({
    message: "none",
    operation: "none",
  });
  const history = useHistory();
  const handleChange = (
    event,
    newValue,
    triggerId = "",
    extractData,
    isDuplicate
  ) => {
    setValue(newValue);
    setTriggerId(triggerId);
    setExtractData(
      extractData
        ? transformFromSuperMetricDataObject(extractData, isDuplicate)
        : {}
    );
    if (newValue === 2 && triggerId) {
      history.push(`/extractor?=${triggerId}`);
    } else {
      history.push(`/extractor`);
    }
  };

  const email_connected = useSelector(
    (state) => state.extractor.email_connected
  ); // Navigation
  const [removeAccountModal, setRemoveAccountModal] = useState({
    open: false,
    removeId: "",
  }); // navigation pane

  const formatDate = (date) => moment(date).format("YYYY-MM-DD");
  const [reRunDateRange, setReRunDateRange] = useState({
    dateType: "",
    fromDate: null,
    toDate: null,
  }
  );
  const updateReRunDateRange = (date) => {
    setReRunDateRange({
      dateType: date.label ?? "Custom",
      fromDate: date?.startDate ? formatDate(date.startDate) : null,
      toDate: date?.endDate ? formatDate(date.endDate) : null,
    });
  };

  useEffect(() => {
    getUser();
    if (!email_connected) {
      checkEmailConnected();
    }
  }, []);

  useEffect(() => {
    if(value === 0){
      email_connected?.length > 0 && mixpanelObj.track(EVENTS.view_extractor, {}, true);
    }
  }, [email_connected,value]);
  return (
    <>
      <Box sx={{ width: "100%", padding: "24px 24px 10px 124px", height: "100vh" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            background: "#FFFFFF",
            borderRadius: "12px",
            // height: "56px",
            padding: "0px 8px",
            position: "relative",
            boxShadow: "12px 12px 48px 0px rgba(80, 80, 159, 0.04)",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            className='tab-Bar'
          >
            <Tab label="Extractor" {...a11yProps(0)} 
              onClick={ () => mixpanelObj.track(EVENTS.view_extractor, {}, true)}
            />
            <Tab
              label="Triggers"
              {...a11yProps(1)}
              onClick={() =>
                mixpanelObj.track(EVENTS.view_all_triggers, {}, true)
              }
            />
            <Tab
              label="Logs"
              {...a11yProps(2)}
              onClick={() => mixpanelObj.track(EVENTS.click_logs, {}, true)}
            />
          </Tabs>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
          <div style={{ marginRight: "12px" }}>
            {email_connected && <span className="email" style={{ color: "#999999", fontWeight: 500 }}>{"Connected Account"}</span>}
            <span className="email" style={{ color: "#000000", fontWeight: 500 }}>
              {email_connected ? (
                <>
                  <IconCheck style={{ marginLeft: "2px" }} /> {email_connected}
                </>
              ) : (
                <></>
              )}
            </span>
          </div>
          {email_connected && user.role === "admin" && (
            <Tooltip title="Remove Account" placement="top">
              <IconButton
                style={{ padding: 0, marginRight: "15px" }}
                onClick={() => {
                  setRemoveAccountModal({
                    open: true,
                    msg: "This connected account will be removed from Pixis analytics tool that you are in.",
                  });
                }}
              >
                <CloseCircleIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <TabPanel value={value} index={0} style={{ marginTop: "24px", height: "calc(100% - 112px)" }} >
          <Extractor
            updateId={triggerId}
            extractData={extractData}
            setExtractData={setExtractData}
            setTriggerId={setTriggerId}
            setCompletion={setCompletion}
          />
        </TabPanel>
        <TabPanel value={value} index={1} style={{ marginTop: "24px" }}>
          <Triggers redirect={handleChange} setCompletion={setCompletion} />
        </TabPanel>
        <TabPanel value={value} index={2} style={{ marginTop: "24px" }}>
          <Logs triggerId={triggerId} setReRunModal={setReRunModal} />
        </TabPanel>
      </Box>
      <SuccessModal
        modal={completion.message === "Success"}
        setModal={() => setCompletion({ message: "none", operation: "none" })}
        message={`${completion.operation} of Trigger ${completion.operation === "Re-Run" ? "started Successfully" : " is Successful"}`}
      />
      <FailureModal
        modal={completion.message === "Fail"}
        setModal={() => setCompletion({ message: "none", operation: "none" })}
        message={`${completion.operation} of Trigger Failed`}
      />
      <RemoveModal
        type="Account"
        modal={removeAccountModal}
        onClose={() => setRemoveAccountModal({ open: false, removeId: "" })}
      />{" "}
      <ReRunModal
        modal={reRunModal}
        onClose={() => {
          setReRunModal({ id: "", name: "", logId: "", open: false, fromDate: "", toDate: "" })
          updateReRunDateRange({
            label: "",
            startDate: null,
            endDate: null,
          })
        }}
        reRunDateRange={reRunDateRange}
        updateReRunDateRange={updateReRunDateRange}
        setCompletion={setCompletion}
      />
      {/* Navigation */}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  getUser: () => dispatch(getUser()),
  updateUser: (_) => dispatch(updateUser(_)),
  checkEmailConnected: (_) => dispatch(checkEmailConnected(_)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavigationPane);
