import * as React from 'react';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

export default function CustomTimePicker({ time, onChange,disabled=false }) {
    const date = new Date();
    date.setHours(time.hour);
    date.setMinutes(time.min);
    const [value, setValue] = React.useState(dayjs(date));

    React.useEffect(() => {
        onChange({ hour: value.$H, min: value.$m })
    }, [value])

    return (
        <div className="metric-list-form" style={{ width: "95%", marginTop: "10px", pointerEvents: disabled ? "none" : "all"}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker
                    sx={{
                        maxHeight: "40px", width: "100%",
                        "& input": { height: "1px" },
                    }}
                    disabled={disabled}
                    value={value}
                    onChange={(newValue) => { setValue(newValue); }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        </div>
    );
}