import { CHANGE_COMPARE_DATE, CHANGE_DATE, UPDATE_FILTER_CHART_MAP } from "./type";

export const changeDate = payload => {
    return {
        type: CHANGE_DATE,
        payload: payload,
    };
};

export const changeCompareDate = payload => {
    return {
        type: CHANGE_COMPARE_DATE,
        payload: payload,
    };
};

export const updateFilterChartMap = payload => {
    return {
        type: UPDATE_FILTER_CHART_MAP,
        payload: payload
    }
}