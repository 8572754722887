import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../Assets/loaders/chartLoader.json';

export default function Loader({ height = 200, width = 200 }) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center" }}>
            <Lottie
                options={defaultOptions}
                height={height}
                width={width}
            />
        </div>
    );
}