import { all, put, select, takeLatest } from "redux-saga/effects"
import {
    DELETE_TRIGGER, DELETE_TRIGGER_SUCCESS, DELETE_TRIGGER_FAILURE,
    GET_ALL_TRIGGERS, GET_ALL_TRIGGERS_FAILURE, GET_ALL_TRIGGERS_SUCCESS,
    EMAIL_FAILURE, EMAIL_SUCCESS, CHECK_EMAIL, DISCONNECT_EMAIL, AUTHORIZE_EMAIL,
    SHEETS_SUCCESS, SHEETS_FAILURE, GET_SHEET_URLS,
    CREATE_TRIGGER_SUCCESS, CREATE_TRIGGER_FAILURE, CREATE_TRIGGER,
    RUN_TRIGGER, RUN_TRIGGER_FAILURE, RUN_TRIGGER_SUCCESS,
    UPDATE_TRIGGER_SUCCESS, UPDATE_TRIGGER_FAILURE, UPDATE_TRIGGER,UPDATE_BULK_TRIGGER
} from "./type";
import { api } from ".."
import { UPDATE_LOADER } from "../user/type";
import { EVENTS, handleMixPanelData  } from "../../utils/mixpanelV2";

function* checkEmailConnected() {
    try {
        yield put({
            type: UPDATE_LOADER,
            payload: true
        });
        const response = yield api.post(`/v2/analytics/supermetrics/google/isAuthorised`);
        yield put({
            type: EMAIL_SUCCESS,
            payload: response.data
        });
        yield put({
            type: UPDATE_LOADER,
            payload: false
        });
    } catch (error) {
        yield put({
            type: EMAIL_FAILURE
        });
        yield put({
            type: UPDATE_LOADER,
            payload: false
        });
    }
}

function* disconnectAccount() {
    try {
        yield api.post(`/v2/analytics/supermetrics/google/removeAuth`);
        yield put({
            type: EMAIL_SUCCESS,
            payload: { email_connected: "" }
        });
    } catch (error) {
        yield put({
            type: EMAIL_FAILURE
        });
    }
}

function* getRedirectUrl(args) {
    try {
        const response = yield api.post("/v2/analytics/supermetrics/google/getUrl", {
            success_redirect_url: "extractor" + `?google=success`,
            failure_redirect_url: "extractor" + `?google=failure`,
        })
        yield window.location.href = response.data.authorizationUrl;
    } catch (error) {
        yield put({
            type: EMAIL_FAILURE,
        });
    }
}

function* getSheetUrls(args) {
    try {
        const response = yield api.post("/v2/analytics/supermetrics/google/getSheets");
        yield put({
            type: SHEETS_SUCCESS,
            payload: response.data.msg
        });
    } catch (error) {
        yield put({
            type: SHEETS_FAILURE,
        });
    }
}

function* createTrigger(args) {
    try {
        const trigger = args.payload.trigger;
        handleMixPanelData(EVENTS.create_trigger, trigger);
        const response = yield api.post("/v2/analytics/supermetrics/create", {
            triggerObj: trigger
        });
        const id = response.data.id;
        yield put({
            type: CREATE_TRIGGER_SUCCESS,
            payload: {
                id: id,
                trigger,
            }
        });
        yield getAllTriggers();
        yield args.payload.loading("Success");
        handleMixPanelData(EVENTS.success_create_triggers, trigger);
    } catch (error) {
        yield put({
            type: CREATE_TRIGGER_FAILURE
        });
        yield args.payload.loading("Fail");
    }
}

function* updateTrigger(args) {
    try {
        const triggerId = args.payload.triggerId;
        const triggerObj = args.payload.triggerObj;
        const response = yield api.post(`/v2/analytics/supermetrics/update/${triggerId}`, {
            triggerObj
        });
        yield put({
            type: UPDATE_TRIGGER_SUCCESS,
            payload: {
                id: triggerId,
                trigger: triggerObj,
            }
        });
        yield getAllTriggers();
        yield args.payload.loading("Success");
        handleMixPanelData(EVENTS.update_trigger, triggerObj);
    } catch (error) {
        yield put({
            type: UPDATE_TRIGGER_FAILURE
        });
        yield args.payload.loading("Fail");
    }
}

function* deleteTrigger(args) {
    try {
        const triggerId = args.payload.triggerId;
        yield api.post(`/v2/analytics/supermetrics/delete/${triggerId}`);
        yield put({
            type: DELETE_TRIGGER_SUCCESS,
            payload: { id: triggerId }
        });
        yield getAllTriggers();
        yield args.payload.loading("Success");
        handleMixPanelData(EVENTS.delete_trigger, { name: args.payload.trigger_name });
    } catch (error) {
        yield put({
            type: DELETE_TRIGGER_FAILURE
        });
        yield args.payload.loading("Fail");
    }
}

function* runTrigger(args) {
    try {
        const triggerId = args.payload.triggerId;
        yield api.post(`/v2/analytics/supermetrics/trigger-run/${triggerId}`, {
            triggerRunId: args.payload.logId,
            runFromData: args.payload.runFromData,
            runToDate: args.payload.runToDate
        });
        yield put({
            type: RUN_TRIGGER_SUCCESS,
        });
        yield args.payload.loading("Success");
    } catch (error) {
        yield put({
            type: RUN_TRIGGER_FAILURE
        });
        yield args.payload.loading("Fail");
    }
}

function* getAllTriggers(args) {
    try {
        if (args?.payload?.loading) {
            yield put({
                type: UPDATE_LOADER,
                payload: true
            });
        }
        const { current_page,limit_per_page,search_text,status } = args.payload;
        const payload = args?.payload?.isBulkUpdate ? { current_page: 0, limit_per_page: -1, search_text: search_text , status: "ACTIVE" } : 
                            { current_page, limit_per_page, search_text, status: status };
        const response = yield api.post("/v2/analytics/supermetrics/list",{ ...payload });
        const triggers = response.data.response;
        const total = response.data.total;
        yield put({
            type: GET_ALL_TRIGGERS_SUCCESS,
            payload: { triggers,total }
        });
        args?.payload?.isBulkUpdate && args.payload.callBack(triggers);
    } catch (error) {
        yield put({
            type: GET_ALL_TRIGGERS_FAILURE
        });
    }
    if (args?.payload?.loading) {
        yield put({
            type: UPDATE_LOADER,
            payload: false
        });
    }
}

export const getAllLogs = (triggerId = "",payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post(`/v2/analytics/supermetrics/runs/${triggerId}`,{...payload});
        resolve(response.data.response);
    } catch (error) {
        reject(error);
    }
});

function* updateBulkTrigger(args){
    try{
        yield api.post(`/api/supermetrics/bulk-trigger-updation`,{ ...args.payload.payload });
        if (args?.payload?.loading) {
            yield put({
                type: UPDATE_LOADER,
                payload: false
            });
            args.payload.setBulkSuccessModal(true);
        }
    }catch(err){
        if (args?.payload?.loading) {
            yield put({
                type: UPDATE_LOADER,
                payload: false
            });
        }
    }
}

export default function* root() {
    yield all([
        takeLatest(CHECK_EMAIL, checkEmailConnected),
        takeLatest(DISCONNECT_EMAIL, disconnectAccount),
        takeLatest(AUTHORIZE_EMAIL, getRedirectUrl),
        takeLatest(GET_SHEET_URLS, getSheetUrls),
        takeLatest(CREATE_TRIGGER, createTrigger),
        takeLatest(UPDATE_TRIGGER, updateTrigger),
        takeLatest(DELETE_TRIGGER, deleteTrigger),
        takeLatest(GET_ALL_TRIGGERS, getAllTriggers),
        takeLatest(RUN_TRIGGER, runTrigger),
        takeLatest(UPDATE_BULK_TRIGGER, updateBulkTrigger)
    ])
}