import { useState, useEffect } from "react";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Box, Button, IconButton, Modal, Grid, Switch } from '@mui/material';
import { Close } from '@mui/icons-material';
import "../styles/profile.css";
import { useDispatch } from "react-redux";
import { deleteTrigger, disconnectAccount } from "../../api/extractor/action";
import { updateLoader } from "../../api/user/action";

const RemoveModal = (props) => {
    const { type = "Trigger", modal, onClose, setCompletion } = props;
    const [isError, setError] = useState(false);
    const [errorMessageOrSuccess, setErrorMessageOrSuccess] = useState("");
    const [isUserSelectedBox, setUserSelectedBox] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        setError(false);
        setErrorMessageOrSuccess("");
        setUserSelectedBox(false);
    }, [modal]);

    useEffect(() => {
        setError(false);
        setErrorMessageOrSuccess("");
    }, [isUserSelectedBox])

    const remove = () => {
        if (isUserSelectedBox) {
            switch (type) {
                case "Trigger":
                    dispatch(updateLoader(true));
                    dispatch(deleteTrigger({
                        triggerId: modal.removeId,
                        loading: (msg) => {
                            dispatch(updateLoader(false));
                            setCompletion({ message: msg, operation: "Deletion" });
                        },
                        trigger_name: modal.trigger_name
                    }));
                    break;
                case "Account":
                    dispatch(disconnectAccount());
                    break;
                default:
                    break;
            }
            onClose();
        } else {
            setError(true);
            setErrorMessageOrSuccess("Please click on check box first to confirm deletion!");
        }
    }

    return (
        <Modal
            open={modal.open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box className="remove-trigger-modal">
                <div className="header">
                    <div>
                        <b>Remove {type}</b>
                    </div>
                    <div>
                        <IconButton
                            onClick={onClose}
                            aria-label="save"
                            sx={{
                                borderRadius: '4px',
                                padding: '2px',
                                backgroundColor: '#F2F2F2',
                                fontSize: '18px',

                            }}
                            size="small"
                        >
                            <Close />
                        </IconButton>
                    </div>
                </div>
                <div className="content">
                    <p>
                        {modal.msg || ""}
                    </p>
                    <p>
                        Do you really want to proceed?
                    </p>
                    <p>
                        <FormControlLabel
                            value="end"
                            control={<Checkbox />}
                            label={`Check this box to confirm this ${type} removal`}
                            labelPlacement="end"
                            onClick={(e) => setUserSelectedBox(e.target.checked)}
                        />
                    </p>
                    <p style={{ color: isError ? "red" : "green" }}>{errorMessageOrSuccess}</p>
                </div>
                <div className="footer">
                    <Button sx={{ marginRight: "10px" }} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button onClick={() => remove()} variant="contained">
                        Remove {type}
                    </Button>
                </div>
            </Box>

        </Modal >
    )
}
export default RemoveModal;