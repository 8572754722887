import { channelType } from "./transformer";

const INITIAL_STATE = {
    clientId: "",
    isConnecting: false,
    accountData: {},
    dimensions: [],
    metrics: [],
    currency: null,
    metricsUpdated: false,
};


const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case `${channelType}.GET_CLIENT_ID_SUCCESS`:
            return { ...state, clientId: action.payload.clientId };
        case `${channelType}.GET_CLIENT_ID_FAILURE`:
            return { ...state, clientId: "" };
        case `${channelType}.SHOW_CONNECT_ACCOUNT`:
            return { ...state, isConnecting: true };
        case `${channelType}.HIDE_CONNECT_ACCOUNT`:
            return { ...state, isConnecting: false };
        case `${channelType}.GET_ACCOUNT_SUMMARY_SUCCESS`:
            const username = action.payload.accessTokenName;
            const email = action.payload.accessTokenName;
            const accountList = [];
            action.payload.listOfAppIds.forEach((account) => {
                accountList.push({
                    title: account.name,
                    subtitle: account.id,
                    accountId: account.id
                });
            })
            return { ...state, accountData: { users: [{ username: username, email: email }], accountList: accountList } };
        case `${channelType}.START_LOADING`:
            return { ...state, accountData: { ...state.accountData, loading: true } };
        case `${channelType}.DISCONNECT_ACCOUNT_SUCCESS`:
        case `${channelType}.GET_ACCOUNT_SUMMARY_FAILURE`:
            return { ...INITIAL_STATE, clientId: state.clientId };
        case `${channelType}.GET_COLUMNS_SUCCESS`:
            const metrics = { ...state.metrics };
            const dimensions = { ...state.dimensions };
            metrics[action.payload.tableName] = action.payload.data.columns.filter(c => c.type === "METRIC");
            dimensions[action.payload.tableName] = action.payload.data.columns.filter(c => c.type === "DIMENSION");
            return { ...state, metrics: { ...metrics }, dimensions: { ...dimensions } };
        case `${channelType}.GET_FIELDS_SUCCESS`:
            const dimensionArr = action.payload.columns.filter(c => c.type === "DIMENSION");
            const metricArr = action.payload.columns.filter(c => c.type === "METRIC");
            return { ...state, dimensions: dimensionArr, metrics: metricArr, metricsUpdated: true };
        case `${channelType}.GET_CURRENCY_SUCCESS`:
            return { ...state, currency: action.payload }
        default:
            return state;
    }
};

export default reducer;
