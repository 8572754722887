import { ChannelType } from "../../models/channel";

export const channelType = ChannelType.Adjust;

export function getAccountId(account) {
    return { appId: account?.accountId };
}

export function getFilterObject(filter) {
    if (filter.id) {
        return {
            filterGrouping: {
                filterId: filter.id
            }
        }
    }
    const filterArray = [...filter.dimensionFilters];
    const newFilter = [];
    filterArray.forEach((f) => {
        if (f.filter.filterable) {
            newFilter.push({
                fieldName: f.filter.id,
                values: f.value.split(",")
            })
        }
    });

    return {
        filters: newFilter
    };
}

export function getApiObject(metrics, dimensions, filter, table) {
    return {
        metrics: metrics,
        dimensions: dimensions,
        ...getFilterObject(filter),
    };
}

const convertFloats = ["Ctr"]

export function transformData(data) {
    [...data.leftMetrics, ...(data.rightMetrics ?? [])].forEach((m) => {
        convertFloats.find((f) => {
            if (f === m.id) {
                data.data.forEach((d) => {
                    const floatValue = parseFloat(d[m.name].split("%")[0]);
                    d[m.name] = floatValue;
                });
            }
        });
    });
}

export const properties = {
    dateDimension: { id: "Date", name: "Date" },
    filterOperators: [
        { label: "Equal", type: "EQUALS", dataType: ["CURRENCY", "INTEGER", "STRING", "ENUM"] },
        { label: "Not Equal", type: "NOT_EQUALS", dataType: ["CURRENCY", "INTEGER", "STRING", "ENUM"] },
        { label: "Greater Than", type: "GREATER_THAN", dataType: ["CURRENCY", "INTEGER", "FLOAT"] },
        { label: "Greater Than or Equal", type: "GREATER_THAN_OR_EQUAL", dataType: ["CURRENCY", "INTEGER", "FLOAT"] },
        { label: "Less Than", type: "LESS_THAN", dataType: ["CURRENCY", "INTEGER", "FLOAT"] },
        { label: "Less Than or Equal", type: "LESS_THAN_OR_EQUAL", dataType: ["CURRENCY", "INTEGER", "FLOAT"] },
        { label: "Contains", type: "CONTAINS", dataType: "STRING" },
        { label: "Not Contains", type: "NOT_CONTAINS", dataType: "STRING" },
        { label: "In", type: "IN_LIST", dataType: ["STRING", "ENUM"] },
        { label: "Not In", type: "NOT_IN_LIST", dataType: ["STRING", "ENUM"] },
        { label: "Starts With", type: "STARTS_WITH", dataType: "STRING" },
        { label: "Ends With", type: "ENDS_WITH", dataType: "STRING" }
        // { label: "In", type: "IN", dataType: ["ENUM"] },
    ],
    // validFilterBoolOperators: []
}