import moment from "moment";

const mapDateStrings = {
  start_date: "startDate",
  end_date: "endDate",
  startDate: "start_date",
  endDate: "end_date",
  DAY: "Days",
  WEEK: "Weeks",
  MONTH: "Months",
  YEAR: "Years",
  Days: "DAY",
  Weeks: "WEEK",
  Months: "MONTH",
  Years: "YEAR",
};

// previous period

const today = new Date();
var first = today.getDate() - today.getDay() + 1;

const formatDate = (date) =>
  date ? moment(date).format("YYYY-MM-DD") : "YYYY-MM-DD";

const labelDateMapping = {
  Today: {
    period: "DAY",
    unit: 0,
  },
  Yesterday: {
    period: "DAY",
    unit: 1,
  },
  "This Week": {
    period: "WEEK",
    unit: 0,
  },
  "This Month": {
    period: "MONTH",
    unit: 0,
  },
  "This Year": {
    period: "YEAR",
    unit: 0,
  },
};

const dateLabelMapping = {
  DAY: {
    0: { label: "Today", startDate: formatDate(today), endDate: formatDate(today) },
    1: {
      label: "Yesterday",
      startDate: formatDate(new Date(new Date().setDate(today.getDate() - 1))),
      endDate: formatDate(new Date(new Date().setDate(today.getDate() - 1))),
    },
  },
  WEEK: {
    0: {
      startDate: formatDate(new Date().setDate(first)),
      endDate:
        new Date().setDate(today.getDate() - 1) < new Date().setDate(first)
          ? formatDate(new Date().setDate(first))
          : formatDate(new Date().setDate(today.getDate() - 1)),
      label: "This Week",
    },
  },
  MONTH: {
    0: {
      startDate: formatDate(new Date(today.getFullYear(), today.getMonth(), 1)),
      endDate:
      new Date().setDate(today.getDate() - 1) <
        new Date(today.getFullYear(), today.getMonth(), 1)
          ? formatDate(new Date(today.getFullYear(), today.getMonth(), 1))
          : formatDate(new Date().setDate(today.getDate() - 1)),
      label: "This Month",
    },
  },
  YEAR: {
    0: {
      startDate: formatDate(new Date(today.getFullYear(), 0, 1)),
      endDate:
      new Date().setDate(today.getDate() - 1) <
        new Date(today.getFullYear(), 0, 1)
          ? formatDate(new Date(today.getFullYear(), 0, 1))
          : formatDate(new Date().setDate(today.getDate() - 1)),
      label: "This Year",
    },
  },
};

const lastWeekStartDate = (count) => {
  const startDate = new Date(moment().subtract(count, "week").startOf("week"));
  return startDate.setDate(startDate.getDate() + 1);
};
const lastWeekEndDate = () => {
  const endDate = new Date(moment().subtract(1, "week").endOf("week"));
  return endDate.setDate(endDate.getDate() + 1);
};

const handleCustomDate = (type, count, isTillTodaySelected) => {
  let dateObj = {};
  switch (type) {
    default:
    case "Days":
      dateObj = {
        label: `Last ${count} Days`,
        startDate: formatDate(new Date(new Date().setDate(today.getDate() - count))),
        endDate: formatDate(new Date(new Date().setDate(today.getDate() - 1))),
      };
      break;
    case "Months":
      dateObj = {
        label: `Last ${count} Months`,
        startDate: formatDate(new Date(today.getFullYear(), today.getMonth() - count, 1)),
        endDate: isTillTodaySelected
          ? tillToday()
          : formatDate(new Date(today.getFullYear(), today.getMonth(), 0)),
      };
      break;
    case "Years":
      dateObj = {
        label: `Last ${count} Years`,
        startDate: formatDate(new Date(today.getFullYear() - count, 0, 1)),
        endDate: isTillTodaySelected
          ? tillToday()
          : formatDate(new Date(today.getFullYear() - 1, 11, 31)),
      };
      break;
    case "Weeks":
      var startDate = lastWeekStartDate(count);
      var endDate = lastWeekEndDate();
      dateObj = {
        label: `Last ${count} Weeks`,
        startDate: formatDate(startDate),
        endDate: isTillTodaySelected ? tillToday() : formatDate(endDate),
      };
  }
  isTillTodaySelected && !dateObj?.label?.toLowerCase()?.includes("including today")
    ? (dateObj = { ...dateObj, label: dateObj?.label + " Including Today", endDate: tillToday() })
    : {};
  return dateObj;
};


const handleCompareCustomDate = (previous, type, count, currentDate ) => {
  if(previous){
    let diffTime = Math.floor(Math.abs((new Date(currentDate?.endDate) - new Date(currentDate?.startDate)) / (1000 * 60 * 60 * 24))) + 1;
    return {
        startDate: formatDate(new Date(new Date(currentDate?.startDate).setDate(new Date(currentDate?.startDate).getDate() - diffTime))),
        endDate: formatDate(new Date(new Date(currentDate?.endDate).setDate(new Date(currentDate?.endDate).getDate() - diffTime))),
        label: "Previous Period"
      }
  }
  else{
    let startDate = new Date(currentDate?.startDate);
    let endDate = new Date(currentDate?.endDate);
    return ({
      label: `Previous ${count} ${type}`,
      startDate: moment(startDate, "YYYY-MM-DD").subtract(count, type?.toLowerCase()).format("YYYY-MM-DD"),
      endDate: moment(endDate, "YYYY-MM-DD").subtract(count, type?.toLowerCase()).format("YYYY-MM-DD")
    })
  }
};

const tillToday = () => {
  return formatDate(new Date());
};

/**
 *
 * @param {Array[{custom: boolean, start_date: string, end_date: string}]} data
 * @returns {Array[{label:string, startDate: string, endDate: string }]}
 */
export const reportingCalendarUIWrapper = (data) => {
  let currDate = {};
  const tempData = data?.map((item, index) => {
    let tempItem = {};
    let till_today = item?.till_today;
    if (index == 0) {
      if (item?.custom) {
        tempItem.startDate = item?.start_date;
        tempItem.endDate = till_today? tillToday(): item?.end_date;
        tempItem.label = "Custom Date Range" + (till_today ? " Including today" : "");
      } else {
        if (item?.period && dateLabelMapping?.[item?.period]?.[item?.unit]) {
          tempItem = {...dateLabelMapping?.[item?.period]?.[item?.unit]};    
          if(item?.till_today){
            tempItem.endDate = tillToday();
            tempItem.label?.toLowerCase()?.includes('including today') ? {} : tempItem.label+= ' Including Today' ;
          }  
        } else {
          tempItem = handleCustomDate(
            mapDateStrings?.[item?.period],
            item?.unit,
            item?.till_today
          );
        }
      }
      currDate = tempItem;
    } else if (item) {
      if (item?.custom) {
        tempItem.startDate = item?.start_date;
        tempItem.endDate = item?.end_date;
        tempItem.label = "Custom Date Range";
      } else if (item?.previous) {
        tempItem = handleCompareCustomDate(item?.previous, "", "", currDate);
      } else {
        tempItem = handleCompareCustomDate(item?.previous,mapDateStrings[item?.period], item?.unit, currDate);
      }
    }
    return tempItem;
  });
  return tempData;
};

/**
 *
 * @param {Array[{label:string, startDate: string, endDate: string }]} data
 * @returns {Array[{custom: boolean, start_date: string, end_date: string}]}
 */
export const reportingCalendarAPIWrapper = (data) => {
  const tempData = data?.map?.((item, index) => {
    let tempItem = {};
    if (index == 0) {
      if (item?.label?.includes("Custom")) {
        tempItem.start_date = item?.startDate;
        tempItem.end_date = item?.endDate;
        tempItem.custom = true;
        if(item?.label?.toLowerCase()?.includes?.("including today")){
          tempItem.till_today = true;
        }
      } else if (item?.label.includes("Last")) {
        const [, num, type] = item?.label?.split(" ");
        tempItem = {
          period: mapDateStrings?.[type],
          unit: Number(num),
        };
        if(item?.label?.toLowerCase()?.includes?.("including today")){
          tempItem.till_today = true;
        }
      } else {
        const typeArr = item?.label?.split(" ");
        if(item?.label?.toLowerCase()?.includes?.("including today")){
          typeArr?.splice(-2);
          tempItem = {...labelDateMapping?.[typeArr?.join(' ')]};
          tempItem.till_today = true;
        }
        else{
          tempItem = labelDateMapping?.[item?.label];
        }
      }
    } else if (item) {
      if (item?.label?.includes("Custom")) {
        tempItem.start_date = item?.startDate;
        tempItem.end_date = item?.endDate;
        tempItem.custom = true;
        if(item?.label?.toLowerCase()?.includes?.("including today")){
          tempItem.till_today = true;
        }
      } else if (item?.label == "Previous Period") {
        tempItem.previous = true;
        tempItem.unit = 0;
        if(item?.label?.toLowerCase()?.includes?.("including today")){
          tempItem.till_today = true;
        }
      } else if (!item?.label) {
        return null;
      } else {
        const [, num, type] = item?.label?.split(" ");
        tempItem = {
          period: mapDateStrings?.[type],
          unit: Number(num),
        };
        if(item?.label?.toLowerCase()?.includes?.("including today")){
          tempItem.till_today = true;
        }
      }
    }
    return tempItem;
  });
  return tempData;
};
