import React from 'react';
import { Button } from '@mui/material';
import { ReactComponent as ServiceUnavailableIcon } from "../Assets/errorMsgImages/ServiceUnavailable.svg";
import { ReactComponent as NoInternetIcon } from "../Assets/errorMsgImages/NoInternet.svg";
import { ReactComponent as ServiceDownIcon } from "../Assets/errorMsgImages/ServiceDown.svg";
import { ReactComponent as CodeCrashIcon } from "../Assets/errorMsgImages/CodeCrash.svg";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { connect } from 'react-redux';
import { serviceUp } from '../api/user/action';

class AppErrorHandler extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            isOnline: true
        };
    }

    static getDerivedStateFromError(error) {
        console.log(error);
        return { hasError: true };
    }

    componentDidMount() {
        this.setState({ isOnline: navigator.onLine });
        this.props.dispatch(serviceUp());

        window.addEventListener('online', () => {
            this.setState({ isOnline: true });
        });

        window.addEventListener('offline', () => {
            this.setState({ isOnline: false });

        });
    }

    render() {
        const serviceDown = this.props.serviceDown;
        if (this.state.hasError || !this.state.isOnline || serviceDown) {
            return (
                <div style={{
                    height: "100vh", width: "100vw",
                    display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",
                    position: "absolute", zIndex: 20000, background: "white"
                }}>
                    {this.state.hasError
                        ? <>
                            <CodeCrashIcon />
                            <h1> Oops! Our analytics took a tumble.</h1>
                            <h3 style={{ color: "#82838F" }}>Give it a quick reload to keep the data flowing.</h3>
                        </>
                        : !this.state.isOnline
                            ? <>
                                <NoInternetIcon />
                                <h1>Oops, No Internet !</h1>
                                <h3 style={{ color: "#82838F" }}>Your insights will be right here when the connection returns.</h3>
                            </>
                            : <>
                                <ServiceDownIcon />
                                <h1>Oops! An unexpected error occurred. </h1>
                                <h3 style={{ color: "#82838F" }}>Please refresh the page and wait a few moments. If the error persists, please log in again.</h3>

                            </>
                    }
                    <div style={{ marginTop: "10px" }}>
                        {(serviceDown && this.state.isOnline) && <Button
                            style={{ textTransform: "none", borderRadius: '6px', width: "100px", marginRight: "20px" }}
                            variant="outlined"
                            onClick={() => { window.location.href = "/logout"; }}
                        >
                            Logout
                        </Button>}
                        <Button
                            style={{ textTransform: "none", borderRadius: '6px', width: "100px" }}
                            variant="contained"
                            startIcon={<AutorenewIcon />}
                            onClick={() => { window.location.reload() }}
                        >
                            Refresh
                        </Button>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

const mapStateToProps = state => ({
    serviceDown: state.user.serviceDown,
});

export default connect(mapStateToProps)(AppErrorHandler);