import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import PixisIcon from "../../Assets/Icons/pixis-icon.svg";
import BackIcon from "../../Assets/Icons/back_arrow.svg";
import { useHistory } from "react-router-dom";
import { Typography } from '@mui/material';

export default function PersonalizedDemo() {
  const history = useHistory();



  return (
    // <div style={{ width: "100vw", height: "100vh", display: 'flex', flexDirection: 'column' }}>
    //   <div style={{ width: "100vw", height: "75px", lineHeight: "75px", borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
    //     <Button style={{ border: "1px solid black", color: "black", marginLeft: "30px", width: '100px' }} onClick={() => history.goBack()}>
    //       <ArrowBackIosIcon style={{ width: "20px", height: "10px", marginBottom: '2px' }} /> Back
    //     </Button>
    //   </div>
    //   <div style={{ width: "80vw", margin: "20px auto" }}>
    //     <Grid container>
    //       <Grid xs={12} sx={{ width: "100%" }}>
    //         <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
    //           <div>
    //             <img src={PixisLogo} alt="Pixis" />
    //           </div>
    //         </div>
    //         <Typography sx={{ color: "#0869FB", width: "100%", textAlign: "center", fontWeight: '500', marginTop: '1%' }} fontSize={45} >Analytics</Typography>
    //       </Grid>
    //       <Grid xs={12} md={6} lg={6} sx={{ height: "45vh", marginTop: "50px", boxShadow: "inset -4px -4px 30px rgba(8, 105, 251, 0.05), inset 4px 4px 30px rgba(8, 105, 251, 0.05)", borderRadius: "5px" }}>
    //         <div style={{ width: "98%", height: "98%", margin: "auto auto" }}>
    //             <iframe width="100%" height="100%"
    //                 src="https://www.youtube.com/embed/UxjNvw3gP6I">
    //             </iframe>
    //         </div>
    //       </Grid>
    //       <Grid sx={{ height: "45vh", marginTop: "50px" }} xs={0} md={1} />
    //       <Grid xs={12} md={5} sx={{ height: "45vh", marginTop: "50px", display: "flex", alignItems: "flex-start", justifyContent: "center", flexDirection: "column" }}>
    //         <h1 style={{ color: "#333333", textAlign: "left" }}>Steps for Insights</h1>
    //         <p style={{ fontSize: 15 }}>Re-iterating you with help of demo video and steps to play around with the data across different platform  (ads channel / attribution) given below: </p>
    //         <ol style={{ fontSize: 15 }}>
    //           <li>            You can login via custom or google sign up            </li>
    //           <li>
    //             Once you're entered into the platform, connect your data sources that available over the platform such as GA, FB Ads, Adwords, Shopify etc
    //           </li>
    //           <li>
    //             Select your account id for which see the visualisations and performance for.
    //           </li>
    //           <li>
    //             Customized your dashboard/widget as per your view. You can change the dimensions/metrics, apply filters, drag drop and resize your charts, change the widget type.
    //           </li>
    //           <li>
    //             One click for generating different reports (GA, FB Ads, Adwords Etc)
    //           </li>
    //           <li>
    //             Change your account id from manage data sources/connect new sources and generate reports across different channels.
    //           </li>
    //         </ol>

    //       </Grid>

    //     </Grid>

    //   </div>
    //   <Grid sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'flex-end', paddingBottom: '30px' }} xs={12} >
    //     <div style={{ display: "flex", justifyContent: "center", width: "100%", alignItems: "center" }}>
    //       <p><b>still unable to figure out?</b></p>
    //       <p style={{ color: "#0869FB", paddingLeft: "5px" }}>
    //         <a href="https://calendly.com/harshit-shrivastava" target="_blank" style={{ textDecoration: "underline" }}>Book a personalized demo</a>
    //       </p>
    //     </div>
    //   </Grid>
    // </div>
    <div style={{ height: "100vh", width: "100vw", display: "flex", flexDirection: "column", padding: "24px", backgroundColor: "#F8F9FD" }}>

      <div style={{ display: "flex", alignItems: "center", width: "100%", height: "70px", background: "#FFF", borderRadius: "12px", padding: "16px" }}>
        <button style={{ background: "none", border: "none", cursor: "pointer", marginRight: "12px" }} onClick={() => history.goBack()}>
          <img src={BackIcon} alt="" />
        </button>
        <img src={PixisIcon} alt="Pixis icon" />
        <Typography style={{ fontFamily: "Inter", fontSize: "20px", fontWeight: "600", lineHeight: "32px" }}>Pixis Analytics</Typography>
      </div>

      <div style={{ display: "flex", width: "100%", background: "#FFF", borderRadius: "12px", height: "calc(100% - 48px)", marginTop: "16px", alignItems: "center", flexDirection: "column", paddingTop: "77px", paddingRight: "108px" }}>

        <div style={{ display: "flex", alignItems: "center", marginBottom: "40px", width: "100%" }}>
          <div style={{ width: "570px", height: "360px", marginLeft: "102px", marginRight: "102px" }}>
            <iframe width="100%" height="100%" style={{ borderRadius: "12px", border: "none" }}
              src="https://www.youtube.com/embed/UxjNvw3gP6I">
            </iframe>
          </div>

          <div style={{ width: "510px" }}>
            <Typography style={{ fontSize: "32px", fontWeight: "700", lineHeight: "34px", marginBottom: "32px" }}>Steps for Insights</Typography>
            <ul className='steps_insights' style={{ marginLeft: "10px", padding: 0 }}>
              <li style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "400", lineHeight: "22px", color: "#5C6674" }}>Log into the platform through custom login or social login (Google & Facebook).</li>
              <p style={{ borderBottom: "1px solid #7D899C", width: "100%", marginTop: "16px", marginBottom: "16px" }}></p>
              <li style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "400", lineHeight: "22px", color: "#5C6674" }}>Once you've landed on the platform, connect your data sources from the available list of ads and attribution channels such as Google Ads, Facebook Ads, Shopify, etc.</li>
              <p style={{ borderBottom: "1px solid #7D899C", width: "100%", marginTop: "16px", marginBottom: "16px" }}></p>
              <li style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "400", lineHeight: "22px", color: "#5C6674" }}>You can view the ad accounts fetched through the accounts connected for each data source.</li>
              <p style={{ borderBottom: "1px solid #7D899C", width: "100%", marginTop: "16px", marginBottom: "16px" }}></p>
              <li style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "400", lineHeight: "22px", color: "#5C6674" }}>Create a report using multiple ad accounts. Get your ad insights with a single click.</li>
              <p style={{ borderBottom: "1px solid #7D899C", width: "100%", marginTop: "16px", marginBottom: "16px" }}></p>
              <li style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "400", lineHeight: "22px", color: "#5C6674" }}>Visualize your ad performance using a array of smart widgets. Customize your reports and add more charts and edit existing ones. </li>
              <p style={{ borderBottom: "1px solid #7D899C", width: "100%", marginTop: "16px", marginBottom: "16px" }}></p>
              <li style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "400", lineHeight: "22px", color: "#5C6674" }}>Create blends, custom columns and metrics for a robust performance insights.</li>
            </ul>

          </div>


        </div>


        <div style={{ display: "flex", justifyContent: "center", width: "100%", alignItems: "center" }}>
          <Typography style={{ fontFamily: "Inter", fontSize: "12px", lineHeight: "20px", color: "#5C6674" }}>Still unable to figure out?</Typography>
          <Typography style={{ paddingLeft: "5px", fontFamily: "Inter", fontSize: "12px", lineHeight: "20px", color: "#0869FB" }}>
            <a href="https://calendly.com/harshit-shrivastava" target="_blank" style={{ textDecoration: "underline" }}>Book a personalized demo</a>
          </Typography>
        </div>

      </div>


    </div >
  );
}
