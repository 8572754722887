import { Button, MenuItem, Popover, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { transformFromFilterDataObject } from "../Filteration/Utils";
import { Filter } from "../../models/chart";
import { ReactComponent as AddIcon } from "../../Assets/Icons/plus_icon.svg";
import Drop from "../../Assets/Icons/new_down_arrow.svg";
import External from "../../Assets/Icons/export-icon.svg";
import "./editwidget.css"
import { ShareContext } from "./Sidebar";

const FilterDrop = ({ chart, channel, setCurrentFilter, updateFilter, setIsFilterOpen }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const allFilterList = useSelector(state => state.filters.allFilters);
    const { shared } = useContext(ShareContext);

    const editFilter = (filter) => {
        setCurrentFilter(transformFromFilterDataObject(filter));
        setIsFilterOpen(true);
        setAnchorEl(null);
    }

    const applyFilter = (filter) => {
        const currentFilter = transformFromFilterDataObject(filter);
        updateFilter(Filter.new(
            chart,
            currentFilter.name,
            currentFilter.segment,
            currentFilter.dimensionFilters,
            currentFilter.metricFilters,
            currentFilter.id
        ))
        setAnchorEl(null)
    }

    return (
        <>
            <div className="filter-dropdown"
                onClick={(e) => (chart.account || channel.isBlend) && setAnchorEl(e.currentTarget)}
                style={{ opacity: (chart.account || channel.isBlend) ? 1 : 0.6 }}>
                <Typography style={{ fontSize: "12px", fontWeight: "500", lineHeight: "16px", color: "#585969" }}>
                    Add Filter
                </Typography>
                <img src={Drop} alt="" />
            </div>

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'left', top: "8px" }}
                transformOrigin={{ vertical: "bottom", horizontal: "left" }}
                PaperProps={{
                    style: {
                        width: "323px", left: "772px", boxShadow: "0px 12px 40px -16px rgba(0, 0, 0, 0.05);",
                        border: "0.5px solid #E0E3E5", borderRadius: "6px",
                    },
                }}
                sx={{
                    "& .MuiMenuItem-root": {
                        display: "flex",
                        justifyContent: "space-between"
                    }
                }}
            >
                <div style={{ maxHeight: "200px", overflow: "auto", paddingTop: "12px" }}>
                    {allFilterList.map(appliedFilter => {
                        return (
                            <div style={{ display: "flex", flexDirection: "column", background: "#FFF" }}>
                                <MenuItem onClick={() => {
                                    applyFilter(appliedFilter)
                                }} > {appliedFilter.name}
                                    {!shared && <button className="menu_btn" onClick={(e) => {
                                        e.stopPropagation();
                                        editFilter(appliedFilter)
                                    }}>
                                        <img src={External} alt="" /></button>}
                                </MenuItem>
                            </div>
                        )
                    })}
                </div>

                {!shared && <div style={{ padding: "12px 16px", borderTop: "1px solid #F4F4F8" }}>
                    <Button
                        sx={{
                            justifyContent: "center",
                            color: "#333333",
                            border: "none",
                            borderRadius: "8px",
                            height: "40px",
                            width: "100%",
                            padding: "12px",
                            marginTop: "12px 16px",
                            background: "#E3EEFF",
                            "&:hover": {
                                border: "none",
                                background: "#E3EEFF"

                            }
                        }}
                        fullWidth
                        variant="outlined"
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setIsFilterOpen(true)
                            setAnchorEl(null)
                        }}
                    >
                        <Typography style={{ fontSize: "14px", fontWeight: "500", color: "#0869FB", }} >
                            Create New Filter
                        </Typography>
                    </Button>
                </div>}
            </Popover>
        </>
    )
}

export default FilterDrop;