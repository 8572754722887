import { GetChannel } from "../../models/channel";

export function getAccountId(allAccounts, blend) {
    return blend.dataSources?.reduce((obj, dataSource) => ({
        ...obj,
        [dataSource]: GetChannel(dataSource).transformer?.getAccountId(allAccounts[dataSource]),
    }), {});
}

export function getApiObject(metrics, dimensions, filter, table) {
    let blendObj = {
        metrics: metrics.map(m => ({
            id: m.id,
            dataSource: m.dataSource,
            audiences: m.audiences
        })),
        dimensions: (dimensions ?? []).map(d => ({
            id: d.miscInfo?.base_dimension?.id ?? d.id,
            dataSource: d.dataSource,
            audiences: d.audiences
        }))
    };
    if (filter?.id) {
        blendObj.filterGrouping = { filterId: filter?.id }
    }
    return blendObj;
}

export const properties = {
}
