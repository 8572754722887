import { ChannelType } from "../models/channel";

class FormulaMetric {
    constructor(id,formula){
      this.id=id;
      this.formula=formula;
    }
}

const googleAdsFormulaById = [
    new FormulaMetric("metrics.all_conversions_value_per_cost","All conversions value/Cost"),
    new FormulaMetric("metrics.average_cpm","(cost/Impr)*1000"),
    new FormulaMetric("metrics.average_cpv","cost/video view"),
    new FormulaMetric("metrics.conversions_value_per_cost","Conversion value/Cost"),
    new FormulaMetric("metrics.cost_per_conversion","cost/conversion"),
    new FormulaMetric("metrics.ctr","clicks/impr"),
    new FormulaMetric("metrics.engagement_rate","engagement/impr"),
    new FormulaMetric("metrics.interaction_rate","interaction/impr"),
    new FormulaMetric("metrics.video_quartile_p100_rate","(video_quartile_p100_rate*video views)"),
    new FormulaMetric("metrics.video_quartile_p25_rate","(video_quartile_p25_rate*video views)"),
    new FormulaMetric("metrics.video_quartile_p50_rate","(video_quartile_p50_rate*video views)"),
    new FormulaMetric("metrics.video_quartile_p75_rate","(video_quartile_p75_rate*video views)"),
    new FormulaMetric("metrics.active_view_viewability","(viewable impressions/measurable impression)"),
    new FormulaMetric("metrics.conversions_rate","conversions/interactions"),
    new FormulaMetric("metrics.average_cpc","Cost/Clicks"),
]

const facebookAdsFormulaById = [
    new FormulaMetric("frequency","Impressions / reach"),
    new FormulaMetric("video_average_plays","spend / video_continuous_2_sec_watched_actions"),
    new FormulaMetric("cost_per_unique_landing_page_view","spend / unique_landing_page_view"),
    new FormulaMetric("cost_per_lead","spend / leads"),
    new FormulaMetric("cost_per_unique_lead","spend / unique_leads"),
    new FormulaMetric("purchase_roas_2","purchases_conversion_value / spend"),
    new FormulaMetric("cost_per_purchase","spend / purchases"),
    new FormulaMetric("purchases_average_order_value","purchases_conversion_value / purchases"),
    new FormulaMetric("purchases_per_link_clicks","purchases / inline_link_clicks"),
    new FormulaMetric("cost_per_inline_link_click","spend / inline_link_clicks"),
    new FormulaMetric("cpm","1000 * spend / impressions"),
    new FormulaMetric("ctr","100 * clicks / impressions"),
    new FormulaMetric("cpc","spend / clicks"),
    new FormulaMetric("cpp","1000 * spend / reach"),
    new FormulaMetric("website_ctr_link_click","100 * inline_link_clicks / impressions"),
    new FormulaMetric("cost_per_app_install","spend / app_installs"),
    new FormulaMetric("cost_per_unique_content_view","spend / unique_content_view"),
    new FormulaMetric("cost_per_landing_page_view","spend / landing_page_view")
]

const adjustFormulaById = [
  new FormulaMetric("click_conversion_rate", "installs / clicks"),
  new FormulaMetric("ctr", "clicks / impressions"),
  new FormulaMetric("impression_conversion_rate", "installs / impressions"),
  new FormulaMetric("network_installs_diff", "installs - network_installs"),
  new FormulaMetric("installs_per_mile", "1000 * (installs / impressions)"),
  new FormulaMetric("limit_ad_tracking_install_rate", "limit_ad_tracking_installs / installs"),
  new FormulaMetric("limit_ad_tracking_reattribution_rate", "limit_ad_tracking_reattributions / reattributions"),
  new FormulaMetric("ecpi_all", "cost / installs"),
  new FormulaMetric("network_ecpi", "network_cost / network_installs"),
  new FormulaMetric("ecpi", "network_cost / paid_installs"),
  new FormulaMetric("skad_ecpi", "network_ad_spend_skan / skad_installs"),
  new FormulaMetric("ecpm", "(cost / paid_impressions) * 1000"),
  new FormulaMetric("network_ecpm", "(network_cost / network_impressions) * 1000"),
  new FormulaMetric("ecpc", "cost / paid_clicks"),
  new FormulaMetric("ad_rpm", "(ad_revenue / ad_impressions) * 1000"),
  new FormulaMetric("arpdau", "all_revenue / daus"),
  new FormulaMetric("arpdau_ad", "ad_revenue / daus"),
  new FormulaMetric("arpdau_iap", "In-app Revenue / daus"),
  new FormulaMetric("return_on_investment", "cohort_gross_profit / cost"),
  new FormulaMetric("revenue_to_cost", "cohort_revenue / cost"),
  new FormulaMetric("roas", "(cohort_revenue + cohort_ad_revenue) / cost"),
  new FormulaMetric("roas_ad", "cohort_ad_revenue / cost x 100"),
  new FormulaMetric("roas_iap", "cohort_revenue / cost"),
  new FormulaMetric("skad_conversion_value_null_rate", "skad_conversion_value_null / Installs (SKAN)"),
  new FormulaMetric("skad_revenue_min_roas", "(total_revenue_min * skad_installs) / (network_ad_spend_skan * valid_conversions)"),
  new FormulaMetric("skad_revenue_est_roas", "(total_revenue_est * skad_installs) / (network_ad_spend_skan * valid_conversions)"),
  new FormulaMetric("skad_revenue_max_roas", "(total_revenue_max * skad_installs) / (network_ad_spend_skan * valid_conversions)"),
  new FormulaMetric("skad_revenue_min_roi", "(total_revenue_min * skad_installs) / (network_ad_spend_skan * valid_conversions) - 1"),
  new FormulaMetric("skad_revenue_est_roi", "(total_revenue_est * skad_installs) / (network_ad_spend_skan) * valid_conversions) - 1"),
  new FormulaMetric("skad_revenue_max_roi", "(total_revenue_max * skad_installs) / (network_ad_spend_skan * valid_conversions) - 1"),
  new FormulaMetric("rejected_install_invalid_signature_rate", "rejected_installs_invalid_signature / (installs + rejected_installs)"),
  new FormulaMetric("rejected_install_anon_ip_rate", "rejected_installs_anon_ip / (installs + rejected_installs)"),
  new FormulaMetric("rejected_install_click_injection_rate", "rejected_installs_click_injection / (installs + rejected_installs)"),
  new FormulaMetric("rejected_install_distribution_outlier_rate", "rejected_installs_distribution_outlier / (installs + rejected_installs)"),
  new FormulaMetric("rejected_install_malformed_advertising_id_rate", "rejected_install_malformed_advertising_id / (installs + rejected_installs)"),
  new FormulaMetric("rejected_install_too_many_engagements_rate", "rejected_installs_too_many_engagements / (installs + rejected_installs)"),
  new FormulaMetric("rejected_reattribution_rate", "rejected_reattributions / (reattributions + rejected_reattributions)"),
  new FormulaMetric("rejected_reattribution_anon_ip_rate", "rejected_reattributions_anon_ip / (reattributions + rejected_reattributions)"),
  new FormulaMetric("rejected_reattributions_click_injection_rate", "rejected_reattributions_click_injection / (reattributions + rejected_reattributions)"),
  new FormulaMetric("rejected_reattribution_distribution_outlier_rate", "rejected_reattributions_distribution_outlier / (reattributions + rejected_reattributions)"),
  new FormulaMetric("rejected_reattribution_too_many_engagements_rate", "rejected_reattributions_too_many_engagements / (reattributions + rejected_reattributions)"),
  new FormulaMetric("qualifiers_per_assisted_installs", "qualifiers / assisted_installs"),
  new FormulaMetric("impression_based_qualifiers_per_assisted_installs", "impression_based_qualifiers / assisted_installs"),
  new FormulaMetric("click_based_qualifiers_per_assisted_installs", "click_based_qualifiers / assisted_installs")
]

const appsflyerFormulaById = [
  new FormulaMetric("loyal_users_rate", "Loyal Users / Installs"),
  new FormulaMetric("activity_average_dau_mau_rate", "activity_average_dau / activity_average_mau"),
]

const MappedCalculatedFormulaById = {
  [ChannelType.GoogleAds]: googleAdsFormulaById,
  [ChannelType.FacebookAds]: facebookAdsFormulaById,
  [ChannelType.Adjust]: adjustFormulaById,
  [ChannelType.Appsflyer]: appsflyerFormulaById
}

export const getFormula = (channelType,id) => {
  return MappedCalculatedFormulaById[channelType]?.find(formula => formula.id === id)?.formula;
}