import { all, put, select, takeLatest, takeLeading } from "redux-saga/effects";
import { api } from "..";
import { CHECK_AUTH, SELECT_ACCOUNT } from "../channel/type";
import { channelType, getAccountId } from "./transformer";
import mixpanelObj, { EVENTS } from "../../utils/mixpanel";
import { convertAccountsFormat } from "../../models/channel";

function* getClientID() {
    try {
        const response = yield api.get("/v2/analytics/channel/google-analytics/get-client-id");
        yield put({
            type: `${channelType}.GET_CLIENT_ID_SUCCESS`,
            payload: response.data.clientId
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_CLIENT_ID_FAILURE`,
            payload: error.response
        });
    }
}

function* connectAccount(args) {
    try {
        const clientId = yield select((state) => state[channelType].clientId);
        let token = localStorage.getItem("accessToken");
        let state = encodeURIComponent(JSON.stringify({
            access_token: token,
            success_redirect_url: args.payload.redirect_url + `?channel=${channelType}`,
            failure_redirect_url: args.payload.redirect_url + `?channel=${channelType}&failure`
        }));
        const scopes = [
            'https://www.googleapis.com/auth/analytics.readonly',
            'https://www.googleapis.com/auth/userinfo.email',
            'https://www.googleapis.com/auth/userinfo.profile'
        ];
        let authorizationUrl = `https://accounts.google.com/o/oauth2/v2/auth` +
            `?scope=${scopes.join(' ')}` +
            `&client_id=${clientId}&state=${state}` +
            `&redirect_uri=${process.env.REACT_APP_GOOGLE_ANALYTICS_REDIRECT_URI}` +
            `&access_type=offline&prompt=consent` +
            `&include_granted_scopes=true&response_type=code`;
        // `&client_id=414657388305-a3es35pmj8h159j59e6ihmael2i9s6j4.apps.googleusercontent.com`
        yield window.location.href = authorizationUrl;
    } catch (error) {
        yield put({
            type: `${channelType}.CONNECT_ACCOUNT_FAILURE`,
        });
    }
}

function* disconnectAccount() {
    try {
        yield api.post("/v2/analytics/google-analytics/revoke");
        yield put({
            type: `${channelType}.DISCONNECT_ACCOUNT_SUCCESS`,
        });
        yield put({
            type: SELECT_ACCOUNT,
            payload: {
                channel: channelType,
            }
        });
        yield put({
            type: CHECK_AUTH,
        });
        mixpanelObj.track(EVENTS.EVENTS.remove_source, { data_source: channelType, channel_type: "attribution" }, true);
    } catch (error) {
        yield put({
            type: `${channelType}.DISCONNECT_ACCOUNT_FAILURE`,
        });
    }
}

function* getAccountSummary(args) {
    try {
        yield put({ type: `${channelType}.START_LOADING` });
        const response = yield api.get(`/v2/analytics/ga/get-account-summary?refresh=${args.payload.refresh}`);
        const data = yield convertAccountsFormat(response.data.data)
        yield put({
            type: `${channelType}.GET_ACCOUNT_SUMMARY_SUCCESS`,
            payload: data
        });
        yield put({
            type: "UPDATE_ACCOUNTS_LENGTH",
            payload: { accLen: data.allAccounts.length, channelType }
        })
    } catch (error) {
        yield put({
            type: `${channelType}.GET_ACCOUNT_SUMMARY_FAILURE`,
            payload: error.response
        });
    }
}

function* getSegments() {
    try {
        const response = yield api.post("/v2/analytics/ga/get-segment-list");
        yield put({
            type: `${channelType}.GET_SEGMENTS_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_SEGMENTS_FAILURE`,
            payload: error.response
        });
    }
}

function* getCustomFields(selectedAccount) {
    try {
        const response = yield api.post("/v2/analytics/ga/get-dimensions-metrics", {
            account_id: selectedAccount.accountId,
            web_property_id: selectedAccount.webPropertyId,
            view_id: selectedAccount.viewId,
            customOnly: true
        });
        yield put({
            type: `${channelType}.GET_CUSTOM_FIELDS_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_FIELDS_FAILURE`,
            payload: error.response
        });
    }
}

function* getDimensionsAndMetrics() {
    try {
        const response = yield api.post("/v2/analytics/ga/get-dimensions-metrics");
        yield put({
            type: `${channelType}.GET_DIMENSION_METRICS_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_DIMENSION_METRICS_FAILURE`,
            payload: error.response
        });
    }
}

function* getFields() {
    const state = yield select((state) => state);
    const { segments, metrics, dimensions } = state[channelType] || {};
    const dimensionFunction = () => getCustomFields(state.channel.selectedAccounts[channelType]);
    yield all([(!segments.length) && getSegments(), (!metrics.length || !dimensions.length) && getDimensionsAndMetrics(), dimensionFunction()]);
}

function* getCurrency(args) {
    try {
        const response = yield api.post("/v2/analytics/ga/get-currency", getAccountId(args.payload));
        yield put({
            type: `${channelType}.GET_CURRENCY_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_CURRENCY_FAILURE`,
            payload: error.response
        });
    }
}

export default function* root() {
    yield all([
        takeLatest(`${channelType}.GET_CLIENT_ID`, getClientID),
        takeLatest(`${channelType}.CONNECT_ACCOUNT`, connectAccount),
        takeLatest(`${channelType}.DISCONNECT_ACCOUNT`, disconnectAccount),
        takeLeading(`${channelType}.GET_ACCOUNT_SUMMARY`, getAccountSummary),
        takeLatest(`${channelType}.GET_FIELDS`, getFields),
        takeLatest(`${channelType}.GET_CURRENCY`, getCurrency),
    ])
}
