import { useState } from "react";
import { Grid, Button, Typography, Link, Stack, ListItem, TextField } from "@mui/material";
import Pixis from "../../Assets/Images/logos_pixis.svg";
import { subscribeToNewsletter } from "../../api/user/saga";

const Footer = (props) => {

    const references = [
        {
            name: "AI Infrastructure",
            links: [
                { name: "Governance AI", link: "https://pixis.ai/governance-ai" },
                { name: "Creative AI", link: "https://pixis.ai/creative-ai" },
                { name: "Targeting AI", link: "https://pixis.ai/targeting-ai" }
            ]
        },
        {
            name: "Products and Plugins",
            links: [
                { name: "Pixis Performance" },
                { name: "AI Optimizer", link: "https://pixis.ai/pixis-plugin" },
                { name: "Pixis Insights" },
                { name: "Market Research", link: "https://pixis.ai/market-research" },
                { name: "UI/UX Testing", link: "https://pixis.ai/uiux-testing" },
            ]
        },
        {
            name: "Resources",
            links: [
                { name: "The Marketing AIvolution Blog", link: "https://pixis.ai/the-marketing-ai-volution-blog" },
                { name: "Growth Caffeine - The Pixis Podcast", link: "https://pixis.ai/growth-caffeine" },
                { name: "The MarTech Brief Newsletter", link: "https://pixis.ai/the-martech-brief" }
            ]
        },
        {
            name: "About Us",
            links: [
                { name: "Company", link: "https://pixis.ai/company" },
                { name: "Careers", link: "https://pixis.ai/careers" },
                { name: "Press & Media", link: "https://pixis.ai/press-and-media" },
                { name: "Affiliate Marketing", link: "https://pixis.ai/partners" },
                { name: "Labor Condition Application (LCAs)", link: "https://pixis.ai/labor-condition-application" },
                { name: "Privacy Policy", link: "https://pixis.ai/privacy-policy/" }
            ]
        }
    ]

    const [subscribed, setSubscribed] = useState(false);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("")
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    const subscribe = () => {
        if (email.length === 0) {
            setEmailError("Email is required");
            return;
        } else if (!emailRegex.test(email)) {
            setEmailError("Invalid Email");
            return;
        }
        subscribeToNewsletter({ email: email })
        .then(() => setSubscribed(true))
        .catch(err => setEmailError("Something went wrong"));
    }

    return (

        <Grid container spacing={2} style={{ background: "#030831", padding: "5vh 5vw" }}>
            <Grid item xs={12} md={12} lg={2} style={{ display: "flex", alignItems: "baseline" }}>
                <img style={{ width: "40px" }} src={Pixis} alt="dots" />
                <Typography style={{ color: "#0869FB", paddingLeft: "15px", fontWeight: '600', margin: 0, fontSize: '42px', fontFamily: 'Lato', paddingBotom: '3px' }} variant="h4" gutterBottom>
                    <span style={{ color: 'white' }}>Pixis</span>
                </Typography>
            </Grid>
            {references.map((ref, refIndex) =>
                <Grid item xs={6} md={3} lg={2}>
                    <Typography style={{ color: "#D1D1D1", fontWeight: '600', fontSize: '20px', fontFamily: 'Lato' }} variant="h4" gutterBottom>
                        {ref.name}
                    </Typography>
                    <hr style={{ border: "1px solid #13385D", marginRight: "70%" }} />
                    <Stack spacing={0}>
                        {ref.links.map((item, index) =>
                            <ListItem key={index} style={{ paddingLeft: 0 }}  >
                                {item.link
                                    ? <Typography style={{ color: "#EAEAEA", fontWeight: '300', fontSize: '16px', fontFamily: 'Lato' }} variant="h4" gutterBottom>
                                        <Link href={item.link} underline="hover" color="inherit">
                                            {item.name}
                                        </Link>
                                    </Typography>
                                    : <Typography style={{ color: "#2776FD", fontWeight: '500', fontSize: '16px', fontFamily: 'Lato' }} variant="h4" gutterBottom>
                                        {item.name}
                                    </Typography>
                                }
                            </ListItem>
                        )}
                    </Stack>
                </Grid>
            )}
            <Grid item xs={12} md={12} lg={2}>
                <Typography style={{ color: "#D1D1D1", fontWeight: '600', fontSize: '20px', fontFamily: 'Lato' }} variant="h4" gutterBottom>
                    Subscribe
                </Typography>
                <hr style={{ border: "1px solid #13385D", marginRight: "70%" }} />
                <ListItem style={{ paddingLeft: 0 }}>
                    <Typography style={{ color: "#EAEAEA", fontWeight: '300', fontSize: '16px', fontFamily: 'Lato' }} variant="h4" gutterBottom>
                        Sign up with your email address to
                        receive news and updates.
                    </Typography>
                </ListItem>
                {!subscribed
                    ? <>
                        <TextField
                            required
                            placeholder="Email Address"
                            size="small"
                            error={emailError}
                            helperText={emailError}
                            onChange={e => { setEmailError(""); setEmail(e.target.value) }}
                            style={{ width: '100%' }}
                            sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                                borderRadius: '8px'
                            }}
                            InputLabelProps={{ shrink: true, style: { color: 'black', fontFamily: 'Inter' } }}
                            InputProps={{ style: { borderRadius: '8px', background: "#fff" } }}
                            variant="outlined" />
                        <Button onClick={() => { subscribe() }} style={{ textTransform: "none", padding: "10px 40px", borderRadius: "8px", marginTop: "10px", alignSelf: "center" }} variant="contained" color="primary" >
                            Submit
                        </Button>
                    </>
                    : <Typography style={{ color: "#D1D1D1", fontWeight: '600', fontSize: '18px', fontFamily: 'Lato' }} variant="h4" gutterBottom>
                        Thank you for subscribing!
                    </Typography>
                }
            </Grid>
        </Grid>

    )
}

export default Footer;
