export const blendsList = (payload) => {
    return {
        type: 'BLENDS_LIST',
        payload: payload
    }
}

export const blendsCreate = (payload) => {
    return {
        type: "BLENDS_CREATE",
        payload: payload,
    }
}

export const updateBlend = (payload) => {
    return {
        type: "BLENDS_UPDATE",
        payload: payload,
    }
}

export const deleteBlend = (payload) => {
    return {
        type: "BLENDS_DELETE",
        payload: payload,
    }
}
