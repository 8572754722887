import Modal from '@mui/material/Modal';
import { Box, Button, IconButton } from '@mui/material';
import UserIcon from "../../Assets/Icons/User.svg";
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { connect } from 'react-redux';
import { channelType } from '../../api/shopify-ads/transformer';
import { closeConnecting } from '../../api/shopify-ads/action';
import { connectAccountApi } from '../../api/shopify-ads/action';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 386,
    width: '25%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '24px',
    borderRadius: '12px'
};

const closeButtonStyle = {
    borderRadius: '8px',
    height: '40px',
    width: '40px',
    padding: '8px',
    background: '#f8f9fa',
}

function ShopifyConnectModal({ isConnecting, connectAccountApi, close, redirect_url = "selectchannels" }) {
    const [state, setState] = useState({
        shopify_access_token: "",
        store_name: ""
    });

    const handleChange = (property, value) => {
        setState({ ...state, [property]: value });
    }

    return (
        <Modal open={isConnecting} onClose={close} aria-labelledby="Connect Shopify" >
            <Box sx={style}>
                <div className='displayRowSpaced'>
                    <div className='connect-account-logo'>
                        <img src={UserIcon} alt="UserIcon" height='24px' width='24px' />
                    </div>
                    <IconButton aria-label="save" onClick={close} sx={closeButtonStyle}>
                        <CloseIcon sx={{ fontSize: 24 }} />
                    </IconButton>
                </div>

                <h3 style={{ textAlign: "left", marginBottom: "4px", fontSize: '20px', marginTop: '16px' }}><b>New Shopify account</b></h3>
                <p style={{ fontSize: "14px", color: '#5C6674' }}>
                    Follow these <a href={`${process.env.REACT_APP_BASE_URL}/connect-shopify-account`} target="_blank" rel="noreferrer">instructions</a> to connect your shopify account.
                    Please make sure that you're adding "read_analytics", "read_gift_cards", "read_locales", "read_locations", "read_reports", "read_shipping", "read_orders", and "read_products" in the scope while creating app.
                </p>

                <div style={{ margin: '32px 0' }}>
                    <div >
                        <label htmlFor="shop_url">Shop URL</label>
                        <input
                            className='input-text'
                            placeholder="shopname.myshopify.com"
                            id="shop_url"
                            onChange={(e) => handleChange("store_name", e.target.value)}
                        />
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <label htmlFor="Acees-token">Access token</label>
                        <input
                            className='input-text'
                            placeholder='shpat_*************************'
                            onChange={(e) => handleChange("shopify_access_token", e.target.value)}
                        />
                    </div>
                </div>

                <Button
                    sx={{ padding: "14px 24px", borderRadius: "8px", textTransform: "none", width: '100%', height: '40px' }}
                    variant="contained"
                    onClick={() => connectAccountApi({ redirect_url, ...state })}
                >
                    Connect An Account
                </Button>
            </Box>
        </Modal>
    );
}

const mapStateToProps = state => ({
    isConnecting: state[channelType].isConnecting,
})

const mapDispatchToProps = dispatch => ({
    connectAccountApi: (_) => dispatch(connectAccountApi(_)),
    close: () => dispatch(closeConnecting()),
})

export default (connect(mapStateToProps, mapDispatchToProps)(ShopifyConnectModal));
