import { channelType } from "./transformer";

const INITIAL_STATE = {
    clientId: "",
    accountData: {},
    tables: null,
    dimensions: [],
    metrics: [],
    currency: null,
    metricsUpdated: false,
    tablesUpdated: {}
};

const metricTypes = ["INTEGER", "FLOAT", "TIME", "PERCENT", "CURRENCY", "Number"];
const dimensionTypes = ["BOOLEAN", "DATE", "ENUM", "DATETIME", "OBJECT", "UNKNOWN", "STRING"];

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case `${channelType}.GET_CLIENT_ID_SUCCESS`:
            return { ...state, clientId: action.payload.clientId };
        case `${channelType}.GET_CLIENT_ID_FAILURE`:
            return { ...state, clientId: "" };
        case `${channelType}.GET_ACCOUNT_SUMMARY_SUCCESS`:
            const accountList = action.payload.allAccounts.map(data => ({
                title: data.adAccountName ?? data.adAccountId,
                subtitle: data.mccName,
                id: data.adAccountId,
                customerId: data.adAccountId,
                // loginCustomerId: data.adAccountId,
                users: data.users,
                channelType
            }))
            return { ...state, accountData: { users: action.payload.allUsers, accountList: accountList } };
        case `${channelType}.START_LOADING`:
            return { ...state, accountData: { ...state.accountData, loading: true } };
        case `${channelType}.DISCONNECT_ACCOUNT_SUCCESS`:
        case `${channelType}.GET_ACCOUNT_SUMMARY_FAILURE`:
            return { ...INITIAL_STATE, clientId: state.clientId };
        case `${channelType}.GET_TABLES_SUCCESS`:
            return { ...state, tables: action.payload, metricsUpdated: true };
        case `${channelType}.GET_COLUMNS_SUCCESS`:
            const { dimensions, metrics } = action.payload.data.reduce((group, col) => {
                col.type === "DIMENSION" ? group.dimensions.push(col) : group.metrics.push(col)
                return group;
            }, { dimensions: [], metrics: [] })
            return {
                ...state, metrics, dimensions, metricsUpdated: true,
                // metrics: { ...state.metrics, [action.payload.tableName]: metrics },
                // dimensions: { ...state.dimensions, [action.payload.tableName]: dimensions },
                // tablesUpdated: { ...state.tablesUpdated, [action.payload.tableName]: true }
            };
        case `${channelType}.GET_FIELDS_SUCCESS`:
            const allFields = {};
            Object.entries(action.payload).map(([tableName, fields]) => {
                allFields[tableName] = fields.map(field => ({
                    name: field.name,
                    id: field.id,
                    dataType: field.dataType,
                    type: field.type,
                    group: field.group,
                }));
            });
            return {
                ...state,
                dimensions: Object.entries(allFields).reduce((obj, [tableName, fields]) => ({ ...obj, [tableName]: fields.filter(c => c.type === "DIMENSION") }), {}),
                metrics: Object.entries(allFields).reduce((obj, [tableName, fields]) => ({ ...obj, [tableName]: fields.filter(c => c.type === "METRIC") }), {}),
            };
        case `${channelType}.GET_CURRENCY_SUCCESS`:
            return { ...state, currency: action.payload.currency }
        default:
            return state;
    }
};

export default reducer;
