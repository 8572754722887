import { FormControl, InputAdornment, TextField, } from "@mui/material";
import React from "react";

export default function DataInput({ value, isEditable = true, startAdornment, defaultTitle = "", onChange, error, stretch = "80%", disabled = false }) {
    return (
        <FormControl
            sx={{ marginTop: "10px", display: "flex", flexDirection: "row", width: "95%",pointerEvents:disabled ? "none" : "all"}}>
            <div className="metric-list-form" style={{ width: "100%" }}>
                <TextField
                    hiddenLabel
                    id="Analytics"
                    variant="filled"
                    size="small"
                    style={{ width: stretch }}
                    InputProps={{
                        disableUnderline: true,
                        readOnly: !isEditable,
                        startAdornment: (
                            startAdornment
                                ? <InputAdornment onClick={startAdornment.onClick}>
                                    <img style={{ width: "18px", marginRight: "10px" }} src={startAdornment.src} alt="Calendar" />
                                </InputAdornment>
                                : <></>
                        ),
                    }}
                    value={value}
                    onChange={(event) => onChange(event.target.value)}
                    placeholder={defaultTitle}
                />
            </div>
        </FormControl>
    );
}
