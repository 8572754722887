import { Grid, Tab } from "../../models/report";
import { Chart, Metric, Filter } from "../../models/chart";
import { channelType } from "./transformer";


//tables
const AccountPerformance = { id: "AccountPerformance", name: "AccountPerformance" };

//metrics
const Impressions={id:"Impressions",name:"Absolute Top Impression Rate Percent"}
const Ctr= {id:"Ctr",name:"Ctr"}

//dimensions
const DATE = { id: "Date", name: "Date" };

const overallCharts = [
  new Chart(channelType, "Impressions vs Clicks", "LINE",AccountPerformance,
      { x: 0, y: 0, w: 6, h: 2, static: false },
      [new Metric(Impressions)],[new Metric(Ctr)]
  ),
]; 

const overallGrid = [
  new Grid(null, null, null, overallCharts),
];

export default [
  new Tab("Overall", 0, overallGrid),
];
