import { Close } from "@mui/icons-material";
import { Container, IconButton } from "@mui/material"
import { connect } from "react-redux";
import { useState } from "react";
import LogoutModal from "../Modal/LogoutModal";

function CloseIcon({ isAuthenticated }) {
    const [showLogoutModal, setShowLogoutModal] = useState(false);

    return (
        <Container>
            <IconButton
                onClick={() => {
                    if (isAuthenticated) { setShowLogoutModal(true); }
                    else { window.location.href = "/login"; }
                }}
                aria-label="delete" size="large" style={{ position: 'absolute', right: 10, top: 10 }}>
                <Close fontSize="inherit" />
            </IconButton>
            <LogoutModal modal={showLogoutModal} setModal={setShowLogoutModal} />
        </Container>
    )
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(CloseIcon);
