import { LOGIN, SIGNUP, LOGOUT, SOCIAL_LOGIN, IS_ONBOARDED, RESET_ERROR, RESET_FAILURE } from "./type";

export const signup = (payload) => {
    return {
        type: SIGNUP,
        payload: payload
    };
};

export const login = (payload) => {
    return {
        type: LOGIN,
        payload: payload
    };
};

export const resetError = (payload) => {
    return {
        type: RESET_ERROR,
        payload: payload
    };
};

export const socialLogin = (payload) => {
    return {
        type: SOCIAL_LOGIN,
        payload: payload
    };
};

export const logout = () => {
    return {
        type: LOGOUT,
    };
};

export const setIsOnboarded = () => {
    return {
        type: IS_ONBOARDED,
    };
};

export const resetFailure = () => {
    return {
        type: RESET_FAILURE,
    }
};
