import { Chart, Metric, Filter } from "../../models/chart";
import { channelType } from "./transformer";
import { Grid,Tab } from "../../models/report";

const overallCharts = [
    new Chart(channelType, "Impressions vs Clicks", "LINE", null,
       { x: 0, y: 0, w: 6, h: 2, static: false },
       [new Metric({id:"impressions",name:"Impressions"})],[new Metric({id:"clicks",name:"Clicks"})],
       [{id:"date",name:"Date"}]
    ),
    new Chart(channelType, "Total spent", "BAR", null,
       { x: 6, y: 0, w: 6, h: 2, static: false },
       [new Metric({id:"costInLocalCurrency",name:"Total spent"})],[],
       [{id:"date",name:"Date"}]
    ),
    new Chart(channelType, "totalEngagements", "KPI", null,
       { x: 0, y: 0, w: 3, h: 1, static: false },
       [new Metric({id:"totalEngagements",name:"TotalEngagement"})]
    ),
    new Chart(channelType, "Total spent (USD)", "KPI", null,
       { x: 3, y: 0, w: 3, h: 1, static: false },
       [new Metric({id:"costInUsd",name:"Total spent (USD)"})]
    ),
    new Chart(channelType, "Total spent", "KPI", null,
       { x: 6, y: 0, w: 3, h: 1, static: false },
       [new Metric({id:"costInLocalCurrency",name:"Total spent"})]
    ),
    new Chart(channelType, "Clicks", "KPI", null,
      { x: 9, y: 0, w: 3, h: 1, static: false },
      [new Metric({id:"clicks",name:"Clicks"})]
    ),
   new Chart(channelType, "Account Funnel", "FUNNEL",null,
      { x: 6, y: 0, w: 6, h: 2, static: false },
      [new Metric({id:"impressions",name:"Impressions"}),new Metric({id:"clicks",name:"Clicks"})]
   ),
    new Chart(channelType, "Campaign Performance", "TABLE", null,
     { x: 0, y: 0, w: 6, h: 2, static: false },
     [new Metric({id:"impressions",name:"Impressions"}),new Metric({id:"clicks",name:"Clicks"})],null,
     [{id:"campaignName",name:"Campaign Name"}]
    ),
    new Chart(channelType, "Campaign Performance", "BUBBLE", null,
     { x: 0, y: 0, w: 6, h: 2, static: false },
     [new Metric({id:"impressions",name:"Impressions"}),new Metric({id:"clicks",name:"Clicks"})],null,
     [{id:"campaignName",name:"Campaign Name"}]
    )
];

const overallGrid = [
   new Grid(null, null, null, overallCharts),
];


export default [
   new Tab("Overall", 0, overallGrid)
];
