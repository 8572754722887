import { Avatar, Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import myImage from './Assets/glow.png'
import PixisLogo from '../../Assets/Images/logos_pixis.svg'
import './leftbar.scss'
import ManageSources from "./ManageSources";
import CollapsedView from "./CollapsedView";
import { connect } from "react-redux";
import Logouticon from "./Assets/logout_icon.svg"
import LogoutModal from "../Modal/LogoutModal";



const LeftBar = ({ user, navigationCallback }) => {

  const [isPanelExpanded, setIsPanelExpanded] = useState(false);
  const [openBrandOptions] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modal, setModal] = React.useState(false);

  const useStyles = {
    wrapper: {
      top: 0,
      overflow: 'hidden',
      left: 0,
      padding: '24px 24px 24px 16px',
      position: 'fixed',
      height: "100%",
      boxSizing: 'border-box',
      zIndex: 2000,
    },
    root: {
      boxSizing: 'border-box',
      position: 'relative',
      height: '100%',
      width: '80px',
      overflow: 'hidden',
      scrollbarWidth: 'thin',
      scrollbarColor: 'gray transparent',
      backgroundColor: ' #0F0F0F',
      backgroundImage: `url('${myImage}')`,
      backgroundPosition: 'center top',
      color: 'rgba(255, 255, 255, 0.56)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'auto 100%',
      transition: 'width 0.3s ease',
      borderRadius: '10px',
      '&:hover': {
        width: '256px',
        overflow: 'auto',
      },
      '&::-webkit-scrollbar': {
        // width: 1,
        // height: 1,
        display: "none"
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        borderRadius: 20,
      },
    },

  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setModal(true);
    handleClose();
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(names) {
    return {
      sx: {
        bgcolor: stringToColor(names),
      },
      children: `${names.split(' ')[0][0]}`,
    };
  }

  // const classes = useStyles();
  return (
    <>
      <Box sx={useStyles.wrapper} id="left-panel-wrapper">
        <Box id="left-panel-root"
          // className={classes.root}
          sx={
            useStyles.root
          }
          onMouseEnter={() => {
            setIsPanelExpanded(true);
          }}
          onMouseLeave={() => {
            !openBrandOptions && setIsPanelExpanded(false);
          }}
        >
          <Stack direction="row" alignItems="center" style={{ width: '240px' }} justifyContent="space-between">
            <Stack direction="row" alignItems="center">
              <Box style={{ height: '40px', width: '40px', margin: '21px 20px 17px' }}>
                <img style={{ width: '40px', height: '40px' }} src={PixisLogo} alt="" />
              </Box>
              {true && (
                <Typography
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: '18px',
                    fontWeight: '600',
                    color: '#FFF',
                    lineHeight: '22px',
                    // marginLeft: '10px',
                  }}
                >
                  Pixis Analytics
                </Typography>
              )}
            </Stack>

          </Stack>
          <div style={{ marginLeft: 0, transition: 'margin 0.3s ease' }}>
            <ManageSources hideOptions={!isPanelExpanded} navigationCallback={navigationCallback}/>
          </div>

          <div className="scrollBar0"
            style={{
              transition: 'margin 0.3s ease',
              // marginTop: '14px',
              position: 'relative',
              marginLeft: 0,
              height: "calc(100% - 220px)",
              overflow: "auto",
            }}
          >
            <CollapsedView isPanelExpanded={isPanelExpanded} navigationCallback={navigationCallback}/>
          </div>
          <Box>
            {isPanelExpanded ? (
              <div
                style={{
                  width: isPanelExpanded ? '256px' : '80px',
                  transition: 'width 0.3s ease',
                  textAlign: 'left',
                  background: isPanelExpanded ? '#1F1F41' : '#1F1F41',
                  overflow: 'hidden',
                  borderRadius: '0 0 10px 10px',
                }}
              >
                <div
                  style={{
                    width: isPanelExpanded ? '256px' : '80px',
                    padding: '10px 10px 10px 16px',
                    borderRadius: '10px',
                    transition: 'width 0.3s ease',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: "space-between"
                  }}
                >
                  <span className="accnt_pro" style={{
                    display: 'flex', verticalAlign: 'top',
                  }}>
                    <Avatar style={{ width: '48px', height: '48px', textTransform: "capitalize", fontFamily: "Inter", fontSize: "16px", fontWeight: "600" }}
                      {...stringAvatar(user?.name)} />
                    <div style={{ display: isPanelExpanded ? "flex" : "none", alignItems: "center" }}>
                      <div style={{ marginLeft: "20px" }}>
                        <Typography style={{
                          fontFamily: "Inter", fontSize: "14px", fontWeight: "600",
                          lineHeight: "20px", color: "#FFF", textTransform: "capitalize",
                          whiteSpace: "nowrap", overflow: "hidden", width: "130px", textOverflow: 'ellipsis'
                        }}>
                          {user?.name}
                        </Typography>
                        <Typography style={{
                          fontFamily: "Inter", fontSize: "11px", fontWeight: "500",
                          lineHeight: "22px", color: "inherit",
                          overflow: "hidden", width: "130px", textOverflow: 'ellipsis',
                        }}
                          // sx={{
                          //   color: "rgba(255, 255, 255, 0.32)",
                          //   "&:hover": {
                          //     color: "#FFF"
                          //   }
                          // }}
                          title={user && user?.email && user?.email.length > 18 ? user?.email : ''}
                        >
                          {user?.email}
                        </Typography>
                      </div>
                    </div>
                  </span>
                  <button className="logout" onClick={() => handleLogout()} style={{ cursor: "pointer", background: "none", border: "none" }}> <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="log" d="M16 16.5V18.5C16 20.7091 14.2091 22.5 12 22.5H7C4.79086 22.5 3 20.7091 3 18.5V6.5C3 4.29086 4.79086 2.5 7 2.5H12C14.2091 2.5 16 4.29086 16 6.5V8.5" stroke="#6D6E7C" stroke-width="1.5" stroke-linecap="round" />
                    <path class="log" d="M19 15.5L21.2929 13.2071C21.6834 12.8166 21.6834 12.1834 21.2929 11.7929L19 9.5" stroke="#6D6E7C" stroke-width="1.5" stroke-linecap="round" />
                    <path class="log" d="M21 12.5L9 12.5" stroke="#6D6E7C" stroke-width="1.5" stroke-linecap="round" />
                  </svg>
                  </button>
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: isPanelExpanded ? '256px' : '80px',
                  transition: 'width 0.1s ease',
                  textAlign: 'left',
                  background: isPanelExpanded ? 'rgba(31, 31, 65, 1)' : 'rgba(26, 22, 44, 1)',
                  overflow: 'hidden',
                  borderRadius: '0 0 10px 10px',
                }}
              >
                <div
                  style={{
                    width: isPanelExpanded ? '268px' : '80px',
                    padding: '10px 10px 10px 16px',
                    borderRadius: '10px',
                    transition: 'width 0.3s ease',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ display: 'inline-block', verticalAlign: 'top' }}>

                    <Avatar style={{
                      width: '48px', height: '48px', textTransform: "capitalize", fontFamily: "Inter", fontSize: "16px", fontWeight: "600",
                    }} {...stringAvatar(user?.name ?? "")} />
                  </span>
                </div>
              </div>
            )}
          </Box>


        </Box>

      </Box>
      <LogoutModal modal={modal} setModal={setModal} />
    </>
  )
}
const mapStateToProps = (state) => ({
  selectedAccounts: state.channel.selectedAccounts,
  user: state.user.user,
});

export default connect(mapStateToProps)(LeftBar);