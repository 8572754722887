import { IconButton, InputAdornment, TextField } from "@mui/material";
import { ReactComponent as SearchMenu } from '../../Assets/Icons/search-icon.svg';

export default function SearchBarBE({ getData,setSearchText,searchText }) {
    return (
        <TextField
            placeholder="Search"
            id="outlined-basic"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={() => getData(searchText)}>
                           <SearchMenu style={{ width:"100%",height:"100%" }}/>
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            variant="outlined"
            size="small"
            value={searchText}
            onChange={event => setSearchText(event.target.value)}
            onKeyUp={(e) => {
                if(e.keyCode === 13){
                    getData(searchText);
                }
            }}
            sx={{
                width: "100%",
                '& legend': { display: 'none' },
                '& fieldset': { top: 0, borderRadius: "6px", border: "1px solid #EAEAEC", }
            }}
        />
    )
}