import Modal from 'react-modal';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button, Grid } from '@mui/material';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: "#ffff",
		width: "400px",
		height: "250px",
		padding: "0",
		zIndex: 9000000000000,
		position: "absolute"
	},
};

const SelectingChannelErrorModal = (props) => {
	const { modal, setModal } = props;
	return (
		<Modal isOpen={modal} style={customStyles}>
			<div style={{ width: "100%", height: "40px", borderBottom: "1px solid #eee", padding: "10px", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
				<div>
					<Button onClick={() => setModal(!modal)}>X</Button>
				</div>
			</div>
			<div style={{ width: "100%", height: "calc(100% - 40px)", position: "relative", zIndex: 90000000, backgroundColor: "#ffff", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}>
				<div style={{ display: "flex", flexDirection: "column", width: "100%", paddingLeft: "15px", height: "80%", alignItems: "center", justifyContent: "center" }}>
					<Grid item xs={12} style={{ padding: '4px 0 4px 0', textAlign: "center", color: "#333333", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
						<p><CancelIcon style={{ color: "red", width: "50px", height: "40px" }} /></p>
						<h3 style={{ color: "red" }}>Oops</h3>
						<p>Error in connecting your account</p>
						<Button style={{ backgroundColor: "blue", color: "#ffff" }} onClick={() => setModal(!modal)}>TRY AGAIN</Button>
					</Grid>
				</div>
			</div>
		</Modal>
	)
}
export default SelectingChannelErrorModal;