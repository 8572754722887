import Loader from "./Loader";

export default function MainLoader({ heading = "Waiting for data", subHeading = "It can take few minutes to pull your data" }) {
    return (
        <div style={{
            display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",
            position: "absolute", top: 0, left: 0, width: "100vw", height: "100%",
            background: "#80808055", zIndex: 10000
        }}>
            <Loader height={500} width={500} />
        </div>
    )

}