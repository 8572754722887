import { Tab, Tooltip } from "@mui/material";
import * as React from "react";
import { Draggable } from "react-beautiful-dnd";
import { ReactComponent as DropIcon } from "../../Assets/Icons/drop-icon.svg";

export default function DraggableTab(props) {
  const { reportId, tab, tabIndex, currentTabIndex, updateTab, setAnchorEl, ...restProps } = props ?? {};

  React.useEffect(() => {
    if (tab.position !== tabIndex) {
      updateTab({
        report_id: reportId,
        tab_id: tab.tabId,
        updates: [{ id: tab.id, tab_id: tab.tabId, update_type: "update", params: { position: tabIndex }, }],
      });
    }
  }, [])

  const child = (
    <Tooltip title={tab.name} placement="bottom" arrow>
      <Tab
        className="dashboard-tab"
        label={
          <div style={{
            whiteSpace: "nowrap", overflow: "hidden",
            textOverflow: "ellipsis", width: "92px",

          }}>
            {tab.name}
          </div>}
        sx={{
          whiteSpace: "nowrap",
          textTransform: "none",
          padding: "0px",
          margin: "0px 20px 0px 0px",
          display: "flex",
        }}
        value={tabIndex}
        icon={
          currentTabIndex === tabIndex ? (
            <DropIcon
              style={{
                color: "#000000",
                marginLeft: "8px",
              }}
              onClick={(e) =>
                setAnchorEl(e.currentTarget)
              }
            />
          ) : null
        }
        iconPosition="end"
        
      />
    </Tooltip>
  );
  return (
    <Draggable draggableId={`${tabIndex}`} index={tabIndex} disableInteractiveElementBlocking>
      {draggableProvided => (
        <div ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
          {React.cloneElement(child, {
            ...restProps,
            ...draggableProvided.dragHandleProps,
            style: { cursor: "pointer", height: "100%" }
          })}
        </div>
      )}
    </Draggable>
  );
}
