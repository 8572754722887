import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import SearchBarBE from "../../Common/SearchBarBE";
import { mappedColor, changedTriggersIds } from "../Utils";
import Tick from "../../../Assets/Icons/green_icon.svg";
import { useHistory } from "react-router";

const SelectedTriggerList = ({ handleBulkEditing, listBulkEditing = [], changedTriggers, updateId, confirmBulkUpdate }) => {
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [filteredTriggers, setFilteredTriggers] = useState(listBulkEditing);

  useEffect(() => {
    searchText.trim().length > 0 ? setFilteredTriggers(listBulkEditing.filter(trigger => trigger.name.toLowerCase().includes(searchText.toLowerCase()))) : setFilteredTriggers([...listBulkEditing]);
  }, [searchText]);

  useEffect(() => {
    setFilteredTriggers(listBulkEditing);
  }, [listBulkEditing]);

  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "8px",
        background: "#FFF",
        overflowY: "auto",
        height: "100%",
        padding: "16px",
      }}
    >
      <div style={{ marginBottom: "16px" }}>
        <SearchBarBE
          getData={(text) => { }}
          setSearchText={setSearchText}
          searchText={searchText}
        />
      </div>
      {filteredTriggers.map((trigger, index) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            background: updateId === trigger._id ? "#F6F7F8" : "#fff",
            borderLeft: updateId === trigger._id ? `2px solid ${mappedColor[trigger.status]}` : "",
            justifyContent: "space-between",
            paddingLeft: "16px",
            paddingRight: "16px"
          }}
          key={index}
          onClick={() => {
            history.push("?=" + trigger._id);
            handleBulkEditing("SET_CURRENT_TRIGGER", trigger);
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "6px",
                height: "19px",
                background: mappedColor[trigger.status],
                borderRadius: "100px",
                marginRight: "8px",
              }}
            ></div>
            <Typography
              style={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "20px",
                inlineSize: "150px",
                overflowWrap: "break-word",
                paddingTop: "16px",
                paddingBottom: "16px",
                marginRight: "10px",
                marginLeft: "5px",
              }}
            >
              {trigger.name}
            </Typography>
          </div>
          {changedTriggers[trigger._id] && <img src={Tick} alt="" />}
        </div>
      ))}
    </Box>
  );
};

export default SelectedTriggerList;
