import moment from "moment";
import { CHANGE_COMPARE_DATE, CHANGE_DATE, UPDATE_FILTER_CHART_MAP, UPDATE_TOUR } from "./type";

const formatDate = date => moment(date).format("YYYY-MM-DD");

const endDate = new Date();
endDate.setDate(endDate.getDate() - 1);
const startDate = new Date();
startDate.setDate(startDate.getDate() - 7);

const INITIAL_STATE = {
    dateRange: {
        label: "Last 7 Days",
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
    },
    compareDateRange: {
        label: "",
        startDate: "",
        endDate: "",
    },
    filterChartMap: {}
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CHANGE_DATE:
            return { ...state, dateRange: { ...action.payload, startDate: formatDate(action.payload.startDate), endDate: formatDate(action.payload.endDate) } };
        case CHANGE_COMPARE_DATE:
            return {
                ...state, compareDateRange: {
                    ...action.payload,
                    startDate: action.payload.startDate && formatDate(action.payload.startDate),
                    endDate: action.payload.endDate && formatDate(action.payload.endDate)
                }
            };
        case UPDATE_FILTER_CHART_MAP:
            return { ...state, filterChartMap: action.payload }
        default:
            return state;
    }
};

export default reducer;
