import AppBar from "../Dashboard/AppBar";
import Grid from '@mui/material/Grid';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import LeftBar from "../LeftBar/LeftBar";

const PrivacyPolicy = () => {
    const history = useHistory();
    return (
        <>
            {/* <AppBar/> */}
            <LeftBar />
            <div style={{ padding: "24px 24px 10px 124px", height: "100vh" }}>
                <Box sx={{
                    background: "#FFF", borderRadius: "12px", width: "100%", padding: "24px", height: "calc( 100% - 15px )", overflow: "auto"
                }}>
                    <Typography style={{ fontFamily: "Inter", fontWeight: "600", fontSize: "20px", lineHeight: "32px" }}>Privacy Policy</Typography>
                    <Typography style={{ fontFamily: "Inter", fontWeight: "400", fontSize: "12px", lineHeight: "20px", color: "#5C6674" }}>Effective from 01 September 2022</Typography>
                    <p style={{ borderBottom: "1px solid #EAEAEC", width: "100%", marginTop: "24px", marginBottom: "24px" }}></p>

                    <Grid container>
                        <Grid xs={12}>
                            <p>
                                <b>1. INTRODUCTION</b><br /><br />
                                Pixis (“us,” “we,” or “Company”) is committed to respecting the privacy rights of visitors at https://www.pixis.ai (“the Site”) and users of Pixis’s products and features platform and associated services (“Services”). We created this Privacy Policy (“Privacy Policy”) to demonstrate our commitment to fair information practices and privacy protection. Pixis’s Services include an online platform that enables users to create dynamic and compelling analytics reports and infographics. The Personal Information that Pixis processes, collects, and stores is to allow your use of any of the Pixis services.
                                <br /><br />
                                This Privacy Policy is only applicable to the Site and the Services available there on and not applicable to any other websites that you may be able to access from the Site via links, each of which may have data collection, storage, and use practices and policies that differ materially from this Privacy Policy. By using Company’s Services, you consent to Company’s use of the information you provide in connection with creating a trial or paid Pixis account or when reaching out to Company for assistance or enquiring about the Services via chat, contact forms, or other communication tools available at the Site, as set forth in this Privacy Policy. Please be aware that we are not responsible for the privacy practices of any third parties.
                            </p>
                        </Grid>
                        <Grid xs={12}>
                            <p>
                                <b>2. Information collection practices</b><br /><br />
                                <span>2.1. Information we collect.</span><br /><br />
                                (a) Our Service: We may collect personally identifiable information about you, such as your full name, phone number, email address, or similar when you register for an account and order our Services, even if for a free trial. If you choose to purchase Services from us, our third-party payment processors or we will collect your payment information. If you communicate with us by email, chat, or otherwise for the purposes of Services, such as receiving support, any information provided in such communication may be collected as Personal Information, and you should not disclose Personal Information, which you are not allowed to disclose to us. The legal basis to process such personal data is the necessity to enter into or perform the agreement on the provision of the Services.

                                <br />
                                We may send you service-related emails related to your account. If you no longer wish to receive these emails, you may opt-out of them by canceling your account. We may also send you occasional promotional emails. You can opt-out of the promotional and marketing emails at any time.
                                <br />
                                When you use or register for our Service or otherwise interact with us, we may collect the following information from our users that may, in certain circumstances, constitute personal data:

                                <ul style={{ display: "flex", flexDirection: "column" }}>
                                    <li>Password;</li>
                                    <li> E-mail address;</li>
                                    <li>Phone number;</li>
                                    <li>Name;</li>
                                    <li>Client contacts and feedback;</li>
                                    <li>Information concerning the use of the Service;</li>
                                    <li>Information necessary for communications;</li>
                                    <li>Permits and consents;</li>
                                    <li>Other data obtained under the user’s consent.</li>

                                </ul>
                                (b) Analytics<br />
                                We may use third-party Service Providers to monitor and analyse the use of our Service.
                                Google Analytics
                                Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.
                                You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits activity.
                                For more information on the privacy practices of Google, please visit the Google Privacy & Terms<br />
                                <b>web page: </b><a href="http://www.google.com/intl/en/policies/privacy/">http://www.google.com/intl/en/policies/privacy/</a><br /><br />
                                (c) Cookies. Cookies are pieces of text that may be sent to and saved by your browser when you access our Site; your web browser stores these cookies in a way associated with each website you visit, and you can see your cookies through your browser settings. We use cookies to enable our servers to recognize your web browser and tell us how and when you use our Services. Our cookies do not, by themselves, contain information that personally identifies you, and we don’t combine the general information collected through cookies with other such information to tell us who you are. However, we do use cookies to identify that you have logged in and that your web browser has accessed aspects of the Services, and that we may associate that information with the account you may have created for your use of the Services. This information, in turn, is something used to personalize your experience with the Services. Most web browsers have an option for turning off the cookie feature, which will prevent your browser from accepting new cookies, as well as (depending on the sophistication of your web browser software) allowing you to decide on acceptance of each new cookie in a variety of ways. If you reject cookies, you may still use our Sites, but your ability to use some features or areas of our Sites may be limited.<br />
                                Pixis does not collect personally identifiable browsing information from users that exercise the “Do Not Track” option on their browser(s). Please note that this Privacy Policy applies to our use of cookies only, and does not cover the use of cookies by third parties.<br />
                                The legal basis for use of cookies is your consent. You disagree with the use of cookies by selecting your choice in the banner at the Site.<br /><br />
                                (d) Information Related To Data Processed For Our Customers: Pixis processes information under the direction of its customers, and has no direct relationship with the individuals whose data it may process. If you are a client of one of our current customers and would no longer like to be tracked by such a customer, please contact the respective customer directly.<br /><br />
                                (e) Access And Retention Of Data Controlled By Our Customers: An individual who is a current or former Pixis customer who seeks access or wishes to correct, amend or delete inaccurate data should direct his query to gdpr@Pixis.com. An individual who is a client of a current or former Pixis customer who seeks access or wishes to correct, amend or delete inaccurate data should direct his query to the respective customer first and then, if no response is obtained or such response is not satisfactory, should contact gdpr@Pixis.com. If we receive a request under this provision, we will acknowledge it within seventy-two (72) business hours and handle it promptly and as required by law.<br /><br />
                                <br />We will retain personal data we process for as long as needed to provide our services. Pixis will retain this Personal Information as necessary to comply with our legal obligations, resolve disputes and enforce our agreements.
                                (f) Integration Data: When authorizing any of the integrations offered with the service, our code will have access to any data made available by the integrations API. If the integration requires a specific set of permission scopes to be authorized by the user, the API data accessed will also be limited to these scopes.<br /><br />
                                <br />Data retrieved from certain integrations may be cached for a short period and may be stored on our servers to improve the deliverability of data with the service.<br />
                                Data retrieved from certain integrations may be cached for a short period and may be stored on our servers to improve the deliverability of data with the service.<br />
                                The data obtained from integrations will only be used in the dashboard and reports that you and your users have access to. The data is not used internally or in any place that you do not have access to.<br />
                                YouTube. The Service uses the YouTube API Services to display your feed and insights. Once you authorize the Service to connect to your YouTube account, at any time you may revoke access from the Google Security Settings.
                                (g) Job applicant personal data: If you're an applicant for a role with us, please note your personal data is protected by our privacy policy.<br /><br />
                                <span>2.2. How we use your information.</span><br /><br />
                                We use Contact Data to send you information about our company or our platform or services, or to contact you when necessary (i.e. notices relating to the Service; service alerts; responses to your questions and concerns). We use your Financial Data to bill you for our paid Services. While we reserve the right to send you emails concerning your account billing status and service alerts, you may opt-out of our general marketing and promotion emails.
                                <span>2.3. How we share your information.</span><br /><br />
                                <span>(a) Personally Identifiable Information: Pixis will not rent or sell your Personally Identifiable Information to others. We may store Personal Information in locations outside the direct control of Pixis (for instance, on servers or databases co-located with hosting providers). Any personally identifiable information you elect to make publicly available on our Sites or Pixis Services, such as posting comments on our blog page, will be available to others. If you remove information that you have made public on our Sites or Pixis Services, copies may remain viewable in cached and archived pages of our Sites or Pixis Services, or if other users have copied or saved that information.</span><br />
                                We share certain categories of Personally Identifiable Information we collect from you in the ways described in this Privacy Policy. We share Contact Data and Financial Data with our business partners who assist us by performing core services (such as hosting, billing, fulfillment, data storage, and security) related to our operation of the Site. Those business partners only have permission to use your Contact Data and other Personal Information to carry out their specific business obligations to the Company<br />
                                If you registered for a free trial of the Services or enquired about our Services in a chat or web form, we may share your contact details with our partners who help us market and resell our Services so that they are able to contact you and enquire about your experience during the trial or help you use and evaluate the trial Services or to agree on commercial terms of the Services.
                                If you do not want us to share your Contact Data with any third parties, please email us at gdpr@Pixis.com, but please understand that such a request will limit your ability to take advantage of all of the features and services we offer on the Site. Your selection and payment for a paid Pixis plan constitutes your permission for Pixis to store, process, and share your Financial Data for billing and fulfillment purposes.
                                <br /><br />
                                (b) Instances Where We Are Required To Share Your Information: Pixis will disclose your information where required to do so by law, if subject to subpoena or other legal proceeding or if we reasonably believe that such action is necessary to (a) comply with the law and the reasonable requests of law enforcement; (b) to enforce our Terms of Service or to protect the security or integrity of our Service; and/or (c) to exercise or protect the rights, property, or personal safety of Pixis, our users or others.
                                <br /><br />
                                (c) What Happens In The Event Of A Change Of Control: We may buy or sell/divest/transfer the company (including any shares in the company), or any combination of its platform, services, assets and/or businesses. Your information such as customer names and email addresses, and other User information related to Pixis Services may be among the items sold or otherwise transferred in these types of transactions. We may also sell, assign or otherwise transfer such information in the course of corporate divestitures, mergers, acquisitions, bankruptcies, dissolutions, reorganizations, liquidations, similar transactions or proceedings involving all or a portion of the company. You will be notified via email and/or a prominent notice on our Site of any change in ownership or uses of your Personal Information, as well as any choices you may have regarding your Personal Information.
                                <br /><br />
                                (d) Behavioral Targeting / Retargeting: We partner with third parties to manage our advertising on other sites. Our third-party partners may use technologies such as cookies to gather information about your activities on this Site and other sites in order to provide you advertising based upon your browsing activities and interests. The only way to completely “opt-out” of the collection of any information through cookies or other tracking technology is to actively manage the settings on your browser or mobile device. Please refer to your browser’s or mobile device’s technical information for instructions on how to delete and disable cookies, and other tracking/recording tools (to learn more about cookies, clear gifs/web beacons and related technologies, you may wish to visit http://www.allaboutcookies.org and/or the Network Advertising Initiative’s online resources, at http://www.networkadvertising.org). If you access Pixis Services or Sites on your mobile device, you may not be able to control tracking technologies through the settings.
                                Google Ads<br />
                                Google Ads remarketing service is provided by Google Inc.<br />
                                You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads by visiting the Google Ads Settings page: http://www.google.com/settings/ads<br />
                                Google also recommends installing the Google Analytics Opt-out Browser Add-on - <a href="https://tools.google.com/dlpage/gaoptout/">https://tools.google.com/dlpage/gaoptout</a> - for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics.<br />
                                For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: <a href="http://www.google.com/intl/en/policies/privacy/">http://www.google.com/intl/en/policies/privacy/</a><br />
                                Facebook<br />
                                Facebook remarketing service is provided by Facebook Inc.<br />
                                You can learn more about interest-based advertising from Facebook by visiting this page: <a href="https://www.facebook.com/help/164968693837950/">https://www.facebook.com/help/164968693837950</a><br />
                                To opt-out from Facebook's interest-based ads follow these instructions from Facebook: <a href="https://www.facebook.com/help/568137493302217/">https://www.facebook.com/help/568137493302217</a><br />
                                Facebook adheres to the Self-Regulatory Principles for Online Behavioral Advertising established by the Digital Advertising Alliance. You can also opt-out from Facebook and other participating companies through the Digital Advertising Alliance in the USA http://www.aboutads.info/choices/, the Digital Advertising Alliance of Canada in Canada http://youradchoices.ca/ or the European Interactive Digital Advertising Alliance in Europe http://www.youronlinechoices.eu/, or opt-out using your mobile device settings.<br />
                                For more information on the privacy practices of Facebook, please visit Facebook's Data Policy: https://www.facebook.com/privacy/explanation<br /><br />
                                (e) Testimonials: We display personal testimonials of satisfied customers on our Site in addition to other endorsements. With your consent, we may post your testimonial along with your name. If you wish to update or delete your testimonial, you can contact us at gdpr@Pixis.com.
                                <br /><br />
                                (f) User choice regarding collection, use, and distribution of Personal Information. You may choose not to provide us with any Personal Information. In such an event, you may still access the Site; however you will not be able to access and use the Services because, per our Terms of Use, Pixis requires accurate Personal Information for the creation of an account that allows the use of our Services.
                                <br /><br />
                                (g) Information retention policy. Personal Information will only be retained by the company for as long as necessary to provide you with Services, and no longer than 2 years. Company’s billing options for paid plans provide for periodic billing, and Company may retain your Financial Data for that purpose.
                                <span>2.4.  How we store and process your information.</span><br /><br />
                                Your information collected through Pixis Services may be stored and processed in the United States, Europe, or any other country in which Pixis or its subsidiaries, affiliates or service providers maintain facilities. Pixis may transfer information that we collect about you, including Personal Information, to affiliated entities, or to other third parties across borders and from your country or jurisdiction to other countries or jurisdictions around the world. If you are located in the European Union or other regions with laws governing data collection and use, please note that we will not transfer information, including Personal Information, to a country and jurisdiction that does not have the same data protection laws as your jurisdiction.
                                <br />
                            </p>
                        </Grid>
                        <Grid xs={12}>
                            <p>
                                <b>3. Confidentiality and security of Personal Information</b><br /><br />
                                Except as otherwise provided in this Privacy Policy, we will keep your Personal Information private and will not share it
                                with third parties, unless such disclosure is necessary to: (a) comply with a court order or other legal process; (b) protect our
                                rights or property; or (c) enforce our Terms of Service. Your Personal Information is stored on secure servers that are not accessible by
                                third parties.<br /><br />
                                <span>3.1. User ability to access, update, and correct Personal Information.</span><br /><br />
                                We maintain a procedure in order to help you confirm that your Personal Information remains correct and up-to-date. At any time, you may log in to your account, accessible on the Site, and review your Personal Information for accuracy and, if necessary, update your Personal Information that we have already collected, namely Name, Surname, email, company name, address, card details, phone number and password.
                                Should you wish to delete your information, you can request this by contacting us directly at gdpr@Pixis.com. We will acknowledge your request within seventy-two (72) hours and handle it promptly and as required by law.
                                Alternatively, we will retain your information for as long as your account is active or as needed to provide you with services. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes and enforce our agreements.<br /><br />
                                <span>3.2. Lost or stolen information.</span><br /><br />
                                You must promptly notify us if your credit card, username, or password is lost, stolen, or used without permission. In such an event, we will remove that credit card number, user name, or password from your account and update our records accordingly.<br /><br />
                                <span>3.3. Public information.</span><br /><br />
                                The Site contains links to other websites. We are not responsible for the privacy practices or the content of such websites. We also make our blog and our social media accounts available to you. Please understand that any information that is disclosed and/or shared on these platforms becomes public information. We have no control over its use and you should exercise caution when deciding to disclose your Personal Information and any other information you consider private.<br /><br />
                                <span>3.4. Data provided by you to create reports.</span><br /><br />
                                Pixis’s Services enable you to upload your data to create reports embedded with or otherwise displaying your data. Per our Terms of Use, you are solely responsible for respecting your own privacy and confidentiality interests, as well as those interests of any other person or party. It is your responsibility to ensure that any private, sensitive, or confidential information you upload in connection with your use of the Services does not become available or disclosed to unauthorized or unintended viewers. Pixis EXPRESSLY DISCLAIMS ANY RESPONSIBILITY FOR YOUR unauthorized or unpermitted SHARING, DISTRIBUTION, PUBLICATION, or display OF any PRIVATE, sensitive or confidential INFORMATION.<br /><br />
                            </p>
                        </Grid>
                        <Grid xs={12}>
                            <p>
                                <b>4. Data security</b><br /><br />
                                In order to ensure the correct use of information, we have put in place physical, electronic, and managerial procedures to safeguard and secure the information we collect in association with the Service. Please be aware, however, that no data transmissions over the Internet or other networks can be guaranteed to be 100% secure. Consequently, we cannot ensure or warrant the security or integrity of any information you transmit to us or that you authorize us to collect on your behalf. You transmit information to us and authorize us to collect information on your behalf, at your own risk. Once we receive your information, we make reasonable efforts to protect it from unauthorized access, disclosure, alteration, or destruction. If you have any questions about security on our Site, you can contact us at gdpr@Pixis.com.
                                If Pixis learns of a security systems breach we may attempt to notify you electronically within 72 hours so that you can take appropriate protective steps. The Company may post a notice through the Service if a security breach occurs. If this happens, you will need a web browser enabling you to access the Service. The Company may also notify you via email in these circumstances. Depending on where you live, you may have a legal right to receive notice of a security breach in writing.<br /><br />
                            </p>
                        </Grid>
                        <Grid xs={12}>
                            <p>
                                <b>5. Children & minors</b><br /><br />
                                This Site and corresponding Services are not intended for or designed to attract individuals under the age of 13. Pixis does not knowingly collect personally identifiable information from ANY person we actually know is an individual under the age of 13, regardless of parental consent. Personal Information from children between the ages of 13 and 18 is only collected with the consent of their parents or guardians. In the event that we learn that we have collected Personal Information from a child under 13, we will delete that information as quickly as possible. In the event that we learn that we have collected Personal Information from a child between the ages of 13 and 18 without parental consent, we will delete that information as quickly as possible. If you believe that we might have any information from or about a child under 13, please contact us at gdpr@Pixis.com.<br /><br />
                            </p>
                        </Grid>
                        <Grid xs={12}>
                            <p>
                                <b>4.Your rights</b><br /><br />
                                You have a right to access the personal data we process about you, to request a modification of inaccuracies in your personal data, to object to the processing of your personal data, to disagree with the processing of your personal data when processing is based on consent.
                                If you believe that your right in relation to data protection was breached you have a right to lodge a complaint with the data protection supervisory institution in the country of your residence. If you reside in the EU, you may find the list of data protection supervisory institutions here - https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm.<br /><br />
                            </p>
                        </Grid>
                        <Grid xs={12}>
                            <p>
                                <b>Updates and changes to the privacy policy</b><br /><br />
                                We encourage you to review this Privacy Policy regularly for any changes. If we materially change the ways in which we use and disclose personal data, we will update the Effective Date at the top of this page, and send a notification email to our registered users. Your continued use of the Service following any changes to this Privacy Policy constitutes your acceptance of any such changes.<br /><br />
                                <br /><br />
                            </p>
                        </Grid>
                    </Grid>

                </Box>
            </div>
        </>
    )
}
export default PrivacyPolicy;