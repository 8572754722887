import { CREATE_TRIGGER, UPDATE_TRIGGER, DELETE_TRIGGER, GET_ALL_TRIGGERS, GET_TRIGGER, CHECK_EMAIL, AUTHORIZE_EMAIL, GET_SHEET_URLS, GET_ALL_LOGS, DISCONNECT_EMAIL, RUN_TRIGGER, SELECT_BULK_EDITING,UPDATE_BULK_TRIGGER } from "./type";

export const checkEmailConnected = () => {
    return {
        type: CHECK_EMAIL
    };
};

export const disconnectAccount = () => {
    return {
        type: DISCONNECT_EMAIL
    };
};

export const authorizeEmail = () => {
    return {
        type: AUTHORIZE_EMAIL
    };
};

export const getSheetUrls = () => {
    return {
        type: GET_SHEET_URLS
    };
};

export const createTrigger = payload => {
    return {
        type: CREATE_TRIGGER,
        payload: payload
    };
};

export const updateTrigger = payload => {
    return {
        type: UPDATE_TRIGGER,
        payload: payload
    };
};

export const deleteTrigger = payload => {
    return {
        type: DELETE_TRIGGER,
        payload: payload
    };
};

export const getAllTriggers = payload => {
    return {
        type: GET_ALL_TRIGGERS,
        payload: payload
    };
};

export const runTrigger = payload => {
    return {
        type: RUN_TRIGGER,
        payload: payload
    };
};

export const selectBulkEditing = payload => {
    return {
        type:  SELECT_BULK_EDITING,
        payload: payload
    }
}

export const updateBulkTrigger = payload => {
    return {
        type: UPDATE_BULK_TRIGGER,
        payload: payload
    }
}

