import { DELETE_FILTER, GET_ALL_FILTERS } from "./type";

export const deleteFilter = payload => {
    return {
        type: DELETE_FILTER,
        payload: payload
    };
};

export const getAllFilters = payload => {
    return {
        type: GET_ALL_FILTERS,
        payload: payload
    };
};
