import { v4 as uuid } from 'uuid';
import { GetChannel } from "../../models/channel";
import { Metric } from '../../models/chart';

export const transformCreateBlendRequest = (source) => {
    const dsConfigs = source.dataSources.map(dataSource => ({
        dataSource: dataSource.source.type,
        audiences: [{ type: "AD_ACCOUNT", id: dataSource.account.id, dataSource: dataSource.source.type, metaData: dataSource.account }],
        dsOptions: {
            ...(GetChannel(dataSource.source.type).transformer.getTableObject?.(dataSource.table) ?? {}),
            ...((dataSource.filter ? GetChannel(dataSource.source.type).transformer.getFilterObject?.(dataSource.filter) : null) ?? {})
        },
        dimensions: [...dataSource.dimensions.map(dimension => ({ ...dimension, uiName: dimension.name })), ...dataSource.keys.map(key => ({ ...key, uiName: key.name }))],
        metrics: dataSource.metrics.map(metric => ({ ...metric.metric, uiName: metric.metric.name })),
        dataLevel: null //dataSource.source.type === "tiktok-ads" ? dataSource.dataLevel?.id : null
    }));

    const joins = source.dataSources.slice(0, source.dataSources.length - 1).map((dataSource, sourceIndex) => ({
        joinType: dataSource.join,
        keys: dataSource.keys.map((key, keyIndex) => ({
            left: key.id,
            right: source.dataSources[sourceIndex + 1].keys[keyIndex].id
        }))
    }));

    const requestObj = {
        name: source.name,
        dsConfigs,
        joins: joins
    };

    return requestObj;
}

export const transformCreateDataSourceObject = (source) => {
    const dataObj = {
        id: source.id,
        name: source.name,
        dataSources: source.dsConfigs.map((config, index) => ({
            id: uuid(),
            account: config.audiences?.[0]?.metaData,
            keys: config.dimensions.filter(d => source.joins[index] ?
                source.joins[index].keys.map(key => key.left).includes(d.id) :
                source.joins[index - 1].keys.map(key => key.right).includes(d.id)
            ).map(k => ({ ...k, name: k.uiName })),
            dimensions: config.dimensions.filter(d => source.joins[index] ?
                !source.joins[index].keys.map(key => key.left).includes(d.id) :
                !source.joins[index - 1].keys.map(key => key.right).includes(d.id)
            ).map(d => ({ ...d, name: d.uiName })),
            metrics: config.metrics.map(m => new Metric({ ...m, name: m.uiName })),
            source: GetChannel(config.dataSource),
            join: source.joins[index]?.joinType,
            table: GetChannel(config.dataSource).transformer.getTableFromObject?.(config.dsOptions),
            filter: config.dsOptions?.filterGrouping?.filterId
                ? { id: config.dsOptions?.filterGrouping?.filterId }
                : config.dsOptions?.filterObj,
            dataLevel: config.dataLevel ? { id: config.dataLevel, name: capitalizeString(config.dataLevel?.split("_").join(" ")) } : null
        })),
    };

    return dataObj;
}

export const filterAbsoluteMetrics = (metrics = [], channelType = "default") => {
    return metrics?.filter(metric => ["INTEGER", "FLOAT", "PERCENT", "CURRENCY"].includes(metric.dataType))
}

export const dimensionFunctions = {
    Left: {
        active: false,
        formula_parts: ["String", "Delimiter", "Field_number"],
        example: "LEFT(Campign,”_”,1)"
    },
    Right: {
        active: false,
        formula_parts: ["String", "Delimiter", "Field_number"],
        example: "RIGHT(Campign,”_”,1)"
    },
    Splitpart: {
        active: true,
        formula_parts: ["Dimension_column", "Delimiter", "Field_number"],
        example: "SPLITPART(Campign,”_”,1)"
    },
    Split: {
        active: false,
        formula_parts: ["String", "Delimiter", "Field_number"],
        example: "SPLIT(Campign,”_”,1)"
    },
    Trim: {
        active: false,
        formula_parts: ["String", "Delimiter", "Field_number"],
        example: "TRIM(Campign,”_”,1)"
    },
    Replace: {
        active: true,
        formula_parts: ["Dimension_column", "Replace_string", "Replace_with_string"],
        example: 'REPLACE(Campign,”_”,"-")'
    },
    Concatenate: {
        active: false,
        formula_parts: ["String", "Delimiter", "Field_number"],
        example: "CONCATENATE(Campign,”_”,1)"
    },
    Substring: {
        active: false,
        formula_parts: ["String", "Delimiter", "Field_number"],
        example: "SUBSTRING(Campign,”_”,1)"
    },
    Regex: {
        active: false,
        formula_parts: ["String", "Delimiter", "Field_number"],
        example: "REGEX(Campign,”_”,1)"
    }
}

export const validFormulaType = (type) => {
    switch (type) {
        case "Field_number":
            return "number"
        default:
            return "string"
    }
}

export const capitalizeString = (str) => {
    if (!str) {
        return "";
    }
    let tempStr = "";
    str.split(" ").forEach((s) => {
        tempStr += s.charAt(0).toUpperCase() + s.substring(1, s.length).toLowerCase() + " ";
    });
    return tempStr;
}