import { useRef, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  IconButton,
  Typography,
  Paper,
  TextField,
  Input,
  InputAdornment,
  Tooltip,
  List,
} from "@mui/material";
import {
  ChannelType,
  GetChannel,
  SupportedChannels,
} from "../../../models/channel";
import { useSelector } from "react-redux";
import { ReactComponent as PipeIcon } from "../../../Assets/Icons/pipe.svg";
import DataList from "../../CustomizingSources/BlendDataSource/DataList";
import MultipleSelectList from "../../Common/MultipleSelectList";
import "../../styles/profile.css";
import MultipleSelectCheckList from "../../Common/MultipleSelectCheckList";
import {
  authorizeEmail,
  checkEmailConnected,
  createTrigger,
  getSheetUrls,
  updateTrigger,
} from "../../../api/extractor/action";
import { getAccountBasedColumns } from "../../../api/channel/saga";
import { useDispatch } from "react-redux";
import { ReactComponent as Save } from "../../../Assets/Icons/save-icon.svg";
import { ReactComponent as CloseIcon } from "../../../Assets/Icons/close-icon.svg";
import { ReactComponent as Edit } from "../../../Assets/Icons/edit-icon.svg";
import { ReactComponent as GoogleIcon } from "../../../Assets/Images/logos_google.svg";
import { ReactComponent as GoogleDriveIcon } from "../../../Assets/Images/GoogleDrive.svg";
import TriggerParameters from "./TriggerParameters";
import TriggerSchedule from "./TriggerSchedule";
import TriggerIngestion from "./TriggerIngestion";
import moment from "moment";
import {
  ORDER_OPTIONS,
  transformToSuperMetricDataObject,
  STORAGE_OPTIONS,
} from "../Utils";
import { updateLoader } from "../../../api/user/action";
import CustomLoader from "../../Common/CustomLoader";
import { borderRadius } from "@mui/system";
import SelectedTriggerList from "../BulkEditing/SelectedTriggerList";
import { handleMixPanelData,EVENTS } from "../../../utils/mixpanelV2";

export const defaultOrderBy = {
  fieldName: null,
  sortOrder: ORDER_OPTIONS[0],
  fieldError: false,
};

const defaultStartAt = {
  on: null,
  date: null,
  time: { hour: 10, min: 0 },
}; // Schedule

const defaultStorage = {
  headerLine: false, //hasHeaders
  startCell: { column: "", row: "" }, //startingCell
  endCell: { column: "", row: "" }, //endingCell
  newEmptySheet: { id: "yes", name: "Yes" }, //spreadsheetCreate
  existingSheet: null, //spreadsheetId
  sheetNomenclature: "", //spreadsheetName
  tabNomenclature: "", //tabName
  sheetFormat: [],
  tabFormat: [],
  existingFolder: {
    id: "root",
    name: "Root Folder",
    type: "folder",
  },
};

export default function Extractor({
  updateId,
  extractData = {},
  setExtractData,
  setTriggerId,
  setCompletion,
  extra
}) {
  const dispatch = useDispatch();
  const allTriggers = useSelector((state) => state.extractor.allTriggers);
  const triggersNameList = allTriggers.map((trigger) => trigger.name);
  const blends = useSelector((state) => state.blends.blends);
  const selectedAccounts = useSelector(
    (state) => state.channel.selectedAccounts
  );

  const email_connected = useSelector(
    (state) => state.extractor.email_connected
  ); // Navigation
  const authenticatedAccounts = useSelector(
    (state) => state.channel.authenticatedAccounts
  );
  const isChannelAuthenticated = (channel) =>
    !!authenticatedAccounts.find((type) => type === channel?.type);

  const blendChannelData =
    extractData?.source &&
    blends.find((blend) => blend.id === extractData.source);
  const [channel, setChannel] = useState(GetChannel(extractData.channelType));

  const state = useSelector((state) => channel?.getState(state));
  const user = useSelector((state) => state.user.user);
  const loader = useSelector((state) => state.user.loading)
  const accountList = state?.accountData?.accountList ?? [];
  const tables = state?.tables;

  const [accounts, setAccounts] = useState(extractData.accounts ?? []);
  const [table, setTable] = useState(extractData.table ?? null);

  const dataLevels = (state?.dataLevels?.[table?.id] ?? []).map((level) => ({
    id: level,
    name: level,
  }));
  const [dataLevel, setDataLevel] = useState(extractData.dataLevel ?? null);

  const [displayPopup, setDisplayPopup] = useState(updateId ? true : false);
  const [openScheduleBox, setOpenScheduleBox] = useState(
    updateId ? true : false
  );
  const [openIngestionBox, setOpenIngestionBox] = useState(
    updateId ? true : false
  );

  const scrollToEnd = () => {
    var containerBox = document.getElementById("containerBox")
    console.log("Expand from ", containerBox?.scrollWidth)
    setTimeout(function () {
      containerBox.scrollLeft = containerBox?.scrollWidth + 1000
      console.log("to ", containerBox?.scrollWidth)
    }, 500);
  };

  const [initialChannelUpdate, setInitialChannelUpdate] = useState(true);
  const [initialTableUpdate, setInitialTableUpdate] = useState(true);
  const [initialLevelUpdate, setInitialLevelUpdate] = useState(true);

  const [accountCols, setAccountCols] = useState({});
  const [loading, setLoading] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true); // Discard as Email Connected in Navigation
  const [count, setCount] = useState(0); // Navigation

  const nameRef = useRef(extractData.name ?? ""); // Extractor
  const [currName, setCurrName] = useState(nameRef.current);
  const [nameError, setNameError] = useState(false); // Extractor

  const formatDate = (date) => moment(date).format("YYYY-MM-DD"); // Para
  const endDate = new Date(); // Para
  endDate.setDate(endDate.getDate() - 1); // Para
  const startDate = new Date(); // Para
  startDate.setDate(startDate.getDate() - 7); // Para

  const [dateRange, setDateRange] = useState(
    extractData.dateRange ?? {
      dateType: "Last N days (7)",
      fromDate: formatDate(startDate),
      toDate: formatDate(endDate),
    }
  ); // Extractor -> Para, Ingestion

  const [compareDateRange, setCompareDateRange] = useState(
    extractData.compareDateRange ?? {
      dateType: "",
      fromDate: "",
      toDate: "",
    }
  );

  const updateDateRange = (date) => {
    setDateRange({
      dateType: date.label ?? "Custom",
      fromDate: formatDate(date.startDate),
      toDate: formatDate(date.endDate),
    });
  }; // Extractor -> Para, Ingestion

  const updateCompareDate = (date) => {
    setCompareDateRange({
      dateType: date.label ?? "Custom",
      fromDate: date.startDate && formatDate(date.startDate),
      toDate: date.endDate && formatDate(date.endDate),
    });
  };

  const [infoComplete, setInfoComplete] = useState({
    triggerComplete: false,
    scheduleComplete: false,
    storageComplete: false,
  }); // Extractor -> Para, Schedule, Ingestion

  const [triggerData, setTriggerData] = useState(
    extractData.triggerData ?? {
      metrics: [],
      aggregates: [],
      segregate: false,
      dimensions: [],
      numberOfRows: 10000,
      orderBys: [{ ...defaultOrderBy }],
      filters: {},
    }
  ); // Extractor -> Para, Ingestion

  const [scheduleData, setScheduleData] = useState(
    extractData.scheduleData ?? {
      action: null,
      timeZone: null,
      startAt: [{ ...defaultStartAt }], //
    }
  ); // Extractor -> Schedule, Ingestion

  const [storageData, setStorageData] = useState(
    extractData.storageData ?? {
      storage: null, //dataDestination
      writeMode: null, //writePreference
      ...defaultStorage,
    }
  );

  const [emailAuthLoading, setEmailAuthLoading] = useState(false);
  const isBulkEditing = extra?.type === "BULK_EDITING";

  useEffect(() => {
    dispatch(checkEmailConnected());
  }, []); // Navigation

  useEffect(() => {
    scrollToEnd()
  }, [openIngestionBox, openScheduleBox])

  useEffect(() => {
    if (email_connected) {
      if (emailAuthLoading) setEmailAuthLoading(false);
      dispatch(getSheetUrls());
    } else {
      setStorageData({ ...storageData, storage: null }); // Discard and Update Logic by flow
    }
  }, [email_connected]);

  const checkEmailAuthorizationCompleted = () => {
    if (!email_connected) {
      setCount((prevCount) => prevCount + 1);
      dispatch(checkEmailConnected());
    } else {
      setCount(0);
      setEmailAuthLoading(false);
    }
  }; // Navigation

  useEffect(() => {
    if (count !== 0) {
      if (!email_connected && count < 5) {
        const interval = setInterval(
          () => checkEmailAuthorizationCompleted(),
          20 * 1000
        );
        return () => clearInterval(interval);
      } else {
        setCount(0);
        setEmailAuthLoading(false);
        if (!email_connected) {
          setStorageData({ ...storageData, storage: null });
        }
      }
    }
  }, [count]); // Navigation

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
      if (!email_connected) {
        setStorageData({ ...storageData, storage: null });
      } else if (email_connected && !storageData.storage) {
        setStorageData({ ...storageData, storage: STORAGE_OPTIONS[0] });
      }
    }
  }, [storageData.storage]); // Discard as Email Connected in Navigation

  useEffect(() => {
    // console.log("🚀 ~ file: CreateTrigger.js:74 ~ CreateTrigger ~ triggerData:", triggerData)
    const { metrics, dimensions } = triggerData;
    if (metrics.length > 0) {
      setInfoComplete((prev) => ({ ...prev, triggerComplete: true }));
    } else {
      setInfoComplete((prev) => ({ ...prev, triggerComplete: false }));
    }
  }, [triggerData]); // Extractor

  const getAccountCols = (account) => {
    getAccountBasedColumns(account, channel)
      .then((data) => {
        let columns = data?.columns ?? [];
        setAccountCols((prev) => ({
          ...prev,
          [account.title]: {
            metrics: columns.filter((c) => c.type === "METRIC"),
            dimensions: columns.filter((c) => c.type === "DIMENSION"),
          },
        }));
      })
      .catch((err) => {
        console.log("🚀 ~ getAccountBasedColumns ~ err:", err, account);
        setAccountCols((prev) => ({
          ...prev,
          [account.title]: { metrics: [], dimensions: [] },
        }));
      })
      .finally(() => {
        setLoading((prev) => prev - 1);
      });
  };

  const closePopup = () => { setDisplayPopup(false); setOpenScheduleBox(false); setOpenIngestionBox(false); }

  useEffect(() => {
    let accLen = accounts.length;
    if (accLen == 0) {
      closePopup();
    }
    if (!channel?.isBlend && accLen) {
      if (firstLoad || accLen - 1 > Object.keys(accountCols).length) {
        setFirstLoad(false);
        setLoading((prev) => prev + accounts.length);
        for (let account of accounts) {
          if (!accountCols[account.title]) {
            getAccountCols(account);
          } else {
            setLoading((prev) => prev - 1);
          }
        }
      } else {
        let lastAccount = accounts[accLen - 1];
        if (!accountCols[lastAccount.title]) {
          setLoading((prev) => prev + 1);
          getAccountCols(lastAccount);
        }
      }
    } else {
      setAccountCols({});
    }
  }, [accounts]);

  useEffect(() => {
    if (initialChannelUpdate) {
      setInitialChannelUpdate(false);
    } else if (updateId?.length === 0 || !updateId) {
      setAccounts([]);
      setTable(null);
      setDataLevel(null);
      setExtractData({});
      setTriggerId("");
      setInfoComplete({
        triggerComplete: false,
        scheduleComplete: false,
        storageComplete: false,
      });
      setTriggerData({
        metrics: [],
        aggregates: [],
        segregate: false,
        dimensions: [],
        numberOfRows: 10000,
        orderBys: [{ ...defaultOrderBy }],
        filters: {},
      });
      setScheduleData({
        action: null,
        timeZone: null,
        startAt: [{ ...defaultStartAt }], //
      });
      setStorageData({
        storage: null, //dataDestination
        writeMode: null, //writePreference
        ...defaultStorage,
      });
      closePopup();
    }
  }, [channel]);

  useEffect(() => {
    // if (table && state?.tablesUpdated && !state?.tablesUpdated?.[table.id]) {
    //   dispatch(channel.actions?.getColumns(table));
    // }
    if (initialTableUpdate) {
      setInitialTableUpdate(false);
    } else {
      setDataLevel(null);
      setExtractData({});
      setTriggerId("");
      setInfoComplete({
        triggerComplete: false,
        scheduleComplete: false,
        storageComplete: false,
      });
      setTriggerData({
        metrics: [],
        aggregates: [],
        segregate: false,
        dimensions: [],
        numberOfRows: 10000,
        orderBys: [{ ...defaultOrderBy }],
        filters: {},
      });
      setScheduleData({
        action: null,
        timeZone: null,
        startAt: [{ ...defaultStartAt }], //
      });
      setStorageData({
        storage: null, //dataDestination
        writeMode: null, //writePreference
        ...defaultStorage,
      });
      closePopup();
    }
  }, [table]);

  useEffect(() => {
    if (initialLevelUpdate) {
      setInitialLevelUpdate(false);
    } else {
      setExtractData({});
      setTriggerId("");
      setInfoComplete({
        triggerComplete: false,
        scheduleComplete: false,
        storageComplete: false,
      });
      setTriggerData({
        metrics: [],
        aggregates: [],
        segregate: false,
        dimensions: [],
        numberOfRows: 10000,
        orderBys: [{ ...defaultOrderBy }],
        filters: {},
      });
      setScheduleData({
        action: null,
        timeZone: null,
        startAt: [{ ...defaultStartAt }], //
      });
      setStorageData({
        storage: null, //dataDestination
        writeMode: null, //writePreference
        ...defaultStorage,
      });
      closePopup();
    }
  }, [dataLevel]);

  const resetData = () => {
    nameRef.current = ""
    setCurrName("")
    setDisplayPopup(false);
    setOpenScheduleBox(false);
    setOpenIngestionBox(false);
    setChannel(null);
    setAccounts([]);
    setTable(null);
    setDataLevel(null);
    setExtractData({});
    setTriggerId("");
    setInfoComplete({
      triggerComplete: false,
      scheduleComplete: false,
      storageComplete: false,
    });
    setTriggerData({
      metrics: [],
      aggregates: [],
      segregate: false,
      dimensions: [],
      numberOfRows: 10000,
      orderBys: [{ ...defaultOrderBy }],
      filters: {},
    });
    setScheduleData({
      action: null,
      timeZone: null,
      startAt: [{ ...defaultStartAt }], //
    });
    setStorageData({
      storage: null, //dataDestination
      writeMode: null, //writePreference
      ...defaultStorage,
    });
  };

  const checkNameError = () => {
    let err = false;
    if (nameRef.current.length === 0) {
      err = "Required";
    } else if (
      nameRef.current !==
      allTriggers.find((trigger) => trigger._id === updateId)?.name &&
      triggersNameList.includes(nameRef.current)
    ) {
      err = "Name must be unique!";
    }
    setNameError(err);
    return err;
  }; // Extractor

  useEffect(() => {
    // console.log("🚀 ~ file: CreateTrigger.js:74 ~ CreateTrigger ~ scheduleData:", scheduleData)
    const { action, timeZone, startAt } = scheduleData;
    if (
      action &&
      timeZone &&
      startAt.length > 0 &&
      startAt.every((time) =>
        action.id === "weekly"
          ? time.on
          : action.id === "monthly"
            ? time.date
            : true
      )
    ) {
      setInfoComplete((prev) => ({ ...prev, scheduleComplete: true }));
    } else {
      setInfoComplete((prev) => ({ ...prev, scheduleComplete: false }));
    }
  }, [scheduleData]); // Extractor

  useEffect(() => {
    const {
      storage,
      writeMode,
      startCell,
      endCell,
      newEmptySheet,
      existingSheet,
      sheetNomenclature,
      tabNomenclature,
      sheetFormat,
      tabFormat,
    } = storageData;
    if (
      storage &&
      writeMode &&
      (tabNomenclature || tabFormat.length) &&
      (["new-sheet", "new-tab"].includes(writeMode.id) ||
        (startCell.row && startCell.column)) &&
      ((newEmptySheet.id === "no" && existingSheet) ||
        (newEmptySheet.id === "yes" &&
          (sheetNomenclature || sheetFormat.length)))
    ) {
      setInfoComplete((prev) => ({ ...prev, storageComplete: true }));
    } else {
      setInfoComplete((prev) => ({ ...prev, storageComplete: false }));
    }
  }, [storageData]);

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
    } else {
      isBulkEditing ? setTimeout(() => window.location.href = "/extractor", 2 * 1000) : resetData();
    }
  }, [allTriggers]);

  const saveAsDraft = () => {
    const error = checkNameError();
    if (error) return;
    dispatch(updateLoader(true));
    const extractedData = transformToSuperMetricDataObject({
      name: nameRef.current,
      channel,
      table,
      dataLevel,
      accounts,
      dateRange,
      compareDateRange,
      sendOverEmail: false,
      status: "DRAFT",
      triggerData,
      scheduleData,
      storageData,
    });
    dispatch(
      createTrigger({
        trigger: extractedData,
        loading: (msg) => {
          dispatch(updateLoader(false));
          setCompletion({ message: msg, operation: "Drafting" });
        },
      })
    );
  };

  const setInitialState = (accounts, type, table, dateRange, triggerData, storageData, scheduleData, currName) => {
    setDisplayPopup(true); setOpenScheduleBox(true); setOpenIngestionBox(true);
    setInitialTableUpdate(true); setInitialLevelUpdate(true);
    setInitialLoad(true); setFirstLoad(true); setInitialChannelUpdate(true);
    setAccounts(accounts ?? []); setChannel(GetChannel(type));
    setTable(table ?? null); setDateRange(dateRange);
    setTriggerData(triggerData ?? { metrics: [], aggregates: [], segregate: false, dimensions: [], numberOfRows: 10000, orderBys: [{ ...defaultOrderBy }], filters: {} });
    setStorageData(storageData ?? { storage: null, writeMode: null, ...defaultStorage });
    setScheduleData(scheduleData ?? { action: null, timeZone: null, startAt: [{ ...defaultStartAt }] });
    setCurrName(currName);
  }

  useEffect(() => {
    if (isBulkEditing) {
      let { accounts, channelType, dateRange, name, scheduleData, storageData, table, triggerData } = extractData;
      setInitialState(accounts, channelType, table, dateRange, triggerData, storageData, scheduleData, name);
    }
  }, [extractData]);

  useEffect(() => {
    isBulkEditing && extra?.setInfoComplete({ ...infoComplete });
  }, [infoComplete]);

  return (
    <div
      className={isBulkEditing ? "main-container" : ""}
      style={{
        background: "#FFFFFF",
        width: "100%",
        height: "100%",
        border: !isBulkEditing ? "4px solid #FFFFFF" : "",
        borderRadius: !isBulkEditing ? "12px" : "",
        overflow: "none",
        boxShadow: !isBulkEditing ? "12px 12px 48px 0px rgba(80, 80, 159, 0.04)" : "none",
      }}
    >
      <div
        style={{
          height: "100%",
          background: "#F8F9FD",
          borderRadius: !isBulkEditing ? "12px" : "",
          padding: !isBulkEditing ? "15px 30px" : "0px",
        }}
      >
        {emailAuthLoading ? (
          <CustomLoader
            heading={"Waiting for email authentication"}
            subHeading=""
          />
        ) : (
          <>
            {email_connected && !isBulkEditing ? (
              <div
                style={{
                  width: "102%",
                  height: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingTop: 12,
                  paddingRight: 12,
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    disabled={
                      !(
                        channel &&
                        (!tables || (tables && table)) &&
                        accounts.length > 0 &&
                        currName.length > 0 &&
                        !nameError
                      )
                    }
                    sx={{
                      marginRight: "10px",
                      ":disabled": { color: "#0869FB88" },
                      stroke: "#FFF",
                      fill: "#EAEAEA",
                    }}
                    style={{
                      width: 40,
                      height: 40,
                      marginRight: "10px",
                      padding: "0px",
                      stroke: "#6D6E7C",
                      fill: "#FFF",
                    }}
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={() => {
                      saveAsDraft();
                    }}
                  >
                    <Save
                      stroke={
                        !(
                          channel &&
                          (!tables || (tables && table)) &&
                          accounts.length > 0 &&
                          currName.length > 0 &&
                          !nameError
                        )
                          ? "#FFF"
                          : "#6D6E7C"
                      }
                      fill={
                        !(
                          channel &&
                          (!tables || (tables && table)) &&
                          accounts.length > 0 &&
                          currName.length > 0 &&
                          !nameError
                        )
                          ? "#EAEAEA"
                          : "#FFF"
                      }
                    />
                  </IconButton>

                  <IconButton
                    style={{
                      width: 40,
                      height: 40,
                      marginRight: "4px",
                      padding: "0px",
                    }}
                    aria-label="close"
                    onClick={resetData}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            ) : (
              <></>
            )}

            {loader ? (
              <></>
            ) : !email_connected ? (
              <div
                className="trigger-table"
                style={{
                  display: "flex",
                  alignItems: "center",
                  overflowX: "auto",
                  height: "100%",
                }}
              >
                <Grid
                  container
                  item
                  xs={4}
                  flexDirection={"column"}
                  style={{
                    height: "70%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      background: "#FFF",
                      borderRadius: "12px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      alignItems: "center",
                      padding: "20px",
                    }}
                  >
                    <div
                      style={{
                        boxSizing: "border-box",
                        background: "#ffffff",
                        borderRadius: "50%",
                        width: "200px",
                        height: "200px",
                        position: "relative",
                        boxShadow:
                          "0px 6.43px 23.78px 0px rgba(167, 196, 239, 0.5)",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <GoogleDriveIcon width={"80px"} height={"80px"} />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Inter",
                          fontSize: "22px",
                          fontWeight: "500",
                          color: "#585969",
                        }}
                      >
                        No Account Connected
                      </Typography>
                      <Typography className="newText">
                        Connect a Google Drive Account to Access this Page
                      </Typography>
                    </div>
                    <div style={{ height: "46px", width: "fit-content" }}>
                      <Tooltip
                        title={
                          user.role !== "admin"
                            ? "Please contact admin to connect an account."
                            : ""
                        }
                      >
                        {/* <Button
                          disabled={user.role !== "admin"}
                          onClick={() => {
                            if (!email_connected) {
                              setEmailAuthLoading(true);
                              dispatch(authorizeEmail());
                              setTimeout(
                                () => checkEmailAuthorizationCompleted(),
                                15 * 1000
                              );
                            }
                          }}
                          sx={{
                            backgroundColor: "#4285F4",
                            color: "#FFF",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            ":disabled": {
                              color: "#0869FB88",
                              backgroundColor: "#EBF3FF",
                            },
                            "&:hover": {
                              backgroundColor: "#4285F4",
                              boxShadow: "none",
                              color: "#FFF",
                            },
                          }}
                        >
                          <GoogleIcon />
                          <Typography
                            style={{
                              fontFamily: "Roboto",
                              fontSize: "18px",
                              fontWeight: "500",
                              lineHeight: "20px",
                              color: "#FFF",
                              margin: "0px 24px",
                            }}
                          >
                            Sign in with Google
                          </Typography>
                        </Button> */}
                        <Button
                          disabled={user.role !== "admin"}
                          onClick={() => {
                            if (!email_connected) {
                              setEmailAuthLoading(true);
                              dispatch(authorizeEmail());
                              setTimeout(
                                () => checkEmailAuthorizationCompleted(),
                                15 * 1000
                              );
                              handleMixPanelData(EVENTS.click_connect_account,{})
                            }
                          }}
                          style={{
                            margin: "12px 6px",
                            textTransform: "none",
                            borderRadius: "8px",
                          }}
                          variant="outlined"
                        >
                          Connect Your Google Drive Account
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                </Grid>
              </div>
            ) : (
              <div
                id="containerBox"
                className="trigger-table"
                style={{
                  display: "flex",
                  alignItems: "center",
                  overflowX: "auto",
                  height: "100%",
                  margin: !isBulkEditing ? "auto" : "0px",
                  width: "min-content",
                  maxWidth: "100%",
                }}
              >
                {isBulkEditing && <Box sx={{
                  minWidth: "255px", borderRadius: "8px", marginRight: "24px", position: "sticky", left: "0", zIndex: "500", height: "100%"
                }}>
                  <SelectedTriggerList handleBulkEditing={extra.handler} listBulkEditing={extra.listBulkEditing} changedTriggers={extra.changedTriggers} confirmBulkUpdate={extra?.confirmBulkUpdate} updateId={updateId} />
                </Box>}
                <div
                  style={{
                    maxWidth: "340px",
                    minWidth: "340px",
                    background: "#FFF",
                    borderRadius: "12px",
                  }}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    flexDirection={"column"}
                    style={{
                      paddingTop: 0,
                      minHeight: "100%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div className="grid-item" style={{ width: "100%" }}>
                      <div
                        className="trigger_name"
                        style={{
                          borderBottom: "1px solid #eee",
                          paddingBottom: "12px",
                        }}
                      >
                        <TextField
                          variant="standard"
                          placeholder="Trigger Name"
                          defaultValue={nameRef.current}
                          value={currName}
                          error={nameError}
                          label={nameError || ""}
                          required
                          onChange={(e) => {
                            nameRef.current = e.target.value;
                            console.log(nameRef)
                            checkNameError();
                            setCurrName(nameRef.current);
                          }}
                          sx={{
                            "& 	.MuiInput-input": {
                              fontFamily: "Inter",
                              fontWeight: "600",
                              fontSize: "14px",
                              lineHeight: "20px",
                            },
                          }}
                          InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                              <InputAdornment position="start">
                                <Edit style={{ marginRight: "10px" }} />
                              </InputAdornment>
                            ),
                          }}
                          disabled={isBulkEditing}
                        />
                      </div>

                      {/* source type */}
                      <div style={{ marginBottom: "25px", marginTop: "26px" }}>
                        <div style={{ margin: "5px 0px", display: "flex" }}>
                          <Typography className="newText">
                            Choose Your Data Source
                          </Typography>
                        </div>
                        <DataList
                          channel={channel}
                          metric={
                            channel && {
                              id: channel?.type,
                              name: channel?.title,
                            }
                          }
                          metrics={SupportedChannels.filter((channel) =>
                            isChannelAuthenticated(channel)
                          ) // .concat(blends.map(GetChannel))
                            .map((channel) => ({
                              id: channel.type,
                              name: channel.title,
                              icon: channel.icon,
                              isBlend: channel.isBlend,
                            }))}
                          isEditable={true}
                          onClick={(channelObject) =>
                            setChannel(
                              GetChannel(
                                channelObject.isBlend
                                  ? channelObject
                                  : channelObject.id
                              )
                            )
                          }
                          defaultTitle="Choose Source"
                          disabled={updateId?.length > 0}
                        />
                      </div>

                      {/* report type */}
                      {tables && (
                        <div style={{ marginBottom: "25px" }}>
                          <div style={{ margin: "5px 0px", display: "flex" }}>
                            <Typography className="newText">
                              Report Type
                            </Typography>
                          </div>
                          <DataList
                            channel={channel}
                            metric={table}
                            metrics={tables}
                            isEditable={true}
                            width={200}
                            onClick={setTable}
                            defaultTitle="Add Report Type"
                            disabled={isBulkEditing}
                          />
                        </div>
                      )}

                      {tables && dataLevels.length ? (
                        <div>
                          <Typography className="newText">
                            Data Level
                          </Typography>
                          <DataList
                            channel={channel}
                            metric={dataLevel}
                            metrics={dataLevels}
                            isEditable={true}
                            onClick={setDataLevel}
                            defaultTitle="Add Data Level"
                            disabled={isBulkEditing}
                          />
                          <br />
                        </div>
                      ) : null}

                      {/* account type */}
                      <div style={{ marginBottom: "25px", width: "100%" }}>
                        <div style={{ margin: "5px 0px", display: "flex" }}>
                          <Typography className="newText">
                            Select Account Id
                          </Typography>
                        </div>
                        <MultipleSelectCheckList
                          optionsList={accountList}
                          value={accounts}
                          onChange={(accountObject) =>
                            setAccounts(accountObject)
                          }
                          defaultTitle="Choose Account Id "
                          onDelete={true}
                          enableSelectAll={true}
                          disabled={isBulkEditing}
                        />
                      </div>

                      {!displayPopup && (
                        <div
                          className="footer_para"
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            padding: "0px 16px",
                          }}
                        >
                          <Button
                            disabled={!(channel &&
                              (!tables || (tables && table)) &&
                              accounts.length > 0 &&
                              currName.length > 0 &&
                              !nameError)}
                            sx={{
                              backgroundColor: "#0869FB",
                              variant: "contained",
                              "&:hover": {
                                backgroundColor: "#0869FB",
                                borderColor: "#0062cc",
                                boxShadow: "none",
                              },
                              color: "#ffffff",
                              ":disabled": { color: "#0869FB88", backgroundColor: "#EBF3FF" },
                            }}
                            onClick={() => {
                              setDisplayPopup(true);
                            }}
                          >
                            Next
                          </Button>
                        </div>
                      )}
                    </div>
                  </Grid>
                </div>
                {displayPopup &&
                  channel &&
                  (!tables || (tables && table)) &&
                  (!dataLevels.length || dataLevel) &&
                  accounts.length > 0 &&
                  nameRef.current.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "12px",
                          height: "18px",
                          background: "#0869FB",
                          borderRadius: "0px 30px 30px 0px",
                        }}
                      ></div>
                      <PipeIcon width="30px" height="15px" />
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          background: "#FFF",
                          borderRadius: "5px 5px",
                          border: "2px solid #0869FB",
                        }}
                      ></div>
                      <div
                        style={{
                          minHeight: "460px",
                          minWidth: "476px",
                          background: "#FFF",
                          borderRadius: "12px",
                        }}
                      >
                        <TriggerParameters
                          nameRef={nameRef}
                          channel={channel}
                          table={table}
                          dataLevel={dataLevel}
                          accounts={accounts}
                          accountCols={accountCols}
                          loading={loading}
                          dateRange={dateRange}
                          updateDateRange={updateDateRange}
                          compareDateRange={compareDateRange}
                          updateCompareDate={updateCompareDate}
                          infoComplete={infoComplete}
                          triggerData={triggerData}
                          setTriggerData={setTriggerData}
                          openScheduleBox={openScheduleBox}
                          setOpenScheduleBox={setOpenScheduleBox}
                          extra={extra}
                        />
                      </div>
                    </div>
                  )}
                {displayPopup &&
                  channel &&
                  (!tables || (tables && table)) &&
                  (!dataLevels.length || dataLevel) &&
                  accounts.length > 0 &&
                  nameRef.current.length > 0 &&
                  openScheduleBox &&
                  infoComplete.triggerComplete && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "12px",
                          height: "18px",
                          background: "#0869FB",
                          borderRadius: "0px 30px 30px 0px",
                        }}
                      ></div>
                      <PipeIcon width="30px" height="15px" />
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          background: "#FFF",
                          borderRadius: "5px 5px",
                          border: "2px solid #0869FB",
                        }}
                      ></div>
                      <div
                        style={{
                          minHeight: "300px",
                          minWidth: "476px",
                          background: "#FFF",
                          borderRadius: "12px",
                        }}
                      >
                        <TriggerSchedule
                          loading={loading}
                          infoComplete={infoComplete}
                          scheduleData={scheduleData}
                          setScheduleData={setScheduleData}
                          openIngestionBox={openIngestionBox}
                          setOpenIngestionBox={setOpenIngestionBox}
                          isBulkEditing={isBulkEditing}
                        />
                      </div>
                    </div>
                  )}

                {displayPopup &&
                  channel &&
                  (!tables || (tables && table)) &&
                  (!dataLevels.length || dataLevel) &&
                  accounts.length > 0 &&
                  nameRef.current.length > 0 &&
                  openScheduleBox &&
                  openIngestionBox &&
                  infoComplete.triggerComplete &&
                  infoComplete.scheduleComplete && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "12px",
                          height: "18px",
                          background: "#0869FB",
                          borderRadius: "0px 30px 30px 0px",
                        }}
                      ></div>
                      <PipeIcon width="30px" height="15px" />
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          background: "#FFF",
                          borderRadius: "5px 5px",
                          border: "2px solid #0869FB",
                        }}
                      ></div>
                      <div
                        style={{
                          minHeight: "460px",
                          minWidth: "476px",
                          background: "#FFF",
                          borderRadius: "12px",
                        }}
                      >
                        <TriggerIngestion
                          nameRef={nameRef}
                          nameError={nameError}
                          checkNameError={checkNameError}
                          updateId={updateId}
                          channel={channel}
                          table={table}
                          dataLevel={dataLevel}
                          accounts={accounts}
                          extractData={extractData}
                          loading={loading}
                          dateRange={dateRange}
                          compareDateRange={compareDateRange}
                          infoComplete={infoComplete}
                          setInfoComplete={setInfoComplete}
                          triggerData={triggerData}
                          setTriggerData={setTriggerData}
                          scheduleData={scheduleData}
                          setScheduleData={setScheduleData}
                          storageData={storageData}
                          setStorageData={setStorageData}
                          setOpenScheduleBox={setOpenScheduleBox}
                          setOpenIngestionBox={setOpenIngestionBox}
                          setCompletion={setCompletion}
                          extra={extra}
                          isBulkEditing={isBulkEditing}
                        />
                      </div>
                    </div>
                  )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
