import { useState } from "react";
import Modal from 'react-modal';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button, Grid, Typography } from "@mui/material";
const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: "#ffff",
		width: "436px",
		height: "204px",
		padding: "20px 32px 32px 32px",
		zIndex: 9000000000000,
		position: "absolute"
	},
};

const RemoveChannelAccountModal = (props) => {
	const { modal, setModal, channel, onSubmit } = props;
	const [isChecked, setIsChecked] = useState(false);

	const close = () => {
		setIsChecked(false);
		setModal(false);
	}
	return (
		<Modal isOpen={modal} style={customStyles}>
			{/* <div style={{width:"100%",height:"40px",borderBottom:"1px solid #eee",padding:"10px",display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                       <div>
                            <b>Remove channel account</b>
					   </div>
					   <div>
                           <Button onClick={close}>X</Button>
					   </div>
				 </div>
				 <div style={{width:"100%",height:"calc(100% - 40px)",position:"relative",zIndex:90000000,backgroundColor:"#ffff",justifyContent:"center",alignItems:"center",display:"flex",flexDirection:"column"}}>
					    <div style={{display:"flex",flexDirection:"column",width:"100%",padding:"15px"}}>
							<Grid className={classes.gridItem} item xs={12} style={{marginBottom:"15px",color:"#0F0F0F"}}>
								     <p style={{fontSize:"15px"}}>
								            This {channel.title} account will be removed from Pixis Analytics. 
									        No data for this account will appear in the dashboard.
								     </p>
									 <p style={{paddingTop:"5px",fontSize:"15px"}}>
									       Do you really want to proceed?
									 </p>
									<p style={{paddingLeft:"9px"}}>
										<FormControlLabel
											value="end"
											control={<Checkbox />}
											label=" Check this box to confirm the account removal"
											labelPlacement="end"
											className={classes.fontSize}
											onChange={event => setIsChecked(event.target.checked)}
										/>
									</p>
							</Grid>
						</div>
                        <div style={{width:"100%",borderTop:"1px solid #eee",padding:"10px",display:"flex"}}>
                                <div style={{display:"flex",alignItems:"center",width:"100%",justifyContent:"flex-end"}}>
									<div style={{paddingRight:"10px",cursor:"pointer"}} onClick={close}>
										Cancel
									</div>
									<div>
										<Button style={{backgroundColor:"#0869FB",color:"white",padding:"10px",textAlign:"right",float:"right",borderRadius:"10px"}} 
										onClick={() => {
											if (isChecked) { onSubmit(); }
											close();
										}}>Remove account</Button>
									</div>
							    </div>
				        </div>
				</div> */}
			<div >
				<Typography style={{ fontFamily: "Inter", fontSize: "24px", fontWeight: "600", lineHeight: "32px", marginBottom: "12px" }}>Remove</Typography>
				<Typography style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "400", lineHeight: "22px", letterSpacing: "0.175px", color: "#5C6674" }}>Are you sure you want to remove “nikunj@gmail.com” from this page? </Typography>

				<div style={{ marginTop: "24px", display: "flex", justifyContent: "flex-end" }}>
					<Button style={{ color: "#0869FB", marginRight: "12px" }} onClick={close}>No</Button>
					<Button style={{ background: "#0869FB", color: "#FFFFFF" }}
						onClick={() => {
							onSubmit();
							close();
						}}
					>Yes</Button>
				</div>
			</div>
		</Modal>
	)
}
export default RemoveChannelAccountModal;