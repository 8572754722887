import { LOGIN_SUCCESS, SIGNUP_SUCCESS, IS_ONBOARDED, LOGIN_FAILURE, SIGNUP_FAILURE, RESET_FAILURE } from "./type";

const INITIAL_STATE = {
    isAuthenticated: false,
    isOnboarded: false,
    loginFailure: false,
    signupFailure: false,
    loginFailureMessage: '',
    signupFailureMessage: '',
    renderSelectChannel: false
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SIGNUP_SUCCESS:
        case LOGIN_SUCCESS:
            localStorage.setItem("accessToken", action.payload.access_token);
            return {
                ...state, isAuthenticated: true, loginFailure: false, signupFailure: false,
                isAccountConnected: action.payload.isAccountConnected,
                renderSelectChannel: action.payload.have_onboarded
            };
        case IS_ONBOARDED:
            return { ...state, isOnboarded: true }
        case SIGNUP_FAILURE:
            return { ...state, signupFailure: true, signupFailureMessage: action.payload }
        case LOGIN_FAILURE:
            return { ...state, loginFailure: true, loginFailureMessage: action.payload }
        case RESET_FAILURE:
            return { ...state, loginFailure: false, signupFailure: false };
        default:
            return state;
    }
};

export default reducer;
