import { useEffect, useState } from "react";
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import { Grid, Typography, IconButton, Popover, Checkbox, FormControlLabel, Box, Tooltip } from "@mui/material";
import { ReactComponent as NoResultsIcon } from "../../../Assets/errorMsgImages/NoResults.svg";
import { ReactComponent as SelectNewIcon } from "../../../Assets/Images/CreateNew.svg";
import { ReactComponent as EditIcon } from "../../../Assets/Icons/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../Assets/Icons/delete.svg";
import { ReactComponent as InfoIcon } from "../../../Assets/Icons/info-square.svg";
import { ReactComponent as FilterIcon } from "../../../Assets/Icons/filterIcon.svg";
import { ReactComponent as CheckedIcon } from "../../../Assets/Icons/checked_icon.svg";
import { ReactComponent as UncheckedIcon } from "../../../Assets/Icons/unchecked_icon.svg";
import { ReactComponent as NavBackIcon } from "../../../Assets/Icons/back_arrow.svg";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DataBlendIcon from "../../../Assets/Icons/DataBlend.svg";
import Scrollbars from "react-custom-scrollbars";
import SelectSource from "./SelectSource";
import { getAllCustomColumns, deleteColumn, getAllCustomDimensions, deleteDimension } from "../../../api/channel/action";
import { connect } from "react-redux";
import { GetChannel } from "../../../models/channel";
import SelectDimSources from "./SelectDimSources";
import SuccessModal from "../../Modal/SuccessModal";
import FailureModal from "../../Modal/FailureModal";
import { updateLoader } from "../../../api/user/action";
import { blendsList } from "../../../api/blends/action";
import "../../styles/main.css"
import SearchBar from "../../Common/SearchBar";
import DeleteModal from "../../Modal/DeleteModal";
import { handleMixPanelData, EVENTS } from "../../../utils/mixpanelV2";

const CustomizeColumns = (props) => {
    const defaultData = { name: "", source: null, account: null, table: null, format: "", formula: "=", other: { table: null, dataType: null } }
    const defaultDimensionData = {
        name: "", data_source: null, account: null, table: null, base_dimension: null,
        mapped_ds: null, refAccount: null, refTable: null, mapped_dimension: null
    }
    const { updateLoader, allCustomCols, blendsList, getAllCustomColumns, deleteColumn, allCustomDims, getAllCustomDimensions, deleteDimension, user } = props
    const [completion, setCompletion] = useState({ message: "none", operation: "none" })
    const [updateId, setUpdateId] = useState(null)
    const [displaySources, setDisplaySources] = useState(false)
    const [columnData, setColumnData] = useState(defaultData)
    const [customType, setCustomType] = useState("")
    const [displayCol, setDisplayCol] = useState("Metric")
    const [customColumnModal, setCustomColumnModal] = useState(false);
    const [currentCustomColumn, setCurrentCustomColumn] = useState(null);

    const [searchText, setSearchText] = useState("");
    const filterData = (value) => {
        return value.toLowerCase().includes(searchText.toLowerCase())
    }

    useEffect(() => {
        setDisplaySources(false);
        setSearchText("");
        if (completion.message === "none")
            setCustomType("");
    }, [completion])

    const updateColumn = (updateId, type) => {
        setUpdateId(updateId);
        if (type === "Metric") {
            setColumnData(allCustomCols.find(column => column.id === updateId));
        } else {
            setColumnData(allCustomDims.find(dim => dim.id === updateId));
        }
        setCustomType(type);
        setDisplaySources(true);
    }

    const addNewColumn = (type) => {
        setUpdateId(null);
        setColumnData(type === "Dimension" ? defaultDimensionData : defaultData);
        setDisplaySources(true);
    }

    const deleteCustomColumn = (deleteId, type) => {
        updateLoader(true);
        setCustomType(displayCol);
        if (type === "Metric") {
            setColumnData(allCustomCols.find(column => column.id === deleteId))
            deleteColumn({
                id: deleteId,
                loading: (msg) => {
                    updateLoader(false);
                    setCompletion({ message: msg, operation: "Deletion" });
                }
            })
        } else {
            setColumnData(allCustomDims.find(column => column.id === deleteId))
            deleteDimension({
                id: deleteId,
                loading: (msg) => {
                    updateLoader(false);
                    setCompletion({ message: msg, operation: "Deletion" });
                }
            })
        }
    }

    const resetData = () => {
        setUpdateId(null);
        setColumnData(defaultData);
        setDisplaySources(false);
        setCustomType("")
        setSearchText("");
    }

    const [loaded, setLoaded] = useState({ blends: false, customCol: false, customDim: false })
    useEffect(() => {
        if (loaded.customCol && loaded.customDim) {
            updateLoader(false)
        }
    }, [loaded])

    useEffect(() => {
        updateLoader(true);
        blendsList({ update: () => setLoaded(prev => ({ ...prev, blends: true })) });
        getAllCustomColumns({ update: () => setLoaded(prev => ({ ...prev, customCol: true })) });
        getAllCustomDimensions({ update: () => setLoaded(prev => ({ ...prev, customDim: true })) });
    }, [])

    const [isInfo, setIsInfo] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectChannels, setSelectChannels] = useState({});
    return (
        <>
            <Box sx={{
                display: 'flex',
                gap: "10px",
                alignItems: 'center',
                width: "100%",
                background: '#FFFFFF',
                borderRadius: '12px',
                padding: '12px 24px',
                position: 'relative',
                boxShadow: '12px 12px 48px 0px rgba(80, 80, 159, 0.04)',
                marginBottom: "20px"
            }}>
                {displaySources && <NavBackIcon className="pointer" onClick={() => { resetData() }} />}
                <Typography style={{ fontFamily: "Inter", fontSize: "20px", fontWeight: "600", lineHeight: "32px" }}>Custom Column</Typography>
            </Box>
            {displaySources ? <Grid className="main_container" container item xs={12}
                style={{ height: "calc(100% - 80px)", width: "100%", padding: "20px", justifyContent: "center" }}
            >
                {customType === "Dimension"
                    ? <SelectDimSources key={updateId + customType} updateId={updateId} columnData={columnData} customType={customType} setColumnData={setColumnData} resetData={resetData} setCompletion={setCompletion} />
                    : <SelectSource key={updateId + customType} updateId={updateId} columnData={columnData} customType={customType} setColumnData={setColumnData} resetData={resetData} setCompletion={setCompletion} />
                }
            </Grid> : <div style={{ height: "calc(100% - 76px)" }} >
                <Grid container flexDirection={"column"} columnSpacing={{ xs: 1, sm: 2, md: 3 }} spacing={2} style={{ margin: "15px auto", height: "100%", width: "100%" }}>
                    <Grid item xs={3} className="gridItem" style={{ padding: "0px", minHeight: "100%" }} >
                        <div style={{ padding: "20px 0px", minHeight: "100%", height: "100%" }}>
                            {isInfo ?
                                <Scrollbars autoHide style={{ height: "100%" }}>
                                    <div style={{ padding: "0px 20px", height: "100%" }}>
                                        <div className="lato displayRowSpaced" style={{ color: "#2E3044", fontSize: 18, fontWeight: 700, margin: "10px 0px" }}>
                                            <span className="displayRowCenter">
                                                <IconButton
                                                    onClick={() => setIsInfo(false)}
                                                    style={{ marginRight: "10px", padding: "8px", background: "#F3F4F8" }}
                                                    aria-label="go back"
                                                >
                                                    <ArrowBackIcon style={{ width: 16, height: 16 }} />
                                                </IconButton>
                                                Info
                                            </span>
                                            <InfoIcon />
                                        </div>
                                        <hr style={{ border: "1px solid #EAEAEC", margin: "10px 0px 20px 0px" }} />
                                        <div className="inter h14" style={{ padding: "5px 20px", borderLeft: "5px solid #E5E9F4", position: "relative", color: "#6D6E7C" }}>
                                            <TripOriginIcon style={{ color: "#0869FB", background: "white", position: "absolute", top: -10, left: -15 }} />
                                            Customized columns can be created on the data blend sources or integrated sources over the platform. Objective of customized column is to visualize the aggregation metrics data for the sources you've created in data blend or create your own metrics to enchance your campaign performance.
                                        </div>
                                        <div className="inter h14" style={{ padding: "5px 20px", borderLeft: "5px solid #E5E9F4", position: "relative", color: "#6D6E7C" }}>
                                            <TripOriginIcon style={{ color: "#0869FB", background: "white", position: "absolute", top: -10, left: -15 }} />
                                            Within Customized columns, only those base metrics are available and whose data can be summed up on aggregation. From there a particular user can create percentage/ratio metrics.
                                        </div>
                                        <div className="inter h14" style={{ padding: "5px 20px", borderLeft: "5px solid #FFFFFF", position: "relative", color: "#6D6E7C" }}>
                                            <TripOriginIcon style={{ color: "#0869FB", background: "white", position: "absolute", top: -10, left: -15 }} />
                                            Restriction of particular operators while creating your customized columns. The operators are mentioned below:
                                            <br /> " + ", " - ", " / ", " * ", " ( ", " ) ".
                                        </div>
                                    </div>
                                </Scrollbars> :
                                <div className="displayColCenter" style={{ minHeight: "100%", height: "100%" }}>
                                    <div className="lato h18 displayRowSpaced" style={{ margin: "10px 0px", padding: "0px 20px" }}>
                                        Existing Custom Columns
                                        <InfoIcon className="pointer" onClick={() => setIsInfo(true)} />
                                    </div>
                                    <div className="displayRowEven" style={{ border: "1px solid #EAEAEC", borderRadius: "6px", margin: "10px 20px", padding: "8px", width: "calc(100% - 40px)" }}>
                                        <Typography sx={{ width: "50%", padding: "4px 0", borderRadius: "6px", textAlign: "center", cursor: "pointer", color: "#585969" }}
                                            style={displayCol === "Metric" ? { background: "#0869FB", color: "white" } : {}}
                                            onClick={() => { setDisplayCol("Metric") }}
                                        >
                                            All Metrics
                                        </Typography>
                                        <Tooltip title={"Currently not available!"} placement="top">
                                            <Typography sx={{ width: "50%", padding: "4px 0", borderRadius: "6px", textAlign: "center", cursor: "pointer", color: "#585969" }}
                                                style={displayCol === "Dimension" ? { background: "#0869FB", color: "white" } : { opacity: "0.5" }}
                                            // onClick={() => { setDisplayCol("Dimension") }}
                                            >
                                                All Dimensions
                                            </Typography>
                                        </Tooltip>
                                    </div>
                                    <div style={{ padding: "5px 20px", display: "flex", gap: "10px" }}>
                                        <SearchBar setFilterText={setSearchText} keys={["name", "title"]} />
                                        <div className="pointer border1" onClick={(e) => setAnchorEl(e.currentTarget)} >
                                            <FilterIcon style={{ height: "18px", width: "18px" }} />
                                        </div>
                                    </div>
                                    <Popover id='channel-filter-popover'
                                        open={Boolean(anchorEl)}
                                        anchorEl={anchorEl}
                                        onClose={() => setAnchorEl(null)}
                                        anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                                    >
                                        <div style={{ padding: "12px" }}>
                                            {props.authenticatedAccounts.map(acc => {
                                                const channel = GetChannel(acc);
                                                return (<FormControlLabel
                                                    control={<Checkbox checked={selectChannels[acc]}
                                                        checkedIcon={<CheckedIcon />} icon={<UncheckedIcon />}
                                                        onChange={(e) => setSelectChannels({ ...selectChannels, [acc]: e.target.checked })}
                                                    />}
                                                    label={channel.title}
                                                    sx={{
                                                        display: "block", padding: "6px 12px", margin: 0, borderRadius: "8px",
                                                        "&:hover": { background: "#E3EEFF" },
                                                    }}
                                                />)
                                            })}
                                        </div>
                                    </Popover>
                                    {(displayCol === "Dimension" ? allCustomDims : allCustomCols).length ?
                                        <Scrollbars autoHide style={{ height: "90%" }}>
                                            <div style={{ height: "100%", padding: "0px 20px" }}>
                                                {(displayCol === "Dimension" ? allCustomDims : allCustomCols)
                                                    .filter(col => filterData(col.name) &&
                                                        (!Object.values(selectChannels).includes(true) || selectChannels[col.data_source ?? col.source] || selectChannels[col.mapped_ds])
                                                    ).map((column, index, array) => {
                                                        const channel = GetChannel(displayCol === "Dimension" ? column.data_source : column.source);
                                                        const refChannel = displayCol === "Dimension" && GetChannel(column.mapped_ds);
                                                        return (<div style={{ margin: "10px 0px", border: "1px solid #EAEAEC", borderRadius: "6px" }}>
                                                            <div className="displayRowSpaced" style={{ background: "#F6F7F8", padding: "12px" }}>
                                                                <Typography variant="body1" className="h14" style={{ wordBreak: "break-word" }}>
                                                                    {column.name}
                                                                </Typography>
                                                                <div style={{ display: "flex" }}>
                                                                    <IconButton
                                                                        style={{
                                                                            marginLeft: "8px", height: "24px", width: "24px", padding: "3px",
                                                                            background: "#fff", border: "1px solid #EAEAEC", borderRadius: "4px",
                                                                        }}
                                                                        onClick={() => { handleMixPanelData(EVENTS.edit_custom_metric); updateColumn(column.id, displayCol) }}
                                                                    >
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                    {user.role !== "viewer" && <IconButton
                                                                        style={{
                                                                            marginLeft: "8px", height: "24px", width: "24px", padding: "3px",
                                                                            background: "#fff", border: "1px solid #EAEAEC", borderRadius: "4px",
                                                                        }}
                                                                        onClick={() => {
                                                                            setCustomColumnModal(true);
                                                                            setCurrentCustomColumn({ ...column, "type": displayCol })
                                                                        }}
                                                                    >
                                                                        <DeleteIcon />
                                                                    </IconButton>}
                                                                </div>
                                                            </div>
                                                            <div style={{ padding: "12px" }}>
                                                                <div className="displayRowLeft" >
                                                                    {
                                                                        column.other?.is_blended_datasource ?
                                                                            <img style={{ marginRight: "8px", height: 16, width: 16 }} src={DataBlendIcon} alt={"BlendChannel"} />
                                                                            : <img style={{ marginRight: "8px", height: 16, width: 16 }} src={channel?.icon} alt={channel?.title} />
                                                                    }
                                                                    <Typography className="inter" style={{ padding: "1px 8px", color: "#5C6674", fontSize: "12px" }}>
                                                                        {column.other?.is_blended_datasource
                                                                            ? column.other.blendAudiences?.map(acc => acc.name).join(", ")
                                                                            : `${column?.audiences?.[0]?.metaData?.title ?? ""} (${column?.audiences?.[0]?.metaData?.id ?? " "})`
                                                                        }
                                                                    </Typography>
                                                                </div>
                                                                {
                                                                    refChannel && <div className="displayRowLeft" style={{ marginTop: "4px" }}>
                                                                        <img style={{ marginRight: "8px", height: 16, width: 16 }} src={refChannel?.icon} alt={refChannel?.title} />
                                                                        <Typography className="inter ellipseText" style={{ padding: "1px 8px", color: "#5C6674", fontSize: "12px" }}>{column?.account?.title ?? "Pixis Insights (156289063)"}</Typography>
                                                                    </div>}
                                                            </div>
                                                        </div>)
                                                    })}
                                            </div>
                                        </Scrollbars> :
                                        <div className="displayColCenter" style={{ height: "100%", margin: "20px", borderRadius: "6px", background: "#FAFAFA" }}>
                                            <NoResultsIcon width="200px" height="200px" />
                                            <h3 style={{ fontFamily: "Inter", fontSize: "14px", color: "#666666", textAlign: "center" }}>
                                                There is not any Custom {displayCol}s added. <br />
                                                Create a New {displayCol} to proceed.
                                            </h3>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </Grid>
                    <Grid container flexDirection={"column"} item xs={9} style={{ width: "100%", paddingTop: "0px", minHeight: "100%" }} >
                        <Grid container justifyContent="center" alignItems="center" item xs={12} style={{ paddingTop: 0, minHeight: "100%", gap: "10%" }}>
                            <Tooltip title={user.role === "viewer" ? "Viewer does not have permission to create!" : ""} placement="top">
                                <div data-tut="custom_column" className="gridItem displayColTop" style={{ height: "226px", width: "288px", padding: "32px 22px", position: "relative" }}>
                                    <SelectNewIcon disabled style={{ cursor: "pointer", marginBottom: "24px" }}
                                        onClick={() => { setCustomType("Metric"); addNewColumn("Metric"); handleMixPanelData(EVENTS.click_custom_metric); }}
                                    />
                                    <Typography style={{ fontSize: "16px", fontWeight: 600, marginBottom: "5px" }}>Create New Metric</Typography>
                                    <Typography style={{ fontSize: "12px", color: "#6D6D6D", textAlign: "center" }}>Customize your metrics for any data source.</Typography>
                                    {
                                        (user.role === "viewer") &&
                                        <div style={{ position: "absolute", background: "#FFFFFFAA", height: "100%", width: "100%" }}>
                                        </div>
                                    }
                                </div>
                            </Tooltip>
                            <Tooltip title={user.role === "viewer" ? "Viewer does not have permission to create!" : "Currently not available!"} placement="top">
                                <div className="gridItem displayColTop" style={{ height: "226px", width: "288px", padding: "32px 22px", position: "relative" }}>
                                    <SelectNewIcon disabled style={{ cursor: "pointer", marginBottom: "24px" }}
                                        onClick={() => { setCustomType("Dimension"); addNewColumn("Dimension"); handleMixPanelData(EVENTS.click_custom_dimension); }}
                                    />
                                    <Typography style={{ fontSize: "16px", fontWeight: 600, marginBottom: "5px" }}>Create New Dimension</Typography>
                                    <Typography style={{ fontSize: "12px", color: "#6D6D6D", textAlign: "center" }}>Make your own dimensions across ads and attribution channels.</Typography>
                                    {
                                        (user.role === "viewer" || true) &&
                                        <div style={{ position: "absolute", top: 0, background: "#FFFFFFAA", height: "100%", width: "100%" }}>
                                        </div>
                                    }
                                </div>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </div >}

            <SuccessModal
                modal={completion.message === "Success"}
                setModal={() => setCompletion({ message: "none", operation: "none" })}
                message={`${completion.operation} of Custom ${customType === "Dimension" ? "Dimension" : "Metric"} is Successful`}
            />

            <FailureModal
                modal={completion.message === "Fail"}
                setModal={() => setCompletion({ message: "none", operation: "none" })}
                message={`${completion.operation} of Custom ${customType === "Dimension" ? "Dimension" : "Metric"} Failed`}
                error={completion.error}
            />
            {
                currentCustomColumn && <DeleteModal
                    modal={customColumnModal}
                    setModal={setCustomColumnModal}
                    message={`Do you really want to delete this ${currentCustomColumn.name} custom column?`}
                    onDelete={() => deleteCustomColumn(currentCustomColumn.id, currentCustomColumn.type)}
                />
            }
        </>
    )
}

const mapStateToProps = state => ({
    allCustomCols: state.channel.allCustomCols,
    allCustomDims: state.channel.allCustomDims,
    authenticatedAccounts: state.channel.authenticatedAccounts,
    user: state.user.user
});

const mapDispatchToProps = dispatch => ({
    updateLoader: (_) => dispatch(updateLoader(_)),
    blendsList: (_) => dispatch(blendsList(_)),
    getAllCustomDimensions: (_) => dispatch(getAllCustomDimensions(_)),
    deleteDimension: (_) => dispatch(deleteDimension(_)),
    getAllCustomColumns: (_) => dispatch(getAllCustomColumns(_)),
    deleteColumn: (_) => dispatch(deleteColumn(_))
});

export default (connect(mapStateToProps, mapDispatchToProps)(CustomizeColumns));
