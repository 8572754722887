import { UPDATE_USER, USER_DETAILS, UPDATE_USER_ORG, UPDATE_TOUR_FLAG, UPDATE_TOUR_INFO, UPDATE_LOADER } from "./type";

export const getUser = () => {
    return {
        type: USER_DETAILS,
    };
};

export const updateUser = payload => {
    return {
        type: UPDATE_USER,
        payload: payload,
    };
};

export const updateUserOrg = payload => {
    return {
        type: UPDATE_USER_ORG,
        payload: payload,
    };
};

export const endTour = payload => {
    return {
        type: UPDATE_TOUR_FLAG,
        payload: payload
    };
};

export const updateTourInfo = payload => {
    return {
        type: UPDATE_TOUR_INFO,
        payload: payload
    }
}

export const updateLoader = payload => {
    return {
        type: UPDATE_LOADER,
        payload: payload
    }
}

export const serviceDown = () => {
    return {
        type: "SERVICE_DOWN"
    };
};

export const serviceUp = () => {
    return {
        type: "SERVICE_UP"
    };
};