import { all, put, select, takeLatest } from "redux-saga/effects";
import { api } from "..";
import { EVENTS, transformMixPanelData } from "../../utils/mixpanel";

export const getDefaultBlendsList = () => new Promise(async (resolve, reject) => {
    try {
        const response = await api.get(`/v2/analytics/blends/default-blends`);
        resolve(response?.data?.data);
    } catch (error) {
        reject(error.response);
    }
});

function* blendsList(args) {
    try {
        const response = yield api.post("/v2/analytics/blends/list", args?.payload?.params);
        yield put({
            type: "BLENDS_LIST_SUCCESS",
            payload: response.data
        })
    } catch (error) {
        yield put({
            type: `BLENDS_LIST_FAILURE`,
            payload: error.response
        });
    }
    if (args?.payload?.update) {
        yield args.payload.update()
    }
}

function* blendsCreate(args) {
    try {
        const response = yield api.post("/v2/analytics/blends/create", args.payload.blendData);
        yield put({
            type: "BLENDS_CREATE_SUCCESS",
            payload: response.blends
        })
        yield put({
            type: 'BLENDS_LIST',
        })
        transformMixPanelData(EVENTS.success_create_blends, { ...args.payload.blendData });
        yield args.payload.loading("Success");
    } catch (error) {
        yield put({
            type: 'BLENDS_CREATE_FAILURE',
            payload: error.response
        })
        yield args.payload.loading("Fail");
    }
}

function* updateBlend(args) {
    try {
        yield api.post(`/v2/analytics/blends/${args.payload.id}/update`, args.payload.obj);
        yield put({
            type: 'BLENDS_LIST',
        })
        yield args.payload.loading("Success");
        transformMixPanelData(EVENTS.update_blends, { ...args.payload.obj });
    } catch (error) {
        yield args.payload.loading("Fail");
    }
}

function* deleteBlend(args) {
    try {
        yield api.post(`/v2/analytics/blends/${args.payload.id}/delete`);
        yield put({
            type: 'BLENDS_LIST',
        })
        yield args.payload.loading("Success");
    } catch (error) {
        yield args.payload.loading("Fail");
    }
}

export const getBlendColumns = (blendId) => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post(`/v2/analytics/blends/${blendId}/columns`);
        let customColResponse = await api.post(`/v2/analytics/customized-columns/get/all`,
            { source: blendId }
        );
        let customCols = customColResponse.data?.data.map(column => ({
            id: column.id,
            name: column.name,
            type: "METRIC",
            dataType: column.format ?? "FLOAT",
            metricType: column.other.metricType?.id,
            group: "Customized",
            formula: column.other.nameFormula,
            dataSource: column.source,
        })) ?? []
        resolve({ columns: (response.data?.columns ?? []).concat(customCols) });
    } catch (error) {
        reject(error.response);
    }
});

export const getBlendWidgetData = (blendId, apiConfigs, orderBys) => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post("/v2/analytics/blends/extract", {
            blendId: blendId,
            api_configs: apiConfigs, orderBys
        });
        resolve([{ ...response.data, rowCount: response.data.values.length }]);
    } catch (error) {
        reject(error.response);
    }
});

export default function* root() {
    yield all([
        takeLatest("BLENDS_LIST", blendsList),
        takeLatest("BLENDS_CREATE", blendsCreate),
        takeLatest("BLENDS_UPDATE", updateBlend),
        takeLatest("BLENDS_DELETE", deleteBlend),
    ]);
}
