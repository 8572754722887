import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ForgetPassword } from "../../api/user/saga";
import ForgotIcon from "../../Assets/Images/forgotpassword.svg"
import Back from "../../Assets/Icons/backicon.svg"

const ForgotPasswordPage = (props) => {
    const [email, setEmail] = useState(null);
    const [message, setMessage] = useState("");
    const [isError, setError] = useState(false);
    const { modal, setModal } = props;


    const validation = () => {

        if (email === null || !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            return false;
        }
        return true;
    }
    const send = async () => {
        if (!validation()) {
            setError(true);
            setMessage("Enter valid email id. User doesn't exists!");
            return;
        }
        ForgetPassword({ email: email }).then((response) => {
            setError(false);
            setMessage("Reset link for your password successfully sent on email.");
            setTimeout(() => {
                setModal(!modal);
                setEmail(null);
                setMessage("");
                setError(false);
            }, 2000);
        }).catch((err) => {
            setError(true);
            setMessage(err.data.message);
        })
    }
    return (
        <div style={{ display: "flex", flexDirection: "row", height: "100vh", width: "100%" }}>

            <div style={{ height: "100%" }}>
                <img src={ForgotIcon} style={{ height: "100%" }} alt="" />
            </div>
            <div className="forgot_form" style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                <Box
                    sx={{
                        padding: "32px",
                        border: "1px solid #ECEEF1",
                        borderRadius: "12px",
                        width: "464px"
                    }}
                >
                    <div style={{ marginBottom: "20px" }}>
                        <Link style={{ display: "flex", alignItems: "center" }}
                            to="/login"
                        >
                            <img src={Back} alt="" /> <span style={{ fontFamily: "Inter", fontSize: "16px", fontWeight: "500", lineHeight: "20px", color: "#0869FB", marginLeft: "8px" }}>Go Back</span>
                        </Link>
                    </div>
                    <Typography style={{ fontFamily: "Inter", fontSize: "20px", fontWeight: "600", marginBottom: "6px" }}>Forgot Password</Typography>
                    <span style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "400", lineHeight: "16px", color: "#5C6674" }}>Enter your credentials to access your account</span>
                    <div>
                        <Typography style={{ fontFamily: "Inter", fontSize: "16px", fontWeight: "400", fontStyle: "normal", lineHeight: "24px", marginBottom: "8px", marginTop: "16px" }}>E-mail ID <span style={{ color: "red" }}>*</span></Typography>
                        <TextField
                            style={{ width: '100%' }}
                            id="outlined-basic"
                            InputLabelProps={{ shrink: true, style: { color: 'black', fontFamily: 'Inter' } }}
                            placeholder="Enter you E-mail ID"
                            InputProps={{ style: { borderRadius: '6px', height: "40px" } }}
                            inputProps={{
                                style: { padding: "12px 16px" }
                            }}
                            value={email}
                            size="small"
                            onChange={(e) => setEmail(e.target.value)}
                            variant="outlined" />
                        {message !== "" && <div style={{ width: "100%", marginTop: "10px", display: "flex", justifyContent: "center", alignItems: "flex-end", flexDirection: "column" }}>
                            {
                                isError ? <h4 style={{
                                    color: "red",
                                    textAlign: "right",
                                    borderRadius: "10px",
                                    width: "100%",
                                    fontSize: "10px"
                                }}><b>{message}</b>
                                </h4> :
                                    <h4 style={{
                                        color: "green",
                                        textAlign: "right",
                                        borderRadius: "10px",
                                        width: "100%",
                                        fontSize: "10px"
                                    }}><b>{message}</b>
                                    </h4>
                            }
                        </div>}
                    </div>
                    <Button style={{ backgroundColor: "#0869FB", color: "white", padding: "10px", textAlign: "right", float: "right", borderRadius: "8px", width: "100%", marginTop: "24px" }}
                        onClick={() => send()}
                    >SEND</Button>


                </Box>

            </div>
        </div>


    )
}

export default ForgotPasswordPage