import React, { useEffect } from "react";
import ErrorIcon from "../../Assets/Images/error_icon.svg"
import SignInIcon from "../../Assets/Images/NewLogin.svg";
import Divider from "@mui/material/Divider";
import { connect } from "react-redux";
import { login, resetError } from "../../api/auth/action";
import ForgetPasswordModal from '../Modal/ForgetPasswordModal';
import { Box, Button, Checkbox, FormControlLabel, Grid, InputAdornment, Link, TextField, Typography, } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from '@mui/material/IconButton';
import FacebookIcon from "../../Assets/Icons/facebook-icon.svg"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function LoginPage(props) {
	const { login, resetError, loginFailureMessage } = props;
	const history = useHistory();

	const [passwordModal, setPasswordModal] = React.useState(false);
	const [togglePassword, setTogglePassword] = React.useState(false);

	const [state, setState] = React.useState({
		email: "",
		password: ""
	});
	const [errors, setErrors] = React.useState({
		email: null,
		password: null,
		social: null
	});

	const validateData = (property, reqVal) => {
		const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

		switch (property) {
			case "email":
				if (state.email.length === 0) {
					return reqVal ? "Email is required" : null;
				} else if (!emailRegex.test(state.email)) {
					return "Invalid Email";
				}
				break;
			case "password":
				if (state.password.length === 0) {
					return reqVal ? "Password is required" : null;
				}
				break;

			default:
				return null;
		}
		return null;
	}

	const handleChange = (property, value) => {
		setState({ ...state, [property]: value });
		setErrors({ ...errors, [property]: validateData(property), social: null });
	}

	function parentWidth(elem) {
		return elem.parentElement.clientWidth;
	}

	const handleCustomLogin = () => {
		const newErrors = { ...errors, social: null };
		Object.keys(state).forEach(key => newErrors[key] = validateData(key, true));
		setErrors(newErrors);

		const isValid = Object.values(newErrors).find(v => v !== null) === undefined;
		if (isValid) {
			login(state);
		}
	}

	const handleFBLogin = () => {
		if (props.loginFailure) { resetError(); }
		window.fbLogin(function (response) {
			if (response.authResponse) {
				window.getEmail(function (user) {
					if (user.email) {
						history.push("/signedin", {
							prevPath: history.location.pathname,
							authResponse: { ...response.authResponse, email: user.email, type: "facebook" }
						})
					} else {
						setErrors({ ...errors, "social": "Facebook Authentication failed." });
					}
				})
			} else {
				setErrors({ ...errors, "social": "Facebook Login failed." });
			}
		})
	};


	const handleGoogleResponse = (response) => {
		if (props.loginFailure) { resetError(); }
		if (response) {
			history.push("/signedin", {
				prevPath: history.location.pathname,
				authResponse: { ...response, type: "google" }
			})
		} else {
			setErrors({ ...errors, "social": "Google Login failed." });
		}
	}

	useEffect(() => {
		/* global google*/
		google.accounts.id.initialize({
			client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
			callback: handleGoogleResponse
		});
		renderGoogleButton();
	}, [])

	const renderGoogleButton = () => {
		google.accounts.id.renderButton(
			document.getElementById("googleSignIn"),
			{
				theme: 'outline',
				width: document.getElementById("parentElement")?.offsetWidth,
			}
		);
	}
	useEffect(() => {
		window.addEventListener("resize", renderGoogleButton, false);
	}, [])

	return (
		<div style={{ display: "flex", flexDirection: "row", height: "100vh", width: "100%" }}>
			<div
				style={{ height: "100%" }}>
				<img src={SignInIcon} style={{ height: "100%" }} alt="" />
			</div>
			<div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
				<Box sx={{
					padding: "32px",
					border: "1px solid #ECEEF1",
					borderRadius: "12px",
					width: "464px"
				}}>
					<div style={{ width: "100%", marginBottom: "16px" }}>
						<Typography style={{ fontFamily: "Inter", fontSize: "20px", fontWeight: "600", lineHeight: "32px", marginBottom: "6px" }}>Sign In</Typography>
						<span style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "400", color: "#5C6674" }}>Enter your credentials to log into your organisation.</span>
					</div>

					<div style={{ marginBottom: "16px" }}>
						<Typography style={{ fontFamily: "Inter", fontSize: "16px", fontWeight: "400", fontStyle: "normal", lineHeight: "24px", marginBottom: "8px" }}>E-Mail ID <span style={{ color: "red" }}>*</span></Typography>
						<TextField
							required
							error={errors.email !== null}
							// helperText={errors.email}
							placeholder="Enter Your E-mail ID"
							style={{
								width: "100%",
							}
							}
							value={state.email}
							onChange={_ => handleChange("email", _.target.value)}
							onKeyDown={(event) => {
								if (props.loginFailure) { resetError(); }
								if (event.key === "Enter") { handleCustomLogin(); }
							}}
							InputLabelProps={{ shrink: true, style: { color: 'black', fontFamily: 'Inter' } }}
							InputProps={{ style: { borderRadius: '8px' } }}
							inputProps={{ style: { padding: "12px 16px" } }}
							variant="outlined"
						/>
					</div>

					<div style={{ marginBottom: "16px" }}>
						<Typography style={{ fontFamily: "Inter", fontSize: "16px", fontWeight: "400", fontStyle: "normal", lineHeight: "24px", marginBottom: "8px" }}>Password <span style={{ color: "red" }}>*</span></Typography>
						<TextField
							required
							error={errors.password !== null}
							// helperText={errors.password}
							placeholder="Enter Your Password"
							style={{ width: "100%" }}
							type={!togglePassword ? "password" : "text"}
							value={state.password}
							onChange={_ => handleChange("password", _.target.value)}
							onKeyDown={(event) => {
								if (props.loginFailure) { resetError(); }
								if (event.key === "Enter") { handleCustomLogin(); }
							}}
							name="password"
							InputLabelProps={{ shrink: true, style: { color: 'black', fontFamily: 'Inter' } }}
							InputProps={{
								style: { borderRadius: '8px' }, endAdornment: (
									<InputAdornment position="end">
										{togglePassword ? <IconButton onClick={() => setTogglePassword(!togglePassword)}><Visibility style={{ color: "#707070" }} /></IconButton> :
											<IconButton onClick={() => setTogglePassword(!togglePassword)}><VisibilityOff style={{ color: "#707070" }} /></IconButton>}
									</InputAdornment>
								)
							}}
							inputProps={{
								style: { padding: "12px 16px" }
							}}
							variant="outlined" />
					</div>

					<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "24px", marginLeft: "8px" }}>
						<FormControlLabel required control={<Checkbox />} label={<span style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "400", lineHeight: "22px", marginLeft: "-6px" }}>Keep me logged in</span>} />
						<Typography fontSize={14} sx={{ marginRight: '2px' }}>
							{/* <Link onClick={() => setPasswordModal(true)}>
                                    Forgot Password?
                                </Link> */}
							<Link href="/forgotpassword">
								Forgot Password?
							</Link>
						</Typography>
					</div>
					{
						errors && <h4 style={{
							fontFamily: "Inter",
							fontWeight: "400",
							color: "#E91B16",
							textAlign: "left",
							borderRadius: "10px",
							width: "100%",
							fontSize: "14px",
							// marginTop: "12px",
							marginBottom: "12px"
						}}><b>{Object.values(errors).filter(error => error)[0]}</b>
						</h4>
					}
					{props.loginFailure
						? <Grid item xs={12} style={{ backgroundColor: "#FF000033", borderRadius: "8px", display: "flex", alignItems: "center", padding: "5px 15px", margin: "10px 0px" }}>
							<div style={{ width: "20%", display: "flex" }} >
								<img src={ErrorIcon} alt="Error!" />
							</div>
							<div style={{ width: "60%" }} >
								<b>Error!</b>
								<br />{loginFailureMessage}
							</div>
						</Grid>
						: <></>
					}
					<div style={{ marginBottom: '16px' }}>
						<Button onClick={() => handleCustomLogin()} style={{ width: "100%", height: "50px", borderRadius: '8px' }} variant="contained" color="primary">
							Sign In
						</Button>
					</div>

					<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
						<Typography style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "400", textAlign: "center" }}>
							<span style={{ marginRight: 8 }}>Dont have an account?</span>
							<Link href="/createaccount" onClick={() => { if (props.loginFailure) { resetError(); } }} >
								<span style={{ color: "#0869FB", fontFamily: "Inter", fontSize: "14px", fontWeight: "500", lineHeight: "20px" }}>Sign Up</span>
							</Link>
						</Typography>
					</div>
					<div style={{ marginTop: "16px", marginBottom: "16px", fontFamily: "Inter", fontSize: "14px", fontWeight: "400", color: "#5C6674" }}><Divider>or </Divider></div>

					<div style={{ display: "flex", gap: "14px" }}>
						<div id="parentElement" style={{ width: "50%" }} >
							<div id="googleSignIn" style={{ width: "50%" }} />
						</div>

						<button onClick={() => handleFBLogin()} className="button0 button1"
						>
							<img src={FacebookIcon} style={{ marginRight: "8px", height: "24px", width: "24px" }} alt="" /> Sign in with Facebook
						</button>
					</div>
				</Box>
			</div>
			<ForgetPasswordModal modal={passwordModal} setModal={setPasswordModal} />
		</div>
	);
}

const mapDispatchToProps = dispatch => ({
	login: (_) => dispatch(login(_)),
	resetError: () => dispatch(resetError()),
})

const mapStateToProps = state => ({
	loginFailure: state.auth.loginFailure,
	loginFailureMessage: state.auth.loginFailureMessage
});

export default (connect(mapStateToProps, mapDispatchToProps)(LoginPage))
