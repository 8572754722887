import { Grid, Tab } from "../../models/report";
import { Chart, Metric, Filter } from "../../models/chart";
import { channelType } from "./transformer";

// metrics
const IMPRESSIONS = { id: "impressions", name: "Impressions" };
const LEADS = { id: "leads", name: "Leads" };
const SPEND = { id: "spend", name: "Amount spent" };
const REACH = { id: "reach", name: "Reach" };
const CLICKS = { id: "clicks", name: "Clicks (all)" };
const POST_ENGAGEMENT = { id: "post_engagement", name: "Post engagement" };
const POST_COMMENTS = { id: "post_comments", name: "Post comments" };
const COST_PER_PURCHASE = { id: "cost_per_purchase", name: "Cost per purchase" };
const GENDER = { id: "gender", name: "Gender", type: "DIMENSION", group: "Popular breakdowns" };
const AGE = { id: "age", name: "Age" };
const LANDING_PAGE_VIEWS = { id: "landing_page_view", name: "Landing page views" };
const WEBSITE_PURCHASE_ROAS = { id: "website_purchase_roas_2", name: "Website purchase ROAS (return on advertising spend)" };
const COST_PER_UNIQUE_LEAD = { id: "cost_per_unique_lead", name: "Cost per unique Lead" };
const WEBSITE_PURCHASES = { id: "website_purchases", name: "Website purchases" };
const WEBSITE_PURCHASE_CONVERSION_VALUE = { id: "website_purchases_conversion_value", name: "Website purchases conversion value" };

// dimensions
const CAMPAIGN_NAME = { id: "campaign_name", name: "Campaign name" };
const ADSET_NAME = { id: "adset_name", name: "Ad set name" };
const AD_NAME = { id: "ad_name", name: "Ad name" };

// filters
const FILTER_GENDER_MALE = {
  boolOperator: null,
  filter: GENDER,
  operator: {
    type: "EQUAL",
    label: "Equal",
    dataType: "STRING",
  },
  value: "Male",
};
const FILTER_GENDER_FEMALE = {
  boolOperator: null,
  filter: GENDER,
  operator: {
    type: "EQUAL",
    label: "Equal",
    dataType: "STRING",
  },
  value: "Female",
};
const FILTER_GENDER_UNCATEGORIZED = {
  boolOperator: null,
  filter: GENDER,
  operator: {
    type: "EQUAL",
    label: "Equal",
    dataType: "STRING",
  },
  value: "Uncategorized",
};


const overallCharts = [
  new Chart(channelType, "Leads vs. CPL", "LINE", null,
    { x: 0, y: 0, w: 6, h: 2, static: false },
    [new Metric(LEADS)],
    [new Metric({ id: "cost_per_lead", name: "Cost per Lead" })],
  ),
  new Chart(channelType, "Conversion Funnel", "FUNNEL", null,
    { x: 6, y: 0, w: 6, h: 2, static: false },
    [
      new Metric(IMPRESSIONS),
      new Metric(REACH),
      new Metric(CLICKS),
      // new Metric({ id: "Conversion rate", name: "Conversion rate" }),
    ],
  ),
  new Chart(channelType, "Total Spent", "KPI", null,
    { x: 0, y: 2, w: 3, h: 1, static: false },
    [new Metric(SPEND)],
  ),
  new Chart(channelType, "CPC (All)", "KPI", null,
    { x: 3, y: 2, w: 3, h: 1, static: false },
    [new Metric({ id: "cpc", name: "CPC (all)" })],
  ),
  new Chart(channelType, "CPM", "KPI", null,
    { x: 6, y: 2, w: 3, h: 1, static: false },
    [new Metric({ id: "cpm", name: "CPM (cost per 1,000 impressions)" })],
  ),
  new Chart(channelType, "Unique Leads", "KPI", null,
    { x: 9, y: 2, w: 3, h: 1, static: false },
    [new Metric({ id: "unique_leads", name: "Unique Leads" })],
  ),
  new Chart(channelType, "Cost per unique lead", "KPI", null,
    { x: 6, y: 3, w: 6, h: 1, static: false },
    [new Metric(COST_PER_UNIQUE_LEAD)],
  ),
  new Chart(channelType, "Campaign Performance", "TABLE", null,
    { x: 0, y: 2, w: 6, h: 2, static: false },
    [
      new Metric(SPEND),
      new Metric({ id: "unique_leads", name: "Unique Leads" }),
      new Metric(COST_PER_UNIQUE_LEAD),
    ],
    null,
    [CAMPAIGN_NAME],
  ),
  new Chart(channelType, "Adset Performance", "TABLE", null,
    { x: 6, y: 3, w: 6, h: 3, static: false },
    [
      new Metric(SPEND),
      new Metric(LEADS),
      new Metric(COST_PER_UNIQUE_LEAD),
      new Metric({ id: "cpc", name: "CPC (all)" }),
    ],
    null,
    [ADSET_NAME],
  ),
  new Chart(channelType, "Ad Name", "TABLE", null,
    { x: 0, y: 5, w: 6, h: 2, static: false },
    [
      new Metric(SPEND),
      new Metric(IMPRESSIONS),
      new Metric(CLICKS),
      new Metric({ id: "ctr", name: "CTR (all)" }),
      new Metric({ id: "cpc", name: "CPC (all)" }),
    ],
    null,
    [AD_NAME],
  ),
];

const overallGrid = [
  new Grid(null, null, null, overallCharts),
];


const socialEngagementCharts = [
  new Chart(channelType, "Ads Engagement", "LINE", null,
    { x: 0, y: 0, w: 6, h: 2, static: false },
    [new Metric(POST_ENGAGEMENT)],
  ),
  new Chart(channelType, "Ads Comments", "LINE", null,
    { x: 6, y: 0, w: 6, h: 2, static: false },
    [new Metric(POST_COMMENTS)],
  ),
  new Chart(channelType, "Total Engagement", "KPI", null,
    { x: 0, y: 2, w: 3, h: 1, static: false },
    [new Metric(POST_ENGAGEMENT)],
  ),
  new Chart(channelType, "Total Comments", "KPI", null,
    { x: 3, y: 2, w: 3, h: 1, static: false },
    [new Metric(POST_COMMENTS)],
  ),
  new Chart(channelType, "Social Reach", "KPI", null,
    { x: 6, y: 2, w: 6, h: 1, static: false },
    [new Metric(REACH)],
  ),
  new Chart(channelType, "Distribution by reactions", "STACKBAR", null,
    { x: 0, y: 3, w: 6, h: 2, static: false },
    [
      new Metric({ id: "post_like_reactions", name: "Post Like reactions" }),
      new Metric({ id: "post_love_reactions", name: "Post Love reactions" }),
      new Metric({ id: "post_wow_reactions", name: "Post Wow reactions" }),
      new Metric({ id: "post_haha_reactions", name: "Post Haha reactions" }),
      new Metric({ id: "post_sad_reactions", name: "Post Sad reactions" }),
      new Metric({ id: "post_angry_reactions", name: "Post Angry reactions" }),
    ],
  ),
  new Chart(channelType, "Ads Funnel", "FUNNEL", null,
    { x: 6, y: 3, w: 6, h: 2, static: false },
    [
      new Metric(REACH),
      new Metric(IMPRESSIONS),
      new Metric(CLICKS),
      new Metric({ id: "conversions", name: "All Conversions" }),
    ],
  ),
  new Chart(channelType, "Engagement by Campaigns", "TABLE", null,
    { x: 0, y: 5, w: 6, h: 2, static: false },
    [
      new Metric(IMPRESSIONS),
      new Metric(POST_ENGAGEMENT),
      new Metric(POST_COMMENTS),
      new Metric({ id: "post_like_reactions", name: "Post Like reactions" }),
      new Metric({ id: "post_shares", name: "Post shares" }),
      new Metric({ id: "post_shares", name: "Post shares" }),
      new Metric({ id: "post_reactions", name: "Post reactions" }),
    ],
    null,
    [CAMPAIGN_NAME],
  ),
  new Chart(channelType, "Reach (Gender)", "DONUT", null,
    { x: 6, y: 5, w: 6, h: 2, static: false },
    [
      new Metric(REACH, new Filter("Male", null, [FILTER_GENDER_MALE])),
      new Metric(REACH, new Filter("Female", null, [FILTER_GENDER_FEMALE])),
      new Metric(REACH, new Filter("Uncategorized", null, [FILTER_GENDER_UNCATEGORIZED])),
    ],
  ),
  new Chart(channelType, "Post Engagement (Gender)", "DONUT", null,
    { x: 0, y: 7, w: 6, h: 2, static: false },
    [
      new Metric(POST_ENGAGEMENT, new Filter("Male", null, [FILTER_GENDER_MALE])),
      new Metric(POST_ENGAGEMENT, new Filter("Female", null, [FILTER_GENDER_FEMALE])),
      new Metric(POST_ENGAGEMENT, new Filter("Uncategorized", null, [FILTER_GENDER_UNCATEGORIZED])),
    ],
  ),
  new Chart(channelType, "Post Comments (Gender)", "DONUT", null,
    { x: 6, y: 7, w: 6, h: 2, static: false },
    [
      new Metric(POST_COMMENTS, new Filter("Male", null, [FILTER_GENDER_MALE])),
      new Metric(POST_COMMENTS, new Filter("Female", null, [FILTER_GENDER_FEMALE])),
      new Metric(POST_COMMENTS, new Filter("Uncategorized", null, [FILTER_GENDER_UNCATEGORIZED])),
    ],
  ),
];

const socialEngagementGrid = [
  new Grid(null, null, null, socialEngagementCharts),
];


const onsiteConversionCharts = [
  new Chart(channelType, "Leads Dyanmics", "LINEBAR", null,
    { x: 0, y: 0, w: 6, h: 2, static: false },
    [new Metric(LEADS), /* new Metric({ id: "Leads Form", name: "Leads Form" }) */],
    // [new Metric({ id: "Total Custom Conversions", name: "Total Custom Conversions" })],
  ),
  new Chart(channelType, "Cost per Conversion", "KPI", null,
    { x: 6, y: 0, w: 3, h: 1, static: false },
    [new Metric(COST_PER_PURCHASE)],
  ),
  new Chart(channelType, "Lead Conversions", "KPI", null,
    { x: 9, y: 0, w: 3, h: 1, static: false },
    [new Metric(LEADS)],
  ),
  new Chart(channelType, "Completed Registration", "KPI", null,
    { x: 6, y: 1, w: 6, h: 1, static: false },
    [new Metric({ id: "complete_registration", name: "Registrations completed" })],
  ),
  // new Chart(channelType, "Conversion Rate", "KPI", null,
  //   { x: 0, y: 0, w: 3, h: 1, static: false },
  //   [new Metric({ id: "Conversion Rate", name: "Conversion Rate" })],
  // ),
  // new Chart(channelType, "Leads Form", "KPI", null,
  //   { x: 0, y: 0, w: 3, h: 1, static: false },
  //   [new Metric({ id: "Leads Form", name: "Leads Form" })],
  // ),
  // new Chart(channelType, "Total Custom Conversions", "KPI", null,
  //   { x: 0, y: 0, w: 3, h: 1, static: false },
  //   [new Metric({ id: "Total Custom Conversions", name: "Total Custom Conversions" })],
  // ),
  new Chart(channelType, "Conversion by Campaign", "TABLE", null,
    { x: 0, y: 2, w: 6, h: 2, static: false },
    [
      new Metric(LEADS),
      // new Metric({ id: "Leads Form", name: "Leads Form" }),
      new Metric(COST_PER_PURCHASE),
      // new Metric({ id: "Conversion Rate", name: "Conversion Rate" }),
      // new Metric({ id: "Total Custom Conversions", name: "Total Custom Conversions" }),
    ],
    null,
    [CAMPAIGN_NAME],
  ),
  new Chart(channelType, "Conversion by Platform", "TABLE", null,
    { x: 6, y: 2, w: 6, h: 2, static: false },
    [
      new Metric(LEADS),
      // new Metric({ id: "Leads Form", name: "Leads Form" }),
      new Metric(COST_PER_PURCHASE),
      // new Metric({ id: "Conversion Rate", name: "Conversion Rate" }),
      // new Metric({ id: "Total Custom Conversions", name: "Total Custom Conversions" }),
    ],
    null,
    [{ id: "device_platform", name: "Device platform" }],
  ),
  new Chart(channelType, "Conversion by Demographics", "TABLE", null,
    { x: 0, y: 4, w: 6, h: 2, static: false },
    [
      new Metric(LEADS),
      // new Metric({ id: "Leads Form", name: "Leads Form" }),
      new Metric(COST_PER_PURCHASE),
      // new Metric({ id: "Conversion Rate", name: "Conversion Rate" }),
      // new Metric({ id: "Total Custom Conversions", name: "Total Custom Conversions" }),
    ],
    null,
    [GENDER, AGE],
  ),
  new Chart(channelType, "Conversion by Location", "TABLE", null,
    { x: 6, y: 4, w: 6, h: 2, static: false },
    [
      new Metric(LEADS),
      // new Metric({ id: "Leads Form", name: "Leads Form" }),
      new Metric(COST_PER_PURCHASE),
      // new Metric({ id: "Conversion Rate", name: "Conversion Rate" }),
      // new Metric({ id: "Total Custom Conversions", name: "Total Custom Conversions" }),
    ],
    null,
    [{ id: "country", name: "Country" }],
  ),
];

const onsiteConversionsGrid = [
  new Grid(null, null, null, onsiteConversionCharts),
];


const onsiteBehaviourCharts = [
  new Chart(channelType, "Landing Page Views", "LINE", null,
    { x: 0, y: 0, w: 6, h: 2, static: false },
    [new Metric(LANDING_PAGE_VIEWS)],
  ),
  new Chart(channelType, "View Content", "LINE", null,
    { x: 6, y: 0, w: 6, h: 2, static: false },
    [new Metric({ id: "view_content", name: "Content views" })],
  ),
  new Chart(channelType, "View Content", "KPI", null,
    { x: 0, y: 2, w: 3, h: 1, static: false },
    [new Metric({ id: "view_content", name: "Content views" })],
  ),
  new Chart(channelType, "Unique Content View", "KPI", null,
    { x: 3, y: 2, w: 3, h: 1, static: false },
    [new Metric({ id: "unique_content_view", name: "Unique content views" })],
  ),
  new Chart(channelType, "Unqiue Landing Page Views", "KPI", null,
    { x: 6, y: 2, w: 3, h: 1, static: false },
    [new Metric({ id: "unique_landing_page_view", name: "Unique landing page views" })],
  ),
  new Chart(channelType, "Landing Page Views", "KPI", null,
    { x: 9, y: 2, w: 3, h: 1, static: false },
    [new Metric(LANDING_PAGE_VIEWS)],
  ),
  new Chart(channelType, "Cost per landing page views", "KPI", null,
    { x: 0, y: 3, w: 6, h: 1, static: false },
    [new Metric({ id: "cost_per_landing_page_view", name: "Cost per landing page view" })],
  ),
  new Chart(channelType, "Cost per unique content view", "KPI", null,
    { x: 6, y: 3, w: 6, h: 1, static: false },
    [new Metric({ id: "cost_per_unique_content_view", name: "Cost per unique content view" })],
  ),
];

const onsiteBehaviourGrid = [
  new Grid(null, null, null, onsiteBehaviourCharts),
];


const ecommerceCharts = [
  new Chart(channelType, "Funnel Conversion", "FUNNEL", null,
    { x: 0, y: 0, w: 6, h: 2, static: false },
    [
      new Metric(IMPRESSIONS),
      new Metric(LANDING_PAGE_VIEWS),
      new Metric({ id: "add_to_cart", name: "Adds to cart" }),
      new Metric({ id: "initiated_checkout", name: "Checkouts initiated" }),
      new Metric(WEBSITE_PURCHASES),
    ],
  ),
  new Chart(channelType, "Leads Dyanmics", "LINEBAR", null,
    { x: 6, y: 0, w: 6, h: 2, static: false },
    [new Metric(WEBSITE_PURCHASES)],
    [new Metric(WEBSITE_PURCHASE_CONVERSION_VALUE)],
  ),
  new Chart(channelType, "Website ROAS", "KPI", null,
    { x: 0, y: 2, w: 6, h: 1, static: false },
    [new Metric(WEBSITE_PURCHASE_ROAS)],
  ),
  new Chart(channelType, "Website Purchase (In Dollars)", "KPI", null,
    { x: 6, y: 2, w: 3, h: 1, static: false },
    [new Metric(WEBSITE_PURCHASE_CONVERSION_VALUE)],
  ),
  new Chart(channelType, "Count of Purchases", "KPI", null,
    { x: 9, y: 2, w: 3, h: 1, static: false },
    [new Metric(WEBSITE_PURCHASES)],
  ),
  new Chart(channelType, "Sales by Demographics", "TABLE", null,
    { x: 0, y: 3, w: 6, h: 2, static: false },
    [
      new Metric(WEBSITE_PURCHASES),
      new Metric(WEBSITE_PURCHASE_CONVERSION_VALUE),
    ],
    null,
    [GENDER, AGE],
  ),
  new Chart(channelType, "Sales by Campaign", "TABLE", null,
    { x: 6, y: 3, w: 6, h: 2, static: false },
    [
      new Metric(CLICKS),
      new Metric(WEBSITE_PURCHASES),
      new Metric(SPEND),
      new Metric(WEBSITE_PURCHASE_CONVERSION_VALUE),
      new Metric(WEBSITE_PURCHASE_ROAS),
    ],
    null,
    [CAMPAIGN_NAME],
  ),
  new Chart(channelType, "Sales by Adset", "TABLE", null,
    { x: 0, y: 5, w: 6, h: 2, static: false },
    [
      new Metric(CLICKS),
      new Metric(WEBSITE_PURCHASES),
      new Metric(SPEND),
      new Metric(WEBSITE_PURCHASE_CONVERSION_VALUE),
      new Metric(WEBSITE_PURCHASE_ROAS),
    ],
    null,
    [ADSET_NAME],
  ),
  new Chart(channelType, "Sales by Ad Name", "TABLE", null,
    { x: 6, y: 5, w: 6, h: 2, static: false },
    [
      new Metric(CLICKS),
      new Metric(WEBSITE_PURCHASES),
      new Metric(SPEND),
      new Metric(WEBSITE_PURCHASE_CONVERSION_VALUE),
      new Metric(WEBSITE_PURCHASE_ROAS),
    ],
    null,
    [AD_NAME],
  ),
];

const ecommerceGrid = [
  new Grid(null, null, null, ecommerceCharts),
];


export default [
  new Tab("Overall", 0, overallGrid),
  new Tab("Social Engagement", 1, socialEngagementGrid),
  new Tab("On-site Conversions", 2, onsiteConversionsGrid),
  new Tab("On-site Behaviour", 3, onsiteBehaviourGrid),
  new Tab("Ecommerce", 4, ecommerceGrid),
];
