import { useEffect, useState } from "react";
import EditDataSource from "./EditDataSources";
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import { Grid, Typography, Link, Tooltip, IconButton, Popover, FormControlLabel, Checkbox, Box } from "@mui/material";
import { ReactComponent as NoResultsIcon } from "../../../Assets/errorMsgImages/NoResults.svg";
import { ReactComponent as SelectNewIcon } from "../../../Assets/Images/CreateNew.svg";
import { ReactComponent as EditIcon } from "../../../Assets/Icons/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../Assets/Icons/delete.svg";
import { ReactComponent as InfoIcon } from "../../../Assets/Icons/info-square.svg";
import { ReactComponent as FilterIcon } from "../../../Assets/Icons/filterIcon.svg";
import { ReactComponent as CheckedIcon } from "../../../Assets/Icons/checked_icon.svg";
import { ReactComponent as UncheckedIcon } from "../../../Assets/Icons/unchecked_icon.svg";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Scrollbars from "react-custom-scrollbars";
import { connect } from "react-redux";
import { blendsList, deleteBlend } from "../../../api/blends/action";
import { GetChannel } from "../../../models/channel";
import { getAllCustomDimensions } from "../../../api/channel/action";
import SuccessModal from "../../Modal/SuccessModal";
import FailureModal from "../../Modal/FailureModal";
import { updateLoader } from "../../../api/user/action";
import SearchBar from "../../Common/SearchBar";
import DeleteModal from "../../Modal/DeleteModal";
import "../../styles/main.css";
import BlendTemplateModal from "../../Modal/BlendTemplateModal";
import { handleMixPanelData, EVENTS } from "../../../utils/mixpanelV2";

const BlendDataSource = (props) => {
    const { blendsList, blends, deleteBlend, user, allCustomDims, getAllCustomDimensions, updateLoader } = props;
    const [updateSource, setUpdateSource] = useState(null);
    const [displaySources, setDisplaySources] = useState(false);
    const [isTemplate, setIsTemplate] = useState(false);
    const [completion, setCompletion] = useState({ message: "none", operation: "none" });
    const [blendModal, setBlendModal] = useState(false);
    const [currentBlend, setCurrentBlend] = useState(null);

    useEffect(() => {
        setDisplaySources(false)
    }, [completion])

    const [filteredBlends, setFilteredBlends] = useState(blends ?? [])
    useEffect(() => {
        setFilteredBlends(blends ?? [])
    }, [blends])

    const deleteSource = (sourceId) => {
        updateLoader(true);
        deleteBlend({
            id: sourceId,
            loading: (msg) => {
                updateLoader(false);
                setCompletion({ message: msg, operation: "Deletion" });
            }
        });
    }

    const resetData = () => {
        setUpdateSource(null);
        setDisplaySources(false);
        setIsTemplate(false);
    }

    const selectTemplate = (source) => {
        setUpdateSource(source);
        setDisplaySources(true);
        setIsTemplate(true);
        handleClose();
    }

    useEffect(() => {
        updateLoader(true);
        blendsList({ update: () => updateLoader(false) });
        if (!allCustomDims || !allCustomDims.length) {
            getAllCustomDimensions();
        }
    }, []);

    const [isInfo, setIsInfo] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectChannels, setSelectChannels] = useState({});
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(true)
        handleMixPanelData(EVENTS.create_default_blend);
    };
    const handleClose = () => setIsOpen(false);

    return (displaySources ?
        <EditDataSource
            key={updateSource?.id} source={updateSource}
            isTemplate={isTemplate}
            setIsTemplate={setIsTemplate}
            setCompletion={setCompletion}
            resetData={resetData}
        /> :
        <>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                width: "100%",
                background: '#FFFFFF',
                borderRadius: '12px',
                padding: '12px 24px',
                position: 'relative',
                boxShadow: '12px 12px 48px 0px rgba(80, 80, 159, 0.04)',
                marginBottom: "24px"
            }}>
                <Typography style={{ fontFamily: "Inter", fontSize: "20px", fontWeight: "600", lineHeight: "32px" }}>Data Blending</Typography>
            </Box>
            <div style={{ margin: "0px", height: "calc(100% - 80px)" }} >
                <Grid container flexDirection={"column"} columnSpacing={{ xs: 1, sm: 2, md: 3 }} spacing={2} style={{ margin: "15px auto", height: "100%", width: "100%" }}>
                    <Grid item xs={3} className="gridItem" style={{ padding: "0px", minHeight: "100%" }} >
                        <div style={{ padding: "20px 0px", minHeight: "100%", height: "100%" }}>
                            <div style={{ minHeight: "calc(100% - 50px)", height: "100%" }}>
                                {isInfo ?
                                    <Scrollbars autoHide style={{ height: "100%" }}>
                                        <div style={{ padding: "0px 20px", height: "100%" }}>
                                            <div className=" h18 lato displayRowSpaced" style={{ margin: "10px 0px" }}>
                                                <span className="displayRowCenter">
                                                    <IconButton
                                                        onClick={() => setIsInfo(false)}
                                                        style={{ marginRight: "10px", padding: "8px", background: "#F3F4F8" }}
                                                        aria-label="go back"
                                                    >
                                                        <ArrowBackIcon style={{ width: 16, height: 16 }} />
                                                    </IconButton>
                                                    Info
                                                </span>
                                                <InfoIcon />
                                            </div>
                                            <hr style={{ border: "1px solid #EAEAEC", margin: "10px 0px 20px 0px" }} />
                                            <div className="inter h14" style={{ padding: "5px 20px", borderLeft: "5px solid #E5E9F4", position: "relative", color: "#6D6E7C" }}>
                                                <TripOriginIcon style={{ color: "#0869FB", background: "white", position: "absolute", top: -10, left: -15 }} />
                                                Data Blending is one of the helpful features developed to analyze the and club the marketing data by joining different sources such as ads channel metrics i.e impressions, clicks, spends etc with attribution channels metrics no. of users, landing page view, sessions etc and visualize into the same widget.
                                            </div>
                                            <div className="inter h14" style={{ padding: "5px 20px", borderLeft: "5px solid #E5E9F4", position: "relative", color: "#6D6E7C" }}>
                                                <TripOriginIcon style={{ color: "#0869FB", background: "white", position: "absolute", top: -10, left: -15 }} />
                                                While joining two or more sources, you need to specify the relationship between two chosen tables whether it’s an inner, full outer, left join and right join.
                                                For more information across these joins, please go through this <Link href="https://dataschool.com/how-to-teach-people-sql/sql-join-types-explained-visually/">link</Link>.
                                                Note: You need to specify the same joining key across different data sources for accurate results with mandatory at least one dimension and metric.
                                            </div>
                                            <div className="inter h14" style={{ padding: "5px 20px", borderLeft: "5px solid #FFFFFF", position: "relative", color: "#6D6E7C" }}>
                                                <TripOriginIcon style={{ color: "#0869FB", background: "white", position: "absolute", top: -10, left: -15 }} />
                                                Once you’ve created your data source, you can go to “Edit Widget” -{">"} “Data Sources” -{">"} and choose your customized data source to collaborate metrics within the same view and fasten your action using those visualizations.
                                                Example: Spends from different ads platforms into one line chart.
                                            </div>
                                        </div>
                                    </Scrollbars> :
                                    <div className="displayColCenter" style={{ padding: "0px 20px", height: "100%" }}>
                                        <div className="h18 lato displayRowSpaced" style={{ margin: "10px 0px 20px 0px" }}>
                                            Existing Data Sources
                                            <InfoIcon className="pointer" onClick={() => setIsInfo(true)} />
                                        </div>
                                        <div style={{ display: "flex", gap: "10px" }}>
                                            <SearchBar filteredData={blends} setFilteredData={setFilteredBlends} keys={["name", "lastSheetName", "lastTabName"]} />
                                            <div className="pointer border1" onClick={(e) => setAnchorEl(e.currentTarget)} >
                                                <FilterIcon style={{ height: "18px", width: "18px" }} />
                                            </div>
                                        </div>
                                        <Popover id='channel-filter-popover'
                                            open={Boolean(anchorEl)}
                                            anchorEl={anchorEl}
                                            onClose={() => setAnchorEl(null)}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                                        >
                                            <div style={{ padding: "12px" }}>
                                                {props.authenticatedAccounts.map(acc => {
                                                    const channel = GetChannel(acc);
                                                    return (<FormControlLabel
                                                        control={<Checkbox checked={selectChannels[acc]}
                                                            checkedIcon={<CheckedIcon />} icon={<UncheckedIcon />}
                                                            onChange={(e) => setSelectChannels({ ...selectChannels, [acc]: e.target.checked })}
                                                        />}
                                                        label={channel.title}
                                                        sx={{
                                                            display: "block", padding: "6px 12px", margin: 0, borderRadius: "8px",
                                                            "&:hover": { background: "#E3EEFF" },
                                                        }}
                                                    />)
                                                })}
                                            </div>
                                        </Popover>
                                        {blends.length ?
                                            <Scrollbars autoHide style={{ height: "90%", marginTop: "5px", width: "calc(100% + 40px)" }}>
                                                {
                                                    filteredBlends.filter(source => (
                                                        !Object.values(selectChannels).includes(true) ||
                                                        source.dsConfigs.some(src => selectChannels[src.dataSource])
                                                    )).map(source => {
                                                        return (
                                                            <div style={{ margin: "10px 20px", border: "1px solid #EAEAEC", borderRadius: "6px" }}>
                                                                <div className="displayRowSpaced" style={{ background: "#F6F7F8", padding: "12px" }}>
                                                                    <Typography variant="body1" className="h14" style={{ overflowWrap: "anywhere" }}>
                                                                        {source.name}
                                                                    </Typography>
                                                                    <div style={{ display: "flex" }}>
                                                                        <IconButton
                                                                            style={{
                                                                                marginLeft: "8px", height: "24px", width: "24px", padding: "3px",
                                                                                background: "#fff", border: "1px solid #EAEAEC", borderRadius: "4px",
                                                                            }}
                                                                            onClick={() => {
                                                                                handleMixPanelData(EVENTS.edit_blends);
                                                                                setUpdateSource(source); setDisplaySources(true);
                                                                
                                                                            }}
                                                                        >
                                                                            <EditIcon />
                                                                        </IconButton>
                                                                        {user.role !== "viewer" &&
                                                                            <IconButton
                                                                                style={{
                                                                                    marginLeft: "8px", height: "24px", width: "24px", padding: "3px",
                                                                                    background: "#fff", border: "1px solid #EAEAEC", borderRadius: "4px",
                                                                                }}
                                                                                onClick={() => {
                                                                                    setBlendModal(!blendModal); setCurrentBlend(source);
                                                                                    
                                                                                }}
                                                                            >
                                                                                <DeleteIcon />
                                                                            </IconButton>}
                                                                    </div>
                                                                </div>
                                                                <div style={{ padding: "8px" }}>
                                                                    {
                                                                        source.dsConfigs.map(src => {
                                                                            const channel = GetChannel(src.dataSource);
                                                                            return (<div className="displayRowLeft" style={{ margin: "4px" }}>
                                                                                <img style={{ marginRight: "8px", height: 16, width: 16 }} src={channel?.icon} alt={channel?.title} />
                                                                                <Typography className="inter ellipseText" style={{ padding: "1px 8px", color: "#5C6674", fontSize: "12px" }}>
                                                                                    {src?.audiences?.[0]?.metaData?.title} ({src?.audiences?.[0]?.metaData?.id})
                                                                                </Typography>
                                                                            </div>)
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Scrollbars> :
                                            <div className="displayColCenter highlight1" style={{ height: "90%", marginTop: "15px" }}>
                                                <NoResultsIcon width="200px" height="200px" />
                                                <h3 style={{ fontFamily: "Inter", fontSize: "14px", color: "#666666", textAlign: "center" }}>
                                                    There is not any custom data source added. <br />
                                                    Create a new source to proceed.
                                                </h3>
                                            </div>}
                                    </div>}
                            </div>
                        </div>
                    </Grid>
                    <Grid container justifyContent="center" alignItems="center" item xs={9} style={{ paddingTop: 0, minHeight: "100%", gap: "10%" }}>
                        <Tooltip title={user.role === "viewer" ? "Viewer does not have permission to create!" : ""} placement="top">
                            <div className="gridItem displayColCenter" style={{ minHeight: "30%", height: "30%", width: "30%", position: "relative" }}>
                                <SelectNewIcon disabled style={{ cursor: "pointer", marginBottom: "20px" }} onClick={handleOpen} />
                                <Typography style={{ fontSize: "16px", fontWeight: 600, marginBottom: "5px" }}>Default Blends</Typography>
                                <Typography style={{ fontSize: "12px", color: "#6D6D6D", textAlign: "center" }}>Join multiple sources using ads and attribution channels.</Typography>
                                {
                                    (user.role === "viewer") &&
                                    <div style={{ position: "absolute", background: "#FFFFFFAA", height: "100%", width: "100%" }}>
                                    </div>
                                }
                            </div>
                        </Tooltip>
                        <Tooltip title={user.role === "viewer" ? "Viewer does not have permission to create!" : ""} placement="top">
                            <div className="gridItem displayColCenter" style={{ minHeight: "30%", height: "30%", width: "30%", position: "relative" }}>
                                <SelectNewIcon disabled style={{ cursor: "pointer", marginBottom: "20px" }} onClick={() => {
                                    setUpdateSource(null); setDisplaySources(true);
                                    handleMixPanelData(EVENTS.create_custom_blends);
                                }} />
                                <Typography style={{ fontSize: "16px", fontWeight: 600, marginBottom: "5px" }}>Create From Scratch</Typography>
                                <Typography style={{ fontSize: "12px", color: "#6D6D6D", textAlign: "center" }}>Join multiple sources using ads and attribution channels.</Typography>
                                {
                                    (user.role === "viewer") &&
                                    <div style={{ position: "absolute", background: "#FFFFFFAA", height: "100%", width: "100%" }}>
                                    </div>
                                }
                            </div>
                        </Tooltip>
                    </Grid>

                </Grid>
            </div>
            <SuccessModal
                modal={completion.message === "Success"}
                setModal={() => setCompletion({ message: "none", operation: "none" })}
                message={`${completion.operation} of Blends Source is Successful`}
            />

            <FailureModal
                modal={completion.message === "Fail"}
                setModal={() => setCompletion({ message: "none", operation: "none" })}
                message={`${completion.operation} of Blends Source Failed`}
            />
            {currentBlend && <DeleteModal
                modal={blendModal}
                setModal={setBlendModal}
                message={`Do you really want to delete this ${currentBlend.name} blend?`}
                onDelete={() => deleteSource(currentBlend.id)}
            />}
            {isOpen && <BlendTemplateModal isOpen={isOpen} isClose={handleClose} selectTemplate={selectTemplate} />}
        </>
    )
}

const mapStateToProps = state => ({
    blends: state.blends.blends,
    user: state.user.user,
    allCustomDims: state.channel.allCustomDims,
    authenticatedAccounts: state.channel.authenticatedAccounts
});

const mapDispatchToProps = dispatch => ({
    blendsList: (_) => dispatch(blendsList(_)),
    deleteBlend: (_) => dispatch(deleteBlend(_)),
    getAllCustomDimensions: () => dispatch(getAllCustomDimensions()),
    updateLoader: (_) => dispatch(updateLoader(_))
});

export default connect(mapStateToProps, mapDispatchToProps)(BlendDataSource);