import Modal from 'react-modal';
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ResetPassword } from "../../api/user/saga";
import { Button, Grid, TextField } from '@mui/material';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: "#ffff",
		width: "400px",
		padding: "0",
		zIndex: 9000000000000,
		position: "absolute"
	},
};

const validatePassword = (newPassword, reEnterNewPassword) => {
	const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
	if (newPassword.length === 0) {
		return { err: true, msg: "Password is required" };
	}
	if (!passwordRegex.test(newPassword)) {
		return { err: true, msg: "Invalid Password (minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character)" };
	}
	if (reEnterNewPassword.length === 0) {
		return { err: true, msg: "Confirm password is required" };
	}
	if (newPassword !== reEnterNewPassword) {
		return { err: true, msg: "Passwords do not match" };
	}
	return { err: false, msg: "" };
}

const ResetPasswordModal = () => {
	const [newPassword, setNewPassword] = useState("");
	const [reEnterNewPassword, setReEnterPassword] = useState("");
	const [isError, setError] = useState(false);
	const [message, setMessage] = useState("");
	const [modal, setModal] = useState(true);
	const reset_token = useParams().reset_token;
	const updatePassword = async () => {
		const isPswdValid = validatePassword(newPassword, reEnterNewPassword);
		if (isPswdValid.err) {
			setError(true);
			setMessage(isPswdValid.msg);
			return;
		}
		ResetPassword({
			reset_token: reset_token,
			password: newPassword
		}).then((response) => {
			setError(false);
			setMessage("password changed successfully");
			setTimeout(() => {
				window.location.href = "/login";
			}, 2000);
		}).catch((err) => {
			setError(true);
			setMessage(err.data.message);
		})

	}
	return (
		<Modal isOpen={modal} style={customStyles}>
			<div style={{ width: "100%", height: "40px", borderBottom: "1px solid #eee", padding: "10px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				<div>
					<b>Update your password</b>
				</div>
			</div>
			<div style={{ width: "100%", height: "calc(100% - 40px)", position: "relative", zIndex: 90000000, backgroundColor: "#ffff", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}>
				<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "70%" }}>
					<Grid item xs={12} style={{ padding: '4px 0 4px 0', marginBottom: "15px", marginTop: "20px" }}>
						<TextField
							label="Enter new password"
							style={{ width: '100%' }}
							type="password"
							InputLabelProps={{ shrink: true }}
							size="small"
							value={newPassword}
							onChange={(e) => setNewPassword(e.target.value)}
							variant="outlined" />
					</Grid>
					<Grid item xs={12} style={{ padding: '4px 0 4px 0', marginBottom: "15px" }}>
						<TextField
							label="Re-enter new password"
							style={{ width: '100%' }}
							type="password"
							InputLabelProps={{ shrink: true }}
							size="small"
							value={reEnterNewPassword}
							onChange={(e) => setReEnterPassword(e.target.value)}
							variant="outlined" />
					</Grid>
					{
						message.length > 0 && <Grid style={{ padding: '4px 0 4px 0' }} item xs={12}>
							{
								isError ? <p style={{ color: "red", textAlign: "right", lineHeight: 1, fontSize: "10px" }}>{message}</p> : <p style={{ color: "green", textAlign: "right", lineHeight: 0 }}>{message}</p>
							}
						</Grid>
					}
					<Grid item xs={12} style={{ padding: '4px 0 4px 0', marginTop: "15px" }}>
						<Button style={{ backgroundColor: "#0869FB", color: "white", padding: "10px", marginBottom: '20px', textAlign: "right", float: "right", borderRadius: "10px", width: "100%" }} onClick={() => updatePassword()}>Update password</Button>
					</Grid>
				</div>
			</div>
		</Modal>
	)
}
export default ResetPasswordModal;