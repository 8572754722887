import { Box, Typography } from "@mui/material";
import Expand from "../../Assets/Icons/Left.svg";
import Trash from "../../Assets/Icons/trash.svg";
import FilterIcon from "../../Assets/Icons/newFilter.svg";
import { useContext, useState } from "react";
import { ShareContext } from "../EditWidget/Sidebar";

const FilterAccod = ({ chart, appliedFilter, editFilter, applyFilter, deleteFilter }) => {
    const { shared } = useContext(ShareContext) || {};
    const [isExpand, setisExpand] = useState(true);
    const filterGroups = appliedFilter.filterGroups;
    const segments = appliedFilter.additionalFilters[0]?.values?.filter(val => val);
    const unify = appliedFilter.additionalFilters[0]?.unify_data

    return (
        <>
            <Box sx={{ width: "100%", padding: "16px", background: "#FFF", marginBottom: "16px", borderRadius: "8px" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: "700", lineHeight: "20px" }}>
                        {appliedFilter.name}</Typography>
                    <div style={{ display: "flex", alignItems: "center", gap: "8px", justifyContent: "flex-end" }}>
                        {!shared && <button
                            onClick={() => editFilter(appliedFilter)}
                            style={{ border: "none", background: "none", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", padding: "0px", zIndex: 100 }} >
                            <img src={FilterIcon} alt="" />
                        </button>}
                        {!shared && <button
                            onClick={() => deleteFilter(appliedFilter)}
                            style={{ border: "none", background: "none", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", padding: "0px", zIndex: 100 }} >
                            <img src={Trash} alt="" />
                        </button>}
                        <button
                            onClick={() => setisExpand(!isExpand)}
                            style={{
                                border: "none", background: "none", cursor: "pointer", display: "flex", alignItems: "center",
                                justifyContent: "center", padding: "0px", zIndex: 100,

                            }} >
                            <img src={Expand} style={{ transform: isExpand ? "rotate(0deg)" : "rotate(180deg)", }} alt="" />
                        </button>
                    </div>
                </div>

                <Box sx={{ width: "100%", height: isExpand ? "30px" : "auto", overflow: "hidden", display: "flex", flexDirection: "column" }}>
                    <div style={{ display: " flex", justifyContent: "space-between", position: "relative", gap: "15px" }}>
                        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                            {(filterGroups && filterGroups.length) ? <Typography style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: "400", lineHeight: "20px", marginTop: "8px" }}>
                                <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "6px" }}>
                                    {
                                        filterGroups.map((filterGroup, index) => {
                                            return <span style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                                                {
                                                    filterGroup.filters.map((filter, index) => {
                                                        return (
                                                            <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "6px" }}>
                                                                <span style={{ border: "1px solid #EAEAEC", padding: "0px 8px", borderRadius: "6px", gap: "6px" }}>
                                                                    {" " + filter.fieldName + " " + (filter.not ? "NOT " : "") + filter.operator + " " + filter.expressions + " "}

                                                                </span>
                                                                {(filterGroup.filters.length === (index + 1) ? "" :
                                                                    <b style={{ border: "1px solid #EAEAEC", padding: "0px 8px", borderRadius: "6px" }}>{filterGroup.operator}</b>
                                                                )}
                                                            </div>
                                                        )

                                                    })
                                                }

                                                {filterGroups.length !== (index + 1)
                                                    ? <b style={{ border: "1px solid #EAEAEC", padding: "0px 8px", borderRadius: "6px" }}> {appliedFilter.operator} </b>
                                                    : ""
                                                }
                                            </span>;
                                        })
                                    }
                                </div>

                            </Typography> : null}
                            {(segments && segments.length) ?
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Typography style={{ fontFamily: "Inter", fontWeight: "700", fontSize: "12px", lineHeight: "20px" }}>Parameters: </Typography>
                                    <Typography style={{ border: "1px solid #EAEAEC", padding: "0px 8px", borderRadius: "6px", gap: "6px", fontFamily: "Inter", fontWeight: "400", fontSize: "12px", marginLeft: "10px" }}>

                                        {segments.join(", ")}
                                        {unify && " Unify: " + unify.value}
                                    </Typography>
                                </div>
                                : null
                            }
                        </div>

                        {chart &&
                            <Typography
                                style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: 500, color: "#0869FB", cursor: "pointer", marginTop: "10px" }}
                                onClick={() => { applyFilter(appliedFilter) }}
                            >
                                Apply
                            </Typography>
                        }
                    </div>

                </Box>

            </Box>
        </>
    )
}
export default FilterAccod;