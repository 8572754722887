import Modal from '@mui/material/Modal';
import { Box, Button, IconButton } from '@mui/material';
import UserIcon from "../../Assets/Icons/User.svg";
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { connect } from 'react-redux';
import { channelType } from '../../api/adjust/transformer';
import { connectAccountApi, closeConnectingAdjust } from '../../api/adjust/action';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 386,
    width: '25%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '24px',
    borderRadius: '12px'
};

const closeButtonStyle = {
    borderRadius: '8px',
    height: '40px',
    width: '40px',
    padding: '8px',
    background: '#f8f9fa',
}

function AdjustConnectModal({ isConnecting, connectAccountApi, close, redirect_url = "selectchannels" }) {
    const [state, setState] = useState({
        adjust_access_token: "",
        token_name: ""
    });

    const handleChange = (property, value) => {
        setState({ ...state, [property]: value });
    }

    return (
        <Modal open={isConnecting} onClose={close} aria-labelledby="Connect Adjust" >
            <Box sx={style}>
                <div className='displayRowSpaced'>
                    <div className='connect-account-logo'>
                        <img src={UserIcon} alt="UserIcon" height='24px' width='24px' />
                    </div>
                    <IconButton aria-label="save" onClick={close} sx={closeButtonStyle}>
                        <CloseIcon sx={{ fontSize: 24 }} />
                    </IconButton>
                </div>

                <h3 style={{ textAlign: "left", marginBottom: "4px", fontSize: '20px', marginTop: '16px' }}><b>New Adjust account</b></h3>
                <div className='connect-account-instructions'>
                    <p style={{ fontSize: "14px", color: '#5C6674' }}>
                        Follow these <a href={`https://help.adjust.com/en/article/api-authentication`} target="_blank" rel="noreferrer">instructions</a> to connect your Adjust account.
                    </p>
                    <ul style={{ paddingLeft: '25px' }}>
                        <li>Select the settings icon in the bottom left corner.</li>
                        <li>Select Account settings. Your account settings page opens.</li>
                        <li>Select the My profile tab.</li>
                        <li>Your API token is shown with your User details.</li>
                    </ul>
                </div>
                <div style={{ margin: '32px 0' }}>
                    <div >
                        <label htmlFor="Adjust_url">Access Token Name</label>
                        <input
                            className='input-text'
                            placeholder="Enter access token name"
                            id="Adjust_url"
                            onChange={(e) => handleChange("store_name", e.target.value)}
                        />
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <label htmlFor="Acees-token">Access token</label>
                        <input
                            className='input-text'
                            placeholder='***************'
                            onChange={(e) => handleChange("adjust_access_token", e.target.value)}
                        />
                    </div>
                </div>
                <Button
                    sx={{ padding: "14px 24px", borderRadius: "8px", textTransform: "none", width: '100%', height: '40px', fontSize: '14px' }}
                    variant="contained"
                    onClick={() => connectAccountApi({ redirect_url, ...state })}
                >Connect An Account</Button>
            </Box>
        </Modal>
    );
}

const mapStateToProps = state => ({
    isConnecting: state[channelType].isConnecting,
})

const mapDispatchToProps = dispatch => ({
    connectAccountApi: (_) => dispatch(connectAccountApi(_)),
    close: () => dispatch(closeConnectingAdjust()),
})

export default (connect(mapStateToProps, mapDispatchToProps)(AdjustConnectModal));
