import { useEffect } from "react";
import Grid from '@mui/material/Grid';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useState } from "react";
import { Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from "@mui/material";
import { getOrgainzationMembers, getInvitedOrgainzationMembers, inviteUsers, removeInvitedUserRoleApi } from "../../api/user/saga";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ReactComponent as EditIcon } from "../../Assets/Icons/edit-icon.svg";
import ChangeInvitedTeamModal from "../Modal/ChangeInvitedTeamModal";
import RemoveInvitedUserModal from "../Modal/RemoveInvitedUserModal";
import InviteTeamModal from "../Modal/InviteTeamModal";
import ReInviteModal from "../Modal/ReInviteModal";
import { useDispatch } from "react-redux";
import { updateLoader } from "../../api/user/action";
import { handleMixPanelData,EVENTS } from "../../utils/mixpanelV2";

const ManageTeam = ({ user, setError, setMessage }) => {
    const [changeUserRoleData, setChangeUserRoleData] = useState({ email: "", existingRole: "", newRole: "", reason: "", uuid: "" });
    const [dataOfInviteNewUser, setDataOfInviteNewUser] = useState({ email: "", name: "", role: "" });
    const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);
    const [isRemoveInvitedUserModalOpen, setRemoveInvitedUserModalOpen] = useState(false);
    const [invitedUsers, setInvitedUsers] = useState(null);
    const [isEmailVerified, setEmailVerified] = useState(true);
    const [changeInvitedUserModal, setInvitedUserModal] = useState(false);
    const [pendingInvitationsUsers, setPendingInvitationUsers] = useState(null);
    const [isRequestSentSuccessFully, setRequestSentSuccessfully] = useState({ currentIndex: null, isSuccess: false });
    const [userRemoveData, setUserRemoveData] = useState(null);
    const [numberOfInvitedUser, setNumberOfInvitedUser] = useState(0);
    const [numberOfInactiveUsers, setNumberOfInactiveUsers] = useState(0);
    const [reInviteUserModal, setReInviteUserModal] = useState(false);
    const [reInviteTeamModalData, setReInviteTeamModalData] = useState({ email: "", name: "", role: "", uuid: "" });
    const [currentUser, setCurrentUser] = useState({});

    const dispatch = useDispatch();

    const resend = (pending_user, index) => {
        inviteUsers(pending_user).then((data) => {
            setRequestSentSuccessfully({ currentIndex: index, isSuccess: true });
        })
    }
    const isExternalEditor = (e) => {
        let externalOrgs = [];
        invitedUsers.map((invited_users, index) => {
            if (e == invited_users.email)
                externalOrgs = [...externalOrgs, invited_users.organization_name]
        });
        return externalOrgs;
    }
    const removeInvitedUserRole = (data) => {
        setRemoveInvitedUserModalOpen(!isRemoveInvitedUserModalOpen);
        setUserRemoveData(data);
    }
    const reInviteUser = (reInviteUserData) => {
        setReInviteUserModal(!reInviteUserModal);
        setTimeout(() => {
            setReInviteTeamModalData(reInviteUserData);
        }, 0);
    }

    const getCurrentUser = async (data) => {
        data.users.forEach((d) => {
            if (d.uuid === user.uuid && d.role === user.role && (!user.org || d.organization === user.org.id))
                setCurrentUser(d);
        });
    }

    const getOrganizationMember = () => new Promise((resolve, reject) => {
        getOrgainzationMembers().then((data) => {
            getCurrentUser(data).then(() => {
                setInvitedUsers(data.users);
                let count = 0;
                data.users.forEach(data => data.is_active !== false && count++);
                setNumberOfInvitedUser(count);
                setNumberOfInactiveUsers(data.users.length - count);
                if (user?.role === "externaleditor")
                    data.users.forEach(data => data.organization !== user.org?.id && count--);
                else
                    data.users.forEach(data => (user.org ? data.organization !== user.org?.id : data.organization_name !== user.company_name) && count--);
                setNumberOfInvitedUser(count);
                resolve();
            }).catch((err) => {
                setError(true);
                setMessage(err.data.message);
                reject();
            })
        }).catch((err) => { reject() })
    })

    const getInvitedOrgainzationMember = () => new Promise((resolve, reject) => {
        getInvitedOrgainzationMembers().then((data) => {
            setPendingInvitationUsers(data.users);
            resolve();
        }).catch(err => { reject(); })
    })

    const mappedRole = new Map([
        ["editor", { role: "Editor", des: "Accessible to modify the reports" }],
        ["superadmin", { role: "Super Admin", des: "Accessible to add and manage the data sources and organization members" }],
        ["admin", { role: "Admin", des: "Accessible to manage the data sources" }],
        ["viewer", { role: "Viewer", des: "Accessible to view the reports" }],
        ["externaleditor", { role: "External Editor", des: "Accessible to modify other org's reports as well" }]
    ])

    useEffect(() => {
        dispatch(updateLoader(true))
        Promise.all([getOrganizationMember(), getInvitedOrgainzationMember()])
            .finally(() => {
                dispatch(updateLoader(false));
            })
    }, []);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const Members = {
        "Organization Members": {
            name: "Organization Members",
            count: numberOfInvitedUser,
            list: invitedUsers?.filter(user => user.is_active !== false && user.organization === currentUser.organization),
            actions: ["Edit", "Remove"]
        },
        "Pending Invitations": {
            name: "Pending Invitations",
            count: pendingInvitationsUsers?.length || 0,
            list: pendingInvitationsUsers,
            disabled: !["admin", "superadmin"].includes(user.role),
            actions: ["Resend", "Remove"]
        },
        "Inactive Users": {
            name: "Inactive Users",
            count: numberOfInactiveUsers,
            list: invitedUsers?.filter(user => user.is_active === false),
            disabled: !["admin", "superadmin"].includes(user.role),
            actions: ["Unblock", "Remove"]
        }
    }

    const [displayMembers, setDisplayMembers] = useState(Object.keys(Members)[0]);

    useEffect(() => {
        setPage(0);
    }, [displayMembers])

    return (
        <div className="main_container" style={{ padding: 0 }} >
            <Grid className="gridItem" container >
                {/*Manage Team*/}
                <Grid xs={12} className="displayRowSpaced">
                    <h3 className="inter h22">Your Team</h3>
                    {(user?.role === "admin" || user?.role === "superadmin") &&
                        <Button data-tut="invite_users" variant="contained" sx={{ backgroundColor: "#0869FB" }} style={{ textTransform: "none" }}
                            onClick={() => { setIsInviteUserModalOpen(!isInviteUserModalOpen); handleMixPanelData(EVENTS.click_invite_team) }}
                        >
                            Invite New Users
                        </Button>
                    }
                </Grid>
                <hr style={{ border: "1px solid #EAEAEC", margin: "15px -20px", width: "calc(100% + 40px)" }} />
                <div data-tut="view_members" className="hightlight2" style={{ display: "flex", padding: "15px", margin: "5px 0px 20px" }}>
                    {
                        Object.values(Members).map(member => {
                            return (
                                <Tooltip placement="top" style={{ cursor: "pointer" }}
                                    title={member.disabled ? `User does not have the permission to view ${member.name}` : ""}
                                >
                                    <Typography
                                        sx={{
                                            width: `max-content`, padding: "6px 25px", borderRadius: "6px", textAlign: "center", cursor: member.disabled ? "default" : "pointer",
                                            color: member.disabled ? "#C0C1C7" : "#585969", fontSize: "14px", textTransform: "uppercase"
                                        }}
                                        style={displayMembers === member.name ? { background: "#0869FB", color: "white" } : {}}
                                        onClick={() => { !member.disabled && setDisplayMembers(member.name) }}
                                    >
                                        {member.name} ({member.count})
                                    </Typography>
                                </Tooltip>
                            )
                        })
                    }
                </div>
                <div className="hightlight2">
                    <TableContainer className="trigger-table" sx={{ maxHeight: "70vh" }}>
                        <Table
                            stickyHeader
                            sx={{ minWidth: "100%", width: "auto", tableLayout: "fixed" }}
                            aria-label="simple table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell width="max-content">NAME</TableCell>
                                    <TableCell width="max-content">EMAIL</TableCell>
                                    <TableCell align="left">ROLE</TableCell>
                                    <TableCell sx={{ width: "14rem", textAlign: "left", paddingLeft: "30px" }}>ACTION</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {((rowsPerPage > 0
                                    ? Members[displayMembers].list?.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    : Members[displayMembers].list
                                ) ?? []).map((invited_user, index) => {
                                    return (
                                        <TableRow
                                            key={invited_user._id}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        >
                                            <TableCell scope="row">{invited_user.name}</TableCell>
                                            <TableCell scope="row">{invited_user.email}</TableCell>

                                            <TableCell align="left">
                                                <div className="displayCol">
                                                    <span style={{ display: "flex", alignItems: "center" }}>
                                                        {mappedRole.get(invited_user.role).role}
                                                        {
                                                            (invited_user.role !== "externaleditor" && isExternalEditor(invited_user.email).length > 1) &&
                                                            <Tooltip
                                                                title={isExternalEditor(invited_user.email).filter(e => e !== invited_user.organization_name).join(", ")}
                                                                style={{ cursor: "pointer" }}
                                                                placement="top">
                                                                <span>{("/" + mappedRole.get("externaleditor").role)}</span>
                                                            </Tooltip>

                                                        }{
                                                            (Members[displayMembers].actions.includes("Edit") && ["admin", "superadmin"].includes(user.role) && !["admin", "superadmin", "externaleditor"].includes(invited_user.role)) &&
                                                            <EditIcon style={{ cursor: "pointer", margin: "6px 8px", height: 16, width: 16 }}
                                                                onClick={() => {
                                                                    setChangeUserRoleData({ ...changeUserRoleData, existingRole: invited_user.role, uuid: invited_user.uuid, email: invited_user.email });
                                                                    setInvitedUserModal(!changeInvitedUserModal);
                                                                }}
                                                                fill={"#0869FB"}
                                                            />
                                                        }
                                                    </span>
                                                    <span className="greyText">{mappedRole.get(invited_user.role).des}</span>
                                                </div>
                                            </TableCell>

                                            <TableCell sx={{ textAlign: "center" }}>
                                                <Tooltip placement="top" style={{ cursor: "pointer" }}
                                                    title={displayMembers === "Organization Members" && (Members[displayMembers].actions.includes("Remove") && ["admin", "superadmin"].includes(invited_user.role) ?
                                                        " Admin Users cannot be removed!" :
                                                        !["admin", "superadmin"].includes(user.role) ? "User does not have the permission to remove" : "")
                                                    }
                                                >
                                                    <div style={{ display: "flex" }}>
                                                        {Members[displayMembers].actions.includes("Remove") &&
                                                            <IconButton
                                                                onClick={() => removeInvitedUserRole({ user_uuid: invited_user.uuid, API_TYPE: displayMembers })}
                                                                disabled={!(["admin", "superadmin"].includes(user.role) && !["admin", "superadmin"].includes(invited_user.role)) && displayMembers === "Organization Members"}
                                                                sx={{ ":hover": { background: "#EBF3FF", border: "1px solid #0869FB" } }}
                                                                disableRipple
                                                            >
                                                                <p style={{
                                                                    cursor: "pointer", lineHeight: 0, margin: "auto", padding: "5px 10px", fontSize: "12px",
                                                                    color: (["admin", "superadmin"].includes(user.role) && !["admin", "superadmin"].includes(invited_user.role)) ? "#F34A3F" : displayMembers !== "Organization Members" ? "#F34A3F" : "#6D6E7C",
                                                                }}>
                                                                    <b>Remove</b>
                                                                </p>
                                                            </IconButton>
                                                        }

                                                        {Members[displayMembers].actions.includes("Resend") &&
                                                            <IconButton
                                                                onClick={() => resend({ email: invited_user.email, name: invited_user.name, role: invited_user.role }, index)}
                                                                sx={{ ":hover": { background: "#EBF3FF", border: "1px solid #0869FB" } }}
                                                                disableRipple
                                                            >
                                                                <p style={{ margin: "auto", padding: "5px 10px", fontSize: "12px", color: "#0869FB", cursor: "pointer", lineHeight: 0 }}>
                                                                    <b>
                                                                        {isRequestSentSuccessFully.currentIndex !== null && isRequestSentSuccessFully.currentIndex === index && isRequestSentSuccessFully.isSuccess ? "Sent" : "Resend"}
                                                                    </b>
                                                                </p>
                                                            </IconButton>
                                                        }

                                                        {Members[displayMembers].actions.includes("Unblock") &&
                                                            <IconButton
                                                                onClick={() => reInviteUser({ name: invited_user.name, email: invited_user.email, role: "", user_uuid: invited_user.uuid })}
                                                                sx={{ ":hover": { background: "#EBF3FF", border: "1px solid #0869FB" } }}
                                                                disableRipple
                                                            >
                                                                <p style={{ margin: "auto", padding: "5px 10px", fontSize: "12px", color: "#0869FB", cursor: "pointer", lineHeight: 0 }}>
                                                                    <b>Unblock</b>
                                                                </p>
                                                            </IconButton>
                                                        }
                                                    </div>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        className="pagination"
                        rowsPerPageOptions={[10, 25, 50, { label: "All", value: -1 }]}
                        component="div"
                        count={Members[displayMembers].list?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </Grid >
            <InviteTeamModal
                domain={user.company_domain}
                modal={isInviteUserModalOpen}
                dataOfInviteNewUser={dataOfInviteNewUser}
                setDataOfInviteNewUser={setDataOfInviteNewUser}
                setModal={setIsInviteUserModalOpen}
                isEmailVerified={isEmailVerified}
                getOrganizationMember={getOrganizationMember}
                getInvitedOrgainzationMembers={getInvitedOrgainzationMember}
            />
            <ChangeInvitedTeamModal
                modal={changeInvitedUserModal}
                setModal={setInvitedUserModal}
                getOrganizationMember={getOrganizationMember}
                changeUserRoleData={changeUserRoleData}
                setChangeUserRoleData={setChangeUserRoleData}
            />
            <RemoveInvitedUserModal
                modal={isRemoveInvitedUserModalOpen}
                setModal={setRemoveInvitedUserModalOpen}
                userRemoveData={userRemoveData}
                getOrganizationMember={getOrganizationMember}
                getInvitedOrgainzationMember={getInvitedOrgainzationMember}
            />
            <ReInviteModal
                domain={user.company_domain}
                modal={reInviteUserModal}
                setModal={setReInviteUserModal}
                dataOfInviteNewUser={reInviteTeamModalData}
                setDataOfInviteNewUser={setReInviteTeamModalData}
                getInvitedOrgainzationMembers={getOrganizationMember}
            />
        </div >
    )
}


export default ManageTeam;
