import React, { useState } from "react";
import Modal from 'react-modal';
import "../styles/modal.css";
import { ForgetPassword } from "../../api/user/saga";
import { Button, Grid, TextField } from "@mui/material";

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: "#ffff",
		width: "450px",
		height: "280px",
		padding: "0",
		zIndex: 9000000000000,
		position: "absolute"
	},
};

const ForgetPasswordModal = (props) => {
	const [email, setEmail] = useState(null);
	const [message, setMessage] = useState("");
	const [isError, setError] = useState(false);
	const { modal, setModal } = props;

	const validation = () => {

		if (email === null || !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
			return false;
		}
		return true;
	}
	const send = async () => {
		if (!validation()) {
			setError(true);
			setMessage("Enter valid email id. User doesn't exists!");
			return;
		}
		ForgetPassword({ email: email }).then((response) => {
			setError(false);
			setMessage("Reset link for your password successfully sent on email.");
			setTimeout(() => {
				setModal(!modal);
				setEmail(null);
				setMessage("");
				setError(false);
			}, 2000);
		}).catch((err) => {
			setError(true);
			setMessage(err.data.message);
		})
	}

	return (
		<Modal isOpen={modal} style={customStyles}>
			<div style={{ width: "100%", height: "40px", borderBottom: "1px solid #eee", padding: "10px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				<div>
					<b>Forgot Password</b>
				</div>
				<div>
					<Button onClick={() => setModal(!modal)}>X</Button>
				</div>
			</div>
			<div style={{ width: "100%", position: "relative", zIndex: 90000000, backgroundColor: "#ffff", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}>
				<div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
					<Grid item xs={12} style={{ padding: '4px 0 4px 0', width: "80%", margin: "0 auto", marginTop: "50px", marginBottom: "10px" }}>
						<TextField
							label="Enter your email address"
							style={{ width: '100%' }}
							id="outlined-basic"
							InputLabelProps={{ shrink: true }}
							InputProps={{ style: { borderRadius: '6px' } }}
							value={email}
							size="small"
							onChange={(e) => setEmail(e.target.value)}
							variant="outlined" />
					</Grid>
					{message !== "" && <Grid xs={12} style={{ width: "80%", margin: "0 auto", display: "flex", justifyContent: "center", alignItems: "flex-start", flexDirection: "column" }}>
						{
							isError ? <h4 style={{
								color: "red",
								textAlign: "right",
								borderRadius: "10px",
								width: "100%",
								fontSize: "10px"
							}}><b>{message}</b>
							</h4> :
								<h4 style={{
									color: "green",
									textAlign: "right",
									borderRadius: "10px",
									width: "100%",
									fontSize: "10px"
								}}><b>{message}</b>
								</h4>
						}
					</Grid>}
					<Grid xs={12} style={{ width: "80%", margin: "0 auto", transform: "translateY(20px)" }}>
						<Button style={{ backgroundColor: "#0869FB", color: "white", padding: "10px", textAlign: "right", float: "right", borderRadius: "8px", width: "100%" }}
							onClick={() => send()}
						>SEND</Button>
					</Grid>
				</div>
			</div>
		</Modal>
	)
}
export default ForgetPasswordModal;
