import { Checkbox, Grid, Typography } from "@mui/material";
import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import CircleChecked from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import IconButton from "@mui/material/IconButton";
import { useDispatch } from "react-redux";

const classes = {
    arrow: {
        width: "50%",
        lineHeight: "12px",
        position: "relative",
        background: "#0869FB",
        textAlign: "center",
        height: 18,
        fontSize: 10,
        marginLeft: -1,
        color: "#fff",
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 2,
        borderTopRightRadius: 4,
        borderTopRadius: 2
    },
    triangle: {
        position: "relative",
        textAlign: "center",
        lineHeight: 1,
        borderColor: "transparent #0869FB transparent transparent",
        borderStyle: "solid",
        borderWidth: "9px 10px 10px 0",
        height: 0,
        width: 0,
        borderTopRightRadius: 2,
        borderBottomRightRadius: 2,
    },
    gridItem: {
        padding: "0 !important"
    },
    overlay: {
        flex: 1,
        opacity: 1,
        backgroundColor: 'black',
    }
};

const label = { inputProps: { "aria-label": "Checkbox" } };

function CardComponent(props) {
    const { tabSelected, channel, authenticatedAccounts, user } = props;
    const dispatch = useDispatch();
    const isAuthenticated = !!authenticatedAccounts.find(type => type === channel.type);
    return (
        <Grid key={channel.type} style={{ cursor: "pointer" }} item xs={12} sm={tabSelected === 0 ? 6 : 6} md={tabSelected === 0 ? 4 : 4} lg={tabSelected === 0 ? 4 : 3} align="center">
            <Card style={{ height: 200, backgroundColor: channel.isSupported ? isAuthenticated ? "white" : "#EEEEEE80" : "#EEEEEE80" }}>
                <CardContent style={{ position: "relative", minHeight: 100 }}>
                    {/* <div
                        style={{ visibility: channel.isSupported ? "hidden" : "visible", width: "200%", height: "200%", backgroundColor: "grey", position: "absolute", top: -10, left: -10, opacity: 0.1, zIndex: 1 }}>
                    </div> */}
                    <Grid container spacing={2} alignItems="center" justifyContent="center">

                        {!channel.isSupported ?
                            <Grid item align="right" style={{ width: "80%", marginRight: "-40%" }}>
                                <div style={{ flexDirection: "row", display: "flex", justifyContent: "end", width: "100%", visibility: channel.isSupported ? "hidden" : "visible" }}>
                                    <div style={classes.triangle}></div>
                                    <div style={{ padding: "2px", ...classes.arrow }}>Coming soon</div>
                                </div>
                            </Grid> : null}
                        {channel.isSupported ? isAuthenticated ?
                            <Grid item xs={2} align="right">
                                <Checkbox
                                    style={{ color: "#12C8B2", position: "absolute", top: 0, right: 0, visibility: channel.isSupported ? isAuthenticated ? "visible" : "hidden" : "hidden", padding: "9px" }}
                                    icon={<CircleUnchecked />}
                                    checkedIcon={<CircleChecked />}
                                    {...label}
                                    checked={isAuthenticated}
                                />
                            </Grid> : null : null}

                        <Grid style={{ ...classes.gridItem, opacity: channel.isSupported ? 1 : 0.8 }} item xs={12} align="center">
                            <IconButton style={{ padding: 4 }} aria-label="ads">
                                <img src={channel.icon} style={{ height: 40, marginTop: channel.isSupported ? isAuthenticated ? "0px" : "0px" : "0px" }} alt={"ads"} />
                            </IconButton>
                        </Grid>
                        <Grid style={{ opacity: channel.isSupported ? 1 : 0.8 }} item xs={12} align="center">
                            <Typography style={{ fontSize: 16, fontWeight: 600 }}>
                                {channel.title}
                            </Typography>
                            <Typography style={{ fontSize: 14, fontWeight: 400, padding: "5px 20px" }}>
                                {channel.subtext}
                            </Typography>
                            <Button
                                onClick={() => dispatch({ ...channel.actions.connectAccount, payload: { redirect_url: "selectchannels" } })} size="small" variant="contained"
                                style={{ visibility: channel.isSupported ? isAuthenticated ? "hidden" : "visible" : "hidden", width: "auto", textAlign: "center", fontSize: 11, fontWeight: 400, textTransform: "none", backgroundColor: (user.role === "admin" || user.role === "superadmin") ? "#0769FB" : "rgb(178, 176, 176)", color: "white" }}
                                disabled={!(user.role === "admin" || user.role === "superadmin")}
                            >
                                Connect your account
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

const mapStateToProps = state => ({
    authenticatedAccounts: state.channel.authenticatedAccounts,
    user: state.user.user,
});

export default (connect(mapStateToProps)(CardComponent));
