import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import {
  LegendPosition,
  TextStyle,
  Palette,
  TablePalette,
  LegendStyle,
  CustomPalette,
} from "../Charts/ChartUtils";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import { ReactComponent as IconEdit } from "../../Assets/Icons/edit.svg";
import DoneIcon from "@mui/icons-material/Done";
import Scrollbars from "react-custom-scrollbars";

const ChartSettings = ({ editDrawerState }) => {
  const { chart, handleChartChange } = editDrawerState;
  const [legendPos, setLegendPos] = useState(chart.chartStyle.legendPosition);
  const [selectedPalette, setSelectedPalette] = useState(
    Array.isArray(chart.chartStyle.palette) ? 7 : chart.chartStyle.palette
  );

  const [formats, setFormats] = React.useState(
    chart.chartStyle.titleStyle.fontFormat
  );

  const [legendStyle, setLegendStyle] = React.useState(
    chart.chartStyle.legendStyle
  );

  const [titleStyle, setTitleStyle] = useState(chart.chartStyle.titleStyle);

  const [tableStyle, setTableStyle] = useState(chart.chartStyle.tableStyle);

  const [seriesStyle, setSeriesStyle] = useState(chart.chartStyle.seriesStyle);

  // const [chartStyle, setChartStyle] = useState({
  //   FontStyle: "",
  //   BackColor: "",
  //   BorderColor: "",
  // });

  const [customPalleteColors, setCustomColors] = useState(
    Array.isArray(chart.chartStyle.palette)
      ? chart.chartStyle.palette
      : CustomPalette(chart.type)
  );
  const [updatePallette, setUpdatePallete] = useState(false);

  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);
  };

  useEffect(() => {
    handleTitleChange("fontFormat", formats);
  }, [formats]);

  useEffect(() => {
    handleChartChange("chartStyle", { titleStyle: titleStyle });
  }, [titleStyle]);

  useEffect(() => {
    handleChartChange("chartStyle", { legendStyle: legendStyle });
  }, [legendStyle]);

  useEffect(() => {
    handleChartChange("chartStyle", { tableStyle: tableStyle });
  }, [tableStyle]);

  useEffect(() => {
    handleChartChange("chartStyle", { seriesStyle: seriesStyle });
  }, [seriesStyle]);

  const handleTitleChange = (property, value) => {
    setTitleStyle({ ...titleStyle, [property]: value });
  };

  const handleLegendStyleChange = (property, value) => {
    setLegendStyle({ ...legendStyle, [property]: value });
  };

  const handleTableChange = (setting, property, value) => {
    setTableStyle({
      ...tableStyle,
      [setting]: { ...tableStyle[setting], [property]: value },
    });
  };

  const handleSeriesChange = (property, value) => {
    setSeriesStyle({ ...seriesStyle, [property]: value });
  };

  const toShowLegendStyle = (type) => {
    return type !== "TABLE" && type !== "TEXT";  //type !== "DONUT" && type !== "FUNNEL" 
  };
  const toShowTitleStyle = (type) => {
    return type !== "KPI";
  };
  const toShowSeriesStyle = (type) => {
    return (
      type === "LINE" ||
      type === "BAR" || type === "BAR3D" ||
      type === "LINEBAR" ||
      type === "STACKBAR" || type === "STACKBAR3D" ||
      type === "AREA" ||
      type === "WATERFALL"
    );
  };

  return (
    <Scrollbars
      autoHide
      style={{
        height: "calc(100vh - 300px)",
      }}
    >
      {/* <Accordion disableGutters sx={{ boxShadow: "none" }}>
        <AccordionSummary
          sx={{ maxHeight: "30px" }}
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{fontFamily: 'Inter', fontSize: '14px', fontWeight: '500', color: '#000000'}}>Chart Style</Typography
        </AccordionSummary>
        <AccordionDetails>
          <FormControl
            sx={{ width: "100%", justifyContent: "center" }}
            size="small"
          >

            <Grid container>
              <Grid item xs={6}>
                <h4 className="inter bold" >
                  Background Color
                </Typography
                <Select
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  style={{
                    width: "50%",
                    backgroundColor: chartStyle.BackColor,
                  }}
                  variant="outlined"
                  onChange={(e) => {
                    setChartStyle({ ...chartStyle, BackColor: e.target.value });
                  }}
                >
                  {Colors.map((pos, index) => (
                    <MenuItem
                      key={index}
                      value={pos}
                      style={{ height: "30px", backgroundColor: pos }}
                    ></MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6}>
                <h4 className="inter bold" >
                  Border Color
                </Typography
                <Select
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  style={{
                    width: "50%",
                    backgroundColor: chartStyle.BorderColor,
                  }}
                  variant="outlined"
                  onChange={(e) => {
                    setChartStyle({
                      ...chartStyle,
                      BorderColor: e.target.value,
                    });
                  }}
                >
                  {Colors.map((pos, index) => (
                    <MenuItem
                      key={index}
                      value={pos}
                      style={{ height: "30px", backgroundColor: pos }}
                    ></MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </FormControl>
        </AccordionDetails>
      </Accordion> */}

      {toShowTitleStyle(chart.type) && (
        <Accordion
          disableGutters
          sx={{
            boxShadow: "none",
            // "& .Mui-expanded": { backgroundColor: "#F8F9FD" },,
            marginBottom: "0px",
            width: "100%",
            '&:before': {
              display: 'none',
            },
          }}
          elevation={0}
        >
          <AccordionSummary
            sx={{ maxHeight: "30px", width: "100%", padding: "0px" }}
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "500",
                color: "#000000",
              }}
            >
              Chart {chart.type === "TEXT" ? "Text" : "Title"}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl
              sx={{ width: "100%", justifyContent: "center" }}
              size="small"
            >
              <Grid container>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000",
                      marginBottom: "8px",
                    }}
                  >
                    Font Style
                  </Typography>
                  <Select
                    id="demo-simple-select"
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #EAEAEC",
                      },
                      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid #EAEAEC",
                      },
                      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid #EAEAEC",
                      },
                    }}
                    inputProps={{
                      sx: {
                        display: "flex",
                        alignItems: "center",
                        width: "220px",
                      },
                    }}
                    MenuProps={{
                      sx: {
                        display: "flex",
                        marginTop: "8px",
                        borderRadius: "12px",
                        "& .Mui-selected": {
                          borderRadius: "6px",
                        },
                      },
                      MenuListProps: {
                        sx: {
                          padding: "10px !important",
                          borderRadius: "12px",
                        },
                      },
                    }}
                    style={{
                      width: "90%",
                      borderRadius: "6px",
                      border: "none",
                    }}
                    variant="outlined"
                    value={titleStyle.font}
                    onChange={(e) => {
                      handleTitleChange("font", e.target.value);
                    }}
                  >
                    {TextStyle.FontStyle.map((pos, index) => (
                      <MenuItem key={index} value={pos}>
                        <div
                          style={{
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: "400",
                            lineHeight: "20px",
                          }}
                        >
                          {pos}
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000",
                      marginBottom: "8px",
                    }}
                  >
                    Font Size
                  </Typography>
                  <Select
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #EAEAEC",
                      },
                      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid #EAEAEC",
                      },
                      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid #EAEAEC",
                      },
                    }}
                    inputProps={{
                      sx: {
                        display: "flex",
                        alignItems: "center",
                        width: "220px",
                      },
                    }}
                    MenuProps={{
                      sx: {
                        display: "flex",
                        marginTop: "8px",
                        borderRadius: "12px",
                        "& .Mui-selected": {
                          borderRadius: "6px",
                        },
                      },
                      MenuListProps: {
                        sx: {
                          padding: "10px !important",
                          borderRadius: "12px",
                        },
                      },
                    }}
                    style={{
                      width: "90%",
                      borderRadius: "6px",
                    }}
                    variant="outlined"
                    value={titleStyle.fontSize}
                    onChange={(e) => {
                      handleTitleChange("fontSize", e.target.value);
                    }}
                  >
                    {TextStyle.FontSize.map((pos, index) => (
                      <MenuItem key={index} value={pos}>
                        <div
                          style={{
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: "400",
                            lineHeight: "20px",
                          }}
                        >
                          {pos}
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>

              <Grid container style={{}}>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000",
                      marginTop: "25px",
                      marginBottom: "8px",
                    }}
                  >
                    Font Format
                  </Typography>
                  <ToggleButtonGroup
                    value={formats}
                    onChange={handleFormat}
                    aria-label="text formatting"
                  >
                    <ToggleButton value="bold" aria-label="bold">
                      <FormatBoldIcon />
                    </ToggleButton>
                    <ToggleButton value="italic" aria-label="italic">
                      <FormatItalicIcon />
                    </ToggleButton>
                    <ToggleButton value="underline" aria-label="underlined">
                      <FormatUnderlinedIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000",
                      marginTop: "25px",
                      marginBottom: "8px",
                    }}
                  >
                    Font Color
                  </Typography>
                  <input
                    style={{ width: "90%", height: "45px" }}
                    type="color"
                    value={titleStyle.color}
                    onChange={(e) => {
                      handleTitleChange("color", e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      )}

      {toShowSeriesStyle(chart.type) && (
        <Accordion
          disableGutters
          sx={{
            boxShadow: "none",
            // "& .Mui-expanded": { backgroundColor: "#F8F9FD" },,
            marginBottom: "0px",
            width: "100%",
            '&:before': {
              display: 'none',
            },
          }}
          elevation={0}
        >
          <AccordionSummary
            sx={{ maxHeight: "30px", width: "100%", padding: "0px" }}
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "500",
                color: "#000000"
              }}
            >
              Chart Series
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl
              sx={{ width: "100%", justifyContent: "center" }}
              size="small"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={seriesStyle?.dataPoints}
                    onChange={(e) => {
                      handleSeriesChange("dataPoints", e.target.checked);
                    }}
                  />
                }
                label="Enable Data Points"
              />
            </FormControl>
          </AccordionDetails>
        </Accordion>
      )}

      {toShowLegendStyle(chart.type) && (
        <Accordion
          disableGutters
          sx={{
            boxShadow: "none",
            // "& .Mui-expanded": { backgroundColor: "#F8F9FD" },,
            marginBottom: "0px",
            width: "100%",
            '&:before': {
              display: 'none',
            },
          }}
          elevation={0}
        >
          <AccordionSummary
            sx={{ maxHeight: "30px", width: "100%", padding: "0px" }}
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "500",
                color: "#000000",
              }}
            >
              {chart.type === "WORDCLOUD" ? "Word Style" : "Legend Style"}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl
              sx={{ width: "100%", justifyContent: "center" }}
              size="small"
            >
              {chart.type !== "KPI" &&
                chart.type !== "WORDCLOUD" &&
                chart.type !== "GEO" && (
                  <>
                    <Typography
                      style={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#000000",
                        marginTop: "8px",
                      }}
                    >
                      Position:
                    </Typography>
                    <Select
                      sx={{
                        width: "95%",
                        marginTop: "8px",
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "25px",
                      }}
                      value={legendPos}
                      variant="outlined"
                      onChange={(e) => {
                        handleChartChange("chartStyle", {
                          legendPosition: e.target.value,
                        });
                        setLegendPos(e.target.value);
                      }}
                    >
                      {LegendPosition.map((pos, index) => (
                        <MenuItem key={index} value={pos}>
                          {pos}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}

              <Grid container style={{}}>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000",
                      marginBottom: "8px",
                    }}
                  >
                    Font Style
                  </Typography>
                  <Select
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #EAEAEC",
                      },
                      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid #EAEAEC",
                      },
                      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid #EAEAEC",
                      },
                    }}
                    inputProps={{
                      sx: {
                        display: "flex",
                        alignItems: "center",
                        width: "220px",
                      },
                    }}
                    MenuProps={{
                      sx: {
                        display: "flex",
                        marginTop: "8px",
                        borderRadius: "12px",
                        "& .Mui-selected": {
                          borderRadius: "6px",
                        },
                      },
                      MenuListProps: {
                        sx: {
                          padding: "10px !important",
                          borderRadius: "12px",
                        },
                      },
                    }}
                    style={{ width: "90%" }}
                    variant="outlined"
                    value={legendStyle.fontStyle}
                    onChange={(e) => {
                      handleLegendStyleChange("fontStyle", e.target.value);
                    }}
                  >
                    {LegendStyle.FontStyle.map((pos, index) => (
                      <MenuItem key={index} value={pos}>
                        <div
                          style={{
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: "400",
                            lineHeight: "20px",
                          }}
                        >
                          {pos}
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000",
                      marginBottom: "8px",
                    }}
                  >
                    Font Size
                  </Typography>
                  <Select
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #EAEAEC",
                      },
                      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid #EAEAEC",
                      },
                      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid #EAEAEC",
                      },
                    }}
                    inputProps={{
                      sx: {
                        display: "flex",
                        alignItems: "center",
                        width: "220px",
                      },
                    }}
                    MenuProps={{
                      sx: {
                        display: "flex",
                        marginTop: "8px",
                        borderRadius: "12px",
                        "& .Mui-selected": {
                          borderRadius: "6px",
                        },
                      },
                      MenuListProps: {
                        sx: {
                          padding: "10px !important",
                          borderRadius: "12px",
                        },
                      },
                    }}
                    style={{ width: "90%" }}
                    variant="outlined"
                    value={legendStyle.fontSize}
                    onChange={(e) => {
                      handleLegendStyleChange("fontSize", e.target.value);
                    }}
                  >
                    {LegendStyle.FontSize.map((pos, index) => (
                      <MenuItem key={index} value={pos}>
                        <div
                          style={{
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: "400",
                            lineHeight: "20px",
                          }}
                        >
                          {pos}
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      )}

      {/* table styles */}
      {chart.type === "TABLE" && (
        <>
          <Accordion
            disableGutters
            sx={{
              boxShadow: "none",
              // "& .Mui-expanded": { backgroundColor: "#F8F9FD" },,
              width: "100%",
              '&:before': {
                display: 'none',
              },
            }}
            elevation={0}
          >
            <AccordionSummary
              sx={{ maxHeight: "30px", width: "100%", padding: "0px" }}
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#000000",
                }}
              >
                Table Header Style
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl
                sx={{ width: "100%", justifyContent: "center" }}
                size="small"
              >
                <Grid container>
                  <Grid item xs={6}>
                    <Typography
                      style={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#000000",
                        marginBottom: "8px",
                      }}
                    >
                      Font Style:
                    </Typography>
                    <Select
                      style={{ width: "90%" }}
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #EAEAEC",
                        },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          border: "1px solid #EAEAEC",
                        },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          border: "1px solid #EAEAEC",
                        },
                      }}
                      inputProps={{
                        sx: {
                          display: "flex",
                          alignItems: "center",
                          width: "220px",
                        },
                      }}
                      MenuProps={{
                        sx: {
                          display: "flex",
                          marginTop: "8px",
                          borderRadius: "12px",
                          "& .Mui-selected": {
                            borderRadius: "6px",
                          },
                        },
                        MenuListProps: {
                          sx: {
                            padding: "10px !important",
                            borderRadius: "12px",
                          },
                        },
                      }}
                      value={tableStyle.tableHeader.font}
                      variant="outlined"
                      onChange={(e) => {
                        handleTableChange(
                          "tableHeader",
                          "font",
                          e.target.value
                        );
                      }}
                    >
                      {TextStyle.FontStyle.map((pos, index) => (
                        <MenuItem key={index} value={pos}>
                          <div
                            style={{
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontWeight: "400",
                              lineHeight: "20px",
                            }}
                          >
                            {pos}
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      style={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#000000",
                        marginBottom: "8px",
                      }}
                    >
                      Font Size:
                    </Typography>
                    <Select
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #EAEAEC",
                        },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          border: "1px solid #EAEAEC",
                        },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          border: "1px solid #EAEAEC",
                        },
                        marginBottom: "25px",
                      }}
                      inputProps={{
                        sx: {
                          display: "flex",
                          alignItems: "center",
                          width: "220px",
                        },
                      }}
                      MenuProps={{
                        sx: {
                          display: "flex",
                          marginTop: "8px",
                          borderRadius: "12px",
                          "& .Mui-selected": {
                            borderRadius: "6px",
                          },
                        },
                        MenuListProps: {
                          sx: {
                            padding: "10px !important",
                            borderRadius: "12px",
                          },
                        },
                      }}
                      style={{ width: "90%" }}
                      variant="outlined"
                      value={tableStyle.tableHeader.fontSize}
                      onChange={(e) => {
                        handleTableChange(
                          "tableHeader",
                          "fontSize",
                          e.target.value
                        );
                      }}
                    >
                      {TextStyle.FontSize.map((pos, index) => (
                        <MenuItem key={index} value={pos}>
                          <div
                            style={{
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontWeight: "400",
                              lineHeight: "20px",
                            }}
                          >
                            {pos}
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </FormControl>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            sx={{
              boxShadow: "none",
              // "& .Mui-expanded": { backgroundColor: "#F8F9FD" },,
              width: "100%",
              '&:before': {
                display: 'none',
              },
            }}
            elevation={0}
          >
            <AccordionSummary
              sx={{ maxHeight: "30px", width: "100%", padding: "0px" }}
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#000000",
                  marginBottom: "8px",
                }}
              >
                Table Content Style
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl
                sx={{ width: "100%", justifyContent: "center" }}
                size="small"
              >
                <Grid container>
                  <Grid item xs={6}>
                    <Typography
                      style={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#000000",
                        marginBottom: "8px",
                      }}
                    >
                      Font Style:
                    </Typography>
                    <Select
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #EAEAEC",
                        },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          border: "1px solid #EAEAEC",
                        },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          border: "1px solid #EAEAEC",
                        },
                        marginBottom: "25px",
                      }}
                      inputProps={{
                        sx: {
                          display: "flex",
                          alignItems: "center",
                          width: "220px",
                        },
                      }}
                      MenuProps={{
                        sx: {
                          display: "flex",
                          marginTop: "8px",
                          borderRadius: "12px",
                          "& .Mui-selected": {
                            borderRadius: "6px",
                          },
                        },
                        MenuListProps: {
                          sx: {
                            padding: "10px !important",
                            borderRadius: "12px",
                          },
                        },
                      }}
                      style={{
                        width: "90%",
                        borderRadius: "6px",
                        border: "none",
                      }}
                      value={tableStyle.tableContent.font}
                      variant="outlined"
                      onChange={(e) => {
                        handleTableChange(
                          "tableContent",
                          "font",
                          e.target.value
                        );
                      }}
                    >
                      {TextStyle.FontStyle.map((pos, index) => (
                        <MenuItem key={index} value={pos}>
                          <div
                            style={{
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontWeight: "400",
                              lineHeight: "20px",
                            }}
                          >
                            {pos}
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      style={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#000000",
                        marginBottom: "8px",
                      }}
                    >
                      Font Size:
                    </Typography>
                    <Select
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #EAEAEC",
                        },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          border: "1px solid #EAEAEC",
                        },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          border: "1px solid #EAEAEC",
                        },
                      }}
                      inputProps={{
                        sx: {
                          display: "flex",
                          alignItems: "center",
                          width: "220px",
                        },
                      }}
                      MenuProps={{
                        sx: {
                          display: "flex",
                          marginTop: "8px",
                          borderRadius: "12px",
                          "& .Mui-selected": {
                            borderRadius: "6px",
                          },
                        },
                        MenuListProps: {
                          sx: {
                            padding: "10px !important",
                            borderRadius: "12px",
                          },
                        },
                      }}
                      style={{ width: "90%" }}
                      variant="outlined"
                      value={tableStyle.tableContent.fontSize}
                      onChange={(e) => {
                        handleTableChange(
                          "tableContent",
                          "fontSize",
                          e.target.value
                        );
                      }}
                    >
                      {TextStyle.FontSize.map((pos, index) => (
                        <MenuItem key={index} value={pos}>
                          <div
                            style={{
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontWeight: "400",
                              lineHeight: "20px",
                            }}
                          >
                            {pos}
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </FormControl>
            </AccordionDetails>
          </Accordion>
        </>
      )}

      {chart.type !== "TEXT" && <Accordion
        disableGutters
        sx={{
          boxShadow: "none",
          // "& .Mui-expanded": { backgroundColor: "#F8F9FD" },,
          width: "100%",
          '&:before': {
            display: 'none',
          },
        }}
        defaultExpanded
        elevation={0}
      >
        <AccordionSummary
          sx={{ maxHeight: "30px", width: "100%", padding: "0px" }}
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            style={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "500",
              color: "#000000",
            }}
          >
            Chart Color Palette
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {((chart.type === "HEATMAP" || chart.type === "TABLE") ? TablePalette : Palette).map(
              (colors, index) =>
                index === 0 ? (
                  <Grid item xs={12}>
                    <Grid item xs={12} style={{ display: "flex" }}>
                      <span
                        className="inter"
                        style={{
                          color: "#858585",
                          fontSize: 12,
                          float: "right",
                          paddingBottom: "5px",
                          paddingRight: "2px",
                        }}
                      >
                        Default Palette
                      </span>
                    </Grid>

                    <Grid item xs={6}>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          borderRadius: "8px",
                          overflow: "hidden",
                          marginBottom: "8px",
                          cursor: "pointer",
                          border:
                            index === selectedPalette ? "1px solid blue" : "",
                          opacity: index === selectedPalette ? "1" : "0.6",
                        }}
                        onClick={() => {
                          handleChartChange("chartStyle", { palette: index });
                          setSelectedPalette(index);
                        }}
                      >
                        {colors.map((color) => (
                          <div
                            style={{
                              height: "40px",
                              width: "20%",
                              backgroundColor: color,
                            }}
                          ></div>
                        ))}
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={6}>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        borderRadius: "8px",
                        overflow: "hidden",
                        marginBottom: "8px",
                        cursor: "pointer",
                        border:
                          index === selectedPalette ? "1px solid blue" : "",
                        opacity: index === selectedPalette ? "1" : "0.6",
                      }}
                      onClick={() => {
                        handleChartChange("chartStyle", {
                          palette: index,
                        });
                        setSelectedPalette(index);
                      }}
                    >
                      {colors.map((color) => (
                        <div
                          style={{
                            height: "40px",
                            width: "20%",
                            backgroundColor: color,
                          }}
                        ></div>
                      ))}
                    </div>
                  </Grid>
                )
            )}
          </Grid>
          <Grid container style={{ marginTop: "10px" }}>
            <Grid item xs={12} style={{ display: "flex" }}>
              <span
                className="inter"
                style={{
                  color: "#858585",
                  fontSize: 12,
                  float: "right",
                  paddingBottom: "5px",
                  paddingRight: "2px",
                }}
              >
                Custom Palette
              </span>
            </Grid>

            <Grid item xs={6}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  borderRadius: "8px",
                  overflow: "hidden",
                  marginBottom: "8px",
                  cursor: "pointer",
                  border: 7 === selectedPalette ? "1px solid blue" : "",
                  opacity: 7 === selectedPalette ? "1" : "0.6",
                }}
                onClick={() => {
                  handleChartChange("chartStyle", {
                    palette: customPalleteColors,
                  });
                  setSelectedPalette(7);
                }}
              >
                {customPalleteColors.map((color, index) => (
                  <div
                    style={{
                      height: "40px",
                      width: "20%",
                      backgroundColor: color,
                    }}
                  >
                    {updatePallette && (
                      <input
                        style={{
                          width: "100%",
                          height: "100%",
                          padding: 0,
                          border: 5,
                        }}
                        type="color"
                        value={customPalleteColors[index]}
                        onChange={(e) => {
                          customPalleteColors[index] = e.target.value;
                          setCustomColors([...customPalleteColors]);
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                height: "40px",
              }}
            >
              {updatePallette ? (
                <IconButton style={{ marginLeft: "10px" }} aria-label="ads">
                  <DoneIcon
                    onClick={() => {
                      setUpdatePallete(false);
                      handleChartChange("chartStyle", {
                        palette: customPalleteColors,
                      });
                    }}
                    style={{ color: "#1CC9AA", height: "25px", width: "25px" }}
                  />
                </IconButton>
              ) : (
                <IconButton style={{ marginLeft: "10px" }} aria-label="ads">
                  <IconEdit
                    onClick={() => setUpdatePallete(true)}
                    style={{ height: "25px", width: "25px" }}
                  />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>}
    </Scrollbars>
  );
};

export default ChartSettings;