import { Grid, Tab } from "../../models/report";
import { Chart, Metric, Filter } from "../../models/chart";
import { channelType } from "./transformer";

// tables
const SALES = { id: "sales", name: "Sales" };

// metrics
const TOTAL_SALES = { id: "total_sales", name: "Total Sales" }
const GROSS_SALES = { id: "gross_sales", name: "Gross Sales" };
const NET_SALES = { id: "net_sales", name: "Net Sales" };
const ORDERS = { id: "orders", name: "Orders" };
const ITEM_QUANTITY = { id: "ordered_item_quantity", name: "Ordered Item Quantity" };
const RETURN_QUANTITY = { id: "returned_item_quantity", name: "Returned Item Quantity" };
const DISCOUNTS = { id: "discounts", name: "Discounts" };

// dimensions

const overallCharts = [
  new Chart(channelType, "Sales Over Time", "LINE", SALES,
    { x: 0, y: 0, w: 6, h: 2, static: false },
    [new Metric(TOTAL_SALES), new Metric(GROSS_SALES)],
  ),
  new Chart(channelType, "Sales Structure", "STACKBAR", SALES,
    { x: 6, y: 0, w: 6, h: 2, static: false },
    [
      new Metric(TOTAL_SALES),
      new Metric({ id: "returns", name: "Returns" }),
      new Metric(DISCOUNTS),
    ],
  ),
  new Chart(channelType, "No. of orders", "KPI", SALES,
    { x: 0, y: 2, w: 3, h: 1, static: false },
    [new Metric(ORDERS)],
  ),
  new Chart(channelType, "Total Sales", "KPI", SALES,
    { x: 3, y: 2, w: 3, h: 1, static: false },
    [new Metric(TOTAL_SALES)],
  ),
  new Chart(channelType, "Gross Sales", "KPI", SALES,
    { x: 6, y: 2, w: 3, h: 1, static: false },
    [new Metric(GROSS_SALES)],
  ),
  new Chart(channelType, "Net Sales", "KPI", SALES,
    { x: 9, y: 2, w: 3, h: 1, static: false },
    [new Metric(NET_SALES)],
  ),
  new Chart(channelType, "Discounts", "KPI", SALES,
    { x: 0, y: 3, w: 6, h: 1, static: false },
    [new Metric(DISCOUNTS)],
  ),
  new Chart(channelType, "Refunds", "KPI", SALES,
    { x: 6, y: 3, w: 6, h: 1, static: false },
    [new Metric(ORDERS, new Filter("Refunds", null, [
      {
        boolOperator: null,
        filter: {
          id: "financial_status",
          name: "Financial Status",
          "type": "DIMENSION",
          "group": "Order",
          "format": "STRING",
          "dataType": "STRING"
        },
        operator: { label: "Equal", type: "=", dataType: ["INTEGER", "STRING"] },
        value: "Refunded",
      },
    ]))],
  ),
  new Chart(channelType, "Users", "TABLE", SALES,
    { x: 0, y: 4, w: 6, h: 2, static: false },
    [
      new Metric(ORDERS),
      new Metric(TOTAL_SALES),
      new Metric(GROSS_SALES),
      new Metric(ITEM_QUANTITY),
      new Metric(DISCOUNTS),
    ],
    null,
    [{ id: "customer_type", name: "Customer Type" }]
  ),
  new Chart(channelType, "Quantity Breakdown", "FUNNEL", SALES,
    { x: 6, y: 4, w: 6, h: 2, static: false },
    [
      new Metric(ITEM_QUANTITY),
      new Metric(RETURN_QUANTITY),
      new Metric({ id: "net_quantity", name: "Net Quantity" }),
    ],
  ),
  new Chart(channelType, "Products", "TABLE", SALES,
    { x: 0, y: 6, w: 6, h: 2, static: false },
    [
      new Metric(ORDERS),
      new Metric(TOTAL_SALES),
      new Metric(GROSS_SALES),
      new Metric(ITEM_QUANTITY),
      new Metric(RETURN_QUANTITY),
    ],
    null,
    [{ id: "product_title", name: "Product Title" }, { id: "variant_title", name: "Variant Title" }]
  ),
  new Chart(channelType, "Refferer Quants", "TABLE", SALES,
    { x: 6, y: 6, w: 6, h: 2, static: false },
    [
      new Metric(ORDERS),
      new Metric(TOTAL_SALES),
      new Metric(GROSS_SALES),
      new Metric(ITEM_QUANTITY),
      new Metric(RETURN_QUANTITY),
    ],
    null,
    [{ id: "referrer_source", name: "Referrer Source" }]
  ),
  new Chart(channelType, "Country Distribution", "TABLE", SALES,
    { x: 0, y: 8, w: 6, h: 2, static: false },
    [
      new Metric(ORDERS),
      new Metric(ITEM_QUANTITY),
      new Metric(RETURN_QUANTITY),
      new Metric(TOTAL_SALES),
    ],
    null,
    [{ id: "shipping_country", name: "Shipping Country" }]
  ),
  new Chart(channelType, "City Distribution", "TABLE", SALES,
    { x: 6, y: 8, w: 6, h: 2, static: false },
    [
      new Metric(ORDERS),
      new Metric(ITEM_QUANTITY),
      new Metric(RETURN_QUANTITY),
      new Metric(TOTAL_SALES),
    ],
    null,
    [{ id: "shipping_city", name: "Shipping City" }]
  ),
];

const overallGrid = [
  new Grid(null, null, null, overallCharts),
];


export default [
  new Tab("Overall", 0, overallGrid),
];
