import { Input } from "@mui/material";
import React from "react";
import { ReactComponent as EmailIcon } from "../../Assets/Icons/email_icon.svg";

class ChipEmail extends React.Component {
    state = {
        items: this.props.emails,
        value: "",
        error: null
    };

    handleKeyDown = evt => {
        if (["Enter", "Tab", ",", " "].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.value.trim();

            if (value && this.isValid(value)) {
                this.setState({
                    items: [...this.state.items, this.state.value],
                    value: ""
                });
            }
        }
    };

    handleChange = evt => {
        this.setState({
            value: evt.target.value,
            error: null
        });
    };

    handleDelete = item => {
        this.setState({
            items: this.state.items.filter(i => i !== item)
        });
    };

    handlePaste = evt => {
        evt.preventDefault();

        var paste = evt.clipboardData.getData("text");
        // var emails = paste.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);

        // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

        // if (emails) {
        //     var toBeAdded = emails.filter(email => !this.isInList(email));

        //     this.setState({
        //         items: [...this.state.items, ...toBeAdded]
        //     });
        // }

        this.setState({
            value: paste,
            error: null
        })
    };

    isValid(email) {
        let error = null;

        if (this.isInList(email)) {
            error = `${email} has already been added.`;
        }

        if (!this.isEmail(email)) {
            error = `${email} is not a valid email address.`;
        }

        if (error) {
            this.setState({ error });

            return false;
        }

        return true;
    }

    isInList(email) {
        return this.state.items.includes(email);
    }

    isEmail(email) {
        // return /^[^\s@]+@[^\s@]+\.[^\s@.]+$/.test(email);
        return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.items !== prevState.items) {
            this.props.setEmails(this.state.items);
        }
    }

    render() {
        return (
            <>


                <Input
                    sx={{
                        fontFamily: "Inter", fontSize: "14px", fontWeight: "400"
                    }}
                    disableUnderline
                    className="email-input"
                    value={this.state.value}
                    placeholder="Add Email"
                    startAdornment={<EmailIcon style={{ marginRight: "10px" }} />}
                    onKeyDown={this.handleKeyDown}
                    onChange={this.handleChange}
                    onPaste={this.handlePaste}
                />
                <div style={{ marginTop: "12px", maxHeight: "100px", overflow: "auto" }}>

                    {this.state.items.map(item => (

                        <div className="tag-item" key={item}>
                            {item}
                            <button
                                type="button"
                                className="button"
                                onClick={() => this.handleDelete(item)}
                            >
                                &times;
                            </button>
                        </div>

                    ))}
                </div>


                {this.state.error && <p className="error">{this.state.error}</p>}
            </>
        );
    }
}


export default ChipEmail;
