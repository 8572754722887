import { Grid, Tab } from "../../models/report";
import { Chart, Metric, Filter } from "../../models/chart";
import { channelType } from "./transformer";

// metrics
const USERS = { id: "ga:users", name: "Users" };
const NEW_USERS = { id: "ga:newUsers", name: "New Users" };
const SESSIONS = { id: "ga:sessions", name: "Sessions" };
const GOAL_COMPLETIONS = { id: "ga:goalCompletionsAll", name: "Goal Completions" };
const GOAL_CONVERSIONS = { id: "ga:goalConversionRateAll", name: "Goal Conversion Rate" };
const BOUNCE_RATE = { id: "ga:bounceRate", name: "Bounce Rate" };
const SESSION_DURATION = { id: "ga:sessionDuration", name: "Session Duration" };
const AVG_SESSION_DURATION = { id: "ga:avgSessionDuration", name: "Avg. Session Duration" };
const AVG_TIME_ON_PAGE = { id: "ga:avgTimeOnPage", name: "Avg. Time On Page" };
const PAGE_VIEWS = { id: "ga:pageviews", name: "Pageviews" };
const PAGE_VIEWS_PER_SESSION = { id: "ga:pageviewsPerSession", name: "Pages / Session" };
const UNIQUE_PAGE_VIEWS = { id: "ga:uniquePageviews", name: "Unique Pageviews" };
const EXIT_RATE = { id: "ga:exitRate", name: "Exit Rate" };
const TRANSACTIONS = { id: "ga:transactions", name: "Transactions" };
const TRANSACTION_REVENUE = { id: "ga:transactionRevenue", name: "Revenue" };
const TRANSACTIONS_PER_SESSION = { id: "ga:transactionsPerSession", name: "Ecommerce Conversion Rate" };

// dimensions
const DATE = { id: "ga:date", name: "Date" };
const SOURCE_MEDIUM = { id: "ga:sourceMedium", name: "Source Medium" };
const CAMPAIGN = { id: "ga:campaign", name: "Campaign" };
const CITY = { id: "ga:city", name: "City" };
const COUNTRY = { id: "ga:country", name: "Country" };
const DEVICE_CATEGORY = { id: "ga:deviceCategory", name: "Device Category" };

// segments
const ORGANIC_TRAFFIC = { id: "gaid::-5", name: "Organic Traffic" };
const PAID_TRAFFIC = { id: "gaid::-4", name: "Paid Traffic" };
const DIRECT_TRAFFIC = { id: "gaid::-7", name: "Direct Traffic" };
const REFERRAL_TRAFFIC = { id: "gaid::-8", name: "Referral Traffic" };
const NEW_USERS_SEGMENT = { id: "gaid::-2", name: "New Users" };
const RETURNING_USERS_SEGMENT = { id: "gaid::-3", name: "Returning Users" };


const overallAcquisitionCharts = [
  new Chart(channelType, "Users vs. New Users", "LINE", null,
    { x: 0, y: 0, w: 6, h: 2, static: false },
    [new Metric(USERS), new Metric(NEW_USERS)],
  ),
  new Chart(channelType, "Conversions", "BAR", null,
    { x: 6, y: 0, w: 6, h: 2, static: false },
    [new Metric(GOAL_COMPLETIONS)],
  ),
  new Chart(channelType, "Acquistion Social Channel", "DONUT", null,
    { x: 0, y: 2, w: 6, h: 2, static: false },
    [
      new Metric(USERS, new Filter(ORGANIC_TRAFFIC.name, ORGANIC_TRAFFIC)),
      new Metric(USERS, new Filter(PAID_TRAFFIC.name, PAID_TRAFFIC)),
      new Metric(USERS, new Filter(DIRECT_TRAFFIC.name, DIRECT_TRAFFIC)),
      new Metric(USERS, new Filter("Social Traffic", null, [
        {
          boolOperator: null,
          filter: {
            id: "ga:channelGrouping",
            name: "Default Channel Grouping",
            dataType: "STRING",
            group: "Channel Grouping",
            type: "DIMENSION",
          },
          operator: {
            type: "EXACT",
            label: "Exactly matches",
            dataType: "STRING",
          },
          value: "Paid Social",
        },
      ])),
    ],
  ),
  new Chart(channelType, "Sessions", "KPI", null,
    { x: 6, y: 2, w: 3, h: 1, static: false },
    [new Metric(SESSIONS)],
  ),
  new Chart(channelType, "Users", "KPI", null,
    { x: 9, y: 2, w: 3, h: 1, static: false },
    [new Metric(USERS)],
  ),
  new Chart(channelType, "Bounce Rate", "KPI", null,
    { x: 6, y: 3, w: 3, h: 1, static: false },
    [new Metric(BOUNCE_RATE)],
  ),
  new Chart(channelType, "Goal Completions", "KPI", null,
    { x: 9, y: 3, w: 3, h: 1, static: false },
    [new Metric(GOAL_COMPLETIONS)],
  ),
];

const overallBehaviourCharts = [
  new Chart(channelType, "Page View vs Unique Views", "LINE", null,
    { x: 0, y: 0, w: 6, h: 2, static: false },
    [new Metric(PAGE_VIEWS), new Metric(UNIQUE_PAGE_VIEWS)],
  ),
  new Chart(channelType, "Bounce Rate Vs Exit Rate", "LINE", null,
    { x: 6, y: 0, w: 6, h: 2, static: false },
    [new Metric(BOUNCE_RATE), new Metric(EXIT_RATE)],
  ),
  new Chart(channelType, "Avg Time on page", "KPI", null,
    { x: 0, y: 2, w: 3, h: 1, static: false },
    [new Metric(AVG_TIME_ON_PAGE)],
  ),
  new Chart(channelType, "Exit Rate", "KPI", null,
    { x: 3, y: 2, w: 3, h: 1, static: false },
    [new Metric(EXIT_RATE)],
  ),
  new Chart(channelType, "Page Views per sessions", "KPI", null,
    { x: 6, y: 2, w: 3, h: 1, static: false },
    [new Metric(PAGE_VIEWS_PER_SESSION)],
  ),
  new Chart(channelType, "Bounce Rate", "KPI", null,
    { x: 9, y: 2, w: 3, h: 1, static: false },
    [new Metric(BOUNCE_RATE)],
  ),
];

const overallAudienceCharts = [
  new Chart(channelType, "Country Breakdown", "TABLE", null,
    { x: 0, y: 0, w: 6, h: 3, static: false },
    [
      new Metric(USERS),
      new Metric(NEW_USERS),
      new Metric(SESSIONS),
    ],
    null,
    [COUNTRY],
  ),
  new Chart(channelType, "Users (Returning vs New)", "TABLE", null,
    { x: 6, y: 0, w: 6, h: 1, static: false },
    [
      new Metric(SESSIONS),
      new Metric(NEW_USERS),
      new Metric(GOAL_COMPLETIONS),
    ],
    null,
    [{ id: "ga:userType", name: "User Type" }],
  ),
  new Chart(channelType, "Device Usage", "PIE", null,
    { x: 0, y: 3, w: 6, h: 2, static: false },
    [
      new Metric(SESSIONS, new Filter("Mobile Traffic", { id: "gaid::-14", name: "Mobile Traffic" })),
      new Metric(SESSIONS, new Filter("Tablet Traffic", { id: "gaid::-13", name: "Tablet Traffic" })),
    ],
  ),
  new Chart(channelType, "Source Medium", "TABLE", null,
    { x: 6, y: 1, w: 6, h: 2, static: false },
    [
      new Metric(SESSIONS),
      new Metric(NEW_USERS),
      new Metric(GOAL_COMPLETIONS),
    ],
    null,
    [SOURCE_MEDIUM],
  ),
  new Chart(channelType, "Browser breakdown", "TABLE", null,
    { x: 6, y: 4, w: 6, h: 2, static: false },
    [
      new Metric(PAGE_VIEWS),
      new Metric(SESSIONS),
    ],
    null,
    [{ id: "ga:browser", name: "Browser" }],
  ),
];

const overallGrid = [
  new Grid(null, "ACQUISITION", "Google Analytics", overallAcquisitionCharts),
  new Grid(null, "BEHAVIOUR", "Google Analytics", overallBehaviourCharts),
  new Grid(null, "AUDIENCE", "Google Analytics", overallAudienceCharts),
]


const conversionCharts = [
  new Chart(channelType, "Conversion Funnel", "FUNNEL", null,
    { x: 0, y: 0, w: 6, h: 2, static: false },
    [
      new Metric(SESSIONS),
      new Metric(USERS),
      new Metric(USERS, new Filter(RETURNING_USERS_SEGMENT.name, RETURNING_USERS_SEGMENT)),
    ],
  ),
  new Chart(channelType, "Goal Completion (User Type)", "DONUT", null,
    { x: 6, y: 0, w: 6, h: 2, static: false },
    [
      new Metric(GOAL_COMPLETIONS, new Filter(NEW_USERS_SEGMENT.name, NEW_USERS_SEGMENT)),
      new Metric(GOAL_COMPLETIONS, new Filter(RETURNING_USERS_SEGMENT.name, RETURNING_USERS_SEGMENT)),
    ],
  ),
  new Chart(channelType, "Goal Completion All", "KPI", null,
    { x: 0, y: 2, w: 3, h: 1, static: false },
    [new Metric(GOAL_COMPLETIONS)],
  ),
  new Chart(channelType, "Goal Conversion Rate", "KPI", null,
    { x: 3, y: 2, w: 3, h: 1, static: false },
    [new Metric(GOAL_CONVERSIONS)],
  ),
  new Chart(channelType, "Goal Value All", "KPI", null,
    { x: 0, y: 3, w: 6, h: 1, static: false },
    [new Metric({ id: "ga:goalValuePerSession", name: "Per Session Goal Value" })],
  ),
  new Chart(channelType, "Top Days of Conversions", "TABLE", null,
    { x: 6, y: 2, w: 6, h: 2, static: false },
    [
      new Metric(SESSIONS),
      new Metric(GOAL_COMPLETIONS),
      new Metric(GOAL_CONVERSIONS),
    ],
    null,
    [DATE],
  ),
  new Chart(channelType, "Conversion by source medium", "TABLE", null,
    { x: 0, y: 4, w: 6, h: 3, static: false },
    [
      new Metric(SESSIONS),
      new Metric(GOAL_COMPLETIONS),
      new Metric(GOAL_CONVERSIONS),
    ],
    null,
    [SOURCE_MEDIUM],
  ),
  new Chart(channelType, "Conversion by Campaigns", "TABLE", null,
    { x: 6, y: 4, w: 6, h: 2, static: false },
    [
      new Metric(SESSIONS),
      new Metric(GOAL_COMPLETIONS),
      new Metric(GOAL_CONVERSIONS),
    ],
    null,
    [CAMPAIGN],
  ),
  new Chart(channelType, "Conversion by ads", "TABLE", null,
    { x: 0, y: 7, w: 6, h: 3, static: false },
    [
      new Metric(SESSIONS),
      new Metric(GOAL_COMPLETIONS),
      new Metric(GOAL_CONVERSIONS),
    ],
    null,
    [CAMPAIGN, { id: "ga:adContent", name: "Ad Content" }],
  ),
  new Chart(channelType, "Conversion by Device category", "TABLE", null,
    { x: 6, y: 6, w: 6, h: 2, static: false },
    [
      new Metric(SESSIONS),
      new Metric(GOAL_COMPLETIONS),
      new Metric(GOAL_CONVERSIONS),
    ],
    null,
    [DEVICE_CATEGORY],
  ),
  new Chart(channelType, "Conversion by City", "TABLE", null,
    { x: 0, y: 10, w: 6, h: 3, static: false },
    [
      new Metric(SESSIONS),
      new Metric(GOAL_COMPLETIONS),
      new Metric(GOAL_CONVERSIONS),
    ],
    null,
    [CITY],
  ),
  new Chart(channelType, "Conversion by gender", "TABLE", null,
    { x: 6, y: 8, w: 6, h: 2, static: false },
    [
      new Metric(SESSIONS),
      new Metric(GOAL_COMPLETIONS),
      new Metric(GOAL_CONVERSIONS),
    ],
    null,
    [{ id: "ga:userGender", name: "Gender" }],
  ),
  new Chart(channelType, "Conversion by interest other category", "TABLE", null,
    { x: 6, y: 10, w: 6, h: 3, static: false },
    [
      new Metric(SESSIONS),
      new Metric(GOAL_COMPLETIONS),
      new Metric(GOAL_CONVERSIONS),
    ],
    null,
    [{ id: "ga:interestOtherCategory", name: "Other Category" }],
  ),
];

const conversionsGrid = [
  new Grid(null, "CONVERSIONS", "Google Analytics", conversionCharts),
]


const trafficOverallCharts = [
  new Chart(channelType, "All Channels", "TABLE", null,
    { x: 0, y: 0, w: 6, h: 3, static: false },
    [
      new Metric(SESSIONS),
      new Metric(USERS),
      new Metric(BOUNCE_RATE),
      new Metric(SESSION_DURATION),
    ],
    null,
    [{ id: "ga:channelGrouping", name: "Default Channel Grouping" }],
  ),
  new Chart(channelType, "Total Sessions", "LINE", null,
    { x: 6, y: 0, w: 6, h: 2, static: false },
    [new Metric(SESSIONS)],
  ),
  new Chart(channelType, "New Users", "LINE", null,
    { x: 6, y: 2, w: 6, h: 2, static: false },
    [new Metric(USERS)],
  ),
  new Chart(channelType, "Total Sessions", "KPI", null,
    { x: 0, y: 3, w: 3, h: 1, static: false },
    [new Metric(SESSIONS)],
  ),
  new Chart(channelType, "Organic Traffic", "KPI", null,
    { x: 3, y: 3, w: 3, h: 1, static: false },
    [new Metric(SESSIONS, new Filter(ORGANIC_TRAFFIC.name, ORGANIC_TRAFFIC))],
  ),
];

const trafficPaidCharts = [
  new Chart(channelType, "Campaigns", "TABLE", null,
    { x: 0, y: 0, w: 6, h: 3, static: false },
    [
      new Metric(SESSIONS),
      new Metric(USERS),
      new Metric(BOUNCE_RATE),
      new Metric(SESSION_DURATION),
    ],
    null,
    [CAMPAIGN],
    new Filter(PAID_TRAFFIC.name, PAID_TRAFFIC),
  ),
  new Chart(channelType, "Paid Traffic", "LINE", null,
    { x: 6, y: 0, w: 6, h: 2, static: false },
    [new Metric(SESSIONS, new Filter(PAID_TRAFFIC.name, PAID_TRAFFIC))],
  ),
  new Chart(channelType, "New Paid Traffic Users", "LINE", null,
    { x: 6, y: 2, w: 6, h: 2, static: false },
    [new Metric(NEW_USERS, new Filter(PAID_TRAFFIC.name, PAID_TRAFFIC))],
  ),
  new Chart(channelType, "Paid Traffic", "KPI", null,
    { x: 0, y: 3, w: 3, h: 1, static: false },
    [new Metric(SESSIONS, new Filter(PAID_TRAFFIC.name, PAID_TRAFFIC))],
  ),
  new Chart(channelType, "New Users", "KPI", null,
    { x: 3, y: 3, w: 3, h: 1, static: false },
    [new Metric(NEW_USERS, new Filter(ORGANIC_TRAFFIC.name, ORGANIC_TRAFFIC))],
  ),
];

const trafficOrganicCharts = [
  new Chart(channelType, "Traffic by source", "TABLE", null,
    { x: 0, y: 0, w: 6, h: 3, static: false },
    [
      new Metric(SESSIONS),
      new Metric(USERS),
      new Metric(BOUNCE_RATE),
      new Metric(SESSION_DURATION),
    ],
    null,
    [SOURCE_MEDIUM],
    new Filter(ORGANIC_TRAFFIC.name, ORGANIC_TRAFFIC),
  ),
  new Chart(channelType, "Organic Traffic Sessions", "LINE", null,
    { x: 6, y: 0, w: 6, h: 2, static: false },
    [new Metric(SESSIONS, new Filter(ORGANIC_TRAFFIC.name, ORGANIC_TRAFFIC))],
  ),
  new Chart(channelType, "New Organic Traffic Users", "LINE", null,
    { x: 6, y: 2, w: 6, h: 2, static: false },
    [new Metric(NEW_USERS, new Filter(ORGANIC_TRAFFIC.name, ORGANIC_TRAFFIC))],
  ),
  new Chart(channelType, "Organic Traffic", "KPI", null,
    { x: 0, y: 3, w: 3, h: 1, static: false },
    [new Metric(NEW_USERS, new Filter(ORGANIC_TRAFFIC.name, ORGANIC_TRAFFIC))],
  ),
  new Chart(channelType, "New Users", "KPI", null,
    { x: 3, y: 3, w: 3, h: 1, static: false },
    [new Metric(SESSIONS, new Filter(ORGANIC_TRAFFIC.name, ORGANIC_TRAFFIC))],
  ),
];

const trafficDirectCharts = [
  new Chart(channelType, "Direct Traffic", "LINE", null,
    { x: 0, y: 0, w: 6, h: 2, static: false },
    [new Metric(SESSIONS, new Filter(DIRECT_TRAFFIC.name, DIRECT_TRAFFIC))],
  ),
  new Chart(channelType, "New Direct Users", "LINE", null,
    { x: 6, y: 0, w: 6, h: 2, static: false },
    [new Metric(NEW_USERS, new Filter(DIRECT_TRAFFIC.name, DIRECT_TRAFFIC))],
  ),
  new Chart(channelType, "Direct Traffic", "KPI", null,
    { x: 0, y: 2, w: 12, h: 1, static: false },
    [new Metric(SESSIONS, new Filter(DIRECT_TRAFFIC.name, DIRECT_TRAFFIC))],
  ),
];

const trafficReferralCharts = [
  new Chart(channelType, "Referral Traffic", "LINE", null,
    { x: 0, y: 0, w: 6, h: 2, static: false },
    [new Metric(SESSIONS, new Filter(REFERRAL_TRAFFIC.name, REFERRAL_TRAFFIC))],
  ),
  new Chart(channelType, "Referral New Users", "LINE", null,
    { x: 6, y: 0, w: 6, h: 2, static: false },
    [new Metric(NEW_USERS, new Filter(REFERRAL_TRAFFIC.name, REFERRAL_TRAFFIC))],
  ),
  new Chart(channelType, "Referral Traffic", "KPI", null,
    { x: 0, y: 2, w: 12, h: 1, static: false },
    [new Metric(SESSIONS, new Filter(REFERRAL_TRAFFIC.name, REFERRAL_TRAFFIC))],
  ),
];

const trafficGrid = [
  new Grid(null, "OVERALL TRAFFIC", "Google Analytics", trafficOverallCharts),
  new Grid(null, "PAID TRAFFIC", "Google Analytics", trafficPaidCharts),
  new Grid(null, "ORGANIC TRAFFIC", "Google Analytics", trafficOrganicCharts),
  new Grid(null, "DIRECT TRAFFIC", "Google Analytics", trafficDirectCharts),
  new Grid(null, "REFERRAL TRAFFIC", "Google Analytics", trafficReferralCharts),
]


const onSiteOverallCharts = [
  new Chart(channelType, "User Type Dynamics", "LINE", null,
    { x: 0, y: 0, w: 6, h: 2, static: false },
    [
      new Metric(USERS),
      new Metric(USERS, new Filter(NEW_USERS_SEGMENT.name, NEW_USERS_SEGMENT)),
      new Metric(USERS, new Filter(RETURNING_USERS_SEGMENT.name, RETURNING_USERS_SEGMENT)),
    ],
  ),
  new Chart(channelType, "User Distribution", "PIE", null,
    { x: 6, y: 0, w: 6, h: 2, static: false },
    [
      new Metric(USERS, new Filter(NEW_USERS_SEGMENT.name, NEW_USERS_SEGMENT)),
      new Metric(USERS, new Filter(RETURNING_USERS_SEGMENT.name, RETURNING_USERS_SEGMENT)),
    ],
  ),
  new Chart(channelType, "New/Returning Activity", "TABLE", null,
    { x: 6, y: 2, w: 6, h: 2, static: false },
    [
      new Metric(USERS),
      new Metric(NEW_USERS),
      new Metric(PAGE_VIEWS_PER_SESSION),
      new Metric(AVG_SESSION_DURATION),
    ],
    null,
    [{ id: "ga:userType", name: "User Type" }],
  ),
  new Chart(channelType, "Sessions", "KPI", null,
    { x: 0, y: 2, w: 3, h: 1, static: false },
    [new Metric(SESSIONS)],
  ),
  new Chart(channelType, "Users", "KPI", null,
    { x: 3, y: 2, w: 3, h: 1, static: false },
    [new Metric(USERS)],
  ),
  new Chart(channelType, "Number of Session Per User", "KPI", null,
    { x: 0, y: 3, w: 3, h: 1, static: false },
    [new Metric({ id: "ga:sessionsPerUser", name: "Number of Sessions per User" })],
  ),
  new Chart(channelType, "Avg Session Duration", "KPI", null,
    { x: 3, y: 3, w: 3, h: 1, static: false },
    [new Metric(AVG_SESSION_DURATION)],
  ),
  new Chart(channelType, "Bounce Rate", "KPI", null,
    { x: 0, y: 4, w: 3, h: 1, static: false },
    [new Metric(BOUNCE_RATE)],
  ),
  new Chart(channelType, "Page Views Per Session", "KPI", null,
    { x: 3, y: 4, w: 3, h: 1, static: false },
    [new Metric(PAGE_VIEWS_PER_SESSION)],
  ),
  new Chart(channelType, "Page Views", "KPI", null,
    { x: 6, y: 4, w: 3, h: 1, static: false },
    [new Metric(PAGE_VIEWS)],
  ),
  new Chart(channelType, "Unique Page Views", "KPI", null,
    { x: 9, y: 4, w: 3, h: 1, static: false },
    [new Metric(UNIQUE_PAGE_VIEWS)],
  ),
];

const onSiteDeviceCharts = [
  new Chart(channelType, "User Devices Trend", "LINE", null,
    { x: 0, y: 0, w: 6, h: 2, static: false },
    [
      new Metric(USERS, new Filter("Tablet and Desktop Traffic", { id: "gaid::-15", name: "Tablet and Desktop Traffic" })),
      new Metric(USERS, new Filter("Mobile Traffic", { id: "gaid::-14", name: "Mobile Traffic" })),
    ],
  ),
  new Chart(channelType, "User Distribution by Devices", "PIE", null,
    { x: 6, y: 0, w: 6, h: 2, static: false },
    [
      new Metric(USERS, new Filter("Tablet and Desktop Traffic", { id: "gaid::-15", name: "Tablet and Desktop Traffic" })),
      new Metric(USERS, new Filter("Mobile Traffic", { id: "gaid::-14", name: "Mobile Traffic" })),
    ],
  ),
  new Chart(channelType, "Device Session Trend", "LINE", null,
    { x: 0, y: 2, w: 6, h: 2, static: false },
    [
      new Metric(AVG_SESSION_DURATION, new Filter("Tablet and Desktop Traffic", { id: "gaid::-15", name: "Tablet and Desktop Traffic" })),
      new Metric(AVG_SESSION_DURATION, new Filter("Mobile Traffic", { id: "gaid::-14", name: "Mobile Traffic" })),
    ],
  ),
  new Chart(channelType, "Devices Activity", "TABLE", null,
    { x: 6, y: 2, w: 6, h: 2, static: false },
    [
      new Metric(SESSIONS),
      new Metric(BOUNCE_RATE),
      new Metric(PAGE_VIEWS_PER_SESSION),
      new Metric(AVG_SESSION_DURATION),
    ],
    null,
    [DEVICE_CATEGORY],
  ),
];

const onSiteLocationCharts = [
  new Chart(channelType, "Performance by Country", "TABLE", null,
    { x: 0, y: 0, w: 6, h: 3, static: false },
    [
      new Metric(SESSIONS),
      new Metric(PAGE_VIEWS),
      new Metric(PAGE_VIEWS_PER_SESSION),
      new Metric(AVG_SESSION_DURATION),
    ],
    null,
    [COUNTRY],
  ),
  new Chart(channelType, "Performance by City", "TABLE", null,
    { x: 6, y: 0, w: 6, h: 3, static: false },
    [
      new Metric(SESSIONS),
      new Metric(PAGE_VIEWS),
      new Metric(PAGE_VIEWS_PER_SESSION),
      new Metric(AVG_SESSION_DURATION),
    ],
    null,
    [CITY],
  ),
  new Chart(channelType, "Avg Session Duration", "KPI", null,
    { x: 0, y: 3, w: 6, h: 1, static: false },
    [new Metric(AVG_SESSION_DURATION)],
  ),
  new Chart(channelType, "Page Views Per Session", "KPI", null,
    { x: 6, y: 3, w: 6, h: 1, static: false },
    [new Metric(PAGE_VIEWS_PER_SESSION)],
  ),
];

const onSitePageCharts = [
  new Chart(channelType, "Top Pages", "TABLE", null,
    { x: 0, y: 0, w: 9, h: 2, static: false },
    [
      new Metric(SESSIONS),
      new Metric(PAGE_VIEWS),
      new Metric(UNIQUE_PAGE_VIEWS),
      new Metric(AVG_TIME_ON_PAGE),
      new Metric(PAGE_VIEWS_PER_SESSION),
      new Metric({ id: "ga:entrances", name: "Entrances" }),
      new Metric({ id: "ga:exits", name: "Exits" }),
    ],
    null,
    [{ id: "ga:pageTitle", name: "Page Title" }],
  ),
  new Chart(channelType, "Exit Rate", "KPI", null,
    { x: 9, y: 0, w: 3, h: 1, static: false },
    [new Metric(EXIT_RATE)],
  ),
  new Chart(channelType, "Avg. Time on Page", "KPI", null,
    { x: 9, y: 1, w: 3, h: 1, static: false },
    [new Metric(AVG_TIME_ON_PAGE)],
  ),
];

const onSiteGrid = [
  new Grid(null, "OVERALL", "Google Analytics", onSiteOverallCharts),
  new Grid(null, "DEVICES", "Google Analytics", onSiteDeviceCharts),
  new Grid(null, "LOCATION", "Google Analytics", onSiteLocationCharts),
  new Grid(null, "PAGES", "Google Analytics", onSitePageCharts),
]


const ecommerceOverallCharts = [
  new Chart(channelType, "Ecommerce Funnel", "FUNNEL", null,
    { x: 0, y: 0, w: 6, h: 3, static: false },
    [
      new Metric(SESSIONS),
      new Metric({ id: "ga:productAddsToCart", name: "Product Adds To Cart" }),
      new Metric({ id: "ga:productCheckouts", name: "Product Checkouts" }),
      new Metric(TRANSACTIONS),
    ],
  ),
  new Chart(channelType, "Revenue vs Transactions", "LINE", null,
    { x: 6, y: 0, w: 6, h: 3, static: false },
    [new Metric(TRANSACTION_REVENUE)],
    [new Metric(TRANSACTIONS)],
  ),
  new Chart(channelType, "Transactions", "KPI", null,
    { x: 0, y: 0, w: 4, h: 1, static: false },
    [new Metric(TRANSACTIONS)],
  ),
  new Chart(channelType, "Transaction Revenue", "KPI", null,
    { x: 4, y: 3, w: 4, h: 1, static: false },
    [new Metric(TRANSACTION_REVENUE)],
  ),
  new Chart(channelType, "Conversion Rate", "KPI", null,
    { x: 8, y: 3, w: 4, h: 1, static: false },
    [new Metric(TRANSACTIONS_PER_SESSION)],
  ),
  new Chart(channelType, "Avg. Order Value", "KPI", null,
    { x: 0, y: 4, w: 4, h: 1, static: false },
    [new Metric({ id: "ga:revenuePerTransaction", name: "Avg. Order Value" })],
  ),
  new Chart(channelType, "Unique Purchases", "KPI", null,
    { x: 4, y: 4, w: 4, h: 1, static: false },
    [new Metric({ id: "ga:uniquePurchases", name: "Unique Purchases" })],
  ),
  new Chart(channelType, "Revenue per Session", "KPI", null,
    { x: 8, y: 4, w: 4, h: 1, static: false },
    [new Metric({ id: "ga:transactionRevenuePerSession", name: "Per Session Value" })],
  ),
];

const ecommerceProductCharts = [
  new Chart(channelType, "Performance by Product Category", "TABLE", null,
    { x: 0, y: 0, w: 6, h: 3, static: false },
    [
      new Metric({ id: "ga:itemQuantity", name: "Quantity" }),
      new Metric({ id: "ga:revenuePerItem", name: "Avg. Price" }),
      new Metric({ id: "ga:itemRevenue", name: "Product Revenue" }),
    ],
    null,
    [{ id: "ga:productCategory", name: "Product Category" }],
  ),
  new Chart(channelType, "Top Products", "TABLE", null,
    { x: 6, y: 0, w: 6, h: 3, static: false },
    [
      new Metric({ id: "ga:itemQuantity", name: "Quantity" }),
      new Metric({ id: "ga:revenuePerItem", name: "Avg. Price" }),
      new Metric({ id: "ga:itemRevenue", name: "Product Revenue" }),
    ],
    null,
    [{ id: "ga:productName", name: "Product" }, { id: "ga:productSku", name: "Product SKU" }],
  ),
];

const ecommerceSalesCharts = [
  new Chart(channelType, "Performance by Campaign", "TABLE", null,
    { x: 0, y: 0, w: 6, h: 3, static: false },
    [
      new Metric(SESSIONS),
      new Metric(TRANSACTIONS),
      new Metric(TRANSACTION_REVENUE),
      new Metric(TRANSACTIONS_PER_SESSION),
    ],
    null,
    [CAMPAIGN],
  ),
  new Chart(channelType, "Performance by Source Medium", "TABLE", null,
    { x: 6, y: 0, w: 6, h: 3, static: false },
    [
      new Metric(SESSIONS),
      new Metric(TRANSACTIONS),
      new Metric(TRANSACTION_REVENUE),
      new Metric(TRANSACTIONS_PER_SESSION),
    ],
    null,
    [SOURCE_MEDIUM],
  ),
];

const ecommerceDeviceCharts = [
  new Chart(channelType, "Device Category", "TABLE", null,
    { x: 0, y: 0, w: 6, h: 3, static: false },
    [
      new Metric(SESSIONS),
      new Metric(TRANSACTIONS),
      new Metric(TRANSACTION_REVENUE),
      new Metric(TRANSACTIONS_PER_SESSION),
    ],
    null,
    [DEVICE_CATEGORY],
  ),
  new Chart(channelType, "Device Category Trend", "BAR", null,
    { x: 6, y: 0, w: 6, h: 3, static: false },
    [
      new Metric(TRANSACTION_REVENUE, new Filter("Android Traffic", { id: "gaid::-16", name: "Android Traffic" })),
      new Metric(TRANSACTION_REVENUE, new Filter("iOS Traffic", { id: "gaid::-17", name: "iOS Traffic" })),
      new Metric(TRANSACTION_REVENUE, new Filter("Other Traffic (Neither iOS nor Android)", { id: "gaid::-18", name: "Other Traffic (Neither iOS nor Android)" })),
    ],
  ),
  new Chart(channelType, "Country wise performance", "TABLE", null,
    { x: 0, y: 3, w: 6, h: 3, static: false },
    [
      new Metric(SESSIONS),
      new Metric(TRANSACTIONS),
      new Metric(TRANSACTION_REVENUE),
      new Metric(TRANSACTIONS_PER_SESSION),
    ],
    null,
    [COUNTRY],
  ),
  new Chart(channelType, "City wise performance", "TABLE", null,
    { x: 6, y: 3, w: 6, h: 3, static: false },
    [
      new Metric(SESSIONS),
      new Metric(TRANSACTIONS),
      new Metric(TRANSACTION_REVENUE),
      new Metric(TRANSACTIONS_PER_SESSION),
    ],
    null,
    [CITY],
  ),
];

const ecommerceGrid = [
  new Grid(null, "OVERALL", "Google Analytics", ecommerceOverallCharts),
  new Grid(null, "PRODUCTS", "Google Analytics", ecommerceProductCharts),
  new Grid(null, "SALES", "Google Analytics", ecommerceSalesCharts),
  new Grid(null, "DEVICE/LOCATIONS", "Google Analytics", ecommerceDeviceCharts),
]


export default [
  new Tab("Overall", 0, overallGrid),
  new Tab("Conversions", 1, conversionsGrid),
  new Tab("Traffic", 2, trafficGrid),
  new Tab("On-site Behaviour", 3, onSiteGrid),
  new Tab("Ecommerce", 4, ecommerceGrid),
];
