import { SELECT_ACCOUNT } from "../channel/type";
import { channelType } from "./transformer";

const INITIAL_STATE = {
    clientId: "",
    accountData: {},
    segments: [
        {
            name: "View through attribution window",
            values: [
                { id: "1d_view", name: "1 day" },
                { id: "1d_ev", name: "1 day engagement" },
                // { id: "7d_view", name: "7 days" },
                // { id: "28d_view", name: "28 days" },
            ],
        },
        {
            name: "Click through attribution window",
            values: [
                { id: "1d_click", name: "1 day" },
                { id: "7d_click", name: "7 days" },
                { id: "28d_click", name: "28 days" },
            ],
        },
        {
            name: "SKad Network API",
            values: [
                { id: "skan_view", name: "View", value: false },
                { id: "skan_click", name: "Click", value: true },
            ],
        },
    ],
    dimensions: [],
    metrics: [],
    customDimensions: [],
    customMetrics: [],
    currency: null,
    metricsUpdated: false,
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case `${channelType}.GET_CLIENT_ID_SUCCESS`:
            return { ...state, clientId: action.payload.clientId };
        case `${channelType}.GET_CLIENT_ID_FAILURE`:
            return { ...state, clientId: "" };
        case `${channelType}.GET_ACCOUNT_SUMMARY_SUCCESS`:
            const accountList = action.payload.allAccounts.map(data => ({
                title: data.adAccountName,
                // subtitle: data.adAccountId,
                id: data.adAccountId,
                accountId: data.adAccountId,
                users: data.users,
                channelType
            }))
            return { ...state, accountData: { users: action.payload.allUsers, accountList: accountList } };
        case `${channelType}.START_LOADING`:
            return { ...state, accountData: { ...state.accountData, loading: true } };
        case `${channelType}.DISCONNECT_ACCOUNT_SUCCESS`:
        case `${channelType}.GET_ACCOUNT_SUMMARY_FAILURE`:
            return { ...INITIAL_STATE, clientId: state.clientId };
        case `${channelType}.GET_FIELDS_SUCCESS`:
            const { dimensions, metrics } = action.payload.columns.reduce((group, col) => {
                col.type === "DIMENSION" ? group.dimensions.push(col) : group.metrics.push(col)
                return group;
            }, { dimensions: [], metrics: [] })
            return { ...state, dimensions: dimensions, metrics: metrics, metricsUpdated: true };
        case `${channelType}.GET_CUSTOM_FIELDS_SUCCESS`:
            const { dimensions: customDimensions, metrics: customMetrics } = action.payload.columns.reduce((group, col) => {
                col.type === "DIMENSION" ? group.dimensions.push(col) : group.metrics.push(col)
                return group;
            }, { dimensions: [], metrics: [] })
            return { ...state, customDimensions: customDimensions, customMetrics: customMetrics, metricsUpdated: state.metrics.length && state.dimensions.length };
        case `${channelType}.GET_CURRENCY_SUCCESS`:
            return { ...state, currency: action.payload.currency }
        case SELECT_ACCOUNT:
            if (action.payload.channel === channelType) {
                return { ...state, customDimensions: [], customMetrics: [], metricsUpdated: false };
            }
            return state;
        default:
            return state;
    }
};

export default reducer;
