import { all, put, select, takeLatest, takeLeading } from "redux-saga/effects";
import { api } from "..";
import { CHECK_AUTH, SELECT_ACCOUNT } from "../channel/type";
import { channelType, getAccountId } from "./transformer";
import mixpanelObj, { EVENTS } from "../../utils/mixpanel";
import { convertAccountsFormat } from "../../models/channel";

function* getClientID() {
    try {
        const response = yield api.get("/v2/analytics/channel/linkedin-ads/get-client-id");
        yield put({
            type: `${channelType}.GET_CLIENT_ID_SUCCESS`,
            payload: response.data.clientId
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_CLIENT_ID_FAILURE`,
            payload: error.response
        });
    }
}

function* connectAccount(args) {
    const state = yield select((state) => state);
    const clientId = state[channelType].clientId;
    yield window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${process.env.REACT_APP_BASE_URL}/${args.payload.redirect_url}/&state=${channelType}&scope=r_emailaddress,r_liteprofile,w_member_social,w_organization_social,rw_ads,r_ads_reporting,r_ads_reporting,r_1st_connections_size,rw_organization_admin,r_organization_social`;
}

function* sendLinkedlnCode(args) {
    try {
        yield api.post("/v2/analytics/linkedin-ads/auth", {
            code: args.payload.code,
            redirect_url: args.payload.redirect_url,
        });
        mixpanelObj.track(EVENTS.connect_source, {
            data_source: channelType,
            channel_type: "ads",
            connected: "Yes"
        }, true);
        window.location.href = window.location.origin + window.location.pathname + `?channel=${channelType}`;
    } catch (error) {
        yield put({
            type: `${channelType}.CONNECT_ACCOUNT_FAILURE`,
        });
        mixpanelObj.track(EVENTS.connect_source, {
            data_source: channelType,
            channel_type: "ads",
            connected: "No"
        }, true);
        window.location.href = window.location.origin + window.location.pathname + `?channel=${channelType}&failure`;
    }
}

function* disconnectAccount() {
    try {
        yield api.post("/v2/analytics/linkedin-ads/revoke");
        yield put({
            type: `${channelType}.DISCONNECT_ACCOUNT_SUCCESS`,
        });
        yield put({
            type: SELECT_ACCOUNT,
            payload: {
                channel: channelType,
            }
        });
        yield put({
            type: CHECK_AUTH,
        });
        mixpanelObj.track(EVENTS.remove_source, { data_source: channelType, channel_type: "ads" }, true);
    } catch (error) {
        yield put({
            type: `${channelType}.DISCONNECT_ACCOUNT_FAILURE`,
        });
    }
}

function* getAccountSummary(args) {
    try {
        yield put({ type: `${channelType}.START_LOADING` });
        const response = yield api.get(`/v2/analytics/linkedin-ads/get-account-summary?refresh=${args.payload.refresh}`);
        const data = yield convertAccountsFormat(response.data.data)
        yield put({
            type: `${channelType}.GET_ACCOUNT_SUMMARY_SUCCESS`,
            payload: data
        });
        yield put({
            type: "UPDATE_ACCOUNTS_LENGTH",
            payload: { accLen: data.allAccounts.length, channelType }
        })
    } catch (error) {
        yield put({
            type: `${channelType}.GET_ACCOUNT_SUMMARY_FAILURE`,
            payload: error.response
        });
    }
}

function* getFields() {
    try {
        const response = yield api.post("/v2/analytics/linkedin-ads/get-columns");
        yield put({
            type: `${channelType}.GET_FIELDS_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_FIELDS_FAILURE`,
            payload: error.response
        });
    }
}

function* getCurrency(args) {
    try {
        const response = yield api.post("/v2/analytics/linkedin-ads/get-currency", getAccountId(args.payload));
        yield put({
            type: `${channelType}.GET_CURRENCY_SUCCESS`,
            payload: response.data
        });
    } catch (error) {
        yield put({
            type: `${channelType}.GET_CURRENCY_FAILURE`,
            payload: error.response
        });
    }
}

export default function* root() {
    yield all([
        takeLatest(`${channelType}.GET_CLIENT_ID`, getClientID),
        takeLatest(`${channelType}.CONNECT_ACCOUNT`, connectAccount),
        takeLatest(`${channelType}.SEND_CODE`, sendLinkedlnCode),
        takeLatest(`${channelType}.DISCONNECT_ACCOUNT`, disconnectAccount),
        takeLeading(`${channelType}.GET_ACCOUNT_SUMMARY`, getAccountSummary),
        takeLatest(`${channelType}.GET_FIELDS`, getFields),
        takeLatest(`${channelType}.GET_CURRENCY`, getCurrency),
    ])
}
