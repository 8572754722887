import { api } from "..";

export const getOrganizationList = () => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post("/v2/analytics/super-admin/get-organization-list");
        resolve(response.data.organization_list);
    } catch (error) {
        reject(error.response);
    }
});

export const selectOrganization = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post("/v2/analytics/super-admin/select-organization", payload);
        localStorage.setItem("accessToken", response.data.access_token);
        resolve(response.data);
    } catch (err) {
        reject(err.response);
    }
});
