import "../../components/styles/shopify.css";
import pixisLogo from "../../Assets/Images/logos_pixis.svg";
import createAppShopify from "../../Assets/Images/create_app_shopify.png";
import newShopifyAccount from "../../Assets/Images/new_shopify_account.gif";

export default function ShopifyConnectPage() {
    return (
        <>
            <div className="containerShopify">
                <header class="header">
                    <nav>
                         <div>
                              <img src={pixisLogo} alt="Pixis" />
                         </div>
                         <div className="logoText">
                              <h1 style={{fontSize:"35px"}}><span style={{color:"black"}}><b>Pixis </b></span> <span style={{color:"rgb(8, 105, 251)"}}><b>Analytics</b></span></h1>
                         </div>
                    </nav>
                </header>
                <main className="main">
                        <div class="contents">
                            <div className="mainHeading">
                                <h1> How to connect your Shopify account to Pixis Analytics</h1>
                            </div>
                            <div className="article">
                                <p>This article presents the steps needed to retrieve the API access key needed to connect your Shopify store.</p>
                            </div>
                            <div className="articleDescription">
                                    <p>
                                        In order to connect a <b>Shopify</b> store to your Pixis Analytics account, you will need to fill in the following fields which can be
                                        seen in the screenshot below. This article will help you find the required information. We understand this process is quite cumbersome. 
                                        However, this is a temporary source connection flow to ensure a stable API connection, 
                                        while the team is investigating potential solutions to improve it in the future.
                                    </p>
                            </div>
                            <div className="imgContainer">
                                <img src="https://downloads.intercomcdn.com/i/o/548135854/76d1c0297e3fd58892854444/Screenshot+2022-07-18+at+16.43.11.png" alt="Step 1" />
                            </div>
                            <div className="shopUrl">
                                <h3><b>Get Shop URL:</b></h3>
                                <ol>
                                    <li><p>When logged into your Shopify account, click on <b>'Settings'</b> (button in the bottom left corner of the screen): </p></li>
                                    <div>
                                            <img src="https://whatagraph-team.intercom-attachments-7.com/i/o/548158275/4a33a22d17636027ce4b5f29/DHFoNC2TbvcsnVGoQi_3MHxZ4WmYCQ73TcGwZ-qMZ5GunZQ9eOdnAHsgxZn8YKg5ghKR9qcAlWVMyUb0LLFc4UvjEmNLyLErSgv_o7o3vlSaLzMC4mVzB5Ii9GkW9sOVEPOpYCLBRmWy1c7MJcOrXe4" alt="Step 2" />
                                    </div>
                                </ol>
                            </div>
                            <div className="shopUrl">
                                <h3><b>Get Access Token:</b></h3>
                                <ol>
                                    <li><p>In the Settings menu, click on <b>'Apps and sales channels'</b>:  </p></li>
                                    <div>
                                            <img src="https://downloads.intercomcdn.com/i/o/548140205/12b27327c069d0b15edeb5a1/Screenshot+2022-07-18+at+16.48.51.png" style={{objectFit:"contain",height:"260px"}} alt="Step 4" />
                                    </div>
                                    <li><p>Click the <b>'Develop apps'</b> or <b>'Develop apps for your store'</b> button: </p></li>
                                    <div>
                                            <img src="https://whatagraph-team.intercom-attachments-7.com/i/o/548158313/4a8c48044f0dfa23bf7ba6a8/x14fWBahyRkic3ymGkSKv4-WuGsmfUVyMViQRdHlMgmOwfemk7hiKI4vg1pvxzob858pYQf-y_OSbNZdoqG2aQQVFOaZE9OQeeB0hmYl_aJo26huEbZCcbmZxDnp-TS3qP5dysIO02vdLtXrn1R_HHI" alt="Step 5" />
                                    </div>
                                    <li><p>Click the <b>'Create an app'</b> button:  </p></li>
                                    <div>
                                          <img src="https://whatagraph-team.intercom-attachments-7.com/i/o/548158321/8a8270bf0a4ee5f0cb2c20d7/8U7L--lbDbX9pqTkJPqJjR1az8Y5RfxH805RZi7FWMYVG4nGlBz4rcq0BU3lHtnwA0xSEg616dLJx-qR5DWxJYQnadnFdeBdrT9PkZ8OYOVrFsEvaLjpLFdGx13SgvgO4tcXHRTQipSzhzOSQEkPrF4" alt="Step 6" />
                                    </div>
                                    <li><p>Give the app a memorable <b>name:</b> </p></li>
                                    <div>
                                          <img src={createAppShopify} alt="Create Shopify app" />
                                    </div>
                                    <li><p>Select <b>'Configure Admin API scopes':</b>  </p></li>
                                    <div>
                                          <img src="https://whatagraph-team.intercom-attachments-7.com/i/o/548158340/27ccc735bd8e56df845f0469/Ep0abr1hYYVcVSfln1vCAKXBRFhaGLY5mM5M8-6SSw-X6KpzTnt0gUBbMsI9Je7ECiFKSPiBV0vouTGHRTxWEb4EW65JFg0s2yJdRyWwVhACQ7B2TLgTOyxHHsbH4LbTFt9pd6ga-gzjHGqT2UxS2rE" alt="Step 7" />
                                    </div>
                                    <li><p>Tick the following scopes and click <b>“Save”</b>:  </p></li>
                                    <div>
                                          <ol id="scopesList">
                                              <li>Read_analytics</li>

                                              <li>Read_assigned_fulfillment_orders</li> 

                                              <li>Read_customers</li> 

                                              <li>Read_discounts</li> 

                                              <li> Read_draft_orders</li> 

                                              <li> Read_fulfillments</li> 

                                              <li> Read_gift_cards</li> 

                                              <li> Read_inventory</li> 

                                              <li> Read_locations</li> 

                                              <li> Read_marketing_events</li> 

                                              <li> Read_merchant_managed_fulfillment_orders</li> 

                                              <li>Read_orders</li> 

                                              <li> Read_product_listings</li> 

                                              <li> Read_products</li> 

                                              <li> Read_reports</li> 

                                             <li>Read_shipping</li> 

                                             <li>Read_locales</li> 

                                             <li>Read_third_party_fulfillment_orders </li><br/>
                                          </ol>
                                          <img src="https://whatagraph-team.intercom-attachments-7.com/i/o/548158345/db0b182765f5d47a8b71a087/SA3qIoeRu2rOCvb7BAVeC4MORFE1a-fdCr3OhG4QxXTfF72IurZT708hDcW1N2FAZ-ZBvF6DKGqixFNF_JD2C8bAOa8L2I45ZYE3QUk2kXYcA29Y-E8_N0RjfNwjja_-E-MAlqYZi8902q3gKm2aoLc" alt="Step 8" />
                                    </div>
                                    <li><p>Click 'Install App':   </p></li>
                                    <div>
                                          <img src="https://whatagraph-team.intercom-attachments-7.com/i/o/548158349/e55feb2fa29e05316cdba0f3/5-a9qi0lmlW2KvtQvMfhaCeIQeRI_47pvcx-CxuY_7L2YUa_mgqH1p_28gr9Ec1UDwdTQ81vSF3KpvuTWV_SBEkxf01u4AD2qF28US16ld8AFK22RZBpCYh0MKGvLbKduFRP3bj5v-HfJF2V0oxXMWQ" alt="Step 9" />
                                    </div>
                                    <li><p>Once the app is installed, click <b>'Reveal token once'</b>, copy it, and paste it to the required field in Pixis: </p></li>
                                    <div>
                                          <img src="https://whatagraph-team.intercom-attachments-7.com/i/o/548158361/bc850d693ff62488143a65b0/FmpdtPZR2ZwxrZ3VnUplhp76Biu4t6uI2PVNXXiQe0Jfh4L1B_jBtP_B0Rqvxq8X5cw0XCfYUtWezlEybXFl1daR1nqdCBLzrE8fN2SFj41898JgCwca2L5bKbV9IacQVWOR80QNP8PNmojT4TrfIL4" alt="Step 10" />
                                    </div>
                                    <li><p>Click the <b>'Add account'</b> button on Pixis to connect it: </p></li>
                                    <div>
                                          <img src={newShopifyAccount} alt="New Shopify account" />
                                    </div>
                                </ol>
                            </div>
                            <div className="endArticel">
                                  <p>Did this answer your question?</p>
                                  <div className="emoji">
                                      <p>&#128512;</p>
                                      <p>&#128512;</p>
                                      <p>&#128512;</p>
                                  </div>
                            </div>
                        </div>
                 </main>
            </div>
        </>
    )
}
