import {
    CREATE_REPORT, UPDATE_REPORT, DELETE_REPORT, GET_ALL_REPORTS, GET_REPORT,
    UPDATE_CHART, UPDATE_GRID, UPDATE_TAB, GET_TAB_DETAILS,
    DUPLICATE_REPORT, GET_DEFAULT_REPORT, SET_DEFAULT_REPORT,
    UPDATE_REPORT_DATA, ADD_REPORT_DATA, CLEAR_REPORT_UPDATES,
    UPDATE_REPORT_DATE_RANGE, UPDATE_REPORT_AD_ACC_COLS,
    SHARE_REPORT, GET_SHARED_REPORT
} from "./type";

export const shareReport = payload => {
    return {
        type: SHARE_REPORT,
        payload: payload
    };
};

export const getSharedReportDetails = payload => {
    return {
        type: GET_SHARED_REPORT,
        payload: payload
    };
};

export const createReport = payload => {
    return {
        type: CREATE_REPORT,
        payload: payload
    };
};

export const updateReport = payload => {
    return {
        type: UPDATE_REPORT,
        payload: payload
    };
};

export const updateReportData = payload => {
    return {
        type: UPDATE_REPORT_DATA,
        payload: payload
    };
};

export const updateReportDateRange = payload => {
    return {
        type: UPDATE_REPORT_DATE_RANGE,
        payload: payload
    };
};

export const updateReportAdAccCols = payload => {
    return {
        type: UPDATE_REPORT_AD_ACC_COLS,
        payload: payload
    };
};

export const addReportData = payload => {
    return {
        type: ADD_REPORT_DATA,
        payload: payload
    };
};

export const clearReportUpdates = payload => {
    return {
        type: CLEAR_REPORT_UPDATES,
        payload: payload
    };
};

export const duplicateReport = payload => {
    return {
        type: DUPLICATE_REPORT,
        payload: payload
    };
};

export const deleteReport = payload => {
    return {
        type: DELETE_REPORT,
        payload: payload
    };
};

export const getAllReports = payload => {
    return {
        type: GET_ALL_REPORTS,
        payload: payload
    };
};

export const getReport = payload => {
    return {
        type: GET_REPORT,
        payload: payload
    };
};

export const updateChart = payload => {
    return {
        type: UPDATE_CHART,
        payload: payload
    };
};

export const updateGrid = payload => {
    return {
        type: UPDATE_GRID,
        payload: payload
    };
};

export const updateTab = payload => {
    return {
        type: UPDATE_TAB,
        payload: payload
    };
};

export const getTabDetails = payload => {
    return {
        type: GET_TAB_DETAILS,
        payload: payload
    };
};

export const getDefaultReport = payload => {
    return {
        type: GET_DEFAULT_REPORT,
        payload: payload
    };
};

export const setDefaultReport = payload => {
    return {
        type: SET_DEFAULT_REPORT,
        payload: payload
    };
};
