import React from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import "../styles/modal.css";

const UnsavedModal = (props) => {
  const { modal, setModal, message, onChange, callbackRedirection } = props;

  const customStyles = {
    top: "30%",
    left: "35%",
    right: "auto",
    bottom: "auto",
    backgroundColor: "#ffff",
    borderRadius: "8px",
    width: "30%",
    height: "204px",
    padding: "30px",
    zIndex: 9000000000000,
    position: "absolute",
    fontFamily: "Inter",
    display: "flex",
    flexDirection: "column",
  };

  const handleDiscardClick = () => {
    setModal(false);
    if (callbackRedirection) {
      callbackRedirection();
    }
  };

  return (
    <div>
      <Modal
        open={modal}
        onClose={() => setModal("none")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={customStyles}>
          <Typography
            component="div"
            variant="h4"
            sx={{
              color: "#000000",
              fontSize: "24px",
              fontWeight: "bold",
              fontFamily: "Inter",
              textShadow: "12px 12px 48px 0px rgba(80, 80, 159, 0.04)",
            }}
          >
            Unsaved Changes
          </Typography>
          <Typography
            component="p"
            variant="p"
            style={{ marginTop: "12px", color: "#5C6674", fontSize: "14px" }}
          >
            {message}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "12px",
            }}
          >
            <Button
              onClick={handleDiscardClick}
              sx={{
                color: "#0869FB",
                textTransform: "capitalize",
                marginRight: "10px",
              }}
            >
              Discard
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#0869FB", textTransform: "capitalize" }}
              onClick={() => {
                onChange();
                setModal(false);
              }}
            >
              Save Changes
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
export default UnsavedModal;