import * as React from "react";
import {
    Autocomplete,
    Box,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import "../styles/profile.css";
import { v4 as uuidv4 } from "uuid";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { styled } from "@mui/material/styles";
import Tooltip from '@mui/material/Tooltip';
import { getFormula } from "../../utils/FormulaBasedMetrics";

const TriggerTooltip = styled(({ className, ...props }) => {
    return   <Tooltip 
                {...props} 
                componentsProps={{ tooltip: { className: className } }} 
                placement="top-end"
                PopperProps={{
                    placement:"bottom-start"
                }}
            />
 })(`
    color: lightblue;
    background-color: #ffff;
    font-size: 1.5em;
    padding: 10px;
    box-shadow:0px 10px 24px 0px rgba(35, 40, 60, 0.05);
    border:2px solid rgba(35, 40, 60, 0.05);
`);

const CSS = { fontSize:"12px", color:"#5C6674",fontFamily:"Inter",marginBottom:"10px",lineHeight:"17px" }

const RenderTooltip = ({channelType,option}) => {
    return (
        <>
            <h4 style={{...CSS,fontWeight:"bolder",color:"#2E3044"}}>{option.name}</h4>
            <h4 style={{...CSS}}><span style={{color:"#585969",fontWeight:600,opacity:0.8}}>Custom Formula:</span> = [{getFormula(channelType,option.id)}]</h4>
            <h4 style={{...CSS}}><span style={{color:"#585969",fontWeight:600,opacity:0.8}}>Data Type:</span> {option.dataType}</h4>
            <h4 style={{...CSS}}><span style={{color:"#585969",fontWeight:600,opacity:0.8}}>Note:</span> Calculated metrics are aggregated according to the formula.</h4>
        </>
    )
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const options = ["Sum", "Average", "Minimum", "Maximum"];

const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === "dark"
            ? "0 0 0 1px rgb(16 22 26 / 40%)"
            : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    "input:hover ~ &": {
        backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
        boxShadow: "none",
        background:
            theme.palette.mode === "dark"
                ? "rgba(57,75,89,.5)"
                : "rgba(206,217,224,.5)",
    },
}));

export default function MultipleSelectCheckList({
    value,
    aggregate = [],
    optionsList = [],
    defaultTitle = "",
    onChange,
    onDelete,
    subMenu = false,
    onSubListChange,
    enableSelectAll,
    channelType = "",
    disabled = false
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [subMenuOpened, setSubMenuOpened] = React.useState(false);

    const rowsPerPage = 50;
    const [page, setPage] = React.useState(0);
    const [filteredOptions, setFilteredOptions] = React.useState(optionsList);
    const [pages, setPages] = React.useState(
        Math.ceil(filteredOptions.length / rowsPerPage)
    );

    React.useEffect(() => {
        setPages(Math.ceil(filteredOptions.length / rowsPerPage));
        setPage(0);
    }, [filteredOptions]);

    const [searchText, setSearchText] = React.useState("");
    const [selectAll, setSelectAll] = React.useState(false);

    const handleToggleSelectAll = () => {
        setSelectAll((prev) => {
            let newValues = [];
            if (!prev) {
                if (searchText) {
                    newValues = [
                        ...value.filter(
                            (value) =>
                                !(value.name ?? value.title + value.subtitle)
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                        ),
                        ...filteredOptions,
                    ];
                    setSearchText("");
                } else {
                    newValues = filteredOptions.slice(0, 150);
                }
            }
            onChange(newValues);
            setOpen(false);
            return newValues.length === optionsList.length;
        });
    };

    React.useEffect(() => {
        setSelectAll(false);
    }, [optionsList]);

    React.useEffect(() => {
        const filtered = optionsList.filter((option) =>
            (option.name ?? option.title + option.subtitle)
                .toLowerCase()
                .includes(searchText.toLowerCase())
        );
        setFilteredOptions(filtered);
    }, [searchText, optionsList]);

    const handleClose = () => {
        setAnchorEl(null);
    };
    const ref = React.createRef();
    return (
        <>
            <FormControl
                sx={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    width: "95%",
                }}
            >
                <Autocomplete
                    ref={ref}
                    multiple
                    id="checkboxes-multiple-select"
                    sx={{ width: "100%" }}
                    value={value}
                    options={
                        enableSelectAll
                            ? filteredOptions.slice(
                                page * rowsPerPage,
                                (page + 1) * rowsPerPage
                            )
                            : filteredOptions
                    }
                    size="small"
                    disableCloseOnSelect
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    onBlur={(event) => {
                        if (subMenuOpened) {
                            setOpen(true);
                            setSubMenuOpened(false);
                        }
                    }}
                    groupBy={(option) => option.group}
                    getOptionLabel={(option) =>
                        option.name ?? option.title + option.subtitle
                    }
                    getOptionDisabled={(option) => option.disabled}
                    isOptionEqualToValue={(option, value) =>
                        (value.id ?? value.name ?? value.title + value.subtitle) ===
                        (option.id ?? option.name ?? option.title + option.subtitle)
                    }
                    disabled={disabled}
                    renderOption={(props, option, { selected }) => {
                        return (
                            <li
                                style={{
                                    paddingLeft: "8px",
                                    margin: "12px 0px",
                                    background: selected ? "#EBF3FF" : "",
                                    borderRadius: "8px",
                                }}
                                {...props}
                                key={uuidv4()} //borderBottom: "1px solid #F4F4F8"
                                onClick={(e) => {
                                    props.onClick(e);
                                    if (subMenu && !selected) {
                                        setAnchorEl({ target: ref.current, option });
                                        !getFormula(channelType,option.id) && setSubMenuOpened(true);
                                    }
                                }}
                            >
                                <Checkbox
                                    icon={<BpIcon />}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        width:"100%"
                                    }}
                                >
                                    <TriggerTooltip title={((getFormula(channelType,option.id)) ? <RenderTooltip option={option} channelType={channelType} /> : "")}>
                                        <Typography
                                            style={{
                                                fontFamily: "Inter",
                                                fontSize: "14px",
                                                fontWeight: 500,
                                                display:"flex",
                                                justifyContent:"space-between",
                                                alignItems:"center",
                                                width:"100%"
                                            }}
                                            component="div"
                                        >
                                            <span>{option.name ?? option.title}{" "}</span>
                                            {getFormula(channelType,option.id) && <span style={{
                                                    backgroundColor:"#F6F7F8",
                                                    color:"#5C6674",
                                                    padding:"3px 8px",
                                                    textAlign:"right",
                                                    display:"inline-block",
                                                    borderRadius:"4px"
                                                }}
                                            >
                                                CAL
                                            </span>}
                                        </Typography>
                                    </TriggerTooltip>
                                    {option.subtitle && (
                                        <Typography
                                            style={{
                                                padding: "4px 0px",
                                                fontFamily: "Inter",
                                                fontSize: "10px",
                                                fontWeight: 400,
                                                color: "#82838F",
                                            }}
                                        >
                                            {" "}
                                            {option.subtitle}
                                        </Typography>
                                    )}
                                </div>
                            </li>
                        );
                    }}
                    ListboxComponent={(listProps) => {
                        const { children, className, ...restProps } = listProps;
                        return (
                            <ul
                                {...restProps}
                                className={className + (enableSelectAll ? " scrollList" : "")}
                            >
                                {children}
                            </ul>
                        );
                    }}
                    PaperComponent={(paperProps) => {
                        const { children, ...restPaperProps } = paperProps;
                        return (
                            <Paper {...restPaperProps} style={{ padding: "2px 10px" }}>
                                {filteredOptions.length > 0 && enableSelectAll && (
                                    <>
                                        <Box
                                            onMouseDown={(e) => e.preventDefault()} // prevent blur
                                            pl={1.5}
                                            py={0.5}
                                        >
                                            <FormControlLabel
                                                sx={{
                                                    "& .MuiFormControlLabel-label": {
                                                        fontFamily: "Inter",
                                                        fontSize: "14px",
                                                        fontWeight: 500,
                                                    },
                                                }}
                                                style={{ paddingLeft: "6px" }}
                                                onClick={(e) => {
                                                    e.preventDefault(); // prevent blur
                                                    handleToggleSelectAll();
                                                }}
                                                label="Select all"
                                                control={
                                                    <Checkbox
                                                        id="select-all-checkbox"
                                                        style={{ marginRight: 8 }}
                                                        icon={<BpIcon />}
                                                        checkedIcon={checkedIcon}
                                                        checked={selectAll}
                                                    />
                                                }
                                            />
                                        </Box>
                                        <Divider style={{ borderColor: "#F4F4F8" }} />
                                    </>
                                )}
                                {children}
                                {enableSelectAll && pages > 1 && (
                                    <>
                                        <Divider style={{ borderColor: "#F4F4F8" }} />
                                        <div
                                            onMouseDown={(e) => e.preventDefault()} // prevent blur
                                            style={{ display: "flex", justifyContent: "center" }}
                                        >
                                            <IconButton
                                                sx={{ color: "black" }}
                                                onClick={() => setPage((prev) => prev - 1)}
                                                disabled={page === 0}
                                            >
                                                <KeyboardArrowLeftIcon style={{ color: "#6D6E7C" }} />
                                            </IconButton>
                                            <IconButton
                                                sx={{ color: "black" }}
                                                onClick={() => setPage((prev) => prev + 1)}
                                                disabled={
                                                    page + 1 ===
                                                    Math.ceil((filteredOptions.length - 1) / rowsPerPage)
                                                }
                                            >
                                                <KeyboardArrowRightIcon style={{ color: "#6D6E7C" }} />
                                            </IconButton>
                                        </div>
                                    </>
                                )}
                            </Paper>
                        );
                    }}
                    disableClearable={!onDelete}
                    renderTags={(value, getTagProps) => (
                        <div
                            className="chipBlock"
                            style={
                                value[0]?.title
                                    ? { maxHeight: "140px" }
                                    : { maxHeight: "60px", overflow: "scroll" }
                            }
                        >
                            {value.map((option, index) => {
                                const formula=getFormula(channelType,option.id);
                                return  <Chip
                                            onClick={(e) => {
                                                if (subMenu) {
                                                    setOpen(true);
                                                    setAnchorEl({ target: ref.current, option: { aggregate: aggregate[index], index, name: option.name ?? option.title,id: option?.id ?? "" } });
                                                    !formula && setSubMenuOpened(true);
                                                }
                                            }}
                                            sx={{
                                                borderRadius: '6px',
                                                background: '#f7f7f7',
                                                border: '1px solid #EAEAEC'
                                            }}
                                            // variant="outlined"
                                            label={
                                                <>
                                                    <span>{option.name ?? option.title}</span>
                                                    <span style={{color:"#4CA8F5"}}>
                                                        {subMenu?.length >= 1 && formula ? " (CAL) " : subMenu ? " (" + aggregate[index] + ")" : ""}
                                                    </span>
                                                </>
                                            }
                                            size="small"
                                            {...getTagProps({ index })}
                                        />
                            })}
                        </div>
                    )}
                    renderInput={(params) => {
                        if (params.InputProps) {
                            params.InputProps.disableUnderline = true;
                        }
                        return (
                            <div className="metric-list-form" style={{ width: "100%" }}>
                                <TextField
                                    hiddenLabel
                                    id="Analytics"
                                    variant="filled"
                                    size="small"
                                    InputProps={{
                                        disableUnderline: true,
                                        readOnly: true,
                                    }}
                                    isEmpty={!value}
                                    placeholder={defaultTitle}
                                    {...params}
                                    onChange={(event) => {
                                        setSearchText(event.target.value);
                                    }}
                                    onBlur={(e) => {
                                        setSearchText("");
                                    }}
                                />
                            </div>
                        );
                    }}
                    onChange={(event, newValue, reason) => {
                        if (reason === "clear" || reason === "removeOption")
                            setSelectAll(false);
                        if (
                            reason === "selectOption" &&
                            newValue.length === optionsList.length
                        )
                            setSelectAll(true);
                        if (subMenu) {
                            if (newValue.length > aggregate.length) {
                                onSubListChange(aggregate.push("Sum")); //new value added so default adding SUM aggregator
                            } else {
                                for (let [index, val] of value.entries()) {
                                    if (newValue.findIndex((v) => v.id === val.id) === -1) {
                                        aggregate.splice(index, 1); //old value deleted so filtering out old added aggregator
                                        onSubListChange(aggregate);
                                    }
                                }
                            }
                        }
                        setSearchText("");
                        onChange(newValue);
                    }}
                />
            </FormControl>
            {!getFormula(channelType,anchorEl?.option?.id) && <Menu
                id="menu-appbar"
                className="smooth-shadow"
                anchorEl={anchorEl?.target}
                // keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        minWidth: "200px",
                        padding: "0px 20px",
                    },
                }}
            >
                {
                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", width: '200px' }}>
                        <Typography
                            style={{
                                padding: "4px 0px",
                                fontFamily: "Inter",
                                fontSize: "12px",
                                fontWeight: 600,
                                color: "#82838F"
                            }}
                        >
                            {anchorEl?.option?.name}
                        </Typography>
                    </div>
                }
                {options.map((option) => (
                    <MenuItem
                        key={option}
                        selected={option === anchorEl?.option?.aggregate}
                        onClick={() => {
                            handleClose();
                            const lastIndex = aggregate.length - 1;
                            if (anchorEl?.option?.aggregate) {
                                aggregate[anchorEl?.option?.index] = option
                            } else {
                                aggregate[lastIndex] = option;
                            }
                            onSubListChange(aggregate);
                        }}
                        sx={{
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontWeight: 500
                        }}
                    >
                        {option}
                    </MenuItem>
                ))}
            </Menu>}
        </>
    );
}
