import { capitalizeString } from "../../components/CustomizingSources/Utils";
import { ChannelType } from "../../models/channel";

export const channelType = ChannelType.TiktokAds;

export function getAccountId(account) {
    return { advertiserId: account?.accountId?.toString() };
}

export function getTableObject(table) {
    return {
        reportType: "BASIC", //table?.id.toUpperCase(),
        tableUiName: "Basic" //table?.name,
    }
}

export function getApiObject(metrics, dimensions, filter, table, dataLevelTiktok) {
    const apiObject = {
        // reportType: table.name.toUpperCase(),
        reportType: "BASIC",
        metrics: metrics,
        dimensions: dimensions,
        dataLevel: null
        // dataLevel: dataLevelTiktok
    };
    return {
        ...apiObject,
        ...getFilterObject(filter),
    };
}

export function getTableFromObject(object) {
    return {
        id: capitalizeString(object.reportType).trim(),
        name: object.tableUiName,
    }
}

export function getFilterObject(filter) {
    if (filter.id) {
        return {
            filterGrouping: {
                filterId: filter.id
            }
        }
    }
    return {
        conditions: [...filter.dimensionFilters, ...filter.metricFilters].map(filter => ({
            fieldName: filter.filter.id,
            operator: filter.operator.type,
            value: filter.value,
        })),
    }
}

const convertFloats = ["Ctr"]

export function transformData(data) {
    [...data.leftMetrics, ...(data.rightMetrics ?? [])].forEach((m) => {
        convertFloats.find((f) => {
            if (f === m.id) {
                data.data.forEach((d) => {
                    const floatValue = parseFloat(d[m.name].split("%")[0]);
                    d[m.name] = floatValue;
                });
            }
        });
    });
}


export const properties = {
    dateDimension: { id: "Date", name: "Date" },
    dateFormat: "YYYY-MM-DD",
    filterOperators: [
        { label: "In", type: "IN", dataType: ["ENUM"] },
    ]
}
