
import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  Typography,
  Tooltip
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { ReactComponent as Dashboard } from "./Assets/dashboard_icon.svg";
import { ReactComponent as ActvDashboard } from "./Assets/actv_dashboard.svg";
import { ReactComponent as ActvCustom } from "./Assets/actv_custom_column.svg";
import { ReactComponent as ActvSetting } from "./Assets/actv_setting.svg";
import { ReactComponent as ActvExtractor } from "./Assets/actv_extractor.svg";
import { ReactComponent as Extractor } from "./Assets/extractor.svg";
import { ReactComponent as DataSource } from "./Assets/data_sources_icon.svg";
import { ReactComponent as Custom } from "./Assets/customcolumn.svg";
import { ReactComponent as Setting } from "./Assets/settings_icon.svg";
import { ReactComponent as ActvDatablend } from "./Assets/actv_datablend.svg";
import { ReactComponent as Tree1 } from "./Assets/tree1_icon.svg";
import { ReactComponent as Tree2 } from "./Assets/tree2_icon.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DeleteIcon from '../../Assets/Icons/close-no-bg.svg'
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { DELETE_REPORT_SUCCESS } from "../../api/report/type";
import { handleMixPanelData, EVENTS } from "../../utils/mixpanelV2";

const CollapsedView = ({ isPanelExpanded, reports = {}, navigationCallback }) => {
  const menuItems = [
    {
      menuId: 'dashboard',
      text: "Report Manager",
      icon: <Dashboard />,
      activeIcon: <ActvDashboard />,
      path: "/report-management",
      subMenu: Object.keys(reports).map((reportId) => {
        const tempReportObj = reports[reportId];
        return {
          name: Object.values(tempReportObj)?.[0]?.report_name,
          subMenuId: reportId,
          path: `/dashboard?report_id=${reportId}`,
        }
      })
      ,
    },
    // {
    //   menuId: 'pixisextractor',
    //   text: "Pixis Extractor",
    //   icon: <Extractor />,
    //   activeIcon: <ActvExtractor />,
    //   path: "/extractor",
    // },
    {
      menuId: "datablending",
      text: "Data Blending",
      icon: <DataSource />,
      activeIcon: <ActvDatablend />,
      path: "/data-blending"
    },
    {
      menuId: 'customcolumn',
      text: "Custom Column",
      icon: <Custom />,
      activeIcon: <ActvCustom />,
      path: "/custom-column",
    },

    {
      menuId: 'setting',
      text: "Settings",
      icon: <Setting />,
      activeIcon: <ActvSetting />,
      subMenu: [
        {
          name: "Manage Profile",
          subMenuId: 0,
          path: "/profile",
        },
        {
          name: "Privacy Policy",
          subMenuId: 2,
          path: "/privacy-policy",
        }, {
          name: "Terms Of Service",
          subMenuId: 3,
          path: "/terms-and-conditions",
        }, {
          name: "FAQ",
          subMenuId: 4,
          path: "/faq",
        },

      ],
    },
  ];




  const location = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [hide] = useState(false);
  const history = useHistory();
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [close] = useState(false);
  const [currentHoveredIndex, setCurrentHoveredIndex] = useState(null);

  useEffect(() => {
    if (isPanelExpanded === false) {
      setOpen(false);
      setActiveMenu(null);
      setActiveSubMenu(null);
      setIsHovered(true);
    }
  }, [isPanelExpanded]);


  const handleMouseEnter = (index) => {
    setIsHovered(true);
    setCurrentHoveredIndex(index)
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
    setCurrentHoveredIndex(null)
  };

  return (

    <div
      style={{
        transition: 'margin 0.3s ease',
        marginTop: '14px',
        position: 'relative',
        marginLeft: 0,
      }}
    >
      {menuItems.map((item, index) => {
        let isthere = item.subMenu?.find(childItem => location.pathname.startsWith(childItem.path))
        return <div style={{ marginTop: "0px" }} >
          <ListItemButton
            sx={{
              display: hide ? "none" : 'flex',
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.12)",
                color: "#FFF"
              },
              position: 'relative',
              backgroundColor: location.pathname.startsWith(item.path) || isthere ? "rgba(255, 255, 255, 0.03)" : null,
              color: location.pathname.startsWith(item.path) || isthere ? "#FFF" : null
            }}
            style={{
              height: "56px",
              width: !close ? "auto" : "56px",
              borderRadius: "15px",
              transition: "margin 1.5s ease",
              position: "relative",
              margin: "0 10px",
              padding: "19px",
            }}

            onMouseEnter={() => handleMouseEnter(item.path)}
            onMouseLeave={() => handleMouseLeave(item.path)}
          >
            <div style={{
              display: "flex", alignItems: "center",

            }}
              onClick={() => {
                handleMixPanelData(EVENTS.click_left_panel, { "menuId": item.menuId });
                setIsHovered(true);
                if (!open) {
                  setOpen(true);
                } else {
                  setOpen(false);
                }
                if (activeMenu !== item.menuItems && open === true) {
                  setOpen(true);
                }
                if (item.text === "Settings") {
                  setActiveMenu(activeMenu === null ? item.menuId : null);
                  setActiveSubMenu(-1);
                  setOpen(!open);
                }
                if (menuItems && !location.pathname.startsWith(item.path)) {
                  navigationCallback
                    ? navigationCallback(() => history.push(item.path))
                    : history.push(item.path);
                }

              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0, marginRight: "26px", color: "inherit"
                }}
              >
                {((isHovered && item.path === currentHoveredIndex) || location.pathname.startsWith(item.path) || isthere) ? item.activeIcon : item.icon}
              </ListItemIcon>
              {!close && (
                <div style={{ display: 'flex', alignItems: 'center', "& :hover": { color: "#FFF" } }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      width: "138px",
                    }}
                  >
                    {item.text}
                  </Typography>
                </div>
              )}
            </div>
            {item.subMenu &&
              item.subMenu.length > 0 &&
              (activeMenu !== item.menuId ? (
                <div style={{ margin: "16px 0px" }} onClick={() => {
                  setActiveMenu(activeMenu === null ? item.menuId : null);
                  setActiveSubMenu(-1);
                  setOpen(true);
                }}>
                  <ExpandMoreIcon />
                </div>
              ) : (
                <div style={{ margin: "16px 0px" }}
                  onClick={() => {
                    setActiveMenu(null);
                    setActiveSubMenu(-1);
                    setOpen(false)
                  }}
                >
                  <ExpandLessIcon />

                </div>
              ))}
          </ListItemButton>

          <div className="scrollBar0"
            style={{
              marginLeft: '30px',
              display: close ? 'none' : 'block',
              maxHeight: item.menuId === 'dashboard' ? '155px' : "",
              overflow: "auto"
            }}  >

            {item.subMenu?.map((menu, subMenukey) => (

              <Collapse
                in={activeMenu === item.menuId && open}
                timeout="auto"
                onMouseEnter={() => {
                  setIsHovered(true);
                }}
                onMouseLeave={() => {
                  setIsHovered(false);
                }}
              >
                <List
                  onClick={() => {
                    setActiveSubMenu(subMenukey);
                  }}
                  component="div"
                  style={{
                    marginLeft: '10px',
                    position: 'relative',
                  }}
                  disablePadding
                >
                  <div
                    style={{
                      width: '20px',
                      position: 'absolute',
                      left: 7,
                      opacity: 0.3,
                      top: subMenukey === 0 ? '-3px' : '-37px',
                    }}
                  >
                    {subMenukey === 0 && <Tree1 />}
                    {subMenukey > 0 && <Tree2 />}
                  </div>
                  <ListItemButton
                    onClick={() => {
                      navigationCallback ? navigationCallback(() => history.push(menu.path)) :
                        history.push(menu.path);
                    }}
                    style={{
                      displa: "flex",
                      justifyContent: "space-between",
                      width: '158px',
                      padding: '8px 14px',
                      borderRadius: '10px',
                      marginLeft: '24px',
                      marginTop: '3px',
                      height: '36px',
                      color: activeSubMenu === subMenukey ? 'rgba(255, 255, 255, 1)' : '',
                      backgroundColor: activeSubMenu === subMenukey ? 'rgba(255, 255, 255, 0.03)' : '',
                    }}
                    sx={{
                      pl: 4,
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.03)',
                        color: "#FFF"
                      },
                      background: location.pathname.startsWith(menu.path) ? 'rgba(255, 255, 255, 0.03)' : null,
                      color: location.pathname.startsWith(menu.path) ? "#FFF" : null
                    }}
                  >
                    <Box classname="list_menu" sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between",width:"100%" }}>
                      <Tooltip title={menu.name} placement="right-end" PopperProps={{style:{zIndex:99999999}}}>
                        <Typography style={{ fontFamily: 'Inter', fontSize: '12px', fontWeight: '500', lineHeight: '20px',width:"100%" }}>
                          {menu.name?.length > 10 ? menu.name?.substring(0,12)+"..." : menu.name}
                        </Typography>
                      </Tooltip>
                    </Box>
                    {item.menuId === 'dashboard' ? <button style={{ background: "none", border: "none", cursor: "pointer", display: "flex", justifyContent: "flex-end" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch({
                          type: DELETE_REPORT_SUCCESS,
                          payload: {
                            id: menu.subMenuId,
                          }
                        })
                      }}
                    > <img style={{ height: "16px", width: "16px" }} src={DeleteIcon} alt="" /> </button> : <></>}

                  </ListItemButton>
                </List>
              </Collapse>


            ))}
          </div>
        </div>
      })}


    </div>

  );
};
const mapStateToProps = (state) => ({
  reports: state.report.reports
});

export default connect(mapStateToProps)(CollapsedView)
