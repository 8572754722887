import { CREATE_TRIGGER_SUCCESS, DELETE_TRIGGER_SUCCESS, EMAIL_FAILURE, EMAIL_SUCCESS, GET_ALL_TRIGGERS_SUCCESS, RUN_TRIGGER_FAILURE, RUN_TRIGGER_SUCCESS, SHEETS_SUCCESS, UPDATE_TRIGGER_SUCCESS,SELECT_BULK_EDITING } from "./type"
import { transformFromSuperMetricDataObject } from "../../components/SuperMetrics/Utils";

const INITIAL_STATE = {
    email_connected: "",
    availableSheets: [],
    allTriggers: [],
    total_triggers: 0,
    listBulkEditing : [],
    allCheckBoxEnable : false
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EMAIL_SUCCESS:
            let email = action.payload.email_connected;
            return { ...state, email_connected: email, availableSheets: (!email || email !== state.email_connected) ? [] : state.availableSheets };
        case EMAIL_FAILURE:
            return { ...state, email_connected: "", availableSheets: [] };
        case SHEETS_SUCCESS:
            return { ...state, availableSheets: action.payload };
        case CREATE_TRIGGER_SUCCESS:
            const trigger = action.payload.trigger;
            const id = action.payload.id;
            return { ...state, allTriggers: state.allTriggers.concat({ id: id, ...trigger }) };
        case UPDATE_TRIGGER_SUCCESS:
            const updatedTriggers = Array.from(state.allTriggers);
            const pos = updatedTriggers.findIndex(e => e.id === action.payload.id);
            updatedTriggers[pos] = { ...updatedTriggers[pos], ...action.payload.trigger };
            return { ...state, allTriggers: [...updatedTriggers] }
        case DELETE_TRIGGER_SUCCESS:
            return { ...state, allTriggers: state.allTriggers.filter(trigger => trigger.id !== action.payload.id) }
        case GET_ALL_TRIGGERS_SUCCESS:
            return { ...state, allTriggers: action.payload.triggers,total_triggers: action.payload.total };
        case SELECT_BULK_EDITING:
            let tempState = [...state.listBulkEditing];
            let allCheckBoxEnable = state.allCheckBoxEnable;
            if(Array.isArray(action.payload.triggers)){
                if(action.payload.triggers.length === 0){
                    tempState = [];
                    allCheckBoxEnable = false;
                }else{
                    action.payload.triggers.forEach((payload)=>{
                        if(payload.status === "ACTIVE"){
                            const triggerId = payload._id;
                            const index = tempState.findIndex(trigger => triggerId === trigger._id);
                            index === -1 && tempState.push({...transformFromSuperMetricDataObject({...payload},false),_id:triggerId});
                        }
                    });
                    allCheckBoxEnable =  true;
                }
            }else{
                const triggerId = action.payload.triggers._id;
                const index = tempState.findIndex(trigger => triggerId === trigger._id);
                index === -1 ? tempState.push({...transformFromSuperMetricDataObject({...action.payload.triggers},false),_id:triggerId}) : tempState.splice(index,1);
            }
            return { ...state, listBulkEditing: [ ...tempState ], allCheckBoxEnable : allCheckBoxEnable}
        default:
            return state;
    } 
};

export default reducer;

