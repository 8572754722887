import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../Assets/loaders/reportLoader.json';

export default function DashboardLoader() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <div style={{ width: "100vw", height: "100vh", display: "flex", alignItems: "center" }}>
            <Lottie
                options={defaultOptions}
                height={200}
                width={200}
            />
        </div>
    );
}