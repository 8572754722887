import { all, put, takeLatest } from "redux-saga/effects"
import { api } from "..";
import { SOCIAL_LOGIN, LOGIN, LOGIN_FAILURE, LOGIN_SUCCESS, SIGNUP, SIGNUP_FAILURE, SIGNUP_SUCCESS, RESET_FAILURE, RESET_ERROR } from "./type";
import { EVENTS,mixpanel_obj,handleMixPanelData } from "../../utils/mixpanelV2";

function* signup(args) {
    try {
        const response = yield api.post("/v2/analytics/users/signup/", {
            name: `${args.payload.firstName} ${args.payload.lastName}`,
            email: args.payload.email,
            password: args.payload.password,
            mobile: args.payload.phone,
            company_name: args.payload.company,
            company_domain: (args.payload.domain.startsWith("www") ? "" : "www.") + args.payload.domain
        }, false);
        yield put({
            type: SIGNUP_SUCCESS,
            payload: response.data
        });
        mixpanel_obj.login_success(args.payload.email);
        handleMixPanelData(EVENTS.sign_up_cus, { email: args.payload.email, organisation_name: args.payload.company, signup_type: "custom" });
    } catch (error) {
        yield put({
            type: SIGNUP_FAILURE,
            payload: error.response.data.message
        });
    }
}

function* login(args) {
    try {
        const response = yield api.post("/v2/analytics/users/login/", {
            email: args.payload.email,
            password: args.payload.password,
            auth_type: "custom",
        }, false);
        yield put({
            type: LOGIN_SUCCESS,
            payload: response.data
        });
        mixpanel_obj.login_success(args.payload.email);
        handleMixPanelData(EVENTS.sign_in_cus, { email_id: args.payload.email, auth_type: "custom" });
    } catch (error) {
        yield put({
            type: LOGIN_FAILURE,
            payload: error.response?.data?.message
        });
        if (error.code === "ERR_NETWORK") {
            yield put({
                type: "SERVICE_DOWN"
            });
        }
    }
}

function* resetFailure() {
    try {
        yield put({
            type: RESET_FAILURE,
            payload: false
        });
    } catch (error) {
        yield put({
            type: LOGIN_FAILURE,
            payload: true,
        });
    }
}

export const checkSocialLogin = (authToken, email, auth_type) => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post("/v2/analytics/users/does-exists/", {
            auth_token: authToken,
            email,
            auth_type
        }, false);
        resolve(response.data);
    } catch (error) {
        reject(error);
    }
});

function* socialLogin(args) {
    try {
        const response = yield api.post("/v2/analytics/users/login/", {
            email: args.payload.email,
            auth_token: args.payload.credential,
            company_name: args.payload.company,
            company_domain: args.payload.domain,
            auth_type: args.payload.type
        }, false);
        yield put({
            type: LOGIN_SUCCESS,
            payload: response.data
        });

        mixpanel_obj.login_success(args.payload.email);
        handleMixPanelData(args.payload.type === "google" ? EVENTS.sign_in_google : EVENTS.sign_in_fb, { email_id: args.payload.email, auth_type:  args.payload.type});
    } catch (error) {
        yield put({
            type: LOGIN_FAILURE,
            payload: error.response?.data?.message
        });
        if (error.code === "ERR_NETWORK") {
            yield put({
                type: "SERVICE_DOWN"
            });
        }
    }
}

export const sendFingerprint = (visitorData, visitorId) => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post("/v2/analytics/users/fingerprint", {
            fingerprint_result: visitorData,
            visitor_id: visitorId,
        });
        resolve(response.data.responses);
    } catch (error) {
        reject(error.response);
    }
});

export default function* root() {
    yield all([
        takeLatest(SIGNUP, signup),
        takeLatest(LOGIN, login),
        takeLatest(SOCIAL_LOGIN, socialLogin),
        takeLatest(RESET_ERROR, resetFailure)
    ])
}
