import { ChannelType } from "../../models/channel";

export const channelType = ChannelType.LinkedInAds;


export function getAccountId(account) {
    return {
        accountId: account?.accountId,
        facets: [
            {
                key: "accounts",
                urns: [account?.accountId]
            }
        ]
    }
}

export function getFilterObject(filter) {
    if (filter.id) {
        return {
            filterGrouping: {
                filterId: filter.id
            }
        }
    }
    const filterArray = [...filter.dimensionFilters];
    const newFilter = [];
    filterArray.forEach((f) => {
        newFilter.push({
            key: f.value,
            urns: [f.urns]
        })
    });

    if (newFilter.length === 0) {
        return {};
    }

    return {
        facets: newFilter
    };
}

export function getApiObject(metrics, dimensions, filter, table) {
    return {
        metrics: metrics,
        dimensions: dimensions,
        ...getFilterObject(filter),
    };
}

export const properties = {
    dateDimension: { id: "date", name: "Date" },
    validKeys: ["accounts", "campaigns", "creatives", "campaignGroups"],
    filterOperators: [
        { label: "Equal", type: "EQUALS", dataType: ["CURRENCY", "INTEGER", "STRING"] },
        { label: "Not Equal", type: "NOT_EQUALS", dataType: ["CURRENCY", "INTEGER", "STRING"] },
        { label: "Greater Than", type: "GREATER_THAN", dataType: ["CURRENCY", "INTEGER", "FLOAT"] },
        { label: "Greater Than or Equal", type: "GREATER_THAN_OR_EQUAL", dataType: ["CURRENCY", "INTEGER", "FLOAT"] },
        { label: "Less Than", type: "LESS_THAN", dataType: ["CURRENCY", "INTEGER", "FLOAT"] },
        { label: "Less Than or Equal", type: "LESS_THAN_OR_EQUAL", dataType: ["CURRENCY", "INTEGER", "FLOAT"] },
        { label: "Contains", type: "CONTAINS", dataType: "STRING" },
        { label: "Not Contains", type: "NOT_CONTAINS", dataType: "STRING" },
        { label: "In", type: "IN_LIST", dataType: "STRING" },
        { label: "Not In", type: "NOT_IN_LIST", dataType: "STRING" },
        { label: "Starts With", type: "STARTS_WITH", dataType: "STRING" },
        { label: "Ends With", type: "ENDS_WITH", dataType: "STRING" }
    ],
    // validFilterBoolOperators: ["AND"]
}